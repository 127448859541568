import * as yup from 'yup';

export const NameSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .max(50)
    .min(3),
});
export const EmailSchema = yup.object().shape({
  email: yup
    .string()
    .required()
    .email()
    .max(255)
    .min(2),
});
export const PhoneSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required()
    .max(20)
    .min(5),
});

export const PasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required()
    .max(6)
    .min(6),
});

export const CodeSchema = yup.object().shape({
  code: yup
    .string()
    .required()
    .max(10)
    .min(5),
});

export const NumberSchema = yup.object().shape({
  code: yup
    .number()
    .required()
    .max(10)
    .min(5),
});

const validate = async (value, schema, message) => {
  const isValid = await schema.isValid(value);
  return {isValid, message: isValid ? '' : message};
};
export const validateName = async name => {
  return await validate({name}, NameSchema, "Name is invalid");
};
export const validateEmail = email => {
  return validate({email}, EmailSchema, "email format is invalid");
};
export const validatePhone = phoneNumber => {
  return validate({phoneNumber}, PhoneSchema, "phone number is invalid");
};
export const validatePassword = password => {
  return validate({password}, PasswordSchema, "password is invalid");
};
export const validateCode = code => {
  return validate({code}, CodeSchema, "code is invalid");
};
export const validateNumber = number => {
  return validate({number}, NumberSchema, "number is invalid");
};

export const validatePhoneNumber = phoneNumber => {
  return validate({phoneNumber}, PhoneSchema, "number is invalid");
};

