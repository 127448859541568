import {
  GetTableAreas,
  CreateTableAreas,
  UpdateTableAreas,
  SubscribeUpdateTable,
  SubscribeCreateTable,
} from "./schema/TableAreas.schema";
import Repository from "./Repository";
import { graphqlOperation } from "@aws-amplify/api";
import GraphqlService from "../service/Graphql.service";
import { get, isNil, omitBy, isArray, delay } from "lodash";
import GraphqlFunctions from "../service/Graphql.functions";
import { isNetworkError } from "../manager/AppointmentManager";

let subscribeCreateTable = null;
let subscribeUpdateTable = null;

class TableRepository extends Repository {
  getOfflineData = async () => {
    // const table = await GraphqlService.fetchOfflineTables("OFFLINE_TABLES");
    const table = await GraphqlFunctions.commonGraphqlService(
      "FETCH_TABLE_OFFLINE"
    );
    return table;
  };

  updateTables = async (data: any, retryCount: number = 1) => {
    const input = omitBy(data, isNil);
    const isOnline = await this.isCheckOnline();
    try {
      let table = data;
      // table = await GraphqlService.updateTable(data);
      table = await GraphqlFunctions.commonGraphqlService("UPDATE_TABLE", data);
      if (!isOnline) {
        // await GraphqlService.updateOfflineTable(data);
        await GraphqlFunctions.commonGraphqlService(
          "UPDATE_TABLE_OFFLINE",
          data
        );
      } else {
        try {
          const result = await this?.API.graphql(
            graphqlOperation(UpdateTableAreas, { input })
          );
          table = get(result, "data.updateTableAreas", {});
          if (!table) {
            console.log("Update table not went through");
          }
        } catch (error) {
          if (isArray(error?.errors)) {
            const conditional = error.errors.find(
              ({ errorType }) =>
                errorType === "DynamoDB:ConditionalCheckFailedException"
            );
            if (conditional) {
              this.updateTables(data);
            } else {
              throw error;
            }
          }
          console.log(error);
        }
      }
      return table;
    } catch (error) {
      console.warn("update table area", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return new Promise((res) => {
          delay(() => {
            res(this.updateTables(data, ++retryCount));
          }, 500 * retryCount);
        });
      }
      return { error };
    }
  };

  subscribeUpdateTable = async (
    shopId: string,
    callback: (data: any) => void
  ) => {
    const isOnline = await this.isCheckOnline();
    if (isOnline) {
      if (subscribeUpdateTable && subscribeUpdateTable.unsubscribe) {
        subscribeUpdateTable.unsubscribe();
      }
      subscribeUpdateTable = await this.API.graphql(
        graphqlOperation(SubscribeUpdateTable)
      )
      subscribeUpdateTable.subscribe({
        next: (data: any) => {
          console.log(">>> subscribe update table", data);
          const table = get(data, "value.data.onUpdateTableAreas", {});
          if (table) {
            callback(table);
          } else {
            console.log("Unhandled subscription "); // MIXPANEL
          }
        },
        error: () => {
          delay(() => {
            this.subscribeUpdateTable(shopId, callback);
          }, 1000);
        },
        completed: () => {
          console.log("completed");
        },
      });
    } else {
      if (!window?.standlone) {
        await GraphqlService.onUpdateTable(shopId, (data) => {
          console.log(">>> subscribe update table", data);
          const table = get(data, "data.onUpdateTable", {});
          if (table && !table.error) {
            callback(table);
          }
        });
      }
    }
  };

  createTables = async (data: any, retryCount: number = 1) => {
    const input = omitBy(data, isNil);
    const isOnline = await this.isCheckOnline();
    try {
      let table = data;
      // table = await GraphqlService.createTable(data);
      table = await GraphqlFunctions.commonGraphqlService("CREATE_TABLE", data);
      if (!isOnline) {
        // await GraphqlService.createOfflineTable(data);
        await GraphqlFunctions.commonGraphqlService(
          "CREATE_TABLE_OFFLINE",
          data
        );
      } else {
        try {
          const result = await this?.API.graphql(
            graphqlOperation(CreateTableAreas, { input })
          );
          table = get(result, "data.createTableAreas", {});
          if (!table) {
            console.log("Create table not went through");
          }
        } catch (error) {
          if (isArray(error?.errors)) {
            const conditional = error.errors.find(
              ({ errorType }) =>
                errorType === "DynamoDB:ConditionalCheckFailedException"
            );
            if (conditional) {
              this.updateTables(data);
            } else {
              throw error;
            }
          }
          console.log(error);
        }
      }
      return table;
    } catch (error) {
      console.warn("create table area", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return new Promise((res) => {
          delay(() => {
            res(this.createTables(data, ++retryCount));
          }, 500 * retryCount);
        });
      }
      return { error };
    }
  };

  subscribeCreateTable = async (
    shopId: string,
    callback: (data: any) => void
  ) => {
    const isOnline = await this.isCheckOnline();
    if (isOnline) {
      if (subscribeCreateTable && subscribeCreateTable.unsubscribe) {
        subscribeCreateTable.unsubscribe();
      }
      subscribeCreateTable = await  this.API.graphql(
        graphqlOperation(SubscribeCreateTable)
      );
      subscribeCreateTable.subscribe({
        next: (data: any) => {
          console.log(">>> subscribe create table", data);
          const table = get(data, "value.data.onCreateTableAreas", {});
          if (table) {
            callback(table);
          } else {
            console.log("Unhandled subscription ");
          }
        },
        error: () => {
          delay(() => {
            this.subscribeCreateTable(shopId, callback);
          }, 1000);
        },
        completed: () => {
          console.log("completed");
        },
      });
    } else {
      if (!window?.standlone) {
        await GraphqlService.onCreateTable(shopId, (data) => {
          console.log(">>> subscribe create table", data);
          const table = get(data, "data.onCreateTable", {});
          if (table && !table.error) {
            callback(table);
          }
        });
      }
    }
  };

  fetchTables = async (shopId: string, retryCount: number = 1) => {
    let data: any = [];
    const isOnline = await this.isCheckOnline();
    try {
      if (isOnline) {
        try {
          const response: any = await this.API.graphql(
            graphqlOperation(GetTableAreas, { shopId })
          );
          data = get(response, "data.getTableAreas", {});
          // data = await GraphqlService.setShopTables(tables);
          await GraphqlFunctions.commonGraphqlService("SET_TABLES", data);
        } catch (error) {
          throw error;
        }
      } else {
        // data = await GraphqlService.fetchTables(shopId);
        data = await GraphqlFunctions.commonGraphqlService(
          "FETCH_TABLES",
          shopId
        );
      }
      return data;
    } catch (error) {
      console.warn("fetch tables", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchTables(shopId, ++retryCount);
      }
      return { error };
    }
  };
}

export default new TableRepository();
