import React, {Component, useRef, useState} from 'react';
import styles from '../../Styles/Styles.css';


const SaveButton = (props) => {
  const {onClick, text= 'Save', isValid = true} = props;
  return (
    <div onClick={() => onClick()} className={styles.saveButtonContainer} style={{backgroundColor: isValid ? '#49a4d4' : '#8A9CA5'}}>
      <div className={styles.saveButtonText}>{text}</div>
    </div>
  );
};
export default SaveButton;
