import gql from 'graphql-tag';

export const CreateSupplier = gql`
  mutation CreateSupplier($input: CreateSupplierInput!) {
    createSupplier(input: $input) {
      supId
      supName
      supEmail
      mobileNumber
      telephoneNumber
      company
      address
      comments
      createdUserId
      salonId
      address
      createdTime
      updatedTime
    }
  }
`;

export const UpdateSupplier = gql`
  mutation UpdateSupplier($input: UpdateSupplierInput!) {
    updateSupplier(input: $input) {
      supId
      supName
      supEmail
      mobileNumber
      telephoneNumber
      company
      address
      comments
      createdUserId
      salonId
      address
      createdTime
      updatedTime
    }
  }
`;

export const DeleteSupplier = gql`
  mutation DeleteSupplier($input: DeleteSupplierInput!) {
    deleteSupplier(input: $input) {
      supId
      supName
      supEmail
      mobileNumber
      telephoneNumber
      company
      address
      comments
      createdUserId
      salonId
      address
      createdTime
      updatedTime
    }
  }
`;

export const fetchOptionSets = gql`
  query FetchOptionSets($shopId: String!, $limit: Int, $nextToken: String) {
    fetchProductOptionSets(shopId: $shopId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopId
        setName
        createdTime
        updatedTime
        setDisplayName
        options {
          id
          optionName
        }
      }
      nextToken
    }
  }
`;

export const CreateOption = gql`
  mutation CreateOption($input: CreateProductOptionSetInput!) {
    createProductOptionSet(input: $input) {
      id
      shopId
      setName
      setDisplayName
      createdTime
      updatedTime
      options {
        id
        optionName
      }
    }
  }
`;

export const UpdateOption = gql`
  mutation UpdateOption($input: UpdateProductOptionSetInput!) {
    updateProductOptionSet(input: $input) {
      id
      shopId
      setName
      setDisplayName
      createdTime
      updatedTime
      options {
        id
        optionName
      }
    }
  }
`;

export const DeleteOption = gql`
  mutation DeleteOption($input: DeleteProductOptionSetInput!) {
    deleteProductOptionSet(input: $input) {
      id
      shopId
    }
  }
`;