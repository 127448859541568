import React, { useEffect, useState } from "react";
import {
  CheckoutDiscountContainer,
  CheckoutDiscountListContainer,
} from "../Styles/Styled";
import {
  SwipeAction,
  SwipeableList,
  LeadingActions,
  TrailingActions,
  Type as ListType,
  SwipeableListItem,
} from "react-swipeable-list";
import styles from "../Styles/Styles.css";
import {
  updateStockItemCount,
  getSelectedModifiersItems,
} from "../../internal/manager/ProductManager";
import { connect } from "react-redux/es/index";
import { Actions } from "../../internal/app/Actions";
import IconDelete from "../../../assets/svg/IconDelete.svg";
import IconPlus from "../../../assets/svg/IconPlusButton.svg";
import IconMinus from "../../../assets/svg/IconMinusButton.svg";
import ProductDeleteModal from "../model/views/ProductDeleteModal";
import ApprovalView from "../../modules/orders/views/ApprovalView";
import { isCanAccess } from "../../internal/manager/EmployeeManager";
import EditProductPriceModal from "../model/views/EditProductPriceModal";
import _, {
  capitalize,
  get,
  groupBy,
  isEmpty,
  startCase,
  filter,
} from "lodash";
import { getCurrencyWithPrice } from "../../internal/manager/PaymentManager";
import { getServiceChargeSettings } from "../../internal/manager/AppointmentManager";

const CheckoutDetailsComponent = (props) => {
  const {
    shopData,
    totalPrice,
    orderOffers,
    deleteOrder,
    productList,
    currentUser,
    absoluteTotal,
    serviceCharge,
    selectedProduct,
    purchaseServices,
    purchaseProducts,
    currentStockList,
    setPurchaseService,
    setPurchaseProducts,
    selectedAppointment,
    appointmentDiscounts,
    deletePurchaseProduct,
    deletePurchaseService,
    updateCurrentStockCount,
    removeAppointmentDiscount,
  } = props;
  const [didSwipe, setSwiped] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [deletedType, setDeletedType] = useState("");
  const [selectedItem, setSelectedItem] = useState({} as any);
  const [selectedItemData, setSelectedItemData] = useState({});
  const [isOpenEditProduct, setIsOpenEditProduct] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenApprovalModal, setIsOpenApprovalModal] = useState(false);

  const toggleDeleteModal = () => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const toggleProductPriceModal = (item = null) => {
    if (!item) {
      return setIsOpenEditProduct(false);
    }
    setEditItem(item);
    setIsOpenEditProduct(!isOpenEditProduct);
  };

  const findItemNested = (categories, id, nestingKey) => {
    categories.map((category, service) => {
      if (category) return category;
      if (service.id === id) return service;
      if (service[nestingKey])
        return findItemNested(service[nestingKey], id, nestingKey);
      return null;
    });
  };

  const removeSelectedItem = () => {
    if (selectedItem.type === "PRODUCT") {
      removeSelectedProduct();
    } else if (selectedItem.type === "SERVICE") {
      removeSelectedService();
    } else {
      removeDiscount();
    }
  };

  const removeSelectedService = () => {
    toggleDeleteModal();
    deletePurchaseService(selectedItem);
  };

  const removeDiscount = () => {
    toggleDeleteModal();
    removeAppointmentDiscount(selectedItem);
  };

  const addSubService = (type, item) => {
    setSelectedItem(item);
    if (type === "ADD") {
      const quantity = item.quantity + 1;
      const total = quantity * item.servicePrice;
      const param = {
        id: item.id,
        price: total,
        tax: item.tax,
        symbol1: "X",
        type: "SERVICE",
        image: item.image,
        quantity: quantity,
        symbol2: item.symbol2,
        serviceTitle: item.serviceTitle,
        servicePrice: item.servicePrice,
      };
      setPurchaseService([param]);
    } else {
      if (item.quantity === 1) {
        if (isCanAccess(role, "isCanDeleteItem")) {
          setSelectedItemData({});
          toggleDeleteModal();
        } else {
          setSelectedItemData({});
          setIsOpenApprovalModal(true);
        }
      } else {
        const quantity = item.quantity - 1;
        const total = quantity * item.servicePrice;
        const param = {
          id: item.id,
          price: total,
          symbol1: "X",
          tax: item.tax,
          type: "SERVICE",
          image: item.image,
          quantity: quantity,
          symbol2: item.symbol2,
          serviceTitle: item.serviceTitle,
          servicePrice: item.servicePrice,
        };
        setPurchaseService([param]);
      }
    }
  };

  const removeSelectedProduct = () => {
    if (
      !isEmpty(selectedAppointment) &&
      selectedAppointment?.productDetails?.length === 1
    ) {
      deleteOrder({
        orderId: selectedAppointment.orderId,
        shopId: selectedAppointment.shopId,
      });
    } else {
      const product = _.find(
        productList,
        (item) => item.pid === selectedItem.id
      );
      toggleDeleteModal();
      onUpdateStockCount(product.pid, true);
      // deletePurchaseProduct(product);
      deletePurchaseProduct(selectedItem);
    }
  };

  const removeSelectedServiceItem = (item: any) => {
    deletePurchaseService(item);
  };

  const removeSelectedProductItem = (item: any) => {
    if (
      !isEmpty(selectedAppointment) &&
      selectedAppointment?.productDetails?.length === 1
    ) {
      deleteOrder({
        orderId: selectedAppointment.orderId,
        shopId: selectedAppointment.shopId,
      });
    } else {
      const product = _.find(purchaseProducts, (pro) => pro.id === item.id);
      onUpdateStockCount(product.id, true);
      deletePurchaseProduct(selectedItem);
    }
  };

  const role = currentUser?.specializations;

  const onUpdateStockCount = (
    productId,
    isRemoved,
    param = null,
    isUsingBtn = false
  ) => {
    const productData = isRemoved
      ? _.find(purchaseProducts, (item) => item.id === productId)
      : param;
    const particularProduct = _.find(
      productList,
      (product) => product.pid === productData.id
    );
    const stockItems = _.get(particularProduct, "stockItems", []);
    const newStock = updateStockItemCount(
      stockItems,
      currentStockList,
      isUsingBtn ? 1 : productData.quantity,
      isRemoved
    );
    updateCurrentStockCount(newStock);
  };

  const addSubProduct = (type, item) => {
    setSelectedItem(item);
    setDeletedType("SWIPE_RIGHT");
    if (type === "ADD") {
      const quantity = item.quantity + 1;
      const total = quantity * item.productSalePrice;
      const param = {
        id: item.id,
        symbol1: "X",
        price: total,
        tax: item.tax,
        type: "PRODUCT",
        image: item.image,
        quantity: quantity,
        symbol2: item.symbol2,
        sendToBar: !!item.sendToBar,
        productName: item.productName,
        productPrice: item.productPrice,
        sendToKitchen: !!item.sendToKitchen,
        sendPrepTicket: item.sendPrepTicket,
        productSalePrice: item.productSalePrice,
      };
      setPurchaseProducts([param]);
      onUpdateStockCount(param.id, false, param, true);
    } else {
      if (item.quantity === 1) {
        if (isCanAccess(role, "isCanDeleteItem")) {
          setSelectedItemData({});
          toggleDeleteModal();
        } else {
          setSelectedItemData({});
          setIsOpenApprovalModal(true);
        }
      } else {
        const quantity = item.quantity - 1;
        const total = quantity * item.productSalePrice;
        const param = {
          id: item.id,
          symbol1: "X",
          price: total,
          tax: item.tax,
          type: "PRODUCT",
          image: item.image,
          quantity: quantity,
          symbol2: item.symbol2,
          sendToBar: !!item.sendToBar,
          productName: item.productName,
          productPrice: item.productPrice,
          sendToKitchen: !!item.sendToKitchen,
          sendPrepTicket: item.sendPrepTicket,
          productSalePrice: item.productSalePrice,
        };
        setPurchaseProducts([param]);
        onUpdateStockCount(param.id, true, param, true);
      }
    }
  };

  const onClickAddSubProductService = (type, item, event) => {
    event.stopPropagation();
    if (item.type === "PRODUCT") {
      addSubProduct(type, item);
    } else {
      addSubService(type, item);
    }
  };

  const trailingActions = (item: any) => (
    <TrailingActions>
      <SwipeAction
        destructive={false}
        onClick={() => {
          setDeletedType("SWIPE_LEFT");
          setSelectedItem(item);
          if (isCanAccess(role, "isCanDeleteItem")) {
            toggleDeleteModal();
          } else {
            setSelectedItemData({});
            setIsOpenApprovalModal(true);
          }
        }}
      >
        <div className={styles.checkoutPerchesListDeleteWrapper}>
          <div className={styles.checkoutPerchesListDeleteWrapperInfo}>
            <img src={IconDelete} />
            <div className={styles.checkoutPerchesListDeleteText}>Delete</div>
          </div>
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  const leadingActions = (item) => (
    <LeadingActions>
      <SwipeAction onClick={() => {}} destructive={false}>
        <div className={styles.checkoutPerchesListDeleteWrapper}>
          <div className={styles.checkoutPerchesListAddWrapper}>
            <img
              onClick={(e) => onClickAddSubProductService("ADD", item, e)}
              src={IconPlus}
            />
            <img
              onClick={(e) => onClickAddSubProductService("SUB", item, e)}
              src={IconMinus}
            />
          </div>
        </div>
      </SwipeAction>
    </LeadingActions>
  );

  const { isEnabled, serviceChargePercentage } = getServiceChargeSettings(
    shopData.systemSettings
  );

  const renderProductItem = (purchaseProducts: any) => {
    const groupProducts = groupBy(purchaseProducts, "id");

    // const groupProducts = groupBy(purchaseProducts.map((prod) => {
    //   if (isEmpty(prod.priceVariants)) {
    //     prod.gId = prod.id;
    //   } else {
    //     prod.gId = prod.priceV
    //   }
    // }), "id");

    console.log({groupProducts})

    return (
      <>
        {Object.keys(groupProducts).map((key) => {
          const productName = get(groupProducts[key], "[0].productName", "");
          const isOptions = !isEmpty(
            get(groupProducts[key], "[0].priceVariants", null)
          );

          return (
            <div className={styles.orderDetailsPurchaseItemWrapper}>
              {/* {isOptions && (
                <div className={styles.orderDetailsPurchaseItemText}>
                  {startCase(productName)}
                </div>
              )} */}
              <SwipeableList fullSwipe={false} type={ListType.IOS}>
                {groupProducts[key] &&
                  groupProducts[key].map((item: any) => {
                    let title: string;
                    let optionName: string = "";
                    const price = getCurrencyWithPrice(
                      item.productSalePrice,
                      shopData.currency,
                      false
                    );
                    if (isOptions) {
                      title = capitalize(productName);
                      optionName = get(item, "priceVariants[0].optionUnit", "");
                    } else {
                      title = startCase(get(item, "productName", ""));
                    }
                    console.log(item.modifiers)
                    const modifierItems = getSelectedModifiersItems(
                      item.modifiers
                    );
                    console.log(modifierItems)
                    const modifierNames = modifierItems
                      .map(
                        (modifier: any) =>
                          modifier.items?.map((item) => `${item.name} X ${item.quantity}`)
                      )
                      .join(", ");

                    return (
                      <SwipeableListItem
                        onSwipeStart={() => setSwiped(true)}
                        leadingActions={leadingActions(item)}
                        trailingActions={trailingActions(item)}
                        onSwipeEnd={() => _.defer(() => setSwiped(false))}
                      >
                        <div
                          className={styles.checkoutPerchesListWrapperInfo}
                          onClick={() => {
                            if (!didSwipe) {
                              toggleProductPriceModal(item);
                            }
                          }}
                        >
                          <div
                            className={
                              styles.orderDetailsPurchaseItemPriceWrapper
                            }
                          >
                            <div>
                              <div
                                className={
                                  styles.orderDetailsPurchaseItemPriceText
                                }
                              >
                                {title}
                              </div>
                              <div
                                className={
                                  styles.orderDetailsPurchaseItemPriceOptionText
                                }
                              >
                                {optionName}
                              </div>
                              <div
                                className={
                                  styles.orderDetailsPurchaseItemModifierText
                                }
                              >
                                {modifierNames}
                              </div>
                            </div>
                            <div
                              className={
                                styles.checkoutPerchesListServiceWrapper
                              }
                            >
                              <div
                                className={
                                  styles.checkoutPerchesListQuantityText
                                }
                              >{`${item.quantity} X `}</div>
                              <div
                                className={
                                  styles.checkoutPerchesListServiceText
                                }
                              >
                                {price}
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwipeableListItem>
                    );
                  })}
              </SwipeableList>
            </div>
          );
        })}
      </>
    );
  };

  const renderServiceItem = (purchaseServices: any) => {
    return (
      <SwipeableList fullSwipe={false} type={ListType.IOS}>
        {purchaseServices &&
          purchaseServices.map((item: any) => {
            const itemPrice = item.servicePrice;
            const price = getCurrencyWithPrice(
              itemPrice,
              shopData.currency,
              false
            );
            return (
              <SwipeableListItem
                onSwipeStart={() => setSwiped(true)}
                leadingActions={leadingActions(item)}
                trailingActions={trailingActions(item)}
                onSwipeEnd={() => _.defer(() => setSwiped(false))}
              >
                <div
                  className={styles.checkoutPerchesListWrapperInfo}
                  onClick={() => {
                    if (!didSwipe) {
                      toggleProductPriceModal(item);
                    }
                  }}
                >
                  <div className={styles.checkoutPerchesListServiceText}>
                    {startCase(item.serviceTitle)}
                  </div>
                  <div className={styles.checkoutPerchesListServiceWrapper}>
                    <div
                      className={styles.checkoutPerchesListQuantityText}
                    >{`${item.quantity} X `}</div>
                    <div className={styles.checkoutPerchesListServiceText}>
                      {price}
                    </div>
                  </div>
                </div>
              </SwipeableListItem>
            );
          })}
      </SwipeableList>
    );
  };

  return (
    <div className={styles.checkoutDetailsWrapper}>
      <div className={styles.checkoutDetailsTotalText}>Your Total</div>
      <div className={styles.checkoutTotalPrice}>
        <div className={styles.checkoutCurrencyText}>{shopData.currency}</div>
        <div className={styles.checkoutTotalPriceText}>
          {getCurrencyWithPrice(totalPrice, shopData.currency, false)}
        </div>
      </div>

      <div className={styles.checkoutPerchesListWrapper}>
        {renderProductItem(purchaseProducts)}
        {renderServiceItem(purchaseServices)}
      </div>

      <CheckoutDiscountContainer>
        <SwipeableList fullSwipe={false} type={ListType.IOS}>
          {orderOffers.map((item: any, index: number) => {
            let amount = 0;
            if (item.offerType === "PRODUCT") {
              const filterProduct = filter(
                purchaseProducts,
                (product: any) => product.offerProduct
              );
              amount = filterProduct.reduce((acc, item) => {
                return acc + parseFloat(item.price);
              }, 0);
            } else {
              amount = item.amount;
            }
            return (
              <SwipeableListItem
                key={index}
                trailingActions={trailingActions(item)}
              >
                <CheckoutDiscountListContainer>
                  <div>{item.title}</div>
                  <div>{`(${getCurrencyWithPrice(`${amount}`)})`}</div>
                </CheckoutDiscountListContainer>
              </SwipeableListItem>
            );
          })}
        </SwipeableList>
      </CheckoutDiscountContainer>

      <CheckoutDiscountContainer>
        <SwipeableList fullSwipe={false} type={ListType.IOS}>
          {appointmentDiscounts.map((item: any, index: number) => {
            let amount = item.amount;
            if (item.amountType === "PERCENTAGE") {
              amount = (absoluteTotal * parseFloat(item.percentage)) / 100;
            }
            const price = getCurrencyWithPrice(
              amount,
              shopData.currency,
              false
            );
            return (
              <SwipeableListItem
                key={index}
                trailingActions={trailingActions(item)}
              >
                <CheckoutDiscountListContainer>
                  <div>{item.title}</div>
                  <div>{`(${price})`}</div>
                </CheckoutDiscountListContainer>
              </SwipeableListItem>
            );
          })}
        </SwipeableList>
      </CheckoutDiscountContainer>

      {isEnabled ? (
        <div className={styles.checkoutPerchesListWrapperInfo}>
          <div className={styles.checkoutPerchesListServiceText}>
            Service Charge {serviceChargePercentage}%
          </div>
          <div className={styles.checkoutPerchesListServiceText}>
            {`${getCurrencyWithPrice(serviceCharge, shopData.currency)}`}
          </div>
        </div>
      ) : null}

      {/* <div className={styles.checkoutPerchesListWrapperInfo}>
        <div className={styles.checkoutPerchesListServiceText}>Tax</div>
        <div className={styles.checkoutPerchesListServiceText}>0</div>
      </div> */}

      <div className={styles.checkoutPerchesListWrapperInfo}>
        <div className={styles.checkoutPerchesListTotalText}>Total Price</div>
        <div
          className={styles.checkoutPerchesListTotalText}
        >{`${getCurrencyWithPrice(totalPrice, shopData.currency)}`}</div>
      </div>
      <EditProductPriceModal
        isOpen={isOpenEditProduct}
        editItem={editItem}
        closeModal={() => toggleProductPriceModal()}
        onOpenApproveModal={(type, selectedItem) => {
          setSelectedItemData({ type, selectedItem });
          setIsOpenApprovalModal(true);
        }}
      />
      <ProductDeleteModal
        isOpen={isOpenDeleteModal}
        closeModal={() => toggleDeleteModal()}
        removeProduct={() => removeSelectedItem()}
        status={!_.isNil(selectedItem.type) ? selectedItem.type : "Discount"}
      />
      <ApprovalView
        isOpenApprovalModal={isOpenApprovalModal}
        onSetIsOpenApprovalModal={(status) => setIsOpenApprovalModal(status)}
        onOpenEditModal={() => {
          if (!isEmpty(selectedItemData)) {
            setIsOpenEditProduct(true);
          }
        }}
        deleteItem={() => {
          if (!isEmpty(selectedItemData)) {
            if (selectedItemData?.type === "PRODUCT") {
              removeSelectedProductItem(selectedItemData?.selectedItem);
            } else {
              removeSelectedServiceItem(selectedItemData?.selectedItem);
            }
          } else {
            toggleDeleteModal();
          }
          setIsOpenApprovalModal(false);
        }}
      />
    </div>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
    categories: state.service.get("categories"),
    orderOffers: state.offer.get("orderOffers"),
    productList: state.product.get("productList"),
    purchaseProducts: state.product.get("purchaseProducts"),
    purchaseServices: state.service.get("purchaseServices"),
    currentStockList: state.product.get("currentStockList"),
    selectedProduct: state.product.get("choossedProductItem"),
    selectedAppointment: state.orders.get("selectedAppointment"),
    serviceCategoryList: state.service.get("serviceCategoryList"),
    appointmentDiscounts: state.product.get("appointmentDiscounts"),
  }),
  {
    deleteOrder: Actions.payment.deleteOrder,
    selectProduct: Actions.product.selectProduct,
    selectService: Actions.service.selectService,
    setPurchaseService: Actions.service.setPurchaseService,
    setPurchaseProducts: Actions.product.setPurchaseProducts,
    deletePurchaseService: Actions.service.deletePurchaseService,
    deletePurchaseProduct: Actions.product.deletePurchaseProduct,
    updateCurrentStockCount: Actions.product.updateCurrentStockCount,
    removeAppointmentDiscount: Actions.product.removeAppointmentDiscount,
  }
)(CheckoutDetailsComponent);
