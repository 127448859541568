import React, { useEffect, useState } from "react";
import _, {
  get,
  find,
  filter,
  isEmpty,
  compact,
  findIndex,
  capitalize,
} from "lodash";
import {
  OfferWrapper,
  OfferItemWrapper,
  OfferDataWrapper,
  OfferApplyButton,
  SelectedOfferWrapper,
} from "../Styles/Styled";
import moment from "moment";
import { connect } from "react-redux";
import {
  getOfferDetails,
  getCurrencyWithPrice,
} from "../../internal/manager/PaymentManager";
import { Actions } from "../../internal/app/Actions";
import IconDelete from "../../../assets/svg/IconDiscountDelete.svg";
import AddOfferModal from "../AddOffer/index";

const OfferView = (props: any) => {
  const {
    offer,
    shopData,
    productList,
    orderOffers,
    setOrderOffer,
    purchaseProducts,
    deleteOrderOffer,
    setOfferProducts,
  } = props;
  const [offers, setOffers] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [selectedOffers, setSelectedOffers] = useState<any>([]);
  const [isOpenOfferModal, setIsOpenOfferModal] = useState(false);

  useEffect(() => {
    setSelectedOffers(orderOffers);
  }, [orderOffers]);

  useEffect(() => {
    setProducts(productList);
  }, [productList]);

  useEffect(() => {
    if (offer) {
      setOffers(offer.offers);
    }
  }, [offer]);

  const toggleOfferModal = () => {
    setSelectedItem({});
    setIsOpenOfferModal(!isOpenOfferModal);
  };

  const onClickRemove = (offer: any) => {
    deleteOrderOffer(offer);
  };

  const onAddOffer = (offer: any) => {
    const isApply =
      findIndex(selectedOffers, (data: any) => data.offerId === offer.id) > -1;
    if (isApply) {
      return;
    }
    let data: any = getOfferDetails(
      offer,
      shopData,
      purchaseProducts,
      products
    );
    if (get(data, "[0].offerType", "") === "PRODUCT") {
      const product: any = data[0].offerItems.map((item: any) => {
        const findProduct = find(
          products,
          (product: any) => product.pid === item.pid
        );
        if (findProduct) {
          return {
            tax: 0,
            quantity: 1,
            symbol1: "",
            symbol2: "",
            type: "PRODUCT",
            offerProduct: true,
            enteredNumber1: "",
            enteredNumber2: "",
            id: findProduct.pid,
            image: findProduct.image,
            sendToBar: !!findProduct.sendToBar,
            productName: findProduct.productName,
            price: parseFloat(findProduct.itemPrice),
            sendToKitchen: !!findProduct.sendToKitchen,
            sendPrepTicket: findProduct.sendPrepTicket,
            productPrice: parseFloat(findProduct.itemPrice),
            productSalePrice: parseFloat(findProduct.itemPrice),
          };
        }
      });
      setOfferProducts(compact(product));
    }
    setOrderOffer(data);
  };

  const onSelectOffer = (offer: any) => {
    const isSelected = offer.id === selectedItem?.id;
    setSelectedItem(isSelected ? {} : offer);
  };

  const getActiveOffer = (offer: any) => {
    let isValidDate: boolean = true;
    let isValidTime: boolean = false;
    if (!offer.isEveryDay) {
      isValidDate = offer.timePeriods.some((timePeriod: any) =>
        moment().isSame(timePeriod.date, "day")
      );
      const validDates = filter(offer.timePeriods, (timePeriod: any) => {
        if (moment().isSame(timePeriod.date, "day")) {
          return timePeriod;
        }
      });
      isValidTime = validDates.some((timePeriod: any) => {
        const beforeTime = moment(timePeriod.startTime, "hh:mm A");
        const afterTime = moment(timePeriod.endTime, "hh:mm A");
        const isBetween = moment().isBetween(beforeTime, afterTime);
        return isBetween;
      });
    } else {
      const beforeTime = moment(offer.timePeriod.startTime, "hh:mm A");
      const afterTime = moment(offer.timePeriod.endTime, "hh:mm A");
      isValidTime = moment().isBetween(beforeTime, afterTime);
    }
    if (!isEmpty(purchaseProducts) && isValidDate && isValidTime) {
      const results: any = purchaseProducts.filter(({ id }) =>
        offer.offerItems.every(({ pid }) => pid === id)
      );
      return !isEmpty(results);
    }
    return false;
  };

  const getOfferItem = (offerItem: any) => {
    let value = "";
    if (offerItem.type === "DISCOUNT") {
      if (offerItem.discount.amountType === "FIXED_AMOUNT") {
        value = getCurrencyWithPrice(offerItem.discount.amount);
      } else {
        value = `${offerItem.discount.amount}%`;
      }
    } else {
      if (offerItem?.products) {
        value = offerItem?.products
          .map((data: any) => capitalize(data.name))
          .join(", ");
      }
    }
    return value;
  };

  return (
    <>
      <OfferWrapper>
        {/* <button onClick={() => {}}>{"Add Offer"}</button> */}
        <div>
          {!isEmpty(selectedOffers) &&
            selectedOffers.map((offer: any) => {
              let period = "";
              if (offer.isEveryDay) {
                period = `Every Day ${offer.offerTime.startTime} - ${offer.offerTime.endTime}`;
              } else {
                period = `${offer.offerDate} ${offer.offerTime.startTime} - ${offer.offerTime.endTime}`;
              }
              return (
                <SelectedOfferWrapper>
                  <div>
                    <div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickRemove(offer);
                        }}
                      >
                        <img src={IconDelete} />
                      </div>
                      <div>
                        <div>{offer.title}</div>
                        <div>{period}</div>
                      </div>
                    </div>
                    <div>{offer.displayTitle}</div>
                  </div>
                  <div />
                </SelectedOfferWrapper>
              );
            })}
        </div>
        {!isEmpty(offers) &&
          offers.map((offer: any) => {
            let period = "";
            if (offer.isEveryDay) {
              period = `Every Day ${offer.timePeriod.startTime} - ${offer.timePeriod.endTime}`;
            } else {
              period = `${offer.timePeriods[0].date} ${offer.timePeriods[0].startTime} - ${offer.timePeriods[0].endTime}`;
            }
            const isActive = getActiveOffer(offer);
            const isSelected = offer.id === selectedItem?.id;
            return (
              <OfferDataWrapper>
                <div onClick={() => onSelectOffer(offer)}>
                  <div>
                    <div>{offer.title}</div>
                    <div>{period}</div>
                  </div>
                  <OfferApplyButton
                    disabled={!isActive}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onAddOffer(offer);
                    }}
                  >
                    Apply
                  </OfferApplyButton>
                </div>
                {isSelected &&
                  selectedItem.offerItems.map((item: any) => {
                    let value = getOfferItem(item);
                    return (
                      <OfferItemWrapper>
                        <div>
                          <div>{capitalize(item.name)}</div>
                          <div>{period}</div>
                        </div>
                        <div>{value}</div>
                      </OfferItemWrapper>
                    );
                  })}
                <div />
              </OfferDataWrapper>
            );
          })}
      </OfferWrapper>

      <AddOfferModal
        offer={selectedItem}
        isOpen={isOpenOfferModal}
        closeModal={() => toggleOfferModal()}
      />
    </>
  );
};
export default connect(
  (state: any) => ({
    offer: state.offer.get("offer"),
    shopData: state.login.get("merchantShop"),
    orderOffers: state.offer.get("orderOffers"),
    productList: state.product.get("productList"),
    purchaseProducts: state.product.get("purchaseProducts"),
  }),
  {
    setOrderOffer: Actions.offer.setOrderOffer,
    deleteOrderOffer: Actions.offer.deleteOrderOffer,
    setOfferProducts: Actions.product.setOfferProducts,
  }
)(OfferView);
