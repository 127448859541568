import styled from "styled-components";
import BaseColors from "../../internal/helpers/BaseColors";

export const IconSearchContainer = styled.div<{ height: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  & > img: first-child {
       height: 20px;
       width: 20px;
       margin-right: 10px;
  }
    
  & > input {
    width: 100%;
    height: 45px;
    border: none;
    color: #000000;
    font-size: 14px;
    padding-right: 20px;
    font-family: Montserrat;
font-weight: 500;
  }

  &> button {
      height: 29px;
      width: 124px;
      border: 1px solid #8A9CA5;
      border-radius: 8px;
      background-color: ${BaseColors.white}
      color: #000000;
      font-size: 12px;
      font-family: Montserrat;
font-weight: 500;

      :focus {
         outline: none;
      }
  }
`;

export const TagSearchContainer = styled.div<{ isHide: boolean, visibleFilter: boolean }>`
  width: 100%;
  height: 48px;
  display: flex;
  margin-bottom: 17px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${BaseColors.whisper};

  &> div: first-child {
    height: 100%;
    display: flex;
    flex-direction: row;
    width: ${props => props.isHide ? '80%' : '40px'};
    
    &> div: first-child {
      width: 100%;
      display: flex;
      height: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      
      &> div: first-child {
        width: 100%;
        display: flex;
        height: 100%;
        overflow: scroll;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        scrollbar-width: none;

        ::-webkit-scrollbar {
          display: none;
        }
      }
    }

    & > img {
      width: 40px;
      height: 40px;
      display: flex;
      cursor: pointer;
      padding: 7.5px;
      background-color: ${props => props.visibleFilter ? BaseColors.pictonBlue : 'transparent'};
    }
  }

  &> div: nth-child(2) {
    width: ${props => props.isHide ? '50%' : '80%'};
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    
    & > input {
      width: 100%;
      height: 80%;
      border: none;
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 8px;
      font-family: Montserrat;
      background-color: ${BaseColors.white};
    }

    & > img {
      width: 25px;
      height: 25px;
      cursor: pointer;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;

export const TagButton = styled.button<{ isSelected: boolean, isALL: boolean }>`
        height: 100%;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        border-top: unset;
        border-left: unset;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: unset;
        font-family: Montserrat;
        border-right: 1px solid #8A9CA550;
        border-left: ${props => props.isALL ? '1px solid #8A9CA550' : 'unset'};

        color: ${props => props.isSelected ? BaseColors.white : BaseColors.black};
        background-color: ${props => props.isSelected ? BaseColors.pictonBlue : 'tranparent'};

        :hover {
          color: ${BaseColors.white};
          background-color: ${BaseColors.cornflowerBlue}
        }
        :focus {
          outline: none;
        }
`;

export const FilterContainer = styled.div<{ paddingRight: string }>`
  z-index: 100;
  width: 165px;
  display: flex;
  margin-left: 13px;
  position: absolute;
  align-items: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    width: 196px;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);

    :hover {
      cursor: pointer;
    }

    :focus {
      background-color: #49a4d4;
    }

    & > p {
      width: 100%;
      font-family: Montserrat;
      font-weight: normal;
      font-style: normal;
      color: #262626;
      font-size: 14px;
      padding: 12px 16px;
      margin: 0px;

      :hover {
        border-radius: 3px;
        background-color: ${BaseColors.solitude};
      }
    }

    & > div {
      width: 100%;
      height: 0.5px;
      align-items: center;
      background-color: #f5f5f5;
      border: none;
    }
  }
`;