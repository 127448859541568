import mixpanel from "mixpanel-browser";

const MixpanelService = () => {
  return {
    init: () => {
      mixpanel.init("260e09d688a836d5e12362b58c38e7c2", { debug: true });
    },
    setProfile: (merchant) => {
      mixpanel.identify(merchant.shopId);
      mixpanel.people.set({
        ...merchant,
      });
    },
    setEmployee: (employee) => {
      mixpanel.people.set({
        ...employee,
      });
    },
    track: (tag, data) => {
      mixpanel.track(tag, data);
    },
  };
};

export default MixpanelService();


export const MP_EVENTS = {
  OFFLINE_ERROR_COMPLETE_ORDERS: 'OFFLINE_ERROR_COMPLETE_ORDERS',
  OFFLINE_ERROR_INCOMPLETE_ORDERS: 'OFFLINE_ERROR_INCOMPLETE_ORDERS',
  OFFLINE_MODE: 'OFFLINE_MODE',
  ONLINE_MODE: 'ONLINE_MODE',
}