import React, { useEffect, useState } from "react";
import moment from "moment";
import { Row, Col } from "react-grid-system";
import {
  getCurrencyWithPrice,
  getOfferPrice,
  getTransactionSubTotal,
} from "../../../internal/manager/PaymentManager";
import styles from "../../../components/Styles/Styles.css";
import IconCash from "../../../../assets/svg/IconCash.svg";
import IconCreditCard from "../../../../assets/svg/IconCard.svg";
import TransactionTableHeader from "../views/TransactionTableHeader";
import IconPaymentSplit from "../../../../assets/svg/PaymentSplit.svg";
import _, { get, capitalize, startCase, groupBy, isEmpty } from "lodash";

const TransactionDetails = (props) => {
  const { selectedTransaction, shopData } = props;
  const [name, setName] = useState<string>("");
  const [refunds, setRefunds] = useState<any>([]);
  const [type, setType] = useState<string>("CASH");
  const [services, setServices] = useState<any>([]);
  const [discounts, setDiscounts] = useState<any>([]);
  const [offerPrice, setOfferPrice] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<string>("0.00");
  const [groupProducts, setGroupProducts] = useState<any>({});
  const [totalPrice, setTotalPrice] = useState<string>("0.00");

  useEffect(() => {
    if (selectedTransaction) {
      setType(_.get(selectedTransaction, "paymentMethod", "CASH"));
      setName(
        `${selectedTransaction.firstName} ${selectedTransaction.lastName}`
      );
      const txRefunds = _.get(selectedTransaction, "refunds", []);
      const service = _.get(selectedTransaction, "serviceDetails", []);
      const txProducts = _.get(selectedTransaction, "productDetails", []);
      const txDiscounts = _.get(selectedTransaction, "discountDetails", []);
      const currencyPrice = getCurrencyWithPrice(
        selectedTransaction.totalPrice,
        shopData.currency
      );
      const subTotalPrice = getCurrencyWithPrice(
        getTransactionSubTotal(service, txProducts),
        shopData.currency
      );
      const groupProducts = groupBy(txProducts, "id");
      const offerPrice = getOfferPrice(selectedTransaction.offerDetails);
      setServices(service);
      setRefunds(txRefunds);
      setDiscounts(txDiscounts);
      setOfferPrice(offerPrice);
      setSubTotal(subTotalPrice);
      setTotalPrice(currencyPrice);
      setGroupProducts(groupProducts);
    } else {
      setType("");
      setName("");
      setServices([]);
      setDiscounts([]);
      setSubTotal("0.00");
      setGroupProducts([]);
      setTotalPrice("0.00");
    }
  }, [selectedTransaction, shopData]);

  const getIcon = (paymentMethod) => {
    switch (paymentMethod) {
      case "CASH": {
        return IconCash;
      }
      case "CARD": {
        return IconCreditCard;
      }
      case "SPLIT": {
        return IconPaymentSplit;
      }
      default: {
        return IconCash;
      }
    }
  };

  const today = moment(selectedTransaction.date).format("Do MMMM YYYY");
  const paidAmount = getCurrencyWithPrice(
    selectedTransaction.paidPrice,
    shopData.currency
  );
  const splitPayments = _.get(selectedTransaction, "splitPayments", []);

  return (
    <Row style={{ margin: 0, width: "100%" }}>
      <Col style={{ padding: 0 }} xs={12}>
        <div className={styles.transactionDetailsTodayWrapper}>
          <div>
            <div
              className={styles.transactionDetailsTodayBoldText}
            >{`${capitalize(type)} Payment`}</div>
            <div
              className={styles.transactionDetailsTodayText}
            >{` on ${today}`}</div>
          </div>
          <div>
            <div className={styles.transactionDetailsTodayBoldText}>
              Reciept:{" "}
            </div>
            <div className={styles.transactionDetailsTodayBoldText}>
              {selectedTransaction.recieptId}
            </div>
          </div>
        </div>
      </Col>

      <Col style={{ padding: 0 }} xs={12}>
        <div className={styles.transactionDetailsPriceWrapper}>
          <div className={styles.transactionDetailsPriceWrapperInfo}>
            <img src={getIcon(type)} />
            <div className={styles.transactionDetailsPriceValue}>
              {capitalize(type)}
            </div>
          </div>
          <div className={styles.transactionDetailsPriceText}>{totalPrice}</div>
        </div>
      </Col>

      {selectedTransaction.clientId ? (
        <>
          <Col style={{ padding: 0, width: "100%" }} xs={12}>
            <TransactionTableHeader
              filter={false}
              title={"Customer information"}
            />
          </Col>
          <Col style={{ padding: 0 }} xs={12}>
            <div className={styles.transactionDetailsNameWrapper}>
              <div className={styles.transactionDetailsName}>
                {_.startCase(name)}
              </div>
              <div className={styles.transactionDetailsMobile}>
                {selectedTransaction.mobileNumber}
              </div>
            </div>
          </Col>
        </>
      ) : null}

      <Col style={{ padding: 0 }} xs={12}>
        <TransactionTableHeader
          filter={false}
          title={"Services and products"}
        />
      </Col>

      <Col style={{ padding: 0 }} xs={12}>
        {services?.map((item) => {
          const price = getCurrencyWithPrice(
            item.price,
            shopData.currency,
            false
          );
          let character = _.get(item, "title", "serviceTitle").match(/\b(\w)/g);
          let nameIcon = _.toUpper(character[0]) + _.toUpper(character[1]);

          return (
            <div className={styles.transactionDetailsServiceWrapper}>
              <div className={styles.transactionDetailsImageWrapper}>
                <div className={styles.transactionDetailsImageText}>
                  {nameIcon}
                </div>
              </div>
              <div className={styles.transactionDetailsServiceWrapperInfo}>
                <div className={styles.transactionDetailsServiceText}>
                  {startCase(item.title || item.serviceTitle)}
                </div>
                <div className={styles.transactionDetailsServiceText}>
                  {price}
                </div>
              </div>
            </div>
          );
        })}
      </Col>
      <Col style={{ padding: "15px" }} xs={12}>
        {Object.keys(groupProducts).map((key) => {
          const productName = get(groupProducts[key], "[0].productName", "");
          const isOptions = !isEmpty(
            get(groupProducts[key], "[0].priceVariants", null)
          );
          return (
            <div className={styles.transactionDetailsProductWrapper}>
              {groupProducts[key] &&
                groupProducts[key].map((item: any) => {
                  let title: string;
                  let character: any;
                  let optionName: string = "";
                  let modifierNames: string = "";
                  const price = getCurrencyWithPrice(
                    item.sellingPrice,
                    shopData.currency,
                    false
                  );
                  const productOptionName = get(
                    item,
                    "priceVariants[0].optionUnit",
                    ""
                  );
                  title = startCase(get(item, "productName", ""));
                  if (isOptions) {
                    optionName = get(item, "priceVariants[0].optionUnit", "");
                    character = productOptionName.match(/\b(\w)/g);
                  } else {
                    character = title.match(/\b(\w)/g);
                  }
                  let nameIcon =
                    _.toUpper(character[0]) + _.toUpper(character[1]);
                  if (!isEmpty(item?.modifiers)) {
                    modifierNames = item.modifiers
                      .map((modifier: any) =>
                        modifier.items?.map(
                          (item) => `${item.name} X ${item.quantity}`
                        )
                      )
                      .join(", ");
                  }
                  return (
                    <div
                      className={styles.transactionDetailsProductItemWrapper}
                    >
                      <div className={styles.transactionDetailsImageWrapper}>
                        <div className={styles.transactionDetailsImageText}>
                          {nameIcon}
                        </div>
                      </div>
                      <div
                        className={styles.transactionDetailsServiceWrapperInfo}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div className={styles.transactionDetailsServiceText}>
                            {title}
                          </div>
                          {isOptions && (
                            <div
                              className={
                                styles.transactionDetailsServiceOptionText
                              }
                            >
                              {` - ${optionName} x ${item.quantity}`}
                            </div>
                          )}
                          {!isEmpty(modifierNames) && (
                            <div
                              className={
                                styles.transactionDetailsServiceOptionText
                              }
                            >
                              {` - ${modifierNames}`}
                            </div>
                          )}
                        </div>
                        <div className={styles.transactionDetailsServiceText}>
                          {price}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </Col>

      <Col style={{ padding: 0 }} xs={12}>
        <TransactionTableHeader filter={false} title={"Bill information"} />
      </Col>

      <Col style={{ padding: 0 }} xs={12}>
        <div className={styles.transactionDetailsBillWrapper}>
          {services?.map((item: any) => {
            const price = getCurrencyWithPrice(
              item.totalPrice,
              shopData.currency,
              false
            );
            return (
              <div className={styles.transactionDetailsBillWrapperInfo}>
                <div className={styles.transactionDetailsBillServiceText}>
                  {startCase(
                    `${item.title || item.serviceTitle} x ${item.quantity}`
                  )}
                </div>
                <div className={styles.transactionDetailsBillServiceText}>
                  {price}
                </div>
              </div>
            );
          })}
          {Object.keys(groupProducts).map((key) => {
            const productName = get(groupProducts[key], "[0].productName", "");
            const isOptions = !isEmpty(
              get(groupProducts[key], "[0].priceVariants", null)
            );
            return (
              <div className={styles.transactionDetailsBillHeaderWrapper}>
                {groupProducts[key] &&
                  groupProducts[key].map((item: any) => {
                    let title: string;
                    let optionName: string = "";
                    let modifierNames: string = "";
                    const price = getCurrencyWithPrice(
                      item.totalPrice,
                      shopData.currency,
                      false
                    );
                    title = startCase(get(item, "productName", ""));

                    if (isOptions) {
                      optionName = get(item, "priceVariants[0].optionUnit", "");
                    }
                    if (!isEmpty(item?.modifiers)) {
                      modifierNames = item.modifiers
                        .map((modifier: any) =>
                          modifier?.items?.map(
                            (item) => `${item.name} X ${item.quantity}`
                          )
                        )
                        .join(", ");
                    }

                    return (
                      <div className={styles.transactionDetailsBillWrapperInfo}>
                        <div className={styles.transactionProductItemWrapper}>
                          <div
                            className={styles.transactionDetailsBillServiceText}
                          >
                            {title}
                          </div>
                          {isOptions && (
                            <div
                              className={
                                styles.transactionDetailsServiceOptionText
                              }
                            >
                              {` - ${optionName} x ${item.quantity}`}
                            </div>
                          )}
                          {!isEmpty(modifierNames) && (
                            <div
                              className={
                                styles.transactionDetailsServiceOptionText
                              }
                            >
                              {` - ${modifierNames}`}
                            </div>
                          )}
                        </div>
                        <div
                          className={styles.transactionDetailsBillServiceText}
                        >
                          {price}
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
          <hr />
          {
            <div className={styles.transactionDetailsBillWrapperInfo}>
              <div className={styles.transactionDetailsBillServiceTotalText}>
                Sub Total
              </div>
              <div className={styles.transactionDetailsBillServiceTotalText}>
                {subTotal}
              </div>
            </div>
          }

          {discounts?.map((item) => {
            const price = getCurrencyWithPrice(
              item.amount,
              shopData.currency,
              false
            );
            return (
              <div className={styles.transactionDetailsBillWrapperInfo}>
                <div
                  className={styles.transactionDetailsBillServiceText}
                >{`${item.title}  ${item.percentage}%`}</div>
                <div className={styles.transactionDetailsBillServiceText}>
                  ({price})
                </div>
              </div>
            );
          })}

          {refunds?.map((item) => {
            const price = getCurrencyWithPrice(
              item.amount,
              shopData.currency,
              false
            );
            return (
              <div className={styles.transactionDetailsBillWrapperInfo}>
                <div
                  className={styles.transactionDetailsBillRefundText}
                >{`${_.upperFirst(_.toLower(item.reason))}`}</div>
                <div className={styles.transactionDetailsBillRefundText}>
                  ({price})
                </div>
              </div>
            );
          })}

          <div className={styles.transactionDetailsBillWrapperInfo}>
            <div className={styles.transactionDetailsBillServiceText}>
              {"Offers"}
            </div>
            <div className={styles.transactionDetailsBillServiceText}>
              -{getCurrencyWithPrice(`${offerPrice}`, shopData.currency)}
            </div>
          </div>

          <div className={styles.transactionDetailsBillWrapperInfo}>
            <div className={styles.transactionDetailsBillServiceText}>
              {"Service Charges"}
            </div>
            <div className={styles.transactionDetailsBillServiceText}>
              {getCurrencyWithPrice(
                _.get(selectedTransaction, "serviceCharge.amount", 0.0),
                shopData.currency
              )}
            </div>
          </div>

          <div className={styles.transactionDetailsBillWrapperInfo}>
            <div className={styles.transactionDetailsBillServiceTotalText}>
              {"Total"}
            </div>
            <div className={styles.transactionDetailsBillServiceTotalText}>
              {totalPrice}
            </div>
          </div>
          <hr />
          <div className={styles.transactionDetailsBillWrapperInfo}>
            <div className={styles.transactionDetailsBillServiceTotalText}>
              {"Paid Amount"}
            </div>
            <div className={styles.transactionDetailsBillServiceTotalText}>
              {paidAmount}
            </div>
          </div>
          {type === "SPLIT"
            ? splitPayments.map((item) => (
                <div className={styles.transactionDetailsBillWrapperInfo}>
                  <div className={styles.transactionDetailsBillServiceText}>
                    {capitalize(item.method)}
                  </div>
                  <div className={styles.transactionDetailsBillServiceText}>
                    {getCurrencyWithPrice(item.amount, shopData.currency)}
                  </div>
                </div>
              ))
            : null}
        </div>
      </Col>
    </Row>
  );
};
export default TransactionDetails;
