import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import {SalesReportCardContainer} from '../Styles/Styled';
import {getCurrencyWithPrice} from "../../internal/manager/PaymentManager";

const SalesReportCard = (props) => {
  const {shop, type, reportsTotal} = props;
  const [header, setHeader] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [title, setTitle] = useState('');

  const setComponentHeader = () => {
    switch (type) {
      case 'SALE':
        return setHeader('Gross Sales');
      case 'PRODUCT':
        return setHeader('Sold Products');
      case 'APPOINTMENT':
        return setHeader('Total Online');
      default:
        return;
    }
  };

  const setComponentTitle = () => {
    switch (type) {
      case 'SALE': {
        const totalSalesPrice = _.get(reportsTotal, 'totalSalesPrice', 0);
        const salesPrice = getCurrencyWithPrice(totalSalesPrice, shop.currency);
        setTotalCount(salesPrice);
        return setTitle(`Total Transactions: ${_.get(reportsTotal, 'totalSalesCount', 0)}`);
      }
      case 'PRODUCT': {
        const totalProductPrice = _.get(reportsTotal, 'totalProductPrice', 0);
        const productPrice = getCurrencyWithPrice(totalProductPrice, shop.currency);
        setTotalCount(_.get(reportsTotal, 'totalProductCount', 0));
        return setTitle(`Product Sales: ${productPrice}`);
      }
      case 'APPOINTMENT': {
        const totalServicePrice = _.get(reportsTotal, 'totalServicePrice', 0);
        const servicePrice = getCurrencyWithPrice(totalServicePrice, shop.currency);
        setTotalCount(_.get(reportsTotal, 'totalServiceCount', 0));
        return setTitle(`Online ${servicePrice}`);
      }
      default: {
        return;
      }
    }
  };

  useEffect(() => {
    setComponentHeader();
  }, [type]);

  useEffect(() => {
    setComponentTitle();
  }, [reportsTotal]);

  return (
    <SalesReportCardContainer>
      <div>{header}</div>
      <div>{totalCount}</div>
      <div>{title}</div>
    </SalesReportCardContainer>

  );
};
export default connect(state =>
    ({
      shop: state.login.get('merchantShop'),
      reportsTotal: state.reports.get('reportsTotal'),
    }),
  ({}),
)(SalesReportCard);
