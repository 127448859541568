import React, { useEffect, useState } from "react";
import {
  WarrantyForm,
  ProductOptionWrapper,
  WarrantyTitleWrapper,
  WarrantyButtonWrapper,
  WarrantyDurationWrapper,
  ProductOptionFormWrapper,
  ProductOptionHeaderWrapper,
} from "./Styled";
import * as Yup from "yup";
import "antd/dist/antd.css";
import "../Styles.style.css";
import { nanoid } from "nanoid";
import Select from "react-select";
import { WarrantyProps } from "../Types";
import { connect } from "react-redux/es/index";
import _, { capitalize, isEmpty } from "lodash";
import { Formik, useFormikContext } from "formik";
import { Actions } from "../../../internal/app/Actions";
import BackIcon from "../../../../assets/svg/backIcon.svg";
import { navigate } from "../../../internal/service/Navigation.service";
import { getWarrantyDuration } from "../../../internal/manager/ProductManager";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";

const style = {
  control: (base, state) => ({
    ...base,
    width: "187px",
    height: "45px",
    paddingRight: "20px",
    border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    "&:hover": {
      border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    },
    fontWeight: 500,
    fontSize: "14px",
    color: "#434343",
    paddingLeft: "5px",
    borderRadius: "6px",
    fontFamily: "Montserrat",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
  }),
  menu: (base, state) => ({
    ...base,
    width: "544px",
  }),
};

const WarrantySchema = Yup.object({
  title: Yup.string()
    .min(2, "Must be 2 characters or more")
    .required("Please enter warranty title"),
  durationType: Yup.string().required("Please enter warranty duration type"),
  duration: Yup.number()
    .min(1, "Please enter a valid duration")
    .typeError("Please enter a valid duration")
    .required("Please enter warranty duration"),
  // .when("durationType", {
  //   is: "DAYS",
  //   then: Yup.number().test("Days", "Please enter valid day count", (val) => {
  //     if (val == undefined) {
  //       return true;
  //     }
  //     return parseInt(val) != 0 || parseInt(val) <= 31;
  //   }),
  // }),
});

const AddWarrantyView = (props: any) => {
  const { productWarranty, setProductWarranty } = props;
  const initials = {
    title: "",
    duration: 0,
    wrid: nanoid(),
    description: "",
    durationType: "MONTHS",
    createdTime: Date.now(),
    updatedTime: Date.now(),
  };
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<WarrantyProps>(initials);

  useEffect(() => {
    setIsEdit(!isEmpty(productWarranty));
  }, [productWarranty]);

  const handleSubmit = (values: any, { resetForm }: any) => {
    setProductWarranty(values);
    resetForm();
  };

  return (
    <div className="addProductOptionWrapper">
      <TopNavigationBar viewName="Products" path="/menu" />
      <ProductOptionWrapper>
        <ProductOptionHeaderWrapper>
          <div onClick={() => navigate("/add-product")}>
            <img src={BackIcon} />
            <div>{isEdit ? "Edit Warranty" : "Add Warranty"}</div>
          </div>
        </ProductOptionHeaderWrapper>

        <ProductOptionFormWrapper>
          <Formik
            onSubmit={handleSubmit}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={WarrantySchema}
          >
            {({ errors, values, submitCount, handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <RenderInput warranty={productWarranty} />
                    <WarrantyButtonWrapper>
                      <div>
                        <div />
                        <div>
                          {!isEmpty(productWarranty) && (
                            <button onClick={() => {}}>{"Remove"}</button>
                          )}
                          <button type="submit">
                            {isEdit ? "Edit Warranty" : "Add Warranty"}
                          </button>
                        </div>
                      </div>
                    </WarrantyButtonWrapper>
                  </div>
                </form>
              );
            }}
          </Formik>
        </ProductOptionFormWrapper>
      </ProductOptionWrapper>
    </div>
  );
};

const RenderInput = ({ warranty }: any) => {
  const { values, errors, touched, submitCount, handleChange, setFieldValue } =
    useFormikContext<{
      title: string;
      duration: string;
      description: string;
      durationType: string;
    }>();

  useEffect(() => {
    if (!isEmpty(warranty)) {
      setFieldValue("wrid", warranty.wrid);
      setFieldValue("title", warranty.title);
      setFieldValue("duration", warranty.duration);
      setFieldValue("description", warranty.description);
      setFieldValue("createdTime", warranty.createdTime);
      setFieldValue("durationType", warranty.durationType);
    }
  }, [warranty]);

  return (
    <WarrantyForm>
      <WarrantyDurationWrapper>
        <div>Warranty Duration</div>
        <div>
          <div>
            <input
              name="duration"
              placeholder="duration"
              value={values.duration}
              onChange={(event: any) => {
                let number = event.target.value;
                setFieldValue(`duration`, number);
              }}
            />
            {!isEmpty(errors?.duration) && submitCount > 0 && (
              <span>{errors.duration}</span>
            )}
          </div>
          <div>
            <Select
              styles={style}
              name="durationType"
              className="basic-single"
              classNamePrefix="select"
              options={getWarrantyDuration}
              value={{ label: capitalize(values.durationType) }}
              placeholder={
                <div className="categoryPlaceholderText">durationType</div>
              }
              onChange={(data: any) =>
                setFieldValue(`durationType`, data.value)
              }
            />
            {!isEmpty(errors?.durationType) && submitCount > 0 && (
              <span>{errors.durationType}</span>
            )}
          </div>
        </div>
      </WarrantyDurationWrapper>

      <WarrantyTitleWrapper>
        <div>
          <div>Title</div>
          <div>
            <input
              name="title"
              placeholder="Title"
              value={values.title}
              onChange={(event: any) => {
                let text = event.target.value;
                setFieldValue(`title`, text);
              }}
            />
            {!isEmpty(errors?.title) && submitCount > 0 && (
              <span>{errors.title}</span>
            )}
          </div>
        </div>
        <div>
          <div>Description</div>
          <div>
            <textarea
              name="description"
              value={values.description}
              className="serviceTextAreaInput"
              placeholder="Description"
              onChange={(event: any) => {
                let text = event.target.value;
                setFieldValue(`description`, text);
              }}
            />
            {!isEmpty(errors?.description) && submitCount > 0 && (
              <span>{errors.description}</span>
            )}
          </div>
        </div>
      </WarrantyTitleWrapper>
    </WarrantyForm>
  );
};

export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    optionSets: state.product.get("optionSets"),
    currentUser: state.login.get("currentUser"),
    selectedProduct: state.product.get("selectedProduct"),
    productCategoryList: state.product.get("productCategoryList"),
    productWarranty: state.warranty.get("productWarranty"),
  }),
  {
    createProduct: Actions.product.createProduct,
    updateProduct: Actions.product.updateProduct,
    deleteProduct: Actions.product.deleteProduct,
    setProductWarranty: Actions.warranty.setProductWarranty,
  }
)(AddWarrantyView);
