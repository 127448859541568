import React, { useEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";
import "antd/dist/antd.css";
import { connect } from "react-redux/es/index";
import { Actions } from "../../../internal/app/Actions";
import EditProductItemTopHeader from "../../../components/product/views/EditProductItemTopHeader";
import _, { isEmpty } from "lodash";
import ServiceWarningModal from "../../../components/model/views/ServiceWarningModal";
import { navigate } from "../../../internal/service/Navigation.service";
import { nanoid } from "nanoid";

const EditRoomTypeView = (props) => {
  const {
    shop,
    createRoomType,
    editRoomTypes,
    selectedRoomType,
  }: any = props;

  const [isOpenDiscardModel, setIsOpenDiscardModel] = useState(false);

  const [title, setTypeTitle] = useState("");
  const [desc, setDescription] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(selectedRoomType)) {
      setTypeTitle(selectedRoomType.title);
      setDescription(selectedRoomType.desc);
      setBasePrice(selectedRoomType.basePrice);
    } else {
      setTypeTitle('');
      setDescription('');
      setBasePrice('');
    }
  }, [selectedRoomType]);

  const isEdit = !_.isEmpty(selectedRoomType);

  const onCreateRoomType = () => {
    let params = {
      title,
      desc,
      rtId: nanoid(8),
      shopId: shop.shopId,
      basePrice,
    };
    if (isEdit) {
      params.rtId = selectedRoomType.rtId;
      editRoomTypes(params);
      // createRoomType(param);
    } else {
      // param.createdTime = Date.now();
      createRoomType(params);
    }
  };

  const onDeleteBrand = () => {
    // const param = {
    //   id: selectedBrand.id,
    //   shopId: shop.shopId,
    // };
    // deleteBrand(param);
  };

  const isValid = !isEmpty(title) && !isEmpty(basePrice);

  return (
    <div className="addCategoryMainContainer">
      <TopNavigationBar viewName="Products" path="/menu" />
      <div className="addServiceViewContainer">
        <div className="addServiceViewWrapper">
          <EditProductItemTopHeader
            onCreateService={() => {
              if (isValid) {
                onCreateRoomType();
              }
            }}
            isEdit={isEdit}
            onBack={() => {
              if (isEdit) {
                setIsOpenDiscardModel(true);
              } else {
                navigate("/rooms");
              }
            }}
            isValid={isValid}
            buttonText={"Save Room Type"}
            backBtnText={isEdit ? "Edit Room Type" : "Add Room Type"}
          />
          <Row>
            <Col xs={12}>
              <div className="serviceAddFormContainer">
                <div className="addServiceDetailsText">Room Type Details</div>
                <div className="addCategoryInputContainer">
                  <Row>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">Room Type Title*</div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input
                        onChange={(text) => setTypeTitle(text.target.value)}
                        value={title}
                        className="serviceTextInput"
                        placeholder="Delux Rooms"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">Room Base Price*</div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input
                        onChange={(text) => setBasePrice(text.target.value)}
                        value={basePrice}
                        className="serviceTextInput"
                        placeholder="200,000"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "30px" }}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">Description</div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <textarea
                        onChange={(text) => setDescription(text.target.value)}
                        value={desc}
                        className="serviceTextAreaInput"
                        placeholder="Description"
                      />
                    </Col>
                  </Row>
                  {isEdit ? (
                    <Row style={{ marginTop: "20px" }}>
                      <Col xs={2} className="serviceTitleCol" />
                      <Col xs={10} className="serviceTitleColInput">
                        <div
                          onClick={() => {
                            setIsOpenDeleteModel(true);
                          }}
                          className="categoryDeleteBtnContainer"
                          style={
                            {
                              // backgroundColor: isHasntProducts
                              //   ? "#FFD6D6"
                              //   : "#8A9CA5",
                              // border: isHasntProducts
                              //   ? "1px solid #FF5F5F"
                              //   : "1px solid #8A9CA5",
                            }
                          }
                        >
                          <div
                            className="serviceDeleteBtnText"
                            style={
                              {
                                // color: isHasntProducts ? "#FF5F5F" : "#ffffff",
                              }
                            }
                          >
                            Delete Room Type
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ServiceWarningModal
        isOpen={isOpenDiscardModel}
        headerText={"Are you sure?"}
        bodText={"All your changes will be discarded"}
        cancelBtnText={"Discard"}
        onCancel={() => {
          setIsOpenDiscardModel(false);
          navigate("/rooms");
        }}
        closeModal={() => setIsOpenDiscardModel(false)}
      />
      <ServiceWarningModal
        isOpen={isOpenDeleteModel}
        headerText={"Warning!"}
        bodText={
          "Your brand will be deleted and no longer\n available to order for the users"
        }
        cancelBtnText={"Delete"}
        onCancel={() => {
          onDeleteBrand();
          setIsOpenDeleteModel(false);
        }}
        closeModal={() => setIsOpenDeleteModel(false)}
      />
    </div>
  );
};

const EditRoomType = connect(
  (state) => ({
    shop: state.login.get("merchantShop"),
    selectedRoomType: state.rooms.get("selectedRoomType"),
    currentUser: state.login.get("currentUser"),
  }),
  {
    deleteRoom: Actions.rooms.deleteRoom,
    createRoomType: Actions.rooms.createRoomType,
    editRoomTypes: Actions.rooms.editRoomTypes,
  }
)(EditRoomTypeView);

export default EditRoomType;
