import _, { filter, lowerCase } from "lodash";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { nanoid } from "nanoid";

export const mapCategory = (categoryList) => {
  const result = categoryList.map((category) => {
    return {
      value: category.category,
      label: category.category,
      id: category.id,
      createdTime: category.createdTime,
      isPrimary: category.isPrimary,
      services: category.services,
      specializations: category.specializations,
    };
  });

  return result;
};

export const mapDuration = (duration) => {
  const removeHText = duration && duration.replace("h", "");
  const removeMinText = removeHText && removeHText.replace("min", "");

  return removeMinText;
};

export const mapCreateService = (
  categories,
  selectedCategory,
  duration,
  price,
  serviceTitle,
  description,
  serviceImageId
) => {
  const index = _.findIndex(
    categories.categories,
    (item: any) => item.id === selectedCategory.id
  );
  let selectedServiceData = selectedCategory.services || [];
  let categoryList = categories.categories;
  const hours = moment(duration).format("HH");
  const minutes = moment(duration).format("mm");

  selectedServiceData.push({
    duration: `${hours}h:${minutes}min`,
    id: uuid(),
    price,
    serviceTitle,
    description,
    image: serviceImageId,
  });

  if (index > -1) {
    categoryList[index].services = selectedServiceData;
  }

  return {
    scid: categories.scid,
    categories: categoryList,
  };
};

export const mapUpdateService = (
  selectedCategoryService,
  categories,
  selectedCategory,
  duration,
  price,
  serviceTitle,
  description,
  serviceImageId
) => {
  const { service, category } = selectedCategoryService;
  let categoryList = categories.categories;
  const categoryIndex = _.findIndex(
    categoryList,
    (item: any) => item.id === selectedCategory.id
  );
  let categoryServiceData = _.get(selectedCategory, "services", []);
  const hours = moment(duration).format("HH");
  const minutes = moment(duration).format("mm");
  const serviceIndex = _.findIndex(
    categoryServiceData,
    (item: any) => item.id === service.id
  );

  if (category.id !== selectedCategory.id) {
    const beforeCategoryIndex = _.findIndex(
      categoryList,
      (item: any) => item.id === category.id
    );
    if (beforeCategoryIndex > -1) {
      const removedServiceIndex = _.findIndex(
        categoryList[beforeCategoryIndex].services,
        (item: any) => item.id === service.id
      );
      if (removedServiceIndex > -1) {
        categoryList[beforeCategoryIndex].services.splice(
          removedServiceIndex,
          1
        );
      }
    }

    if (categoryIndex > -1) {
      categoryServiceData.push({
        duration: `${hours}h:${minutes}min`,
        id: service.id,
        price,
        serviceTitle,
        description,
        specializations: service.specializations,
        isPrimary: service.service,
        image: serviceImageId,
      });
    }
  }

  if (serviceIndex > -1) {
    categoryServiceData[serviceIndex].duration = `${hours}h:${minutes}min`;
    categoryServiceData[serviceIndex].price = price;
    categoryServiceData[serviceIndex].serviceTitle = serviceTitle;
    categoryServiceData[serviceIndex].description = description;
    categoryServiceData[serviceIndex].image = serviceImageId;
  }

  if (categoryIndex > -1) {
    categoryList[categoryIndex].services = categoryServiceData;
  }

  return {
    scid: categories.scid,
    categories: categoryList,
  };
};

export const mapDeleteService = (selectedCategoryService, categories) => {
  const { service, category } = selectedCategoryService;
  let categoryList = categories.categories;
  const categoryIndex = _.findIndex(
    categoryList,
    (item: any) => item.id === category.id
  );
  let categoryServiceData = category.services;
  if (categoryIndex > -1) {
    const removedServiceIndex = _.findIndex(
      categoryList[categoryIndex].services,
      (item: any) => item.id === service.id
    );

    if (removedServiceIndex > -1) {
      categoryList[categoryIndex].services.splice(removedServiceIndex, 1);
    }
  }

  // if (categoryIndex > -1) {
  //   categoryList[categoryIndex].services = categoryServiceData;
  // }

  return {
    scid: categories.scid,
    categories: categoryList,
  };
};

export const mapCreateCategory = (categories, categoryTitle, description) => {
  let categoryList = categories.categories;
  categoryList.push({
    category: categoryTitle,
    createdTime: Date.now(),
    id: nanoid(8),
    description,
  });

  return {
    scid: categories.scid,
    categories: categoryList,
  };
};

export const mapUpdateCategory = (
  categories,
  selectedCategoryItem,
  categoryTitle,
  description
) => {
  let categoryList = categories.categories;
  const index = _.findIndex(
    categoryList,
    (item: any) => item.id === selectedCategoryItem.id
  );

  if (index > -1) {
    categoryList[index].category = categoryTitle;
    categoryList[index].description = description;
  }

  return {
    scid: categories.scid,
    categories: categoryList,
  };
};

export const mapDeleteCategory = (selectedCategoryItem, categories) => {
  let categoryList = categories.categories;
  const index = _.findIndex(
    categoryList,
    (item: any) => item.id === selectedCategoryItem.id
  );

  if (index > -1) {
    categoryList.splice(index, 1);
  }

  return {
    scid: categories.scid,
    categories: categoryList,
  };
};

export const getFavouriteService = (categoryList) => {
  let serviceList: any = [];

  categoryList.forEach((category) => {
    const favouriteServiceList = _.filter(
      category.services,
      (item) => item.isFavourite === true
    );
    serviceList.push(favouriteServiceList);
  });

  return _.uniqBy(_.flattenDeep(serviceList), "id");
};

export const searchProductsServices = (text, categories, productList) => {
  const services = categories.reduce((services, category) => {
    const filteredServices = category?.services?.filter(({ serviceTitle }) =>
      lowerCase(serviceTitle).includes(lowerCase(text))
    );
    if (filteredServices) {
      return filteredServices.concat(services);
    }
    return services;
  }, []);

  const products = productList.filter(({ productName }) =>
    lowerCase(productName).includes(lowerCase(text))
  );
  return _.sortBy(
    [...services, ...products],
    (item) => item.serviceName || item.productName
  );
};

export const mapCreateQuickService = (
  categories,
  selectedCategory,
  price,
  serviceTitle
) => {
  const index = _.findIndex(
    categories.categories,
    (item: any) => item.id === selectedCategory.id
  );
  let selectedServiceData = selectedCategory.services || [];
  let categoryList = categories.categories;
  const id = nanoid(8);
  selectedServiceData.push({
    id,
    price,
    serviceTitle,
  });
  if (index > -1) {
    categoryList[index].services = selectedServiceData;
  }

  const params = { scid: categories.scid, categories: categoryList };
  const serviceData = { id, categoryId: selectedCategory.id };
  return { params, serviceData };
};

export const setNumberOnly = (
  value: string,
  prevValue: string,
  setOperator: (value: string) => void
): void => {
  try {
    const input = _.isFunction(value?.replace)
      ? value.replace(/[^.,\d]/g, "")
      : value;
    const parsed = parseFloat(input);
    if (isFinite(parsed)) {
      setOperator(input);
      return;
    }
  } catch (e) {
    console.warn("parse error", value, e);
  }
  const prevParsedValue = parseFloat(prevValue);
  if (value !== "" && isFinite(prevParsedValue)) {
    setOperator(prevValue);
  } else if (value === "") {
    setOperator("");
  } else {
    setOperator("0");
  }
};

export const mapMenu = (params) => {
  const { shopId, menuId, title, position, categories, updatedTime } = params;
  const menu = {
    shopId,
    menuId,
    title,
    position,
    categories: categories
      ? categories?.map(({ categoryId, position, products }) => {
          return {
            categoryId,
            position,
            products:
              products?.map(({ productId, position }) => ({
                productId,
                position,
              })) || [],
          };
        }) || []
      : [],
    updatedTime,
  };
  return menu;
};
