import React, { Component, useEffect, useState } from "react";
import styles from "../../../components/Styles/Styles.css";
import { Container, Row, Col } from "react-grid-system";
import { connect } from "react-redux";
import { Actions } from "../../../internal/app/Actions";
import SearchComponent from "../../search/SearchComponent";
import AddDiscountModal from "../../model/views/AddDiscountModal";
import IconDelete from "../../../../assets/svg/IconDiscountDelete.svg";
import _, { isArray } from "lodash";
import { getCurrencyWithPrice } from "../../../internal/manager/PaymentManager";
import { TableAreas, TableWrapper } from "./Styled";

const ShopOrderRooms = (props) => {
  const {
    shopData,
    selectedAppointment,
    incompleteOrders,
    roomAreas,
    selectRoom,
  } = props;
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [areaRooms, setAreaRooms] = useState<Array<any>>([]);

  useEffect(() => {
    // if (selectedAppointment.tableOrder) {
    const orderRmId = selectedAppointment.roomOrder?.rmId;
    let orderArea: any = roomAreas.roomAreas[0];

    if (orderRmId) {
      roomAreas.roomAreas.forEach((ra) => {
        const rm = ra.rooms.find(({ rmId }) => rmId === orderRmId);
        if (rm) {
          orderArea = ra;
          setSelectedRoom(rm);
        }
      });
    }

    const orderRemovedTables = mapRooms(orderArea)
    setSelectedArea(orderArea);
    setAreaRooms([...orderRemovedTables]);

    // setSelectedArea(tableAreas.tableAreas[0]);
    // } else if (tableAreas.tableAreas) {
    // setAreaRooms(tableAreas.tableAreas[0].tables || []);
    // }
  }, [roomAreas, selectedAppointment, incompleteOrders]);

  const onSelectRoom = (room: any) => {
    if (selectedRoom && selectedRoom.rmId === room.rmId) {
      setSelectedRoom(null);
      selectRoom(null);
    } else {
      setSelectedRoom(room);
      selectRoom(room);
    }
  };

  const mapRooms = (roomAreas) => {
    const rooms = roomAreas.rooms || [];
    const orderRemovedRooms = rooms
      .map(({ order, ...rm }) => rm)
      .filter(({ rmType }) => rmType === "ACTIVE");

    if (isArray(rooms) && isArray(incompleteOrders)) {
      incompleteOrders.forEach((order) => {
        if (order.roomOrder) {
          const odRmId = order.tableOrder.rmId;
          const index = orderRemovedRooms.findIndex(
            ({ rmId }) => rmId === odRmId
          );
          if (index > -1) {
            orderRemovedRooms[index] = {
              ...orderRemovedRooms[index],
              order,
            };
          }
        }
      });
    }
    return orderRemovedRooms;
  };

  const onSelectArea = (ra) => {
    setSelectedArea(ra);
    setAreaRooms(mapRooms(ra));
  };

  return (
    <div>
      <TableAreas>
        {roomAreas.roomAreas.map((ta) => (
          <div
            className="selected"
            key={ta.areaId}
            onClick={() => onSelectArea(ta)}
          >
            {ta.areaName}
          </div>
        ))}
      </TableAreas>
      <TableWrapper>
        {areaRooms?.map((room) => (
          <button
            disabled={
              room.rmId !== selectedRoom?.rmId &&
              room.order &&
              selectedAppointment.orderId !== room.order.orderId
            }
            onClick={() => onSelectRoom(room)}
            className={getClassName(selectedRoom, room, selectedAppointment)}
          >
            {room.rmNumber}
          </button>
        ))}
      </TableWrapper>
    </div>
  );
};

const getClassName = (selectedRoom, room, selectedAppointment) => {
  if (room.rmId === selectedRoom?.rmId) {
    return "selected";
  } else if (
    room.order &&
    selectedAppointment.orderId !== room.order.orderId
  ) {
    return "disabled";
  }
  return "";
};

export default connect(
  (state) => ({
    shopData: state.login.get("merchantShop"),
    selectedAppointment: state.orders.get("selectedAppointment"),
    incompleteOrders: state.orders.get("unCompletedAppointments"),
    roomAreas: state.rooms.get("roomAreas"),
  }),
  {
    removeAppointmentDiscount: Actions.product.removeAppointmentDiscount,
    selectAppointment: Actions.orders.selectedAppointment,
    selectRoom: Actions.rooms.selectRoom,
  }
)(ShopOrderRooms);
