import React from 'react';
import DragItems from './Constants';
import { connect } from 'react-redux';
import { DragWrapper } from './Styled';
import TableEditArea from './TableEditArea';
import TableNavigation from './TableNavigation';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const TableViewComponent = connect()(() => {
  const [_, drop] = useDrop(() => ({
    accept: DragItems.TABLE,
    // item: {}
  }));

  const items = [{ item: 'table 1' }];

  return (
    <DragWrapper id="table">
      <TableNavigation />
      <TableEditArea />
    </DragWrapper>
  );
});

// <DndProvider backend={HTML5Backend}></DndProvider>
const TableView = () => (
  <DndProvider backend={HTML5Backend}>
    <TableViewComponent />
  </DndProvider>
);

export default TableView;
