import React, { useState } from "react";
import { connect } from "react-redux";
import SideBarLayout from "../../../components/layouts/SideBarLayout";
import { Actions } from "../../../internal/app/Actions";
import { navigate } from "../../../internal/service/Navigation.service";
import ListRoomTypes from "./ListRoomTypes";

const RoomTypesView = connect(state => ({}), ({
  selectRoomType: Actions.rooms.selectRoomType,
}))(({selectRoomType}) => {
  const [currentTab, setTab] = useState(options[0].value);

  return (
    <SideBarLayout
      {...{
        header: 'Edit Room Types',
        title: "Rooms",
        path: "/menu",
        options,
        selectedTab: currentTab,
        onClickTab: setTab,
        buttonText: 'Add Room Type',
        onClickButton: () => {
          selectRoomType(null);
          navigate('/edit-room');
        },
        sidebar: 'ROOMS',
      }}
    >
      <RenderContent currentTab={currentTab} />
    </SideBarLayout>
  );
});

const RenderContent = ({ currentTab }) => {
  switch (currentTab) {
    case options[0].value:
      return <ListRoomTypes />
    default:
      break;
  }

  return <div></div>;
}

const options = [
  {
    label: "Room Types",
    value: "ROOM_TYPES",
  },
  {
    label: "All Rooms",
    value: "ALL_ROOMS",
  },
];

export default RoomTypesView;
