import React from "react";
import ReactModal from "react-modal";
import styles from "../../../components/Styles/ModelStyles.css";

const ReopenModel = (props) => {
  const { isOpen, onCancel, onConfirm } = props;

  const customStyles = {
    content: {
      top: "46%",
      outline: "none",
      overflow: "auto",
      position: "absolute",
      borderRadius: "14px",
      left: 'calc(50% - 230px)',
      WebkitOverflowScrolling: "touch",
      border: "1px solid rgba(112, 112, 112, 0.1)",
    },
  };

  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        style={customStyles}
        className={styles.productDeleteModalWrapper}
      >
        <div className={styles.productDeleteModalWrapperInfo}>
          <div className={styles.productDeleteModalHeaderText}>{"Reopen Order"}</div>
          <div className={styles.productDeleteModalTitleText}>{"Are you sure to reopen this order? You can close the order again from order queue"}</div>
          <div className={styles.productDeleteModalBtnWrapper}>
            <button className={styles.productDeleteModalBtnRemove} onClick={() => onCancel()}>
              <div className={styles.productDeleteModalBtnText}>{"Cancel"}</div>
            </button>
            <button className={styles.productDeleteModalBtnCancel} onClick={() => onConfirm()}>
              <div className={styles.productDeleteModalBtnText}>{'Reopen'}</div>
            </button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default ReopenModel;
