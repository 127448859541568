import swal from "sweetalert";
import { ModuleEvents } from "./Actions";
import { Actions } from "../../internal/app/Actions";

export default {
  [ModuleEvents.RESTORE_ACTIVITY_LOG]: async ({
    dispatch,
    payload,
    appState,
  }) => {
    const shopId = appState.login.get("merchantShop").shopId;
    if (payload && !payload.error) {
      dispatch(Actions.activityLog.fetchActivities(shopId));
    } else {
      await warning("Error occurred while restore activity log");
    }
  },
};

const warning = (alert) => {
  return swal({
    title: alert,
    icon: "warning",
  });
};
