import swal from "sweetalert";
import { ModuleEvents } from "./Actions";
import { isEmpty, get, omit } from "lodash";
import { Actions } from "../../internal/app/Actions";
import { showAlert } from "../../internal/manager/AlertManager";
import { goBack, navigate } from "../../internal/service/Navigation.service";

const Handlers = {
  [ModuleEvents.UPDATE_BRAND]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    if (payload && !payload.error) {
      dispatch(Actions.product.fetchShopBrands(shop.shopId));
      navigate("/products-customized");
    } else {
      return warning("Brand updated unsuccessful");
    }
  },
  [ModuleEvents.CREATE_BRAND]: ({ dispatch, payload, appState }) => {
    // const shop = appState.login.get('merchantShop');
    if (payload.result && !payload.result.error) {
      // dispatch(Actions.product.fetchShopBrands(shop.shopId));
      if (payload.type !== "ADD_QUICK_ITEM") {
        navigate("/products-customized");
      }
    } else {
      return warning("Brand created unsuccessful");
    }
  },
  [ModuleEvents.DELETE_BRAND]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    if (payload && !payload.error) {
      dispatch(Actions.product.fetchShopBrands(shop.shopId));
      navigate("/products-customized");
    } else {
      return warning("Brand deleted unsuccessful");
    }
  },
  [ModuleEvents.FETCH_SHOP_PRODUCT_CATEGORIES]: ({
    dispatch,
    payload,
    appState,
  }) => {
    const shop = appState.login.get("merchantShop");
    if (payload && !payload.error && payload.length > 0) {
      dispatch(Actions.product.fetchShopProducts(shop.shopId));
    }
  },
  [ModuleEvents.CREATE_PRODUCT]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    const warranty = appState.warranty.get("warranty");
    if (payload.result && !payload.result.error) {
      dispatch(Actions.product.fetchShopProducts(shop.shopId));
      dispatch(Actions.product.fetchStock(shop.shopId));
      dispatch(Actions.product.fetchShopOptionSets(shop.shopId));
      // dispatch(Actions.product.fetchFavoriteProducts(shop.shopId));
      if (!isEmpty(warranty.warranties)) {
        warranty.warranties.map((warranty: any) => {
          if (get(warranty, "status", "") === "CREATE") {
            const params = omit(warranty, ["status", "productName", "pid"]);
            params.shopId = shop.shopId;
            params.duration = parseInt(warranty.duration);
            dispatch(Actions.warranty.createWarranty(params));
          } else if (get(warranty, "status", "") === "UPDATE") {
            const params = omit(warranty, ["status", "productName", "pid"]);
            params.shopId = shop.shopId;
            params.duration = parseInt(warranty.duration);
            dispatch(Actions.warranty.updateWarranty(params));
          }
        });
      }
      if (payload.type !== "ADD_QUICK_ITEM") {
        navigate("/products-customized");
      }
      showAlert("Product added successfully");
      dispatch(Actions.warranty.fetchWarranties(shop.shopId));
    } else {
      showAlert(
        "Error occurred while adding product, contact support",
        "error"
      );
    }
  },
  [ModuleEvents.UPDATE_PRODUCT]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    const warranty = appState.warranty.get("warranty");
    if (payload && !payload.error) {
      dispatch(Actions.product.fetchShopProducts(shop.shopId));
      dispatch(Actions.product.fetchStock(shop.shopId));
      dispatch(Actions.product.fetchShopOptionSets(shop.shopId));
      if (!isEmpty(warranty.warranties)) {
        warranty.warranties.map((warranty: any) => {
          if (get(warranty, "status", "") === "CREATE") {
            const params = omit(warranty, ["status", "productName", "pid"]);
            params.shopId = shop.shopId;
            params.duration = parseInt(warranty.duration);
            dispatch(Actions.warranty.createWarranty(params));
          } else if (get(warranty, "status", "") === "UPDATE") {
            const params = omit(warranty, ["status", "productName", "pid"]);
            params.shopId = shop.shopId;
            params.duration = parseInt(warranty.duration);
            dispatch(Actions.warranty.updateWarranty(params));
          }
        });
      }
      showAlert("Product updated successfully");
      navigate("/products-customized");
      dispatch(Actions.warranty.fetchWarranties(shop.shopId));
    } else {
      showAlert(
        "Error occurred while updating product, contact support",
        "error"
      );
    }
  },

  [ModuleEvents.CREATE_SUPPLIER]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    if (payload && !payload.error) {
      dispatch(Actions.product.fetchSalonSuppliers(shop.shopId));
      navigate("/products-customized");
    } else {
      return warning("Supplier created failed");
    }
  },
  [ModuleEvents.UPDATE_SUPPLIER]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    if (payload && !payload.error) {
      dispatch(Actions.product.fetchSalonSuppliers(shop.shopId));
      navigate("/products-customized");
    } else {
      return warning("Supplier updated failed");
    }
  },
  [ModuleEvents.DELETE_SUPPLIER]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    if (payload && !payload.error) {
      dispatch(Actions.product.fetchSalonSuppliers(shop.shopId));
      navigate("/products-customized");
    } else {
      return warning("Supplier deleted failed");
    }
  },

  [ModuleEvents.DELETE_PRODUCT]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    const selectedProductCategory = appState.product.get(
      "selectedProductCategory"
    );
    if (payload && !payload.error) {
      dispatch(Actions.product.fetchShopProducts(shop.shopId));
      dispatch(Actions.product.fetchStock(shop.shopId));
      // dispatch(Actions.product.fetchFavoriteProducts(shop.shopId));
      showAlert("Product deleted successfully");
      navigate("/products-customized");
    } else {
      showAlert(
        "Error occurred while deleting product, contact support",
        "error"
      );
    }
  },
  [ModuleEvents.CREATE_PRODUCT_CATEGORY]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    if (payload.result && !payload.result.error) {
      // dispatch(Actions.product.fetchShopProductCategories(shop.shopId));
      if (payload.type !== "ADD_QUICK_ITEM") {
        navigate("/products-customized");
      }
    } else {
      return warning("Create product category failed");
    }
  },
  [ModuleEvents.UPDATE_PRODUCT_CATEGORY]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    if (payload && !payload.error) {
      dispatch(Actions.product.fetchShopProductCategories(shop.shopId));
      navigate("/products-customized");
    }
  },
  [ModuleEvents.CREATE_STOCK]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    const selectedMainMenuView = appState.common.get("selectedMainMenuView");
    const { result, isNavigate } = payload;
    if (result && !result.error) {
      const path =
        selectedMainMenuView === "MENU.STOCK"
          ? "/stock-customized"
          : "/products-customized";
      dispatch(Actions.product.fetchStock(shop.shopId));
      dispatch(Actions.stock.fetchStockRecord(shop.shopId));
      if (isNavigate) {
        goBack();
      }
    }
    dispatch(Actions.product.clearSelectedStock());
  },
  [ModuleEvents.DELETE_STOCK]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    const selectedMainMenuView = appState.common.get("selectedMainMenuView");
    if (payload && !payload.error) {
      const path =
        selectedMainMenuView === "MENU.STOCK"
          ? "/stock-customized"
          : "/products-customized";
      dispatch(Actions.product.fetchStock(shop.shopId));
      dispatch(Actions.stock.fetchStockRecord(shop.shopId));
      goBack();
    }
    dispatch(Actions.product.clearSelectedStock());
  },
  [ModuleEvents.DELETE_PRODUCT_CATEGORY]: ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    if (payload && !payload.error) {
      dispatch(Actions.product.fetchShopProductCategories(shop.shopId));
      navigate("/products-customized");
    }
  },
  [ModuleEvents.CREATE_PRODUCT_TYPES]: ({ payload, appState, dispatch }) => {
    const shop = appState.login.get("merchantShop");
    if (payload && !payload.error) {
      if (payload.type !== "ADD_QUICK_ITEM") {
        navigate("/products-customized");
      }
      dispatch(Actions.product.fetchProductTypes(shop.shopId));
    }
  },
  [ModuleEvents.UPDATE_PRODUCT_TYPES]: ({ payload }) => {
    if (payload && !payload.error) {
      navigate("/products-customized");
    }
  },
  [ModuleEvents.DELETE_PRODUCT_TYPES]: ({ payload }) => {
    if (payload && !payload.error) {
      navigate("/products-customized");
    }
  },
  [ModuleEvents.CLEAR_SELECTED_PRODUCT_ITEMS]: ({
    dispatch,
    payload,
    appState,
  }) => {
    if (payload === '/add-product') {
      const shop = appState.login.get("merchantShop");
      dispatch(Actions.product.fetchStock(shop.shopId));
      dispatch(Actions.product.fetchProductTypes(shop.shopId));
      dispatch(Actions.product.fetchShopOptionSets(shop.shopId));
      dispatch(Actions.product.fetchModifiers(shop.shopId));
    }
    navigate(payload);
  },
  [ModuleEvents.NAVIGATE_EDIT_PRODUCT_CATEGORY_VIEW]: ({
    dispatch,
    payload,
    appState,
  }) => {
    navigate("/add-product-category");
  },
  [ModuleEvents.NAVIGATE_EDIT_BRAND_VIEW]: ({
    dispatch,
    payload,
    appState,
  }) => {
    navigate("/add-brand");
  },
  [ModuleEvents.NAVIGATE_EDIT_PRODUCT_VIEW]: ({
    dispatch,
    payload,
    appState,
  }) => {
    navigate("/add-product");
  },
  [ModuleEvents.NAVIGATE_EDIT_SUPPLIER_VIEW]: ({
    dispatch,
    payload,
    appState,
  }) => {
    navigate("/add-supplier");
  },
  [ModuleEvents.NAVIGATE_EDIT_PRODUCT_TYPE_VIEW]: ({
    dispatch,
    payload,
    appState,
  }) => {
    navigate("/add-product-type");
  },
  [ModuleEvents.NAVIGATE_EDIT_STOCK_VIEW]: ({
    dispatch,
    payload,
    appState,
  }) => {
    navigate("/add-stock");
  },
  [ModuleEvents.SET_PRODUCT_OPTIONS]: ({ dispatch, payload, appState }) => {
    navigate("/add-product-price");
  },
  [ModuleEvents.SET_PRICE_VARIANTS]: ({ dispatch, payload, appState }) => {
    if (payload.navigation) {
      navigate("/add-product");
    }
  },
};

export default Handlers;

const warning = (alert) => {
  return swal({
    title: alert,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  });
};
