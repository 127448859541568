import {
  CreateRoomAreas,
  CreateRoomType,
  DeleteRoomType,
  GetRoomAreas,
  UpdateRoomAreas,
  UpdateRoomType,
} from "./schema/Rooms.schema";
import {
  setValueToLocalStorage,
  deleteCurrentStorageData,
  getValueFromLocalStorage,
  updateCurrentStorageData,
} from "../manager/CommonManager";
import Repository from "./Repository";
import { get, isNil, omitBy } from "lodash";
import { graphqlOperation } from "@aws-amplify/api";
import { isNetworkError } from "../manager/AppointmentManager";

class RoomRepository extends Repository {
  // updateTables = async (data) => {
  //   const input = omitBy(data, isNil);
  //   const userParams = {
  //     input,
  //   };
  //   try {
  //     const result = await this?.API.graphql(graphqlOperation(UpdateTableAreas, userParams));
  //     const tableAreas = get(result, 'data.updateTableAreas', {});

  //     return tableAreas;
  //   } catch (error) {
  //     console.warn('update table area', error);
  //     return {error};
  //   }
  // };

  createRoomType = async (data) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "ROOM_TYPES",
          data,
          data.id,
          "id"
        );
        return newParam;
      }
      const input = omitBy(data, isNil);
      const params = {
        input,
      };

      const result = await this?.API.graphql(
        graphqlOperation(CreateRoomType, params)
      );
      const roomType = get(result, "data.createRoomType", {});
      // const employee = await EmployeeRepository.getEmployee(
      //   order.shopId,
      //   order.empId,
      // );
      // order.employee = employee;
      return roomType;
    } catch (error) {
      console.warn("create room type", error);
      return { error };
    }
  };

  updateRoomType = async (data) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "ROOM_TYPES",
          data,
          data.id,
          "id"
        );
        return newParam;
      }

      const input = omitBy(data, isNil);
      const params = {
        input,
      };

      const result = await this?.API.graphql(
        graphqlOperation(UpdateRoomType, params)
      );
      const roomType = get(result, "data.updateRoomType", {});
      // const employee = await EmployeeRepository.getEmployee(
      //   order.shopId,
      //   order.empId,
      // );
      // order.employee = employee;
      return roomType;
    } catch (error) {
      console.warn("update room type", error);
      return { error };
    }
  };

  deleteRoomType = async (data) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await deleteCurrentStorageData(
          "ROOM_TYPES",
          data,
          data.id,
          "id"
        );
        return newParam;
      }

      const input = omitBy(data, isNil);
      const params = {
        input,
      };

      const result = await this?.API.graphql(
        graphqlOperation(DeleteRoomType, params)
      );
      const roomType = get(result, "data.deleteRoomType", {});
      // const employee = await EmployeeRepository.getEmployee(
      //   order.shopId,
      //   order.empId,
      // );
      // order.employee = employee;
      return roomType;
    } catch (error) {
      console.warn("delete room type", error);
      return { error };
    }
  };

  fetchRoomsTypes = async (shopId, retryCount = 1) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list =  await getValueFromLocalStorage('ROOM_TYPES');
        if (list) {
          const newList = JSON.parse(list);
          return newList.Items;
        }
        return [];
      }
      const result = await this.apiGet({
        apiName: this.API_NETLISE_APP,
        path: `/roomTypes?shopId=${shopId}`,
      });
      await setValueToLocalStorage(
        "ROOM_TYPES",
        JSON.stringify({ Items: result.data })
      );
      return result.data;
    } catch (error) {
      console.warn("fetch salon appointment", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchRoomsTypes(shopId, ++retryCount);
      }
      return { error };
    }
  };

  fetchRooms = async (shopId, retryCount = 1) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("ROOMS");
        if (list) {
          const newList = JSON.parse(list);
          return newList.Items;
        }
        return;
      }
      const response: any = await this.API.graphql(
        graphqlOperation(GetRoomAreas, { shopId })
      );
      await setValueToLocalStorage(
        "ROOMS",
        JSON.stringify({ Items: response.data.getRoomAreas })
      );
      return response.data.getRoomAreas;
    } catch (error) {
      console.warn("fetch rooms", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchRooms(shopId, ++retryCount);
      }
      return { error };
    }
  };

  updateRooms = async (data) => {
    const input = omitBy(data, isNil);
    const userParams = {
      input,
    };
    try {
      const result = await this?.API.graphql(
        graphqlOperation(UpdateRoomAreas, userParams)
      );
      const tableAreas = get(result, "data.updateRoomAreas", {});

      return tableAreas;
    } catch (error) {
      console.warn("update room area", error);
      return { error };
    }
  };

  createRooms = async (data) => {
    const input = omitBy(data, isNil);
    const userParams = {
      input,
    };
    try {
      const result = await this?.API.graphql(
        graphqlOperation(CreateRoomAreas, userParams)
      );
      const tableAreas = get(result, "data.createRoomAreas", {});
      return tableAreas;
    } catch (error) {
      console.warn("create room area", error);
      return { error };
    }
  };
}

export default new RoomRepository();
