import React from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import "../../Styles/ModelStyles.css";
import "react-calendar/dist/Calendar.css";
import styles from "../../Styles/ModelStyles.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { Actions } from "../../../internal/app/Actions";
import CANCEL from "../../../../assets/svg/IconCross.svg";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: 'calc(50% - 230px)',
    border: "1px solid rgba(112, 112, 112, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const OrderSaveModal = (props) => {
  const {
    isOpenOrderSaveModal,
    clearCurrentAppointment,
    onSave,
    openSaveModal,
    onNavigate,
    onDeleteOrder,
  } = props;

  return (
    <div>
      <ReactModal
        isOpen={isOpenOrderSaveModal}
        style={customStyles}
        className={styles.productDeleteModalWrapper}
      >
        <div className={styles.orderDeleteModalWrapperInfo}>
          <div className={styles.saveOrderHeaderWrapperContainer}>
            <div
              className={styles.saveOrderHeaderWrapper}
              onClick={() => openSaveModal(false)}
            >
              <img alt="icon" src={CANCEL} />
            </div>
          </div>
          <div className={styles.productDeleteModalHeaderText}>
            {"Save your order?"}
          </div>
          <div
            className={styles.productDeleteModalTitleText}
          >{`Do you want to save the order \n or discard or delete it fully?`}</div>
          <div className={styles.orderDeleteModalBtnWrapper}>
            <button
              onClick={() => {
                clearCurrentAppointment();
                openSaveModal(false);
                onNavigate();
              }}
              style={{ backgroundColor: "#8a9ca5" }}
              className={styles.productDeleteModalBtnCancel}
            >
              <div className={styles.productDeleteModalBtnText}>
                {"Discard"}
              </div>
            </button>
            <button
              className={styles.productDeleteModalBtnRemove}
              onClick={() => {
                onDeleteOrder();
              }}
            >
              <div className={styles.productDeleteModalBtnText}>{"Delete"}</div>
            </button>
            <button
              className={styles.productDeleteModalBtnCancel}
              onClick={() => onSave()}
            >
              <div className={styles.productDeleteModalBtnText}>{"Save"}</div>
            </button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};
export default connect(
  (state) => ({
    isOpenOrderSaveModal: state.orders.get("isOpenOrderSaveModal"),
  }),
  {
    openSaveModal: Actions.orders.openSaveModal,
    clearCurrentAppointment: Actions.payment.clearCurrentAppointment,
  }
)(OrderSaveModal);
