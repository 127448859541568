import React from "react";
import { LoaderWrapper } from "./Styled";
import { CircularProgress } from "@material-ui/core";

const AsyncUpdate = ({ loading, downloadUpdate, skip }) => {
  return (
    <LoaderWrapper>
      <div>
        {loading ? (
          <h2>Downloading...</h2>
        ) : (
          <h2>
            You have a new update. <br />
            Click update to download and restart the app.
          </h2>
        )}
        <br />
        {loading ? (
          <div className="buttons">
            <CircularProgress />
          </div>
        ) : (
          <div className="buttons">
            <button
              className="close"
              onClick={() => {
                skip();
              }}
            >
              Skip, do later
            </button>
            <button
              onClick={() => {
                downloadUpdate();
              }}
            >
              Update now
            </button>
          </div>
        )}
      </div>
    </LoaderWrapper>
  );
};

export default AsyncUpdate;
