import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showAlert = (alert, type = "success") => {
  toast[type](alert, {
    autoClose: 1500,
    draggable: true,
    theme: "colored",
    closeOnClick: false,
    pauseOnHover: true,
    progress: undefined,
    position: "top-right",
    hideProgressBar: false,
  });
};

export const getSendToItemMessage = (kitchenCount, barCount) => {
  if (kitchenCount > 0 && barCount > 0) {
    return `${kitchenCount} Kitchen and ${barCount} Bar items sent.`
  } else if (kitchenCount > 0) {
    return `${kitchenCount} Kitchen items sent.`
  } else {
    return `${barCount} bar items sent.`
  }
};
