import Repository from './Repository';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import {CreateVehicle, GetDiscounts, UpdateVehicle} from "./schema/Order.schema";
import {getValueFromLocalStorage, setValueToLocalStorage} from "../manager/CommonManager";

class OrderRepository extends Repository {
  searchServiceAndProduct = async (shopId, searchText) => {
    const message = this.buildMessage({shopId, searchText});
    try {
      const result = await this.apiPost({
        apiName: this.API_ORDERS,
        path: `/proucts-and-services`,
        message,
      });
      return result.searchList;
    } catch (error) {
      console.warn('search service and product', error);
      return {error};
    }
  };

  fetchVehicles = async (shopId, clientId, limit, lastKey) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list =  await getValueFromLocalStorage('VEHICLES');
        if (list) {
          return JSON.parse(list);
        }

        return;
      }

      const message = this.buildMessage({shopId, clientId, limit, lastKey});

      const result = await this.apiPost({
        apiName: this.API_ORDERS,
        path: `/vehicles`,
        message,
      });
      await setValueToLocalStorage('VEHICLES', JSON.stringify(result.vehicleResult));
      return result.vehicleResult;
    } catch (error) {
      console.warn('fetch vehicles error', error);
      return {error};
    }
  };

  fetchDiscounts = async (shopId) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list =  await getValueFromLocalStorage('DISCOUNTS');
        if (list) {
          return JSON.parse(list);
        }

        return;
      }

      const result:any = await this.API.graphql(graphqlOperation(GetDiscounts, {shopId}));
      await setValueToLocalStorage('DISCOUNTS', JSON.stringify(result.data.getDiscounts));
      return result.data.getDiscounts;
    } catch (error) {
      console.warn('fetch discounts error', error);
      return { error };
    }
  };


  createVehicle = async (params)=> {
    params.createdTime = Date.now();
    params.updatedTime = params.createdTime;
    try {
      const result:any = await this.API.graphql(graphqlOperation(CreateVehicle, {input: params}));
      return result.data.createVehicle;
    } catch (error) {
      console.warn('create vehicle error', error);
      return { error };
    }
  };

  updateVehicle = async (params)=> {
    params.updatedTime = Date.now();
    try {
      const result:any = await this.API.graphql(graphqlOperation(UpdateVehicle, {input: params}));
      return result.data.updateVehicle;
    } catch (error) {
      console.warn('create vehicle error', error);
      return { error };
    }
  };
}

export default new OrderRepository();
