import React, {Component} from 'react';
import styles from '../../../components/Styles/Styles.css';
import {Container, Row, Col} from 'react-grid-system';
import IconCross from "../../../../assets/svg/IconCross.svg";

const TransactionsList = (props) => {
  const {searchText, onChangeSearch, deleteSearchText} = props;
  return (
    <Row>
      <Col xs={12} style={{paddingLeft: '27px', paddingRight: '27px', marginTop: '10px'}}>
        <div className={styles.transactionsListInputWrapper}>
          <input value={searchText} onChange={(event) => onChangeSearch(event.target.value)} type={"text"} placeholder={"Search for a transaction"} className={styles.transactionsListInput}/>
          <img onClick={() => deleteSearchText()} src={IconCross}/>
        </div>
      </Col>
    </Row>
  );
};
export default TransactionsList;
