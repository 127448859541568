import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PrinterInfo } from "electron";
import Radio from "@material-ui/core/Radio";
import { Row, Col } from "react-grid-system";
import { capitalize, groupBy } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { PrinterContainer, TitleBold } from "./Styled";
import styles from "../../../components/Styles/Styles.css";
import UpsertPrinter from "../../model/views/UpsertPrinter";
import SaveButton from "../../../components/Buttons/Views/SaveButton";
import { isCanAccess } from "../../../internal/manager/EmployeeManager";
import CancelButton from "../../../components/Buttons/Views/CancelButton";
import GraphqlFunctions from "../../../internal/service/Graphql.functions";

const PrinterSetup = ({ currentUser }) => {
  const [printers, setPrinters] = useState<any>([]);
  const [isOpen, setOpen] = useState<boolean>(false);

  const getPrinters = async () => {
    const prs: any = await GraphqlFunctions.commonGraphqlService(
      "FETCH_PRINTERS"
    );
    setPrinters(prs);
  };

  useEffect(() => {
    // const prs = StorageService.getItem("printers") || [];
    // setPrinters(prs);
    getPrinters();
  }, [isOpen]);

  const removePrinter = async (printerId) => {
    // const printers = StorageService.getItem("printers") || [];
    let printers: any = await GraphqlFunctions.commonGraphqlService(
      "FETCH_PRINTERS"
    );
    const printerRemoved = printers.filter(({ id }) => id !== printerId);

    // StorageService.setItem("printers", [...printerRemoved]);
    await GraphqlFunctions.commonGraphqlService("SET_PRINTERS", printerRemoved);
    setPrinters(printerRemoved);
  };

  const role = currentUser?.specializations;
  const groupedPrinters = groupBy(printers, "printType");

  return (
    <div>
      {Object.entries(groupedPrinters).map(([key, value]) => (
        <div key={key}>
          <Row>
            <Col>
              <TitleBold>{capitalize(key)} Printers</TitleBold>
            </Col>
          </Row>
          <PrinterContainer>
            {value.map((printer: PrinterInfo, index: number) => (
              <div>
                <div>
                  {printer.printerName} {printer.usb || printer.ipAddress}
                </div>
                <div>
                  {isCanAccess(role, "isEditOrDeletePrinters") ? (
                    <CancelButton
                      text={"Remove"}
                      onClick={() => removePrinter(printer.id)}
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </PrinterContainer>
        </div>
      ))}

      <Row>
        <Col xs={12}>
          {isCanAccess(role, "isEditOrDeletePrinters") ? (
            <div className={styles.printerSetupButtonsWrapper}>
              <SaveButton
                text={"Add Printer"}
                onClick={() => {
                  setOpen(true);
                }}
              />
            </div>
          ) : null}
        </Col>
      </Row>
      <UpsertPrinter isOpen={isOpen} onClose={() => setOpen(false)} />
      {/*<Row>
        <Col xs={12}>
          <div className={styles.printerSetupWrapper}>
            <div className={styles.printerSetupText}>Device type</div>
            <input
              className={styles.printerSetupDeviceSelect}
              placeholder={'TCP / IP Device'}
            />
          </div>

          <div className={styles.printerSetupWrapper}>
            <div className={styles.printerSetupText}>Hostname or IP address</div>
            <input className={styles.printerSetupDeviceInput}/>
          </div>

          <div className={styles.printerSetupWrapper}>
            <div className={styles.printerSetupText}>Associated registers</div>
            <input className={styles.printerSetupDeviceInput}/>
          </div>

        </Col>

      </Row>

      <Row>
        <Col xs={12}>
          <div className={styles.printerSetupButtonsWrapper}>
            <SaveButton text={'Add a new printer'} onClick={() => {}}/>
            <CancelButton text={'Remove'} onClick={() => {}}/>
          </div>
        </Col>
      </Row>*/}
    </div>
  );
};

const RadioButton = withStyles({
  root: {
    color: "#49A4D4",
    "&$checked": {
      color: "#49A4D4",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default connect(
  (state: any) => ({
    printers: state.common.get("printers"),
    currentUser: state.login.get("currentUser"),
  }),
  {}
)(PrinterSetup);
