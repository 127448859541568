import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import _ from "lodash";

const initialState = Map({
  roomTypes: [],
  selectedRoomType: null,
  roomAreas: [],
  rooms: [],
  roomMode: "saved", // edit
  selectedArea: null,
  selectedRoom: null,
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.EDIT_ROOMS: {
      const selectedArea = state.get("selectedArea");
      const roomAreas: any = payload;

      const index = roomAreas.roomAreas.findIndex(
        (rm) => rm.areaId === selectedArea
      );
      if (index > -1) {
        const rooms = roomAreas.roomAreas[index].rooms;
        return state.set("roomAreas", payload).set("rooms", [...rooms]);
      } else {
        const rooms = roomAreas.roomAreas[0].rooms;
        return state
          .set("roomAreas", payload)
          .set("rooms", [...rooms])
          .set("selectedArea", roomAreas.roomAreas[0].areaId);
      }
    }
    case ModuleEvents.SELECT_ROOM_TYPE: {
      return state.set("selectedRoomType", payload);
    }
    case ModuleEvents.SELECT_ROOM: {
      return state.set("selectedRoom", payload);
    }
    case ModuleEvents.FETCH_ROOM_TYPES: {
      return state.set("roomTypes", payload);
    }
    case ModuleEvents.SET_ROOM_MODE: {
      return state.set("roomMode", payload);
    }
    case ModuleEvents.SAVE_ROOMS:
    case ModuleEvents.FETCH_ROOMS: {
      return state
        .set("roomAreas", payload)
        .set("selectedArea", payload.roomAreas[0].areaId)
        .set("rooms", payload.roomAreas[0].rooms);
    } 

    default:
      return state;
  }
};

export default Reducer;
