import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import "../../Styles/ModelStyles.css";
import styles from "../../Styles/ModelStyles.css";
import {
  getModifierItems,
  checkArrayEquality,
  updateStockItemCount,
  getStockRemainingCount,
} from "../../../internal/manager/ProductManager";
import {
  isCanAccess,
  getHiPriorityUserRole,
} from "../../../internal/manager/EmployeeManager";
import { Actions } from "../../../internal/app/Actions";
import SubIcon from "../../../../assets/svg/IconSub.svg";
import CANCEL from "../../../../assets/icons/cancel.svg";
import AddIcon from "../../../../assets/svg/IconAddItem.svg";
import { EditProductPriceContainer } from "../../Styles/Styled";
import _, { filter, find, findIndex, get, isEmpty } from "lodash";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 266px)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const EditProductPriceModal = (props) => {
  const {
    shop,
    isOpen,
    closeModal,
    categories,
    productList,
    currentUser,
    editItem = {},
    purchaseProducts,
    currentStockList,
    purchaseServices,
    onOpenApproveModal,
    setPurchaseService,
    setPurchaseProducts,
    deletePurchaseService,
    deletePurchaseProduct,
    updateCurrentStockCount,
  } = props;
  const [menuItem, setMenuItem] = useState<any>({});
  const [selectedItem, setSelectedItem] = useState<any>({});
  const role = currentUser?.specializations;

  useEffect(() => {
    if (!isEmpty(editItem)) {
      let itemList =
        editItem.type === "SERVICE" ? purchaseServices : purchaseProducts;
      if (editItem.type != "SERVICE") {
        let index: any;
        let filterProducts: any = [];
        if (!isEmpty(editItem?.opId)) {
          index = findIndex(
            purchaseProducts,
            (item: any) => item.opId === editItem.opId
          );
          filterProducts = filter(
            purchaseProducts,
            (item: any) => item.opId === editItem.opId
          );
        } else {
          index = findIndex(
            purchaseProducts,
            (item: any) => item.id === editItem.id
          );
          filterProducts = filter(
            purchaseProducts,
            (item: any) => item.id === editItem.id
          );
        }
        if (!isEmpty(editItem?.modifiers) && !isEmpty(filterProducts)) {
          index = findIndex(purchaseProducts, (product: any) => {
            let array1: any = getModifierItems(product.modifiers);
            let array2: any = getModifierItems(editItem.modifiers);
            const result = checkArrayEquality(array1, array2);
            return result;
          });
        }
        if (index > -1) {
          setSelectedItem({ ...purchaseProducts[index] });
        }
      } else {
        if (editItem.opId) {
          const index = itemList.findIndex(
            (item: any) => item.opId === editItem.opId
          );
          if (index > -1) {
            setSelectedItem({ ...itemList[index] });
          }
        } else {
          const index = itemList.findIndex((item) => item.id === editItem.id);
          if (index >= 0) {
            const foundItem = itemList[index];
            setSelectedItem({ ...foundItem });
          }
        }
      }
    } else {
      setSelectedItem({});
    }
  }, [isOpen, editItem]);

  useEffect(() => {
    if (!isEmpty(editItem)) {
      if (editItem.type === "SERVICE") {
        const item = categories?.categories.reduce((acc, cat) => {
          const service = cat?.services?.find(
            (service) => service.id === editItem.id
          );
          if (service) {
            return service;
          }
          return acc;
        }, null);
        setMenuItem(item);
      } else {
        const item = productList.find(
          (product: any) => product.pid === editItem.id
        );
        setMenuItem(item);
      }
    }
  }, [productList, categories, purchaseServices, purchaseProducts, editItem]);

  const onClickReset = () => {
    if (selectedItem.type === "SERVICE") {
      selectedItem.price = menuItem.price;
      selectedItem.servicePrice = menuItem.price;
    } else {
      selectedItem.price = menuItem.itemPrice;
      selectedItem.productSalePrice = menuItem.itemPrice;
    }
    selectedItem.quantity = 1;
    setSelectedItem({ ...selectedItem });
  };

  const onChangeItemPrice = (text) => {
    let price = parseFloat(text);
    if (isNaN(price) || price === Infinity) {
      price = 0;
    }

    if (selectedItem.type === "SERVICE") {
      selectedItem.servicePrice = price;
    } else {
      selectedItem.productSalePrice = price;
    }
    selectedItem.price = parseFloat(text) * selectedItem.quantity;
    setSelectedItem({ ...selectedItem });
  };

  const onUpdateStockCount = (diffQuantity, isRemoved, param) => {
    const particularProduct = _.find(
      productList,
      (product) => product.pid === param.id
    );
    const stockItems = _.get(particularProduct, "stockItems", []);
    const newStock = updateStockItemCount(
      stockItems,
      currentStockList,
      diffQuantity,
      isRemoved
    );
    updateCurrentStockCount(newStock);
  };

  const changeItemCount = (type) => {
    let { quantity } = selectedItem;
    const remainingCount = getStockRemainingCount(
      selectedItem.id,
      productList,
      currentStockList
    );
    if (type === "INCREMENT") {
      quantity++;
    } else {
      quantity = quantity > 1 ? quantity - 1 : quantity;
    }
    if (selectedItem.type === "SERVICE") {
      selectedItem.price = quantity * selectedItem.servicePrice;
    } else {
      selectedItem.price = quantity * selectedItem.productSalePrice;
    }
    setSelectedItem({ ...selectedItem, quantity });
  };

  const onClickUpdateItem = () => {
    if (selectedItem.type === "PRODUCT") {
      const productData: any = find(purchaseProducts, (item: any) => {
        if (selectedItem.pid) {
          return item.id === selectedItem.pid;
        } else {
          if (selectedItem?.opId) {
            if (!isEmpty(selectedItem.modifiers)) {
              let array1: any = getModifierItems(item.modifiers);
              let array2: any = getModifierItems(selectedItem.modifiers);
              const result = checkArrayEquality(array1, array2);
              return result;
            }
            return item.opId === selectedItem.opId;
          } else {
            if (!isEmpty(selectedItem.modifiers)) {
              let array1: any = getModifierItems(item.modifiers);
              let array2: any = getModifierItems(selectedItem.modifiers);
              const result = checkArrayEquality(array1, array2);
              return result;
            }
            return item.id === selectedItem.id;
          }
        }
      });

      const isDecrement = productData?.quantity > selectedItem?.quantity;
      const diffQuantity = Math.abs(
        productData?.quantity - selectedItem?.quantity
      );
      if (productData?.quantity !== selectedItem?.quantity) {
        onUpdateStockCount(diffQuantity, isDecrement, selectedItem);
      }
      setPurchaseProducts([selectedItem]);
    } else if (selectedItem.type === "SERVICE") {
      setPurchaseService([selectedItem]);
    }
    closeModal();
  };

  const onDeleteItem = () => {
    if (selectedItem.type === "PRODUCT") {
      if (isCanAccess(role, "isCanDeleteItem")) {
        removeSelectedProduct();
      } else {
        onOpenApproveModal("PRODUCT", selectedItem);
      }
    } else if (selectedItem.type === "SERVICE") {
      if (isCanAccess(role, "isCanDeleteItem")) {
        removeSelectedService();
      } else {
        onOpenApproveModal("SERVICE", selectedItem);
      }
    }
    closeModal();
  };

  const removeSelectedService = () => {
    deletePurchaseService(selectedItem);
  };

  const removeSelectedProduct = () => {
    const product = _.find(
      purchaseProducts,
      (item) => item.id === selectedItem.id
    );
    if (product) {
      onUpdateStockCount(product?.quantity, true, selectedItem);
      deletePurchaseProduct(product);
    }
  };

  const isService = editItem?.type === "SERVICE";
  const productOptionName = get(
    editItem,
    "priceVariants[0].optionUnit",
    editItem?.productName
  );

  const title = isService ? editItem?.serviceTitle : productOptionName;
  const itemPrice = isService
    ? selectedItem?.servicePrice
    : selectedItem?.productSalePrice;
  const productPrice = isService
    ? menuItem?.price
    : get(editItem, "productPrice", menuItem?.itemPrice);
  const quantity = get(selectedItem, "quantity", 0);
  const userRole = getHiPriorityUserRole(currentUser.specializations);
  const isDisabled = userRole === "Waiter";

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.editProductPriceModal}
    >
      <EditProductPriceContainer>
        <div
          onClick={() => {
            closeModal();
            setSelectedItem(editItem);
          }}
        >
          <div>{"Item Information"}</div>
          <img src={CANCEL} />
        </div>

        <div>
          <div>
            <div>{"ITEM NAME"}</div>
            <input disabled value={title || ""} />
          </div>

          <div>
            <div>
              <div>{"ITEM PRICE"}</div>
              <input
                disabled={isDisabled}
                value={itemPrice}
                onChange={(number) => onChangeItemPrice(number.target.value)}
              />
            </div>
            <div>
              <div>{"STANDARD PRICE"}</div>
              <div>
                <div>{productPrice}</div>
                <button onClick={() => onClickReset()}>{"Reset"}</button>
              </div>
            </div>
          </div>
          <div>
            <div>{"ITEM COUNT"}</div>
            <div>
              <img src={SubIcon} onClick={() => changeItemCount("DECREMENT")} />
              <div>{quantity}</div>
              <img src={AddIcon} onClick={() => changeItemCount("INCREMENT")} />
            </div>
          </div>
          <div>
            <button onClick={() => onDeleteItem()}>
              <div>Delete</div>
            </button>
            <button onClick={() => onClickUpdateItem()}>
              <div>Update Item</div>
            </button>
            <button
              onClick={() => {
                closeModal();
                setSelectedItem(editItem);
              }}
            >
              <div>Cancel</div>
            </button>
          </div>
        </div>
      </EditProductPriceContainer>
    </ReactModal>
  );
};
export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    categories: state.service.get("categories"),
    currentUser: state.login.get("currentUser"),
    productList: state.product.get("productList"),
    purchaseProducts: state.product.get("purchaseProducts"),
    currentStockList: state.product.get("currentStockList"),
    purchaseServices: state.service.get("purchaseServices"),
  }),
  {
    setPurchaseService: Actions.service.setPurchaseService,
    setPurchaseProducts: Actions.product.setPurchaseProducts,
    deletePurchaseService: Actions.service.deletePurchaseService,
    deletePurchaseProduct: Actions.product.deletePurchaseProduct,
    updateCurrentStockCount: Actions.product.updateCurrentStockCount,
  }
)(EditProductPriceModal);
