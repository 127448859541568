export const salonHolidays = () => {
  return [
    {
      "holidayId": "ff2cb373-c67f-4b78-967b-7de8d419fa4b",
      "date": 1629138600000,
      "status": "ACTIVE",
      "holidayDetails": {
        "dateRangeEnd": null,
        "totalDays": 1,
        "isSpecial": false,
        "dateEndStamp": null,
        "dateRangeStart": "Tue, 17 Aug, 2021",
        "dateStartStamp": null,
        "isPublic": true,
        "description": ""
      },
      "createdTime": 1628742714988,
      "updatedTime": 1628742714988,
      "sid": "8c83df68-3f7c-4336-854b-ad16aaa954fe"
    },
    {
      "holidayId": "d1f3e21f-eb29-4bf7-92ae-f9d37ff8f12f",
      "date": 1628706600000,
      "status": "ACTIVE",
      "holidayDetails": {
        "dateRangeEnd": null,
        "totalDays": 1,
        "isSpecial": false,
        "dateEndStamp": null,
        "dateRangeStart": "Thu, 12 Aug, 2021",
        "dateStartStamp": null,
        "isPublic": true,
        "description": ""
      },
      "createdTime": 1628742599777,
      "updatedTime": 1628742693475,
      "sid": "8c83df68-3f7c-4336-854b-ad16aaa954fe"
    }
  ]
};



