import React from "react";
import _ from "lodash";
import ReactModal from "react-modal";
import "../../Styles/ModelStyles.css";
import styles from "../../Styles/ModelStyles.css";
import SaveButton from "../../Buttons/Views/SaveButton";
import CANCEL from "../../../../assets/icons/cancel.svg";
import { NeedApprovalContainer } from "../../Styles/Styled";
import CancelButton from "../../Buttons/Views/CancelButton";

const customStyles = {
  content: {
    top: "20%",
    left: "calc(50% - 266px)",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const EnterPinModel = (props) => {
  const {
    pin,
    isOpen,
    onClose,
    onSetPin,
    onCancel,
    onEnterPin,
    onChangePin,
    isShowPinError,
  } = props;
  const isValid = pin?.length >= 6;

  const onChangeText = (text) => {
    onSetPin(text);
    onChangePin(text);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.approvalEmployeeModal}
    >
      <NeedApprovalContainer>
        <div
          onClick={() => {
            onClose();
            onSetPin("");
          }}
        >
          <div>Need Approval</div>
          <img src={CANCEL} />
        </div>
      </NeedApprovalContainer>
      <div>
        <div className={styles.enterPinModalContainer}>
          <div>
            <div className={styles.enterPinText}>PIN</div>
            <input
              onChange={(text) => onChangeText(text.target.value)}
              value={pin}
              className="stockTextInput"
              placeholder="Pin"
              style={{ height: "75px", fontSize: "18px" }}
              type={"password"}
            />
            {isShowPinError ? (
              <div className={styles.errorPinText}>Enter valid pin!</div>
            ) : null}
          </div>
        </div>
        <div className={styles.pinBottomBtnContainer}>
          <CancelButton
            onClick={() => {
              onCancel();
              onSetPin("");
            }}
          />
          <SaveButton
            text={"Enter Pin"}
            isValid={isValid}
            onClick={() => {
              if (isValid) {
                onEnterPin();
              }
            }}
          />
        </div>
      </div>
    </ReactModal>
  );
};
export default EnterPinModel;
