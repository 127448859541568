import _, { isEmpty, get } from "lodash";
import Syncer from "../../internal/sync/Syncer";
import WifiService from "../../internal/service/Wifi.service";
import TableRepository from "../../internal/repos/TableRepository";
import commonRepository from "../../internal/repos/CommonRepository";
import GraphqlFunctions from "../../internal/service/Graphql.functions";
import ActivityRepository from "../../internal/repos/ActivityRepository";
import { getValueFromLocalStorage } from "../../internal/manager/CommonManager";

export const ModuleEvents = {
  SET_ONLINE: "SET_ONLINE",
  SET_OFFLINE: "SET_OFFLINE",
  SELECT_VIEW: "SELECT_VIEW",
  GET_NETWORKS: "GET_NETWORKS",
  SET_PRINTERS: "SET_PRINTERS",
  LOADING_STARTED: "LOADING_STARTED",
  LOADING_FINISHED: "LOADING_FINISHED",
  GET_WIFI_DETAILS: "GET_WIFI_DETAILS",
  GET_CURRENT_NETWORK: "GET_CURRENT_NETWORK",
  SELECT_MAIN_MENU_VIEW: "SELECT_MAIN_MENU_VIEW",
  ON_SUBSCRIPTION_EVENTS: "ON_SUBSCRIPTION_EVENTS",
  UPDATE_ALL_OFFLINE_ACTIONS: "UPDATE_ALL_OFFLINE_ACTIONS",
};

const createAction: any = (type, action: any = () => {}, meta) => {
  return (...args) => ({ type, payload: action(...args), meta });
};

const Actions = {
  selectView: createAction(ModuleEvents.SELECT_VIEW, async (view) => {
    return view;
  }),
  setPrinters: createAction(ModuleEvents.SET_PRINTERS, async (printers) => {
    return printers;
  }),
  getWifiDetails: createAction(ModuleEvents.GET_WIFI_DETAILS, async () => {
    const wifiList = await WifiService.getAllWifiConnections();
    const currentWifi = await WifiService.getCurrentConnection();

    return { wifiList, currentWifi };
  }),
  onSubscriptionEvents: createAction(
    ModuleEvents.ON_SUBSCRIPTION_EVENTS,
    async (event) => {
      if (event && event.data) {
        return JSON.parse(event.data);
      }

      return null;
    }
  ),
  loadingStarted: createAction(
    ModuleEvents.LOADING_STARTED,
    (action) => action
  ),
  loadingFinished: createAction(
    ModuleEvents.LOADING_FINISHED,
    (action) => action
  ),
  updateAllOfflineActions: createAction(
    ModuleEvents.UPDATE_ALL_OFFLINE_ACTIONS,
    async ({ shopId }: any) => {
      const brandList = await getValueFromLocalStorage("BRANDS");
      // const tableData = await getValueFromLocalStorage("TABLES");

      const productCategoriesList = await getValueFromLocalStorage(
        "PRODUCT_CATEGORIES"
      );
      const productsList = await getValueFromLocalStorage("ALL_PRODUCTS");
      const menuList = await getValueFromLocalStorage("MENU");
      const productTypesList = await getValueFromLocalStorage("PRODUCT_TYPES");
      const completedAppointmentsList = await getValueFromLocalStorage(
        "COMPLETED_APPOINTMENT_LIST"
      );
      const unCompletedAppointmentsList = await getValueFromLocalStorage(
        "UN_COMPLETED_APPOINTMENT_LIST"
      );
      const refundOrdersList = await getValueFromLocalStorage("REFUND_ORDERS");
      const clientsList = await getValueFromLocalStorage("MERCHANT_CLIENT");
      const shopCategoriesList = await getValueFromLocalStorage(
        "SHOP_CATEGORIES"
      );
      const stockList = await getValueFromLocalStorage("STOCK");
      const shopRecordList = await getValueFromLocalStorage("STOCK_RECORD");

      const deletedBrandList = await getValueFromLocalStorage("deleted-BRANDS");
      const deletedProductCategoriesList = await getValueFromLocalStorage(
        "deleted-PRODUCT_CATEGORIES"
      );
      const deletedProductsList = await getValueFromLocalStorage(
        "deleted-ALL_PRODUCTS"
      );
      const deletedMenuList = await getValueFromLocalStorage("deleted-MENU");
      const deletedProductTypesList = await getValueFromLocalStorage(
        "deleted-PRODUCT_TYPES"
      );
      const deleteClientsList = await getValueFromLocalStorage(
        "deleted-MERCHANT_CLIENT"
      );
      const deleteStockList = await getValueFromLocalStorage("deleted-STOCK");
      const deleteStockRecordList = await getValueFromLocalStorage(
        "deleted-STOCK_RECORD"
      );
      const deleteOrderList = await getValueFromLocalStorage(
        "deleted-UN_COMPLETED_APPOINTMENT_LIST"
      );

      const brands = brandList ? JSON.parse(brandList) : { Items: [] };
      // const tables = tableData ? JSON.parse(tableData) : {};

      const productCategories = productCategoriesList
        ? JSON.parse(productCategoriesList)
        : { Items: [] };
      const products = productsList ? JSON.parse(productsList) : { Items: [] };
      const menu = menuList ? JSON.parse(menuList) : { Items: [] };
      const productTypes = productTypesList
        ? JSON.parse(productTypesList)
        : { Items: [] };
      const completedAppointments = completedAppointmentsList
        ? JSON.parse(completedAppointmentsList)
        : { Items: [] };
      const unCompletedAppointments = unCompletedAppointmentsList
        ? JSON.parse(unCompletedAppointmentsList)
        : { Items: [] };
      const refundOrders = refundOrdersList ? JSON.parse(refundOrdersList) : [];
      const clients = clientsList ? JSON.parse(clientsList) : { Items: [] };
      const shopCategories = shopCategoriesList
        ? JSON.parse(shopCategoriesList)
        : [];
      const stocks = stockList ? JSON.parse(stockList) : { Items: [] };
      const stockRecords = shopRecordList
        ? JSON.parse(shopRecordList)
        : { Items: [] };

      const deletedBrands = deletedBrandList
        ? JSON.parse(deletedBrandList)
        : [];
      const deletedProductCategories = deletedProductCategoriesList
        ? JSON.parse(deletedProductCategoriesList)
        : [];
      const deletedProducts = deletedProductsList
        ? JSON.parse(deletedProductsList)
        : [];
      const deletedMenu = deletedMenuList ? JSON.parse(deletedMenuList) : [];
      const deletedProductTypes = deletedProductTypesList
        ? JSON.parse(deletedProductTypesList)
        : [];
      const deleteClients = deleteClientsList
        ? JSON.parse(deleteClientsList)
        : [];
      const deleteStocks = deleteStockList ? JSON.parse(deleteStockList) : [];
      const deleteStockRecords = deleteStockRecordList
        ? JSON.parse(deleteStockRecordList)
        : [];
      const deleteOrders = deleteOrderList ? JSON.parse(deleteOrderList) : [];

      const filterBrands = _.filter(brands.Items, (item) => item.isOffline);
      const filterProductCategories = _.filter(
        productCategories.Items,
        (item) => item.isOffline
      );
      const filterProducts = _.filter(products.Items, (item) => item.isOffline);
      const filterMenu = _.filter(menu.Items, (item) => item.isOffline);
      const filterProductTypes = _.filter(
        productTypes.Items,
        (item) => item.isOffline
      );
      const filterCompletedAppointments = _.filter(
        completedAppointments.Items,
        (item) => item.isOffline
      );
      const filterUnCompletedAppointments = _.filter(
        unCompletedAppointments.Items,
        (item) => item.isOffline
      );
      const filterClients = _.filter(clients.Items, (item) => item.isOffline);
      const filterStocks = _.filter(stocks.Items, (item) => item.isOffline);
      const filterStockRecords = _.filter(
        stockRecords.Items,
        (item) => item.isOffline
      );

      // const processOrders = await getValueFromLocalStorage("ORDER_OPERATION");

      await commonRepository.createOfflineProductType(filterProductTypes);
      await commonRepository.deleteOfflineProductType(deletedProductTypes);

      await commonRepository.createOfflineBrands(filterBrands);
      await commonRepository.deleteOfflineBrands(deletedBrands);

      await commonRepository.createOfflineProductCategory(
        filterProductCategories
      );
      await commonRepository.deleteOfflineProductCategory(
        deletedProductCategories
      );

      await commonRepository.createOfflineProduct(filterProducts);
      await commonRepository.deleteOfflineProduct(deletedProducts);

      await commonRepository.createOfflineMenu(filterMenu);
      await commonRepository.deleteOfflineMenu(deletedMenu);

      await commonRepository.createOfflineClients(filterClients);
      await commonRepository.deleteOfflineClients(deleteClients);

      await commonRepository.updateShopCategory(shopCategories);
      await ActivityRepository.createOfflineActivity();

      // await commonRepository.sync();
      await new Syncer().sync();

      // await commonRepository.processOrderActions();

      // const errorListCompleteOrders = await commonRepository.updateCompletedOrders(filterCompletedAppointments);
      // console.log({errorListCompleteOrders})
      // if (errorListCompleteOrders?.length > 0) {
      //   MixpanelService.track(MP_EVENTS.OFFLINE_ERROR_COMPLETE_ORDERS, {data: JSON.stringify(errorListCompleteOrders)});
      // }

      // const errorListIncompleteOrders = await commonRepository.updateUnCompletedOrders(filterUnCompletedAppointments);
      // console.log({errorListIncompleteOrders})
      // if (errorListIncompleteOrders?.length > 0) {
      //   MixpanelService.track(MP_EVENTS.OFFLINE_ERROR_INCOMPLETE_ORDERS, {data: JSON.stringify(errorListIncompleteOrders)});
      // }
      // await commonRepository.deleteOfflineOrders(deleteOrders);

      // await commonRepository.createRefundOrder(refundOrders);

      await commonRepository.createOfflineStock(filterStocks);
      await commonRepository.deleteOfflineStock(deleteStocks);
      await commonRepository.createOfflineStockRecord(filterStockRecords);
      await commonRepository.deleteOfflineStockRecord(deleteStockRecords);
      await commonRepository.createOfflineProductOption(shopId);
      // await TableRepository.createTables(tables);

      const tables = await TableRepository.getOfflineData();
      if (!isEmpty(tables) && !isEmpty(get(tables, "shopId", null))) {
        const response = await TableRepository.createTables(tables);
        if (!response.error) {
          // await GraphqlService.deleteOfflineTable();
          await GraphqlFunctions.commonGraphqlService("DELETE_TABLE_OFFLINE");
        }
        return Promise.resolve(response);
      }

      return [];
    }
  ),
  selectMainMenuView: createAction(
    ModuleEvents.SELECT_MAIN_MENU_VIEW,
    async (view) => {
      return view;
    }
  ),
  setOnline: createAction(ModuleEvents.SET_ONLINE, async (status: boolean) => {
    return status;
  }),
  getNetworks: createAction(ModuleEvents.GET_NETWORKS, async (data: any) => {
    return data;
  }),
  getCurrentConnection: createAction(
    ModuleEvents.GET_CURRENT_NETWORK,
    async (data: any) => {
      return data;
    }
  ),
};

export default Actions;
