import React, {useEffect, useState} from 'react';
import styles from "../Styles/Styles.css";

const SelectedStylistCard = (props) => {
  const {selectedStylist} = props;

  return (
    <div className={styles.appointmentDateCardContainer}>
      <div className={styles.appointmentDateText}>User</div>
      <div className={styles.productTexts}>
        {selectedStylist.name}
      </div>
    </div>
  )
};

export default SelectedStylistCard;
