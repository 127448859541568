import CustomerRepository from '../../internal/repos/CustomerRepository';

export const ModuleEvents = {
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  SEARCH_CUSTOMERS: 'SEARCH_CUSTOMERS',

  SELECT_CUSTOMER: 'SELECT_CUSTOMER',
  FETCH_CUSTOMERS: 'FETCH_CUSTOMERS',
  ON_SELECT_CUSTOMER: 'ON_SELECT_CUSTOMER',
  CREATE_QUICK_CUSTOMER: 'CREATE_QUICK_CUSTOMER',
  REMOVE_QUICK_CUSTOMER: 'REMOVE_QUICK_CUSTOMER',
  REMOVE_SELECTED_CUSTOMER: 'REMOVE_SELECTED_CUSTOMER'

};

const createAction:any = (type, action:any = () => {
}, meta) => {
  return (...args) => ({type, payload: action(...args), meta});
};

export default {
  createCustomer: createAction(ModuleEvents.CREATE_CUSTOMER, async (param) => {
    const result = await CustomerRepository.createCustomer(param);
    return result;
  }, {loading: true}),

  updateCustomer: createAction(ModuleEvents.UPDATE_CUSTOMER, async (param) => {
    const result = await CustomerRepository.updateCustomer(param);
    return result;
  }, {loading: true}),

  deleteCustomer: createAction(ModuleEvents.DELETE_CUSTOMER, async (id, shopId) => {
    const result = await CustomerRepository.deleteCustomer(id, shopId);
    return result;
  }, {loading: true}),

  searchCustomers: createAction(ModuleEvents.SEARCH_CUSTOMERS, async (shopId, searchText, limit = 50, lastKey = null) => {
    const result = await CustomerRepository.searchCustomers( shopId, searchText, limit, lastKey);
    return result;
  }, {loading: true}),

  selectCustomer: createAction(ModuleEvents.SELECT_CUSTOMER, async (data) => {
    return data;
  }, {loading: true}),

  fetchCustomers: createAction(ModuleEvents.FETCH_CUSTOMERS, async (mid, limit = 50, lastKey = null) => {
    const result = await CustomerRepository.fetchCustomers(mid, limit, lastKey);
    return result;
  }, {loading: true}),

  onSelectCustomer: createAction(ModuleEvents.ON_SELECT_CUSTOMER, async (data) => {
    return data;
  }),

  createQuickCustomer: createAction(ModuleEvents.CREATE_QUICK_CUSTOMER, async (param) => {
    const result = await CustomerRepository.createQuickCustomer(param);
    return result;
  }, {loading: true}),

  removeQuickCustomer: createAction(ModuleEvents.REMOVE_QUICK_CUSTOMER, async (param) => {
    const result = await CustomerRepository.removeQuickCustomer(param);
    return result;
  }, {loading: true}),

  removeSelectedCustomer: createAction(ModuleEvents.REMOVE_SELECTED_CUSTOMER, async (data) => {
    return data;
  }, {loading: true}),
};
