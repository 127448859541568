import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import "../../Styles/Styles.css";
import { TimePicker } from "antd";
import { connect } from "react-redux";
import Calendar from "react-calendar";
import { Actions } from "../../../internal/app/Actions";
import { DateTimeContainer } from "../../Styles/Styled";

const DateAndTimeView = (props) => {
  const { selectedAppointment, setOrderDateAndTime } = props;
  const [duration, setDuration] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(new Date());

  useEffect(() => {
    if (!_.isEmpty(selectedAppointment)) {
      setOrderDateAndTime({
        date: selectedAppointment.date,
        time: selectedAppointment.time,
      });
    }
  }, [selectedAppointment]);

  const onSetCurrentType = () => {
    const date = moment(new Date(Date.now()).toDateString()).valueOf();
    setOrderDateAndTime({ date: date, time: moment().format("hh:mm A") });
  };

  const setTime = (text: any) => {
    setDuration(text);
    const date = moment(appointmentDate.toDateString()).valueOf();
    setOrderDateAndTime({ date: date, time: moment(text).format("hh:mm A") });
  };

  const setDate = (date: any) => {
    setAppointmentDate(date);
    const dateValue = moment(date.toDateString()).valueOf();
    setOrderDateAndTime({
      date: dateValue,
      time: !_.isEmpty(duration)
        ? moment(duration).format("hh:mm A")
        : moment().format("hh:mm A"),
    });
  };

  return (
    <DateTimeContainer>
      <div id={"dateTimeHeaderText"}>
        {moment(appointmentDate).format("dddd DD, YYYY")}{" "}
        {_.isEmpty(duration)
          ? moment().format("hh:mm A")
          : moment(duration).format("hh:mm A")}
      </div>

      <div id={"calendarMainContainer"}>
        <div id={"calendarContainer"}>
          <Calendar
            minDate={new Date()}
            value={appointmentDate}
            style={{ width: "100%" }}
            className={"react-calendar"}
            onChange={(date) => setDate(date)}
          />
          <div id={"calenderBottomLine"} />

          <div id={"timePickerWrapper"}>
            <TimePicker
              value={duration}
              format={"HH:mm"}
              placeholder="Duration"
              className={"timePickerStyle"}
              onChange={(text) => setTime(text)}
            />
          </div>
        </div>

        <div id={"useCurrentBtnContainer"}>
          <div onClick={() => onSetCurrentType()} id={"useCurrentBtn"}>
            <div id={"useCurrentDateText"}>Use current date & time</div>
          </div>
        </div>
      </div>
    </DateTimeContainer>
  );
};
export default connect(
  (state: any) => ({
    orderDateAndTime: state.product.get("orderDateAndTime"),
    selectedAppointment: state.orders.get("selectedAppointment"),
  }),
  {
    setOrderDateAndTime: Actions.product.setOrderDateAndTime,
  }
)(DateAndTimeView);
