import styled from "styled-components";
import BaseColors from "../../internal/helpers/BaseColors";

export const WarrantyContainer = styled.div`
  width: 100%;
  height: 100%;

  & > button {
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    margin-top: 5px;
    font-weight: 600;
    padding: 16px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    font-family: "Montserrat";
    background-color: ${BaseColors.white};
    
    color: ${(props: any) =>
      !props.disabled ? BaseColors.cornflowerBlue : BaseColors.gunsmoke};
    border: 1px solid
      ${(props: any) =>
        !props.disabled ? BaseColors.cornflowerBlue : BaseColors.gunsmoke};
    :hover {
      cursor: ${(props: any) => (!props.disabled ? "pointer" : "not-allowed")};
      color: ${(props: any) =>
        !props.disabled ? BaseColors.white : BaseColors.gunsmoke};
      background-color: ${(props: any) =>
        !props.disabled ? BaseColors.cornflowerBlue : BaseColors.white};
    }

    :focus {
      outline: none;
    }
 
  }
`;

export const SelectedWarrantyWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  cursor: ${(props: any) => (props.disabled ? "not-allowed" : "pointer")};

  & > div:first-child {
    width: 100%;
    display: flex;
    padding-top: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > div:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: calc(100% - 140px);
      justify-content: flex-start;

      & > div:first-child {
        width: 42px;
        height: 42px;
        display: flex;
        cursor: pointer;
        margin-right: 13px;
        align-items: center;
        justify-content: center;
        background-color: ${BaseColors.monaLisa};
        :hover {
          cursor: pointer;
        }
      }

      & > div:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100% - 55px);

        & > div:first-child {
          font-size: 14px;
          font-weight: 600;
          text-align: start;
          font-family: "Montserrat";
          color: ${BaseColors.nero};
        }
        & > div:last-child {
          font-size: 12px;
          font-weight: 400;
          font-family: "Montserrat";
          color: ${BaseColors.nero};
        }
      }
    }
    & > div:last-child {
      text-align: end;
      font-size: 14px;
      font-weight: 600;
      font-family: "Montserrat";
      color: ${BaseColors.nero};
    }
  }
  & > div:last-child {
    width: 100%;
    height: 1px;
    border: none;
    margin-top: 16px;
    background-color: ${BaseColors.whisper};
  }
`;

export const WarrantyItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  & > div:first-child {
    width: 100%;
    display: flex;
    padding-top: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    :hover {
      cursor: pointer;
    }
    & > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: calc(100% - 140px);
      justify-content: flex-start;

      & > div:first-child {
        font-size: 14px;
        font-weight: 600;
        text-align: start;
        margin-bottom: 6px;
        font-family: "Montserrat";
        color: ${BaseColors.nero};
      }
      & > div:last-child {
        font-size: 12px;
        font-weight: 400;
        font-family: "Montserrat";
        color: ${BaseColors.nero};
      }
    }
  }
  & > div:last-child {
    width: 100%;
    height: 1px;
    border: none;
    margin-top: 16px;
    background-color: ${BaseColors.whisper};
  }
`;

export const WarrantyApplyButton = styled.button<{ disabled: boolean }>`
  border-radius: 1rem;
  padding: 0.3rem 1rem;
  background: ${BaseColors.white};
  color: ${(props: any) =>
    !props.disabled ? BaseColors.cornflowerBlue : BaseColors.gunsmoke};
  border: 1px solid
    ${(props: any) =>
      !props.disabled ? BaseColors.cornflowerBlue : BaseColors.gunsmoke};
  :hover {
    cursor: ${(props: any) => (!props.disabled ? "pointer" : "not-allowed")};
    color: ${(props: any) =>
      !props.disabled ? BaseColors.white : BaseColors.gunsmoke};
    background-color: ${(props: any) =>
      !props.disabled ? BaseColors.cornflowerBlue : BaseColors.white};
  }
`;
