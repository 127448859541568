import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  AppointmentDetailItem,
  AppointmentRoomWrapper,
  AppointmentDetailComponentContainer,
} from "../Styles/Styled";
import moment from "moment";
import { useIntl } from "react-intl";
import { connect } from "react-redux/es/index";
import IconPlus from "../../../assets/svg/IconPlusColor.svg";
import IconDelete from "../../../assets/svg/IconDelete.svg";
import AppointmentDateCard from "../card/AppointmentDateCard";
import SelectedStylistCard from "../card/SelectedStylistCard";
import SelectedCustomerCard from "../card/SelectedCustomerCard";
import { getCurrencyWithPrice } from "../../internal/manager/PaymentManager";

const AppointmentDetailsComponent = (props) => {
  const {
    shop,
    currentUser,
    selectedArea,
    selectedRoom,
    quickCustomer,
    onDeleteVehicle,
    selectedVehicle,
    selectedEmployee,
    selectedCustomer,
    onDeleteEmployee,
    onDeleteCustomer,
    onDeleteDateTime,
    onDeleteDiscount,
    orderDateAndTime,
    onClickAddVehicle,
    onClickAddCustomer,
    onClickAddEmployee,
    onClickAddDateTime,
    onClickAddDiscount,
    appointmentDiscounts,
  } = props;

  const isLineItemMode = _.get(currentUser, "viewMode", "GRID") === "LIST";

  return (
    <AppointmentDetailComponentContainer>
      {isLineItemMode ? (
        <LineItemDetailView
          shop={shop}
          selectedVehicle={selectedVehicle}
          onDeleteVehicle={onDeleteVehicle}
          selectedEmployee={selectedEmployee}
          selectedCustomer={selectedCustomer}
          onDeleteEmployee={onDeleteEmployee}
          onDeleteCustomer={onDeleteCustomer}
          onDeleteDateTime={onDeleteDateTime}
          onDeleteDiscount={onDeleteDiscount}
          orderDateAndTime={orderDateAndTime}
          onClickAddVehicle={onClickAddVehicle}
          onClickAddCustomer={onClickAddCustomer}
          onClickAddEmployee={onClickAddEmployee}
          onClickAddDateTime={onClickAddDateTime}
          onClickAddDiscount={onClickAddDiscount}
          appointmentDiscounts={appointmentDiscounts}
        />
      ) : (
        <GridItemView
          selectedRoom={selectedRoom}
          orderDateAndTime={orderDateAndTime}
          selectedEmployee={selectedEmployee}
          selectedCustomer={selectedCustomer}
        />
      )}
    </AppointmentDetailComponentContainer>
  );
};

const LineItemDetailView = (props) => {
  const {
    shop,
    selectedVehicle,
    onDeleteVehicle,
    selectedEmployee,
    selectedCustomer,
    onDeleteEmployee,
    onDeleteCustomer,
    onDeleteDiscount,
    onDeleteDateTime,
    orderDateAndTime,
    onClickAddVehicle,
    onClickAddCustomer,
    onClickAddEmployee,
    onClickAddDateTime,
    onClickAddDiscount,
    appointmentDiscounts,
  } = props;
  const { formatMessage: f } = useIntl();
  const isCustomerEmpty = _.isEmpty(selectedCustomer);

  return (
    <div>
      <AppointmentDetailItem>
        <div onClick={() => onClickAddCustomer()}>
          <h3>{f({ id: "ORDER.DETAIL.CUSTOMER" })}</h3>
          <img src={IconPlus} />
        </div>
        {!isCustomerEmpty ? (
          <div>
            <div>
              <div>
                {`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
                <br />
                {`${selectedCustomer.mobileNumber}`}
              </div>
            </div>
            <div onClick={() => onDeleteCustomer()}>
              <img src={IconDelete} />
            </div>
          </div>
        ) : null}
        <hr />
      </AppointmentDetailItem>

      <AppointmentDetailItem disabled={isCustomerEmpty}>
        <div onClick={() => !isCustomerEmpty && onClickAddVehicle()}>
          <h3>{f({ id: "ORDER.DETAIL.VEHICLE" })}</h3>
          <img src={IconPlus} />
        </div>
        {!_.isEmpty(selectedCustomer) && !_.isEmpty(selectedVehicle) ? (
          <div>
            <div>
              <div>
                {`${selectedVehicle.brand}`}
                <br />
                {`${selectedVehicle.plateNumber}`}
              </div>
            </div>
            <div onClick={() => onDeleteVehicle()}>
              <img src={IconDelete} />
            </div>
          </div>
        ) : null}
        <hr />
      </AppointmentDetailItem>

      <AppointmentDetailItem>
        <div onClick={() => onClickAddDiscount()}>
          <h3>{f({ id: "ORDER.DETAIL.DISCOUNT" })}</h3>
          <img src={IconPlus} />
        </div>
        {!_.isEmpty(appointmentDiscounts)
          ? appointmentDiscounts.map((discount: any, index: number) => {
              const price = getCurrencyWithPrice(
                discount.amount,
                shop.currency
              );
              return (
                <div key={index}>
                  <div>
                    <div>
                      {`${discount.title}`}
                      <br />
                      {`${price}`}
                    </div>
                  </div>
                  <div onClick={() => onDeleteDiscount(discount)}>
                    <img src={IconDelete} />
                  </div>
                </div>
              );
            })
          : null}
        <hr />
      </AppointmentDetailItem>

      <AppointmentDetailItem>
        <div onClick={() => onClickAddEmployee()}>
          <h3>{f({ id: "ORDER.DETAIL.EMPLOYEE" })}</h3>
          <img src={IconPlus} />
        </div>
        {!_.isEmpty(selectedEmployee) ? (
          <div>
            <div>{`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</div>
            <div onClick={() => onDeleteEmployee()}>
              <img src={IconDelete} />
            </div>
          </div>
        ) : null}
        <hr />
      </AppointmentDetailItem>

      <AppointmentDetailItem>
        <div onClick={() => onClickAddDateTime()}>
          <h3>{f({ id: "ORDER.DETAIL.DATE_AND_TIME" })}</h3>
          <img src={IconPlus} />
        </div>
        {!_.isEmpty(orderDateAndTime) ? (
          <div>
            <div>
              <div>
                {`${moment(orderDateAndTime.date).format("dddd MMM DD, YYYY")}`}
                <br />
                {`${orderDateAndTime.time}`}
              </div>
            </div>
            <div onClick={() => onDeleteDateTime()}>
              <img src={IconDelete} />
            </div>
          </div>
        ) : null}
        <hr />
      </AppointmentDetailItem>
    </div>
  );
};

const GridItemView = (props: any) => {
  const { selectedEmployee, selectedCustomer, orderDateAndTime, selectedRoom } =
    props;

  return (
    <div>
      {!_.isEmpty(selectedEmployee) ? (
        <SelectedStylistCard selectedStylist={selectedEmployee} />
      ) : null}
      {!_.isEmpty(selectedCustomer) ? (
        <SelectedCustomerCard selectedCustomer={selectedCustomer} />
      ) : null}
      {!_.isEmpty(orderDateAndTime) ? <AppointmentDateCard /> : null}
      {!_.isEmpty(selectedRoom) ? (
        <AppointmentRoomWrapper>
          <div>Room</div>
          <div>{selectedRoom.rmNumber}</div>
        </AppointmentRoomWrapper>
      ) : null}
    </div>
  );
};

export default connect((state: any) => ({
  shop: state.login.get("merchantShop"),
  currentUser: state.login.get("currentUser"),
  selectedArea: state.rooms.get("selectedArea"),
  selectedRoom: state.rooms.get("selectedRoom"),
  quickCustomer: state.customer.get("quickCustomer"),
  selectedVehicle: state.orders.get("selectedVehicle"),
  orderDateAndTime: state.product.get("orderDateAndTime"),
  purchaseProducts: state.product.get("purchaseProducts"),
  selectedCustomer: state.customer.get("selectedCustomer"),
  selectedEmployee: state.employee.get("selectedEmployee"),
  appointmentDiscounts: state.product.get("appointmentDiscounts"),
}))(AppointmentDetailsComponent);
