import React from 'react';
import {connect} from 'react-redux';
import {capitalize, startCase} from 'lodash';
import moment from 'moment';
import DefaultImage from '../../../../assets/images/ducati.jpeg';
// import { PageWrapper, RowSpan, LogoArea, InvoiceTitle, InvoiceTo, RecieptDetails, TableHead, TabledRow, OrderSummary, SignArea } from './styled';
import {getCurrencyWithPrice} from '../../../internal/manager/PaymentManager';

const PaymentRecieptA4 = ({
  params,
  image,
  givenPrice,
  selectedCustomer,
  total,
  purchaseProducts,
  purchaseServices,
  shopData,
  subTotal,
  appointmentDiscounts = [],
  selectedVehicle,
}) => {
  const imageSource = image || DefaultImage;
  const date = moment(params.date).format('Do MMMM YYYY');
  const {mileage, nextService} = selectedVehicle;
  const mileageText = isFinite(mileage) ? mileage : 'N/A'
  const nextServiceText = isFinite(nextService) ? nextService : 'N/A'
  return (
    <div className="reciept-wrapper">
      <div className="reciept-row-span">
        <div className="reciept-logo-area">
          <div><img src={imageSource} /></div>
          <h1>{startCase(shopData.shopName)}</h1>
          <p>{capitalize(shopData.description)}</p>
        </div>
        <div className="reciept-invoice-title">
          <h1>INVOICE</h1>
          <hr/>
          <div>
            <p>{shopData.fbLink}</p>
            <p>{shopData.phoneNumber}</p>
            <p>{shopData.address}</p>
          </div>
          <hr/>
        </div>
      </div>
      <div className="reciept-row-span">
        <div className="reciept-invoice-to">
          <h4>INVOICE TO</h4>

          <p>{capitalize(selectedCustomer.firstName)} {capitalize(selectedCustomer.lastName)}</p>
          <p>{selectedCustomer.mobileNumber}</p>
          <p>{selectedVehicle.plateNumber}</p>
          {
            selectedVehicle?.vid && (
              <>
                <p>{selectedVehicle.brand} {selectedVehicle.modal} {selectedVehicle.brand}</p>
                <p>Mileage: {mileageText}</p>
                <p>Next Service: {nextServiceText}</p>
              </>
            )
          }
        </div>

        <div className="reciept-details">
          <div>
            <div>RECEIEPT NUMBER</div>
            <div>{params.recieptId}</div>
          </div>
          <div>
            <div>RECEIEPT DATE</div>
            <div>{date}</div>
          </div>
        </div>
      </div>

      <div className="reciept-table-head">
        <div>Description</div>
        <div>QTY</div>
        <div>Unit Price</div>
        <div>Total</div>
      </div>
      {purchaseServices.map((line) => (
          <div className="reciept-table-row">
            <div>{line.serviceTitle}</div>
            <div>{line.quantity}</div>
            <div>{getCurrencyWithPrice(line.servicePrice, shopData.currency, false)}</div>
            <div>{getCurrencyWithPrice(line.price, shopData.currency, false)}</div>
          </div>
      ))}
      {purchaseProducts.map((line) => (
          <div className="reciept-table-row">
            <div>{line.productName}</div>
            <div>{line.quantity}</div>
            <div>{getCurrencyWithPrice(line.productSalePrice, shopData.currency, false)}</div>
            <div>{getCurrencyWithPrice(line.price, shopData.currency, false)}</div>
          </div>
      ))}
      <div className="reciept-order-summary">
        <div>
          <div>
            <div>Sub Total</div>
            <div>{getCurrencyWithPrice(subTotal, shopData.currency)}</div>
          </div>
          {
            appointmentDiscounts.map((item) => (
              <div>
                <div>{item.title}</div>
                <div>{getCurrencyWithPrice(item.amount, shopData.currency)}</div>
              </div>
            ))
          }
          <div>
            <div>Total</div>
            <div>{getCurrencyWithPrice(total, shopData.currency)}</div>
          </div>
          <hr/>
          <div>
            <div>Paid Amount</div>
            <div>{getCurrencyWithPrice(givenPrice, shopData.currency)}</div>
          </div>
          <div>
            <div>Remainder</div>
            <div>{getCurrencyWithPrice(`${givenPrice - total}`, shopData.currency)}</div>
          </div>
        </div>
      </div>
      <div className="reciept-customer-sign">
        <p>Thank you for the order and see you next time!</p>
        <div>
          <hr />
          <p>Customer Signature</p>
        </div>
      </div>
      <p className="reciept-footer">Powered by Netlise</p>
    </div>
  );
};

export default PaymentRecieptA4;

//  export default connect(
//  (state) => ({
//    shopData: state.login.get('merchantShop'),
//    currentUser: state.login.get('currentUser'),
//    batchSession: state.login.get('batchSession'),
//    selectedStylist: state.employee.get('selectedStylist'),
//    selectedVehicle: state.orders.get('selectedVehicle'),
//    purchaseProducts: state.product.get('purchaseProducts'),
//    purchaseServices: state.service.get('purchaseServices'),
//    selectedCustomer: state.customer.get('selectedCustomer'),
//    appointmentDateAndTime: state.salon.get('appointmentDateAndTime'),
//    params: {
//     recieptId: '2728'
//    }
//  }),
//  {}
//  )(PaymentRecieptA4);
