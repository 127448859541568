import styled from "styled-components";
import BaseColors from "../../../internal/helpers/BaseColors";

export const ProductContainer = styled.div<{ height: string }>`
  width: 100%;
  height: 100vh;
  background-color: #eff3f6;
`;

export const ProductBottomContainer = styled.div<{ height: string }>`
  // width: 100%;
  height: calc(100vh - 61px);
  display: flex;
  flex-direction: row;
  padding: 10px;

  & > div {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: #ffffff;
  }
`;

export const AppointmentProductContainer = styled.div<{
  visibleFilter: boolean;
}>`
  width: 100%;
  /* display: flex; */
  /* flex-wrap: wrap; */
  padding-top: 16px;
  padding-bottom: 9px;
  height: calc(100vh - 81px);

  & > div:nth-child(2) {
    display: flex;
    /* margin-top: 21px; */
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    overflow-y: hidden;
    // border-bottom: 1px solid #eee;

    & > div:first-child {
      overflow-x: scroll;
      display: flex;
      align-items: center;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      max-height: 140px;
      overflow-x: auto;
    }

    & > img {
      padding: 10px 10px;
      margin-bottom: 12px;
      background-color: ${(props) =>
        props.visibleFilter ? BaseColors.pictonBlue : BaseColors.alabaster};

      :hover {
        cursor: pointer;
      }

      :focus {
        background-color: #49a4d4;
      }
    }
  }

  .productLoading {
    position: absolute;
    top: 50%;
    left: 50%;
  }
`;

export const AppointmentProductCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  align-items: flex-start;
  align-content: flex-start;
`;

export const AppointmentProductKeyBoardContainer = styled.div`
  width: calc(100% - 30px);
  height: 54px;
  border: 1px solid #49a4d4;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  position: absolute;
  bottom: 0;
  background-color: #ffffff;

  :hover {
    cursor: pointer;
  }

  & > div {
    color: #49a4d4;
    font-size: 16px;
    font-weight: 500;
    font-family: "Montserrat";
    font-weight: 600;
  }
`;

export const MenuWrapper = styled.div<{ selected: boolean }>`
  gap: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 132px);
  justify-content: space-between;

  & > div {
    width: 33%;
    height: 100%;
    display: flex;
    border-radius: 3px;
    align-items: center;
    flex-direction: column;
    background-color: #fafafa;
  }
`;

export const MenuItem = styled.div<{ selected: boolean }>`
  gap: 10px;
  width: 100%;
  height: 52px;
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) =>
    props.selected ? BaseColors.pictonBlue : BaseColors.wildSand};

  & > div:first-child {
    font-size: 14px;
    font-weight: 600;
    font-family: "Montserrat";
    color: ${(props) => (props.selected ? BaseColors.white : BaseColors.black)};
  }

  & > div:nth-child(2) {
    gap: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > img {
      :hover {
        cursor: pointer;
      }
    }
  }

  :hover {
    cursor: pointer;
    background-color: ${BaseColors.pictonBlue};

    & > div:first-child {
      color: ${BaseColors.white};
    }
  }
`;

export const MenuButton = styled.button<{ selected: boolean }>`
  gap: 10px;
  height: 34px;
  display: flex;
  margin-top: 17px;
  padding-top: 8px;
  margin-left: 24px;
  margin-right: 32px;
  border-radius: 4px;
  padding-bottom: 8px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #707070;
  width: -webkit-fill-available;
  background-color: ${BaseColors.white};

  :hover {
    cursor: pointer;
    border: 1px solid #49a4d4;
    background-color: ${BaseColors.pictonBlue};

    & > div {
      color: ${BaseColors.white};
    }
    & > svg {
      color: ${BaseColors.white};
    }
  }

  & > div {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    font-family: "Montserrat";
    color: ${BaseColors.outerSpace};

    :hover {
      cursor: pointer;
      color: ${BaseColors.white};
      background-color: ${BaseColors.pictonBlue};
    }
  }

  & > svg {
    font-size: 18px;
    color: ${BaseColors.outerSpace};

    :hover {
      cursor: pointer;
      color: ${BaseColors.white};
      background-color: ${BaseColors.pictonBlue};
    }
  }
`;

export const MenuCreateWrapper = styled.div<{ selected: boolean }>`
  height: 38px;
  display: flex;
  margin-top: 7px;
  margin-bottom: 7px;
  padding-left: 8px;
  padding-right: 8px;
  align-items: right;
  flex-direction: row;
  justify-content: space-between;
  width: -webkit-fill-available;

  & > input {
    height: 100%;
    display: flex;
    padding-left: 12px;
    border-radius: 6px;
    align-items: center;
    padding-right: 12px;
    flex-direction: column;
    width: calc(100% - 66px);
    border: 1px solid #ebeaea;
    background-color: ${BaseColors.white};
  }

  & > div {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    & > img {
      width: 20px;
      height: 100%;

      :hover {
        cursor: pointer;
      }
    }
  }
`;

export const CategoryCreateWrapper = styled.div<{ selected: boolean }>`
  width: 100%;
  display: flex;
  margin-top: 7px;
  margin-left: 24px;
  margin-right: 32px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: -webkit-fill-available;

  & > div {
    width: 100%;
    height: 38px;
    display: flex;
    border-radius: 6px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: space-between;
    background-color: ${(props) =>
      props.selected ? BaseColors.pictonBlue : BaseColors.wildSand};

    :hover {
      cursor: pointer;
      background-color: ${BaseColors.pictonBlue};
    }
  }
`;

export const TextIconWrapper = styled.div<{ selected: boolean }>`
  & > div {
    gap: 15px;
    height: 38px;
    display: flex;
    margin-top: 7px;
    margin-bottom: 7px;
    padding-left: 8px;
    padding-right: 8px;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
    width: -webkit-fill-available;

    & > img {
      width: 20px;
      height: 100%;

      :hover {
        cursor: pointer;
      }
    }

    & > div {
      width: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      & > img {
        width: 20px;
        height: 100%;

        :hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export const ProductOptionWrapper = styled.div`
  width: 100%;
  margin: 10px;
  display: flex;
  border-radius: 3px;
  flex-direction: column;
  width: calc(100vw - 20px);
  height: calc(100vh - 81px);
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.16);
`;

export const ProductOptionHeaderWrapper = styled.div`
  width: 100%;
  height: 51px;
  display: flex;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  flex-direction: row;
  background-color: #fafafa;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    :hover {
      cursor: pointer;
    }

    & > div {
      font-size: 14px;
      color: #353c42;
      font-weight: 700;
      margin-left: 15px;
      font-family: Montserrat;
    }
  }
`;

export const ProductOptionFormWrapper = styled.div`
  overflow-y: auto;
  padding-top: 40px;
  padding-left: 51px;
  padding-right: 51px;
  padding-bottom: 40px;
  width: calc(100vw - 20px);
  height: calc(100vh - 132px);

  & > div {
    width: 100%;
    max-width: 1024px;
  }
`;

export const ProductOptionForm = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 48px;
  flex-direction: column;

  & > img {
    display: flex;
    cursor: pointer;
    align-self: flex-end;
  }

  & > div {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    & > div:first-child {
      width: 200px;
      color: #434343;
      font-size: 16px;
      font-weight: 500;
      line-height: 44px;
      font-family: Montserrat;
    }

    .addProductOptionInput {
      height: 45px;
      width: 544px;
      color: #434343;
      font-size: 14px;
      font-weight: 500;
      font-weight: 400;
      border-radius: 6px;
      font-style: normal;
      padding-left: 23px;
      padding-right: 23px;
      font-family: Montserrat;
      border: 1px solid #ebeaea;
      background-color: #ffffff;
      box-shadow: 0px 0px 10px 5px #c4d3f020;
    }

    .addProductOptionNameWrapper {
      width: 544px;
      padding-left: 23px;
      border-radius: 6px;
      padding-right: 23px;
      border: 1px solid #ebeaea;
      background-color: #f7fbfd;
      box-shadow: 0px 0px 10px 5px #c4d3f020;
    }

    .optionButtonWrapper {
      height: 40px;
      display: flex;
      cursor: pointer;
      font-size: 14px;
      border-width: 0;
      color: #ffffff;
      font-weight: 400;
      align-items: center;
      border-radius: 49px;
      padding-inline: 24px;
      font-family: Montserrat;
      justify-content: center;
      background-color: #49a4d4;
    }

    .emptyLine {
      width: 140px;
    }

    & > span {
      color: #ff9696;
      font-size: 13px;
      margin-top: 4px;
      font-weight: 400;
      margin-left: 23px;
      font-family: Montserrat;
    }
  }
`;

export const ProductOptionCreateInput = styled.div`
  width: 544px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .addProductOptionPlaceholder {
    color: #434343;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
  }

  & > span {
    color: #ff9696;
    font-size: 13px;
    margin-top: 4px;
    font-weight: 400;
    margin-left: 23px;
    font-family: Montserrat;
  }
`;

export const ProductOptionInput = styled.div`
  width: 544px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & > div:first-child {
    width: 100%;
    height: 45px;
    color: #434343;
    font-size: 14px;
    font-weight: 500;
    font-weight: 400;
    border-radius: 6px;
    font-style: normal;
    padding-left: 23px;
    padding-right: 23px;
    font-family: Montserrat;
    border: 1px solid #ebeaea;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 5px #c4d3f020;
  }
  & > span {
    color: #ff9696;
    font-size: 13px;
    margin-top: 4px;
    font-weight: 400;
    margin-left: 23px;
    font-family: Montserrat;
  }
`;

export const ProductOptionNameWrapper = styled.div`
  width: 544px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & > div:first-child {
    width: 100%;
    padding-left: 23px;
    border-radius: 6px;
    padding-right: 23px;
    border: 1px solid #ebeaea;
    background-color: #f7fbfd;
    box-shadow: 0px 0px 10px 5px #c4d3f020;
  }
  & > span {
    color: #ff9696;
    font-size: 13px;
    margin-top: 4px;
    font-weight: 400;
    margin-left: 23px;
    font-family: Montserrat;
  }
`;

export const ProductOptionItemWrapper = styled.div<{ isLast: boolean }>`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${(props) =>
    props.isLast ? "0px solid #e4e8ea" : "1px solid #e4e8ea"};

  & > input {
    width: 80%;
    padding: 0;
    border: 0px;
    height: 35px;
    color: #434343;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    border-radius: 6px;
    font-style: normal;
    font-family: Montserrat;
    background-color: #f7fbfd;
  }

  & > img {
    width: 20px;
    height: 20px;
  }
`;

export const AddOptionSetWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div:first-child {
    width: 200px;
  }

  & > div:nth-child(2) {
    width: 544px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    & > div {
      color: #49a4d4;
      font-size: 14px;
      font-weight: 600;
      margin-right: 12px;
      font-family: Montserrat;
    }
  }
  & > div:last-child {
    width: 140px;
  }
`;

export const AddOptionButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 80px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  & > button {
    height: 40px;
    display: flex;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    border-width: 0;
    font-weight: 500;
    align-items: center;
    border-radius: 49px;
    padding-inline: 32px;
    font-family: Montserrat;
    justify-content: center;
  }
  & > button:first-child {
    margin-right: 20px;
    background-color: #ff5f5f;
  }
  & > button:last-child {
    background-color: #49a4d4;
  }
`;

export const ProductPriceFormWrapper = styled.div`
  overflow-y: auto;
  padding-top: 40px;
  padding-left: 32px;
  padding-right: 44px;
  padding-bottom: 40px;
  width: calc(100vw - 20px);
  height: calc(100vh - 132px);

  & > div {
    width: 100%;
    max-width: 1024px;

    & > div:first-child {
      color: #434343;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 28px;
      font-family: Montserrat;
    }
  }
`;

export const ProductPriceHeaderWrapper = styled.div<{ isLast: boolean }>`
  width: 100%;
  display: flex;
  padding-left: 23px;
  padding-right: 23px;
  margin-bottom: 16px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  & > div:first-child {
    width: 40%;
  }
  & > div:nth-child(2) {
    width: 100px;
  }

  & > div:last-child {
    width: 40%;
    text-align: end;
    padding-right: 10%;
  }

  & > div {
    color: #434343;
    font-size: 16px;
    font-weight: 600;
    text-align: start;
    font-family: Montserrat;
  }
`;

export const ProductPriceWrapper = styled.div<{ isLast: boolean }>`
  width: 100%;
  padding-left: 23px;
  border-radius: 6px;
  padding-right: 23px;
  border: 1px solid #ebeaea;
  background-color: #f7fbfd;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
`;

export const ProductPriceItemWrapper = styled.div<{ isLast: boolean }>`
  width: 100%;
  /* height: 55px; */
  padding: 15px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  border-bottom: ${(props) =>
    props.isLast ? "0px solid #e4e8ea" : "1px solid #e4e8ea"};

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    & > div:first-child {
      width: 40%;
      color: #434343;
      font-size: 14px;
      text-align: start;
      font-weight: 400;
      font-family: Montserrat;
    }

    & > div:nth-child(2) {
      width: 100px;
      color: #434343;
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      font-family: Montserrat;
    }

    & > div:last-child {
      width: 40%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      & > input {
        width: 80%;
        border: 0px;
        height: 25px;
        color: #434343;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
        text-align: end;
        border-radius: 6px;
        font-style: normal;
        font-family: Montserrat;
        background-color: #f7fbfd;
      }

      & > div:first-child {
        width: 80%;
        color: #434343;
        font-size: 14px;
        text-align: end;
        font-weight: 400;
        line-height: 25px;
        font-family: Montserrat;
      }

      & > img {
        width: 20px;
        height: 20px;
      }
    }
  }

  & > span {
    color: #ff9696;
    font-size: 13px;
    font-weight: 400;
    margin-right: 70px;
    font-family: Montserrat;
  }
`;

export const ProductAddPriceWrapper = styled.div<{ isLast: boolean }>`
  width: 100%;
  max-width: 768px;
  padding-left: 23px;
  border-radius: 6px;
  padding-right: 23px;
  border: 1px solid #ebeaea;
  background-color: #f7fbfd;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
`;

export const ProductAddPriceHeaderWrapper = styled.div<{ isLast: boolean }>`
  width: 100%;
  display: flex;
  padding-top: 23px;
  padding-right: 23px;
  margin-bottom: 16px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  & > div:first-child {
    width: 40%;
  }
  & > div:nth-child(2) {
    width: 100px;
    padding-left: 18px;
  }

  & > div:last-child {
    width: 40%;
    text-align: end;
    padding-right: 10%;
  }

  & > div {
    color: #434343;
    font-size: 16px;
    font-weight: 600;
    text-align: start;
    font-family: Montserrat;
  }
`;

export const ProductAddOptionWrapper = styled.div`
  width: 544px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > div {
    color: #49a4d4;
    font-size: 14px;
    font-weight: 600;
    margin-right: 12px;
    font-family: Montserrat;
  }
`;

export const WarrantyForm = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 48px;
  flex-direction: column;
`;

export const WarrantyDurationWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  flex-direction: row;

  & > div:first-child {
    width: 200px;
    color: #434343;
    font-size: 16px;
    font-weight: 500;
    line-height: 44px;
    font-family: Montserrat;
  }

  & > div:last-child {
    gap: 16px;
    width: 544px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      & > input {
        height: 45px;
        width: 125px;
        color: #434343;
        font-size: 14px;
        text-align: end;
        font-weight: 400;
        border-radius: 6px;
        font-style: normal;
        padding-left: 15px;
        padding-right: 23px;
        font-family: Montserrat;
        border: 1px solid #ebeaea;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 5px #c4d3f020;
      }
      & > span {
        color: #ff9696;
        font-size: 13px;
        margin-top: 4px;
        font-weight: 400;
        padding-left: 15px;
        font-family: Montserrat;
      }
    }
  }
`;

export const WarrantyTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    & > div:first-child {
      width: 200px;
      color: #434343;
      font-size: 16px;
      font-weight: 500;
      line-height: 44px;
      font-family: Montserrat;
    }

    & > div:last-child {
      width: 544px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      & > input {
        height: 45px;
        width: 544px;
        color: #434343;
        font-size: 14px;
        font-weight: 400;
        border-radius: 6px;
        font-style: normal;
        padding-left: 15px;
        padding-right: 23px;
        font-family: Montserrat;
        border: 1px solid #ebeaea;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 5px #c4d3f020;
      }

      & > textarea {
        width: 544px;
        height: 111px;
        outline: none;
        color: #434343;
        font-size: 14px;
        font-weight: 500;
        font-weight: 400;
        padding-top: 10px;
        font-style: normal;
        padding-left: 15px;
        border-radius: 6px;
        margin-bottom: 10px;
        padding-right: 20px;
        font-family: Montserrat;
        background-color: #ffffff;
        border: 1px solid #ebeaea;
        box-shadow: 0px 0px 10px 5px #c4d3f020;
      }

      & > span {
        color: #ff9696;
        font-size: 13px;
        margin-top: 4px;
        font-weight: 400;
        padding-left: 15px;
        font-family: Montserrat;
      }
    }
  }
`;

export const WarrantyButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    & > div:first-child {
      width: 200px;
      color: #434343;
      font-size: 16px;
      font-weight: 500;
      line-height: 44px;
      font-family: Montserrat;
    }

    & > div:last-child {
      width: 544px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;

      & > button {
        height: 40px;
        display: flex;
        color: #ffffff;
        cursor: pointer;
        font-size: 14px;
        border-width: 0;
        font-weight: 500;
        align-items: center;
        border-radius: 49px;
        padding-inline: 32px;
        font-family: Montserrat;
        justify-content: center;
      }
      & > button:first-child {
        margin-right: 20px;
        background-color: #ff5f5f;
      }
      & > button:last-child {
        background-color: #49a4d4;
      }
    }
  }
`;

export const ProductModifierWrapper = styled.div`
  width: 100%;
  margin: 10px;
  display: flex;
  border-radius: 3px;
  flex-direction: column;
  width: calc(100vw - 20px);
  height: calc(100vh - 81px);
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.16);
`;

export const ProductModifierHeaderWrapper = styled.div`
  width: 100%;
  height: 51px;
  display: flex;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  flex-direction: row;
  background-color: #fafafa;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    :hover {
      cursor: pointer;
    }

    & > div {
      font-size: 14px;
      color: #353c42;
      font-weight: 700;
      margin-left: 15px;
      font-family: Montserrat;
    }
  }
`;

export const SelectModifierModalWrapper = styled.div`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    padding-left: 20px;
    align-items: center;
    padding-right: 14px;
    flex-direction: row;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    background-color: ${BaseColors.balticSea};

    & > div {
      color: ${BaseColors.white};
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 500;
    }
  }

  & > div:last-child {
    display: flex;
    padding-top: 14px;
    padding-inline: 32px;
    padding-bottom: 40px;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: ${BaseColors.white};
  }
`;
export const SelectModifierItemWrapper = styled.div`
  gap: 8px;
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > div:first-child {
    font-size: 16px;
    font-weight: 600;
    font-family: Montserrat;
    color: ${BaseColors.outerSpace};
  }

  & > div:last-child {
    gap: 16px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 14px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    & > div {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      & > div:first-child {
        width: 30%;
        font-size: 16px;
        font-weight: 400;
        font-family: Montserrat;
        color: ${BaseColors.outerSpace};
      }

      & > div:nth-child(2) {
        width: 30%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        & > img {
          cursor: pointer;
        }

        & > div {
          width: 69px;
          height: 42px;
          display: flex;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          border-radius: 4px;
          align-items: center;
          font-family: Montserrat;
          justify-content: center;
          border: 0.5px solid #8a9ca5;
          color: ${BaseColors.outerSpace};
        }
      }

      & > div:last-child {
        width: 30%;
        font-size: 16px;
        text-align: end;
        font-weight: 400;
        font-family: Montserrat;
        color: ${BaseColors.outerSpace};
      }
    }
  }
`;
export const SelectModifierPriceWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > p {
    font-size: 16px;
    font-weight: 500;
    font-family: Montserrat;
    color: ${BaseColors.black85};
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;
export const SelectModiferCreateButton = styled.button<{ isFill: boolean }>`
  height: 40px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  border-radius: 30px;
  padding-inline: 32px;
  justify-content: center;
  font-family: Montserrat;

  &:disabled {
    color: ${() => BaseColors.baliHai};
    background-color: ${() => BaseColors.white};
    border: 1px solid ${() => BaseColors.baliHai};
  }

  border: 1px solid
    ${(props: any) =>
  props.isFill ? BaseColors.pictonBlue : BaseColors.baliHai};
  color: ${(props: any) =>
  props.isFill ? BaseColors.white : BaseColors.baliHai};
  background-color: ${(props: any) =>
  props.isFill ? BaseColors.pictonBlue : BaseColors.white};

  :focus {
    outline: none;
  }
`;
export const SelectModiferCancelButton = styled.button`
  height: 40px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  border-radius: 30px;
  padding-inline: 32px;
  justify-content: center;
  font-family: Montserrat;
  color: ${BaseColors.baliHai};
  border: 1px solid ${BaseColors.baliHai};
  background-color: ${BaseColors.white};

  &:hover {
    color: ${() => BaseColors.white};
    background-color: ${() => BaseColors.pictonBlue};
    border: 1px solid ${() => BaseColors.pictonBlue};
  }
`;

export const ProductAddModifierWrapper = styled.div`
  width: 100%;
  max-width: 768px;
  margin-top: 16px;
  & > div:first-child {
    color: #434343;
    font-size: 14px;
    font-weight: 600;
    margin-left: 12px;
    font-family: Montserrat;
    margin-bottom: 16px;
  }
  & > div:last-child {
    width: 100%;
    max-width: 768px;
    padding-left: 23px;
    border-radius: 6px;
    padding-right: 23px;
    border: 1px solid #ebeaea;
    background-color: #f7fbfd;
    box-shadow: 0px 0px 10px 5px #c4d3f020;
  }
`;


export const ProductModifierForm = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 48px;
  flex-direction: column;
  & > img {
    display: flex;
    cursor: pointer;
    align-self: flex-end;
  }
  & > div {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    & > div:first-child {
      width: 200px;
      color: #434343;
      font-size: 16px;
      font-weight: 500;
      line-height: 44px;
      font-family: Montserrat;
    }
    .addProductOptionInput {
      height: 45px;
      width: 544px;
      color: #434343;
      font-size: 14px;
      font-weight: 500;
      font-weight: 400;
      border-radius: 6px;
      font-style: normal;
      padding-left: 23px;
      padding-right: 23px;
      font-family: Montserrat;
      border: 1px solid #ebeaea;
      background-color: #ffffff;
      box-shadow: 0px 0px 10px 5px #c4d3f020;
    }
    .addProductOptionNameWrapper {
      width: 544px;
      padding-left: 23px;
      border-radius: 6px;
      padding-right: 23px;
      border: 1px solid #ebeaea;
      background-color: #f7fbfd;
      box-shadow: 0px 0px 10px 5px #c4d3f020;
    }
    .optionButtonWrapper {
      height: 40px;
      width: 140px;
      display: flex;
      cursor: pointer;
      font-size: 14px;
      border-width: 0;
      color: #ffffff;
      font-weight: 400;
      align-items: center;
      border-radius: 49px;
      padding-inline: 24px;
      font-family: Montserrat;
      justify-content: center;
      background-color: #49a4d4;
    }
    .emptyLine {
      width: 140px;
    }
    & > span {
      color: #ff9696;
      font-size: 13px;
      margin-top: 4px;
      font-weight: 400;
      margin-left: 23px;
      font-family: Montserrat;
    }
  }
`;

export const ProductModifierFormWrapper = styled.div`
  overflow-y: auto;
  padding-top: 40px;
  padding-left: 51px;
  padding-right: 51px;
  padding-bottom: 40px;
  width: calc(100vw - 20px);
  height: calc(100vh - 132px);
  & > div {
    width: 100%;
    max-width: 1024px;
  }
`;

export const ProductModifierInput = styled.div`
  width: 544px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & > div:first-child {
    width: 100%;
    height: 45px;
    color: #434343;
    font-size: 14px;
    font-weight: 500;
    font-weight: 400;
    border-radius: 6px;
    font-style: normal;
    padding-left: 23px;
    padding-right: 23px;
    font-family: Montserrat;
    border: 1px solid #ebeaea;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 5px #c4d3f020;
  }
  & > span {
    color: #ff9696;
    font-size: 13px;
    margin-top: 4px;
    font-weight: 400;
    margin-left: 23px;
    font-family: Montserrat;
  }
`;

export const ProductModifierItemWrapper = styled.div<{ isLast: boolean }>`
  width: 100%;
  /* height: 44px; */
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${(props) =>
    props.isLast ? "0px solid #e4e8ea" : "1px solid #e4e8ea"};
  & > input {
    padding: 0;
    border: 0px;
    height: 40px;
    color: #434343;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    border-radius: 6px;
    font-style: normal;
    width: calc(50% - 40px);
    font-family: Montserrat;
    background-color: #f7fbfd;
  }
  & > input:nth-child(2) {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: end;
  }
  & > img {
    width: 20px;
    height: 20px;
    display: flex;
    margin-left: 20px;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  & > div {
    width: 40px;
  }
`;

export const ProductModifierNameWrapper = styled.div`
  width: 544px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #f7fbfd;
  border: 1px solid #ebeaea;
  border-radius: 6px;
  padding: 16px 23px;
  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    & > div {
      color: #434343;
      font-size: 14px;
      font-weight: 700;
      width: calc(50% - 40px);
      font-family: Montserrat;
    }
    & > div:nth-child(2) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
    & > div:last-child {
      width: 40px;
    }
  }
  & > span {
    color: #ff9696;
    font-size: 13px;
    font-weight: 400;
    margin-left: 16px;
    font-family: Montserrat;
  }
`;

export const ProductModifierToggleInput = styled.div`
  width: 544px;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const ProductModifierCreateInput = styled.div`
  width: 544px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .addProductOptionPlaceholder {
    color: #434343;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
  }
  & > span {
    color: #ff9696;
    font-size: 13px;
    margin-top: 4px;
    font-weight: 400;
    margin-left: 23px;
    font-family: Montserrat;
  }
`;

export const ProductAddModifierHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-top: 23px;
  padding-right: 55px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  & > div:first-child {
    width: 50%;
  }
  & > div {
    color: #434343;
    font-size: 16px;
    font-weight: 600;
    text-align: start;
    font-family: Montserrat;
  }
`;

export const AddModifierSetWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div:first-child {
    width: 200px;
  }
  & > div:nth-child(2) {
    width: 544px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    & > div {
      color: #49a4d4;
      font-size: 14px;
      font-weight: 600;
      margin-right: 12px;
      font-family: Montserrat;
    }
  }
  & > div:last-child {
    width: 140px;
  }
`;
export const AddModifierButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 80px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  & > div {
    height: 40px;
    display: flex;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    border-width: 0;
    font-weight: 500;
    align-items: center;
    border-radius: 49px;
    padding-inline: 32px;
    font-family: Montserrat;
    justify-content: center;
    margin-right: 20px;
    background-color: #ff5f5f;
  }
  & > button {
    height: 40px;
    display: flex;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    border-width: 0;
    font-weight: 500;
    align-items: center;
    border-radius: 49px;
    padding-inline: 32px;
    font-family: Montserrat;
    justify-content: center;
  }
  & > button:first-child {
    margin-right: 20px;
    background-color: #ff5f5f;
  }
  & > button:last-child {
    background-color: #49a4d4;
  }
`;

export const ProductModifiersItemWrapper = styled.div<{ isLast: boolean }>`
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  border-bottom: ${(props) =>
    props.isLast ? "0px solid #e4e8ea" : "1px solid #e4e8ea"};
  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    & > div:first-child {
      width: 50%;
      color: #434343;
      font-size: 14px;
      text-align: start;
      font-weight: 400;
      font-family: Montserrat;
    }
    & > div:last-child {
      width: 50%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      & > input {
        width: calc(100% - 40px);
        border: 0px;
        height: 25px;
        color: #434343;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
        text-align: end;
        border-radius: 6px;
        font-style: normal;
        font-family: Montserrat;
        background-color: #f7fbfd;
      }
      & > div:first-child {
        width: calc(100% - 40px);
        color: #434343;
        font-size: 14px;
        text-align: end;
        font-weight: 400;
        line-height: 25px;
        font-family: Montserrat;
      }
      & > img {
        width: 20px;
        height: 20px;
      }
    }
  }
  & > span {
    color: #ff9696;
    font-size: 13px;
    font-weight: 400;
    margin-right: 70px;
    font-family: Montserrat;
  }
`;