import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import TableView from "../../table/views/TableView";
import RoomsView from "../../rooms/views/RoomsView";
import { OrderQueueContainer, OrderQueueBottomContainer } from "./Styled";
import OrderQueue from "../../../components/OrderQueueTable/Views/OrderQueue";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";
import OrderQueueTableFilters from "../../../components/Ordertable/Views/OrderQueueTableFilters";

const OrderQueueView = ({
  orderView,
}: {
  orderView: "TABLE" | "ORDER_QUEUE" | "ROOMS";
}) => {
  const { formatMessage: f } = useIntl();

  const renderContent = () => {
    switch (orderView) {
      case "ORDER_QUEUE":
        return <OrderQueue />;
      case "TABLE":
        return <TableView />;
      case "ROOMS":
        return <RoomsView />;
      default:
        return null;
    }
  };

  return (
    <OrderQueueContainer>
      <TopNavigationBar
        viewName={f({ id: "ORDER_QUEUE.HEADER" })}
        path="/menu"
      />
      <OrderQueueBottomContainer>
        <OrderQueueTableFilters />
        {renderContent()}
      </OrderQueueBottomContainer>
    </OrderQueueContainer>
  );
};
export default connect(
  (state: any) => ({
    orderView: state.orders.get("orderView"),
  }),
  {}
)(OrderQueueView);
