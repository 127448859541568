import styled, { css } from "styled-components";
import _ from "lodash";
import BaseColors from "../../internal/helpers/BaseColors";
import {
  setAppointmentBackColor,
  setBackgroundColor,
} from "../../internal/manager/AppointmentManager";

export const CustomerDetailsWrapper = styled.div`
  height: calc(80vh - 200px);
`;

export const AppointmentDetailItem = styled.div<{ disabled: boolean }>`
  margin-top: 16px;

  & > div:fist-child {
    margin-bottom: 8px;
  }
  & > div {
    display: flex;
    justify-content: space-between;

    & > div:last-child {
      width: 32px;
      height: 32px;
      background-color: ${BaseColors.monaLisa};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-self: center;
    }

    & > h3 {
      margin: 0px;
      ${({ disabled }) =>
        disabled &&
        css`
          color: ${BaseColors.silver};
        `}
    }

    & > div {
      margin-top: 8px;
    }
  }

  & > div:nth-child(1) {
    & > img {
      margin-right: 8px;
    }
  }

  & > hr {
    margin-top: 16px;
    background: rgba(32, 37, 58, 0.06);
    border: none;
    height: 1px;
  }
`;

export const CustomerCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;

  .customerText {
    color: rgba(0, 0, 0, 0.85);
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
  }

  .bottomWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .nameText {
      font-family: Montserrat;
      font-weight: normal;
      font-style: normal;
      color: #262626;
      font-size: 14px;
    }

    .mobileText {
      font-family: "Montserrat";
      font-weight: normal;
      font-style: normal;
      color: #262626;
      font-size: 14px;
      margin-top: 5px;
    }
  }
`;

export const DateCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;

  .appointmentDateText {
    color: rgba(0, 0, 0, 0.85);
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
  }

  .bottomWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .dateText {
      font-family: Montserrat;
      font-weight: normal;
      font-style: normal;
      color: #262626;
      font-size: 14px;
    }
  }
`;

export const AppointmentRoomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;

  & > div {
    color: #262626;
    font-size: 14px;
    font-style: normal;
    font-family: Montserrat;
  }

  & > div:fist-child {
    font-weight: 500;
  }

  & > div:last-child {
    font-weight: 400;
  }
`;

export const AppointmentDetailContainer = styled.div`
  width: 100%;
  height: calc(100vh - 81px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const OrderQueueButton = styled.button<{ valid: boolean }>`
  background-color: ${(props) => (props.valid ? "#ffffff" : "#f5f5f5")};
  border: 1px solid #49a4d4;
  color: ${(props) => (props.valid ? "#49A4D4" : "#cccccc")};
  padding: 0px 14px;
  width: 100%;
  height: 67px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: "Montserrat";
  font-weight: 600;

  :focus {
    outline: none;
  }
  :hover {
    background-color: ${(props) =>
      props.valid ? BaseColors.pictonBlue : "f5f5f5"};
    color: ${(props) => (props.valid ? BaseColors.white : "cccccc")};
  }
`;

export const CheckoutButton = styled.button<{ valid: boolean }>`
  background-color: ${(props) => (props.valid ? "#49a4d4" : "#f5f5f5")};
  border: 1px solid #cccccc;
  color: ${(props) => (props.valid ? "#ffffff" : "#cccccc")};
  padding: 16px 14px;
  width: 100%;
  height: 67px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: "Montserrat";
  font-weight: 600;
  margin-top: 7px;

  :focus {
    outline: none;
  }
`;

export const AppointmentDetailComponentContainer = styled.div`
  height: calc(100vh - 354px);
  display: flex;
  overflow: scroll;
  padding: 16px 8px;
  flex-direction: column;
`;

export const AppointmentDetailAddEmployeeContainer = styled.div<{
  isDisabled: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 33px;

  :hover {
    cursor: pointer;
  }

  & > div:first-child {
    font-family: Montserrat;
    font-weight: 600;
    color: ${(props) =>
      props.isDisabled ? BaseColors.silver : BaseColors.nero};
    font-size: 16px;
  }
`;

export const AppointmentDetailEmployeeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 33px;

  :hover {
    cursor: pointer;
  }

  & > div:first-child {
    font-family: Montserrat;
    font-weight: 400;
    color: ${BaseColors.nero};
    font-size: 14px;
  }

  & > div:last-child {
    width: 24px;
    height: 24px;
    background-color: ${BaseColors.monaLisa};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
`;

export const AppointmentDetailCustomerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  :hover {
    cursor: pointer;
  }

  & > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & > div {
      font-family: Montserrat;
      font-weight: 400;
      color: ${BaseColors.nero};
      font-size: 14px;
    }
  }

  & > div:last-child {
    width: 24px;
    height: 24px;
    background-color: ${BaseColors.monaLisa};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
`;

export const TopNavigationContainer = styled.div`
  width: 100%;
  height: 61px;
  background-color: #353c42;

  & > div {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const TopNavigationHeaderContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;

  & > p {
    color: #ffffff;
    font-size: 22px;
    font-family: "Montserrat";
    font-weight: 600;
    font-weight: normal;
    font-style: normal;
    margin-left: 20px;
    margin-top: 22px;
  }
`;

export const TopNavigationIconContainer = styled.div`
  /* width: 90px; */
  gap: 35px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > img {
    height: 24px;
  }

  & > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    & > img {
      height: 24px;
    }

    & > div {
      width: 115px;
      color: #ff5f5f;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      font-family: "Montserrat";
    }
  }
`;

export const ServiceContainer = styled.div<{ categoryHeight: string }>`
  width: 100%;
  height: 100%;

  .productLoading {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  #keypadContainer {
    width: 100%;
    height: 54px;
    border: 1px solid #49a4d4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    :hover {
      cursor: pointer;
    }

    .keypadText {
      color: #49a4d4;
      font-size: 16px;
      font-weight: 500;
      font-family: "Montserrat";
      font-weight: 600;
    }
  }
  .addCategoryBtn {
    width: 96px;
    height: 70px;
    background-color: #efefef;
    margin-left: 20px;
    margin-top: 18px;
    border-radius: 10px;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .addCategoryBtn:hover {
    cursor: pointer;
  }
`;

export const ServiceCategoryContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 21px;
  align-items: flex-start;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ServiceCategoryCardContainer = styled.div<{ isSelected: boolean }>`
  height: 36px;
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px 12px;
  background-color: ${(props) =>
    props.isSelected ? BaseColors.oceanGreen : BaseColors.white};
  border: ${(props) =>
    props.isSelected ? "1px solid #39B97D" : "1px solid #DEDEDE"};

  :hover {
    cursor: pointer;
  }

  & > div {
    white-space: nowrap;
    text-align: center;
    font-size: 13px;
    font-family: Montserrat;
    font-weight: 600;
    color: ${(props) =>
      props.isSelected ? BaseColors.white : BaseColors.balticSea};
  }
`;

export const ServiceCardContainer = styled.div<{ categoryHeight: string }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  overflow: auto;
  height: calc(100vh - 300px);
`;

export const ServiceKeyBoardContainer = styled.div`
  width: calc(100% - 30px);
  height: 54px;
  border: 1px solid #49a4d4;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  position: absolute;
  bottom: 0;
  background-color: #ffffff;

  :hover {
    cursor: pointer;
  }

  & > div {
    color: #49a4d4;
    font-size: 16px;
    font-weight: 500;
    font-family: "Montserrat";
    font-weight: 600;
  }
`;

export const DateTimeContainer = styled.div`
  height: calc(100vh - 81px);
  padding-top: 30px;

  #dateTimeHeaderText {
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #434343;
    font-family: Montserrat;
    font-weight: 500;
  }

  #calendarMainContainer {
    width: 100%;
    height: calc(100vh - 153px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    #calendarContainer {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: flex-start;
      overflow: auto;
    }

    #calenderBottomLine {
      width: 100%;
      height: 1px;
      background-color: rgba(32, 37, 58, 0.25);
      margin-top: 40px;
      border: none;
    }

    #useCurrentBtnContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;

      #useCurrentBtn {
        width: 589px;
        height: 54px;
        border: 1px solid #49a4d4;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      #useCurrentDateText {
        text-align: center;
        font-family: "Montserrat";
        font-weight: 600;
        color: #49a4d4;
        font-size: 16px;
      }
    }
  }

  #timePickerWrapper {
    width: 100%;
    padding: 40px 16px;

    .timePickerStyle {
      height: 45px;
      width: 100%;
      border: 1px solid #ebeaea;
      border-radius: 6px;
      background-color: #ffffff;
      box-shadow: 0px 0px 10px 5px #c4d3f020;
      margin-bottom: 10px;
    }
  }
`;

export const DateTimeComponentContainer = styled.div`
  width: 100%;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #timeCardContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > div {
      :hover {
        cursor: pointer;
      }
    }
  }

  #timeMainCardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 40px;

    #selectedTimeContainer {
      width: 74px;
      height: 36px;
      border-radius: 8px;
      border: 1px solid #dedede;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      margin-top: 20px;
      :hover {
        cursor: pointer;
      }

      #selectedTime {
        font-family: Montserrat;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
`;

export const BookingContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${BaseColors.aquaHaze};

  .bookingBottomWrapper {
    width: 100%;
    height: calc(100vh - 61px);
  }
`;

export const BookingDetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
`;

export const BookingDetailsHeader = styled.div`
  height: 51px;
  width: 100%;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #fafafa;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    padding-left: 15px;
    padding-right: 10px;
  }
`;

export const BookingDetailsAppointmentHeader = styled.div`
  display: flex;
  justify-content: row;
  align-items: center;

  & > div {
    color: #88938f;
    font-size: 14px;
    margin-left: 10px;
    font-family: Montserrat;
  }

  :hover {
    cursor: pointer;
  }
`;

export const BookingDetailsAppointmentClear = styled.div`
  height: 34px;
  background-color: rgba(255, 0, 0, 0.25);
  border-radius: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 23px;
  padding-right: 20px;

  :hover {
    cursor: pointer;
  }

  & > div {
    font-family: Montserrat;
    color: #ff0000;
    font-size: 14px;
    margin-left: 10px;
  }
`;

export const BookingDetailsBottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 140px);
`;

export const BookingDetailsBottomButton = styled.div`
  width: 100%;

  .totalBox {
    width: 100%;
    height: 72px;
    background-color: #ededed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding-left: 27px;
    padding-right: 16px;

    .totalPriceText {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }

    .totalPriceValueText {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .subTotal {
    width: 100%;
    height: 145px;
    background-color: #f9f9f9;
    padding-left: 30px;
    padding-right: 16px;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 12px;

      & > div {
        font-family: Montserrat;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
      }
    }
  }
`;

export const BookingDetailsBottomService = styled.div`
  width: 100%;
  height: calc(100vh - 273px);
  padding: 24px 15px;
  overflow-x: scroll;
`;

export const BookingDetailsBottomServiceItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 18px;
  justify-content: space-between;
`;

export const BookingDetailsBottomServiceCount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    width: 40px;
    height: 25px;
    border: 1px solid #c3c3c3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BookingDetailsBottomServiceName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > img {
    width: 53px;
    height: 53px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  }
`;

export const ServiceCardImageWrapper = styled.div`
  width: 53px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);

  & > div {
    font-family: "Montserrat";
    font-weight: bold;
    color: #1f1f1f;
    font-size: 25px;
    text-align: left;
  }
`;

export const ServiceCardNameWrapper = styled.div`
  display: flex;
  margin-left: 15px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .nameText {
      color: #434343;
      font-size: 14px;
      font-weight: 500;
      font-family: Montserrat;
    }
    .optionText {
      color: #8a9ca5;
      font-size: 12px;
      font-weight: 400;
      margin-left: 8px;
      font-family: Montserrat;
    }
  }

  .nameText {
    color: #434343;
    font-size: 14px;
    font-weight: 500;
    font-family: Montserrat;
  }

  .priceText {
    color: #434343;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 500;
    font-family: Montserrat;
  }
`;

export const BookingDetailsBottomServiceItemLine = styled.div`
  border-bottom: 1px solid rgba(32, 37, 58, 0.06);
  margin-bottom: 10px;
`;

export const PaymentDetailsContainer = styled.div`
  width: 100%;
  height: calc(100vh - 81px);
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const PaymentDetailsHeaderContainer = styled.div`
  width: 100%;
  .paymentDetailsHeaderPriceText {
    font-size: 40px;
    font-family: Montserrat;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    padding-top: 32px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
  }

  .paymentDetailsCardCashScroll {
    width: 100%;
    height: calc(100vh - 340px);
    padding: 16px 15px;
    overflow-x: auto;
    bottom-border: 4px solid rgba(0, 0, 0, 0.08);

    .paymentDetailsCardCashBottomContainer {
      margin-left: 26px;
      margin-top: 25px;
      margin-right: 26px;
    }
  }
`;

export const PaymentDetailsTextMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 22px 24px 0 24px;
  justify-content: flex-start;

  & > div {
    color: ${BaseColors.charcoal};
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    margin-left: 16px;
    margin-top: 2px;
  }
`;

export const PaymentCardCashWrapper = styled.div`
  gap: 23px;
  width: 100%;
  display: flex;
  padding-left: 26px;
  flex-direction: row;
  padding-right: 26px;
  align-items: center;
  justify-content: space-between;
`;

export const PaymentDetailsCardCashContainer = styled.div<{
  isSelect: boolean;
}>`
  width: 50%;
  height: 69px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  border: 1px solid #ede2e2;
  justify-content: space-between;
  background-color: ${(props) => (props.isSelect ? "#49A4D4" : "#ffffff")};

  .paymentDetailsCardCashImage {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
      font-size: 16px;
      color: #434343;
      font-family: Montserrat;
      font-weight: 500;
      margin-left: 10px;
    }
  }

  .paymentDetailsCardCashPrice {
    font-size: 16px;
    color: #434343;
    font-family: Montserrat;
    font-weight: 500;
  }
`;

export const PaymentDetailsBottomContainer = styled.div<{
  bottomHeight: string;
  serviceHeight: string;
  isCash: boolean;
  isCard: boolean;
  isValidTotal: boolean;
}>`
  width: 100%;
  background-color: #ffffff;
  height: 141px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
  border-top: 4px solid rgba(0, 0, 0, 0.08);
  padding-left: 26px;
  padding-right: 26px;

  .paymentDetailsBottomExpectedChange {
    min-width: 247px;
    height: 34px;
    border-radius: 60px;
    background-color: rgba(62, 122, 161, 0.25);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    flex-direction: row;

    .paymentDetailsBottomExpectedChangeText {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      color: #3e7aa1;
    }

    .paymentDetailsBottomExpectedChangeValue {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 14px;
      color: #3e7aa1;
      margin-left: 5px;
    }
  }
`;

export const PaymentDetailsBottomCashCardButton = styled.div<{
  bottomHeight: string;
  serviceHeight: string;
  isCash: boolean;
  isCard: boolean;
  isValid: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .paymentDetailsBottomCashCardCancelBtn {
    width: 225px;
    height: 59px;
    background-color: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #49a4d4;

    :hover {
      cursor: pointer;
    }

    & > div {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #49a4d4;
    }
  }

  .paymentDetailsBottomCashCardOrderBtn {
    width: 225px;
    height: 59px;
    background-color: ${(props) => (props.isValid ? "#37CEAF" : "#F5F5F5")};
    border-radius: 4px;
    margin-left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      cursor: pointer;
    }

    & > div {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: ${(props) => (props.isValid ? "#ffffff" : "#CCCCCC")};
    }
  }
`;

export const CardComponentContainer = styled.div`
  & > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    & > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > div:first-child {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 16px;
        color: #434343;
      }

      & > input {
        width: 200px;
        height: 48px;
        border: 1px solid #ede2e2;
        padding-left: 20px;
        padding-right: 20px;
        font-family: Montserrat;
        font-size: 16px;
        color: #434343;
        margin-top: 10px;

        :focus {
          outline: none;
        }
      }

      & > div:last-child {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 10px;
        color: ${BaseColors.bittersweet};
        margin-top: 4px;
      }
    }

    & > div:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > div:first-child {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 16px;
        color: #434343;
      }

      & > div:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: row;
        width: 200px;
        height: 48px;
        border: 1px solid #ede2e2;
        padding-left: 20px;
        padding-right: 20px;
        font-family: Montserrat;
        font-size: 16px;
        color: #434343;
        margin-top: 10px;

        & > div {
          font-family: Montserrat;
          font-weight: 500;
          font-size: 16px;
          color: #434343;
        }
      }
    }
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 12px;

    & > div {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #434343;
    }

    & > input {
      width: 100%;
      height: 48px;
      border: 1px solid #ede2e2;
      padding-left: 20px;
      padding-right: 20px;
      font-family: Montserrat;
      font-size: 16px;
      color: #434343;
      margin-top: 10px;

      :focus {
        outline: none;
      }
    }
  }
`;

export const CashComponentContainer = styled.div`
  margin-top: 30px;

  #cashComponentTotalContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    #cashTotalText {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #434343;
    }

    #cashTotalInput {
      width: 100%;
      height: 48px;
      border: 1px solid #ede2e2;
      margin-top: 10px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #434343;

      :focus {
        outline: none;
      }
    }
  }

  #cashComponentCashContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
    position: relative;

    #cashTotalText {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #434343;
    }

    #cashCurrencyText {
      position: absolute;
      bottom: 11px;
      left: 30px;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #434343;
    }

    #cashTotalInput {
      width: 100%;
      height: 48px;
      border: 1px solid #ede2e2;
      margin-top: 10px;
      display: flex;
      align-items: center;
      padding-left: 70px;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #434343;

      :focus {
        outline: none;
      }
    }
  }

  #expectedChangeContainer {
    width: 247px;
    height: 34px;
    border-radius: 60px;
    background-color: rgba(62, 122, 161, 0.25);
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    #expectedChangeText {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      color: #3e7aa1;
    }

    #expectedValue {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 14px;
      color: #3e7aa1;
      font-weight: 700;
      margin-left: 5px;
    }
  }
`;

export const ServiceSummaryContainer = styled.div`
  // width: 100%;
  padding-left: 16px;
  padding-right: 32px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 350px);

  #cashComponentTotalContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    #cashTotalText {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #434343;
    }

    #cashTotalInput {
      width: 100%;
      height: 48px;
      border: 1px solid #ede2e2;
      margin-top: 10px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #434343;

      :focus {
        outline: none;
      }
    }
  }

  #cashComponentCashContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
    position: relative;

    #cashTotalText {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #434343;
    }

    #cashCurrencyText {
      position: absolute;
      bottom: 14px;
      left: 30px;
    }

    #cashTotalInput {
      width: 100%;
      height: 48px;
      border: 1px solid #ede2e2;
      margin-top: 10px;
      display: flex;
      align-items: center;
      padding-left: 70px;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #434343;

      :focus {
        outline: none;
      }
    }
  }

  #expectedChangeContainer {
    width: 247px;
    height: 34px;
    border-radius: 60px;
    background-color: rgba(62, 122, 161, 0.25);
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    #expectedChangeText {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      color: #3e7aa1;
    }

    #expectedValue {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 14px;
      color: #3e7aa1;
      font-weight: 700;
      margin-left: 5px;
    }
  }
`;

export const AppointmentFilterButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: center;

  & > div:last-child {
    display: flex;
    flex-direction: row;
    & > .add-tables {
      background-color: #49a4d4;
    }
    & > .add-tables > div {
      color: #fff;
    }
  }
  & > div:first-child {
    // width: 100%;
    display: flex;
    flex-direction: row;
    & > .add-tables {
      background-color: #49a4d4;
    }
    & > .add-tables > div {
      color: #fff;
    }
    & > .add-order {
      justify-content: space-between;
    }
    & > div {
      margin-right: 1rem;
      height: 34px;
      min-width: 165px;
      background-color: #ffffff;
      border-radius: 4px;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;

      :hover {
        cursor: pointer;
      }

      & > div {
        font-family: Montserrat;
        font-weight: 500;
        color: #000000;
        font-size: 14px;
        :focus {
          background-color: #49a4d4;
        }
      }

      & > div {
        display: flex;
        flex-direction: row;

        & > img {
          margin-left: 5px;
        }
      }
    }
  }
`;

export const AppointmentFilterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 45px;
  position: absolute;
  z-index: 100;

  & > div {
    width: 165px;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
    margin-right: 20px;

    :hover {
      cursor: pointer;
    }

    & > div {
      width: 100%;
      z-index: 1000;

      :focus {
        background-color: #49a4d4;
      }

      & > p {
        width: 100%;
        font-family: "Montserrat";
        color: #000000;
        font-size: 13px;
        padding-left: 16px;
      }

      & > div {
        width: 100%;
        height: 0.5px;
        align-items: center;
        background-color: #f5f5f5;
        margin-top: 5px;
        border: none;
      }
    }
  }
`;

export const AddAppointmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;

  .addAppointmentButtonWrapper {
    width: 165px;
    height: 34px;
    display: flex;
    flex-direction: row;
    background-color: #49a4d4;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    & > div {
      color: #ffffff;
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 500;
    }
  }
`;

export const StylistSpecializationContainer = styled.div`
  // width: 100%;
  display: flex;
  margin-top: 21px;
  align-items: flex-start;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: scroll;
`;

export const StylistSpecializationCardContainer = styled.div<{
  isSelected: boolean;
}>`
  min-width: 120px;
  width: auto;
  height: 36px;
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  // padding: 10px;
  background-color: ${(props) => (props.isSelected ? "#39B97D" : "#ffffff")};
  border: ${(props) =>
    props.isSelected ? "1px solid #39B97D" : "1px solid #DEDEDE"};

  & > div {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // align-items: flex-start;
    font-size: 12px;
    font-family: Montserrat;
    color: ${(props) => (props.isSelected ? "#ffffff" : "rgba(0, 0, 0, 0.85)")};
  }

  :hover {
    cursor: pointer;
  }
`;

export const OrderQueueTableContainer = styled.div`
  width: 100%;
  height: calc(100vh - 140px);
  margin-top: 20px;
`;

export const OrderQueueTableHeaderContainer = styled.div`
  background-color: #717578;
  height: 51px;
  justify-content: center;

  & > div {
    color: #ffffff;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 500;
    font-weight: normal;
    font-style: normal;
    padding-top: 15px;
    padding-left: 15px;
  }
`;

export const OrderQueueTableTitleContainer = styled.div`
  height: calc(100vh - 190px);
`;

export const OrderQueueTableHeaderTitleContainer = styled.div`
  display: flex;
  height: 45px;
  display: grid;
  align-items: center;
  background-color: #f4f8fb;
`;

export const OrderQueueTableHeaderTitleTextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > div {
    font-family: Montserrat;
    font-weight: 500;
    color: #000000;
    font-size: 14px;
    margin-right: 10px;
  }
`;

export const OrderQueueTableValueContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 240px);
  background-color: #ffffff;

  & > div {
    align-items: center;
    background-color: #ffffff;

    :hover {
      background-color: #f8f9fb;
      cursor: pointer;
    }
  }
`;

export const OrderQueueTableImageValueContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > img {
    width: 34px;
    height: 34px;
    border-radius: 50px;
  }

  & > div {
    margin-left: 10px;

    .nameText {
      font-family: Montserrat;
      font-weight: 500;
      color: #434343;
      font-size: 14px;
    }

    .specializationText {
      font-family: Montserrat;
      font-size: 10px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
`;

export const OrderQueueTableValue = styled.div<{
  status: boolean;
  color: string;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Montserrat;
  font-weight: 500;
  color: ${(props) => (_.isNil(props.status) ? "#434343" : props.color)};
  font-size: 14px;
`;

export const IssueRefundModalContainer = styled.div<{
  status: boolean;
  color: string;
}>`
  width: 100%;
  height: 100%;
`;

export const IssueRefundModalHeader = styled.div<{
  status: boolean;
  color: string;
}>`
  height: 61px;
  display: flex;
  flex-direction: row;
  background-color: ${BaseColors.outerSpace};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding-right: 14px;
  padding-left: 20px;

  & > div {
    color: ${BaseColors.white};
    font-size: 14px;
    font-weight: 200;
    font-style: normal;
    font-family: "Montserrat";
    font-weight: 500;
  }

  & > img {
    :hover {
      cursor: pointer;
    }
  }
`;

export const IssueRefundModalBottom = styled.div<{
  status: boolean;
  color: string;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${BaseColors.outerSpace};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding-right: 14px;
  padding-left: 20px;

  & > div {
    color: ${BaseColors.white};
    font-size: 14px;
    font-weight: 200;
    font-style: normal;
    font-family: "Montserrat";
    font-weight: 500;
  }

  & > img {
    :hover {
      cursor: pointer;
    }
  }
`;

export const IssueRefundModalHeaderButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-bottom: 20px;
  padding: 26px 86px;
  background-color: ${BaseColors.white};
`;

export const HeaderLeftItemButton = styled.button<{ isSelected: boolean }>`
  height: "100%";
  background-color: ${(props) =>
    props.isSelected ? BaseColors.shakespeare : BaseColors.white};
  border: ${(props) =>
    props.isSelected ? "1px solid #49A4D4" : "1px solid #49A4D4"};
  color: ${(props) =>
    props.isSelected ? BaseColors.white : BaseColors.outerSpace70};
  padding: 16px 14px;
  width: 244px;
  height: 45px;
  text-align: center;
  font-size: 16px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  transition-duration: 0.4s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 600;

  :focus {
    outline: none;
  }
`;

export const HeaderRightItemButton = styled.button<{ isSelected: boolean }>`
  height: "100%";
  background-color: ${(props) =>
    props.isSelected ? BaseColors.shakespeare : BaseColors.white};
  border: ${(props) =>
    props.isSelected ? "1px solid #49A4D4" : "1px solid #49A4D4"};
  color: ${(props) =>
    props.isSelected ? BaseColors.white : BaseColors.outerSpace70};
  padding: 16px 14px;
  width: 244px;
  height: 45px;
  text-align: center;
  font-size: 16px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transition-duration: 0.4s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 600;

  :focus {
    outline: none;
  }
`;

export const IssueRefundModalItemMainContainer = styled.div<{
  status: boolean;
  color: string;
}>`
  width: 100%;
  overflow: auto;
  min-height: 171px;
  max-height: 300px;
`;

export const IssueRefundModalItemContainer = styled.div<{
  status: boolean;
  color: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 32px;
  height: 72px;
  background-color: ${BaseColors.white};
`;

export const IssueRefundModalItemText = styled.div<{
  status: boolean;
  color: string;
}>`
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: normal;
  font-style: normal;
  color: ${BaseColors.black85};
  border: none;
`;

export const IssueRefundModalItemCheckContainer = styled.div<{
  status: boolean;
  color: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > div {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    color: ${BaseColors.black85};
    margin-left: 23px;
  }
`;

export const IssueRefundModalItemLine = styled.div<{
  status: boolean;
  color: string;
}>`
  height: 1px;
  background-color: #f1f1f2;
  margin-left: 15px;
  margin-right: 20px;
`;

export const IssueRefundModalItemTotalContainer = styled.div<{
  status: boolean;
  color: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > div {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 17px;
    color: ${BaseColors.black85};
  }
`;

export const IssueRefundModalNextContainer = styled.div<{
  isReason: boolean;
  valid: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  margin-right: 33px;

  & > div {
    width: 107px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
      props.isReason && !props.valid
        ? BaseColors.regentGray
        : BaseColors.shakespeare};
    border: none;
    s :hover {
      cursor: pointer;
    }

    & > div {
      color: #ffffff;
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 500;
    }
  }
`;

export const IssueRefundModalAmountContainer = styled.div<{
  status: boolean;
  color: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  margin-left: 32px;
  margin-right: 32px;

  & > input {
    width: 100%;
    height: 42px;
    border: 1px solid #8a9ca5;
    margin-top: 12px;
    display: flex;
    align-items: center;
    padding-left: 60px;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    color: #434343;
    border-radius: 4px;
  }
`;

export const IssueRefundModalAmountLabel = styled.div<{
  status: boolean;
  color: string;
}>`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: ${BaseColors.regentGray};
`;

export const IssueRefundModalAmountCurrency = styled.div<{
  status: boolean;
  color: string;
}>`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  color: #8a9ca5;
  position: absolute;
  bottom: 8px;
  left: 20px;
`;

export const IssueRefundModalMaxRefundValue = styled.div<{
  status: boolean;
  color: string;
}>`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: ${BaseColors.regentGray};
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 25px;
`;

export const IssueRefundModalReasonHeader = styled.div<{
  status: boolean;
  color: string;
}>`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: ${BaseColors.regentGray};
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 10px;
`;

export const IssueRefundModalRefundPrice = styled.div<{
  status: boolean;
  color: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 48px 32px;

  & > div {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    color: ${BaseColors.black85};
  }
`;

export const FilterContainer = styled.div<{ paddingRight: string }>`
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 100;

  & > p {
    width: 100px;
  }

  & > div {
    width: 196px;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);

    :hover {
      cursor: pointer;
    }

    :focus {
      background-color: #49a4d4;
    }

    & > .selected-filter-item {
      background-color: #49a4d4;  
      width: 100%;
    }

    & > div > p {
      width: 100%;
      font-family: Montserrat;
      font-weight: normal;
      font-style: normal;
      color: #262626;
      font-size: 14px;
      padding: 12px 16px;
      margin: 0px;

      :hover {
        border-radius: 3px;
        background-color: ${BaseColors.solitude};
      }
    }

    & > div > div {
      width: 100%;
      height: 0.5px;
      align-items: center;
      background-color: #f5f5f5;
      border: none;
    }
  }
`;

export const AppointmentCalendarContainer = styled.div<{
  status: boolean;
  color: string;
}>`
  width: 100%;
  height: calc(100vh - 162px);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;

  & > div:first-child {
    width: 82px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 75px;

    & > div {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      color: #000000;
      font-size: 14px;
      line-height: 18px;
      font-family: "Montserrat";
      padding-bottom: 93px;
    }
  }

  & > hr {
    position: absolute;
    width: 1px;
    height: calc(100vh - 172px);
    border: none;
    left: 100px;
    background-color: ${BaseColors.mischka};
  }

  & > div:last-child {
    width: 95%;
    height: 100%;
    margin-left: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;

      & > div {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;

        & > div:first-child {
          width: 152px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-bottom: 16px;

          & > img {
            width: 44px;
            height: 44px;
            border-radius: 22px;
          }

          & > div {
            color: #000000;
            font-size: 14px;
            font-family: "Montserrat";
            margin-top: 6px;
          }
        }

        & > div:last-child {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;

          & > div {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            position: relative;
          }
        }
      }
    }
  }
`;

export const AppointmentCardContainer = styled.div<{
  componentHeight: string;
  componentTop: string;
  status: string;
}>`
  width: 146px;
  min-height: 105px;
  height: ${(props) => props.componentHeight};
  background-color: ${(props) => setAppointmentBackColor(props.status)};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-right: 10px;
  margin-bottom: 6px;
  position: absolute;
  top: ${(props) => props.componentTop};
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.04);

  & > div {
    width: 100%;
    height: ${(props) => props.componentHeight};
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    & > hr {
      border: none;
      min-height: 105px;
      width: 3px;
      height: 100%;
      background-color: ${(props) => setBackgroundColor(props.status)};
    }

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 7px;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;

      & div:first-child {
        color: ${(props) => setBackgroundColor(props.status)};
        font-size: 14px;
        font-family: "Montserrat";
        font-weight: 600;
      }

      .startEndTime {
        color: ${(props) => setBackgroundColor(props.status)};
        font-size: 10px;
        font-family: "Montserrat";
      }

      & div:last-child {
        position: absolute;
        color: ${(props) => setBackgroundColor(props.status)};
        font-size: 10px;
        font-family: "Montserrat";
        bottom: 10px;
      }
    }
  }
`;

export const AllServiceContainer = styled.div<{ categoryHeight: string }>`
  width: 100%;
  height: 100%;

  .categoryContainer {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: auto;
    height: ${(props) => props.categoryHeight};
    align-items: flex-start;
  }

  .productLoading {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .addCategoryBtn {
    width: 96px;
    height: 70px;
    background-color: #efefef;
    margin-left: 20px;
    margin-top: 18px;
    border-radius: 10px;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .addCategoryBtn:hover {
    cursor: pointer;
  }

  #serviceContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    overflow: scroll;
    height: 60%;
  }

  #keypadContainer {
    width: 100%;
    height: 54px;
    border: 1px solid #49a4d4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    :hover {
      cursor: pointer;
    }

    .keypadText {
      color: #49a4d4;
      font-size: 16px;
      font-weight: 500;
      font-family: "Montserrat";
      font-weight: 600;
    }
  }
`;

export const SalesReportCardContainer = styled.div<{
  componentHeight: string;
  componentTop: string;
  status: string;
}>`
  min-width: 233px;
  max-width: 430px;
  height: 105px;
  background-color: ${BaseColors.white};
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
  padding: 5px 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  & > div:first-child {
    color: ${BaseColors.oxfordBlue};
    font-size: 18px;
    font-family: "Montserrat";
    font-weight: 600;
  }

  & > div:nth-child(2) {
    color: ${BaseColors.aztec};
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 500;
  }

  & > div:last-child {
    color: ${BaseColors.silverChalice};
    font-size: 14px;
    font-family: "Montserrat";
  }
`;

export const SalesDetailsContainer = styled.div<{ categoryHeight: string }>`
  width: 100%;
  height: calc(100vh - 81px);

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    & > div {
      width: 238px;
      height: 37px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 10px;
      background-color: ${BaseColors.white};
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
      padding: 10px 12px;

      & > div {
        color: ${BaseColors.regentGray};
        font-size: 12px;
        font-family: Montserrat;
      }
    }
  }
`;

export const SalesDetailsTableContainer = styled.div<{
  categoryHeight: string;
}>`
          height: calc(100vh - 265px);
          padding: 10px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;
          overflow-y: scroll;
          overflow-x: hidden;

          .salesDetailsTableLoading {
              position: absolute;
              top: 50%;
              left: 50%;
          }

          .salesDetailsTableEmptyAppointment {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  margin-top: 100px;


                  &> div:first-child {
                    font-family: Montserrat;
font-weight: bold;
                    font-size: 18px;
                    color: ${BaseColors.black};
                  }

                  &> div:last-child {
                    font-family: Montserrat;
font-weight: 500;
                    font-size: 14px;
                    color: ${BaseColors.black85};
                    margin-top: 15px;
                    margin-bottom: 50px;
                  }
            }
    }
`;

export const SalesDetailsDateRangeContainer = styled.div<{
  categoryHeight: string;
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  background-color: ${BaseColors.bittersweet};
`;

export const SalesDetailsReportCardContainer = styled.div<{
  categoryHeight: string;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 40px 15px 10px;
`;

export const SalesDetailsFilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 260px;
  position: absolute;
  z-index: 100;

  & > div {
    width: 165px;
    // height: 250px;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);

    :hover {
      cursor: pointer;
    }

    & > div {
      width: 100%;
      z-index: 1000;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;

      :focus {
        background-color: #49a4d4;
      }

      & > div {
        width: 100%;
        font-family: "Montserrat";
        color: #000000;
        font-size: 13px;
        padding: 7px 15px;
      }

      & > hr {
        width: 100%;
        height: 1px;
        align-items: center;
        background-color: #f5f5f5;
        border: none;
      }
    }
  }
`;

export const ReportChartContainer = styled.div<{
  componentHeight: string;
  componentTop: string;
  status: string;
}>`
  width: 100%;
  height: 450px;
  background-color: ${BaseColors.white};
  border-radius: 4px;
  padding: 10px;
  margin-top: 15px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);

  & > div:first-child {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;

    & > div:first-child {
      color: ${BaseColors.aztec};
      font-size: 18px;
      margin-top: 10px;
      font-family: "Montserrat";
      font-weight: 600;
    }

    & > div:last-child {
      color: ${BaseColors.silverChalice};
      font-size: 14px;
      margin-top: 6px;
      font-family: "Montserrat";
    }
  }

  & > div:last-child {
    width: 100%;
    height: 320px;
  }
`;

export const ReportChartTableContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;

  & > div:first-child {
    display: flex;
    height: 45px;
    display: grid;
    align-items: center;
    background-color: ${BaseColors.alabaster};
  }

  & > div:last-child {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 350px;
    background-color: #ffffff;

    & > div {
      align-items: center;
      background-color: #ffffff;

      :hover {
        background-color: #f8f9fb;
      }
    }
  }
`;

export const ReportChartTableTitleTextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > div {
    font-family: Montserrat;
    font-weight: 500;
    color: #000000;
    font-size: 14px;
    margin-right: 10px;
  }
`;

export const ReportChartTableValue = styled.div<{ index: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Montserrat;
  color: ${(props) =>
    props.index === 2 || props.index === 3
      ? BaseColors.black
      : BaseColors.black85};
  font-size: 12px;
`;

export const EditProductPriceContainer = styled.div`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.balticSea};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    & > div {
      color: ${BaseColors.white};
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 500;
    }
  }

  & > div:last-child {
    padding: 40px 45px;

    & > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > div:first-child {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }

      & > input {
        width: 100%;
        height: 42px;
        border: 1px solid #8a9ca5;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 18px;
        color: ${BaseColors.balticSea};
        background-color: ${BaseColors.baliHai11};
        border-radius: 4px;

        :focus {
          outline: none;
        }
      }
    }

    & > div:nth-child(2) {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 20px;

      & > div:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        & > div:first-child {
          font-family: Montserrat;
          font-weight: 500;
          font-size: 12px;
          color: ${BaseColors.regentGray};
          margin-bottom: 5px;
        }

        & > input {
          width: 100%;
          height: 42px;
          border: 1px solid #8a9ca5;
          display: flex;
          align-items: center;
          padding-left: 16px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 18px;
          color: ${BaseColors.balticSea};
          border-radius: 4px;

          :focus {
            outline: none;
          }
        }
      }

      & > div:last-child {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 16px;

        & > div:first-child {
          font-family: Montserrat;
          font-weight: 500;
          font-size: 12px;
          color: ${BaseColors.regentGray};
          margin-bottom: 5px;
        }

        & > div:nth-child(2) {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          & > div:first-child {
            font-family: Montserrat;
            font-weight: 500;
            font-size: 18px;
            color: ${BaseColors.outerSpace};
            margin-bottom: 5px;
          }

          & > button {
            width: 80px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #8a9ca5;
            border-radius: 49px;
            cursor: pointer;
            font-family: Montserrat;
            font-weight: 500;
            font-size: 14px;
            color: ${BaseColors.regentGray};

            :focus {
              outline: none;
            }
          }
        }
      }
    }

    & > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 20px;

      & > div:first-child {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }

      & > div:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > div {
          width: 113px;
          height: 42px;
          border: 1px solid #8a9ca5;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 16px;
          margin-right: 16px;
        }
      }
    }

    & > div:nth-child(4) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin-top: 40px;

      & > button:nth-child(1) {
        width: 110px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${BaseColors.bittersweet};
        border: none;
        border-radius: 49px;
        cursor: pointer;
        margin-right: 44px;
        align-self: flex-start;

        :focus {
          outline: none;
        }

        & > div {
          color: ${BaseColors.white};
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }

      & > button:nth-child(2) {
        width: 143px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${BaseColors.pictonBlue};
        border: none;
        border-radius: 49px;
        cursor: pointer;
        margin-right: 14px;

        :focus {
          outline: none;
        }

        & > div {
          color: ${BaseColors.white};
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }

      & > button:last-child {
        width: 122px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 49px;
        cursor: pointer;
        border: 1px solid #8a9ca5;

        :focus {
          outline: none;
        }

        & > div {
          color: ${BaseColors.regentGray};
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }
    }
  }
`;

export const DiscountContainer = styled.div`
    width: 100%;
    height: 100%;

    &> button {
        background-color: ${BaseColors.white};
        border: 0.1px solid #49a4d4;
        color: ${BaseColors.pictonBlue};
        padding: 16px 18px;
        width: 100%;
        font-weight: 600;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        transition-duration: 0.4s;
        cursor: pointer;
        font-family: 'Montserrat';
        margin-top: 5px;

        :focus {
            outline: none;
        }

        :hover {
            background-color: ${BaseColors.pictonBlue};
            color: ${BaseColors.white};
        }
    }

    & > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        &> div:first-child:hover {
          cursor: pointer;
        }
        &> div:first-child {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-top: 20px;

            &> div:first-child {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              width: 100%;

              & > div:first-child {
                width: 42px;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: ${BaseColors.monaLisa};
                border: none;
                cursor: pointer;
                margin-right: 13px;
              }

                  & > div:last-child {
                      font-family: 'Montserrat';
                      font-weight: 600;
                      font-size: 14px;
                      width calc(100% - 140px);
                      color: ${BaseColors.nero};
                  }
            }

            & > div:last-child {
                  font-family: 'Montserrat';
                  font-weight: 600;
                  font-size: 14px;
                  color: ${BaseColors.nero};
            }
        }

        & > div:last-child {
            width: 100%;
            height: 1px;
            background-color: ${BaseColors.midnightExpress06};
            border: none;
            margin-top: 16px;
        }
    }

`;

export const AddDiscountContainer = styled.div`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.balticSea};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    & > div {
      color: ${BaseColors.white};
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 500;
    }
    & > img:hover {
      cursor: pointer;
    }
  }

  & > div:last-child {
    padding: 40px 45px;

    & > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > div:first-child {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }

      & > input {
        width: 100%;
        height: 42px;
        border: 1px solid #8a9ca5;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 18px;
        color: ${BaseColors.balticSea};
        border-radius: 4px;

        :focus {
          outline: none;
        }
      }
    }

    & > div:nth-child(2) {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 20px;

      & > div:first-child {
        margin-right: 16px;
      }
      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        & > div:first-child {
          font-family: Montserrat;
          font-weight: 500;
          font-size: 12px;
          color: ${BaseColors.regentGray};
          margin-bottom: 5px;
        }

        & > input {
          width: 100%;
          height: 42px;
          border: 1px solid #8a9ca5;
          display: flex;
          align-items: center;
          padding-left: 16px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 18px;
          color: ${BaseColors.balticSea};
          border-radius: 4px;

          :focus {
            outline: none;
          }
        }
      }
    }

    & > div {
      min-height: 18px;
    }

    & > div > span {
      font-size: 12px;
      color: #fd4b4b;
    }

    & > div:nth-child(4) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 22px;

      & > button:first-child {
        width: 143px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) =>
          props.disabled ? BaseColors.gunsmoke : BaseColors.pictonBlue};
        border: none;
        border-radius: 49px;
        cursor: pointer;
        margin-right: 14px;

        :focus {
          outline: none;
        }

        & > div {
          color: ${BaseColors.white};
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }

      & > button:last-child {
        width: 122px;
        height: 40px;
        display: flex;
        border: none;
        align-items: center;
        justify-content: center;
        border-radius: 49px;
        cursor: pointer;
        border: 1px solid #8a9ca5;
        background-color: ${BaseColors.white};

        :focus {
          outline: none;
        }

        & > div {
          color: ${BaseColors.regentGray};
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }
    }
  }
`;

export const CheckoutDiscountContainer = styled.div`
  margin-top: 0;
`;

export const CheckoutDiscountListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;

  & > div {
    color: #262626;
    font-size: 14px;
    text-align: center;
    font-family: Montserrat;
  }
`;

export const CustomerContainer = styled.div`
  width: 100%;
  height: calc(100vh - 81px);
  padding-top: 20px;

  & > div:nth-child(2) {
    height: calc(100vh - 160px);
    padding-top: 20px;

    & > button {
      background-color: #ffffff;
      border: 0.1px solid #49a4d4;
      color: #49a4d4;
      padding: 16px 32px;
      width: 100%;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      transition-duration: 0.4s;
      cursor: pointer;
      font-family: Montserrat;
      font-weight: 600;
      font-weight: normal;
      font-style: normal;

      :focus {
        outline: none;
      }

      :hover {
        background-color: #49a4d4;
        color: white;
      }
    }
  }
`;

export const CustomerLoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

export const CustomerQuickContainer = styled.div<{
  isExist: boolean;
  isCustomerSelected: boolean;
}>`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  & > div:first-child {
    color: ${(props) =>
      props.isCustomerSelected
        ? BaseColors.black85
        : props.isExist
        ? BaseColors.outerSpace
        : BaseColors.pictonBlue};
    font-size: 14px;
    text-align: center;
    font-family: Montserrat;
    font-weight: ${(props) => (!props.isCustomerSelected ? "700" : "500")};
  }

  .quickCustomerIconName {
    color: ${(props) =>
      props.isCustomerSelected ? BaseColors.black85 : BaseColors.outerSpace};
    font-size: 14px;
    text-align: center;
    font-family: Montserrat;
    font-weight: ${(props) => (!props.isCustomerSelected ? "700" : "500")};
  }

  .quickCustomerIconAdd {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${(props) =>
      props.isExist ? BaseColors.bittersweet : BaseColors.pictonBlue};
    border: none;
    margin-left: 16px;
  }
`;

export const CustomerComponentContainer = styled.div<{ isModal: boolean }>`
  height: ${({ isModal }) =>
    isModal ? "calc(80vh - 350px)" : "calc(100vh - 290px)"};

  overflow-y: auto;
  margin-top: 10px;
`;

export const QuickCustomerContainer = styled.div`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.balticSea};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    & > div {
      color: ${BaseColors.white};
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 500;
    }
  }

  & > div:nth-child(2) {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    color: ${BaseColors.pictonBlue};
    padding: 25px 20px 0 20px;
  }

  & > div:last-child {
    padding: 28px 45px 40px 45px;

    & > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > div:first-child {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }

      & > input {
        width: 100%;
        height: 42px;
        border: 1px solid #8a9ca5;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 18px;
        color: ${BaseColors.balticSea};
        border-radius: 4px;

        :focus {
          outline: none;
        }
      }
    }

    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 20px;

      & > div:first-child {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }

      & > input {
        width: 100%;
        height: 42px;
        border: 1px solid #8a9ca5;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 18px;
        color: ${BaseColors.balticSea};
        border-radius: 4px;

        :focus {
          outline: none;
        }
      }
    }

    & > div:nth-child(3) {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 12px;
      color: ${BaseColors.regentGray};
      margin-top: 18px;
    }

    & > div:nth-child(4) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 16px;

      & > button:first-child {
        width: 143px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${BaseColors.pictonBlue};
        border: none;
        border-radius: 49px;
        cursor: pointer;
        margin-right: 14px;

        :focus {
          outline: none;
        }

        & > div {
          color: ${BaseColors.white};
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }

      & > button:last-child {
        width: 122px;
        height: 40px;
        display: flex;
        border: none;
        align-items: center;
        justify-content: center;
        border-radius: 49px;
        cursor: pointer;
        border: 1px solid #8a9ca5;
        background-color: ${BaseColors.white};

        :focus {
          outline: none;
        }

        & > div {
          color: ${BaseColors.regentGray};
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }
    }
  }
`;

export const SelectEmployeeModalContainer = styled.div`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.outerSpace};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    :focus {
      outline: none;
    }

    & > div {
      color: ${BaseColors.white};
      font-size: 14px;
      font-weight: 200;
      font-style: normal;
      font-family: "Montserrat";
      font-weight: 500;
    }

    & > img {
      :hover {
        cursor: pointer;
      }
    }
  }

  & > div:nth-child(2) {
    width: 100%;
    max-height: 204px;
    overflow: scroll;
    padding: 19px 32px;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > div {
        font-family: Montserrat;
        font-size: 14px;
        color: ${BaseColors.black85};
        margin-left: 23px;

        :hover {
          cursor: pointer;
        }
      }
    }
  }

  & > div:nth-child(3) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 14px;

    &>button: first-child {
      width: 160px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #49a4d4;
      border: none;
      border-radius: 49px;
      cursor: pointer;
      margin-right: 14px;

      :focus {
        outline: none;
      }

      & > div {
        color: ${BaseColors.white};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }

    & > button:last-child {
      width: 122px;
      height: 39px;
      display: flex;
      border: none;
      align-items: center;
      justify-content: center;
      border-radius: 49px;
      cursor: pointer;
      border: 1px solid #8a9ca5;
      background-color: ${BaseColors.white};

      :focus {
        outline: none;
      }

      & > div {
        color: ${BaseColors.regentGray};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }
  }
`;

export const SelectCustomerModalContainer = styled.div`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.outerSpace};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    :focus {
      outline: none;
    }

    & > div {
      color: ${BaseColors.white};
      font-size: 14px;
      font-weight: 200;
      font-style: normal;
      font-family: "Montserrat";
      font-weight: 500;
    }

    & > img {
      :hover {
        cursor: pointer;
      }
    }
  }

  & > div:nth-child(2) {
    padding-left: 31px;
    padding-right: 31px;
    height: 60vh;
    overflow-y: hidden;

    & > button {
      background-color: #ffffff;
      border: 0.1px solid #49a4d4;
      color: #49a4d4;
      padding: 14px 32px;
      width: 100%;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      transition-duration: 0.4s;
      cursor: pointer;
      font-family: Montserrat;
      font-weight: 600;
      font-weight: normal;
      font-style: normal;
      margin-top: 18px;

      :focus {
        outline: none;
      }

      :hover {
        background-color: #49a4d4;
        color: white;
      }
    }
  }
  & > div:nth-child(3) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 24px;
    margin-top: 16px;

    &>button: first-child {
      width: 160px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #49a4d4;
      border: none;
      border-radius: 49px;
      cursor: pointer;
      margin-right: 14px;

      :focus {
        outline: none;
      }

      & > div {
        color: ${BaseColors.white};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }

    & > button:last-child {
      width: 122px;
      height: 39px;
      display: flex;
      border: none;
      align-items: center;
      justify-content: center;
      border-radius: 49px;
      cursor: pointer;
      border: 1px solid #8a9ca5;
      background-color: ${BaseColors.white};

      :focus {
        outline: none;
      }

      & > div {
        color: ${BaseColors.regentGray};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }
  }
`;

export const CreateVehicleContainer = styled.div`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.balticSea};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    & > div {
      color: ${BaseColors.white};
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 500;
    }
  }

  & > div:nth-child(2) {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    color: ${BaseColors.pictonBlue};
    padding: 25px 20px 0 20px;
  }

  & > div:last-child {
      padding: 28px 32px 0px 32px;

      & > div:first-child {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          & > div:first-child {
            font-family: Montserrat;
font-weight: 500;
            font-size: 14px;
            color: ${BaseColors.regentGray};
            margin-bottom: 5px;
          }
      }

      & > div:nth-child(2) {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          margin-top: 17px;

          & > div:first-child {
              font-family: Montserrat;
              font-size: 14px;
              color: ${BaseColors.regentGray};
              margin-bottom: 5px;
          }

          & > input {
              width: 100%;
              height: 42px;
              border: 1px solid #8A9CA5;
              display: flex;
              align-items: center;
              padding-left: 16px;
              font-family: Montserrat;
font-weight: 500;
              font-size: 14px;
              color: ${BaseColors.black85};
              background-color: ${BaseColors.white};
              border-radius: 4px;
              boxShadow: "0px 0px 10px 5px #c4d3f020",

              :focus {
                outline: none;
              }
          }
      }

      & > div:nth-child(3) {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          margin-top: 17px;

          & > div:first-child {
              font-family: Montserrat;
              font-size: 14px;
              color: ${BaseColors.regentGray};
              margin-bottom: 5px;
          }

          & > input {
              width: 100%;
              height: 42px;
              border: 1px solid #8A9CA5;
              display: flex;
              align-items: center;
              padding-left: 16px;
              font-family: Montserrat;
font-weight: 500;
              font-size: 14px;
              color: ${BaseColors.black85};
              background-color: ${BaseColors.white};
              border-radius: 4px;

              :focus {
                outline: none;
              }
          }
      }


      & > div:nth-child(4) {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: 17px;

          & > div:first-child {
              width: 45%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;

              & > div:first-child {
                  font-family: Montserrat;
                  font-size: 14px;
                  color: ${BaseColors.regentGray};
                  margin-bottom: 5px;
              }

              & > input {
                  width: 100%;
                  height: 42px;
                  border: 1px solid #8A9CA5;
                  display: flex;
                  align-items: center;
                  padding-left: 16px;
                  font-family: Montserrat;
font-weight: 500;
                  font-size: 14px;
                  color: ${BaseColors.black85};
                  background-color: ${BaseColors.white};
                  border-radius: 4px;

                  :focus {
                    outline: none;
                  }
              }
          }

          & > div:last-child {
              width: 45%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;

              & > div:first-child {
                  font-family: Montserrat;
                  font-size: 14px;
                  color: ${BaseColors.regentGray};
                  margin-bottom: 5px;
              }

              & > input {
                  width: 100%;
                  height: 42px;
                  border: 1px solid #8A9CA5;
                  display: flex;
                  align-items: center;
                  padding-left: 16px;
                  font-family: Montserrat;
font-weight: 500;
                  font-size: 14px;
                  color: ${BaseColors.black85};
                  background-color: ${BaseColors.white};
                  border-radius: 4px;

                  :focus {
                    outline: none;
                  }
              }
          }
      }

      & > div:nth-child(5) {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          margin-top: 30px;

          & > button:first-child {
              width: 143px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: ${BaseColors.pictonBlue};
              border: none;
              border-radius: 49px;
              cursor: pointer;
              margin-right: 14px;

              :focus {
                outline: none;
              }

              & > div {
                color: ${BaseColors.white};
                font-size: 14px;
                font-family: Montserrat;
                font-weight: 500;
              }
          }

          & > button:last-child {
              width: 122px;
              height: 40px;
              display: flex;
              border: none;
              align-items: center;
              justify-content: center;
              border-radius: 49px;
              cursor: pointer;
              border: 1px solid #8a9ca5;
              background-color: ${BaseColors.white};

              :focus {
                outline: none;
              }

              & > div {
                color: ${BaseColors.regentGray};
                font-size: 14px;
                font-family: Montserrat;
                font-weight: 500;
              }
          }
      }
  }
`;

export const SelectVehicleModalContainer = styled.div`
  width: 100%;
  // height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.outerSpace};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    :focus {
      outline: none;
    }

    & > div {
      color: ${BaseColors.white};
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 500;
    }

    & > img {
      :hover {
        cursor: pointer;
      }
    }
  }

  & > div:nth-child(2) {
    padding: 15px 31px 24px 31px;

    & > button {
      background-color: #ffffff;
      border: 0.1px solid #49a4d4;
      color: #49a4d4;
      padding: 14px 32px;
      width: 100%;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      transition-duration: 0.4s;
      cursor: pointer;
      font-family: Montserrat;
      font-weight: 600;
      font-weight: normal;
      font-style: normal;

      :focus {
        outline: none;
      }

      :hover {
        background-color: #49a4d4;
        color: white;
      }
    }
  }

  & > div:nth-child(3) {
    width: 100%;
    max-height: 204px;
    overflow: auto;
    padding: 0 32px 19px 32px;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      & > div {
        font-family: Montserrat;
        font-size: 14px;
        color: ${BaseColors.black85};
        margin-left: 23px;

        :hover {
          cursor: pointer;
        }
      }
    }
  }

  & > div:nth-child(4) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 31px 24px 31px;

    & > div:first-child {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > div:first-child {
        font-family: Montserrat;
        font-size: 14px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }

      & > input {
        width: 100%;
        height: 42px;
        border: 1px solid #8a9ca5;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 14px;
        color: ${BaseColors.black85};
        background-color: ${BaseColors.white};
        border-radius: 4px;

        :focus {
          outline: none;
        }
      }
    }
    & > div:last-child {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > div:first-child {
        font-family: Montserrat;
        font-size: 14px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }

      & > input {
        width: 100%;
        height: 42px;
        border: 1px solid #8a9ca5;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 14px;
        color: ${BaseColors.black85};
        background-color: ${BaseColors.white};
        border-radius: 4px;

        :focus {
          outline: none;
        }
      }
    }
  }

  & > div:nth-child(5) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 14px;

    &>button: first-child {
      width: 160px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #49a4d4;
      border: none;
      border-radius: 49px;
      cursor: pointer;
      margin-right: 14px;

      :focus {
        outline: none;
      }

      & > div {
        color: ${BaseColors.white};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }

    & > button:last-child {
      width: 122px;
      height: 39px;
      display: flex;
      border: none;
      align-items: center;
      justify-content: center;
      border-radius: 49px;
      cursor: pointer;
      border: 1px solid #8a9ca5;
      background-color: ${BaseColors.white};

      :focus {
        outline: none;
      }

      & > div {
        color: ${BaseColors.regentGray};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }
  }
`;

export const CreateWarrantyModalContainer = styled.div<{ disabled: boolean }>`
  width: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    padding-left: 20px;
    align-items: center;
    padding-right: 14px;
    flex-direction: row;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    background-color: ${BaseColors.outerSpace};

    :focus {
      outline: none;
    }

    & > div {
      color: ${BaseColors.white};
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 500;
    }

    & > img {
      :hover {
        cursor: pointer;
      }
    }
  }

  & > div:nth-child(2) {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    color: ${BaseColors.pictonBlue};
    padding: 25px 20px 0 20px;
  }

  & > div:nth-child(3) {
    width: 100%;
    max-height: 204px;
    overflow: auto;
    padding: 24px 32px 19px 32px;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;

      & > div {
        font-family: Montserrat;
        font-size: 14px;
        color: ${BaseColors.black85};
        margin-left: 23px;

        :hover {
          cursor: pointer;
        }
      }
    }
  }

  & > div:nth-child(4) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 31px 24px 31px;

    & > div:first-child {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 16px;

      & > div:first-child {
        font-family: Montserrat;
        font-size: 14px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }

      & > div:last-child {
        width: 100%;
        height: 42px;
        border: 1px solid #8a9ca5;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 14px;
        color: ${BaseColors.black85};
        background-color: ${BaseColors.white};
        border-radius: 4px;

        :focus {
          outline: none;
        }
      }
    }

    & > div:last-child {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 16px;

      & > div:first-child {
        font-family: Montserrat;
        font-size: 14px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }

      & > textarea {
        width: 100%;
        height: 95px;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 16px;
        align-items: center;
        font-family: Montserrat;
        border: 1px solid #8a9ca5;
        color: ${BaseColors.black85};
        background-color: ${BaseColors.white};
        border-radius: 4px;

        :focus {
          outline: none;
        }
      }
    }
  }

  & > div:nth-child(5) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 14px;

    &>button: first-child {
      width: 160px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${(props) =>
        props.disabled ? BaseColors.baliHai25 : BaseColors.shakespeare};
      border: none;
      border-radius: 49px;
      cursor: pointer;
      margin-right: 14px;

      :focus {
        outline: none;
      }

      & > div {
        color: ${(props) =>
          props.disabled ? BaseColors.outerSpace70 : BaseColors.white};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }

    & > button:last-child {
      width: 122px;
      height: 39px;
      display: flex;
      border: none;
      align-items: center;
      justify-content: center;
      border-radius: 49px;
      cursor: pointer;
      border: 1px solid #8a9ca5;
      background-color: ${BaseColors.white};

      :focus {
        outline: none;
      }

      & > div {
        color: ${BaseColors.regentGray};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }
  }
`;

export const SelectDateAndTimeModalContainer = styled.div`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.outerSpace};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    :focus {
      outline: none;
    }

    & > div {
      color: ${BaseColors.white};
      font-size: 14px;
      font-weight: 200;
      font-style: normal;
      font-family: "Montserrat";
      font-weight: 500;
    }

    & > img {
      :hover {
        cursor: pointer;
      }
    }
  }

  & > div:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    overflow: auto;
    padding: 24px 32px;

    & > div:nth-child(2) {
      width: 100%;
      height: 1px;
      background-color: rgba(32, 37, 58, 0.25);
      margin-top: 20px;
      border: none;
    }

    & > div:last-child {
      width: 100%;
      margin-top: 16px;

      .timePickerStyle {
        height: 45px;
        width: 100%;
        border: 1px solid #ebeaea;
        border-radius: 6px;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 5px #c4d3f020;
        margin-bottom: 10px;
      }
    }
  }

  & > div:nth-child(3) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 14px;

    &>button: first-child {
      width: 160px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #49a4d4;
      border: none;
      border-radius: 49px;
      cursor: pointer;
      margin-right: 14px;

      :focus {
        outline: none;
      }

      & > div {
        color: ${BaseColors.white};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }

    & > button:last-child {
      width: 122px;
      height: 39px;
      display: flex;
      border: none;
      align-items: center;
      justify-content: center;
      border-radius: 49px;
      cursor: pointer;
      border: 1px solid #8a9ca5;
      background-color: ${BaseColors.white};

      :focus {
        outline: none;
      }

      & > div {
        color: ${BaseColors.regentGray};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }
  }
`;

export const CreateQuickItemContainer = styled.div<{
  selectedHeader: string;
  isValidProduct: boolean;
  isValidService: boolean;
}>`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.balticSea};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    & > div {
      color: ${BaseColors.white};
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 500;
    }
  }

  & > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 26px 0 40px 0;
    background-color: ${BaseColors.white};

    & > div:first-child {
      height: "100%";
      background-color: ${(props) =>
        props.selectedHeader === "PRODUCT"
          ? BaseColors.shakespeare
          : BaseColors.white};
      border: ${(props) =>
        props.selectedHeader === "PRODUCT"
          ? "1px solid #49A4D4"
          : "1px solid #49A4D4"};
      color: ${(props) =>
        props.selectedHeader === "PRODUCT"
          ? BaseColors.white
          : BaseColors.outerSpace70};
      padding: 16px 14px;
      width: 206px;
      height: 45px;
      text-align: center;
      font-size: 16px;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      transition-duration: 0.4s;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: "Montserrat";
      font-weight: 600;

      :focus {
        outline: none;
      }
    }

    & > div:last-child {
      height: "100%";
      background-color: ${(props) =>
        props.selectedHeader === "SERVICE"
          ? BaseColors.shakespeare
          : BaseColors.white};
      border: ${(props) =>
        props.selectedHeader === "SERVICE"
          ? "1px solid #49A4D4"
          : "1px solid #49A4D4"};
      color: ${(props) =>
        props.selectedHeader === "SERVICE"
          ? BaseColors.white
          : BaseColors.outerSpace70};
      padding: 16px 14px;
      width: 206px;
      height: 45px;
      text-align: center;
      font-size: 16px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      transition-duration: 0.4s;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: "Montserrat";
      font-weight: 600;

      :focus {
        outline: none;
      }
    }
  }

  & > div:nth-child(3) {
    height: 240px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 32px 0px 32px;

    & > div:first-child {
      width: 80%;

      & > div:first-child {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        & > div:first-child {
          width: 120px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 14px;
          color: ${BaseColors.regentGray};
        }

        & > input {
          width: 100%;
          height: 42px;
          border: 1px solid #8a9ca5;
          display: flex;
          align-items: center;
          padding-left: 16px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 14px;
          color: ${BaseColors.black85};
          background-color: ${BaseColors.white};
          border-radius: 4px;
          box-shadow: "0px 0px 10px 5px #c4d3f020";

          :focus {
            outline: none;
          }
        }
      }

      & > div:nth-child(2) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 16px;

        & > div:first-child {
          width: 120px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 14px;
          color: ${BaseColors.regentGray};
        }
      }

      & > div:nth-child(3) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 16px;

        & > div:first-child {
          width: 120px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 14px;
          color: ${BaseColors.regentGray};
        }

        & > input {
          width: 100%;
          height: 42px;
          border: 1px solid #8a9ca5;
          display: flex;
          align-items: center;
          padding-left: 16px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 14px;
          color: ${BaseColors.black85};
          background-color: ${BaseColors.white};
          border-radius: 4px;
          box-shadow: "0px 0px 10px 5px #c4d3f020";
          :focus {
            outline: none;
          }
        }
      }

      & > div:nth-child(4) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 16px;

        & > div:first-child {
          width: 120px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 14px;
          color: ${BaseColors.regentGray};
        }

        & > input {
          width: 100%;
          height: 42px;
          border: 1px solid #8a9ca5;
          display: flex;
          align-items: center;
          padding-left: 16px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 14px;
          color: ${BaseColors.black85};
          background-color: ${BaseColors.white};
          border-radius: 4px;

          :focus {
            outline: none;
          }
        }
      }
    }

    & > div:last-child {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: center;
      margin-left: 18px;

      & > button {
        width: 150px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${BaseColors.pictonBlue};
        border: none;
        border-radius: 49px;
        cursor: pointer;
        margin-bottom: 24px;

        :focus {
          outline: none;
        }

        & > div {
          color: ${BaseColors.white};
          font-size: 14px;
          font-family: Montserrat;
        }
      }
    }
  }

  & > div:nth-child(4) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    padding: 32px;

    & > button:first-child {
      width: 143px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${(props) =>
        props.selectedHeader === "PRODUCT"
          ? props.isValidProduct
            ? BaseColors.pictonBlue
            : BaseColors.baliHai
          : props.isValidService
          ? BaseColors.pictonBlue
          : BaseColors.baliHai};
      border: none;
      border-radius: 49px;
      cursor: pointer;
      margin-right: 14px;

      :focus {
        outline: none;
      }

      & > div {
        color: ${BaseColors.white};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }

    & > button:last-child {
      width: 122px;
      height: 40px;
      display: flex;
      border: none;
      align-items: center;
      justify-content: center;
      border-radius: 49px;
      cursor: pointer;
      border: 1px solid #8a9ca5;
      background-color: ${BaseColors.white};

      :focus {
        outline: none;
      }

      & > div {
        color: ${BaseColors.regentGray};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
      }
    }
  }
`;

export const CreateCategoryContainer = styled.div<{ isValid: boolean }>`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.balticSea};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    & > div {
      color: ${BaseColors.white};
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 500;
    }
  }

  & > div:last-child {
    padding: 32px 32px 0px 32px;

    & > div:first-child {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > div:first-child {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 14px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }
    }

    & > div:nth-child(2) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 16px;

      & > div:first-child {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 14px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }

      & > textarea {
        width: 100%;
        height: 100px;
        border: 1px solid #8a9ca5;
        display: flex;
        align-items: center;
        padding: 16px;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 16px;
        color: ${BaseColors.black85};
        background-color: ${BaseColors.white};
        border-radius: 4px;
        box-shadow: "0px 0px 10px 5px #c4d3f020";
        :focus {
          outline: none !important;
          outline: 0 !important;
          border-color: #8a9ca5 !important;
          border: 1px solid #8a9ca5;
        }
      }
    }

    & > div:nth-child(3) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 40px;

      & > button {
        width: 182px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) =>
          props.isValid ? BaseColors.pictonBlue : BaseColors.baliHai};
        border: none;
        border-radius: 8px;
        cursor: pointer;

        :focus {
          outline: none;
        }

        & > div {
          color: ${BaseColors.white};
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }
    }
  }
`;

export const NeedApprovalContainer = styled.div<{ isValid: boolean }>`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.balticSea};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    & > div {
      color: ${BaseColors.white};
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 500;
    }
  }
`;

export const CustomerCardMainContainer = styled.div<{ isSelected: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 18px;
  padding-right: 10px;

  & > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div:first-child {
      font-family: Montserrat;
      font-weight: ${(props) => (props.isSelected ? "700" : "500")};
      color: ${BaseColors.nero};
      font-size: 14px;
      text-align: left;
      margin-right: 16px;
    }

    & > img:last-child {
      margin-left: 24px;
    }
  }
  & > div:last-child {
    font-family: Montserrat;
    font-weight: ${(props) => (props.isSelected ? "700" : "500")};
    color: ${BaseColors.nero};
    font-size: 14px;
  }
`;

export const CreateCustomerModelContainer = styled.div<{ isValid: boolean }>`
  & > div:first-child {
    display: flex;
    align-items: center;
    background-color: #353c42;
    height: 61px;
    margin: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 14px;

    & > div {
      color: ${BaseColors.white};
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 500;
    }

    & > img {
      :hover {
        cursor: pointer;
      }
    }
  }

  & > div:nth-child(2) {
    color: #49a4d4;
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: normal;
    font-style: normal;
    padding-left: 16px;
    display: flex;
    padding-top: 24px;
  }

  & > div:nth-child(3) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 32px;
    padding-right: 32px;

    & > div:first-child {
      width: 100%;
      margin-top: 16px;

      & > div {
        color: ${BaseColors.baliHai};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
        margin-bottom: 5px;
      }

      & > input {
        height: 45px;
        width: 100%;
        color: #000000;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
        margin-bottom: 5px;
        border: 1px solid #8a9ca5;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    & > div:nth-child(2) {
      width: 100%;
      margin-top: 16px;

      & > div {
        color: ${BaseColors.baliHai};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
        margin-bottom: 5px;
      }

      & > input {
        height: 45px;
        width: 100%;
        color: #000000;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
        margin-bottom: 5px;
        border: 1px solid #8a9ca5;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    & > div:nth-child(3) {
      width: 100%;
      margin-top: 16px;

      & > div:first-child {
        color: ${BaseColors.baliHai};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
        margin-bottom: 5px;
      }

      & > div:last-child {
        height: 45px;
        width: 100%;
        color: #000000;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
        margin-bottom: 5px;
        border: 1px solid #8a9ca5;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    & > div:nth-child(4) {
      width: 100%;
      margin-top: 16px;

      & > div {
        color: ${BaseColors.baliHai};
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
        margin-bottom: 5px;
      }

      & > input {
        height: 45px;
        width: 100%;
        color: #000000;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 500;
        margin-bottom: 5px;
        border: 1px solid #8a9ca5;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  & > div:nth-child(4) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 32px;
    margin-left: 32px;
    margin-top: 40px;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      & > div:first-child {
        width: 159px;
        height: 40px;
        background-color: ${(props) =>
          props.isValid ? BaseColors.pictonBlue : BaseColors.nobel};
        border-radius: 49px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
          color: ${BaseColors.white};
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }

      & > div:last-child {
        width: 122px;
        height: 40px;
        border: 1px solid #8a9ca5;
        border-radius: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;

        & > div {
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
          color: ${BaseColors.regentGray};
        }
      }
    }

    & > button {
      height: 40px;
      width: 100px;
      border: 1px solid #ff5f5f;
      border-radius: 49px;
      background-color: #ffd6d6;
      color: #ff5f5f;
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 500;

      :focus {
        outline: none;
      }
    }
  }
`;

export const LogoutModalContainer = styled.div<{ isSelected: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;

  & > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & > img {
      :hover {
        cursor: pointer;
      }
    }
  }
  & > div:last-child {
    padding: 0px 48px 32px 48px;

    & > div:first-child {
      font-size: 21px;
      color: ${BaseColors.nero};
      text-align: center;
      font-family: Montserrat;
      font-weight: 700;
    }

    & > div:nth-child(2) {
      font-size: 14px;
      color: ${BaseColors.nero};
      text-align: center;
      font-family: Montserrat;
      font-weight: 500;
      margin-top: 24px;
    }

    & > div:nth-child(3) {
      width: 100%;
      display: flex;
      margin-top: 40px;
      flex-direction: row;
      align-items: center;
      padding-right: 30px;
      padding-left: 30px;
      justify-content: space-between;

      &>button: first-child {
        width: 126px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ff8383;
        border-radius: 5px;
        border: none;

        :hover {
          cursor: pointer;
        }

        & > div {
          color: ${BaseColors.white};
          font-family: Montserrat;
          font-weight: 500;
          font-size: 14px;
        }
      }
      &>button: last-child {
        width: 126px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #49a4d4;
        border-radius: 5px;
        border: none;

        :hover {
          cursor: pointer;
        }

        & > div {
          color: ${BaseColors.white};
          font-family: Montserrat;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
`;

export const SplitBillWrapper = styled.div<{
  isSelect: boolean;
}>`
  width: 100%;
  height: 69px;
  display: flex;
  max-width: 196px;
  margin-top: 26px;
  margin-left: 26px;
  padding-left: 31px;
  flex-direction: row;
  align-items: center;
  padding-right: 31px;
  border: 1px solid #ede2e2;
  justify-content: space-between;
  background-color: ${(props) => (props.isSelect ? "#49A4D4" : "#ffffff")};

  :hover {
    cursor: pointer;
    background-color: "#49A4D4";
  }

  & > div {
    font-size: 16px;
    color: #434343;
    font-family: Montserrat;
    font-weight: 500;
    margin-left: 30px;
  }
`;

export const SplitPaymentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - 140px);

  & > div:first-child {
    color: #353c42;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 600;
    margin-bottom: 27px;
    font-family: Montserrat;
  }
`;

export const SplitPaymentButtonWrapper = styled.div<{
  isSelect: boolean;
  isDisable: boolean;
}>`
  gap: 36px;
  width: 100%;
  height: 69px;
  display: flex;
  max-width: 312px;
  margin-top: 8px;
  padding-left: 70px;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  border: 1px solid #49a4d4;
  justify-content: flex-start;
  background-color: ${(props) =>
    props.isDisable ? "#F4F6F6" : props.isSelect ? "#49A4D4" : "#E4E8EA"};
  opacity: ${(props) => (props.isDisable ? 0.5 : 1)};

  :hover {
    cursor: pointer;
    background-color: "#49A4D4";
  }

  & > div {
    font-size: 16px;
    color: #434343;
    font-weight: 500;
    font-family: Montserrat;
  }
`;

export const SplitBillDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  border-radius: 3px;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  height: calc(100vh - 81px);
  justify-content: space-between;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);

  & > div:first-child {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    & > div:first-child {
      width: 100%;
      font-size: 40px;
      font-weight: bold;
      padding-top: 32px;
      text-align: center;
      font-family: Montserrat;
      color: rgba(0, 0, 0, 0.85);
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
    }

    & > div:nth-child(2) {
      width: 100%;
      display: flex;
      margin-top: 56px;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
`;

export const SplitBillPriceCard = styled.div`
  width: 100%;
  display: flex;
  max-width: 523px;
  padding-left: 62px;
  margin-bottom: 21px;
  align-items: center;
  padding-right: 40px;
  flex-direction: row;
  background-color: #ffffff;
  justify-content: flex-start;

  & > div:first-child {
    width: 100%;
    color: #353c42;
    font-size: 16px;
    font-weight: 500;
    text-align: start;
    font-family: Montserrat;
  }

  & > div:last-child {
    gap: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    & > div {
      width: 100%;
      color: #353c42;
      font-size: 16px;
      font-weight: 500;
      text-align: end;
      font-family: Montserrat;
    }
    & > button {
      border: 0px;
      background: none;
    }
    & > button > img {
      width: 20px;
      height: 20px;

      :hover {
        cursor: pointer;
      }
    }
  }
`;

export const SplitBillLine = styled.div`
  display: flex;
  max-width: 523px;
  margin-top: 4px;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
  flex-direction: column;
  width: calc(100% - 80px);
  justify-content: flex-start;
  border-bottom: 1px solid #353c42;
`;

export const CheckRowWrapper = styled.div`
  width: 100%;
  display: flex;
  max-width: 523px;
`;

export const SplitBillTotalPrice = styled.div<{ isBold: boolean }>`
  width: 100%;
  display: flex;
  max-width: 523px;
  padding-left: 62px;
  margin-top: 21px;
  margin-bottom: 21px;
  align-items: center;
  padding-right: 40px;
  flex-direction: row;
  background-color: #ffffff;
  justify-content: flex-start;

  & > div:first-child {
    width: 100%;
    color: #353c42;
    font-size: 16px;
    text-align: start;
    font-family: Montserrat;
    font-weight: ${(props) => (props.isBold ? "700" : "500")};
  }

  & > div:last-child {
    width: 100%;
    color: #353c42;
    font-size: 16px;
    text-align: end;
    margin-right: 32px;
    font-family: Montserrat;
    font-weight: ${(props) => (props.isBold ? "700" : "500")};
  }
`;

export const SplitBillButtonWrapper = styled.div<{
  bottomHeight: string;
  serviceHeight: string;
  isCash: boolean;
  isCard: boolean;
  isValid: boolean;
}>`
  width: 100%;
  display: flex;
  padding-top: 70px;
  padding-left: 26px;
  padding-right: 26px;
  align-items: center;
  flex-direction: row;
  padding-bottom: 12px;
  background-color: #ffffff;
  justify-content: space-between;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);

  & > div:first-child {
    width: 225px;
    height: 59px;
    background-color: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #49a4d4;

    :hover {
      cursor: pointer;
    }

    & > div {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: #cccccc;
    }
  }

  & > div:last-child {
    width: 225px;
    height: 59px;
    background-color: ${(props) => (props.isValid ? "#37CEAF" : "#F5F5F5")};
    border-radius: 4px;
    margin-left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      cursor: pointer;
    }

    & > div {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      color: ${(props) => (props.isValid ? "#ffffff" : "#CCCCCC")};
    }
  }
`;

export const PaymentAmounts = styled.div`
  gap: 19px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 29px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  & > div:hover {
    cursor: pointer;
    border: 1px solid ${BaseColors.pictonBlue};
  }

  & > div {
    height: 49px;
    display: flex;
    font-size: 16px;
    padding: 0 21px;
    font-weight: 600;
    border-radius: 4px;
    align-items: center;
    font-family: Montserrat;
    justify-content: center;
    color: ${BaseColors.outerSpace};
    background: ${BaseColors.tableFree};
    border: 1px solid ${BaseColors.tableFree};
  }
`;

export const PaymentAmountInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > div {
    width: 100%;
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & > div {
      color: #8a9ca5;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }

    & > input {
      width: 100%;
      height: 42px;
      color: #353c42;
      font-size: 16px;
      margin-top: 7px;
      max-width: 285px;
      padding-left: 20px;
      border-radius: 4px;
      padding-right: 20px;
      font-family: Montserrat;
      border: 1px solid #8a9ca5;

      :focus {
        outline: none;
      }
    }
  }
`;

export const SplitPaymentDetailsItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - 140px);
  padding-left: 35px;
  padding-right: 35px;

  & > div:first-child {
    color: #353c42;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 600;
    margin-bottom: 27px;
    font-family: Montserrat;
  }
`;

export const SplitPaymentButton = styled.div<{
  isValid: boolean;
}>`
  width: 100%;
  display: flex;
  padding-top: 37px;
  align-items: center;
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: space-between;

  & > div:first-child {
    height: 59px;
    display: flex;
    color: #49a4d4;
    font-size: 16px;
    max-width: 180px;
    font-weight: 500;
    border-radius: 4px;
    align-items: center;
    width: calc(50% - 25px);
    font-family: Montserrat;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #49a4d4;

    :hover {
      cursor: pointer;
    }
  }

  & > button:last-child {
    height: 59px;
    display: flex;
    font-size: 16px;
    max-width: 180px;
    font-weight: 500;
    margin-left: 18px;
    border-radius: 4px;
    align-items: center;
    font-family: Montserrat;
    justify-content: center;
    width: calc(50% - 25px);
    border: 1px solid ${(props) => (props.isValid ? "#37CEAF" : "#F5F5F5")};
    color: ${(props) => (props.isValid ? "#ffffff" : "#CCCCCC")};
    background-color: ${(props) => (props.isValid ? "#37CEAF" : "#F5F5F5")};

    :hover {
      cursor: pointer;
    }
  }
`;

export const OrderWarpper = styled.div<{
  status: boolean;
  color: string;
}>`
  width: 100%;
  height: 100%;
`;

export const OrderHeaderWarpper = styled.div`
  height: 61px;
  display: flex;
  padding-left: 20px;
  flex-direction: row;
  align-items: center;
  padding-right: 14px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  background-color: ${BaseColors.outerSpace};

  & > div {
    font-size: 14px;
    font-weight: 200;
    font-weight: 500;
    font-style: normal;
    color: ${BaseColors.white};
    font-family: "Montserrat";
  }

  & > img {
    :hover {
      cursor: pointer;
    }
  }
`;

export const OrderDetailsWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 104px;

  & > div:first-child {
    display: flex;
    padding: 32px;
    padding-top: 0;
    max-height: 400px;
    padding-bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      :hover {
        background-color: ${BaseColors.whisper};
      }

      & > div {
        gap: 10px;
        display: flex;
        padding-top: 20px;
        flex-direction: row;
        padding-bottom: 20px;
        align-items: flex-start;
        justify-content: center;

        & > div {
          font-size: 16px;
          font-weight: 500;
          font-family: Montserrat;
          color: ${BaseColors.black};
        }
        & > div:nth-child(1) {
          width: 200px;
        }
        & > div:nth-child(2) {
          width: 60px;
        }
        & > div:nth-child(3) {
          width: 132px;
        }
        & > div:nth-child(4) {
          width: 140px;
          text-align: end;
        }
      }
      & > hr {
        margin: 0;
        width: 100%;
        height: 1px;
        border: none;
        background-color: ${BaseColors.mischka};
      }
    }
  }
`;

export const OrderButtonWrapper = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > button:first-child {
    width: 80px;
    height: 38px;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    border-radius: 49px;
    font-family: Montserrat;
    justify-content: center;
    border: 1px solid #8a9ca5;
    color: ${BaseColors.regentGray};
    background-color: ${BaseColors.white};
    :focus {
      outline: none;
    }
    :hover {
      background-color: ${BaseColors.whisper};
    }
  }

  & > button:last-child {
    width: 160px;
    height: 40px;
    border: none;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    border-radius: 49px;
    justify-content: center;
    font-family: Montserrat;
    color: ${BaseColors.white};
    background-color: ${BaseColors.cornflowerBlue};
    :focus {
      outline: none;
    }
  }
`;

export const SelectOptionModalWrapper = styled.div`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    padding-left: 20px;
    flex-direction: row;
    padding-right: 14px;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    background-color: ${BaseColors.balticSea};

    & > div {
      color: ${BaseColors.white};
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 500;
    }
  }

  & > div:last-child {
    display: flex;
    padding-top: 14px;
    padding-inline: 32px;
    padding-bottom: 40px;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: ${BaseColors.white};
  }
`;

export const SelectOptionItemWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > div:first-child {
    font-size: 16px;
    font-weight: 600;
    font-family: Montserrat;
    color: ${BaseColors.outerSpace};
  }

  & > div:last-child {
    display: flex;
    flex-wrap: wrap;
    padding-top: 14px;
    flex-direction: row;
  }
`;

export const SelectOptionButton = styled.button<{
  isSelected: boolean;
  isDisabled: boolean;
}>`
  width: 152px;
  height: 40px;
  border: none;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-right: 7px;
  margin-bottom: 7px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  border: 1px solid
    ${(props: any) =>
      props.isDisabled ? BaseColors.monaLisa : BaseColors.pictonBlue};
  color: ${(props: any) =>
    props.isDisabled
      ? BaseColors.monaLisa
      : props.isSelected
      ? BaseColors.white
      : BaseColors.black85};
  background-color: ${(props: any) =>
    props.isDisabled || !props.isSelected
      ? BaseColors.white
      : BaseColors.pictonBlue};

  :focus {
    outline: none;
  }
`;

export const SelectOptionPriceWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > p {
    font-size: 16px;
    font-weight: 500;
    font-family: Montserrat;
    color: ${BaseColors.black85};
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const SelectOptionCreateButton = styled.button<{ isFill: boolean }>`
  height: 40px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  border-radius: 30px;
  padding-inline: 32px;
  justify-content: center;
  font-family: Montserrat;
  border: 1px solid
    ${(props: any) =>
      props.isFill ? BaseColors.pictonBlue : BaseColors.baliHai};
  color: ${(props: any) =>
    props.isFill ? BaseColors.white : BaseColors.baliHai};
  background-color: ${(props: any) =>
    props.isFill ? BaseColors.pictonBlue : BaseColors.white};

  :focus {
    outline: none;
  }
`;

export const PurchaseDetailsGroupWrapper = styled.div`
  width: 100%;

  .productNameText {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    font-family: Montserrat;
    color: ${BaseColors.outerSpace};
  }
`;

export const AddWarrantyModalWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  & > div:first-child {
    height: 61px;
    display: flex;
    padding-left: 20px;
    align-items: center;
    padding-right: 14px;
    flex-direction: row;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    background-color: ${BaseColors.outerSpace};
    :focus {
      outline: none;
    }
    & > div {
      font-size: 16px;
      font-weight: 400;
      font-family: Montserrat;
      color: ${BaseColors.white};
    }
    & > img {
      :hover {
        cursor: pointer;
      }
    }
  }
`;

export const AddWarrantyDataWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  padding: 30px 24px;

  & > div:first-child {
    font-size: 16px;
    font-weight: 500;
    font-family: Montserrat;
    color: ${BaseColors.pictonBlue};
  }

  & > div:nth-child(2) {
    gap: 24px;
    width: 100%;
    display: flex;
    overflow: auto;
    max-height: 204px;
    padding: 24px 12px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      & > span {
        padding: 0;
      }
      & > div {
        font-size: 14px;
        font-weight: 500;
        margin-left: 23px;
        font-family: Montserrat;
        color: ${BaseColors.black85};

        :hover {
          cursor: pointer;
        }
      }
    }
  }

  & > div:nth-child(3) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 12px 24px 12px;

    & > div:first-child {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
      font-family: Montserrat;
      text-transform: uppercase;
      color: ${BaseColors.regentGray};
    }

    & > div:last-child {
      width: 207px;
      height: 42px;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      padding-left: 14px;
      padding-right: 14px;
      align-items: center;
      font-family: Montserrat;
      border: 1px solid #8a9ca5;
      color: ${BaseColors.outerSpace};
      background-color: ${BaseColors.white};

      :focus {
        outline: none;
      }
    }
  }

  & > div:nth-child(4) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 12px 24px 12px;

    & > div:first-child {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
      font-family: Montserrat;
      text-transform: uppercase;
      color: ${BaseColors.regentGray};
    }

    & > input {
      width: 100%;
      height: 42px;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      padding: 8px 16px;
      border-radius: 4px;
      align-items: center;
      font-family: Montserrat;
      border: 1px solid #8a9ca5;
      color: ${BaseColors.outerSpace};

      :focus {
        outline: none;
      }
    }
  }

  & > div:nth-child(5) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 12px 24px 12px;
    justify-content: flex-start;

    & > div:first-child {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
      font-family: Montserrat;
      text-transform: uppercase;
      color: ${BaseColors.regentGray};
    }
    & > textarea {
      width: 100%;
      height: 95px;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      padding: 8px 16px;
      border-radius: 4px;
      align-items: center;
      font-family: Montserrat;
      border: 1px solid #8a9ca5;
      color: ${BaseColors.outerSpace};
      background-color: ${BaseColors.white};

      :focus {
        outline: none;
      }
    }
  }

  & > div:nth-child(6) {
    gap: 14px;
    width: 100%;
    display: flex;
    padding: 0 12px;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

    & > button:first-child {
      width: 160px;
      height: 39px;
      border: none;
      display: flex;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      align-items: center;
      border-radius: 49px;
      font-family: Montserrat;
      justify-content: center;
      background-color: ${(props) =>
        props.disabled ? BaseColors.baliHai25 : BaseColors.shakespeare};
      color: ${(props) =>
        props.disabled ? BaseColors.outerSpace70 : BaseColors.white};

      :focus {
        outline: none;
      }
    }

    & > button:last-child {
      width: 122px;
      height: 39px;
      display: flex;
      border: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      align-items: center;
      border-radius: 49px;
      font-family: Montserrat;
      justify-content: center;
      border: 1px solid #8a9ca5;
      color: ${BaseColors.regentGray};
      background-color: ${BaseColors.white};

      :focus {
        outline: none;
      }
    }
  }
`;

export const OfferWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  padding-right: 16px;
  height: calc(100vh - 81px);

  /* & > button {
    width: 100%;
    font-size: 16px;
    margin-top: 5px;
    cursor: pointer;
    font-weight: 600;
    padding: 16px 18px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    transition-duration: 0.4s;
    font-family: "Montserrat";
    border: 0.1px solid #49a4d4;
    color: ${BaseColors.pictonBlue};
    background-color: ${BaseColors.white};
    :focus {
      outline: none;
    }
    :hover {
      background-color: ${BaseColors.pictonBlue};
      color: ${BaseColors.white};
    }
  } */

  & > div:nth-child(1) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const OfferDataWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  & > div:first-child {
    width: 100%;
    display: flex;
    padding-top: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    :hover {
      cursor: pointer;
    }

    & > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: calc(100% - 140px);
      justify-content: flex-start;

      & > div:first-child {
        font-size: 14px;
        font-weight: 600;
        text-align: start;
        font-family: "Montserrat";
        color: ${BaseColors.nero};
      }

      & > div:last-child {
        font-size: 12px;
        font-weight: 400;
        font-family: "Montserrat";
        color: ${BaseColors.nero};
      }
    }
  }

  & > div:last-child {
    width: 100%;
    height: 1px;
    border: none;
    margin-top: 16px;
    background-color: ${BaseColors.whisper};
  }
`;

export const SelectedOfferWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  & > div:first-child {
    width: 100%;
    display: flex;
    padding-top: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > div:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: calc(100% - 140px);
      justify-content: flex-start;

      & > div:first-child {
        width: 42px;
        height: 42px;
        display: flex;
        cursor: pointer;
        margin-right: 13px;
        align-items: center;
        justify-content: center;
        background-color: ${BaseColors.monaLisa};
        :hover {
          cursor: pointer;
        }
      }

      & > div:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        & > div:first-child {
          font-size: 14px;
          font-weight: 600;
          text-align: start;
          font-family: "Montserrat";
          color: ${BaseColors.nero};
        }

        & > div:last-child {
          font-size: 12px;
          font-weight: 400;
          font-family: "Montserrat";
          color: ${BaseColors.nero};
        }
      }
    }

    & > div:last-child {
      text-align: end;
      font-size: 14px;
      font-weight: 600;
      font-family: "Montserrat";
      color: ${BaseColors.nero};
    }
  }

  & > div:last-child {
    width: 100%;
    height: 1px;
    border: none;
    margin-top: 16px;
    background-color: ${BaseColors.whisper};
  }
`;

export const OfferItemWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-top: 16px;
  padding-left: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 188px);

    & > div:first-child {
      font-size: 14px;
      font-weight: 600;
      text-align: start;
      font-family: "Montserrat";
      color: ${BaseColors.nero};
    }

    & > div:last-child {
      font-size: 12px;
      font-weight: 400;
      font-family: "Montserrat";
      color: ${BaseColors.nero};
    }
  }
  & > div:last-child {
    font-size: 12px;
    font-weight: 600;
    margin-right: 12px;
    font-family: "Montserrat";
    color: ${BaseColors.nero};
  }
`;

export const OfferApplyButton = styled.button<{ disabled: boolean }>`
  border-radius: 1rem;
  padding: 0.3rem 1rem;
  background: ${BaseColors.white};
  color: ${(props: any) =>
    !props.disabled ? BaseColors.cornflowerBlue : BaseColors.gunsmoke};
  border: 1px solid
    ${(props: any) =>
      !props.disabled ? BaseColors.cornflowerBlue : BaseColors.gunsmoke};
  :hover {
    cursor: ${(props: any) => (!props.disabled ? "pointer" : "not-allowed")};
    color: ${(props: any) =>
      !props.disabled ? BaseColors.white : BaseColors.gunsmoke};
    background-color: ${(props: any) =>
      !props.disabled ? BaseColors.cornflowerBlue : BaseColors.white};
  }
`;

export const AddOfferWrapper = styled.div`
  width: 100%;
  height: 100%;

  & > div:first-child {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: ${BaseColors.balticSea};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;

    & > div {
      color: ${BaseColors.white};
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 500;
    }
    & > img:hover {
      cursor: pointer;
    }
  }

  & > div:last-child {
    padding: 40px 45px;

    & > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > div:first-child {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        color: ${BaseColors.regentGray};
        margin-bottom: 5px;
      }

      & > input {
        width: 100%;
        height: 42px;
        border: 1px solid #8a9ca5;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 18px;
        color: ${BaseColors.balticSea};
        border-radius: 4px;

        :focus {
          outline: none;
        }
      }
    }

    & > div:nth-child(2) {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 20px;

      & > div:first-child {
        margin-right: 16px;
      }
      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        & > div:first-child {
          font-family: Montserrat;
          font-weight: 500;
          font-size: 12px;
          color: ${BaseColors.regentGray};
          margin-bottom: 5px;
        }

        & > input {
          width: 100%;
          height: 42px;
          border: 1px solid #8a9ca5;
          display: flex;
          align-items: center;
          padding-left: 16px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 18px;
          color: ${BaseColors.balticSea};
          border-radius: 4px;

          :focus {
            outline: none;
          }
        }
      }
    }

    & > div {
      min-height: 18px;
    }

    & > div > span {
      font-size: 12px;
      color: #fd4b4b;
    }

    & > div:nth-child(4) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 22px;

      & > button:first-child {
        width: 143px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) =>
          props.disabled ? BaseColors.gunsmoke : BaseColors.pictonBlue};
        border: none;
        border-radius: 49px;
        cursor: pointer;
        margin-right: 14px;

        :focus {
          outline: none;
        }

        & > div {
          color: ${BaseColors.white};
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }

      & > button:last-child {
        width: 122px;
        height: 40px;
        display: flex;
        border: none;
        align-items: center;
        justify-content: center;
        border-radius: 49px;
        cursor: pointer;
        border: 1px solid #8a9ca5;
        background-color: ${BaseColors.white};

        :focus {
          outline: none;
        }

        & > div {
          color: ${BaseColors.regentGray};
          font-size: 14px;
          font-family: Montserrat;
          font-weight: 500;
        }
      }
    }
  }
`;
