import React, {useState} from 'react';
import {connect} from "react-redux";
import NeedApprovalModel from "../../../components/model/views/NeedApprovalModel";
import EnterPinModel from "../../../components/model/views/EnterPinModel";
import {getDecryptedCode} from "../../../internal/manager/SecurityManager";

const ApprovalView = (props) => {
  const { isOpenApprovalModal, onSetIsOpenApprovalModal, deleteItem, onOpenEditModal } = props;
  const [isOpenEnterPinModal, setIsOpenEnterPinModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [pin, setPin] = useState('');
  const [isShowPinError, setIsShowPinError] = useState(false);

  const onDeleteItem = () => {
    const employeePin = getDecryptedCode(selectedEmployee?.pin);
    if (employeePin === pin) {
      deleteItem();
      setIsOpenEnterPinModal(false);
      setDefaultValue();
    } else {
      setIsShowPinError(true);
    }
  };

  const setDefaultValue = () => {
    setSelectedEmployee({});
    setPin('');
    setIsShowPinError(false);
  };

  return (
    <div>
      <NeedApprovalModel
        isOpen={isOpenApprovalModal}
        onClose={() => {
          onSetIsOpenApprovalModal(false);
          setDefaultValue();
        }}
        onCancel={() => {
          onSetIsOpenApprovalModal(false);
          onOpenEditModal();
          setDefaultValue();
        }}
        selectedEmployee={selectedEmployee}
        onSelectEmployee={(employee) => setSelectedEmployee(employee)}
        onOpenPinModal={() => {
          onSetIsOpenApprovalModal(false);
          setIsOpenEnterPinModal(true);
        }}
      />
      <EnterPinModel
        isOpen={isOpenEnterPinModal}
        onClose={() => {
          setIsOpenEnterPinModal(false);
          setDefaultValue();
        }}
        onCancel={() => {
          setIsOpenEnterPinModal(false);
          onSetIsOpenApprovalModal(true);
          setIsShowPinError(false);
        }}
        isShowPinError={isShowPinError}
        onChangePin={(text) => {
          setPin(text);
          setIsShowPinError(false);
        }}
        pin={pin}
        onSetPin={(pinValue) => setPin(pinValue)}
        onEnterPin={() => {
          onDeleteItem();
        }}
      />
    </div>
  );
};

export default connect(
  state => ({
    isStopProgress: state.login.get('isStopProgress'),
    progressCount: state.login.get('progressCount')
  }),
  {
  }
)(ApprovalView);
