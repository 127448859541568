import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import _, { isEmpty, find, get } from "lodash";
import {
  AppointmentProductContainer,
  AppointmentProductCardContainer,
} from "../../../modules/product/views/Styled";
import ProductCard from "../../card/ProductCard";
import {
  getInitialModifiers,
  updateStockItemCount,
} from "../../../internal/manager/ProductManager";
import TagSearchBar from "../../search/TagSearchBar";
import { Actions } from "../../../internal/app/Actions";
import ServiceCategoryCard from "../../card/ServiceCategoryCard";
import FilterComponent from "../../closeCash/views/FilterComponent";
import SelectOptionModal from "../../model/views/SelectOptionModal";
import ApprovalView from "../../../modules/orders/views/ApprovalView";
import IconFilter from "../../../../assets/svg/IconCloshcashFilter.svg";
import { isCanAccess } from "../../../internal/manager/EmployeeManager";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ProductSelectModal from "../../../components/model/views/ProductSelectModal";
import ProductDeleteModal from "../../../components/model/views/ProductDeleteModal";
import IconSelectFilter from "../../../../assets/svg/IconCloshcashSelectFilter.svg";
import SelectModifierModal from "../../../modules/product/views/SelectModifierModal";

const filterOptions = [
  {
    label: "Category",
    value: "CATEGORY",
  },
  {
    label: "Brand Name",
    value: "BRAND_NAME",
  },
];

const Products = (props) => {
  const {
    shop,
    stockList,
    fetchMenu,
    catalogue,
    productList,
    currentUser,
    searchProduct,
    loadingAction,
    fetchCategories,
    fetchShopBrands,
    purchaseProducts,
    productBrandList,
    currentStockList,
    openProductKeyPad,
    selectProductItem,
    fetchShopProducts,
    selectProductBrand,
    setPurchaseProducts,
    choossedProductItem,
    productCategoryList,
    selectedProductBrand,
    selectProductCategory,
    deletePurchaseProduct,
    selectedProductCategory,
    updateCurrentStockCount,
    setPurchaseProductItem,
    selectedPurchaseProduct,
  } = props;
  const { action, loading } = loadingAction;

  const [searchText, setSearchText] = useState("");
  const [filterType, setFilterType] = useState("CATEGORY");
  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState(productCategoryList);
  const [isOpenSelectModal, setIsOpenSelectModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenOptionModal, setIsOpenOptionModal] = useState<boolean>(false);
  const [selectedRemoveProduct, setSelectedRemoveProduct] = useState<any>({});
  const [isOpenApprovalModal, setIsOpenApprovalModal] =
    useState<boolean>(false);
  const [isOpenModifierModal, setIsOpenModifierModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(shop.shopId)) {
      fetchShopBrands(shop.shopId);
      fetchCategories(shop.shopId);
      fetchMenu(shop.shopId);
    }
  }, [shop]);

  useEffect(() => {
    if (filterType === "CATEGORY") {
      setCategoryList(productCategoryList);
      selectProductCategory(selectedProductCategory);
    } else {
      selectProductBrand(selectedProductBrand);
    }
  }, [filterType]);

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText]);

  const debouncedSearch = useCallback(
    _.debounce((text) => searchProductForText(text), 600, {
      leading: false,
      trailing: true,
    }),
    [selectedProductCategory]
  );

  const searchProductForText = (text) => {
    if (text && text.length > 0) {
      searchProduct(text, selectedProductCategory.categoryId, shop.shopId);
    } else {
      fetchShopProducts(shop.shopId);
    }
  };

  const toggleDeleteModal = () => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const removeSelectedProduct = () => {
    toggleDeleteModal();
    selectProductItem({});
    onUpdateStockCount(selectedRemoveProduct.pid, true);
    deletePurchaseProduct(selectedRemoveProduct);
    setSelectedRemoveProduct({});
  };

  const onClickProductFilter = () => {
    setVisibleFilter(!visibleFilter);
  };

  const onClickMainFilter = (item) => {
    setFilterType(item.value);
    onClickProductFilter();
  };

  const onUpdateStockCount = (productId, isRemoved, param = null) => {
    const productData = isRemoved
      ? _.find(purchaseProducts, (item) => item.id === productId)
      : param;
    const particularProduct = _.find(
      productList,
      (product) => product.pid === productData.id
    );
    const stockItems = _.get(particularProduct, "stockItems", []);
    const newStock = updateStockItemCount(
      stockItems,
      currentStockList,
      productData.quantity,
      isRemoved
    );
    updateCurrentStockCount(newStock);
  };

  const toggleOptionModal = () => {
    // selectProductItem({});
    setIsOpenOptionModal(!isOpenOptionModal);
  };

  const toggleModifierModal = () => {
    setIsOpenModifierModal(!isOpenModifierModal);
  };

  const onSelectProductItem = (product: any) => {
    const initialValue = getInitialModifiers(product);
    if (!isEmpty(product.priceVariants)) {
      toggleOptionModal();
    } else {
      const param: any = {
        tax: 0,
        quantity: 1,
        symbol1: "",
        symbol2: "",
        id: product.pid,
        type: "PRODUCT",
        enteredNumber1: "",
        enteredNumber2: "",
        image: product.image,
        modifiers: initialValue,
        sendToBar: !!product.sendToBar,
        productName: product.productName,
        price: parseFloat(product.itemPrice),
        sendToKitchen: !!product.sendToKitchen,
        sendPrepTicket: product.sendPrepTicket,
        warranty: get(product, "warranty", {}),
        productPrice: parseFloat(product.itemPrice),
        productSalePrice: parseFloat(product.itemPrice),
      };
      setPurchaseProductItem(param);
      // setPurchaseProducts([param]);
      if (!isEmpty(product.modifiers)) {
        toggleModifierModal();
      } else {
        onUpdateStockCount(param.id, false, param);
        setPurchaseProducts([param]);
      }
    }
    selectProductItem(product);
  };

  const onSelectCategory = (data) => {
    setSearchText("");
    if (filterType === "CATEGORY") {
      selectProductCategory(data);
    } else {
      selectProductBrand(data);
    }
  };

  const onClickMenu = useCallback(
    (id: string) => {
      const selectedMenu = find(catalogue, (item) => item.menuId === id);
      let categories: any = [];
      if (id !== "ALL" && !isEmpty(selectedMenu?.categories)) {
        selectedMenu?.categories.forEach((cat) => {
          const category = productCategoryList.find(
            ({ categoryId }) => categoryId === cat.categoryId
          );
          if (category) {
            if (!isEmpty(cat.products)) {
              category.products = cat.products;
            }
            categories.push(category);
          }
        });
      } else if (id === "ALL") {
        categories = productCategoryList;
        fetchShopProducts(shop.shopId);
      }
      if (categories.length > 0) {
        // selectProductCategory(categories[0]);
      } else {
        // selectProductCategory({});
      }
      onSelectCategory({
        category: "ALL",
        categoryId: "ALL",
        shopId: shop.shopId,
      });
      setCategoryList(categories);
    },
    [
      catalogue,
      fetchShopProducts,
      productCategoryList,
      shop.shopId,
      onSelectCategory,
    ]
  );

  const [height, setHeight] = useState<any>(0);
  const ref: any = useRef(null);

  useEffect(() => {
    if (ref?.current) {
      setHeight(ref.current?.clientHeight);
    }
  });

  const onBackFromModifier = () => {
    if (!isEmpty(selectedPurchaseProduct?.priceVariants)) {
      toggleModifierModal();
      toggleOptionModal();
    } else {
      deletePurchaseProduct(selectedPurchaseProduct);
      toggleModifierModal();
    }
  };

  const onBackFromOption = () => {
    if (!isEmpty(selectedPurchaseProduct)) {
      deletePurchaseProduct(selectedPurchaseProduct);
    }
    toggleOptionModal();
  };

  return (
    <AppointmentProductContainer visibleFilter={visibleFilter}>
      {/* <SearchComponent
        searchText={searchText}
        title="Search by product name"
        deleteSearchText={() => setSearchText("")}
        onSearchText={(text) => setSearchText(text)}
      /> */}
      <TagSearchBar
        searchText={searchText}
        title="Search by product name"
        deleteSearchText={() => setSearchText("")}
        onSearchText={(text) => setSearchText(text)}
        onClickTag={(id) => onClickMenu(id)}
      />
      <div ref={ref}>
        <div className="category-list">
          <ServiceCategoryCard
            category={{
              category: "ALL",
              categoryId: "ALL",
              shopId: shop.shopId,
            }}
            isCategory={filterType === "CATEGORY"}
            onSelectCategory={(data: any) => onSelectCategory(data)}
            isSelected={selectedProductCategory.categoryId === "ALL"}
          />
          {categoryList.map((category) => {
            const isCategory = filterType === "CATEGORY";
            const isSelected = isCategory
              ? selectedProductCategory.categoryId === category.categoryId
              : category.id === selectedProductBrand.id;
            return (
              <ServiceCategoryCard
                key={category.id}
                category={category}
                isSelected={isSelected}
                isCategory={isCategory}
                onSelectCategory={(data) => onSelectCategory(data)}
              />
            );
          })}
        </div>
        <img
          onClick={() => onClickProductFilter()}
          src={visibleFilter ? IconSelectFilter : IconFilter}
        />
      </div>
      {visibleFilter && (
        <FilterComponent
          paddingRight={"140px"}
          options={filterOptions}
          visible={visibleFilter}
          onClick={(item) => onClickMainFilter(item)}
        />
      )}
      {loading &&
        (action.type === "SEARCH_PRODUCT" ||
          action.type === "FETCH_ALL_PRODUCT") && (
          <div className={"productLoading"}>
            <CircularProgress color="primary" size={40} value={5} />
          </div>
        )}

      <AppointmentProductCardContainer
        style={{ height: `calc(100% - ${height + 70}px)` }}
      >
        {productList.map((item: any) => {
          if (selectedProductCategory.products) {
            const pIndex = selectedProductCategory.products.findIndex(
              ({ productId, pid }) => productId === item.pid || pid === item.pid
            );
            if (pIndex === -1) {
              return null;
            }
          }
          let isSelected = item.pid === choossedProductItem.pid;
          let index = _.findIndex(
            purchaseProducts,
            (product: any) => product.id === item.pid
          );
          return (
            <ProductCard
              key={item.pid}
              item={item}
              index={index}
              isEdit={false}
              isSelected={isSelected}
              stockList={currentStockList}
              category={selectedProductCategory}
              onDelete={() => {
                if (
                  !isCanAccess(currentUser.specializations, "isCanDeleteItem")
                ) {
                  setSelectedRemoveProduct(item);
                  setIsOpenApprovalModal(true);
                } else {
                  setSelectedRemoveProduct(item);
                  toggleDeleteModal();
                }
              }}
              onSelectServiceItem={(data) => onSelectProductItem(data)}
            />
          );
        })}
      </AppointmentProductCardContainer>

      {/* <AppointmentProductKeyBoardContainer
        onClick={() => {
          if (!_.isEmpty(choossedProductItem)) {
            openProductKeyPad(true);
          } else {
            setIsOpenSelectModal(true)
          }
        }}>
        <div>Keypad</div>
      </AppointmentProductKeyBoardContainer> */}

      <SelectOptionModal
        isOpen={isOpenOptionModal}
        closeModal={() => toggleOptionModal()}
        onBackFromOption={() => onBackFromOption()}
        updateStockCount={(params: any) =>
          onUpdateStockCount(params.id, false, params)
        }
        onOpenModifier={() => toggleModifierModal()}
      />

      <SelectModifierModal
        isOpen={isOpenModifierModal}
        closeModal={() => toggleModifierModal()}
        onBackFromModifier={() => onBackFromModifier()}
      />
      <ProductDeleteModal
        status={"PRODUCT"}
        isOpen={isOpenDeleteModal}
        closeModal={() => toggleDeleteModal()}
        removeProduct={() => removeSelectedProduct()}
      />
      <ProductSelectModal
        status={"PRODUCT"}
        isOpen={isOpenSelectModal}
        closeModal={() => setIsOpenSelectModal(false)}
      />
      <ApprovalView
        isOpenApprovalModal={isOpenApprovalModal}
        onSetIsOpenApprovalModal={(status) => setIsOpenApprovalModal(status)}
        onOpenEditModal={() => {}}
        deleteItem={() => {
          toggleDeleteModal();
          setIsOpenApprovalModal(false);
        }}
      />
    </AppointmentProductContainer>
  );
};
export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    catalogue: state.product.get("catalogue"),
    stockList: state.product.get("stockList"),
    currentUser: state.login.get("currentUser"),
    productList: state.product.get("productList"),
    loadingAction: state.common.get("loadingAction"),
    productBrandList: state.product.get("brandsList"),
    purchaseProducts: state.product.get("purchaseProducts"),
    currentStockList: state.product.get("currentStockList"),
    productCategoryList: state.product.get("productCategoryList"),
    choossedProductItem: state.product.get("choossedProductItem"),
    selectedProductBrand: state.product.get("selectedProductBrand"),
    selectedProductCategory: state.product.get("selectedProductCategory"),
    selectedPurchaseProduct: state.product.get("selectedPurchaseProduct"),
  }),
  {
    fetchMenu: Actions.product.fetchMenu,
    searchProduct: Actions.product.searchProduct,
    fetchShopBrands: Actions.product.fetchShopBrands,
    fetchCategories: Actions.service.fetchCategories,
    openProductKeyPad: Actions.product.openProductKeyPad,
    fetchShopProducts: Actions.product.fetchShopProducts,
    selectProductItem: Actions.product.selectProductItem,
    selectProductBrand: Actions.product.selectProductBrand,
    setPurchaseProducts: Actions.product.setPurchaseProducts,
    removeSelectedProduct: Actions.product.removeSelectedProduct,
    selectProductCategory: Actions.product.selectProductCategory,
    deletePurchaseProduct: Actions.product.deletePurchaseProduct,
    setPurchaseProductItem: Actions.product.setPurchaseProductItem,
    updateCurrentStockCount: Actions.product.updateCurrentStockCount,
  }
)(Products);
