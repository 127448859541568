import { nanoid } from "nanoid";
import Repository from "./Repository";
import { cloneDeep, get } from "lodash";
import Storage from "@aws-amplify/storage";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import GraphqlFunctions from "../service/Graphql.functions";
import { isNetworkError } from "../manager/AppointmentManager";
import { CreateActivityLog, fetchActivities } from "./schema/Order.schema";

class ActivityRepository extends Repository {
  uploadDataLocalStorage = (data: any) => {
    data.metaData.forEach(async (meta: any) => {
      const url = await Storage.get(meta.value, {
        cacheControl: "no-cache",
        region: "ap-southeast-1",
        bucket: "netlise-activity-logs",
      });
      // fetch(url)
      //   .then((response) => response.json())
      //   .then(async (data) => {
      //     await setValueToLocalStorage(meta.value, JSON.stringify(data));
      //   });
    });
  };

  uploadLocalStorage = (data: any) => {
    data.map((_activity: any) => {
      // this.uploadDataLocalStorage(activity);
    });
  };

  fetchActivityLogs = async (
    shopId: string,
    limit: number,
    nextToken: any,
    retryCount: number = 1
  ) => {
    const isOnline = await this.isCheckOnline();
    try {
      let data: any = [];
      let token: any = null;
      if (isOnline) {
        try {
          const params = {
            limit,
            shopId,
            nextToken,
          };
          const result = await this.API.graphql(
            graphqlOperation(fetchActivities, params)
          );
          data = get(result, "data.fetchActivities.items", []);
          token = get(result, "data.fetchActivities.nextToken", null);
          await GraphqlFunctions.commonGraphqlService("SET_ACTIVITY_LOGS", {
            activities: data,
            type: "ACTIVITY_LOG",
          });
          this.uploadLocalStorage(data);
        } catch (error) {
          throw error;
        }
      } else {
        data = await GraphqlFunctions.commonGraphqlService(
          "GET_ACTIVITY_LOGS",
          { shopId, type: "ACTIVITY_LOG" }
        );
      }
      return { data, nextToken: token };
    } catch (error) {
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchActivityLogs(
          shopId,
          limit,
          nextToken,
          ++retryCount
        );
      }
      console.warn("fetch activities error", error);
      return { error };
    }
  };

  createActivityLog = async (params: any, type: string = "CREATED") => {
    const isOnline = await this.isCheckOnline();
    try {
      let data: any;
      data = await GraphqlFunctions.commonGraphqlService(
        "CREATE_ACTIVITY_LOG",
        { activity: params, type: "ACTIVITY_LOG" }
      );
      if (!isOnline) {
        await GraphqlFunctions.commonGraphqlService("CREATE_ACTIVITY_LOG", {
          activity: params,
          type: "OFFLINE_ACTIVITY_LOG",
        });
      } else {
        const result = await this.API.graphql(
          graphqlOperation(CreateActivityLog, { input: params })
        );
        data = get(result, "data.createActivityLog", {});
        if (type === "CREATED") {
          await this.uploadDataLocalStorage(data);
        }
      }
      return data;
    } catch (error) {
      return { error };
    }
  };

  uploadBucket = async (params: any) => {
    await Promise.all(
      params.metaData.map(async (data: any) => {
        const path = `${params.shopId}/${params.payloadId}/${data.key}`;
        const metaJson = JSON.stringify(data.value);
        const { key } = await Storage.put(path, metaJson, {
          bucket: "netlise-activity-logs",
          // "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
        });
        data.value = key;
        return data;
      })
    );
    return params;
  };

  // @ts-ignore
  uploadActivity = async (params: any, type: string = "CREATED") => {
    let data: any;
    let payloadId = nanoid(6);
    params["payloadId"] = payloadId;
    const isOnline = await this.isCheckOnline();

    // try {
    //   if (!isOnline) {
    //     data = await this.createActivityLog(params);
    //   } else {
    //     params = await this.uploadBucket(params);
    //     data = await this.createActivityLog(params, type);
    //   }
    //   return data;
    // } catch (error) {
    //   return { error };
    // }
  };

  createOfflineActivity = async () => {
    // const isOnline = await this.isCheckOnline();
    // let data = await GraphqlFunctions.commonGraphqlService(
    //   "GET_ACTIVITY_LOGS",
    //   { shopId: "", type: "OFFLINE_ACTIVITY_LOG" }
    // );
    // let activities: any = [];
    // if (isOnline) {
    //   try {
    //     await Promise.all(
    //       data.map(async (activity: any) => {
    //         const path = activity.payloadId;
    //         const activityJson = JSON.stringify(activity);
    //         const key = await Storage.put(path, activityJson, {
    //           bucket: "netlise-activity-logs",
    //           // "Content-Type": "application/json",
    //           // "Access-Control-Allow-Origin": "*",
    //         });
    //         const result = await this.API.graphql(
    //           graphqlOperation(CreateActivityLog, { input: activity })
    //         );
    //         await GraphqlFunctions.commonGraphqlService("DELETE_ACTIVITY_LOG", {
    //           activity,
    //           type: "OFFLINE_ACTIVITY_LOG",
    //         });
    //         activities.push(get(result, "data.createActivityLog", {}));
    //       })
    //     );
    //     return activities;
    //   } catch (error) {
    //     return { error };
    //   }
    // }
  };

  restoreActivityLog = async (params: any) => {
    let newActivity: any = cloneDeep(params);
    newActivity["logId"] = nanoid();
    newActivity["actType"] = "RESTORED";
    newActivity["createdTime"] = Date.now();
    newActivity["updatedTime"] = Date.now();
    try {
      return await this.createActivityLog(newActivity, "RESTORED");
    } catch (error) {
      return { error };
    }
  };
}

export default new ActivityRepository();
