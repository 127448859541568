export const USER_ROLE_PERMISSION = {
  'ADMIN': {
    isCheckout: true,
    isDeleteLineItems: true,
    isEditOrDeleteTable: true,
    isEditOrDeleteRooms: true,
    isEditOrDeleteUsers: true,
    isEditOrDeletePrinters: true,
    isCanDeleteItem: true
  },
  'FRONT_DESK': {
    isCheckout: true,
    isDeleteLineItems: false,
    isEditOrDeleteTable: false,
    isEditOrDeleteRooms: false,
    isEditOrDeleteUsers: false,
    isEditOrDeletePrinters: false,
    isCanDeleteItem: false
  },
  'MANAGER': {
    isCheckout: true,
    isDeleteLineItems: true,
    isEditOrDeleteTable: true,
    isEditOrDeleteRooms: true,
    isEditOrDeleteUsers: true,
    isEditOrDeletePrinters: true,
    isCanDeleteItem: true
  },
  'WAITER': {
    isCheckout: false,
    isDeleteLineItems: false,
    isEditOrDeleteTable: false,
    isEditOrDeleteRooms: false,
    isEditOrDeleteUsers: false,
    isEditOrDeletePrinters: false,
    isCanDeleteItem: false
  }
}


