import Storage from '@aws-amplify/storage';
import Repository from "./Repository";

class StorageRepository extends Repository {
  async uploadImage (fileName, file) {
    try {
      return Storage.put(fileName, file, {
        contentType: file.type,
        // bucket: "netlise-activity-logs",
      });
    } catch (error) {
      return {error}
    }
  }
}

export default new StorageRepository();

