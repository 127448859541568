import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import SortIcon from "../../../../assets/icons/sortIcon.svg";
import defaultStylistImage from "../../../../assets/images/default-user.png";
import { connect } from "react-redux";
import { Actions } from "../../../internal/app/Actions";
import { generateImageUrl } from "../../../internal/manager/ImageManager";
import moment from "moment";
import _, { startCase } from "lodash";
import BaseColors from "../../../internal/helpers/BaseColors";
import {
  OrderQueueTableValue,
  OrderQueueTableContainer,
  OrderQueueTableTitleContainer,
  OrderQueueTableValueContainer,
  OrderQueueTableHeaderContainer,
  OrderQueueTableImageValueContainer,
  OrderQueueTableHeaderTitleContainer,
  OrderQueueTableHeaderTitleTextContainer,
} from "../../Styles/Styled";
import { useIntl } from "react-intl";

const OrderQueue = (props) => {
  const {
    unCompletedAppointments,
    clearCurrentAppointment,
    selectedAppointment,
    currentUser,
  } = props;
  const [appointments, setAppointments] = useState([]);
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    setAppointments(unCompletedAppointments);
  }, [unCompletedAppointments]);

  const setBookingStatusColor = (status) => {
    switch (status) {
      case "PENDING":
        return BaseColors.ceruleanBlue;
      case "REOPEN":
        return BaseColors.RedOrange;
      case "CONFIRMED":
        return BaseColors.mediumSeaGreen;
      case "CANCELED":
        return BaseColors.RedOrange;
      case "DECLINED":
        return BaseColors.RedOrange;
      default:
        return BaseColors.goldenPoppy;
    }
  };

  const gridMode = _.get(currentUser, "viewMode", "GRID") === "GRID";

  const onSelectedAppointment = (data) => {
    clearCurrentAppointment(true, {
      navigate: gridMode ? "/product" : "/order-list",
    });
    setTimeout(() => {
      selectedAppointment(data);
    }, 200);
  };

  return (
    <OrderQueueTableContainer>
      <OrderQueueTableHeaderContainer>
        <div>{f({ id: "ORDER_QUEUE.TITLE" })}</div>
      </OrderQueueTableHeaderContainer>
      <OrderQueueTableTitleContainer>
        <OrderQueueTableHeaderTitleContainer>
          <Row style={{ height: "100%", margin: 0 }}>
            <Col md={2}>
              <OrderQueueTableHeaderTitleTextContainer>
                <div>{f({ id: "ORDER_QUEUE.TABLE.EMPLOYEE" })}</div>
                <img src={SortIcon} />
              </OrderQueueTableHeaderTitleTextContainer>
            </Col>
            <Col md={2}>
              <OrderQueueTableHeaderTitleTextContainer>
                <div>{f({ id: "ORDER_QUEUE.TABLE.ORDER_DATE" })}</div>
                <img src={SortIcon} />
              </OrderQueueTableHeaderTitleTextContainer>
            </Col>
            <Col md={2}>
              <OrderQueueTableHeaderTitleTextContainer>
                <div>{f({ id: "ORDER_QUEUE.TABLE.CUSTOMER_NAME" })}</div>
                <img src={SortIcon} />
              </OrderQueueTableHeaderTitleTextContainer>
            </Col>
            <Col md={1.5}>
              <OrderQueueTableHeaderTitleTextContainer>
                <div>Order Id</div>
                <img src={SortIcon} />
              </OrderQueueTableHeaderTitleTextContainer>
            </Col>
            <Col md={2.5}>
              <OrderQueueTableHeaderTitleTextContainer>
                <div>{f({ id: "ORDER_QUEUE.TABLE.SERVICE" })}</div>
                <img src={SortIcon} />
              </OrderQueueTableHeaderTitleTextContainer>
            </Col>
            <Col md={1}>
              <OrderQueueTableHeaderTitleTextContainer>
                <div>{f({ id: "ORDER_QUEUE.TABLE.STATUS" })}</div>
                <img src={SortIcon} />
              </OrderQueueTableHeaderTitleTextContainer>
            </Col>
            <Col md={1}>
              <OrderQueueTableHeaderTitleTextContainer>
                <div>{f({ id: "ORDER_QUEUE.TABLE.TABLE_NUM" })}</div>
                <img src={SortIcon} />
              </OrderQueueTableHeaderTitleTextContainer>
            </Col>
          </Row>
        </OrderQueueTableHeaderTitleContainer>

        <OrderQueueTableValueContainer>
          {appointments &&
            appointments?.map((data: any, index: number) => {
              const serviceText = _.get(data, "productDetails", [])
                ?.map((item) => startCase(item.productName || item.title))
                .join(", ");
              const color = setBookingStatusColor(data.orderStatus);
              const appointmentDate = data.date
                ? moment(data.date).format("dddd DD/MM/YYYY") + " " + data.time
                : "";
              const image = !_.isEmpty(data.stylistId)
                ? _.get(data, "stylist.profileImage", null)
                : _.get(data, "salon.image", null);

              return (
                <div onClick={() => onSelectedAppointment(data)}>
                  <Row style={{ height: "100%", margin: 0, minHeight: "60px" }}>
                    <Col md={2}>
                      <OrderQueueTableImageValueContainer>
                        <img
                          src={
                            !_.isEmpty(image)
                              ? generateImageUrl(image)
                              : defaultStylistImage
                          }
                        />
                        <div>
                          <div className={"nameText"}>
                            {data.employeeName || data.shopName}
                          </div>
                          <div className={"specializationText"}>
                            {_.get(data, "stylist.specializations.0.title", "")}
                          </div>
                        </div>
                      </OrderQueueTableImageValueContainer>
                    </Col>
                    <Col md={2}>
                      <OrderQueueTableValue>
                        {appointmentDate}
                      </OrderQueueTableValue>
                    </Col>
                    <Col md={2}>
                      <OrderQueueTableValue>{`${data.firstName || ""} ${
                        data.lastName || ""
                      }`}</OrderQueueTableValue>
                    </Col>
                    <Col md={1.5}>
                      <OrderQueueTableValue>
                        {data.recieptId}
                      </OrderQueueTableValue>
                    </Col>
                    <Col md={2.5}>
                      <OrderQueueTableValue>{serviceText}</OrderQueueTableValue>
                    </Col>
                    <Col md={1}>
                      <OrderQueueTableValue status={true} color={color}>
                        {_.upperFirst(_.toLower(data.orderStatus))}
                      </OrderQueueTableValue>
                    </Col>
                    <Col md={1}>
                      <OrderQueueTableValue>
                        {data?.tableOrder?.tbNumber || "-"}
                      </OrderQueueTableValue>
                    </Col>
                  </Row>
                  {unCompletedAppointments.length - index !== 1 ? (
                    <div className="orderQueueBottomLine" />
                  ) : null}
                </div>
              );
            })}
        </OrderQueueTableValueContainer>
      </OrderQueueTableTitleContainer>
    </OrderQueueTableContainer>
  );
};
export default connect(
  (state: any) => ({
    shopId: state.common.get("shopId"),
    salonAppointmentList: state.orders.get("salonAppointmentList"),
    unCompletedAppointments: state.orders.get("unCompletedAppointments"),
    stylistList: state.employee.get("stylistList"),
    currentUser: state.login.get("currentUser"),
  }),
  {
    clearCurrentAppointment: Actions.payment.clearCurrentAppointment,
    selectedAppointment: Actions.orders.selectedAppointment,
  }
)(OrderQueue);
