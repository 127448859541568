import Repository from "./Repository";
import _ from "lodash";
import {
  setValueToLocalStorage,
  filterEmployeesForSpec,
  getValueFromLocalStorage,
} from "../manager/CommonManager";
import { GetEmployee } from "./schema/Employee.schema";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { isNetworkError } from "../manager/AppointmentManager";

class EmployeeRepository extends Repository {
  async getEmployee(shopId: string, empId: string, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("EMPLOYEE_DATA");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }
      const result: any = await this.API?.graphql(
        graphqlOperation(GetEmployee, { empId, shopId })
      );
      await setValueToLocalStorage(
        "EMPLOYEE_DATA",
        JSON.stringify(result.data.getEmployee)
      );
      return result.data.getEmployee;
    } catch (error: any) {
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.getEmployee(shopId, empId, ++retryCount);
      }
      console.warn("get current user error", error);
      return { error };
    }
  }

  async fetchEmployees(shopId, limit, lastKey, retryCount = 1) {
    const isOnline = await this.isCheckOnline();
    try {
      if (!isOnline) {
        const list = await getValueFromLocalStorage("SHOP_EMPLOYEE");
        if (list) {
          return JSON.parse(list);
        }
        return { items: [] };
      }

      const message = this.buildMessage({ shopId, limit, lastKey });
      const data = await this.apiPost({
        apiName: this.DASHBOARD_API,
        path: "/employees",
        message,
      });
      await setValueToLocalStorage(
        "SHOP_EMPLOYEE",
        JSON.stringify(data.result)
      );
      return data.result;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchEmployees(shopId, limit, lastKey, ++retryCount);
      }
      return { error };
    }
  }

  async fetchEmployeesForSpecId(shopId, specialization) {
    const isOnline = await this.isCheckOnline();
    const params = {
      shopId,
      endpointType: "SPECIALIZATION",
      specializationId: specialization.id,
    };
    if (!isOnline) {
      const list = await getValueFromLocalStorage("SHOP_EMPLOYEE");
      if (list) {
        const stylistResult = JSON.parse(list);
        const filterList = filterEmployeesForSpec(
          stylistResult,
          specialization.id
        );
        return {
          Items: specialization.id === "0" ? stylistResult.items : filterList,
        };
      }
      return { Items: [] };
    }

    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: "/stylists",
        message,
      });
      return result.stylistResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchShopEmployees(shopId: string, retryCount: number = 1) {
    const isOnline = await this.isCheckOnline();
    try {
      if (!isOnline) {
        const list = await getValueFromLocalStorage("LOGIN_EMPLOYEE");
        if (list) {
          return JSON.parse(list);
        }
        return { Items: [] };
      }
      const params = {
        shopId,
      };
      const message = this.buildMessage(params);
      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: "/employees/shop-id",
        message,
      });
      await setValueToLocalStorage(
        "LOGIN_EMPLOYEE",
        JSON.stringify(result.employeeResult)
      );
      return result.employeeResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchShopEmployees(shopId, ++retryCount);
      }
      return { error };
    }
  }
}

export default new EmployeeRepository();
