import React, { useState, useEffect } from "react";
import { Row, Col } from "react-grid-system";
import IconCross from "../../../assets/svg/IconCross.svg";
import IconSearch from "../../../assets/svg/IconSearch.svg";
import { TagSearchContainer, TagButton, FilterContainer } from "./Styled";
import _, { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import Slide from "react-reveal/Slide";
import { connect } from "react-redux";
import { Actions } from "../../internal/app/Actions";
import IconSelectFilter from "../../../assets/svg/IconCloshcashSelectFilter.svg";
import IconFilter from "../../../assets/svg/IconCloshcashFilter.svg";

const TagSearchBar = (props) => {
  const {
    title,
    searchText,
    onSearchText,
    deleteSearchText,
    onClickTag,
    catalogue,
  } = props;
  const { formatMessage: f } = useIntl();
  const [hideSearch, setHideSearch] = useState(true);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [selectedTag, setSelectedTag] = useState("ALL");
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    if (!isEmpty(catalogue)) {
      setMenus(catalogue);
      //   setSelectedTag(catalogue[0].menuId);
    }
    // } else {
    setSelectedTag("ALL");
    onClickTag("ALL");
  }, [catalogue]);

  const onSelectMenu = (id: string) => {
    setSelectedTag(id);
    onClickTag(id);
  };

  const onClickIcon = () => {
    if (hideSearch) {
      setHideSearch(false);
    } else if (!isEmpty(searchText)) {
      deleteSearchText();
    } else {
      setHideSearch(true);
    }
  };

  return (
    <TagSearchContainer isHide={hideSearch} visibleFilter={visibleFilter}>
      <div>
        {hideSearch ? (
          <div>
            <div>
              {menus.map((item: any) => {
                const isSelected = item.menuId === selectedTag;
                return (
                  <TagButton
                    isALL={false}
                    isSelected={isSelected}
                    onClick={() => onSelectMenu(item.menuId)}
                  >
                    {item.title}
                  </TagButton>
                );
              })}
              <TagButton
                isALL={true}
                onClick={() => onSelectMenu("ALL")}
                isSelected={selectedTag === "ALL"}
              >
                {"All"}
              </TagButton>
            </div>
          </div>
        ) : null}
        {visibleFilter ? (
          <FilterContainer>
            {tags.map((item, index) => {
              return (
                <div>
                  <p onClick={() => setSelectedTag(item.id)}>{item.label}</p>
                  {index !== tags.length - 1 ? <div /> : null}
                </div>
              );
            })}
          </FilterContainer>
        ) : null}
      </div>
      <div>
        {
          <Slide distance={"40px"} duration={100} right when={!hideSearch}>
            <input
              type={"text"}
              value={searchText}
              placeholder={title}
              onChange={(event) => onSearchText(event.target.value)}
            />
          </Slide>
        }
        <img
          onClick={() => onClickIcon()}
          src={hideSearch ? IconSearch : IconCross}
        />
      </div>
    </TagSearchContainer>
  );
};
export default connect(
  (state) => ({
    catalogue: state.product.get("catalogue"),
  }),
  {}
)(TagSearchBar);
