import React, {useEffect, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import {connect} from 'react-redux';
import {Actions} from '../../../internal/app/Actions';
import _ from 'lodash';
import '../../Styles/ModelStyles.css';
import {CreateCategoryContainer} from '../../Styles/Styled';
import {nanoid} from "nanoid";
import Select from 'react-select';
import {mapStockMetrics} from "../../../internal/manager/ProductManager";

const customStyles = {
  content: {
    top: '10vh',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
    left: 'calc(50% - 215px)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const style = {
  control: (base, state) => ({
    ...base,
    height: "45px",
    width: "100%",
    paddingRight: "20px",
    border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    "&:hover": {
      border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    },
    borderRadius: "6px",
    color: "#434343",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    paddingLeft: "5px",
  }),
};

const AddStockModel = (props) => {
  const {
    type, isOpen, closeModal, currentUser, createStock, shop} = props;

  const [header, setHeader] = useState('');
  const [titlePlaceholder, setTitlePlaceholder] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [itemName, setItemName] = useState('');

  useEffect(() => {
    switch (type) {
      case 'STOCK_ITEM':
        setHeader('Add Stock');
        setTitlePlaceholder('STOCK ITEM NAME');
        setButtonText('Add Stock');
        return;
      default:
        return;
    }
  }, [type]);

  const initialState = () => {
    setItemName('');
    setSelectedMetric(null);
  };

  const onCreateStock = () => {
    const param:any = {
      itemName,
      metric: selectedMetric.value,
      shopId: shop.shopId,
      stockId: nanoid(8),
      updatedTime: Date.now(),
      createdUserId: currentUser.empId,
      metricValue: selectedMetric.metricValue,
      createdTime: Date.now()
    };

    createStock(param, false);
    closeModal();
    initialState();
  };

  const onInputChange = (option, {action}) => {
    if (action === "input-change") {
      const optionLength = option.length;
      const inputValueLength = inputValue.length;
      const newInputValue =
        optionLength < inputValueLength
          ? option
          : inputValue + option[option.length - 1];
      setInputValue(newInputValue);
    }
  };

  const isValid = !_.isEmpty(selectedMetric) && itemName;

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.createCategoryModal}
    >
      <CreateCategoryContainer isValid={!_.isEmpty(inputValue)}>
        <div onClick={() => {
          closeModal();
          initialState();
        }}>
          <div>{header}</div>
          <img src={CANCEL}/>
        </div>
        <div>
          <div>
            <div>{titlePlaceholder}</div>
            <input
              onChange={(text) => setItemName(text.target.value)}
              value={itemName}
              className='stockTextInput'
              placeholder="Stock item Name"
            />
          </div>

          <div style={{width: '100%'}}>
            <div className="serviceTitleText">
              STOCK METRIC
            </div>
            <Select
              className="basic-single"
              classNamePrefix="select"
              onChange={(data) => {
                setSelectedMetric(data);
                setInputValue('');
              }}
              styles={style}
              value={selectedMetric}
              placeholder={
                <div className="categoryPlaceholderText">
                  Stock metric
                </div>
              }
              name="color"
              options={mapStockMetrics}
              onInputChange={onInputChange}
            />
          </div>
          <div>
            <button disabled={!isValid} style={{backgroundColor: isValid ? '#49A4D4' : '#8A9CA5'}} onClick={() => {
              onCreateStock()
            }}>
              <div>{buttonText}</div>
            </button>
          </div>
        </div>
      </CreateCategoryContainer>
    </ReactModal>
  );
};
export default connect(
  (state) => ({
    shop: state.login.get('merchantShop'),
    currentUser: state.login.get('currentUser'),
  }),
  {
    createStock: Actions.product.createStock,

  }
)(AddStockModel);
