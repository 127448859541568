import React, { useEffect, useState, useCallback } from "react";
import locale from "locale-codes";
import Select from "react-select";
import Toggle from "react-toggle";
import { connect } from "react-redux";
import _, { get, isEmpty, isNil } from "lodash";
import { Actions } from "../../../internal/app/Actions";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { RegisterContainer, RegisterOrderWrapper } from "./Styled";
import IconRegisterSelect from "../../../../assets/svg/IconRegisterSelect.svg";
import { mapSystemOptions } from "../../../internal/manager/AppointmentManager";
import IconRegisterUnselect from "../../../../assets/svg/IconRegisterUnselect.svg";

const style = {
  control: (base, state) => ({
    ...base,
    width: "100%",
    height: "42px",
    fontSize: "14px",
    paddingLeft: "5px",
    borderRadius: "4px",
    paddingRight: "20px",
    color: "#00000085",
    fontFamily: "Montserrat",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5",
    "&:hover": {
      border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5",
    },
  }),
};

const OrderOptionsSettings = {
  "orderOptions.isRooms": "false",
  "orderOptions.isOffers": "false",
  "orderOptions.isWarranty": "false",
  "orderQueueOptions.isQueueRooms": "false",
  "orderQueueOptions.isQueueTables": "false",
};

const Register = (props: any) => {
  const { shop, updateShop, currentUser, updateEmployee } = props;
  const [mode, setMode] = useState("GRID");
  const [language, setLanguage] = useState({});
  const [languageList, setLanguageList] = useState([]);

  const getMappedSettings = useCallback(() => {
    if (shop.systemSettings) {
      let settings = OrderOptionsSettings;
      shop.systemSettings.forEach(({ key, value }) => {
        if (!isNil(settings[key])) {
          settings[key] = value;
        }
      });
      return settings;
    }
    return OrderOptionsSettings;
  }, [shop]);

  const [optionsSettings, setOptionsSettings] = useState(getMappedSettings());

  useEffect(() => {
    setOptionsSettings(getMappedSettings());
  }, [shop, getMappedSettings]);

  useEffect(() => {
    if (shop.systemSettings) {
      let settings = OrderOptionsSettings;

      shop.systemSettings.forEach(({ key, value }) => {
        if (!isNil(settings[key])) {
          settings[key] = value;
        }
      });
    }
  }, [shop]);

  useEffect(() => {
    setMode(
      isEmpty(get(currentUser, "viewMode", "GRID"))
        ? "GRID"
        : currentUser.viewMode
    );
    const userLanguage: any = _.get(currentUser, "language", "en-US");
    const findLanguage: any = _.find(
      locale.all,
      (data) => data.tag === userLanguage
    );
    setLanguage({
      value: findLanguage.tag,
      label: `${findLanguage.location} ${findLanguage.local}`,
    });
  }, [currentUser]);

  useEffect(() => {
    const filterLanguage = locale.all.filter(
      (data) => data.tag === "si-LK" || data.tag === "en-US"
    );
    const languageList = filterLanguage.map((data) => {
      // all languages for need to add these function
      // if (!_.isNil(data.local) && !_.isNil(data.location)) {
      //   return {
      //     value: data.tag,
      //     label: `${data.location} ${data.local}`
      //   }
      // }
      // return null
      return {
        value: data.tag,
        label: `${data.location} ${data.local}`,
      };
    });
    const sortLanguage: any = _.sortBy(_.compact(languageList), ["label"]);
    setLanguageList(sortLanguage);
  }, []);

  const onClickMode = (type) => {
    setMode(type);
    const params = {
      empId: currentUser.empId,
      shopId: currentUser.shopId,
      viewMode: type,
    };
    debouncedUpdateUser(params);
  };

  const onClickLanguage = (data) => {
    setLanguage(data);
    const params = {
      empId: currentUser.empId,
      shopId: currentUser.shopId,
      language: data.value,
    };
    debouncedUpdateUser(params);
  };

  const debouncedUpdateUser = _.debounce((params) => {
    updateEmployee(params);
  }, 500);

  const onChangeField = (field, value) => {
    const options = {
      ...optionsSettings,
      [field]: `${value}`.trim(),
    };
    setOptionsSettings(options);
    const settings = mapSystemOptions(shop.systemSettings, options);
    shop.systemSettings = settings;
    updateShop(shop);
  };

  const renderToggle = (props: any) => {
    const { value, field } = props;
    return (
      <Toggle
        value={value}
        checked={value}
        icons={{
          checked: false,
          unchecked: false,
        }}
        onChange={(e: any) => onChangeField(field, e.target.checked)}
      />
    );
  };

  return (
    <RegisterContainer>
      <div>
        <div>{"Register Location"}</div>
        <div>{get(shop, "shopName", "")}</div>
      </div>
      <div>
        <div>{"Address"}</div>
        <div>{get(shop, "address", "")}</div>
      </div>
      <div>{"Register Order Creation Mode"}</div>
      <div onClick={() => onClickMode("GRID")}>
        <Checkbox
          checked={mode === "GRID"}
          onChange={() => onClickMode("GRID")}
          icon={<img src={IconRegisterUnselect} />}
          checkedIcon={<img src={IconRegisterSelect} />}
        />
        <div>{"Grid Mode"}</div>
      </div>

      <div onClick={() => onClickMode("LIST")}>
        <Checkbox
          checked={mode === "LIST"}
          icon={<img src={IconRegisterUnselect} />}
          onChange={() => onClickMode("LIST")}
          checkedIcon={<img src={IconRegisterSelect} />}
        />
        <div>{"Line Item Mode"}</div>
      </div>

      <div />

      <div>
        <div>{"Operating Language"}</div>
        <div>
          <Select
            styles={style}
            value={language}
            options={languageList}
            onChange={(data) => onClickLanguage(data)}
            placeholder={
              <div className="languagePlaceholderText">language</div>
            }
          />
        </div>
      </div>

      <RegisterOrderWrapper>
        <div>{"Order Options"}</div>
        <div>
          <div>{"Offers"}</div>
          {renderToggle({
            value: optionsSettings["orderOptions.isOffers"] === "true",
            field: "orderOptions.isOffers",
          })}
        </div>
        {shop.shopCategory === "RETAILS" && (
          <div>
            <div>{"Warranty"}</div>
            {renderToggle({
              value: optionsSettings["orderOptions.isWarranty"] === "true",
              field: "orderOptions.isWarranty",
            })}
          </div>
        )}
        {shop.shopCategory === "HOSPITALITY" && (
          <div>
            <div>{"Rooms"}</div>
            {renderToggle({
              value: optionsSettings["orderOptions.isRooms"] === "true",
              field: "orderOptions.isRooms",
            })}
          </div>
        )}
      </RegisterOrderWrapper>
      {(shop.shopCategory === "FOOD_AND_BEVERAGES" ||
        shop.shopCategory === "HOSPITALITY") && (
        <RegisterOrderWrapper>
          <div>{"Order Queue Options"}</div>
          {shop.shopCategory === "FOOD_AND_BEVERAGES" && (
            <div>
              <div>{"Tables"}</div>
              {renderToggle({
                value:
                  optionsSettings["orderQueueOptions.isQueueTables"] === "true",
                field: "orderQueueOptions.isQueueTables",
              })}
            </div>
          )}
          {shop.shopCategory === "HOSPITALITY" && (
            <div>
              <div>{"Rooms"}</div>
              {renderToggle({
                value:
                  optionsSettings["orderQueueOptions.isQueueRooms"] === "true",
                field: "orderQueueOptions.isQueueRooms",
              })}
            </div>
          )}
        </RegisterOrderWrapper>
      )}
    </RegisterContainer>
  );
};
export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
  }),
  {
    updateShop: Actions.profile.updateShop,
    updateEmployee: Actions.profile.updateEmployee,
  }
)(Register);
