import CloseCashRepository from "../../internal/repos/CloseCashRepository";

export const ModuleEvents = {
  CLOSE_CASH: "CLOSE_CASH",
  GET_BATCH_SESSION: "GET_BATCH_SESSION",
  GET_BATCH_SUMMARY: "GET_BATCH_SUMMARY",
  FETCH_ORDER_SUMMARY: "FETCH_ORDER_SUMMARY",
  CREATE_BATCH_SESSION: "CREATE_BATCH_SESSION",
  CREATE_CASH_OPERATION: "CREATE_CASH_OPERATION",
  UPDATE_CASH_OPERATION: "UPDATE_CASH_OPERATION",
  GET_PAST_BATCH_SUMMARY: "GET_PAST_BATCH_SUMMARY",
  COMPLETED_CASH_APPOINTMENTS: "COMPLETED_CASH_APPOINTMENTS",
  PAGINATE_PAST_BATCH_SUMMARY: "PAGINATE_PAST_BATCH_SUMMARY"
};

const createAction = (type, action = () => {}, meta) => {
  return (...args) => ({ type, payload: action(...args) , meta });
};

export default {
  getBatchSession: createAction(
    ModuleEvents.GET_BATCH_SESSION,
    async (id) => {
      const result = await CloseCashRepository.getBatchSession(id);
      return result;
    },
    { loading: true }
  ),
  createCashOperation: createAction(
    ModuleEvents.CREATE_CASH_OPERATION,
    async (params) => {
      const result = await CloseCashRepository.createCash(params);
      return result;
    },
    { loading: true }
  ),
  updateCashOperation: createAction(
    ModuleEvents.UPDATE_CASH_OPERATION,
    async (params) => {
      const result = await CloseCashRepository.updateCloseCash(params);
      return result;
    },
    { loading: true }
  ),
  completedCashAppointments: createAction(
    ModuleEvents.COMPLETED_CASH_APPOINTMENTS,
    async (batchId, shopId, limit = 50, lastKey = null) => {
      const result = await CloseCashRepository.completedCloseCash(
        batchId,
        shopId,
        limit,
        lastKey
      );
      return result;
    },
    { loading: true }
  ),
  getBatchSummary: createAction(
    ModuleEvents.GET_BATCH_SUMMARY,
    async (id) => {
      const result = await CloseCashRepository.getBatchSummary(id);
      return result;
    },
    { loading: true }
  ),
  closeCash: createAction(
    ModuleEvents.CLOSE_CASH,
    async (param) => {
      const result = await CloseCashRepository.closeCash(param);
      return result;
    },
    { loading: true, blocking: true }
  ),
  getPastBatchSummary: createAction(
    ModuleEvents.GET_PAST_BATCH_SUMMARY,
    async (shopId, userId, limit = 15, lastKey = null) => {
      const result = await CloseCashRepository.getPastBatchSummary(
        shopId,
        userId,
        limit,
        lastKey
      );
      return result;
    },
    { loading: true }
  ),
  paginatePastBatchSummary: createAction(
    ModuleEvents.PAGINATE_PAST_BATCH_SUMMARY,
    async (shopId, userId, limit = 10, lastKey = null) => {
      const result = await CloseCashRepository.getPaginatePastBatchSummary(
        shopId,
        userId,
        limit,
        lastKey
      );
      return result;
    },
    { loading: true }
  ),
  createBatchSession: createAction(
    ModuleEvents.CREATE_BATCH_SESSION,
    async (shopId, empId) => {
      const result = await CloseCashRepository.createBatch(shopId, empId);
      return result;
    },
    { loading: true, blocking: true }
  ),
  fetchOrderSummary: createAction(
    ModuleEvents.FETCH_ORDER_SUMMARY,
    async (shopId, batchId) => {
      const result = await CloseCashRepository.fetchBatchOrderSummary(
        shopId,
        batchId
      );
      return result;
    },
    { loading: true }
  ),
};
