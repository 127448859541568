import gql from 'graphql-tag';

export const CreateTableAreas = gql`
  mutation CreateTableAreas($input: CreateTableAreasInput!) {
    createTableAreas(input: $input) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      }
    }
  }
`;

export const UpdateTableAreas = gql`
  mutation UpdateTableAreas($input: UpdateTableAreasInput!) {
    updateTableAreas(input: $input) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
          w
        }
      }
    }
  }
`;

export const GetTableAreas = gql`
query GetTableAreas($shopId: String!) {
  getTableAreas(shopId: $shopId) {
    shopId
    tableAreas {
      areaName
      areaId
      tables {
        tbId
        tbNumber
        tbType
        tbShape
        x
        y
        h
        w
      }
    }
  }
}
`;

export const createTable = gql`
  mutation CreateTable($input: CreateTableInput!) {
    createTable(input: $input) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      }
    }
  }
`;

export const createOfflineTable = gql`
  mutation CreateOfflineTable($input: CreateOfflineTableInput!) {
    createOfflineTable(input: $input) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      }
    }
  }
`;

export const updateTable = gql`
  mutation UpdateTable($input: UpdateTableInput!) {
    updateTable(input: $input) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      }
    }
  }
`;

export const updateOfflineTable = gql`
  mutation UpdateOfflineTable($input: UpdateOfflineTableInput!) {
    updateOfflineTable(input: $input) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      }
    }
  }
`;

export const onCreateTable = gql`
  subscription OnCreateTable($shopId: String) {
    onCreateTable(shopId: $shopId) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      } 
    }
  }
`;

export const onUpdateTable = gql`
  subscription OnUpdateTable($shopId: String) {
    onUpdateTable(shopId: $shopId) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      } 
    }
  }
`;

export const setShopTables = gql`
  mutation SetShopTables($tables: SetShopTablesInput) {
    setShopTables(tables: $tables) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      }
    }
  }
`;

export const fetchTables = gql`
  query FetchTables($shopId: String!) {
    fetchTables(shopId: $shopId) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      }
    }
  }
`;

export const fetchOfflineTables = gql`
  query FetchOfflineTables($key: String!) {
    fetchOfflineTables(key: $key) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      }
    }
  }
`;

export const deleteOfflineTable = gql`
  mutation DeleteOfflineTable($input: DeleteOfflineTableInput!) {
    deleteOfflineTable(input: $input) {
        key
    }
  }
`;

export const serviceMessage = gql`
  mutation ServiceMessage($input: ServiceMessageInput!) {
    serviceMessage(input: $input) {
        key
        value
    }
  }
`;

export const onServiceMessage = gql`
  subscription OnServiceMessage($shopId: String) {
    onServiceMessage(shopId: $shopId) {
      key
      value 
    }
  }
`;

export const SubscribeUpdateTable = gql`
  subscription OnUpdateTableAreas($shopId: String) {
    onUpdateTableAreas(shopId: $shopId) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      } 
    }
  }
`;

export const SubscribeCreateTable = gql`
  subscription OnCreateTableAreas($shopId: String) {
    onCreateTableAreas(shopId: $shopId) {
      shopId
      tableAreas {
        areaName
        areaId
        tables {
          tbId
          tbNumber
          tbType
          tbShape
          x
          y
          h
	        w
        }
      } 
    }
  }
`;