import React, { Component, useState } from 'react';
import styles from '../../Common/Style/Styles.css';

class signInHeader extends Component<{title?: string}> {
  render() {
    const {title = "Welcome to Netlise"} = this.props;
    return (
      <div>
        <h1 className={styles.welcomeText}>{title}</h1>
      </div>
    );
  }
}

export default signInHeader;
