import React, { useEffect, useState } from "react";
import {
  OrderLineContainer,
  OrderLineTableContainer,
  OrderLineSearchContainer,
} from "./Styled";
import _, { get } from "lodash";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Row, Col } from "react-grid-system";
import { Actions } from "../../../internal/app/Actions";
import OrderLineDetailView from "./OrderLineDetailView";
import IconSearchBar from "../../../components/search/IconSearchBar";
import AppointmentDetailsBill from "../../orders/views/AppointmentDetailsBillView";
import { searchProductsServices } from "../../../internal/manager/CategoryManager";
import ServiceWarningModal from "../../../components/model/views/ServiceWarningModal";
import CreateCategoryModel from "../../../components/model/views/CreateCategoryModel";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";
import CreateQuickItemModel from "../../../components/model/views/CreateQuickItemModel";

const OrderLineView = (props) => {
  const {
    productList,
    orders,
    categories,
    setPurchaseProducts,
    setPurchaseService,
    deletePurchaseProduct,
    deletePurchaseService,
    purchaseProducts,
    purchaseServices,
  } = props;
  const [searchText, setSearchText] = useState("");
  const [purchaseItem, setPurchaseItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchedItems, setSearchedItems] = useState([]);
  const [createModelType, setCreateModelType] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isQuickItemModel, setIsQuickItemModel] = useState(false);
  const [isProductCreateModel, setIsProductCreateModel] = useState(false);
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    const items: any = purchaseServices.concat(purchaseProducts);
    setPurchaseItem([...items]);
  }, [purchaseProducts, purchaseServices]);

  useEffect(() => {
    const searchItems: any = searchProductsServices(
      "",
      categories?.categories || [],
      productList
    );
    setSearchedItems(searchItems);
  }, [categories, productList]);

  const searchOrderItems = (text) => {
    setSearchText(text);
    if (text && text.length > 0) {
      const searchItems: any = searchProductsServices(
        text,
        categories?.categories || [],
        productList
      );
      setSearchedItems(searchItems);
    }
  };

  const onClickedItem = (item) => {
    setSearchText("");
    if (!_.isEmpty(item.serviceTitle)) {
      const param = {
        tax: 0,
        quantity: 1,
        symbol1: "",
        symbol2: "",
        id: item.id,
        type: "SERVICE",
        enteredNumber1: "",
        enteredNumber2: "",
        price: item.price,
        duration: item.duration,
        servicePrice: item.price,
        serviceTitle: item.serviceTitle,
      };
      setPurchaseService([param]);
    } else {
      const param = {
        tax: 0,
        quantity: 1,
        symbol1: "",
        symbol2: "",
        id: item.pid,
        type: "PRODUCT",
        image: item.image,
        enteredNumber1: "",
        enteredNumber2: "",
        sendToBar: !!item.sendToBar,
        productName: item.productName,
        price: parseFloat(item.itemPrice),
        warranty: get(item, "warranty", {}),
        sendToKitchen: !!item.sendToKitchen,
        sendPrepTicket: item.sendPrepTicket,
        productPrice: parseFloat(item.itemPrice),
        productSalePrice: parseFloat(item.itemPrice),
      };
      setPurchaseProducts([param]);
    }
  };

  const onClickAdd = (item) => {
    if (item.type === "SERVICE") {
      const quantity = item.quantity + 1;
      const total = quantity * item.servicePrice;
      const param = {
        id: item.id,
        price: total,
        symbol1: "X",
        tax: item.tax,
        type: item.type,
        image: item.image,
        quantity: quantity,
        symbol2: item.symbol2,
        servicePrice: item.servicePrice,
        serviceTitle: item.serviceTitle,
      };
      setPurchaseService([param]);
    } else {
      const quantity = item.quantity + 1;
      const total = quantity * item.productSalePrice;
      const param = {
        id: item.id,
        symbol1: "X",
        price: total,
        tax: item.tax,
        type: item.type,
        image: item.image,
        quantity: quantity,
        symbol2: item.symbol2,
        sendToBar: !!item.sendToBar,
        productName: item.productName,
        productPrice: item.productPrice,
        warranty: get(item, "warranty", {}),
        sendToKitchen: !!item.sendToKitchen,
        sendPrepTicket: item.sendPrepTicket,
        productSalePrice: item.productSalePrice,
      };
      setPurchaseProducts([param]);
    }
  };

  const onClickSub = (item) => {
    setSelectedItem(item);
    if (item.type === "SERVICE") {
      if (item.quantity === 1) {
        toggleRemoveModal();
      } else {
        const quantity = item.quantity - 1;
        const total = quantity * item.servicePrice;
        const param = {
          id: item.id,
          price: total,
          symbol1: "X",
          tax: item.tax,
          type: item.type,
          image: item.image,
          quantity: quantity,
          symbol2: item.symbol2,
          serviceTitle: item.serviceTitle,
          servicePrice: item.servicePrice,
        };
        setPurchaseService([param]);
      }
    } else {
      if (item.quantity === 1) {
        toggleRemoveModal();
      } else {
        const quantity = item.quantity - 1;
        const total = quantity * item.productSalePrice;
        const param = {
          id: item.id,
          symbol1: "X",
          price: total,
          tax: item.tax,
          type: item.type,
          image: item.image,
          quantity: quantity,
          symbol2: item.symbol2,
          sendToBar: !!item.sendToBar,
          productName: item.productName,
          productPrice: item.productPrice,
          warranty: get(item, "warranty", {}),
          sendToKitchen: !!item.sendToKitchen,
          sendPrepTicket: item.sendPrepTicket,
          productSalePrice: item.productSalePrice,
        };
        setPurchaseProducts([param]);
      }
    }
  };

  const toggleRemoveModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const removeItem = () => {
    if (selectedItem.type === "SERVICE") {
      deletePurchaseService(selectedItem);
    } else {
      deletePurchaseProduct({ pid: selectedItem.id });
    }
    toggleRemoveModal();
  };

  const deleteItem = (item) => {
    setSelectedItem(item);
    toggleRemoveModal();
  };

  const toggleAddNewItem = () => {
    setIsQuickItemModel(!isQuickItemModel);
  };

  const toggleCreateBrandCategory = (type = "") => {
    toggleAddNewItem();
    setCreateModelType(type);
    setIsProductCreateModel(!isProductCreateModel);
  };

  return (
    <OrderLineContainer>
      <TopNavigationBar viewName={f({ id: "ORDER.TITLE" })} path="/menu" />
      <div>
        <div>
          <Row style={{ height: "100%", margin: 0 }}>
            <Col style={{ height: "100%", padding: 0 }} xs={8}>
              <OrderLineTableContainer>
                {!_.isEmpty(purchaseItem) ? (
                  <OrderLineDetailView
                    items={purchaseItem}
                    removeItem={(item) => deleteItem(item)}
                    onClickAdd={(item) => onClickAdd(item)}
                    onClickSub={(item) => onClickSub(item)}
                  />
                ) : null}
                <IconSearchBar
                  searchText={searchText}
                  title={f({ id: "ORDER.SEARCH_PLACEHOLDER" })}
                  deleteSearchText={() => setSearchText("")}
                  onSearchText={(text) => searchOrderItems(text)}
                  onClickAddNewItem={() => toggleAddNewItem()}
                />
              </OrderLineTableContainer>
              <OrderLineSearchContainer>
                <div>
                  {searchedItems.map((item: any, index: number) => {
                    return (
                      <div key={item.id || item.pid}>
                        <p onClick={() => onClickedItem(item)}>
                          {item.productName || item.serviceTitle}
                        </p>
                        {index !== orders.length - 1 ? <div /> : null}
                      </div>
                    );
                  })}
                </div>
              </OrderLineSearchContainer>
            </Col>
            <Col style={{ height: "100%" }} xs={4}>
              <AppointmentDetailsBill />
            </Col>
          </Row>
        </div>
      </div>

      <ServiceWarningModal
        isOpen={openDeleteModal}
        headerText={"Are you sure ?"}
        bodText={`Are you sure you want to remove this selected \n ${_.toLower(
          selectedItem.type
        )} from bill ?`}
        cancelBtnText={"Delete"}
        onCancel={() => removeItem()}
        closeModal={() => toggleRemoveModal()}
      />

      <CreateQuickItemModel
        text={searchText}
        isOpen={isQuickItemModel}
        closeModal={() => setIsQuickItemModel(false)}
        onClickCreateProductCategory={() =>
          toggleCreateBrandCategory("CATEGORY")
        }
        onClickCreateProductBrand={() => toggleCreateBrandCategory("BRAND")}
        onClickCreateServiceCategory={() =>
          toggleCreateBrandCategory("SERVICE")
        }
      />

      <CreateCategoryModel
        type={createModelType}
        isOpen={isProductCreateModel}
        closeModal={() => toggleCreateBrandCategory()}
      />
    </OrderLineContainer>
  );
};
export default connect(
  (state) => ({
    orders: state.orders.get("orders"),
    shop: state.login.get("merchantShop"),
    categories: state.service.get("categories"),
    currentUser: state.login.get("currentUser"),
    productList: state.product.get("productList"),
    selectedView: state.common.get("selectedView"),
    purchaseOrders: state.orders.get("purchaseOrders"),
    purchaseServices: state.service.get("purchaseServices"),
    purchaseProducts: state.product.get("purchaseProducts"),
    selectedAppointment: state.orders.get("selectedAppointment"),
  }),
  {
    setPurchaseService: Actions.service.setPurchaseService,
    setPurchaseProducts: Actions.product.setPurchaseProducts,
    deletePurchaseService: Actions.service.deletePurchaseService,
    deletePurchaseProduct: Actions.product.deletePurchaseProduct,
    searchServiceAndProduct: Actions.orders.searchServiceAndProduct,
  }
)(OrderLineView);
