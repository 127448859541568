import React, { useEffect, useState } from "react";
import styles from "../Styles/Styles.css";
import UpIcon from "../../../assets/icons/upIcon.svg";
import DownIcon from "../../../assets/icons/downIcon.svg";
import IconPrinter from "../../../assets/svg/IconCloshcashPrinter.svg";

const AppointmentDropDownComponent = (props) => {
  const { isSelected, onSelected, title, showPrint, onClickPrint } = props;

  return (
    <div
      onClick={() => onSelected()}
      style={{
        border: isSelected ? "1px solid #49A4D4" : "1px solid #F5F5F5",
        backgroundColor: isSelected ? "#FFFFFF" : "#f5f5f5",
      }}
      className={styles.checkOutButton}
    >
      <div
        style={{ color: isSelected ? "#49A4D4" : "#434343" }}
        className={styles.checkoutText}
      >
        {title}
      </div>
      <div className={styles.buttonGroup}>
        {showPrint ? (
          <img className={styles.printCheckoutButton} onClick={() => onClickPrint()} src={IconPrinter} />
        ) : null}
        <div onClick={() => onSelected()}>
          <img src={isSelected ? UpIcon : DownIcon} />
        </div>
      </div>
    </div>
  );
};

export default AppointmentDropDownComponent;
