import React, {useEffect, useState} from 'react';
import styles from "../Styles/Styles.css";
import CustomerCard from "../card/CustomerCard";
import _ from 'lodash';

const CustomerComponent = (props) => {
  const {customerResult, onSelectCustomer, selectedCustomer, onClickEditCustomer} = props;
  return (
    <div className={styles.mainFilterLetterWrapper}>
      {/*<div className={styles.mainFilterLetter}>{_.upperCase(custometKey)}</div>*/}
      {
        Array.isArray(customerResult) && customerResult.map((customer) => {
          const isSelected = _.get(selectedCustomer, 'id', '') === customer.id;
          return (
            <CustomerCard
              key={customer.id}
              customer={customer}
              isSelected={isSelected}
              onSelectCustomer={(event) => onSelectCustomer(event, customer)}
              onClickEditCustomer={(event) => onClickEditCustomer(event)}
            />
          )
        })
      }
    </div>
  )
};

export default CustomerComponent;
