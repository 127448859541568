import React, { useEffect, useRef, useState } from "react";
import "../Styles.style.css";
import {
  MenuWrapper,
  MenuItem,
  MenuButton,
  TextIconWrapper,
  MenuCreateWrapper,
  CategoryCreateWrapper,
} from "./Styled";
import { nanoid } from "nanoid";
import Select from "react-select";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import Fade from "react-reveal/Fade";
import { HiOutlinePlus } from "react-icons/hi";
import { Actions } from "../../../internal/app/Actions";
import IconBin from "../../../../assets/svg/IconBin.svg";
import IconCheck from "../../../../assets/svg/IconCheck.svg";
import IconArrowUp from "../../../../assets/svg/IconArrowUp.svg";
import IconEdit from "../../../../assets/svg/IconProductEdit.svg";
import _, { isEmpty, omit, find, filter, findIndex, startCase, isArray } from "lodash";
import IconArrowDown from "../../../../assets/svg/IconArrowDown.svg";
import IconArrowUpUnSelect from "../../../../assets/svg/IconArrowUpUnSelect.svg";
import IconEditUnSelect from "../../../../assets/svg/IconProductEditUnSelect.svg";
import IconArrowDownUnSelect from "../../../../assets/svg/IconArrowDownUnSelect.svg";

type Product = {
  productId: string;
  position: string;
};
type Category = {
  categoryId: string;
  position: string;
  products: Array<Product>;
};
type Menu = {
  shopId: string;
  menuId: string;
  title: string;
  position: string;
  updatedTime: number;
  categories: Array<Category>;
};

const Catalogue = (props) => {
  const {
    shop,
    createMenu,
    updateMenu,
    deleteMenu,
    catalogue,
    fetchMenu,
    productList,
    productCategoryList,
    fetchShopProductCategories,
  } = props;

  const productRef = useRef();
  const categoryRef = useRef();
  const [modalType, setModalType] = useState<string>("");
  const [menus, setMenus] = useState<Array<any>>([]);
  const [selectedMenu, setSelectedMenu] = useState<any>({});
  const [menuTitle, setMenuTitle] = useState<string>("");
  const [editMenuTitle, setEditMenuTitle] = useState<string>("");
  const [categoryOptions, setCategoryOptions] = useState<Array<any>>([]);
  const [categories, setCategories] = useState<Array<any>>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [productOptions, setProductOptions] = useState<Array<any>>([]);
  const [products, setProducts] = useState<Array<any>>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>({});

  useEffect(() => {
    if (!isEmpty(shop.shopId)) {
      fetchMenu(shop.shopId);
      fetchShopProductCategories(shop.shopId);
    }
  }, [shop]);

  useEffect(() => {
    if (!isEmpty(catalogue)) {
      const newMenus = catalogue;
      newMenus.map((item: any) => (item["isEdit"] = false));
      setMenus(newMenus);
      if (!isEmpty(selectedMenu)) {
        const menu = find(
          catalogue,
          (data) => data.menuId === selectedMenu.menuId
        );
        setSelectedMenu(menu);
        const existingCategories = find(
          catalogue,
          (item) => item.menuId === selectedMenu.menuId
        )?.categories;
        const newCategories = existingCategories?.map((item: any) => {
          item["isEdit"] = false;
          item["categoryName"] = find(
            productCategoryList,
            (data) => data.categoryId === item.categoryId
          )?.categoryName;
          return item;
        });
        setCategories(newCategories);
        onSetCategory();

        if (!isEmpty(selectedCategory)) {
          const category = find(
            existingCategories,
            (data) => data.categoryId === selectedCategory.categoryId
          );
          setSelectedCategory(category);
          const existingProducts = category?.products;
          const newProducts = existingProducts?.map((item: any) => {
            item["isEdit"] = false;
            item["productName"] = find(
              productList,
              (data) => data.pid === item.productId
            ).productName;
            return item;
          });
          setProducts(newProducts);
          onSetProduct();
        }
      } else {
        setSelectedMenu(catalogue[0]);
      }
    } else {
      setMenus([]);
    }
  }, [catalogue]);

  const onSetCategory = () => {
    const newCategories = selectedMenu?.categories;

    if (newCategories) {
      let newArr: any = [];
      productCategoryList.map((arr2Elem) => {
        if (
          newCategories.findIndex(
            (arr1Elem) => arr1Elem.categoryId === arr2Elem.categoryId
          ) === -1
        ) {
          newArr.push(arr2Elem);
        }
      });
      const options = newArr.map((item) => {
        return {
          value: item.categoryId,
          label: item.categoryName,
          isSelected: false,
        };
      });
      setCategoryOptions(options);
    } else {
      const options = productCategoryList.map((item) => {
        return {
          value: item.categoryId,
          label: item.categoryName,
          isSelected: false,
        };
      });
      setCategoryOptions(options);
    }
  };

  const onSetProduct = () => {
    const newProducts = selectedCategory?.products;
    if (newProducts) {
      const newArr: any = productList.filter(
        (prod) =>
          newProducts.findIndex((newProd) => newProd.productId === prod.pid) <
            0 && prod.categoryId === selectedCategory.categoryId
      );
      const options = newArr.map((item) => {
        return {
          value: item.pid,
          label: startCase(item.productName),
          isSelected: false,
        };
      });
      setProductOptions(options);
    } else {
      const options = productList
        .filter((prod) => prod?.categoryId === selectedCategory.categoryId)
        .map((item) => {
          return {
            value: item.pid,
            label: startCase(item.productName),
            isSelected: false,
          };
        });
      setProductOptions(options);
    }
  };

  useEffect(() => {
    const newCategories = selectedMenu?.categories;
    if (newCategories) {
      newCategories.map((item: any) => {
        item["isEdit"] = false;
        item["categoryName"] = find(
          productCategoryList,
          (data) => data.categoryId === item.categoryId
        )?.categoryName;
      });
      setCategories(newCategories);
    } else {
      setCategories([]);
    }
    onSetCategory();
  }, [selectedMenu]);

  useEffect(() => {
    if (!isEmpty(productCategoryList)) {
      onSetCategory();
    }
  }, [productCategoryList]);

  useEffect(() => {
    const newProducts = selectedCategory?.products;
    if (newProducts) {
      newProducts.map((item: any) => {
        item["isEdit"] = false;
        item["productName"] = find(
          productList,
          (data) => data.pid === item.productId
        )?.productName;
      });
      setProducts(newProducts);
    } else {
      setProducts([]);
    }
    onSetProduct();
  }, [selectedCategory]);

  useEffect(() => {
    if (!isEmpty(productList)) {
      onSetProduct();
    }
  }, [productList]);

  const toggleModal = (type) => {
    setModalType(modalType === type ? "" : type);
  };

  const onSelectedMenu = (item) => {
    if (!isEmpty(item?.menuId)) {
      setSelectedCategory({});
      const isSelect = item.menuId === selectedMenu?.menuId;
      setSelectedMenu(isSelect ? {} : item);
    }
  };

  const onCreateMenu = () => {
    const lastElement = _.last(catalogue);
    if (!isEmpty(menuTitle)) {
      const params = {
        menuId: nanoid(),
        title: menuTitle,
        shopId: shop.shopId,
        updatedTime: Date.now(),
        position: !isEmpty(lastElement)
          ? `${parseInt(lastElement.position) + 1}`
          : 1,
      };
      setMenuTitle("");
      setModalType("");
      createMenu(params);
    }
  };

  const onUpdateMenu = (item: any, index: number) => {
    if (!isEmpty(editMenuTitle)) {
      const params = {
        menuId: item.menuId,
        shopId: item.shopId,
        title: editMenuTitle,
        updatedTime: Date.now(),
        position: item.position,
      };
      menus[index].isEdit = false;

      const categories = item.categories?.map((item) => {
        item?.products?.map((item) => omit(item, ["isEdit", "productName"]));
        return omit(item, ["isEdit", "categoryName"]);
      }) || [];
      params["categories"] = categories;
      setEditMenuTitle("");
      setMenus([...menus]);
      updateMenu(params);
    }
  };

  const onDeleteMenu = (item: any, index: number) => {
    const params = {
      menuId: item.menuId,
      shopId: item.shopId,
    };
    menus[index].isEdit = false;
    setEditMenuTitle("");
    setMenus([...menus]);
    deleteMenu(params);
  };

  const onSelectCategory = (data) => {
    if (data) {
      const params = omit(selectedMenu, "isEdit");
      params.updatedTime = Date.now();

      const lastElement = _.last(categories);
      const newCategory = {
        categoryId: data.value,
        position: !isEmpty(categories)
          ? `${parseInt(lastElement.position) + 1}`
          : 1,
      };
      if (!isEmpty(categories)) {
        const existingCategories = [...categories, newCategory].map((item) =>
          omit(item, ["isEdit", "categoryName"])
        );
        params["categories"] = existingCategories;
        params.categories?.map((item) => {
          if (isArray(item?.products)) {
            item.products = item.products.map((item) => omit(item, ["isEdit", "productName"]));
          }
          return item;
        });
      } else {
        params["categories"] = [omit(newCategory, ["isEdit", "productName"])];
      }
      setModalType("");
      updateMenu(params);
      setTimeout(() => {
        categoryRef?.current?.select.clearValue();
      }, 800);
    }
  };

  const onSelectProduct = (data) => {
    if (data) {
      const params = omit(selectedMenu, "isEdit");
      params.updatedTime = Date.now();
      const lastElement = _.last(products);

      if (!isEmpty(categories)) {
        const existingCategories = categories.map((item) =>
          omit(item, ["isEdit", "categoryName"])
        );
        params["categories"] = [...existingCategories];
        const newProduct = {
          productId: data.value,
          position: !isEmpty(products)
            ? `${parseInt(lastElement.position) + 1}`
            : 1,
        };
        const index = params.categories?.findIndex(
          (item) => item?.categoryId === selectedCategory.categoryId
        );
        if (!isEmpty(products)) {
          const updatingProducts = [...products, newProduct];
          params.categories[index]["products"] = updatingProducts.map((item) =>
            omit(item, ["isEdit", "productName"])
          );
        } else {
          params.categories[index]["products"] = [newProduct];
        }
      }
      setModalType("");
      updateMenu(params);
      setTimeout(() => {
        productRef?.current?.select.clearValue();
      }, 800);
    }
  };

  const style = {
    control: (base, state) => ({
      ...base,
      height: "34px",
      width: "100%",
      paddingRight: "20px",
      border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5",
      "&:hover": {
        border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5",
      },
      borderRadius: "4px",
      color: "#00000085",
      fontSize: "14px",
      fontFamily: "Montserrat",
      boxShadow: "0px 0px 10px 5px #c4d3f020",
      paddingLeft: "5px",
    }),
  };

  const onClickUp = (item, index) => {
    if (index > 0) {
      const previousItem = menus[index - 1];
      const newItem = item;
      newItem.position = previousItem.position;
      previousItem.position = item.position;
      menus[index - 1] = newItem;
      menus[index] = previousItem;
      setMenus([...menus]);
    }
  };

  const onClickDown = (item, index) => {
    if (index < menus.length - 1) {
      const afterItem = menus[index + 1];
      const newItem = item;
      newItem.position = afterItem.position;
      afterItem.position = item.position;
      menus[index + 1] = newItem;
      menus[index] = afterItem;
      setMenus([...menus]);
    }
  };

  const onClickEdit = (item, index) => {
    const newMenus = menus.map((item: any, menuIndex: number) => {
      item.isEdit = menuIndex === index;
      return item;
    });
    const findMenu = find(menus, (data) => data.menuId === item.menuId);
    setMenus([...newMenus]);
    setSelectedMenu(findMenu);
    setEditMenuTitle(item.title);
  };

  const onHideMenu = () => {
    setMenuTitle("");
    setModalType("");
  };

  const onClickUpCategory = (item, index) => {
    if (index > 0) {
      const previousItem = categories[index - 1];
      const newItem = item;
      newItem.position = previousItem.position;
      previousItem.position = item.position;
      categories[index - 1] = newItem;
      categories[index] = previousItem;
      setCategories([...categories]);
      onUpdatePosition(false);
    }
  };

  const onClickDownCategory = (item, index) => {
    if (index < categories.length - 1) {
      const afterItem = categories[index + 1];
      const newItem = item;
      newItem.position = afterItem.position;
      afterItem.position = item.position;
      categories[index + 1] = newItem;
      categories[index] = afterItem;
      setCategories([...categories]);
      onUpdatePosition(false);
    }
  };

  const onSelectedCategory = (item) => {
    if (!isEmpty(item?.categoryId)) {
      const isSelect = item.categoryId === selectedCategory?.categoryId;
      setSelectedCategory(isSelect ? {} : item);
    }
  };

  const onSelectedProduct = (item) => {
    if (!isEmpty(item?.productId)) {
      const isSelect = item.productId === selectedCategory?.productId;
      setSelectedProduct(isSelect ? {} : item);
    }
  };

  const onClickUpProduct = (item, index) => {
    if (index > 0) {
      const previousItem = products[index - 1];
      const newItem = item;
      newItem.position = previousItem.position;
      previousItem.position = item.position;
      products[index - 1] = newItem;
      products[index] = previousItem;
      setCategories([...products]);
      onUpdatePosition(true);
    }
  };

  const onClickDownProduct = (item, index) => {
    if (index < products.length - 1) {
      const afterItem = products[index + 1];
      const newItem = item;
      newItem.position = afterItem.position;
      afterItem.position = item.position;
      products[index + 1] = newItem;
      products[index] = afterItem;
      setCategories([...products]);
      onUpdatePosition(true);
    }
  };

  const onUpdatePosition = (type: boolean) => {
    const params = omit(selectedMenu, "isEdit");
    params.updatedTime = Date.now();

    if (!isEmpty(categories)) {
      const existingCategories = categories.map((item) =>
        omit(item, ["isEdit", "categoryName"])
      );
      params["categories"] = existingCategories;
      const index = params.categories.findIndex(
        (item) => item.categoryId === selectedCategory.categoryId
      );
      if (type) {
        const existingProducts = products.map((item) =>
          omit(item, ["isEdit", "productName"])
        );
        params.categories[index]["products"] = existingProducts;
      }
    }
    setMenuTitle("");
    updateMenu(params);
  };

  const onCategoryEdit = (item, index) => {
    const newCategories = categories.map((item: any, cIndex: number) => {
      item.isEdit = cIndex === index;
      return item;
    });
    setCategories([...newCategories]);
  };

  const onDeleteCategory = (item: any, index: number) => {
    const params = omit(selectedMenu, "isEdit");
    params.updatedTime = Date.now();

    if (!isEmpty(categories)) {
      const filterCategories = filter(
        categories,
        (data) => data.categoryId !== item.categoryId
      );
      const existingCategories = filterCategories.map((item) => {
        let data: any = [];
        data = omit(item, ["isEdit", "categoryName"]);
        let product = item?.products?.map((item) =>
          omit(item, ["isEdit", "productName"])
        ) || [];
        data["products"] = product;
        return data;
      });
      params["categories"] = existingCategories;
    }
    setMenuTitle("");
    updateMenu(params);
  };

  const onHideEditCategory = () => {
    const index = findIndex(
      categories,
      (item) => item.categoryId === selectedCategory?.categoryId
    );
    selectedCategory["isEdit"] = false;
    categories[index] = selectedCategory;
    setCategories([...categories]);
  };

  const onProductEdit = (item, index) => {
    const newProducts = products.map((item: any, cIndex: number) => {
      item.isEdit = cIndex === index;
      return item;
    });
    setProducts([...newProducts]);
  };

  const onDeleteProduct = (item: any, index: number) => {
    const params = omit(selectedMenu, "isEdit");
    params.updatedTime = Date.now();

    const filterProducts = filter(
      selectedCategory.products,
      (data) => data.productId !== item.productId
    );
    selectedCategory["products"] = filterProducts;
    const categoryIndex = findIndex(
      categories,
      (item) => item.categoryId === selectedCategory.categoryId
    );
    categories[categoryIndex] = selectedCategory;

    const existingCategories = categories.map((item) => {
      let data: any = [];
      data = omit(item, ["isEdit", "categoryName"]);
      let product = item?.products?.map((item) =>
        omit(item, ["isEdit", "productName"])
      ) || [];
      data["products"] = product;
      return data;
    });
    params["categories"] = existingCategories;
    setMenuTitle("");
    updateMenu(params);
  };

  const onHideEditProduct = () => {
    const index = findIndex(
      products,
      (item) => item.productId === selectedProduct?.productId
    );
    selectedProduct["isEdit"] = false;
    products[index] = selectedProduct;
    setProducts([...products]);
  };
  const { formatMessage: f } = useIntl();
  return (
    <MenuWrapper>
      <div>
        {menus?.map((item, index) => {
          const isSelected = selectedMenu?.menuId === item?.menuId;
          return (
            <>
              {!item.isEdit ? (
                <MenuItem
                  onClick={() => onSelectedMenu(item)}
                  selected={isSelected}
                >
                  <div>{item.title}</div>
                  <div>
                    <img
                      onClick={() => onClickEdit(item, index)}
                      src={isSelected ? IconEdit : IconEditUnSelect}
                      style={{ height: "18px", marginRight: "6px" }}
                    />
                    <img
                      onClick={() => onClickUp(item, index)}
                      src={isSelected ? IconArrowUp : IconArrowUpUnSelect}
                      style={{ height: "31px" }}
                    />
                    <img
                      onClick={() => onClickDown(item, index)}
                      src={isSelected ? IconArrowDown : IconArrowDownUnSelect}
                      style={{ height: "31px" }}
                    />
                  </div>
                </MenuItem>
              ) : (
                <MenuCreateWrapper>
                  <input
                    value={editMenuTitle}
                    placeholder={f({ id: "MENU.MENU_TITLE" })}
                    onChange={(event) => setEditMenuTitle(event.target.value)}
                  />
                  <div>
                    <img
                      onClick={() => onUpdateMenu(item, index)}
                      src={IconCheck}
                    />
                    <img
                      onClick={() => onDeleteMenu(item, index)}
                      src={IconBin}
                    />
                  </div>
                </MenuCreateWrapper>
              )}
            </>
          );
        })}
        {/* <Fade top duration={100} distance={"10px"} when={modalType === "MENU"}> */}
        {modalType === "MENU" ? (
          <MenuCreateWrapper>
            <input
              value={menuTitle}
              placeholder={f({ id: "MENU.MENU_TITLE" })}
              onChange={(event) => setMenuTitle(event.target.value)}
            />
            <div>
              <img onClick={() => onCreateMenu()} src={IconCheck} />
              <img onClick={() => onHideMenu()} src={IconBin} />
            </div>
          </MenuCreateWrapper>
        ) : null}

        <MenuButton onClick={() => toggleModal("MENU")}>
          <div>{f({ id: "MENU.ADD_MENU" })}</div>
          <HiOutlinePlus />
        </MenuButton>
      </div>

      <div>
        {!isEmpty(selectedMenu) ? (
          <>
            {categories?.map((category: any, index: number) => {
              const isSelected =
                selectedCategory?.categoryId === category?.categoryId;
              return (
                <MenuItem
                  selected={isSelected}
                  onClick={() => onSelectedCategory(category)}
                >
                  <div>{category.categoryName}</div>
                  {!category.isEdit ? (
                    <div>
                      <img
                        onClick={() => onCategoryEdit(category, index)}
                        src={isSelected ? IconEdit : IconEditUnSelect}
                        style={{ height: "18px", marginRight: "6px" }}
                      />
                      <img
                        onClick={() => onClickUpCategory(category, index)}
                        src={isSelected ? IconArrowUp : IconArrowUpUnSelect}
                        style={{ height: "31px" }}
                      />
                      <img
                        onClick={() => onClickDownCategory(category, index)}
                        src={isSelected ? IconArrowDown : IconArrowDownUnSelect}
                        style={{ height: "31px" }}
                      />
                    </div>
                  ) : (
                    <TextIconWrapper>
                      <div>
                        <img
                          onClick={() => onHideEditCategory()}
                          src={IconCheck}
                        />
                        <img
                          src={IconBin}
                          onClick={() => onDeleteCategory(category, index)}
                        />
                      </div>
                    </TextIconWrapper>
                  )}
                </MenuItem>
              );
            })}
            {/* <Fade
              top
              duration={100}
              distance={"10px"}
              when={modalType === "CATEGORY"}
            > */}
            {modalType === "CATEGORY" ? (
              <CategoryCreateWrapper>
                <Select
                  styles={style}
                  ref={categoryRef}
                  options={categoryOptions}
                  placeholder={f({ id: "MENU.SELECT_CATEGORY" })}
                  onChange={(data) => onSelectCategory(data)}
                />
              </CategoryCreateWrapper>
            ) : null}

            <MenuButton onClick={() => toggleModal("CATEGORY")}>
              <div>{f({ id: "MENU.ADD_CATEGORY" })}</div>
              <HiOutlinePlus />
            </MenuButton>
          </>
        ) : null}
      </div>

      <div>
        {!isEmpty(selectedCategory) ? (
          <>
            {products?.map((product: any, index: number) => {
              const isSelected =
                selectedProduct?.productId === product?.productId;
              return (
                <MenuItem
                  selected={isSelected}
                  onClick={() => onSelectedProduct(product)}
                >
                  <div>{startCase(product.productName)}</div>
                  {!product.isEdit ? (
                    <div>
                      <img
                        onClick={() => onProductEdit(product, index)}
                        src={isSelected ? IconEdit : IconEditUnSelect}
                        style={{ height: "18px", marginRight: "6px" }}
                      />
                      <img
                        onClick={() => onClickUpProduct(product, index)}
                        src={isSelected ? IconArrowUp : IconArrowUpUnSelect}
                        style={{ height: "31px" }}
                      />
                      <img
                        onClick={() => onClickDownProduct(product, index)}
                        src={isSelected ? IconArrowDown : IconArrowDownUnSelect}
                        style={{ height: "31px" }}
                      />
                    </div>
                  ) : (
                    <TextIconWrapper>
                      <div>
                        <img
                          onClick={() => onHideEditProduct()}
                          src={IconCheck}
                        />
                        <img
                          src={IconBin}
                          onClick={() => onDeleteProduct(product, index)}
                        />
                      </div>
                    </TextIconWrapper>
                  )}
                </MenuItem>
              );
            })}
            {/* <Fade
              top
              duration={100}
              distance={"10px"}
              when={modalType === "PRODUCT"}
            > */}
            {modalType === "PRODUCT" ? (
              <CategoryCreateWrapper>
                <Select
                  styles={style}
                  ref={productRef}
                  options={productOptions}
                  onChange={(data) => onSelectProduct(data)}
                  placeholder={f({ id: "MENU.SELECT_PRODUCT" })}
                />
              </CategoryCreateWrapper>
            ) : null}

            <MenuButton onClick={() => toggleModal("PRODUCT")}>
              <div>{f({ id: "MENU.ADD_PRODUCT" })}</div>
              <HiOutlinePlus />
            </MenuButton>
          </>
        ) : null}
      </div>
    </MenuWrapper>
  );
};
export default connect(
  (state) => ({
    shop: state.login.get("merchantShop"),
    catalogue: state.product.get("catalogue"),
    productList: state.product.get("productList"),
    loadingAction: state.common.get("loadingAction"),
    productCategoryList: state.product.get("productCategoryList"),
  }),
  {
    fetchMenu: Actions.product.fetchMenu,
    createMenu: Actions.product.createMenu,
    updateMenu: Actions.product.updateMenu,
    deleteMenu: Actions.product.deleteMenu,
    fetchShopProductCategories: Actions.product.fetchShopProductCategories,
  }
)(Catalogue);
