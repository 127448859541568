import React, { Component, useRef, useEffect, useState } from "react";
import styles from "../../Styles/ModelStyles.css";
import { Container, Row, Col } from "react-grid-system";
import ReactModal from "react-modal";
import CANCEL from "../../../../assets/icons/cancel.svg";
import { connect } from "react-redux";
import "../../Styles/ModelStyles.css";
import SaveButton from "../../Buttons/Views/SaveButton";
import CancelButton from "../../Buttons/Views/CancelButton";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 265px)",
    border: "1px solid rgba(112, 112, 112, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: "15px",
    height: "15px",
    boxShadow: "0 1px 6px rgba(0, 0, 0, 0.08)",
    border: "1px solid #EBEAEA",
    backgroundColor: "#FFFFFF",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto #FFFFFF",
      outlineOffset: 200,
    },
    "input:hover ~ &": {
      backgroundColor: "#FFFFFF",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      width: "15px",
      height: "15px",
      borderRadius: 3,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

const WifiPasswordModal = (props) => {
  const { isOpen, closeModal, onClickJoin } = props;

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isRemember, setIsRemember] = useState(false);

  const classes = useStyles();

  const onChangePassword = (event) => {
    setIsShowPassword(event.target.checked);
  };

  const onChangeRemember = (event) => {
    setIsRemember(event.target.checked);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.wifiPasswordModal}
    >
      <div>
        <div
          className={styles.wifiPasswordHeaderWrapper}
          onClick={() => closeModal()}
        >
          <div className={styles.wifiPasswordHeaderText}>
            {"Enter Wifi password"}
          </div>
          <img alt="icon" src={CANCEL} />
        </div>

        <div className={styles.wifiPasswordHeaderDescWrapper}>
          <div
            className={styles.wifiPasswordHeaderDescText}
          >{`The Wifi network ${"Wifi zone name"} requires a password.`}</div>
          <Row>
            <Col md={12}>
              <div className={styles.wifiPasswordInputWrapper}>
                <div className={styles.wifiPasswordLabelText}>
                  {"Wifi Password : "}
                </div>
                <input
                  type={isShowPassword ? "text" : "password"}
                  className={styles.wifiPasswordInput}
                />
              </div>

              <div className={styles.wifiPasswordCheckboxWrapper}>
                <div className={styles.wifiPasswordCheckboxWrapperInfo}>
                  <Checkbox
                    checked={isShowPassword}
                    className={classes.root}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    icon={<span className={classes.icon} />}
                    onChange={(event) => onChangePassword(event)}
                  />
                  <div className={styles.wifiPasswordCheckboxLabel}>
                    {"Show password"}
                  </div>
                </div>
                <div className={styles.wifiPasswordCheckboxWrapperInfo}>
                  <Checkbox
                    checked={isRemember}
                    className={classes.root}
                    disableRipple
                    color="default"
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    icon={<span className={classes.icon} />}
                    inputProps={{ "aria-label": "decorative checkbox" }}
                    onChange={(event) => onChangeRemember(event)}
                  />
                  <div className={styles.wifiPasswordCheckboxLabel}>
                    {"Remember this network"}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div className={styles.wifiPasswordButtonsWrapper}>
                <SaveButton text={"Join"} onClick={() => onClickJoin()} />
                <CancelButton text={"Cancel"} onClick={() => closeModal()} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </ReactModal>
  );
};
export default connect((state) => ({}), {})(WifiPasswordModal);
