import { get, isArray, isString, findIndex } from "lodash";
import GraphqlService from "./Graphql.service";
const MYSTORAGE_KEY_PREFIX = "@NetliseApp:";
class AppStorage {
  dataMemory: any = {};

  constructor() {
    this.sync();
  }

  storeSet = async (key, value: string) => {
    if (window.api) {
      this.dataMemory[key] = isString(value) ? JSON.parse(value) : value; // just for validation
      return new Promise((res) => {
        window.api.set(
          { key: `${MYSTORAGE_KEY_PREFIX}${key}`, value },
          (data) => {
            res(data);
          }
        );
      });
    } else {
      throw new Error("Not supported");
    }
  };

  storeGet = (key: string) => {
    if (window.api) {
      return get(this.dataMemory, key, undefined);
    } else {
      throw new Error("Not supported");
    }
  };

  storeGetResource = async (key: string) => {
    const data = await GraphqlService.fetchOfflineOrders(key);
    this.dataMemory[key] = data;
    return this.dataMemory[key];
  };

  storeSetResource = async (value: any, key: string) => {
    this.dataMemory[key] = value;
    return this.dataMemory[key];
  };

  storePush = async (value: any, key: string) => {
    const stored = get(this.dataMemory, key, []);
    if (stored && isArray(stored)) {
      const index = findIndex(
        stored,
        (order) => order.orderId === value.orderId
      );
      if (index > -1) {
        stored[index] = value;
      } else {
        stored.push(value);
      }
    } else {
      stored.push(value);
    }
    this.dataMemory[key] = stored;
    return value;
  };

  storeRemove = async (value: any, key: string) => {
    const stored = get(this.dataMemory, key, []);
    if (stored && isArray(stored)) {
      const index = findIndex(
        stored,
        (order) => order.orderId === value.orderId
      );
      if (index > -1) {
        stored.splice(index, 1);
      }
    }
    this.dataMemory[key] = stored;
    return value;
  };

  // storeGetResource = async (key: string) => {
  //   if (window.api) {
  //     const data = await new Promise(async (res) => {
  //       window.api.get(`${MYSTORAGE_KEY_PREFIX}${key}`, (response) =>
  //         res(response)
  //       );
  //     });
  //     console.log(">>>>> incoming data", data);
  //     this.dataMemory[key] = isString(data) ? JSON.parse(data) : [];

  //     console.log(data);
  //     return this.dataMemory[key];
  //   } else {
  //     throw new Error("Not supported");
  //   }
  // };

  // storePush = async (key: string, value: any, id?: string) => {
  //   if (window.api) {
  //     const stored = get(this.dataMemory, key, []);
  //     stored.push(value);
  //     const data = await this.storeSet(key, JSON.stringify(stored));
  //     console.log(">>>>> incoming data", data);
  //     this.dataMemory[key] = stored;
  //     if (id && isArray(data)) {
  //       return data.find((item) => item[id] === value[id]);
  //     }
  //     console.log(data);
  //     return value;
  //   } else {
  //     throw new Error("Not supported");
  //   }
  // };

  // storeRemove = async (key: string) => {
  //   if (window.api) {
  //     return get(this.dataMemory, key, undefined);
  //   } else {
  //     throw new Error("Not supported");
  //   }
  // };

  storeRemoveItem = async (key: string, id: string, compare: string) => {
    if (window.api) {
      const data = await this.storeGetResource(key);
      if (data && isArray(data)) {
        const index = data.findIndex((item) => item[id] === compare);
        let item = undefined;
        if (index > -1) {
          data.splice(index, 1);
          item = data[index];
        }
        await this.storeSet(key, JSON.stringify(data));
        return item;
      }
    } else {
      throw new Error("Not supported");
    }
  };

  storeShift = async (key: string) => {
    if (window.api) {
      const data = get(this.dataMemory, key, undefined);
      if (data && isArray(data)) {
        const item = data.shift();
        await this.storeSet(key, JSON.stringify(data));
        return item;
      }
    } else {
      throw new Error("Not supported");
    }
  };

  sync = async () => {
    if (window.api) {
      return await new Promise((res, rej) => {
        try {
          window.api.getStore((store) => {
            Object.keys(store).forEach((key) => {
              if (key.startsWith(MYSTORAGE_KEY_PREFIX)) {
                const value = store[key];
                const memoryKey = key.replace(MYSTORAGE_KEY_PREFIX, "");
                this.dataMemory[memoryKey] = isString(value)
                  ? JSON.parse(value)
                  : undefined;
              }
            });
            res(store);
          });
        } catch (e) {
          rej(e);
        }
      });
    }
  };
}
const AppStorageInstance = new AppStorage();
export default AppStorageInstance;
