import React, { useState } from "react";
import { Rnd } from "react-rnd";
import { connect } from "react-redux";
import { SquareWrapper } from "./Styled";
import { get, isEmpty, slice } from "lodash";
import { Actions } from "../../../internal/app/Actions";
import EditIcon from "../../../../assets/svg/editTable.png";
import BaseColors from "../../../internal/helpers/BaseColors";
import EditIconDark from "../../../../assets/svg/TableEditIconDark.svg";
import TableOrderModal from "../../../components/model/views/TableOrderModal";
import { getTableColor } from "../../../internal/manager/EmployeeManager";

export const Square = connect(
  (state: any) => ({
    mode: state.table.get("mode"),
    tables: state.table.get("tables"),
    tableAreas: state.table.get("tableAreas"),
    currentUser: state.login.get("currentUser"),
    selectedArea: state.table.get("selectedArea"),
    employees: state.employee.get("employees"),
  }),
  {
    editTables: Actions.table.editTables,
    createOrder: Actions.table.editTables,
    createTableOrder: Actions.table.createTableOrder,
    selectedAppointment: Actions.orders.selectedAppointment,
    clearCurrentAppointment: Actions.payment.clearCurrentAppointment,
  }
)(
  ({
    mode,
    table,
    onEdit,
    employees,
    editTables,
    tableAreas,
    currentUser,
    selectedArea,
    createTableOrder,
    selectedAppointment,
    clearCurrentAppointment,
  }: any) => {
    const [openOrderList, setOpenOrderList] = useState<boolean>(false);

    const updateTable = (ref, drag) => {
      const index = tableAreas.tableAreas.findIndex(
        (tb) => tb.areaId === selectedArea
      );

      if (index > -1) {
        const tables = tableAreas.tableAreas[index].tables;
        const tbIndex = tables.findIndex((t) => t.tbId === table.tbId);

        if (tbIndex > -1) {
          if (ref) {
            table.w = parseFloat(ref.style.width);
            table.h = parseFloat(ref.style.height);
          }
          table.x = parseFloat(drag.x);
          table.y = parseFloat(drag.y);
          tables[tbIndex] = table;
        }
        tableAreas.tableAreas[index].tables = tables;
        editTables({ ...tableAreas });
      }
    };

    const gridMode = get(currentUser, "viewMode", "GRID") === "GRID";

    const onSelectOrder = (data) => {
      clearCurrentAppointment(true, {
        navigate: gridMode ? "/product" : "/order-list",
      });
      setTimeout(() => {
        selectedAppointment(data);
      }, 200);
    };

    const toggleMultipleModal = () => {
      setOpenOrderList(!openOrderList);
    };

    const onClickTable = () => {
      if (mode !== "edit") {
        if (!isEmpty(table.orders)) {
          if (table.orders.length > 1) {
            toggleMultipleModal();
          } else {
            onSelectOrder(table.orders[0]);
          }
        } else {
          createTableOrder(table);
        }
      }
    };

    const addNewOrder = () => {
      createTableOrder(table);
    };

    const onSelectedOrder = (order: any) => {
      onSelectOrder(order);
    };

    const isFree = isEmpty(table.orders);
    const orderTable = isFree
      ? BaseColors.tableFree
      : getTableColor({ orders: table.orders, employees });
    const tableColor =
      table.tbType === "ACTIVE" ? orderTable : BaseColors.gunsmoke;

    return (
      <Rnd
        minWidth={100}
        minHeight={100}
        default={{
          x: 0,
          y: 0,
          width: 100,
          height: 100,
        }}
        bounds=".table-edit-wrapper"
        disableDragging={mode !== "edit"}
        position={{ x: table.x, y: table.y }}
        size={{ width: table.w, height: table.h }}
        style={{
          background: tableColor,
          borderRadius: table.tbShape === "ROUND" ? "100%" : "unset",
        }}
        onDragStop={(e, d) => {
          updateTable(null, d);
          // this.setStaxte({ x: d.x, y: d.y });
        }}
        enableResizing={mode === "edit"}
        onResizeStop={(e, direction, ref, delta, position) => {
          updateTable(ref, position);
          // this.setState({
          //   width: ref.style.width,
          //   height: ref.style.height,
          //   ...position,
          // });
        }}
      >
        <SquareWrapper
          onClick={() => onClickTable()}
          isRound={table.tbShape === "ROUND"}
          disabled={table.tbType !== "ACTIVE"}
          // style={{ left: table.x, top: table.y }}
          // ref={(el) => drop(drag(el))}
        >
          {/* <div>
            {mode === "edit" ? (
              <img
                alt=""
                src={isFree ? EditIconDark : EditIcon}
                onClick={() => {
                  onEdit(table);
                  // setEdit(areaId);
                  // setOpen(true);
                }}
              />
            ) : null}
          </div> */}
          {!isEmpty(table.orders) && table.orders.length > 1 && (
            <div className={`table-value`}>{table.orders.length}</div>
          )}
          <div className={`table-number ${isFree ? "dark" : ""}`}>
            {mode === "edit" ? (
              <img
                alt=""
                src={isFree ? EditIconDark : EditIcon}
                onClick={() => {
                  onEdit(table);
                  // setEdit(areaId);
                  // setOpen(true);
                }}
              />
            ) : null}
            {table.tbNumber}
          </div>
          {!isEmpty(table.orders) &&
            slice(table.orders, 0, 2).map((order: any) => {
              return <div className={"table-order-id"}>{order.recieptId}</div>;
            })}
          {!isEmpty(table.orders) && table.orders.length > 2 && (
            <div className={"table-order-id"}>{"..."}</div>
          )}
          {/* <div style={{ width: "100px", height: "100px", position: "relative" }}> */}
          {/* <Resizer
            onMouseEnter={() => setResize(true)}
            onMoseLeave={() => setResize(false)}
          /> */}
          {/* </div> */}
        </SquareWrapper>
        <TableOrderModal
          data={table}
          isOpen={openOrderList}
          addNewOrder={() => addNewOrder()}
          closeModal={() => toggleMultipleModal()}
          onSelectedOrder={(order: any) => onSelectedOrder(order)}
        />
      </Rnd>
    );
  }
);
