import styled from "styled-components";

export const MenuContainer = styled.div<{ height: string }>`
  width: 100%;
  height: ${(props) => props.height};
  background-color: #000000;
  padding: 28px 28px;
  height: 100vh;

  .backToTextWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 55px;

    :hover {
      cursor: pointer;
    }

    & > div {
      color: #ffffff;
      font-size: 26px;
      margin-left: 30px;
      font-family: Montserrat;
    }
  }

  .navigation-wrapper {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 152px);
    justify-content: space-between;

    .navigation-item-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-left: 100px;
      flex-direction: row;
      width: calc(100vw - 306px);
      height: calc(100vh - 202px);
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      & > div {
        width: 50%;
        height: 56px;
        color: #ffffff;
        font-size: 36px;
        font-weight: bold;
        font-family: "Montserrat";

        :hover {
          color: #49a4d4;
          cursor: pointer;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .sign-out-wrapper {
      width: 140px;
      height: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: space-between;

      & > img {
        :hover {
          color: #49a4d4;
          cursor: pointer;
        }
      }

      & > div {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;

        & > div {
          color: #ffffff;
          font-size: 22px;
          margin-left: 20px;
          font-family: "Montserrat";
          :hover {
            color: #49a4d4;
            cursor: pointer;
          }
        }
      }
    }
  }
`;
