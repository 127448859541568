import React from 'react';
import {Map} from 'immutable';
import {ModuleEvents} from './Actions';
import moment from 'moment';
import _ from "lodash";
import {ModuleEvents as PaymentModule} from "../payment/Actions";

const initialState = Map({
  selectedView: "Service",
  shopId: "8c83df68-3f7c-4336-854b-ad16aaa954fe",
  appointmentDateAndTime: {},
  salonSchedule: [
    {
      "start": "6:00am",
      "end": "7:00pm",
      "isClosed": false,
      "day": "Monday"
    },
    {
      "start": "",
      "end": "",
      "isClosed": true,
      "day": "Tuesday"
    },
    {
      "start": "",
      "end": "",
      "isClosed": true,
      "day": "Wednesday"
    },
    {
      "start": "3:00am",
      "day": "Thursday",
      "end": "8:00pm"
    },
    {
      "start": "",
      "end": "",
      "isClosed": true,
      "day": "Friday"
    },
    {
      "start": "",
      "end": "",
      "isClosed": true,
      "day": "Saturday"
    },
    {
      "start": "",
      "end": "",
      "isClosed": true,
      "day": "Sunday"
    }
  ],
  loadingAction: {loading: false, action: {}},
  holidays: [],
  holidayTimeStamps: new Map(),
  stylistAppointments:
    {
      "createdUserId": "17030bcf-6188-4c77-9c09-9ff39d8d132b",
      "appointmentId": "0c2b903d-8837-441a-8381-f566d4948681",
      "lastName": "user",
      "createdTime": 1626873396136,
      "time": "06.00",
      "email": "manori@gmail.com",
      "name": "manori user",
      "shopId": "51bab21d-bb6b-4242-8c2d-4b7dc44ace67",
      "firstName": "manori",
      "salonName": "The bridal house",
      "date": 1626892200000,
      "orderStatus": "PENDING",
      "updatedTime": 1626873396136,
      "mobileNumber": "+94778016490",
      "serviceDetails": [
        {
          "duration": "1h:00min",
          "id": "ec2eb5fe-8949-4dcb-ae5c-4a3fc6f0682e",
          "serviceTitle": "color",
          "price": 1200
        }
      ]
    },


  salon: {
    "sid": "8c83df68-3f7c-4336-854b-ad16aaa954fe",
    "currency": "LKR",
    "specialization": [
      "UNISEX"
    ],
    "maxPrice": 200,
    "place_id": "ChIJf_8zsNNb4joRlWtk5cUx-00",
    "reviewCount": 24,
    "lng": 79.8709181,
    "createdTime": 1627884447264,
    "claimStatus": "PENDING",
    "email": "admin@gmail.com",
    "createdBy": "38a1a270-56da-4b23-b38a-83fa383836eb",
    "salonPriceCount": 1,
    "salonName": "",
    "image": "376a1218-7b44-4e49-a679-9ef9ab2bb9ed.jpeg",
    "salonCategory": [
      "Beauty Salons",
      "Hair Salons"
    ],
    "mobileNumber": "+94702826718",
    "rating": 4.6,
    "address": "VVRC+G93, Colombo 00500",
    "schedule": [
      {
        "start": "6:22am",
        "end": "7:22pm",
        "isClosed": false,
        "day": "Monday"
      },
      {
        "start": "8:00am",
        "end": "8:00pm",
        "isClosed": false,
        "day": "Tuesday"
      },
      {
        "start": "8:00am",
        "day": "Wednesday",
        "isClosed": false,
        "end": "10:30pm"
      },
      {
        "start": "3:00am",
        "end": "8:00pm",
        "isClosed": false,
        "day": "Thursday"
      },
      {
        "start": "3:00am",
        "end": "9:00pm",
        "isClosed": false,
        "day": "Friday"
      },
      {
        "start": "3:30am",
        "end": "8:30pm",
        "isClosed": false,
        "day": "Saturday"
      },
      {
        "start": "5:00am",
        "end": "9:00pm",
        "isClosed": false,
        "day": "Sunday"
      }
    ],
    "averagePrice": 50,
    "paymentMethod": "CARD_AND_CASH",
    "description": "As a user i should be able to click on an subscription payment transaction and view the generated invoice\n",
    "lat": 6.8912694,
    "minPrice": 100,
    "updatedTime": 1630053810044
  }
});

export const Reducer = (state = initialState, action) => {
  const {payload, type, error} = action;

  switch (action.type) {
    case ModuleEvents.FETCH_SALON_HOLIDAYS: {
      if (payload && !payload.error) {
        switch (payload.type) {
          case 'LOADING': {
            const holidays = state.get('holidays');
            const newFiltered = _.filter(payload.result.items, (item) => {
              return item.status !== 'DELETED'
            });
            const newList = holidays.concat(newFiltered);
            const sortedItems = _.sortBy(newList, 'date');
            return state
              .set('holidays', sortedItems)
              .set('holidayLastKey', payload.result.lastKey)
              .set('selectedSalonId', payload.selectedSalonId)
          }
          case 'APPOINTMENT': {
            const newFiltered = _.filter(payload.result.items, (item) => {
              return item.status !== 'DELETED'
            });
            let stampMap = new Map();
            const sortedItems = _.sortBy(newFiltered, 'date');

            sortedItems.forEach((item, index) => {
              if (_.isNil(item.holidayDetails.dateRangeEnd)) {
                stampMap = stampMap.set(item.date, index)
              } else {
                const {totalDays} = item.holidayDetails;
                let i = 0;
                let newItem = item.date;
                while (i < totalDays) {
                  stampMap = stampMap.set(newItem, index)
                  newItem = moment(newItem).add(1, 'day').valueOf();
                  i++;
                }
              }
            });
            return state
              .set('holidayTimeStamps', stampMap)
              .set('holidays', sortedItems)
          }
          default: {
            const newFiltered = _.filter(payload.result.items, (item) => {
              return item.status !== 'DELETED'
            });
            const sortedItems = _.sortBy(newFiltered, 'date');
            return state
              .set('holidays', sortedItems)
              .set('holidayLastKey', payload.result.lastKey)
              .set('selectedSalonId', payload.selectedSalonId)
          }
        }
      }
      return state
    }

    case ModuleEvents.SELECTED_DATE_AND_TIME: {
      return state.set('appointmentDateAndTime', payload);
    }

    case PaymentModule.CHECKOUT: {
      if (payload && !payload.stopNavigation) {
        return state.set('appointmentDateAndTime', {});
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
