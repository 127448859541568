import React, {useState, useEffect} from 'react';
import styles from '../../Styles/Styles.css';
import {Row, Col} from 'react-grid-system';
import SaveButton from '../../Buttons/Views/SaveButton';
import CancelButton from '../../Buttons/Views/CancelButton';
import {connect} from "react-redux/es/index";
import IconShowEye from "../../../../assets/svg/IconShowEye.svg";
import IconHideEye from "../../../../assets/svg/IconHideEye.svg";
import {Actions} from "../../../internal/app/Actions";
import {validatePassword} from "../../../internal/validator/Validate";

const UserInformation = (props) => {
  const {currentUser, resetPassword, accessToken, updateUser} = props;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isValidCurrentPassword, setIsValidCurrentPassword] = useState(false);
  const [isValidNewPassword, setIsValidNewPassword] = useState(false);
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);
  const [isEnabledNameInput, setIsEnabledNameInput] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    setName(currentUser.name);
  }, []);

  const setValue = async (setFunc, setValid, text) => {
    const isValid = await validatePassword(text);
    if (text.length <= 6) {
      setFunc(text);
      setValid(isValid.isValid);
    }
  };

  const clearInputs = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setIsValidCurrentPassword(false);
    setIsValidNewPassword(false);
  };

  const onResetPassword = () => {
    const param = {
      accessToken,
      previousPassword: currentPassword,
      newPassword
    };

    resetPassword(param);
    clearInputs();
  };

  const onUpdateUser = () => {
    const param = {
      empId: currentUser.empId,
      shopId: currentUser.shopId,
      name
    };

    updateUser(param);
  };

  const isValid = isValidCurrentPassword && isValidNewPassword && isValidConfirmPassword && newPassword === confirmPassword;
  const isNameValid = name && name.length > 0;

  return (
    <div className={styles.userInformationContainer}>
      <Row>
        <Col xs={12}>
          <div className={styles.userInfoTextTitle}>User information</div>
        </Col>
      </Row>

      <div className={styles.userInfoRow}>
        <Row>
          <Col xs={4}>
            <div className={styles.userInformationTop}>
              <p className={styles.userInfoTitle}>User Name</p>
              <p className={styles.userInfoEmail} style={{marginTop: isEnabledNameInput ? '55px' : '28px'}}>Email</p>
            </div>
          </Col>
          <Col xs={4}>
            <div className={styles.userInformationTop}>
              {
                isEnabledNameInput ?
                  <input value={name} onChange={(text) => setName(text.target.value)} className={styles.userInformationNameInput}/>
                  : <p className={styles.infoValues}>{name}</p>
              }
              <p className={styles.infoEmailValues}>{currentUser.email}</p>
            </div>
          </Col>
          <Col xs={4} className={styles.lastCol}>
            <div className={styles.userInformationTop}>
              {
                isEnabledNameInput ?
                  <SaveButton
                    onClick={() => {
                      if (isNameValid) {
                        onUpdateUser();
                        setIsEnabledNameInput(false);
                      }
                    }}
                    isValid={isNameValid}
                  />
                  :
                  <p className={styles.editText} onClick={() => setIsEnabledNameInput(true)}>Edit</p>
              }
            </div>
          </Col>
        </Row>
      </div>

      <Row>
        <Col xs={12}>
          <div className={styles.userInfoTextTitle} style={{paddingTop: '30px'}}>
            Change passcode
          </div>
        </Col>
      </Row>

      <Row className={styles.currentPasscodeContainer}>
        <Col xs={4} className={styles.currentPasscodeLabelContainer}>
          <div className={styles.changePasscodeFieldsTitles}>Current Passcode</div>
        </Col>
        <Col xs={8}>
          <div className={styles.userInformationInputWrapper}>
            <input value={currentPassword} onChange={(text) => setValue(setCurrentPassword, setIsValidCurrentPassword, text.target.value)} type={showCurrentPassword ? "text" : "password"} id="pwd" className={styles.userInformationInput} name="password"/>
            <img onClick={() => setShowCurrentPassword(!showCurrentPassword)} src={showCurrentPassword ? IconShowEye : IconHideEye}/>
          </div>
        </Col>
      </Row>
      <Row className={styles.currentPasscodeContainer}>
        <Col xs={4} className={styles.currentPasscodeLabelContainer}>
          <div className={styles.changePasscodeFieldsTitles}>New Passcode</div>
        </Col>
        <Col xs={8}>
          <div className={styles.userInformationInputWrapper}>
            <input value={newPassword} onChange={(text) => setValue(setNewPassword, setIsValidNewPassword, text.target.value)} type={showNewPassword ? "text" : "password"} id="pwd" className={styles.userInformationInput} name="password"/>
            <img onClick={() => setShowNewPassword(!showNewPassword)} src={showNewPassword ? IconShowEye : IconHideEye}/>
          </div>
        </Col>
      </Row>
      <Row className={styles.currentPasscodeContainer}>
        <Col xs={4} className={styles.currentPasscodeLabelContainer}>
          <div className={styles.changePasscodeFieldsTitles}>Confirm Passcode</div>
        </Col>
        <Col xs={8}>
          <div className={styles.userInformationInputWrapper}>
            <input value={confirmPassword} onChange={(text) => setValue(setConfirmPassword, setIsValidConfirmPassword, text.target.value)} type={showConfirmPassword ? "text" : "password"} id="pwd" className={styles.userInformationInput} name="password"/>
            <img onClick={() => setShowConfirmPassword(!showConfirmPassword)} src={showConfirmPassword ? IconShowEye : IconHideEye}/>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className={styles.saveCancelButtonsRow}>
            <SaveButton
              onClick={() => {
                if (isValid) {
                  onResetPassword()
                }
              }}
              isValid={isValid}
            />
            <CancelButton
              onClick={() => clearInputs()}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default connect(
  state => ({
    currentUser: state.login.get('currentUser'),
    accessToken: state.login.get('accessToken')
  }),
  ({
    resetPassword: Actions.profile.resetPassword,
    updateUser: Actions.profile.updateUser
  })
)(UserInformation);
