import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import styles from "../Styles/Styles.css";
import useImage from '../../internal/hooks/useImage';
import itemSelectedIcon from "../../../assets/icons/itemSelectedIcon.svg";

const ServiceCard = (props) => {
  const {index, onDelete, item, onSelectServiceItem, isSelected, isEdit, category, onSelectService} = props;
  const [nameIcon, setNameIcon] = useState('');
  const [base64Image, setBase64Image] = useState(null);
  const image = useImage(item.image || item.productImage);

  useEffect(() => {
    const imageId = item.image || item.productImage;
    if (imageId) {
      getImage(imageId);
    }
    let character = _.get(item, 'serviceTitle').match(/\b(\w)/g);
    setNameIcon(_.toUpper(character[0]) + _.toUpper(character[1]));
  }, [item]);

  const getImage = (path) => {
    window.api.getFilePath(path, async (result) => {
      const newImage = await new Buffer(result).toString('base64')
      setBase64Image(newImage);
      return result;
    });
  };

  const imageData = base64Image ? `data:image/png;base64,${base64Image}` : image;

  return (
    <div className={styles.serviceCardWrapper} onClick={() => {
      if (index > -1) {
        onDelete();
      } else {
        onSelectServiceItem(item)
      }

      if (isEdit) {
        onSelectService(item);
      }
    }}>
      <div className={styles.serviceCardImageWrapper}>
        {
          item.image ?
            <img className={styles.productImageCard} src={imageData || undefined}/>
            :
            <div className={styles.serviceCardImageTitle}>{nameIcon}</div>
        }
      </div>
      <div className={styles.itemText}>{item.serviceTitle}</div>
      {
        index > -1 ?
          <div className={styles.serviceCardSelectedItemWrapper}>
            <img src={itemSelectedIcon}/>
          </div>
          : null
      }
    </div>
  )
};

export default ServiceCard;
