import gql from 'graphql-tag';

export const CreateRoomType = gql`
  mutation CreateRoomType($input: CreateRoomTypeInput!) {
    createRoomType(input: $input) {
      shopId
      rtId
      title
      basePrice
      desc
    }
  }
`;

export const UpdateRoomType = gql`
  mutation UpdateRoomType($input: UpdateRoomTypeInput!) {
    updateRoomType(input: $input) {
      shopId
      rtId
      title
      basePrice
      desc
    }
  }
`;

export const DeleteRoomType = gql`
  mutation DeleteRoomType($input: DeleteRoomTypeInput!) {
    deleteRoomType(input: $input) {
      shopId
      rtId
      title
      basePrice
      desc
    }
  }
`;


export const GetRoomAreas = gql`
query GetRoomAreas($shopId: String!) {
  getRoomAreas(shopId: $shopId) {
    shopId
    roomAreas {
      areaName
      areaId
      rooms {
        rmId
        rmNumber
        rmType
        rmShape
        x
        y
        h
        w
      }
    }
  }
}
`
export const CreateRoomAreas = gql`
  mutation CreateRoomAreas($input: CreateRoomAreasInput!) {
    createRoomAreas(input: $input) {
      shopId
      roomAreas {
        areaName
        areaId
        rooms {
          rmId
          rmNumber
          rmType
          rmShape
          x
          y
          h
          w
        }
      }
    }
  }
`;

export const UpdateRoomAreas = gql`
  mutation UpdateRoomAreas($input: UpdateRoomAreasInput!) {
    updateRoomAreas(input: $input) {
      shopId
      roomAreas {
        areaName
        areaId
        rooms {
          rmId
          rmNumber
          rmType
          rmShape
          x
          y
          h
          w
        }
      }
    }
  }
`;