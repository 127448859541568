import React, { useEffect, useState } from "react";
import {
  ProductAddPriceWrapper,
  ProductPriceItemWrapper,
  ProductAddOptionWrapper,
  ProductAddModifierWrapper,
  ProductModifiersItemWrapper,
  ProductAddPriceHeaderWrapper,
  ProductAddModifierHeaderWrapper,
} from "./Styled";
import "antd/dist/antd.css";
import "../Styles.style.css";
import Select from "react-select";
import Toggle from "react-toggle";
import { v4 as uuid } from "uuid";
import { Row, Col } from "react-grid-system";
import { connect } from "react-redux/es/index";
import {
  mapBrand,
  mapCategory,
  mapWarranty,
  mapProductType,
} from "../../../internal/manager/ProductManager";
import { Actions } from "../../../internal/app/Actions";
import IconPlus from "../../../../assets/svg/icon-plus.svg";
import CloseIcon from "../../../../assets/images/closeIcon.png";
import _, { find, cloneDeep, isEmpty, capitalize, omit } from "lodash";
import StockItemCard from "../../../components/card/StockItemCard";
import ImageUploadCard from "../../../components/card/ImageUploadCard";
import { navigate } from "../../../internal/service/Navigation.service";
import AddStockModel from "../../../components/model/views/AddStockModel";
import { getCurrencyWithPrice } from "../../../internal/manager/PaymentManager";
import ServiceWarningModal from "../../../components/model/views/ServiceWarningModal";
import CreateCategoryModel from "../../../components/model/views/CreateCategoryModel";
import CreateStockItemModel from "../../../components/model/views/CreateStockItemModel";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";
import EditProductItemTopHeader from "../../../components/product/views/EditProductItemTopHeader";

const style = {
  control: (base, state) => ({
    ...base,
    maxWidth: "544px",
    height: "45px",
    paddingRight: "20px",
    border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    "&:hover": {
      border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    },
    fontWeight: 500,
    fontSize: "14px",
    color: "#434343",
    paddingLeft: "5px",
    borderRadius: "6px",
    fontFamily: "Montserrat",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
  }),
  menu: (base, state) => ({
    ...base,
    width: "544px",
  }),
};

const ProductAddView = (props) => {
  const {
    shop,
    warranty,
    brandsList,
    currentUser,
    productTypes,
    createProduct,
    deleteProduct,
    updateProduct,
    loadingAction,
    productImageId,
    setProductData,
    productWarranty,
    selectedProduct,
    createProductData,
    uploadProductImage,
    removeProductImage,
    productCategoryList,
    productPriceOptions,
    productPriceVariants,
    setProductPriceVariants,
    productModifiers,
    setProductModifiers,
  } = props;

  const [price, setPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [modalType, setModalType] = useState("");
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [isEnable, setIsEnable] = useState<boolean>(false);
  const [sendToBar, setSendToBar] = useState<boolean>(false);
  const [stockItems, setStockItems] = useState<Array<any>>([]);
  const [warranties, setWarranties] = useState<Array<any>>([]);
  const [selectedBrand, setSelectedBrand] = useState<any>(null);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [selectedProductType, setProductType] = useState<any>(null);
  const [sendToKitchen, setSendToKitchen] = useState<boolean>(false);
  const [priceVariants, setPriceVariants] = useState<Array<any>>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [selectedStockItem, setSelectedStockItem] = useState<any>({});
  const [selectedWarranty, setSelectedWarranty] = useState<any>(null);
  const [isOpenStockModal, setIsOpenStockModal] = useState<boolean>(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState<boolean>(false);
  const [isOpenDiscardModel, setIsOpenDiscardModel] = useState<boolean>(false);
  const [isOpenCategoryModal, setIsOpenCategoryModal] =
    useState<boolean>(false);
  const [isOpenStockItemModal, setIsOpenStockItemModal] =
    useState<boolean>(false);
  const [modifiers, setModifiers] = useState<Array<any>>([]);
  const [editModIndex, setEditModIndex] = useState<any>(null);

  useEffect(() => {
    if (!isEmpty(productWarranty)) {
      setSelectedWarranty({
        value: productWarranty.wrid,
        label: capitalize(productWarranty.title),
      });
    }
  }, [productWarranty]);

  useEffect(() => {
    if (warranty) {
      setWarranties(warranty.warranties);
    }
  }, [warranty]);

  useEffect(() => {
    if (!isEmpty(productModifiers)) {
      const data = cloneDeep(productModifiers);
      setModifiers(data);
    }
  }, [productModifiers]);

  useEffect(() => {
    if (!isEmpty(productPriceVariants)) {
      const variants = cloneDeep(productPriceVariants);
      setPriceVariants(variants);
    }
  }, [productPriceVariants]);

  const setCreatedPeoductDetails = (product: any) => {
    setPrice(product.itemPrice);
    setSalePrice(product.itemSalePrice);
    setProductName(_.startCase(product?.productName));
    setSendToKitchen(product?.sendToKitchen);
    setSendToBar(product?.sendToBar);
    setDescription(product?.description);
    if (_.get(product, "stockItems.length", 0) > 0) {
      setStockItems(product?.stockItems);
    }
    const productIndex = _.findIndex(
      productCategoryList,
      (item: any) => item.categoryId === product?.categoryId
    );
    const brandIndex = _.findIndex(
      brandsList,
      (item: any) => item.id === product?.brandId
    );
    const productTypeIndex = _.findIndex(
      productTypes,
      (item: any) => item.id === product?.productTypeId
    );
    if (productIndex > -1) {
      let categoryData = _.cloneDeep(productCategoryList[productIndex]);
      categoryData.value = categoryData.categoryId;
      categoryData.label = categoryData.categoryName;
      setSelectedCategory(categoryData);
    }
    if (productTypeIndex > -1) {
      setProductType({
        ...productTypes[productTypeIndex],
        value: productTypes[productTypeIndex].id,
        label: productTypes[productTypeIndex].typeName,
      });
    }
    if (brandIndex > -1) {
      let brandData = _.cloneDeep(brandsList[brandIndex]);
      brandData.value = brandData.id;
      brandData.label = brandData.brandName;
      setSelectedBrand(brandData);
    }
    setIsEnable(product?.isFavourite);
  };

  useEffect(() => {
    if (!_.isEmpty(selectedProduct)) {
      setCreatedPeoductDetails(selectedProduct);
    }
  }, [selectedProduct, brandsList, productCategoryList]);

  useEffect(() => {
    if (!isEmpty(setProductData) && isEmpty(selectedProduct)) {
      setCreatedPeoductDetails(setProductData);
    }
  }, [setProductData, selectedProduct]);

  const { loading } = loadingAction;
  const isEdit = !_.isEmpty(selectedProduct);

  const onCreateProduct = () => {
    const param: any = {
      sendToBar,
      stockItems,
      pid: uuid(),
      description,
      sendToKitchen,
      shopId: shop.shopId,
      updatedTime: Date.now(),
      brandId: selectedBrand.value,
      productImage: productImageId,
      createdUserId: currentUser.empId,
      categoryId: selectedCategory.value,
      productName: productName.toLowerCase(),
      productTypeId: selectedProductType?.value,
      stockIds: stockItems.map((item) => item.id),
      // tax: parseFloat(tax),
      // commission: parseFloat(commission),
      // stockAvailability: parseInt(stockAvailability),
    };
    if (!isEmpty(productPriceOptions)) {
      const variants = priceVariants.map((variant: any) => {
        variant.price = parseFloat(variant.price);
        return variant;
      });
      param.priceVariants = [...variants];
      param.productOptions = [...productPriceOptions];
    } else {
      param.itemPrice = parseFloat(price);
      param.itemSalePrice = parseFloat(salePrice);
    }
    if (!isEmpty(selectedWarranty)) {
      let result = find(
        warranties,
        (warranty: any) => warranty.wrid === selectedWarranty.value
      );
      result = omit(result, ["status"]);
      result["shopId"] = shop.shopId;
      param["warranty"] = result;
    }
    if (!isEmpty(productModifiers)) {
      param["modifiers"] = productModifiers;
    }
    if (isEdit) {
      param.pid = selectedProduct.pid;
      updateProduct(param);
    } else {
      param.createdTime = Date.now();
      createProduct(param);
    }
  };

  const onDeleteProduct = () => {
    deleteProduct({
      productId: selectedProduct.pid,
      shopId: selectedProduct.shopId,
    });
  };

  const onRemoveItem = (data) => {
    const index = _.findIndex(
      stockItems,
      (item) => item.itemId === data.itemId
    );
    if (index > -1) {
      stockItems.splice(index, 1);
    }
    setStockItems([...stockItems]);
  };

  const onAddStockItem = (item) => {
    if (!_.isEmpty(selectedStockItem)) {
      stockItems[selectedStockItem.index] = item;
    } else {
      stockItems.push(item);
    }
    setStockItems([...stockItems]);
    setSelectedStockItem({});
  };

  const checkValid = () => {
    let valid = false;
    let validPrice: boolean;

    if (!isEmpty(priceVariants)) {
      validPrice = priceVariants.every(
        (variant: any) => !isNaN(parseFloat(variant.price))
      );
    } else {
      validPrice = !isEmpty(price && price.toString());
    }
    valid =
      !isEmpty(productName) &&
      !isEmpty(selectedCategory) &&
      !isEmpty(selectedBrand) &&
      validPrice;
    return valid;
  };

  const toggleCreateItem = (type = "") => {
    setModalType(type);
    setIsOpenCategoryModal(!isOpenCategoryModal);
  };

  const setCreateProductData = () => {
    const param: any = {
      sendToBar,
      stockItems,
      description,
      sendToKitchen,
      brandId: selectedBrand?.value,
      productImage: productImageId,
      categoryId: selectedCategory?.value,
      productName: productName?.toLowerCase(),
      productTypeId: selectedProductType?.value,
      stockIds: stockItems?.map((item) => item.id),
      // tax: parseFloat(tax),
      // commission: parseFloat(commission),
      // stockAvailability: parseInt(stockAvailability),
    };
    if (!isEmpty(price)) {
      param["itemPrice"] = parseFloat(price);
    }
    if (!isEmpty(salePrice)) {
      param["itemSalePrice"] = parseFloat(salePrice);
    }
    createProductData(param);
  };

  const isValid = checkValid();
  const mapBrandList = mapBrand(brandsList);
  const mapWarrantyList = mapWarranty(warranties);
  const mapCategoryList = mapCategory(productCategoryList);
  const mappedProductTypes = mapProductType(productTypes);

  return (
    <div className="addCategoryMainContainer">
      <TopNavigationBar viewName="Products" path="/menu" />
      <div className="addServiceViewContainer">
        <div className="addServiceViewWrapper">
          <EditProductItemTopHeader
            onCreateService={() => {
              if (isValid && !loading) {
                onCreateProduct();
              }
            }}
            isEdit={isEdit}
            onBack={() => {
              if (isEdit) {
                setIsOpenDiscardModel(true);
              } else {
                createProductData({});
                navigate("/products-customized");
              }
            }}
            isValid={isValid}
            backBtnText={isEdit ? "Edit Product" : "Add Product"}
            buttonText={"Save Product"}
          />
          <Row>
            <Col xs={12}>
              <div className="serviceAddFormContainer">
                <div className="addServiceDetailsText">Product Details</div>
                <div className="addServiceInputContainer">
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "16px 0 0 0",
                    }}
                  >
                    <Col
                      xs={2}
                      style={{ padding: 0 }}
                      className="serviceTitleCol"
                    >
                      <div className="serviceTitleText">Product Name*</div>
                    </Col>
                    <Col
                      xs={8}
                      style={{ padding: 0 }}
                      className="serviceTitleColInput"
                    >
                      <input
                        value={productName}
                        className="serviceTextInput"
                        placeholder="Product Name"
                        onChange={(text) => setProductName(text.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "16px 0 0 0",
                    }}
                  >
                    <Col
                      xs={2}
                      style={{ padding: 0 }}
                      className="serviceTitleCol"
                    >
                      <div className="serviceTitleText">Category*</div>
                    </Col>
                    <Col
                      xs={7}
                      style={{ padding: 0 }}
                      className="serviceTitleColInput"
                    >
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        onChange={(data) => setSelectedCategory(data)}
                        styles={style}
                        value={selectedCategory}
                        placeholder={
                          <div className="categoryPlaceholderText">
                            Category
                          </div>
                        }
                        name="color"
                        options={mapCategoryList}
                      />
                    </Col>
                    <Col xs={3}>
                      <div
                        onClick={() => toggleCreateItem("CATEGORY")}
                        className="addCategoryBtnContainer"
                      >
                        <div className="addCategoryBtnText">Add Category</div>
                      </div>
                    </Col>
                    <Col xs={2} />
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "16px 0 0 0",
                    }}
                  >
                    <Col
                      xs={2}
                      style={{ padding: 0 }}
                      className="serviceTitleCol"
                    >
                      <div className="serviceTitleText">Brand*</div>
                    </Col>
                    <Col
                      xs={7}
                      style={{ padding: 0 }}
                      className="serviceTitleColInput"
                    >
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        onChange={(data) => setSelectedBrand(data)}
                        styles={style}
                        value={selectedBrand}
                        placeholder={
                          <div className="categoryPlaceholderText">Brand</div>
                        }
                        name="color"
                        options={mapBrandList}
                      />
                    </Col>
                    <Col xs={3}>
                      <div
                        onClick={() => toggleCreateItem("BRAND")}
                        className="addCategoryBtnContainer"
                      >
                        <div className="addCategoryBtnText">Add Brand</div>
                      </div>
                    </Col>
                    <Col xs={2} />
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "16px 0 0 0",
                    }}
                  >
                    <Col
                      xs={2}
                      style={{ padding: 0 }}
                      className="serviceTitleCol"
                    >
                      <div className="serviceTitleText">Product Type*</div>
                    </Col>
                    <Col
                      xs={7}
                      style={{ padding: 0 }}
                      className="serviceTitleColInput"
                    >
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        onChange={(data) => setProductType(data)}
                        styles={style}
                        value={selectedProductType}
                        placeholder={
                          <div className="categoryPlaceholderText">
                            Product Type
                          </div>
                        }
                        name="color"
                        options={mappedProductTypes}
                      />
                    </Col>
                    <Col xs={3}>
                      <div
                        onClick={() => toggleCreateItem("PRODUCT_TYPE")}
                        className="addCategoryBtnContainer"
                      >
                        <div className="addCategoryBtnText">
                          Add Product Type
                        </div>
                      </div>
                    </Col>
                    <Col xs={2} />
                  </Row>
                  <div className="priceOptionWrapper">
                    <Col xs={2} style={{ paddingLeft: 0, paddingRight: 0 }} />
                    <Col xs={7} style={{ paddingLeft: 0, paddingRight: 0 }}>
                      {isEmpty(productPriceOptions) ? (
                        <>
                          <button
                            className="optionButtonWrapper"
                            onClick={() => {
                              setCreateProductData();
                              navigate("/add-product-options");
                            }}
                          >
                            {"Add Price Options"}
                          </button>
                          <p className="priceOptionTitle">
                            Add product options to differentiate price for
                            product types. For example:{" "}
                            <strong> Large, Medium </strong> and
                            <strong> Small Coffee. </strong>
                          </p>
                        </>
                      ) : (
                        <ProductAddOptionWrapper
                          onClick={() => {
                            setCreateProductData();
                            setProductPriceVariants(priceVariants, false);
                            navigate("/add-product-options");
                          }}
                        >
                          <div>{"Add Options Set"}</div>
                          <img src={IconPlus} />
                        </ProductAddOptionWrapper>
                      )}
                    </Col>
                  </div>
                  {isEmpty(productPriceOptions) ? (
                    <>
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "16px 0 0 0",
                        }}
                      >
                        <Col
                          xs={2}
                          style={{ padding: 0 }}
                          className="serviceTitleCol"
                        >
                          <div className="serviceTitleText">Price*</div>
                        </Col>
                        <Col
                          xs={8}
                          style={{ padding: 0 }}
                          className="serviceTitleColInput"
                        >
                          <input
                            onChange={(text) => setPrice(text.target.value)}
                            value={price}
                            placeholder="Price"
                            className="serviceTextInput"
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "16px 0 0 0",
                        }}
                      >
                        <Col
                          xs={2}
                          style={{ padding: 0 }}
                          className="serviceTitleCol"
                        >
                          <div className="serviceTitleText">Whl Sale Price</div>
                        </Col>
                        <Col
                          xs={8}
                          style={{ padding: 0 }}
                          className="serviceTitleColInput"
                        >
                          <input
                            onChange={(text) => setSalePrice(text.target.value)}
                            value={salePrice}
                            className="serviceTextInput"
                            placeholder="Whl Sale Price"
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <div className="priceOptionWrapper">
                      <Col xs={2} style={{ paddingLeft: 0, paddingRight: 0 }} />
                      <Col xs={10} style={{ paddingLeft: 0, paddingRight: 24 }}>
                        <ProductAddPriceWrapper>
                          <ProductAddPriceHeaderWrapper>
                            <div>{"Option Name"}</div>
                            <div>{"Unit"}</div>
                            <div>{"Price"}</div>
                          </ProductAddPriceHeaderWrapper>
                          {priceVariants.map(
                            (priceVariant: any, index: number) => {
                              const isLastItem =
                                priceVariants.length - 1 === index;
                              return (
                                <ProductPriceItemWrapper isLast={isLastItem}>
                                  <div>
                                    <div>{priceVariant.optionUnit}</div>
                                    <div>{priceVariant.sku}</div>
                                    <div>
                                      {editIndex === index ? (
                                        <input
                                          placeholder="Price"
                                          value={`${priceVariant.price}`}
                                          onBlur={() => setEditIndex(null)}
                                          onChange={(event) => {
                                            priceVariants[index].price =
                                              event.target.value;
                                            setPriceVariants([
                                              ...priceVariants,
                                            ]);
                                          }}
                                        />
                                      ) : (
                                        <div
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setEditIndex(index);
                                          }}
                                        >
                                          {getCurrencyWithPrice(
                                            priceVariant.price,
                                            shop.currency
                                          )}
                                        </div>
                                      )}
                                      {priceVariants.length > 1 && (
                                        <img
                                          src={CloseIcon}
                                          className="imageRemoveIcon"
                                          onClick={() => {
                                            priceVariants.splice(index, 1);
                                            setPriceVariants([
                                              ...priceVariants,
                                            ]);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </ProductPriceItemWrapper>
                              );
                            }
                          )}
                        </ProductAddPriceWrapper>
                      </Col>
                    </div>
                  )}

                  <div className="productModifierWrapper">
                    <Col xs={2} style={{ paddingLeft: 0, paddingRight: 0 }} />
                    <Col xs={7} style={{ paddingLeft: 0, paddingRight: 0 }}>
                      {isEmpty(modifiers) ? (
                        <>
                          <button
                            className="optionButtonWrapper"
                            onClick={() => {
                              navigate("/add-product-modifier");
                            }}
                          >
                            {"Add Modifiers"}
                          </button>
                          <p className="priceOptionTitle">
                            Add modifiers to customise your products with more
                            add ons For example:{" "}
                            <strong> Extra Tomato, Cheese </strong> and
                            <strong> Lettuce. </strong>
                          </p>
                        </>
                      ) : (
                        <ProductAddOptionWrapper
                          onClick={() => {
                            navigate("/add-product-modifier");
                          }}
                        >
                          <div>{"Add Modifier"}</div>
                          <img src={IconPlus} />
                        </ProductAddOptionWrapper>
                      )}
                    </Col>
                  </div>
                  {!isEmpty(modifiers) && (
                    <div className="productModifierItemWrapper">
                      <Col xs={2} style={{ paddingLeft: 0, paddingRight: 0 }} />
                      <Col xs={10} style={{ paddingLeft: 0, paddingRight: 24 }}>
                        {modifiers.map((modifier: any, pIndex: number) => {
                          return (
                            <ProductAddModifierWrapper>
                              <div>{modifier.setName}</div>
                              <div>
                                <ProductAddModifierHeaderWrapper>
                                  <div>{"Modifier Items"}</div>
                                  <div>{"Price"}</div>
                                </ProductAddModifierHeaderWrapper>
                                {modifier?.items.map(
                                  (item: any, index: number) => {
                                    const isLastItem =
                                      modifier?.items.length - 1 === index;
                                    return (
                                      <ProductModifiersItemWrapper
                                        isLast={isLastItem}
                                      >
                                        <div>
                                          <div>{item.itemName}</div>
                                          <div>
                                            {!isEmpty(editModIndex) &&
                                            editModIndex[0] === pIndex &&
                                            editModIndex[1] === index ? (
                                              <input
                                                placeholder="Price"
                                                value={`${item.price}`}
                                                onBlur={() =>
                                                  setEditModIndex(null)
                                                }
                                                onChange={(event) => {
                                                  try {
                                                    item.price = parseFloat(
                                                      event.target.value
                                                    );
                                                    setModifiers([
                                                      ...modifiers,
                                                    ]);
                                                    setProductModifiers(
                                                      modifiers
                                                    );
                                                  } catch (e) {
                                                    console.log('Parse error')
                                                  }
                                                }}
                                              />
                                            ) : (
                                              <div
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setEditModIndex([
                                                    pIndex,
                                                    index,
                                                  ]);
                                                }}
                                              >
                                                {getCurrencyWithPrice(
                                                  item.price,
                                                  shop.currency
                                                )}
                                              </div>
                                            )}
                                            {modifier?.items.length > 1 && (
                                              <img
                                                src={CloseIcon}
                                                className="imageRemoveIcon"
                                                onClick={() => {
                                                  modifier?.items.splice(
                                                    index,
                                                    1
                                                  );
                                                  setModifiers([...modifiers]);
                                                  setProductModifiers(
                                                    modifiers
                                                  );
                                                }}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </ProductModifiersItemWrapper>
                                    );
                                  }
                                )}
                              </div>
                            </ProductAddModifierWrapper>
                          );
                        })}
                      </Col>
                    </div>
                  )}

                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "16px 0 0 0",
                    }}
                  >
                    <Col
                      xs={2}
                      style={{ padding: 0 }}
                      className="serviceTitleCol"
                    >
                      <div className="serviceTitleText">Warranty</div>
                    </Col>
                    <Col
                      xs={7}
                      style={{ padding: 0 }}
                      className="serviceTitleColInput"
                    >
                      <Select
                        styles={style}
                        name="warranty"
                        isClearable={true}
                        className="basic-single"
                        classNamePrefix="select"
                        value={selectedWarranty}
                        options={mapWarrantyList}
                        placeholder={
                          <div className="categoryPlaceholderText">
                            Warranty
                          </div>
                        }
                        onChange={(data: any) => setSelectedWarranty(data)}
                      />
                    </Col>
                    <Col xs={3}>
                      <div
                        className="addCategoryBtnContainer"
                        onClick={() => {
                          setCreateProductData();
                          navigate("/add-warranty");
                        }}
                      >
                        <div className="addCategoryBtnText">Add Warranty</div>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "16px 0 0 0",
                    }}
                  >
                    <Col
                      xs={2}
                      style={{ padding: 0 }}
                      className="serviceTitleCol"
                    >
                      <div className="serviceTitleText">
                        Send to Kitchen(KOT)
                      </div>
                    </Col>
                    <Col
                      xs={8}
                      style={{ padding: 0 }}
                      className="serviceTitleColInput toggles"
                    >
                      <div style={{ marginTop: "8px" }}>
                        <Toggle
                          checked={sendToKitchen}
                          onChange={(e) => setSendToKitchen(e.target.checked)}
                          value={sendToKitchen}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "16px 0 0 0",
                    }}
                  >
                    <Col
                      xs={2}
                      style={{ padding: 0 }}
                      className="serviceTitleCol"
                    >
                      <div className="serviceTitleText">Send to Bar(BOT)</div>
                    </Col>
                    <Col
                      xs={10}
                      style={{ padding: 0 }}
                      className="serviceTitleColInput"
                    >
                      <div style={{ marginTop: "8px" }}>
                        <Toggle
                          value={sendToBar}
                          checked={sendToBar}
                          onChange={(e) => setSendToBar(e.target.checked)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "16px 0 0 0",
                    }}
                  >
                    <Col
                      xs={2}
                      style={{ padding: 0 }}
                      className="serviceTitleCol"
                    >
                      <div className="serviceTitleText">Image</div>
                    </Col>
                    <Col
                      xs={8}
                      style={{ padding: 0 }}
                      className="serviceTitleColInput"
                    >
                      <ImageUploadCard
                        imageId={productImageId}
                        onRemoveImage={() => removeProductImage()}
                        loadingAction={loadingAction}
                        onUploadProductImage={(file) => {
                          uploadProductImage(file);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "16px 0 0 0",
                    }}
                  >
                    <Col
                      xs={2}
                      style={{ padding: 0 }}
                      className="serviceTitleCol"
                    >
                      <div className="serviceTitleText">Description</div>
                    </Col>
                    <Col
                      xs={8}
                      style={{ padding: 0 }}
                      className="serviceTitleColInput"
                    >
                      <textarea
                        onChange={(text) => setDescription(text.target.value)}
                        value={description}
                        className="serviceTextAreaInput"
                        placeholder="Description"
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "16px 0 0 0",
                    }}
                  >
                    <Col
                      xs={2}
                      style={{ padding: 0 }}
                      className="serviceTitleCol"
                    >
                      <div className="serviceTitleText">Stock Items</div>
                    </Col>
                    <Col
                      xs={7}
                      style={{ padding: 0 }}
                      className="serviceTitleColInput"
                    >
                      {stockItems && stockItems.length > 0 ? (
                        <StockItemCard
                          stockItems={stockItems}
                          onRemove={(data) => onRemoveItem(data)}
                          onSelectStockItem={(data, index) => {
                            setSelectedStockItem({ data, index });
                            setIsOpenStockModal(true);
                          }}
                        />
                      ) : null}
                    </Col>
                    <Col xs={3}>
                      <div
                        onClick={() => setIsOpenStockItemModal(true)}
                        className="addCategoryBtnContainer"
                      >
                        <div className="addCategoryBtnText">Add Stock</div>
                      </div>
                    </Col>
                    <Col xs={2} />
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "16px 0 16px 0",
                    }}
                  >
                    <Col xs={2} style={{ padding: 0 }}></Col>
                    <Col style={{ padding: 0 }}>
                      <div
                        onClick={() => setIsOpenStockModal(true)}
                        className="addCategoryBtnContainer"
                      >
                        <div className="addCategoryBtnText">Add Stock Item</div>
                      </div>
                    </Col>
                  </Row>
                  {isEdit ? (
                    <Row
                      style={{
                        margin: 0,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col xs={2} className="serviceTitleCol" />
                      <Col xs={10} className="serviceTitleColInput">
                        <div
                          onClick={() => setIsOpenDeleteModel(true)}
                          className="serviceDeleteBtnContainer"
                        >
                          <div className="serviceDeleteBtnText">
                            Delete Product
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ServiceWarningModal
        isOpen={isOpenDiscardModel}
        headerText={"Are you sure?"}
        bodText={"All your changes will be discarded"}
        cancelBtnText={"Discard"}
        onCancel={() => {
          setIsOpenDiscardModel(false);
          navigate("/products-customized");
        }}
        closeModal={() => setIsOpenDiscardModel(false)}
      />
      <CreateStockItemModel
        type={"STOCK_ITEM"}
        isOpen={isOpenStockModal}
        stockItems={stockItems}
        closeModal={() => {
          setSelectedStockItem({});
          setIsOpenStockModal(false);
        }}
        selectedStockItem={selectedStockItem?.data}
        onAddStockItem={(item) => onAddStockItem(item)}
      />
      <ServiceWarningModal
        isOpen={isOpenDeleteModel}
        headerText={"Warning!"}
        bodText={
          "Your product will be deleted and no longer\n available to order for the users"
        }
        cancelBtnText={"Delete"}
        onCancel={() => {
          onDeleteProduct();
          setIsOpenDeleteModel(false);
        }}
        closeModal={() => setIsOpenDeleteModel(false)}
      />
      <CreateCategoryModel
        type={modalType}
        isOpen={isOpenCategoryModal}
        closeModal={() => toggleCreateItem()}
      />
      <AddStockModel
        isOpen={isOpenStockItemModal}
        type={"STOCK_ITEM"}
        closeModal={() => setIsOpenStockItemModal(false)}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    shop: state.login.get("merchantShop"),
    warranty: state.warranty.get("warranty"),
    brandsList: state.product.get("brandsList"),
    currentUser: state.login.get("currentUser"),
    productTypes: state.product.get("productTypes"),
    loadingAction: state.common.get("loadingAction"),
    productImageId: state.product.get("productImageId"),
    selectedProduct: state.product.get("selectedProduct"),
    productWarranty: state.warranty.get("productWarranty"),
    productModifiers: state.product.get("productModifiers"),
    setProductData: state.product.get("createdProductData"),
    productCategoryList: state.product.get("productCategoryList"),
    productPriceOptions: state.product.get("productPriceOptions"),
    productPriceVariants: state.product.get("productPriceVariants"),
    selectedCategoryService: state.service.get("selectedCategoryService"),
  }),
  {
    createProduct: Actions.product.createProduct,
    updateProduct: Actions.product.updateProduct,
    deleteProduct: Actions.product.deleteProduct,
    createProductData: Actions.product.createProductData,
    uploadProductImage: Actions.product.uploadProductImage,
    removeProductImage: Actions.product.removeProductImage,
    setProductModifiers: Actions.product.setProductModifiers,
    setProductPriceVariants: Actions.product.setPriceVariants,
  }
)(ProductAddView);
