import React, {useEffect} from 'react';
import '../Styles.style.css';
import _, {isEmpty} from 'lodash';
import {connect} from "react-redux";
import {Row, Col} from 'react-grid-system';
import {Actions} from "../../../internal/app/Actions";
import EditIcon from '../../../../assets/svg/categoryEditIcon.svg';

const ProductCategoryTableView = (props) => {
  const {shop, productCategoryList, navigateEditProductCategoryView, fetchShopProductCategories, fetchShopProducts} = props;

  useEffect(() => {
    if (!isEmpty(shop.shopId)) {
      fetchShopProductCategories(shop.shopId);
    }
  }, [shop]);

  const navigateEditScreen = (category) => {
    // fetchShopProducts(shop.shopId);
    navigateEditProductCategoryView(category)
  };

  return (
    <div className="categoryTableContainer">
      <div className="tableHeaderContainer">
        <Row>
          <Col xs={3} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">
              Category
            </div>
          </Col>
          <Col xs={3} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">
              ID
            </div>
          </Col>
          <Col xs={3} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">
              Min Price(LKR)
            </div>
          </Col>
          <Col xs={2} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">
              # Products
            </div>
          </Col>
          <Col xs={1}/>
        </Row>
      </div>
      <div className="categoryTableBodyContainer">
        {
          productCategoryList.map((category) => {
            return (
              <div className="categoryTableBody">
                <Row>
                  <Col xs={3} className="categoryTableHeaderTextContainer">
                    <div className="categoryTableBodyText">
                      {category.categoryName}
                    </div>
                  </Col>
                  <Col xs={3} className="categoryTableHeaderTextContainer">
                    <div className="categoryTableBodyText">
                      {category.categoryId}
                    </div>
                  </Col>
                  <Col xs={3} className="categoryTableHeaderTextContainer">
                    <div className="categoryTableBodyText">
                      {_.get(category, 'minPrice', 0)}
                    </div>
                  </Col>
                  <Col xs={2} className="categoryTableHeaderTextContainer">
                    <div className="categoryTableBodyText">
                      {_.get(category, 'productCount', 0)}
                    </div>
                  </Col>
                  <Col xs={1} className="categoryTableHeaderTextContainer">
                    <div className="categoryEditIcon" onClick={() => navigateEditScreen(category)}>
                      <img src={EditIcon}/>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};
export default connect(
  state => ({
    productCategoryList: state.product.get('productCategoryList'),
    loadingAction: state.common.get('loadingAction'),
    shop: state.login.get('merchantShop'),
  }),
  {
    fetchShopProducts: Actions.product.fetchShopProducts,
    fetchShopProductCategories: Actions.product.fetchShopProductCategories,
    navigateEditProductCategoryView: Actions.product.navigateEditProductCategoryView
  }
)(ProductCategoryTableView);

