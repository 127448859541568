import _ from "lodash";
import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { ModuleEvents as ProfileEvents } from "../profile/Actions";
import { ModuleEvents as CommonEvents } from "../common/Actions";
import { ModuleEvents as CloseCashEvents } from "../closeCash/Actions";

const initialState = Map({
  loginIds: {},
  currentUser: {},
  accessToken: "",
  merchantShop: {},
  batchSession: {},
  progressCount: 10,
  isStopProgress: false,
  selectedLoginEmployee: {},
  openAuthenticationModelStatus: {
    isOpen: false,
    message: "",
  },
  trialLoading: true,
  trialDetails: null,
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.USER_LOGOUT: {
      if (payload) {
        return state
          .set("loginIds", {})
          .set("currentUser", {})
          .set("batchSession", {})
          .set("selectedLoginEmployee", {});
      }
      return state;
    }
    case ModuleEvents.GET_CURRENT_USER: {
      if (payload && !payload.error) {
        return state.set("currentUser", { ...payload });
      }
      return state;
    }
    case ModuleEvents.CREATE_BATCH: {
      if (payload) {
        return state.set("batchSession", { ...payload });
      }
      return state;
    }

    case ModuleEvents.SET_LOGIN_IDS: {
      if (payload && payload.shopId) {
        return state.set("loginIds", payload);
      }
      return state;
    }

    case ModuleEvents.SELECT_LOGIN_EMPLOYEE: {
      if (payload) {
        return state.set("selectedLoginEmployee", { ...payload });
      }
      return state;
    }

    case ProfileEvents.UPDATE_USER: {
      let currentUser = state.get("currentUser");
      if (payload) {
        currentUser.name = payload.name;
      }
      return state.set("currentUser", { ...currentUser });
    }

    case ProfileEvents.UPDATE_EMPLOYEE: {
      if (payload && !payload.error) {
        return state.set("currentUser", payload);
      }
      return state;
    }

    case ModuleEvents.SET_LOADING_PROGRESS: {
      return state.set("progressCount", payload);
    }

    case ModuleEvents.SET_ACCESS_TOKEN: {
      return state.set("accessToken", payload);
    }

    case ProfileEvents.UPDATE_SHOP:
    case ModuleEvents.GET_MERCHANT: {
      if (payload && payload.shopId) {
        return state.set("merchantShop", payload);
      }
      return state;
    }

    case ModuleEvents.MERCHANT_ONBOARD: {
      if (payload && payload.statusCode === 201) {
        return state.set("merchantShop", payload.data);
      }
      return state;
    }
    case ModuleEvents.MASTER_SIGNOUT: {
      return state.set("merchantShop", {});
    }
    case CloseCashEvents.GET_BATCH_SESSION: {
      if (payload && !payload.error) {
        return state.set("batchSession", payload);
      }
      return state;
    }

    case CloseCashEvents.CREATE_BATCH_SESSION: {
      if (payload && !payload.error) {
        return state.set("batchSession", payload);
      }
      return state;
    }

    case ModuleEvents.STOP_PROGRESS: {
      return state.set("isStopProgress", payload);
    }

    case ModuleEvents.OPEN_AUTHENTICATION_MODEL: {
      const { status, message } = payload;
      const openAuthenticationModelStatus = state.get(
        "openAuthenticationModelStatus"
      );
      openAuthenticationModelStatus.isOpen = status;
      openAuthenticationModelStatus.message = message;
      return state
        .set("openAuthenticationModelStatus", {
          ...openAuthenticationModelStatus,
        })
        .set("isStopProgress", false);
    }

    case ModuleEvents.FETCH_TRIAL: {
      if (payload && !payload.error) {
        return state.set("trialDetails", payload.trialDetails)
          .set('activeSubscriptions', payload.subscriptions);
      }
      return state.set("trialDetails", null).set('activeSubscriptions', []);
    }
    case CommonEvents.LOADING_STARTED: {
      if (payload && payload.type === 'FETCH_TRIAL') {
        return state.set("trialLoading", true);
      }
      return state;
    }
    case CommonEvents.LOADING_FINISHED: {
      if (payload && payload.type === 'FETCH_TRIAL') {
        return state.set("trialLoading", false);
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
