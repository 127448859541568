import React from 'react';
import {Col, Row} from 'react-grid-system';
import {
  OrderLineDetailValue,
  OrderLineDetailContainer,
  OrderLineDetailValueContainer,
  OrderLineDetailHeaderContainer,
  OrderLineDetailAddSubContainer,
  OrderLineDetailValueImageContainer,
  OrderLineDetailQtyHeaderContainer
} from "./Styled";
import IconDelete from '../../../../assets/svg/IconDelete.svg'
import IconPlus from "../../../../assets/svg/IconPlusSmall.svg";
import IconMinus from "../../../../assets/svg/IconMinusSmall.svg";
import {getCurrencyWithPrice} from "../../../internal/manager/PaymentManager";

const OrderLineDetailView = (props) => {
  const {items, onClickAdd, onClickSub, removeItem} = props;

  return (
    <OrderLineDetailContainer>
      <div>
        <Row style={{height: '100%', margin: 0}}>
          <Col md={6}>
            <OrderLineDetailHeaderContainer>
              <div>Item Name</div>
            </OrderLineDetailHeaderContainer>
          </Col>
          <Col md={2}>
            <OrderLineDetailQtyHeaderContainer>
              <div>Qty</div>
            </OrderLineDetailQtyHeaderContainer>
          </Col>
          <Col md={2}>
            <OrderLineDetailHeaderContainer flexEnd={true}>
              <div>Unit Price</div>
            </OrderLineDetailHeaderContainer>
          </Col>
          <Col md={2}>
            <OrderLineDetailHeaderContainer flexEnd={true}>
              <div>Price</div>
            </OrderLineDetailHeaderContainer>
          </Col>
        </Row>
      </div>

      <OrderLineDetailValueContainer>
        {
          items.map((data, index) => {
            const unitPrice = getCurrencyWithPrice(data.servicePrice || data.productSalePrice);
            const price = getCurrencyWithPrice(data.price);

            return (
              <div>
                <Row style={{height: '100%', margin: 0, minHeight: '60px'}}>
                  <Col md={6}>
                    <OrderLineDetailValueImageContainer>
                      <div onClick={()=> removeItem(data)}>
                        <img src={IconDelete}/>
                      </div>
                      <div>{data.serviceTitle || data.productName}</div>
                    </OrderLineDetailValueImageContainer>
                  </Col>
                  <Col md={2}>
                    <OrderLineDetailAddSubContainer>
                      <div onClick={() => onClickSub(data)}>
                        <img src={IconMinus}/>
                      </div>
                      <div>{data.quantity}</div>
                      <div onClick={() => onClickAdd(data)}>
                        <img src={IconPlus}/>
                      </div>
                    </OrderLineDetailAddSubContainer>
                  </Col>
                  <Col md={2}>
                    <OrderLineDetailValue>{unitPrice}</OrderLineDetailValue>
                  </Col>
                  <Col md={2}>
                    <OrderLineDetailValue>{price}</OrderLineDetailValue>
                  </Col>
                </Row>
                <div/>
              </div>
            )
          })
        }
      </OrderLineDetailValueContainer>
    </OrderLineDetailContainer>
  )
};
export default OrderLineDetailView;
