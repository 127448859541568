import React from "react";
import { ModuleEvents } from "./Actions";
import { Actions } from "../../internal/app/Actions";
import { navigate } from "../../internal/service/Navigation.service";
import UserRepository from "../../internal/repos/UserRepository";

export default {
  [ModuleEvents.ON_SUBSCRIPTION_EVENTS]: ({ dispatch, payload, appState }) => {
    if (payload) {
      switch (payload.event) {
        case "ONBOARD_PROGRESS_STARTED":
          navigate("/progress");
          break;
        case "ONBOARD_PROGRESS_COMPLETED":
          navigate("/login");
          break;
        case "ON_UPDATE_SERVICE_SUBSCRIPTION":
          dispatch(Actions.service.onServiceUpdateSubscription(payload.data));
          break;
        case "ON_CREATE_SERVICE_SUBSCRIPTION":
          dispatch(Actions.service.onServiceCreateSubscription(payload.data));
          break;
        case "ON_DELETE_SERVICE_SUBSCRIPTION":
          dispatch(Actions.service.onServiceDeleteSubscription(payload.data));
          break;
        case "ON_CREATE_EMPLOYEE_SUBSCRIPTION":
          dispatch(Actions.employee.onStylistCreateSubscription(payload.data));
          break;
        case "ON_UPDATE_EMPLOYEE_SUBSCRIPTION":
          dispatch(Actions.employee.onStylistUpdateSubscription(payload.data));
          break;
        default:
          return null;
      }
    }
  },
  [ModuleEvents.UPDATE_ALL_OFFLINE_ACTIONS]: ({
    dispatch,
    payload,
    appState,
  }) => {
    const loginIds = appState.login.get("loginIds");
    if (loginIds.shopId && loginIds.empId) {
      dispatch(Actions.login.getCurrentUser(loginIds.shopId, loginIds.empId));
    }
  },
  [ModuleEvents.SET_ONLINE]: async ({ dispatch, payload, appState }) => {
    await UserRepository.getCurrentWifi((data: any) => {
      dispatch(Actions.common.getCurrentConnection(data));
    });
  },
};
