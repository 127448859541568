import React, {useEffect, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import {connect} from 'react-redux';
import {Actions} from '../../../internal/app/Actions';
import _ from 'lodash';
import '../../Styles/ModelStyles.css';
import {CreateVehicleContainer} from '../../Styles/Styled';
import {nanoid} from "nanoid";
import Select from 'react-select';

const customStyles = {
  content: {
    top: '20%',
    left: 'calc(50% - 264px)',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const style = {
  control: (base, state) => ({
    ...base,
    height: '42px',
    width: '100%',
    paddingRight: "20px",
    border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5",
    '&:hover': {
      border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5"
    },
    borderRadius: "4px",
    color: "#00000085",
    fontSize: "14px",
    fontFamily: "Montserrat",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    paddingLeft: "5px"
  })
};

const vehicleTypeOptions = [
  {value: 'SUV', label: 'SUV'},
  {value: 'MOTORCYCLE', label: 'Motorcycle'},
  {value: 'VAN', label: 'VAN'},
];

const CreateVehicleModel = (props) => {
  const {isOpen, closeModal, isEdit, shop, createVehicle, selectedCustomer} = props;

  const [vehicleType, setVehicleType] = useState(vehicleTypeOptions[1]);
  const [brand, setBrand] = useState('');
  const [modal, setModal] = useState('');
  const [plateNumber, setPlateNumber] = useState('');
  const [mileage, setMileage] = useState('');

  const initialState = ()=> {
    setVehicleType(null);
    setBrand('');
    setModal('');
    setPlateNumber('');
    setMileage('');
  };

  const onClickCreateVehicle = () => {
    const params = {
      brand,
      modal,
      plateNumber,
      shopId: shop.shopId,
      vid: nanoid(8),
      mileage: parseFloat(mileage),
      clientId: selectedCustomer.id,
      vehicleType: vehicleType.value,
    };
    createVehicle(params);
    initialState();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.selectVehicleModal}
    >
      <CreateVehicleContainer>
        <div onClick={() => closeModal()}>
          <div>{isEdit ? 'Edit Vehicle' : 'Create Vehicle'}</div>
          <img src={CANCEL}/>
        </div>

        <div>{'Vehicle Information'}</div>

        <div>
          <div>
            <div>{'VEHICLE TYPE'}</div>
            <Select
              styles={style}
              value={vehicleType}
              options={vehicleTypeOptions}
              onChange={(data)=> setVehicleType(data)}
              placeholder={<div className="createVehiclePlaceholderText">Vehicle</div>}
            />
          </div>

          <div>
            <div>{'BRAND'}</div>
            <input
              placeholder="Honda, Audi..."
              value={brand}
              onChange={(number) => setBrand(number.target.value)}
            />
          </div>

          <div>
            <div>{'MODAL'}</div>
            <input
              placeholder="Hilux 2020, A5 2018..."
              value={modal}
              onChange={(number) => setModal(number.target.value)}
            />
          </div>

          <div>

            <div>
              <div>{'PLATE NUMBER'}</div>
              <input
                value={plateNumber}
                onChange={(number) => setPlateNumber(number.target.value)}
              />
            </div>

            <div>
              <div>{'Mileage'}</div>
              <input
                value={mileage}
                onChange={(number) => setMileage(number.target.value)}
              />
            </div>

          </div>

          <div>
            <button onClick={() => onClickCreateVehicle()}>
              <div>{'Create Vehicle'}</div>
            </button>
            <button onClick={() => closeModal()}>
              <div>Cancel</div>
            </button>
          </div>
        </div>
      </CreateVehicleContainer>
    </ReactModal>
  );
};
export default connect(
  (state) => ({
    shop: state.login.get('merchantShop'),
    currentUser: state.login.get('currentUser'),
    selectedCustomer: state.customer.get('selectedCustomer'),
  }),
  {
    createQuickCustomer: Actions.orders.createQuickCustomer,
  }
)(CreateVehicleModel);
