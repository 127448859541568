import React, { useEffect, useState } from "react";
import Actions from "../Actions";
import { Square } from "./Tables";
import { useDrop } from "react-dnd";
import DragItems from "./Constants";
import TableModal from "./TableModal";
import { connect } from "react-redux";
import { TableEditWrapper } from "./Styled";
import { cloneDeep, compact, concat, isArray } from "lodash";

const TableEditArea = ({ mode, tables, incompleteOrders }) => {
  const [areaTables, setAreaTables] = useState<any>([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [DragItems.SQUARE],
    drop: (item, monitor) => ({
      name: "Dustbin",
      offset: monitor.getClientOffset(),
      source: monitor.getSourceClientOffset(),
      isOver: monitor.isOver({ shallow: true }),
    }),
    canDrop: (item, monitor) => {
      // console.log(monitor.isOver({ shallow: true }));
      return monitor.isOver({ shallow: true });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  }));

  useEffect(() => {
    if (!isArray(tables)) {
      return;
    }
    // const orderRemovedTables = tables.map(({ order, ...tb }) => tb);

    let orderTables = cloneDeep(tables);
    if (isArray(tables) && isArray(incompleteOrders)) {
      incompleteOrders.forEach((order) => {
        if (order.tableOrder) {
          const orderTbId = order.tableOrder.tbId;
          // const index = orderRemovedTables.findIndex(
          //   ({ tbId }) => tbId === orderTbId
          // );
          const index = orderTables.findIndex(({ tbId }) => tbId === orderTbId);
          if (index > -1) {
            orderTables[index]["orders"] = compact(
              concat(orderTables[index]?.orders, [order])
            );
            // orderRemovedTables[index] = {
            //   ...orderRemovedTables[index],
            //   order,
            // };
          }
        }
      });
    }
    // setAreaTables([...orderRemovedTables]);
    setAreaTables([...orderTables]);
  }, [tables, incompleteOrders]);
  // const tables = tableAreas.tableAreas[index].tables;

  return (
    <TableEditWrapper
      className={`table-edit-wrapper ${mode}`}
      isEdit={mode === "edit"}
    >
      {areaTables.map((table) => {
        return (
          <Square
            table={table}
            onEdit={setSelectedTable}
            key={`${table.tbId}-${table.tbNumber}`}
          />
        );
      })}
      <TableModal
        isOpen={!!selectedTable}
        closeModal={() => setSelectedTable(null)}
        selectedTable={selectedTable}
      />
    </TableEditWrapper>
  );
};

export default connect(
  (state: any) => ({
    mode: state.table.get("mode"),
    tables: state.table.get("tables"),
    tableAreas: state.table.get("tableAreas"),
    incompleteOrders: state.orders.get("unCompletedAppointments"),
  }),
  {
    editTables: Actions.editTables,
  }
)(TableEditArea);
