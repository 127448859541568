import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { connect } from "react-redux";
import EditIcon from "../../../../assets/svg/editTable.png";
import EditIconDark from "../../../../assets/svg/TableEditIconDark.svg";
import { Rnd } from "react-rnd";
import { Resizer, RoomSquareWrapper } from "./Styled";
import BaseColors from "../../../internal/helpers/BaseColors";
import { get, isEmpty } from "lodash";
import { Actions } from "../../../internal/app/Actions";
import moment from "moment";

export const Square = connect(
  (state: any) => ({
    rooms: state.rooms.get("rooms"),
    mode: state.rooms.get("roomMode"),
    roomAreas: state.rooms.get("roomAreas"),
    currentUser: state.login.get("currentUser"),
    selectedArea: state.rooms.get("selectedArea"),
  }),
  {
    editRooms: Actions.rooms.editRooms,
    createRoomOrder: Actions.rooms.createRoomOrder,
    selectedAppointment: Actions.orders.selectedAppointment,
    clearCurrentAppointment: Actions.payment.clearCurrentAppointment,
  }
)(
  ({
    mode,
    room,
    onEdit,
    editRooms,
    roomAreas,
    currentUser,
    selectedArea,
    createRoomOrder,
    selectedAppointment,
    clearCurrentAppointment,
  }) => {

    const updateTable = (ref, drag) => {
      const index = roomAreas.roomAreas.findIndex(
        (rm) => rm.areaId === selectedArea
      );

      if (index > -1) {
        const rooms = roomAreas.roomAreas[index].rooms;
        const rmIndex = rooms.findIndex((t) => t.rmId === room.rmId);

        if (rmIndex > -1) {
          if (ref) {
            room.w = parseFloat(ref.style.width);
            room.h = parseFloat(ref.style.height);
          }
          room.x = parseFloat(drag.x);
          room.y = parseFloat(drag.y);
          rooms[rmIndex] = room;
        }

        roomAreas.roomAreas[index].rooms = rooms;
        editRooms({ ...roomAreas });
      }
    };

    const gridMode = get(currentUser, "viewMode", "GRID") === "GRID";

    const onSelectOrder = (data) => {
      clearCurrentAppointment(true, {
        navigate: gridMode ? "/product" : "/order-list",
      });
      setTimeout(() => {
        selectedAppointment(data);
      }, 200);
    };

    const isFree = isEmpty(room.order);
    const orderTable = isFree
      ? BaseColors.tableFree
      : BaseColors.cornflowerBlue;
    const tableColor =
      room.rmType === "ACTIVE" ? orderTable : BaseColors.gunsmoke;

    const date = !isEmpty(room?.order) ? moment(room?.order?.date).format("dddd") : '';

    return (
      <Rnd
        bounds=".table-edit-wrapper"
        default={{
          x: 0,
          y: 0,
          width: 100,
          height: 100,
        }}
        disableDragging={mode !== "edit"}
        size={{ width: room.w, height: room.h }}
        position={{ x: room.x, y: room.y }}
        minWidth={100}
        minHeight={100}
        style={{
          background: tableColor,
          borderRadius: room.rmShape === "ROUND" ? "100%" : "unset",
        }}
        onDragStop={(e, d) => {
          updateTable(null, d);
          // this.setStaxte({ x: d.x, y: d.y });
        }}
        enableResizing={mode === "edit"}
        onResizeStop={(e, direction, ref, delta, position) => {
          updateTable(ref, position);
          // this.setState({
          //   width: ref.style.width,
          //   height: ref.style.height,
          //   ...position,
          // });
        }}
      >
        <RoomSquareWrapper
          isRound={room.rmShape === "ROUND"}
          disabled={room.rmType !== "ACTIVE"}
          onClick={() => {
            if (mode !== "edit") {
              if (room.order) {
                onSelectOrder(room.order);
              } else {
                createRoomOrder(room);
              }
            }
          }}
          // style={{ left: table.x, top: table.y }}
          // ref={(el) => drop(drag(el))}
        >
          {/* <div>
            {mode === "edit" ? (
              <img
                alt=""
                src={isFree ? EditIconDark : EditIcon}
                onClick={() => {
                  onEdit(room);
                  // setEdit(areaId);
                  // setOpen(true);
                }}
              />
            ) : null}
          </div> */}
          <div className={`table-number ${isFree ? "dark" : ""}`}>
            {mode === "edit" ? (
              <>
                <img
                  alt=""
                  src={isFree ? EditIconDark : EditIcon}
                  onClick={() => {
                    onEdit(room);
                    // setEdit(areaId);
                    // setOpen(true);
                  }}
                />
                <br />
              </>
            ) : null}
            {room.rmNumber}
          </div>
          {date}
          {/* <div style={{ width: "100px", height: "100px", position: "relative" }}> */}
          {/* <Resizer
            onMouseEnter={() => setResize(true)}
            onMoseLeave={() => setResize(false)}
          /> */}
          {/* </div> */}
        </RoomSquareWrapper>
      </Rnd>
    );
  }
);
