import React from 'react';
import styles from '../../Styles/Styles.css';

const StockSubHeader = (props) => {
  const {header, selectedSidebar, onClear} = props;

  return (
    <div className={styles.serviceSubHeaderContainer}>
      <div className={styles.subHeaderWrapper}>
        <div className={styles.subHeaderNewText}>{header}</div>
      </div>
      <div onClick={() => onClear()} className={styles.subHeaderAddCategoryBtn}>
        <div className={styles.subHeaderAddCategoryBtnText}>
          {selectedSidebar === "STOCK_ITEMS" ? "Add Stock" : "Add Stock Record"}
        </div>
      </div>
    </div>
  );
};
export default StockSubHeader;
