import React, { ReactElement } from "react";
import { Col, Container, Row } from "react-grid-system";
import ServiceSubHeader from "../headers/Views/ServiceSubHeader";
import EditProductItemTopHeader from "../product/views/EditProductItemTopHeader";
import SideNavBar from "../sideNavBars/views/SideNavBar";
import TopNavigationBar from "../TopNavigation/Views/TopNavigationBar";
import { SideBarLayoutWrapper } from "./Styled";

const SideBarLayout = ({
  header,
  title,
  buttonText,
  onClickButton,
  sidebar = "ROOMS",
  path,
  options,
  selectedTab,
  onClickTab,
  children,
}: {
  header: string;
  buttonText: string;
  onClickButton: () => void;
  sidebar: "ROOMS";
  title: string;
  path: string;
  options: Array<any>;
  selectedTab: string;
  onClickTab: (type: string) => void;
  children: ReactElement;
}) => {
  return (
    <SideBarLayoutWrapper>
      <TopNavigationBar viewName={title} path={path} />
      <Container fluid >
        <Row>
          <Col xs={12}>
            <ServiceSubHeader
              header={header}
              buttonText={buttonText}
              selectedSidebar={sidebar}
              onClear={() => onClickButton()}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={2} style={{ paddingRight: 0 }}>
            <SideNavBar
              options={options}
              selectedSidebar={selectedTab}
              onClick={(type) => onClickTab(type)}
            />
          </Col>
          <Col xs={10} style={{ paddingLeft: 0 }}>
            {children}
          </Col>
        </Row>
      </Container>
    </SideBarLayoutWrapper>
  );
};

export default SideBarLayout;
