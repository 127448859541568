import React, { useEffect, useState } from "react";
import _ from "lodash";
import { nanoid } from "nanoid";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import "../../Styles/ModelStyles.css";
import styles from "../../Styles/ModelStyles.css";
import { Actions } from "../../../internal/app/Actions";
import CANCEL from "../../../../assets/icons/cancel.svg";
import { AddDiscountContainer } from "../../Styles/Styled";
import { getAppointmentTotalPrice } from "../../../internal/manager/PaymentManager";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 265px)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const AddDiscountModal = (props) => {
  const {
    shop,
    isOpen,
    closeModal,
    orderOffers,
    discountItem,
    purchaseProducts,
    purchaseServices,
    appointmentDiscounts,
    setAppointmentDiscount,
  } = props;
  let purchaseList = purchaseServices.concat(purchaseProducts);
  let { total: totalPrice, serviceCharge } = getAppointmentTotalPrice(
    purchaseList,
    appointmentDiscounts,
    shop,
    orderOffers
  );
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [amount, setAmount] = useState("0");
  const [percentage, setPercentage] = useState("0.00");

  const onChangeTitle = (text) => {
    setTitle(text);
  };

  useEffect(() => {
    if (discountItem?.title) {
      setTitle(discountItem.title);
      setAmount(discountItem.amount);
      setPercentage(discountItem.percentage);
    } else {
      setTitle("");
      setAmount("0");
      setPercentage("0.00");
    }
  }, [discountItem]);

  const onChangeAmount = (text: string) => {
    if (!_.isEmpty(text)) {
      const amount = parseFloat(text);
      if (
        isNaN(text) ||
        isNaN(amount) ||
        amount === Infinity ||
        amount > totalPrice
      ) {
        setError(`Maximum discount: ${totalPrice}`);
        return;
      }
      setAmount(text.replace(/^0+/, ""));
      const percentageValue = ((amount / totalPrice) * 100).toFixed(2);
      if (percentageValue !== "Infinity" && percentageValue !== "NaN") {
        setPercentage(percentageValue);
      } else {
        setPercentage("0.00");
      }
      setError("");
    } else {
      setAmount("0");
      setPercentage("0.00");
      setError("");
    }
  };

  const onChangePercentage = (text) => {
    setPercentage(text);
    if (!_.isEmpty(text)) {
      const amountValue = ((parseFloat(text) * totalPrice) / 100).toFixed(2);
      setAmount(amountValue);
    } else {
      setAmount(0);
    }
  };

  const validate = () => {
    return (
      title.length !== 0 && parseFloat(amount) > 0 && parseFloat(percentage) > 0
    );
  };

  const onClickAddDiscount = () => {
    const params = {
      id: discountItem?.id || nanoid(5),
      title,
      amount: parseFloat(amount),
      percentage: parseFloat(percentage),
    };
    setAppointmentDiscount([params]);
    closeModal();
    setTitle("");
    setAmount(0);
    setPercentage("0.00");
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.addDiscountModal}
    >
      <AddDiscountContainer disabled={!validate()}>
        <div onClick={() => closeModal()}>
          <div>{"Discount Details"}</div>
          <img src={CANCEL} />
        </div>

        <div>
          <div>
            <div>{"DISCOUNT TITLE"}</div>
            <input
              value={title}
              placeholder={"Order Discount"}
              onChange={(number) => onChangeTitle(number.target.value)}
            />
          </div>

          <div>
            <div>
              <div>{"DISCOUNT AMOUNT"}</div>
              <input
                value={amount}
                onChange={(number) => onChangeAmount(number.target.value)}
              />
            </div>

            <div>
              <div>{"DISCOUNT %"}</div>
              <input
                value={percentage}
                onChange={(number) => onChangePercentage(number.target.value)}
              />
            </div>
          </div>
          <div>
            <span>{error}</span>
          </div>

          <div>
            <button disabled={!validate()} onClick={() => onClickAddDiscount()}>
              <div>Add Discount</div>
            </button>
            <button onClick={() => closeModal()}>
              <div>Cancel</div>
            </button>
          </div>
        </div>
      </AddDiscountContainer>
    </ReactModal>
  );
};
export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    orderOffers: state.offer.get("orderOffers"),
    purchaseProducts: state.product.get("purchaseProducts"),
    purchaseServices: state.service.get("purchaseServices"),
    choossedProductItem: state.product.get("choossedProductItem"),
    appointmentDiscounts: state.product.get("appointmentDiscounts"),
  }),
  {
    setAppointmentDiscount: Actions.product.setAppointmentDiscount,
  }
)(AddDiscountModal);
