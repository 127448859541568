import React from 'react';
import {ModuleEvents} from './Actions';
import {navigate} from "../../internal/service/Navigation.service";
import swal from 'sweetalert';
import {Actions} from "../../internal/app/Actions";

const Handlers = {
  [ModuleEvents.CREATE_STOCK_RECORD]: ({dispatch, payload, appState}) => {
    const shop = appState.login.get('merchantShop');
    if(payload && !payload.error) {
      dispatch(Actions.stock.fetchStockRecord(shop.shopId));
      navigate('/stock-customized');
    }
    dispatch(Actions.stock.clearSelectedStockRecord());
  },
  [ModuleEvents.UPDATE_STOCK_RECORD]: ({dispatch, payload, appState}) => {
    const shop = appState.login.get('merchantShop');
    if(payload && !payload.error) {
      dispatch(Actions.stock.fetchStockRecord(shop.shopId));
      navigate('/stock-customized');
    }
    dispatch(Actions.stock.clearSelectedStockRecord());
  },
  [ModuleEvents.NAVIGATE_EDIT_STOCK_RECORD]: ({dispatch, payload, appState}) => {
    navigate("/add-stock-record");
  },
  [ModuleEvents.DELETE_STOCK_RECORD]: ({dispatch, payload, appState}) => {
    const shop = appState.login.get('merchantShop');
    if(payload && !payload.error) {
      dispatch(Actions.stock.fetchStockRecord(shop.shopId));
      navigate('/stock-customized');
    }
    dispatch(Actions.stock.clearSelectedStockRecord());
  },
};

export default Handlers;


