import React, {Component} from 'react';
import styles from '../../../components/Common/Style/Styles.css';
import {Container, Row, Col} from 'react-grid-system';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import ServiceSubHeader from '../../../components/headers/Views/ServiceSubHeader';
import UserInformation from '../../../components/profile/views/UserInformation';
import {connect} from "react-redux/es/index";
import {Actions} from "../../../internal/app/Actions";
const {innerWidth, innerHeight} = window;

const Profile = (props) => {
  return (
    <div style={{backgroundColor: '#EFF3F6', height: window.innerHeight}}>
      <Row>
        <Col xs={12}>
          <TopNavigationBar viewName="Profile" path="/menu"/>
        </Col>
      </Row>

      <div className={styles.profileWrapper}>
        <div style={{
          backgroundColor: '#FFFFFF',
          height: window.innerHeight - 100,
          width: '100%',
          borderTopRightRadius: '3px',
          borderTopLeftRadius: '3px'
        }}>
          <Row>
            <Col xs={12}>
              <ServiceSubHeader header={'Profile settings'}/>
            </Col>
          </Row>

          <Container className={styles.subHeaderContainer} fluid>
            <Row>
              <Col xs={12}>
                <UserInformation/>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};
export default connect(
  state => ({
    currentUser: state.login.get('currentUser'),
  }),
  ({})
)(Profile);
