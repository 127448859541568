import _ from "lodash";
import { nanoid } from "nanoid";
import {
  toLowerCase,
  setValueToLocalStorage,
  deleteCurrentStorageData,
  getValueFromLocalStorage,
  updateCurrentStorageData,
} from "../manager/CommonManager";
import Repository from "./Repository";
import { isNetworkError } from "../manager/AppointmentManager";

class CustomerRepository extends Repository {
  fetchCustomers = async (
    shopId,
    limit = 100,
    lastKey = null,
    retryCount = 1
  ) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("MERCHANT_CLIENT");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }

      const params = {
        shopId,
        endpointType: "MERCHANT_CLIENT",
        limit,
        lastKey,
      };
      const message = this.buildMessage(params);

      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: "/clients",
        message,
      });
      await setValueToLocalStorage(
        "MERCHANT_CLIENT",
        JSON.stringify(result.clientResult)
      );

      return result.clientResult;
    } catch (error) {
      console.warn("error", error);
      if (error.toJSON().message === "Network Error" && retryCount <= 3) {
        return await this.fetchCustomers(shopId, 100, null, ++retryCount);
      }
      return { error };
    }
  };

  searchCustomers = async (shopId, searchText, limit, lastKey) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("MERCHANT_CLIENT");
        if (list) {
          const customerResult = JSON.parse(list);
          const filterList = _.filter(
            customerResult.Items,
            (item) =>
              item.type !== "STORE_CUSTOMER" &&
              (toLowerCase(item.name).includes(toLowerCase(searchText)) ||
                toLowerCase(item.email).includes(toLowerCase(searchText)) ||
                item.mobileNumber.includes(searchText))
          );
          return filterList;
        }

        return [];
      }

      const message = this.buildMessage({ shopId, searchText, limit, lastKey });

      const result = await this.apiPost({
        apiName: this.DASHBOARD_API_V2,
        path: "/client/searchClient",
        message,
      });
      return result.clientList;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  };

  searchCustomerForShopId = async (shopId, searchText, limit, lastKey) => {
    const params = {
      shopId,
      searchText,
      endpointType: "SEARCH_MERCHANT_CLIENT",
      limit,
      lastKey,
    };
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: "/clients",
        message,
      });

      return result.clientResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  };

  async createCustomer(param) {
    param.id = nanoid(16);
    param.createdTime = Date.now();
    param.updatedTime = Date.now();
    const message = this.buildMessage(param);
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "MERCHANT_CLIENT",
          param,
          param.id,
          "id"
        );
        return { data: newParam, code: "SUCCESSFULLY_CREATED" };
      }

      const result = await this.apiPost({
        apiName: this.DASHBOARD_API_V2,
        path: "/client",
        message,
      });
      return result.response;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  updateCustomer = async (params, retryCount = 1) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "MERCHANT_CLIENT",
          params,
          params.id,
          "id",
          true
        );
        return newParam;
      }

      params.updatedTime = Date.now();
      const message = this.buildMessage(params);

      const result = await this.apiPut({
        apiName: this.DASHBOARD_API_V2,
        path: "/client",
        message,
      });
      return result.response.data;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.updateCustomer(params, ++retryCount);
      }
      return { error };
    }
  };

  deleteCustomer = async (id, shopId, retryCount = 1) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await deleteCurrentStorageData(
          "MERCHANT_CLIENT",
          { id, shopId },
          id,
          "id"
        );
        return newParam;
      }

      const message = this.buildMessage({ id, shopId });

      const result = await this.apiDelete({
        apiName: this.DASHBOARD_API_V2,
        path: "/client",
        message,
      });
      return result.clientResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteCustomer(id, shopId, ++retryCount);
      }
      return { error };
    }
  };

  async createQuickCustomer(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "MERCHANT_CLIENT",
          param,
          param.id,
          "id"
        );
        return newParam;
      }
      const message = this.buildMessage(param);

      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: "/quick-customer",
        message,
      });
      return result.customerResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createQuickCustomer(param, ++retryCount);
      }
      return { error };
    }
  }

  async removeQuickCustomer(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await deleteCurrentStorageData(
          "MERCHANT_CLIENT",
          { id: param.id, shopId: param.shopId },
          param.id,
          "id"
        );
        return newParam;
      }

      const message = this.buildMessage(param);

      const result = await this.apiDelete({
        apiName: this.DASHBOARD_API_V2,
        path: "/client",
        message,
      });
      return result.clientResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.removeQuickCustomer(param, ++retryCount);
      }
      return { error };
    }
  }
}

export default new CustomerRepository();
