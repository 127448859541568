import React, { useState } from "react";
import _ from "lodash";
import {
  SplitBillLine,
  CheckRowWrapper,
  SplitBillPriceCard,
  SplitBillTotalPrice,
  SplitBillButtonWrapper,
  SplitBillDetailsWrapper,
  PaymentDetailsTextMessageWrapper,
} from "../Styles/Styled";
import { nanoid } from "nanoid";
import { connect } from "react-redux";
import {
  getTotalPrice,
  getSplitTotal,
  getCurrencyWithPrice,
  getCashPaymentValues,
  getAppointmentTotalPrice,
  mapPaymentDetails,
} from "../../internal/manager/PaymentManager";
import { useIntl } from "react-intl";
import { Checkbox } from "@material-ui/core";
import { Actions } from "../../internal/app/Actions";
import IconDelete from "../../../assets/svg/IconPriceDelete.svg";
import { navigate } from "../../internal/service/Navigation.service";
import PrinterRepository from "../../internal/repos/PrinterRepository";
import IconBlueCheck from "../../../assets/svg/IconRegisterSelect.svg";
import IconRegisterUnselect from "../../../assets/svg/IconRegisterUnselect.svg";

const SplitBillDetails = (props) => {
  const {
    shopData,
    checkout,
    orderOffers,
    currentUser,
    batchSession,
    quickCustomer,
    selectedTable,
    selectedRoom,
    loadingAction,
    splitPayments,
    selectedVehicle,
    selectedEmployee,
    selectedCustomer,
    purchaseServices,
    purchaseProducts,
    createActivityLog,
    selectedAppointment,
    updateSplitPayments,
    appointmentWarranty,
    appointmentDiscounts,
    appointmentDateAndTime,
  } = props;
  const [isCash, setIsCash] = useState(true);
  const [isCard, setIsCard] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [isClickComplete, setIsClickComplete] = useState(false);
  const [isClickSendSms, setIsClickSendSms] = useState(false);
  const [isPrintReciept, setPrintReciept] = useState(true);
  const { formatMessage: f } = useIntl();

  let purchaseList = purchaseServices.concat(purchaseProducts);
  let { total: totalPrice, serviceCharge } = getAppointmentTotalPrice(
    purchaseList,
    appointmentDiscounts,
    shopData,
    orderOffers
  );
  let price = getCurrencyWithPrice(totalPrice, shopData.currency);
  const totalSplitPayments = getSplitTotal(splitPayments);

  const printBill = async (total, params) => {
    const subTotal = getTotalPrice([...purchaseServices, ...purchaseProducts]);
    // const imageData = await axios.get(generateImageUrl(shopData.image), {responseType: 'arraybuffer'});
    // let image = null;
    // if (imageData.data) {
    //   image = 'data:image/png;base64,' + Buffer.from(imageData.data, 'binary').toString('base64');
    // }
    try {
      await PrinterRepository.printReciept({
        params,
        // image,
        selectedVehicle,
        selectedCustomer,
        purchaseProducts,
        purchaseServices,
        givenPrice: totalSplitPayments,
        shopData,
        total,
        subTotal,
        appointmentDiscounts,
      });
    } catch (e) {
      // Error message
      console.warn({ e });
    }
  };

  const validateButton = () => {
    if (isCard) {
      return true;
    }
    return parseFloat(totalSplitPayments) >= totalPrice && totalPrice > 0;
  };

  const onCheckout = async (totalPrice, serviceCharge) => {
    if (validateButton() && !loadingAction?.loading) {
      setIsClickComplete(true);
      const params = {
        shopData,
        totalPrice,
        selectedVehicle,
        purchaseServices,
        selectedEmployee,
        purchaseProducts,
        status: "COMPLETED",
        selectedAppointment,
        paymentType: "SPLIT",
        isSendSms: isClickSendSms,
        userId: currentUser.empId,
        offerDetails: orderOffers,
        batchId: batchSession.batchId,
        discounts: appointmentDiscounts,
        paidPrice: totalSplitPayments,
        date: _.get(appointmentDateAndTime, "date"),
        time: _.get(appointmentDateAndTime, "time"),
        recieptId: selectedAppointment.recieptId || nanoid(5),
        cardDetails: { last4: cardNumber, name: cardHolderName },
        serviceCharge: {
          amount: `${serviceCharge}`,
        },
        splitPayments,
        tableOrder: selectedTable?.tbId
          ? {
              tbId: selectedTable.tbId,
              areaId: null,
              tbNumber: selectedTable.tbNumber,
            }
          : null,

        roomOrder: selectedRoom?.rmId ? { ...selectedRoom } : null,
        selectedCustomer: !_.isEmpty(selectedCustomer)
          ? selectedCustomer
          : quickCustomer,
      };
      const orderData = await mapPaymentDetails(params);
      const activityLog = {
        logId: nanoid(),
        actType: "CHECKOUT",
        shopId: shopData.shopId,
        createdTime: Date.now(),
        updatedTime: Date.now(),
        empId: selectedEmployee.empId,
        metaData: [
          {
            key: "ORDER",
            value: orderData,
          },
          {
            key: "EMPLOYEE",
            value: selectedEmployee,
          },
        ],
      };
      createActivityLog(activityLog);
      // updateVehicle(selectedVehicle);
      checkout(params);
      if (isPrintReciept) {
        printBill(totalPrice, params);
      }
    }
  };

  const toggleCash = () => {
    setIsCash(!isCash);
    setIsCard(false);
  };

  const toggleCard = () => {
    setIsCard(!isCard);
    setIsCash(false);
  };

  const onClickSendSms = () => {
    setIsClickSendSms(!isClickSendSms);
  };

  let isValid = validateButton();

  const cashValues = getCashPaymentValues(totalPrice);

  return (
    <SplitBillDetailsWrapper>
      <div>
        <div>{price}</div>
        <div>
          {splitPayments.map((item, index) => {
            const paymentType = item.method === "CARD" ? "Card" : "Cash";
            return (
              <SplitBillPriceCard>
                <div>{paymentType}</div>
                <div>
                  <div>
                    {getCurrencyWithPrice(item.amount, shopData.currency)}
                  </div>
                  <button
                    onClick={() => {
                      splitPayments.splice(index, 1);
                      updateSplitPayments(splitPayments);
                    }}
                  >
                    <img src={IconDelete} />
                  </button>
                </div>
              </SplitBillPriceCard>
            );
          })}
          <SplitBillLine />
        </div>
        <SplitBillTotalPrice isBold={true}>
          <div>Total Balance</div>
          <div>
            {getCurrencyWithPrice(
              totalPrice - totalSplitPayments > 0
                ? totalPrice - totalSplitPayments
                : 0,
              shopData.currency
            )}
          </div>
        </SplitBillTotalPrice>
        <SplitBillLine />

        <SplitBillTotalPrice isBold={false}>
          <div>{"Change"}</div>
          <div>
            {getCurrencyWithPrice(
              totalPrice - totalSplitPayments < 0
                ? totalSplitPayments - totalPrice
                : 0,
              shopData.currency
            )}
          </div>
        </SplitBillTotalPrice>
        <CheckRowWrapper>
          <PaymentDetailsTextMessageWrapper
            onClick={() => setPrintReciept(!isPrintReciept)}
          >
            <Checkbox
              checked={isPrintReciept}
              checkedIcon={<img src={IconBlueCheck} alt="" />}
              icon={<img src={IconRegisterUnselect} alt="" />}
              onChange={() => setPrintReciept(!isPrintReciept)}
            />
            <div>Print Reciept</div>
          </PaymentDetailsTextMessageWrapper>
        </CheckRowWrapper>
      </div>

      <SplitBillButtonWrapper isValid={totalSplitPayments >= totalPrice}>
        <div onClick={() => navigate("/checkout")}>
          <div>{f({ id: "CHECKOUT.CANCEL" })}</div>
        </div>
        <div onClick={() => onCheckout(totalPrice, serviceCharge)}>
          <div>{f({ id: "CHECKOUT.COMPLETE_ORDER" })}</div>
        </div>
      </SplitBillButtonWrapper>
    </SplitBillDetailsWrapper>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
    orderOffers: state.offer.get("orderOffers"),
    selectedRoom: state.room.get("selectedRoom"),
    batchSession: state.login.get("batchSession"),
    selectedTable: state.table.get("selectedTable"),
    loadingAction: state.common.get("loadingAction"),
    splitPayments: state.payment.get("splitPayments"),
    quickCustomer: state.customer.get("quickCustomer"),
    selectedVehicle: state.orders.get("selectedVehicle"),
    purchaseProducts: state.product.get("purchaseProducts"),
    purchaseServices: state.service.get("purchaseServices"),
    selectedEmployee: state.employee.get("selectedEmployee"),
    selectedCustomer: state.customer.get("selectedCustomer"),
    selectedAppointment: state.orders.get("selectedAppointment"),
    appointmentDiscounts: state.product.get("appointmentDiscounts"),
    appointmentWarranty: state.product.get("appointmentWarranty"),
    appointmentDateAndTime: state.salon.get("appointmentDateAndTime"),
  }),
  {
    checkout: Actions.payment.checkout,
    createActivityLog: Actions.activityLog.createActivityLog,
    updateSplitPayments: Actions.payment.updateSplitPayments,
  }
)(SplitBillDetails);
