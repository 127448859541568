import OrderCommand from "./OrderCommand";

class Syncer {
  commands = [new OrderCommand()];

  async sync() {
    await Promise.all(
      this.commands.map(async (command) => await command.execute())
    );
  }
}

export default Syncer;
