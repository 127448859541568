import React, {useEffect, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import {connect} from 'react-redux';
import {Actions} from '../../../internal/app/Actions';
import _ from 'lodash';
import '../../Styles/ModelStyles.css';
import {QuickCustomerContainer} from '../../Styles/Styled';
import {nanoid} from "nanoid";
import { useIntl } from 'react-intl';

const customStyles = {
  content: {
    top: '20%',
    left: 'calc(50% - 264px)',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const QuickCustomerModel = (props) => {
  const {isOpen, closeModal, isEdit, shop, createQuickCustomer, currentUser, quickCustomer} = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const {formatMessage: f} = useIntl();

  useEffect(() => {
    setFirstName("");
    setLastName("");
  }, [quickCustomer]);

  const onClickCreateQuickCustomer = () => {
    const params = {
      shopId: shop.shopId,
      type: 'STORE_CUSTOMER',
      createdUser: currentUser.empId,
      createStatus: isEdit ? 'UPDATE' : 'CREATE',
      id: isEdit ? quickCustomer.id : nanoid(),
      firstName: _.isEmpty(firstName) ? 'Quick' : firstName,
      lastName: _.isEmpty(lastName) ? 'Customer' : lastName,
    };
    createQuickCustomer(params);
    setFirstName('');
    setLastName('');
    closeModal();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.quickCustomerModel}
    >
      <QuickCustomerContainer>
        <div onClick={() => closeModal()}>
          <div>{isEdit ? f({id: "CUSTOMER.QUICK.EDIT_CUSTOMER"}) : f({id: "CUSTOMER.QUICK.CREATE_CUSTOMER"})}</div>
          <img src={CANCEL}/>
        </div>

        <div>{f({id: "CUSTOMER.QUICK.CUSTOMER_INFO"})}</div>

        <div>
          <div>
            <div>{f({id: "CUSTOMER.QUICK.FIRST_NAME"})}</div>
            <input
              value={firstName}
              placeholder="John"
              onChange={(number) => setFirstName(number.target.value)}
            />
          </div>

          <div>
            <div>{f({id: "CUSTOMER.QUICK.LAST_NAME"})}</div>
            <input
              value={lastName}
              placeholder="Doe"
              onChange={(number) => setLastName(number.target.value)}
            />
          </div>
          <div>{f({id: "CUSTOMER.QUICK.DESCRIPTION"})}</div>

          <div>
            <button onClick={() => onClickCreateQuickCustomer()}>
              <div>{isEdit ? f({id: "CUSTOMER.QUICK.EDIT"}) : f({id: "CUSTOMER.QUICK.ADD_CUSTOMER"})}</div>
            </button>
            <button onClick={() => closeModal()}>
              <div>{f({id: "CUSTOMER.QUICK.CANCEL"})}</div>
            </button>
          </div>
        </div>
      </QuickCustomerContainer>
    </ReactModal>
  );
};
export default connect(
  (state) => ({
    shop: state.login.get('merchantShop'),
    currentUser: state.login.get('currentUser'),
    quickCustomer: state.customer.get('quickCustomer'),

  }),
  {
    createQuickCustomer: Actions.customer.createQuickCustomer,
  }
)(QuickCustomerModel);
