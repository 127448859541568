import React, { useEffect, useState } from "react";
import {
  ProductOptionForm,
  ProductOptionInput,
  AddOptionSetWrapper,
  ProductOptionWrapper,
  AddOptionButtonWrapper,
  ProductOptionFormWrapper,
  ProductOptionItemWrapper,
  ProductOptionNameWrapper,
  ProductOptionCreateInput,
  ProductOptionHeaderWrapper,
} from "./Styled";
import "antd/dist/antd.css";
import "../Styles.style.css";
import { nanoid } from "nanoid";
import { ProductOptions } from "../Types";
import { connect } from "react-redux/es/index";
import _, { find, get, isEmpty } from "lodash";
import CreatableSelect from "react-select/creatable";
import { Actions } from "../../../internal/app/Actions";
import BackIcon from "../../../../assets/svg/backIcon.svg";
import IconPlus from "../../../../assets/svg/icon-plus.svg";
import CloseIcon from "../../../../assets/images/closeIcon.png";
import { navigate } from "../../../internal/service/Navigation.service";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";

import * as Yup from "yup";
import { Formik, FieldArray } from "formik";

const style = {
  container: (base: any) => ({
    ...base,
    width: "544px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
  }),
  singleValue: (base: any) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
  }),
  placeholder: (base: any) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
    color: "#979797",
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
  }),
  control: (base: any, state: any) => ({
    ...base,
    height: "45px",
    fontWeight: 400,
    fontSize: "14px",
    maxWidth: "544px",
    color: "#434343",
    paddingLeft: "23px",
    borderRadius: "6px",
    paddingRight: "23px",
    fontFamily: "Montserrat",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    "&:hover": {
      border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    },
  }),
};

const OptionSchema = Yup.object({
  productOptions: Yup.array().of(
    Yup.object().shape({
      setName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Please enter option set name*"),
      setDisplayName: Yup.string()
        .min(2, "Must be 2 characters or more")
        .required("Please enter display name*"),
      options: Yup.array().of(
        Yup.object().shape({
          optionName: Yup.string()
            .min(1, "Must be 1 characters or more")
            .required("Please enter option name*"),
        })
      ),
    })
  ),
});

const ProductOptionView = (props: any) => {
  const {
    shop,
    optionSets,
    selectedProduct,
    setProductOptions,
    productPriceOptions,
  } = props;

  const initials = {
    setName: "",
    id: nanoid(),
    setDisplayName: "",
    shopId: shop.shopId,
    createdTime: Date.now(),
    updatedTime: Date.now(),
    options: [
      {
        id: nanoid(),
        optionUnit: "",
        optionName: "",
        optionPrice: 0,
      },
    ],
  };

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectOptions, setSelectOptions] = useState<Array<any>>([]);
  const [initialValues, setInitialValues] = useState<Array<ProductOptions>>([
    initials,
  ]);

  useEffect(() => {
    setIsEdit(!isEmpty(selectedProduct));
  }, [selectedProduct]);

  useEffect(() => {
    if (!isEmpty(optionSets)) {
      const options = optionSets.map((item: any) => {
        return {
          value: item.id,
          label: item.setName,
        };
      });
      setSelectOptions([...options]);
    }
  }, [optionSets]);

  useEffect(() => {
    if (!isEmpty(productPriceOptions)) {
      setInitialValues([...productPriceOptions]);
    }
  }, [productPriceOptions]);

  const handleSubmit = (values: any, { resetForm }: any) => {
    setProductOptions(values.productOptions);
    resetForm();
  };

  return (
    <div className="addProductOptionWrapper">
      <TopNavigationBar viewName="Products" path="/menu" />
      <ProductOptionWrapper>
        <ProductOptionHeaderWrapper>
          <div onClick={() => navigate("/add-product")}>
            <img src={BackIcon} />
            <div>{isEdit ? "Edit Options" : "Add Options"}</div>
          </div>
        </ProductOptionHeaderWrapper>

        <ProductOptionFormWrapper>
          <Formik
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={OptionSchema}
            initialValues={{ productOptions: initialValues }}
          >
            {({ errors, values, submitCount, handleSubmit, setFieldValue }) => {
              return (
                <form id="productOptionsForm" onSubmit={handleSubmit}>
                  <FieldArray name="productOptions">
                    {({ insert, remove, push, replace }) => {
                      return (
                        <div>
                          {values?.productOptions.map(
                            (productOption: any, index: number) => {
                              const error: any = get(
                                errors,
                                `productOptions.[${index}]`,
                                null
                              );
                              return (
                                <ProductOptionForm>
                                  {values?.productOptions.length > 1 && (
                                    <img
                                      src={CloseIcon}
                                      className="imageRemoveIcon"
                                      onClick={() => remove(index)}
                                    />
                                  )}
                                  <div>
                                    <div>Option Set Name</div>
                                    <ProductOptionCreateInput>
                                      <CreatableSelect
                                        isClearable
                                        styles={style}
                                        options={selectOptions}
                                        value={{
                                          label: productOption.setName,
                                          value: productOption.id,
                                        }}
                                        placeholder={"Option set name*"}
                                        onChange={(
                                          newValue: any,
                                          actionMeta: any
                                        ) => {
                                          setFieldValue(
                                            `productOptions[${index}].setName`,
                                            actionMeta.action === "clear"
                                              ? ""
                                              : newValue.label
                                          );
                                          if (
                                            actionMeta.action ===
                                            "select-option"
                                          ) {
                                            const options = find(
                                              optionSets,
                                              (item: any) =>
                                                item.id === newValue.value
                                            );
                                            replace(index, options);
                                          } else if (
                                            actionMeta.action === "clear"
                                          ) {
                                            const clearData = {
                                              setName: "",
                                              id: nanoid(),
                                              setDisplayName: "",
                                              shopId: shop.shopId,
                                              createdTime: Date.now(),
                                              updatedTime: Date.now(),
                                              options: [
                                                {
                                                  id: nanoid(),
                                                  optionName: "",
                                                },
                                              ],
                                            };
                                            replace(index, clearData);
                                          }
                                        }}
                                      />
                                      {!isEmpty(error?.setName) &&
                                        submitCount > 0 && (
                                          <span>{error.setName}</span>
                                        )}
                                    </ProductOptionCreateInput>
                                    <div className="emptyLine" />
                                  </div>
                                  <div>
                                    <div>Display Name</div>
                                    <ProductOptionInput>
                                      <input
                                        value={productOption.setDisplayName}
                                        placeholder="Product Name"
                                        className="addProductOptionInput"
                                        onChange={(event: any) => {
                                          let text = event.target.value;
                                          setFieldValue(
                                            `productOptions[${index}].setDisplayName`,
                                            text
                                          );
                                        }}
                                      />
                                      {!isEmpty(error?.setDisplayName) &&
                                        submitCount > 0 && (
                                          <span>{error.setDisplayName}</span>
                                        )}
                                    </ProductOptionInput>

                                    <div className="emptyLine" />
                                  </div>
                                  <FieldArray
                                    name={`productOptions.${index}.options`}
                                  >
                                    {({
                                      push: childPush,
                                      remove: childRemove,
                                    }) => {
                                      return (
                                        <div>
                                          <div>Option Name</div>
                                          <ProductOptionNameWrapper>
                                            <div>
                                              {productOption.options.map(
                                                (
                                                  option: any,
                                                  cIndex: number
                                                ) => {
                                                  const isLastItem =
                                                    productOption.options
                                                      .length -
                                                      1 ===
                                                    cIndex;
                                                  return (
                                                    <ProductOptionItemWrapper
                                                      isLast={isLastItem}
                                                    >
                                                      <input
                                                        value={
                                                          option.optionName
                                                        }
                                                        placeholder="Option Name"
                                                        onChange={(event) => {
                                                          let text =
                                                            event.target.value;
                                                          setFieldValue(
                                                            `productOptions[${index}].options[${cIndex}].optionName`,
                                                            text
                                                          );
                                                        }}
                                                      />
                                                      {productOption.options
                                                        .length > 1 && (
                                                        <img
                                                          src={CloseIcon}
                                                          onClick={() => {
                                                            childRemove(cIndex);
                                                          }}
                                                          className="imageRemoveIcon"
                                                        />
                                                      )}
                                                    </ProductOptionItemWrapper>
                                                  );
                                                }
                                              )}
                                            </div>
                                            {!isEmpty(error?.options) &&
                                              submitCount > 0 && (
                                                <span>
                                                  {"Please enter option name*"}
                                                </span>
                                              )}
                                          </ProductOptionNameWrapper>
                                          <button
                                            className="optionButtonWrapper"
                                            onClick={() => {
                                              const options: any = {
                                                id: nanoid(),
                                                optionName: "",
                                                optionPrice: 0,
                                                optionUnit: "",
                                              };
                                              childPush(options);
                                            }}
                                          >
                                            {"Add Options"}
                                          </button>
                                        </div>
                                      );
                                    }}
                                  </FieldArray>
                                </ProductOptionForm>
                              );
                            }
                          )}
                          <AddOptionSetWrapper>
                            <div />
                            <div
                              onClick={() => {
                                const options: any = {
                                  id: nanoid(),
                                  setName: "",
                                  setDisplayName: "",
                                  options: [
                                    {
                                      id: nanoid(),
                                      optionName: "",
                                      optionPrice: 0,
                                      optionUnit: "",
                                    },
                                  ],
                                  shopId: shop.shopId,
                                };
                                push(options);
                              }}
                            >
                              <div>{"Add Options Set"}</div>
                              <img src={IconPlus} />
                            </div>
                            <div />
                          </AddOptionSetWrapper>
                          <AddOptionButtonWrapper>
                            <button onClick={() => {}}>{"Remove All"}</button>
                            <button type="submit">{"Save Options"}</button>
                          </AddOptionButtonWrapper>
                        </div>
                      );
                    }}
                  </FieldArray>
                </form>
              );
            }}
          </Formik>
        </ProductOptionFormWrapper>
      </ProductOptionWrapper>
    </div>
  );
};

export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    optionSets: state.product.get("optionSets"),
    currentUser: state.login.get("currentUser"),
    selectedProduct: state.product.get("selectedProduct"),
    productCategoryList: state.product.get("productCategoryList"),
    productPriceOptions: state.product.get("productPriceOptions"),
  }),
  {
    createProduct: Actions.product.createProduct,
    updateProduct: Actions.product.updateProduct,
    deleteProduct: Actions.product.deleteProduct,
    setProductOptions: Actions.product.setProductOptions,
  }
)(ProductOptionView);
