import React from 'react';
import _ from 'lodash';
import {AnyAction} from 'redux';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

const isServer = typeof window === 'undefined' || typeof document === 'undefined';

const createAction = (type: string, action: any, meta?: any) => {
  return (...args: any):AnyAction => ({ type, payload: action(...args), meta });
};

export {
  _, createAction, connect, React, ReactDOM, isServer
};
