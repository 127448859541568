import React, { useEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
import styles from "../../../components/Styles/Styles.css";

const settingData = [
  {
    label: "Profile",
    value: "PROFILE",
  },
  {
    label: "System Settings",
    value: "SYSTEM_SETTINGS",
  },
  // {
  //   label: "Wifi",
  //   value: "WIFI",
  // },
  {
    label: "Printer Setup",
    value: "PRINTER_SETUP",
  },
  {
    label: "Cash Drawer",
    value: "CASH_DRAWER",
  },
  {
    label: "Register",
    value: "REGISTER",
  },
];

const editMenu = [
  {
    label: "Favourites",
    value: "FAVOURITES",
  },
  {
    label: "categories",
    value: "CATEGORIES",
  },
  {
    label: "All Products",
    value: "ALL_PRODUCTS",
  },
];

const SettingsSideNav = (props: any) => {
  const { onClick, type = "SETTING", selectedSidebar } = props;
  const [value, setValue] = useState(settingData);

  useEffect(() => {
    if (type === "SETTING") {
      setValue(settingData);
    } else if (type === "EDIT_MENU") {
      setValue(editMenu);
    }
  }, [type]);

  return (
    <Row>
      <Col xs={12}>
        <ul
          style={{ backgroundColor: "#F5F5F5", height: "calc(100vh - 142px)" }}
        >
          {value.map((item) => {
            const isSelected = item.value === selectedSidebar;
            return (
              <li
                style={{ backgroundColor: isSelected ? "#49A4D4" : "#F5F5F5" }}
              >
                <a
                  onClick={() => onClick(item.value)}
                  className={styles.settingsSideNavHeader}
                  style={{ color: isSelected ? "#ffffff" : "#434343" }}
                >
                  {item.label}
                </a>
              </li>
            );
          })}
        </ul>
      </Col>
    </Row>
  );
};
export default SettingsSideNav;
