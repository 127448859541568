import { v4 as uuid } from 'uuid';
import Storage from '@aws-amplify/storage';
import {isCheckOnline} from "./CommonManager";

import growShampoo from '../../../assets/images/grow.jpeg';
import doveShampoo from '../../../assets/images/dove.jpeg';
import clearShampoo from '../../../assets/images/clear.jpeg';
import dandexShampoo from '../../../assets/images/dandex.jpeg';
import simpleShampoo from '../../../assets/images/simple.jpeg';
import biotinShampoo from '../../../assets/images/biotin.jpeg';
import sunsilkShampoo from '../../../assets/images/sunsilk.jpeg';
import komarikaShampoo from '../../../assets/images/komarika.jpeg';
import sunsilkNewShampoo from '../../../assets/images/sunsilkNew.jpeg';
import headAndShoulderShampoo from '../../../assets/images/headAndShoulder.jpeg';

import user1 from '../../../assets/icons/user4.svg';
import user2 from '../../../assets/icons/user1.svg';
import user3 from '../../../assets/icons/user2.svg';
import user4 from '../../../assets/icons/user3.svg';
import service1 from '../../../assets/images/service1.jpeg';
import service2 from '../../../assets/images/service2.jpeg';
import service3 from '../../../assets/images/service3.jpeg';
import service4 from '../../../assets/images/service4.jpeg';
import service5 from '../../../assets/images/service5.jpeg';
import service6 from '../../../assets/images/service6.jpeg';
import service7 from '../../../assets/images/service7.jpeg';

export const getImage = {
  clearShampoo,
  dandexShampoo,
  komarikaShampoo,
  sunsilkShampoo,
  sunsilkNewShampoo,
  biotinShampoo,
  growShampoo,
  headAndShoulderShampoo,
  simpleShampoo,
  doveShampoo,
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  service7
};

export const getStylistImage = {
  user1,
  user2,
  user3,
  user4
};

// export const generateImageUrl = (imageId: string) => {
//   return `https://netlise-image-bucket.s3.ap-southeast-1.amazonaws.com/public/${imageId}`;
// };

export const generateImageUrl = async (imageId) => {
  // if (isProd) {
  // const url = `https://netlise-app-bucket.s3.ap-southeast-1.amazonaws.com/public/${imageId}`;
  try {
    const isOnline = await isCheckOnline();
    if (!isOnline) {
      return null;
    }

    const signed = await Storage.get(imageId);
    return signed;
  } catch (e) { }
  return null;
  // }
  // return `https://salon-app-s3.s3-ap-southeast-1.amazonaws.com/public/${imageId}`;
};

export const generateFileId = (file) => {
  const fileKey = uuid();
  let fileId: any;
  if (file) {
    fileId = `${fileKey}.${file.name.split('.').pop()}`;
    return fileId;
  }
  return null;
};

export const generateCloudImageUrl = (imageId) => {
  return `https://netlise-image-bucket.s3.ap-southeast-1.amazonaws.com/public/${imageId}`;
};
