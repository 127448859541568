import styled from "styled-components";
import BaseColors from "../../../internal/helpers/BaseColors";

export const EditRoomWrapper = styled.div``;
export const ListRoomWrapper = styled.div`
  padding: 1rem;

  & > div:first-child {
    height: 51px;
    background-color: #fafafa;
    padding-left: 10px;
    & > div {
      font-size: 16px;
      color: #8a9ca5;
      font-family: Montserrat;
      font-weight: 500;
      display: flex;
      align-items: center;
      height: 51px;
    }
  }
  & > div {
    width: 100%;
    height: 51px;
    padding-left: 10px;
    & > div {
      display: flex;
      align-items: center;
      height: 51px;
    }
  }
`;

export const DragWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 130px);
  background-color: ${BaseColors.aquaHaze};
  display: flex;
`;

// export const ButtonWrapper = styled.div`

// `

export const TableNavWrapper = styled.div`
  width: 200px;
  background-color: #ccd4d8;
  height: 100%;
  margin-top: 0.7rem;
`;

export const NavItem = styled.button`
  height: 3rem;
  width: 100%;
  border: none;

  color: ${(props) =>
    props.selected ? BaseColors.white : BaseColors.outerSpace};
  background-color: ${(props) =>
    props.selected ? BaseColors.oceanGreen : BaseColors.tableNav};

  font-size: 14px;
  display: flex;
  justify-content: space-between;

  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const TableEditWrapper = styled.div`
  background-size: 15px;
  border: 1px solid #ccc;
  height: 100%;
  width: 100%;
  background-color: #fff;
  margin-top: 0.7rem;
  position: relative;

  &.edit {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAAHlBMVEUAAABkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGSH0mEbAAAACnRSTlMAzDPDPPPYnGMw2CgMzQAAAChJREFUKM9jgAPOAgZMwGIwKkhXQSUY0BCCMxkEYUAsEM4cjI4fwYIAf2QMNbUsZjcAAAAASUVORK5CYII=");
    background-color: #eee;
  }

  & > .react-draggable {
    cursor: ${(props) => (props.isEdit ? "move" : "pointer !important")};
  }
`;

export const SquareWrapper = styled.div`
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  border: 1px solid #ccc;
  // background: ${(props) => BaseColors.cornflowerBlue};
  border-radius: ${(props) => (props.isRound ? "100%" : "unset")};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > div > img:hover {
    pointer: cursor;
  }

  & > .table-number.dark {
    color: ${BaseColors.outerSpace};
  }
  & > .table-number {
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    margin-left: 1rem;
    text-align: left;
  }
`;

export const Resizer = styled.div`
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-left: 10px solid green;

  width: 0;
  height: 0;
  transform: rotate(45deg);
  bottom: -5px;
  right: 0;
  position: absolute;
`;

export const TableModalWrapper = styled.div`
  // position: absolute;

  .modal-header img:hover {
    cursor: pointer;
  }
  .modal-header {
    height: 61px;
    display: flex;
    flex-direction: row;
    background-color: #353c42;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 20px;
  }

  .modal-content {
    min-height: 400px;

    
    }
  }
`;

export const InputWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;

  & > div {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 12px;
    color: ${BaseColors.regentGray};
    margin-bottom: 5px;
  }

  & > input {
    width: 100%;
    height: 42px;
    border: 1px solid #8a9ca5;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 18px;
    color: ${BaseColors.balticSea};
    border-radius: 4px;

    :focus {
      outline: none;
    }
  }
`;

export const CounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;

  & > div:first-child {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 12px;
    color: ${BaseColors.regentGray};
    margin-bottom: 5px;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > img:hover {
      cursor: pointer;
    }

    & > div {
      width: 113px;
      height: 42px;
      border: 1px solid #8a9ca5;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
      margin-right: 16px;
      font-size: 18px;
      font-weight: 500;
    }
  }
`;

export const TableModeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;

  & > div:first-child {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 12px;
    color: ${BaseColors.regentGray};
    margin-bottom: 5px;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div:hover {
      cursor: pointer;
    }
    & > div:first-child {
      width: 80px;
      height: 80px;
      border: 1px solid #8a9ca5;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 32px;
      font-size: 18px;
      font-weight: 500;
      background: ${({ type }) =>
        type === "SQUARE" ? BaseColors.pictonBlue : "transparent"};
    }

    & > div:last-child {
      width: 80px;
      height: 80px;
      border: 1px solid #8a9ca5;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      font-size: 18px;
      font-weight: 500;
      background: ${({ type }) =>
        type === "ROUND" ? BaseColors.pictonBlue : "transparent"};
    }
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  margin-top: 1rem;

  & > div:first-child {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 12px;
    color: ${BaseColors.regentGray};
    // margin-bottom: 5px;
    margin-right: 32px;
    margin-top: 5px;
  }
`;

export const TableNavButton = styled.div`
  display: flex;
  flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          & > button:first-child {
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${(props) =>
              props.disabled ? BaseColors.gunsmoke : BaseColors.white};
            border: none;
            cursor: pointer;

            :focus {
              outline: none;
            }

            & > div {
              color: ${BaseColors.pictonBlue};
              font-size: 14px;
              font-family: Montserrat;
              font-weight: 500;
            }
          }

        }
`;

export const ButtonWrapper = styled.div`
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 16px;
          padding: 1rem;

          & > button:first-child {
            width: 143px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${(props) =>
              props.disabled ? BaseColors.gunsmoke : BaseColors.pictonBlue};
            border: none;
            border-radius: 49px;
            cursor: pointer;
            margin-right: 14px;

            :focus {
              outline: none;
            }

            & > div {
              color: ${BaseColors.white};
              font-size: 14px;
              font-family: Montserrat;
              font-weight: 500;
            }
          }

          & > button:nth-child(2) {
            width: 122px;
            height: 40px;
            display: flex;
            border: none;
            align-items: center;
            justify-content: center;
            border-radius: 49px;
            cursor: pointer;
            border: 1px solid #8a9ca5;
            background-color: ${BaseColors.white};
            margin-right: 14px;

            :focus {
              outline: none;
            }

            & > div {
              color: ${BaseColors.regentGray};
              font-size: 14px;
              font-family: Montserrat;
              font-weight: 500;
            }
          }

          & > .delete-button.delete {
            border: 1px solid ${BaseColors.mischka};
            background-color: ${BaseColors.mischka};
          }
          & > .delete-button {
            width: 122px;
            height: 40px;
            display: flex;
            border: none;
            align-items: center;
            justify-content: center;
            border-radius: 49px;
            cursor: pointer;
            border: 1px solid ${BaseColors.RedOrange};
            background-color: ${BaseColors.RedOrange};
            align-self: flex-end;

            :focus {
              outline: none;
            }

            & > div {
              color: ${BaseColors.white};
              font-size: 14px;
              font-family: Montserrat;
              font-weight: 500;
            }
          }
        }
`;

export const TableAreas = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;

  & > div {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
    border: 1px solid ${BaseColors.mischka};
  }
  & > .selected {
    background-color: ${BaseColors.oceanGreen};
    border: 1px solid ${BaseColors.oceanGreen};
    color: white;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > .selected {
    background-color: ${BaseColors.pictonBlue};
    border: 1px solid ${BaseColors.pictonBlue};
    color: ${BaseColors.white};
  }
  & > .disabled {
    background-color: ${BaseColors.bittersweet};
    border: 1px solid ${BaseColors.bittersweet};
    color: white;
  }
  & > button {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: center;
    background-color: ${BaseColors.mischka};
    border: 1px solid ${BaseColors.regentGray};
  }
`;

export const RoomSquareWrapper = styled.div`
  padding: 8px;
  display: flex;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  border: 1px solid #ccc;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: ${(props) => (props.isRound ? "100%" : "unset")};

  & > div > img:hover {
    cursor: pointer;
  }

  & > .table-number.dark {
    color: ${BaseColors.outerSpace};
  }
  & > .table-number {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    /* margin-left: 1rem; */
    /* text-align: left; */
  }
`;
