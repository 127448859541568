import React, {useState} from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../internal/app/Store.ts';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import { ReportsWrapper, ReportsBottomWrapper } from './styled';
import {Col, Row} from "react-grid-system";
import ReportsSideNav from "../../../components/sideNavBars/views/ReportsSideNav";
import SalesDetails from "./SalesDetails";

const ReportsView = (props) => {
  const [selectedView, setSelectedView] = useState('SALES');

  const renderMiddleComponent = () => {
    switch (selectedView) {
      case 'SALES':
        return <SalesDetails/>;
      default:
        return <SalesDetails/>;
    }
  };

  return (
    <ReportsWrapper>
      <TopNavigationBar viewName={'Reports'} path="/menu" />
      <ReportsBottomWrapper>
        <div>
          <Row style={{height: '100%', margin: 0}}>
            <Col style={{height: '100%'}} xs={2}>
              <ReportsSideNav onClick={(type) => setSelectedView(type)} selectedView={selectedView}/>
            </Col>
            <Col style={{height: '100%'}} xs={10}>
              {renderMiddleComponent()}
            </Col>
          </Row>
        </div>
      </ReportsBottomWrapper>
    </ReportsWrapper>
  );
};

export default connect(
  (state: RootState) => ({
    salon: state.login.get('salonData'),
    reportsSummary: state.reports.get('reportsSummary'),
  }),
  () => ({})
)(ReportsView);
