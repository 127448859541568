import React, { useEffect, useState } from "react";
import _, { get } from "lodash";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { TableButton } from "./Styled";
import styles from "../../Styles/Styles.css";
import { Row, Col } from "react-grid-system";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Actions } from "../../../internal/app/Actions";
import UpIcon from "../../../../assets/icons/upIcon.svg";
import RoomIcon from "../../../../assets/svg/room-icon.svg";
import DownIcon from "../../../../assets/icons/downIcon.svg";
import TableIcon from "../../../../assets/svg/TableIcon.svg";
import RoomModal from "../../../modules/rooms/views/RoomModal";
import AddAppointment from "../../Buttons/Views/AddAppointment";
import OrderQueueFilterButtons from "./OrderQueueFilterButtons";
import TableModal from "../../../modules/table/views/TableModal";
import OrderQueueIcon from "../../../../assets/svg/OrderQueueIcon.svg";
import { AppointmentFilterButtonContainer } from "../../Styles/Styled";
import { isCanAccess } from "../../../internal/manager/EmployeeManager";
import { getShopSystemSetting } from "../../../internal/manager/CommonManager";

const appointmentOptions = [
  {
    label: "All appointments",
    value: "ALL",
  },
  {
    label: "Confirmed",
    value: "CONFIRMED",
  },
  {
    label: "Pending",
    value: "PENDING",
  },
  {
    label: "Declined",
    value: "DECLINED",
  },
];

const OrderQueueTableFilters = (props) => {
  const {
    mode,
    shopData,
    roomMode,
    customers,
    employees,
    orderView,
    saveRooms,
    roomAreas,
    selectArea,
    saveTables,
    tableAreas,
    currentUser,
    setEditMode,
    setRoomMode,
    merchantShop,
    changeViewMode,
    filterUnCompletedAppointments,
  } = props;

  const { formatMessage: f } = useIntl();
  const [openFilter, setOpenFilter] = useState("");
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [employeeOptions, setEmployeeOptions] = useState<any>([]);
  const [customerOptions, setCustomerOptions] = useState<any>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>({});
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});
  const [isOpenRoomModal, setOpenRoomModal] = useState<boolean>(false);
  const [appointmentType, setAppointmentType] = useState<string>("ALL");

  useEffect(() => {
    setCustomerOptions([...customers]);
  }, [customers]);

  useEffect(() => {
    setEmployeeOptions([...employees]);
  }, [employees]);

  useEffect(() => {
    const params = {
      shopId: merchantShop.shopId,
      orderStatus: appointmentType,
      clientId: _.get(selectedCustomer, "id", null),
      empId: _.get(selectedEmployee, "empId", null),
    };
    filterUnCompletedAppointments(params);
  }, [appointmentType, selectedEmployee, selectedCustomer]);

  const toggleFilter = (type: string) => {
    setOpenFilter(openFilter === type ? "" : type);
  };

  const getFilterItems = (type) => {
    switch (type) {
      case "APPOINTMENT":
        return appointmentOptions;
      case "EMPLOYEE":
        return employeeOptions;
      case "CUSTOMER":
        return customerOptions;
      default:
        return [];
    }
  };

  const onClickFilterValue = (item) => {
    setOpenFilter("");
    const params = {
      shopId: merchantShop.shopId,
      orderStatus: appointmentType,
      clientId: _.get(selectedCustomer, "userId", null),
      empId: _.get(selectedEmployee, "empId", null),
    };
    switch (openFilter) {
      case "APPOINTMENT":
        setAppointmentType(item.value);
        return params.orderStatus === item.value;
      case "EMPLOYEE":
        setSelectedEmployee(item);
        return params.empId === _.get(item, "empId", null);
      case "CUSTOMER":
        setSelectedCustomer(item);
        return params.clientId === _.get(item, "userId", null);
      default:
        return;
    }
  };

  const getAppointmentType = (type) => {
    switch (type) {
      case "ALL":
        return f({ id: "ORDER_QUEUE.FILTER.ALL_ORDERS" });
      case "CONFIRMED":
        return "Confirmed";
      case "PENDING":
        return "Pending";
      case "DECLINED":
        return "Declined";
      default:
        return f({ id: "ORDER_QUEUE.FILTER.ALL_ORDERS" });
    }
  };

  const getSelectedFilterItems = (type: string) => {
    switch (type) {
      case "APPOINTMENT":
        return getAppointmentType(appointmentType);
      case "EMPLOYEE":
        return _.isEmpty(selectedEmployee)
          ? f({ id: "ORDER_QUEUE.FILTER.EMPLOYEE" })
          : _.truncate(selectedEmployee.name, { length: 15 });
      case "CUSTOMER":
        return _.isEmpty(selectedCustomer)
          ? f({ id: "ORDER_QUEUE.FILTER.CUSTOMER" })
          : _.truncate(
              `${selectedCustomer.firstName} ${selectedCustomer.lastName}`,
              { length: 15 }
            );
      default:
        break;
    }
  };

  const upsertRooms = () => {
    saveRooms(
      { ...roomAreas, shopId: merchantShop.shopId },
      !!roomAreas.shopId
    );
  };

  const upsertTables = () => {
    saveTables(
      { ...tableAreas, shopId: merchantShop.shopId },
      !!tableAreas.shopId
    );
  };

  const role = currentUser?.specializations;

  const RenderOptions = () => {
    switch (orderView) {
      case "TABLE":
        return mode === "edit" ? (
          <div>
            <div
              className=""
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <div>Add Tables</div>
            </div>
            <div className="add-tables" onClick={() => upsertTables()}>
              <div>Save Tables</div>
            </div>
          </div>
        ) : (
          <div>
            {isCanAccess(role, "isEditOrDeleteTable") ? (
              <div
                className="add-tables"
                onClick={() => {
                  const areaId = get(tableAreas.tableAreas, "[0].areaId", "");
                  setEditMode("edit");
                  selectArea(areaId);
                }}
              >
                <div>Edit Tables</div>
              </div>
            ) : null}
          </div>
        );
      case "ROOMS":
        return roomMode === "edit" ? (
          <div>
            <div className="" onClick={() => setOpenRoomModal(true)}>
              <div>Add Rooms</div>
            </div>
            <div className="add-tables" onClick={() => upsertRooms()}>
              <div>Save Rooms</div>
            </div>
          </div>
        ) : (
          <div>
            {isCanAccess(role, "isEditOrDeleteRooms") ? (
              <div className="add-tables" onClick={() => setRoomMode("edit")}>
                <div>Edit Rooms</div>
              </div>
            ) : null}
          </div>
        );

      case "ORDER_QUEUE":
      default:
        return (
          <div>
            <div
              className="add-order"
              onClick={() => toggleFilter("APPOINTMENT")}
            >
              <div>{getSelectedFilterItems("APPOINTMENT")}</div>
              <img src={openFilter === "APPOINTMENT" ? UpIcon : DownIcon} />
            </div>
            <div
              className={styles.tableFilters}
              onClick={() => toggleFilter("EMPLOYEE")}
            >
              <div className={styles.tableFiltersContainerText}>
                {getSelectedFilterItems("EMPLOYEE")}
              </div>
              <div>
                {!_.isEmpty(selectedEmployee) ? (
                  <AiOutlineCloseCircle
                    onClick={() => setSelectedEmployee({})}
                  />
                ) : null}
                <img
                  onClick={() => toggleFilter("EMPLOYEE")}
                  src={openFilter === "EMPLOYEE" ? UpIcon : DownIcon}
                />
              </div>
            </div>

            <div
              className={styles.tableFilters}
              onClick={() => toggleFilter("CUSTOMER")}
            >
              <div className={styles.tableFiltersContainerText}>
                {getSelectedFilterItems("CUSTOMER")}
              </div>
              <div>
                {!_.isEmpty(selectedCustomer) ? (
                  <AiOutlineCloseCircle
                    onClick={() => setSelectedCustomer({})}
                  />
                ) : null}
                <img
                  onClick={() => toggleFilter("CUSTOMER")}
                  src={openFilter === "CUSTOMER" ? UpIcon : DownIcon}
                />
              </div>
            </div>
          </div>
        );
    }
  };

  const category = get(shopData, "shopCategory", "");
  const shopSetting = getShopSystemSetting(shopData);

  return (
    <>
      <AppointmentFilterButtonContainer>
        <RenderOptions />
        {/*<div onClick={() => toggleServiceFilter()} className={styles.tableFilters}>*/}
        {/*  <div className={styles.tableFiltersContainerText}>{getServiceFilter(selectedService)}</div>*/}
        {/*  <img src={openServiceFilter ? UpIcon : DownIcon}/>*/}
        {/*</div>*/}
        <div>
          {category === "HOSPITALITY" && shopSetting?.isQueueRooms && (
            <TableButton
              selected={orderView === "ROOMS"}
              onClick={() => {
                changeViewMode("ROOMS");
              }}
            >
              <img src={RoomIcon} alt="" />
            </TableButton>
          )}
          {(category === "FOOD_AND_BEVERAGES" || category === "HOSPITALITY") &&
            shopSetting?.isQueueTables && (
              <TableButton
                selected={orderView === "TABLE"}
                onClick={() => {
                  changeViewMode("TABLE");
                }}
              >
                <img src={TableIcon} alt="" />
              </TableButton>
            )}
          <TableButton
            selected={orderView === "ORDER_QUEUE"}
            onClick={() => {
              changeViewMode("ORDER_QUEUE");
            }}
          >
            <img src={OrderQueueIcon} alt="" />
          </TableButton>
          <AddAppointment />
        </div>
      </AppointmentFilterButtonContainer>
      <TableModal isOpen={isOpenModal} closeModal={() => setOpenModal(false)} />
      <RoomModal
        isOpen={isOpenRoomModal}
        closeModal={() => setOpenRoomModal(false)}
      />

      <Row style={{ width: "100%" }}>
        <Col md={12}>
          <OrderQueueFilterButtons
            type={openFilter}
            options={getFilterItems(openFilter)}
            onClick={(item) => onClickFilterValue(item)}
          />
        </Col>
      </Row>
    </>
  );
};

export default connect(
  (state: any) => ({
    mode: state.table.get("mode"),
    tables: state.table.get("tables"),
    roomMode: state.rooms.get("roomMode"),
    roomAreas: state.rooms.get("roomAreas"),
    orderView: state.orders.get("orderView"),
    shopData: state.login.get("merchantShop"),
    tableAreas: state.table.get("tableAreas"),
    employees: state.employee.get("employees"),
    customers: state.customer.get("customers"),
    currentUser: state.login.get("currentUser"),
    merchantShop: state.login.get("merchantShop"),
    selectedArea: state.table.get("selectedArea"),
    selectedRoomArea: state.rooms.get("selectedArea"),
    unCompletedAppointments: state.orders.get("unCompletedAppointments"),
  }),
  {
    saveRooms: Actions.rooms.saveRooms,
    editTables: Actions.table.editTables,
    selectArea: Actions.table.selectArea,
    saveTables: Actions.table.saveTables,
    setRoomMode: Actions.rooms.setRoomMode,
    setEditMode: Actions.table.setEditMode,
    changeViewMode: Actions.orders.changeViewMode,
    filterUnCompletedAppointments: Actions.orders.filterUnCompletedAppointments,
  }
)(OrderQueueTableFilters);
