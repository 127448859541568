import React, {Component, useEffect, useState} from 'react';
import styles from '../../../components/Common/Style/Styles.css';
import {Container, Row, Col} from 'react-grid-system';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import SettingsSideNav from '../../../components/sideNavBars/views/SettingsSideNav';
import ServiceItem from '../../../components/editMenu/views/ServiceItem';
import ServiceSubHeader from '../../../components/headers/Views/ServiceSubHeader';
import {connect} from "react-redux";
import {Actions} from "../../../internal/app/Actions";

const EditMenu = (props) => {
  const {fetchAllProduct} = props;
  const [selectedSidebar, setSelectedSidebar] = useState('WIFI');

  useEffect(() => {
    fetchAllProduct();
  }, []);

  const onClickAddItem = () => {
  };

  const onClickSideBar = (type) => {
    setSelectedSidebar(type);
  };

  const renderSidebarValue = () => {
    switch (selectedSidebar) {
      case 'FAVOURITES':
        return (
          <div className={styles.editMenuFavoriteWrapper}>
            <ServiceItem onClickAdd={() => onClickAddItem()}/>
          </div>
        );
      case 'CATEGORIES':
        return (
          <div/>
        );
      case 'ALL_PRODUCTS':
        return (
          <div/>
        );
      default:
        return (
          <div/>
        );
    }
  };

  return (
    <div style={{backgroundColor: '#EFF3F6'}}>
      <Row>
        <Col xs={12}>
          <TopNavigationBar viewName="Edit Menu" path="/menu"/>
        </Col>
      </Row>
      <div className={styles.editMenuWrapper}>
        <div style={{
          backgroundColor: '#FFFFFF',
          height: window.innerHeight - 100,
          width: '100%',
          borderRadius: '3px',
        }}>
          <Row>
            <Col xs={12}>
              <ServiceSubHeader header={'Edit Menu'}/>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <SettingsSideNav type={'EDIT_MENU'} onClick={(type) => onClickSideBar(type)}/>
            </Col>
            <Col xs={10}>
              {
                renderSidebarValue()
              }
            </Col>
          </Row>

        </div>
      </div>
    </div>
  );
};
export default connect(
  state => ({
  }),
  ({
    fetchAllProduct: Actions.product.fetchAllProduct,
  })
)(EditMenu);
