import React from 'react';
import ReactModal from 'react-modal';
import styles from '../../Styles/ModelStyles.css';
import {LogoutModalContainer} from '../../Styles/Styled';
import IconCross from "../../../../assets/svg/IconCross.svg";

const customStyles = {
  content: {
    top: '20%',
    outline: 'none',
    overflow: 'auto',
    borderRadius: '4px',
    position: 'absolute',
    left: "calc(50% - 230px)",
    WebkitOverflowScrolling: 'touch',
    boxShadow: "0px 0px 10px 5px #c4d3f020",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.16)"
  },
};

const LogoutModal = (props) => {
  const {isOpen, onClose, closeCash, logout} = props;
  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        style={customStyles}
        className={styles.LogoutModalWrapper}
      >
        <LogoutModalContainer>
          <div>
            <img onClick={() => onClose()} src={IconCross}/>
          </div>
          <div>
            <div>{'Are you sure ?'}</div>
            <div>{'Are you sure you want to logout and Close cash?'}</div>

            <div>
              <button onClick={() => logout()}>
                <div>{'Yes, Logout'}</div>
              </button>
              <button onClick={() => closeCash()}>
                <div>{'Close Cash'}</div>
              </button>
            </div>
          </div>
        </LogoutModalContainer>
      </ReactModal>
    </div>
  );
};
export default LogoutModal;
