import styled from "styled-components";
import BaseColors from "../../../internal/helpers/BaseColors";

export const PageWrapper = styled.div`
  width: 2480px;
  font-family: Montserrat;
`;

export const RowSpan = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LogoArea = styled.div`
  background: rgba(41, 50, 65, 0.11);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #293241;
  width: 1000px;
  height: 800px;
  border-bottom-right-radius: 40px;

  & > div {
    width: 400px;
    height: 400px;
    margin-top: 100px;
    border-radius: 200px;
    border: 8px solid #fff;
  }
  & > h1 {
    font-size: 80px;
    margin-top: 8px;
  }
  & > p {
    font-size: 36px;
  }
`;

export const InvoiceTitle = styled.div`
  max-width: 700px;
  width: 100%;
  margin-right: 140px;
  text-align: right;

  & > hr {
    margin: 40px 0px;
  }

  & > h1 {
    font-weight: 200;
    font-size: 80px;
    margin-top: 165px;
  }

  & > div {
    margin-top: 8px;
  }

  & > div > p {
    font-size: 24px;
  }
`;

export const InvoiceTo = styled.div`
  max-width: 630px;
  width: 100%;
  margin-left: 140px;

  & > h4 {
    font-weight: 200;
    font-size: 40px;
    margin-top: 100px;
  }

  & > p {
    font-size: 24px;
    margin: 4px 0px;
  }
`;

export const RecieptDetails = styled.div`
  width: 740px;
  font-size: 24px;
  margin-right: 140px;
  padding-top: 180px;

  & > div {
    display: flex;
    justify-content: space-between;
    margin: 4px 0px;
  }

  & > div > div:first-child {
    width: 400px;
  }
  & > div > div:last-child {
    width: 340px;
  }
`;

export const TableHead = styled.div`
  margin-top: 48px;
  margin-right: 140px;
  height: 100px;
  margin-left: 140px;
  background-color: #293241;
  font-size: 40px;
  color: #fff;
  display: flex;
  align-items: center;

  & > div:nth-child(1) {
    padding-left: 24px;
    width: 1060px;
  }
  & > div:nth-child(2) {
    padding-right: 24px;
    text-align: right;
    width: 260px;
  }
  & > div:nth-child(3) {
    padding-right: 24px;
    text-align: right;
    width: 412px;
  }
  & > div:nth-child(4) {
    padding-right: 24px;
    text-align: right;
    flex: 1;
  }
`;

export const TabledRow = styled.div`
  margin-right: 140px;
  height: 100px;
  margin-left: 140px;
  background-color: #fff;
  font-size: 40px;
  color: #293241;
  display: flex;
  align-items: center;
  border-left: 2px solid #293241;
  border-bottom: 2px solid #293241;

  & > div {
    height: 100px;
    display: flex;
    align-items: center;
  }
  & > div:nth-child(1) {
    padding-left: 24px;
    width: 1060px;
    border-right: 2px solid #293241;
  }
  & > div:nth-child(2) {
    padding-right: 24px;
    justify-content: flex-end;
    width: 260px;
    border-right: 2px solid #293241;
  }
  & > div:nth-child(3) {
    padding-right: 24px;
    justify-content: flex-end;
    width: 412px;
    border-right: 2px solid #293241;
  }
  & > div:nth-child(4) {
    padding-right: 24px;
    justify-content: flex-end;
    flex: 1;
    border-right: 2px solid #293241;
  }
`;

export const OrderSummary = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 40px;

  & > div {
    padding: 24px;
    width: calc(100% - 1600px);
    background: #edf6f9;
    margin-right: 140px;
    border-right: 2px solid #293241;
    border-left: 2px solid #293241;
    border-bottom: 2px solid #293241;
  }
  & > div > div {
    display: flex;
    padding: 8px;
    justify-content: space-between;
  }

  & > div > div > div:first-child {
    width: 400px;
  }
`;

export const SignArea = styled.div`
  font-size: 40px;
  margin-top: 160px;
  margin-left: 140px;

  & > div {
    width: 500px;
    margin-top: 240px;
  }
  & > div > hr {
    border: 2px dotted #293241;
  }
`;

export const PaymentAmounts = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 24px;

  & > div:hover {
    cursor: pointer;
    border: 1px solid ${BaseColors.pictonBlue};
  }

  & > div {
    padding: 16px 24px;
    background: ${BaseColors.tableFree};
    border-radius: 4px;
    margin-top: 24px;
    margin-left: 8px;
    border: 1px solid ${BaseColors.tableFree};
    color: ${BaseColors.outerSpace};
    font-weight: 600;
  }
`;
