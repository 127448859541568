import React, {useEffect, Component, useState} from 'react';
import styles from '../Styles/Styles.css';
import {Container, Row, Col} from 'react-grid-system';
import {connect} from "react-redux";
import {Actions} from "../../internal/app/Actions";
import {getAppointmentDateTime} from "../../internal/manager/AppointmentManager";
import moment from 'moment';
import _ from 'lodash';
import {getCurrencyWithPrice} from "../../internal/manager/PaymentManager";


const ReceiptCard = (props) => {
  const {appointment, salon} = props;

  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [price, setPrice] = useState('');

  useEffect(() => {
    if (!_.isEmpty(appointment)) {
      const dateTime = getAppointmentDateTime(appointment);
      let duration = 0;
      appointment.serviceDetails.forEach(item => {
          let serviceSplit = _.split(item.duration, ':');
          duration += parseInt(serviceSplit[0]) * 60 + parseInt(serviceSplit[1]);
        }
      );
      const total = appointment.serviceDetails.map(item => item.price).reduce((prev, curr) => prev + curr, 0);
      const currencyPrice = getCurrencyWithPrice(total, salon.currency);
      const startTime = dateTime.time;
      const endTime = moment(startTime, 'hh:mm').add(duration, 'minutes').format('hh:mmA');
      setPrice(currencyPrice);
      setTime(`${startTime} - ${endTime}`);
      setDate(moment(dateTime.date, 'DD/MM/YYYY').format('MMM DD, YYYY'))
    }
  }, [appointment]);


  return (
    <div className={styles.receiptCardWrapper}>
      <div className={styles.receiptCardHeaderWrapper}>
        <p className={styles.receiptCardHeaderText}>Receipt</p>
      </div>
      <div className={styles.receiptCardWrapperInfo} style={{height: window.innerHeight - 180}}>
        <div>
          <div className={styles.receiptCardDetailsWrapper}>
            <div className={styles.receiptCardDetailsSalonText}>{salon.salonName}</div>
            <div className={styles.receiptCardDetailsAddressText}>{salon.address}</div>
            <div className={styles.receiptCardDetailsBottomLine}/>
          </div>

          <div className={styles.receiptCardTimesWrapper}>
            <div className={styles.receiptCardTimesWrapperInfo}>
              <div className={styles.receiptCardDetailsTimeText}>{'Appointment date'}</div>
              <div className={styles.receiptCardDetailsTimeText}>{date}</div>
            </div>

            <div className={styles.receiptCardTimesWrapperInfo}>
              <div className={styles.receiptCardDetailsTimeText}>{'Time'}</div>
              <div className={styles.receiptCardDetailsTimeText}>{time}</div>
            </div>
          </div>
        </div>

        <div>
          <div className={styles.receiptCardTimesWrapper}>
            <div className={styles.receiptCardServiceWrapper}>
              {
                !_.isEmpty(appointment) && appointment.serviceDetails.map((item) => {
                  const currencyPrice = getCurrencyWithPrice(item.price, salon.currency);

                  return (
                    <div className={styles.receiptCardTimesWrapperInfo}>
                      <div className={styles.receiptCardDetailsServiceText}>{item.title || item.serviceTitle}</div>
                      <div className={styles.receiptCardDetailsServiceText}>{currencyPrice}</div>
                    </div>
                  )
                })
              }
              <div className={styles.receiptCardTimesWrapperInfo}>
                <div className={styles.receiptCardDetailsServiceText}>{'Booking Charges'}</div>
                <div className={styles.receiptCardDetailsServiceText}>{`${salon.currency} 0`}</div>
              </div>
              <div className={styles.receiptCardTimesWrapperInfo}>
                <div className={styles.receiptCardDetailsServiceText}>{'Total'}</div>
                <div className={styles.receiptCardDetailsServiceText}>{price}</div>
              </div>
            </div>

            <div className={styles.receiptCardServiceWrapper}>
              <div className={styles.receiptCardTimesWrapperInfo}>
                <div className={styles.receiptCardDetailsAddressText}>{'Cash'}</div>
                <div className={styles.receiptCardDetailsAddressText}>{0}</div>
              </div>
              <div className={styles.receiptCardTimesWrapperInfo}>
                <div className={styles.receiptCardDetailsAddressText}>{'Change'}</div>
                <div className={styles.receiptCardDetailsAddressText}>{0}</div>
              </div>
            </div>
          </div>

          <div className={styles.receiptCardDeleteWrapper}>
            <div className={styles.receiptCardDeleteText}>{'Delete Order'}</div>
          </div>
          <div className={styles.receiptCardReOpenWrapper}>
            <div className={styles.receiptCardReOpenText}>{'Reopen Order'}</div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default connect(
  state => ({
    salon: state.salon.get('salon'),
  }),
  {
  }
)(ReceiptCard);
