import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import RedCloseIcon from "../../../assets/svg/RedCloseIcon.svg";

const StockItemCard = (props) => {
  const {stockItems, onRemove, onSelectStockItem} = props;
  return (
    <div className="stockItemCardContainer">
      {
        stockItems.map((item, index) => {
          return (
            <div className="stockItemCard">
              <div className="stockItemName" onClick={() => onSelectStockItem(item, index)}>
                {item.itemName} - {item.quantity}{item.metricValue}
              </div>
              <div onClick={() => onRemove(item)}>
                <img src={RedCloseIcon} />
              </div>
            </div>
          )
        })
      }
    </div>
  );
};
export default connect(state =>
    ({}),
  ({}),
)(StockItemCard);
