import React from "react";
import LogTable from "./LogTable";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { ActivityLogWrapper, ActivityLogBottomWrapper } from "./Styled";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";

const ActivityLogView = () => {
  const { formatMessage: f } = useIntl();
  return (
    <ActivityLogWrapper>
      <TopNavigationBar
        viewName={f({ id: "ACTIVITY_LOG.HEADER" })}
        path="/menu"
      />
      <ActivityLogBottomWrapper>
        <LogTable />
      </ActivityLogBottomWrapper>
    </ActivityLogWrapper>
  );
};
export default connect((state: any) => ({}), {})(ActivityLogView);
