import React  from "react";
import styles from "../../Common/Style/Styles.css";
import { CircularProgress } from "@material-ui/core";

const SignInButton = (props: any) => {
  const { text, loading } = props;

  return (
    <div className={styles.submitButton}>
      {loading ? (
        <CircularProgress color="primary" size={40} value={5} />
      ) : (
        <div className={styles.submitButtonText}>{text}</div>
      )}
    </div>
  );
};
export default SignInButton;
