import React from "react";
import { List, Map } from "immutable";
import { ModuleEvents } from "./Actions";

const initialState = Map({
  printers: [],
  networks: [],
  isOnline: false,
  networksList: {},
  progressEvent: {
    eventData: {},
    totalProgress: 0,
  },
  currentConnection: {},
  selectedView: "Service",
  selectedMainMenuView: null,
  loadingAction: { loading: false, action: {} },
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.SELECT_VIEW: {
      return state.set("selectedView", payload);
    }
    case ModuleEvents.SET_PRINTERS: {
      return state.set("printers", payload);
    }
    case ModuleEvents.SELECT_MAIN_MENU_VIEW: {
      return state.set("selectedMainMenuView", payload);
    }
    case ModuleEvents.GET_WIFI_DETAILS: {
      if (payload) {
        return state.set("networksList", payload);
      }
      return state;
    }
    case ModuleEvents.ON_SUBSCRIPTION_EVENTS: {
      const progressEvent: any = state.get("progressEvent");
      if (payload && payload.event === "ONBOARD_PROCESSING") {
        let progress = progressEvent.totalProgress + payload.progress;
        progressEvent.eventData = payload;
        progressEvent.totalProgress = progress;
        return state.set("progressEvent", { ...progressEvent });
      }
      return state;
    }
    case ModuleEvents.LOADING_STARTED: {
      return state.set("loadingAction", { loading: true, action: payload });
    }
    case ModuleEvents.LOADING_FINISHED: {
      return state.set("loadingAction", { loading: false, action: payload });
    }
    case ModuleEvents.SET_ONLINE: {
      const isOnline = state.get("isOnline");
      if (isOnline != payload) {
        return state.set("isOnline", payload);
      }
      return state;
    }
    case ModuleEvents.GET_NETWORKS: {
      if (payload) {
        return state.set("networks", payload);
      }
      return state;
    }
    case ModuleEvents.GET_CURRENT_NETWORK: {
      const currentConnection = state.get("currentConnection");
      if (payload != currentConnection) {
        return state.set("currentConnection", payload);
      }
      return state;
    }
    default:
      return state;
  }
};

export default Reducer;
