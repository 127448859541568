import ReportRepository from '../../internal/repos/ReportsRepository';
import {createAction} from '../../internal/app/AppUtils';

export const ModuleEvents = {
  FETCH_REPORTS: 'FETCH_REPORTS',
  FETCH_REPORT_FOR_DATE: 'FETCH_REPORT_FOR_DATE',
  FETCH_REPORT_FOR_DATE_RANGE: 'FETCH_REPORT_FOR_DATE_RANGE',
  FETCH_APPOINTMENTS_FOR_DATE: 'FETCH_APPOINTMENTS_FOR_DATE',
  FETCH_APPOINTMENTS_FOR_DATE_RANGE: 'FETCH_APPOINTMENTS_FOR_DATE_RANGE'
};

export default {
  fetchReportSummary: createAction(
    ModuleEvents.FETCH_REPORTS,
    async (salonId: string, reportMonth: string) => {
      const result = await ReportRepository.fetchReportSummary(
        salonId,
        reportMonth
      );
      return result?.reportsSummary;
    },
    {loading: true}),

  fetchReportForDate: createAction(
    ModuleEvents.FETCH_REPORT_FOR_DATE,
    async (salonId: string, startDate: bigint) => {
      const result = await ReportRepository.fetchReportForDate(salonId, startDate);
      return result;
    },
    {loading: true}),

  fetchReportForDateRange: createAction(
    ModuleEvents.FETCH_REPORT_FOR_DATE_RANGE,
    async (params) => {
      const result = await ReportRepository.fetchReportForDateRange(params);
      return result;
    },
    {loading: true}),

  fetchAppointmentsForDate: createAction(
    ModuleEvents.FETCH_APPOINTMENTS_FOR_DATE,
    async (salonId, date, limit = null, lastKey = null) => {
      const result = await ReportRepository.fetchAppointmentsForDate(salonId, date, limit, lastKey);
      return result;
    },
    {loading: true}),

  fetchAppointmentsForDateRange: createAction(
    ModuleEvents.FETCH_APPOINTMENTS_FOR_DATE_RANGE,
    async (salonId, startDate, endDate, limit = 100, lastKey = null) => {
      const result = await ReportRepository.fetchAppointmentsForDateRange(salonId, startDate, endDate, limit, lastKey);
      return result;
    },
    {loading: true}),
};
