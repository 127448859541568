import React from 'react';
import {StylistSpecializationContainer, StylistSpecializationCardContainer} from '../Styles/Styled';

const StylistSpecializationComponent = (props) => {
  const {specializationDetails, selectedSpecialization, onSelect} = props;

  return (
    <StylistSpecializationContainer>
      {
        specializationDetails.map((specialization) => {
          const isSelected = selectedSpecialization.id === specialization.id;
          return (
            <StylistSpecializationCardContainer isSelected={isSelected} onClick={() => onSelect(specialization)}>
              <div>{specialization.title}</div>
            </StylistSpecializationCardContainer>
          )
        })
      }
    </StylistSpecializationContainer>
  )
};
export default StylistSpecializationComponent;
