import styled from 'styled-components';
import BaseColors from "../../../internal/helpers/BaseColors";

export const ReportsWrapper = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${BaseColors.aquaHaze};
`;

export const ReportsBottomWrapper = styled.div<{ height: string }>`
  // width: 100%;
  height: calc(100vh - 61px);
  display: flex;
  flex-direction: row;
  padding: 10px;      
  
  &>div {
        width: 100%;
        height: 100%;
        border-radius: 3px;
        background-color: #FFFFFF;
  }
`;
