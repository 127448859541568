import moment from 'moment';
import _ from 'lodash';
import {getCurrencyWithPrice} from "./PaymentManager";


export const getReportTableData = (labels, appointments, type) => {
  if (type === 'DAY') {
    const groupByList = _.groupBy(appointments, 'time');
    let filterList: any = [];

    Object.keys(groupByList).forEach((key,) => {
      const splitDotTime = key.split(':');
      const splitSpaceTime = splitDotTime[1].split(' ');
      const newTime = `${splitDotTime[0]}:00 ${splitSpaceTime[1]}`;
      const findIndex = _.findIndex(labels, item => item === newTime);

      if (findIndex > -1) {
        filterList.push({
          label: newTime,
          values: groupByList[key]
        })
      }
    });

    const labelList = labels.map((label) => {
      const filterData = _.filter(filterList, item => item.label === label);
      return {
        label: label,
        values: _.get(filterData, '0.values', [])
      }
    });
    return mapChartValues(labelList);
  }else {
    const groupByList = _.groupBy(appointments, 'date');
    let filterList: any = [];

    Object.keys(groupByList).forEach((key) => {
      let newDate = '';
      if(type === 'WEEK'){
        newDate = moment(parseInt(key)).format('dddd');
      }else {
        newDate = moment(parseInt(key)).format('Do-MM-YYYY');
      }
      const findIndex = _.findIndex(labels, item => item === newDate);
      if (findIndex > -1) {
        filterList.push({
          label: newDate,
          values: groupByList[key]
        })
      }
    });

    const labelList = labels.map((label) => {
      const filterData = _.filter(filterList, item => item.label === label);
      return {
        label: label,
        values: _.get(filterData, '0.values', [])
      }
    });
    return mapChartValues(labelList);
  }
};

const mapChartValues = (labelList) => {
  return labelList.map((label) => {
    const total = _.sumBy(label.values, 'totalPrice');
    return total > 0 ? total : 0;
  });
};

export const getReportDescriptions = (reportsData, shop) => {
  const servicePrice = getCurrencyWithPrice(_.get(reportsData, 'totalServicePrice', 0), shop.currency);
  const salesPrice = getCurrencyWithPrice(_.get(reportsData, 'totalSalesPrice', 0), shop.currency);
  const refundsPrice = getCurrencyWithPrice(_.get(reportsData, 'totalRefundPrice', 0), shop.currency);
  const productPrice = getCurrencyWithPrice(_.get(reportsData, 'totalProductPrice', 0), shop.currency);
  const netValue = _.get(reportsData, 'totalSalesPrice', 0) - _.get(reportsData, 'totalRefundPrice', 0);
  const netPrice = getCurrencyWithPrice(`${netValue}`, shop.currency);
  const data: any = [];
  data.push(
    {
      description: 'Gross Sales',
      sales: salesPrice,
      refunds: refundsPrice,
      net: netPrice,
    },
    {
      description: 'Discounts & Comps',
      sales: '-',
      refunds: '-',
      net: '-',
    },
    {
      description: 'Net Sales',
      sales: salesPrice,
      refunds: refundsPrice,
      net: netPrice,
    },
    // {
    //   description: 'Total Collected',
    //   sales: salesPrice,
    //   refunds: refundsPrice,
    //   net: netPrice,
    // }
  );
  return data;
};


export const getReportTableColumn = (selectedDateRange, appointments) => {
  const dateRangeValue: any = getDateRangeType(selectedDateRange);

  if (dateRangeValue.type === 'DAY') {
    const startAppointment = appointments.reduce((prev, curr) => moment(prev.time, 'hh:mm A').valueOf() < moment(curr.time, 'hh:mm A').valueOf() ? prev : curr);
    const endAppointment = appointments.reduce((prev, curr) => moment(prev.time, 'hh:mm A').valueOf() < moment(curr.time, 'hh:mm A').valueOf() ? curr : prev);

    let timeArray:any = [];

    const splitDotTimeStart = _.split(startAppointment.time, ':');
    const splitSpaceTimeStart = _.split(splitDotTimeStart[1], ' ');
    const newTimeStart = `${splitDotTimeStart[0]}:00 ${splitSpaceTimeStart[1]}`;

    const splitDotTimeEnd = _.split(endAppointment.time, ':');
    const splitSpaceTimeEnd = _.split(splitDotTimeEnd[1], ' ');
    const newTimeEnd = `${parseInt(splitDotTimeEnd[0])+1}:00 ${splitSpaceTimeEnd[1]}`;

    let newStart = moment(newTimeStart, 'hh:mm A').valueOf();
    let newEnd = moment(newTimeEnd, 'hh:mm A').valueOf();

    while (newStart <= newEnd) {
      const time = moment(newStart).format('hh:mm A');
      timeArray.push(time);
      newStart = moment(newStart)
        .add(60, 'minutes')
        .valueOf();
    }
    return timeArray;
  } else {
    const daysOfRange: any = [];
    let i = 0;
    while (i <= dateRangeValue.dateCount) {
      const date = moment(dateRangeValue.start).add(i, "day");
      if (dateRangeValue.type === 'WEEK') {
        daysOfRange.push(date.format('dddd'));
      } else {
        daysOfRange.push(date.format('Do-MM-YYYY'));
      }
      i++;
    }
    return daysOfRange;
  }
};


export const getDateRangeType = (selectedDate) => {
  const start = moment(selectedDate.startDate.toDateString()).valueOf();
  const end = moment(selectedDate.endDate.toDateString()).valueOf();
  const dateCount = moment(selectedDate.endDate).diff(moment(selectedDate.startDate), 'days');
  const startMonth = moment(selectedDate.startDate).startOf('month').valueOf();
  const endMonth = moment(selectedDate.endDate).endOf('month').valueOf();
  const startDay = moment(selectedDate.startDate).dates();
  const endDay = moment(selectedDate.endDate).dates();
  if (dateCount === 0) {
    return {type: 'DAY', selectedDate: start}
  } else if (dateCount === 6) {
    return {type: 'WEEK', start, end, startMonth, endMonth, startDay, endDay, dateCount}
  } else if (dateCount === 30 || dateCount === 29) {
    return {type: 'MONTH', start, end, startMonth, endMonth, startDay, endDay, dateCount}
  } else {
    return {type: 'DATE_RANGE', start, end, startMonth, endMonth, startDay, endDay, dateCount}
  }
};

export const getReportTableHeader = (dateRange) => {
  const start = moment(dateRange.startDate.toDateString()).valueOf();
  const end = moment(dateRange.endDate.toDateString()).valueOf();
  const today = moment(new Date(Date.now()).toDateString()).valueOf();
  const dateCount = moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days');
  let header = '';

  if (dateCount === 0) {
    const selectedDay = moment(start).day();
    const day = moment(today).day();
    if(selectedDay === day){
      header = 'Today';
    }else if(selectedDay === day - 1){
      header = 'Yesterday';
    }else {
      header = `${moment(start).format('Do-MM-YYYY')} Services Growth)`;
    }
  } else if (dateCount === 6) {
    const selectedWeek = moment(start).week();
    const nowWeek = moment(today).week();
    if(selectedWeek === nowWeek){
      header = 'This Week';
    }else if(selectedWeek === nowWeek - 1){
      header = 'Last Week';
    }else {
      header = `${moment(start).format('Do-MM-YYYY')} to ${moment(end).format('Do-MM-YYYY')} Services Growth`
    }
  } else if (dateCount === 30 || dateCount === 29) {
    const selectedMonth = moment(start).month();
    const nowMonth = moment(today).month();
    if(selectedMonth === nowMonth){
      header = 'This Month';
    }else if(selectedMonth === nowMonth - 1){
      header = 'Last Month';
    }else {
      header = `${moment(start).format('Do-MM-YYYY')} to ${moment(end).format('Do-MM-YYYY')} Services Growth`;
    }
  } else {
    header = `${moment(start).format('Do-MM-YYYY')} to ${moment(end).format('Do-MM-YYYY')} Services Growth`;
  }
  return header;
};
