import {
  setValueToLocalStorage,
  getValueFromLocalStorage,
} from "./../manager/CommonManager";
import Auth from "@aws-amplify/auth";
import { get, isEmpty } from "lodash";
import Repository from "./Repository";
import Storage from "../service/Storage.service";
import GraphqlService from "../service/Graphql.service";
import { UpdateEmployee } from "./schema/Employee.schema";
import { GetShop, UpdateShop } from "./schema/Shop.schema";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { getDecryptedCode } from "../manager/SecurityManager";
import { downloadShopImages } from "../manager/CommonManager";
import { isNetworkError } from "../manager/AppointmentManager";

Auth.configure({
  storage: Storage,
});
class UserRepository extends Repository {
  async storeUserToken(token: string) {
    try {
      return await setValueToLocalStorage(
        "EMPLOYEE_TOKEN",
        JSON.stringify({ token })
      );
    } catch (error) {
      return { error };
    }
  }

  async userSignIn(username, password) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const employeeData = await getValueFromLocalStorage(
          "SELECTED_EMPLOYEE"
        );
        if (!isEmpty(employeeData)) {
          const employee = JSON.parse(employeeData);
          const pin = getDecryptedCode(employee.pin);
          if (pin === password) {
            return employee;
          }
        }
        return null;
      }
      const user = await Auth.signIn(username, password);
      // const creds = await Auth.currentCredentials();
      return user;
    } catch (error) {
      return { error };
    }
  }

  async getUserForId(id) {
    const request = this.buildMessage({});
    try {
      const result = await this.apiGet({
        path: `/user?id=${id}`,
        message: request,
        apiName: this.CLOUD_POS_LAMBDA_API,
      });

      return result.userResult;
    } catch (error) {
      return { error };
    }
  }

  async createBatch(empId, shopId, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("BATCH");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }
      const request = this.buildMessage({ shopId, empId });
      const result = await this.apiPost({
        path: "/batch",
        message: request,
        apiName: this.CLOUD_POS_LAMBDA_API,
      });
      await setValueToLocalStorage("BATCH", JSON.stringify(result.batchResult));
      return result.batchResult;
    } catch (error) {
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createBatch(empId, shopId, ++retryCount);
      }
      return { error };
    }
  }

  getShop = async (shopId) => {
    const isOnline = await this.isCheckOnline();
    try {
      if (!isOnline) {
        const list = await getValueFromLocalStorage("SHOP");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }
      const result: any = await this.API.graphql(
        graphqlOperation(GetShop, { shopId })
      );
      await setValueToLocalStorage("SHOP", JSON.stringify(result.data.getShop));
      downloadShopImages(result.data.getShop);
      return result.data.getShop;
    } catch (error) {
      console.warn("create brand error", error);
      return { error };
    }
  };

  async merchantOnboard(pin, token) {
    const isOnline = await this.isCheckOnline();
    if (!isOnline) {
      const list = await getValueFromLocalStorage("SHOP");
      if (list) {
        return JSON.parse(list);
      }
      return;
    }
    const request = this.buildMessage({ pin, token });
    try {
      const result = await this.apiPost({
        path: `/onboard`,
        message: request,
        apiName: this.CLOUD_POS_LAMBDA_API,
      });
      const shop = get(result, "authResult.data", {});
      await setValueToLocalStorage("SHOP", JSON.stringify(shop));
      // downloadShopImages(shop);
      return result.authResult;
    } catch (error) {
      return { error };
    }
  }

  async resetPassword(param) {
    const request = this.buildMessage(param);
    try {
      const result = await this.apiPost({
        path: `/reset`,
        message: request,
        apiName: this.CLOUD_POS_LAMBDA_API,
      });

      return result.resetResult;
    } catch (error) {
      return { error };
    }
  }

  async updateUser(param) {
    const request = this.buildMessage(param);
    try {
      const result = await this.apiPut({
        path: `/user`,
        message: request,
        apiName: this.CLOUD_POS_LAMBDA_API,
      });
      return result.userResult;
    } catch (error) {
      return { error };
    }
  }

  async getStoredUser() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      return user;
    } catch (error) {
      console.warn("error : ", error);
      return { error };
    }
  }

  async userLogout() {
    try {
      const isOnline = await this.isCheckOnline();
      await setValueToLocalStorage("SELECTED_EMPLOYEE", null);
      if (isOnline) {
        await Auth.signOut({ global: true });
        // Storage.removeItem(
        //   "CognitoIdentityId-ap-southeast-1:ff24a572-1677-4011-9960-f100bd5fe99f"
        // );
      }
      return true;
    } catch (e) {
      console.log(e);
      return { error: e };
    }
  }

  async updateEmployee(param) {
    try {
      const result: any = await this.API.graphql(
        graphqlOperation(UpdateEmployee, { input: param })
      );
      return result.data.updateEmployee;
    } catch (error) {
      console.warn("update employee error", error);
      return { error };
    }
  }

  async updateShop(shop) {
    try {
      const result: any = await this.API.graphql({
        query: UpdateShop,
        variables: { input: shop },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      downloadShopImages(result.data.updateShop);
      return result.data.updateShop;
    } catch (error) {
      console.warn("update shop error", error);
      return { error };
    }
  }

  subscribeServiceMessage = async (
    shopId: string,
    callback: (data: any) => void
  ) => {
    if (!window?.standlone) {
      await GraphqlService.onServiceMessage(shopId, (data) => {
        console.log(">>> subscribe service message", data);
        const serviceMessage = get(data, "data.onServiceMessage", {});
        return callback(serviceMessage);
      });
    }
  };

  getNetworks = async (callback: (data: any) => void) => {
    try {
      await window.api.getNetworks(async (result: any) => {
        callback(result);
      });
    } catch (error) {
      console.log(error)
    }
  };

  getCurrentWifi = async (callback: (data: any) => void) => {
    try {
      await window.api.getCurrentConnection(async (result: any) => {
        return callback(result);
      });
    } catch (error) {
      console.log(error)
    }
  };
}

export default new UserRepository();
