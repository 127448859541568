import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import "../../Styles/ModelStyles.css";
import styles from "../../Styles/ModelStyles.css";
import _, { get, isEmpty, startCase } from "lodash";
import CANCEL from "../../../../assets/icons/cancel.svg";
import IconArrowBack from "../../../../assets/svg/IconArrowBack.svg";
import {
  IssueRefundModalContainer,
  IssueRefundModalHeader,
  IssueRefundModalItemMainContainer,
  IssueRefundModalHeaderButton,
  HeaderLeftItemButton,
  HeaderRightItemButton,
  IssueRefundModalItemContainer,
  IssueRefundModalItemCheckContainer,
  IssueRefundModalItemText,
  IssueRefundModalItemLine,
  IssueRefundModalItemTotalContainer,
  IssueRefundModalNextContainer,
  IssueRefundModalAmountContainer,
  IssueRefundModalAmountLabel,
  IssueRefundModalAmountCurrency,
  IssueRefundModalMaxRefundValue,
  IssueRefundModalReasonHeader,
  IssueRefundModalRefundPrice,
} from "../../Styles/Styled";
import Checkbox from "@material-ui/core/Checkbox";
import IconRadioCheck from "../../../../assets/svg/IconRadioCheck.svg";
import IconRadioUncheck from "../../../../assets/svg/IconRadioUncheck.svg";
import { getCurrencyWithPrice } from "../../../internal/manager/PaymentManager";

const customStyles = {
  content: {
    top: "10%",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 265px)",
    border: "1px solid rgba(112, 112, 112, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const reasons = [
  { value: "RETURNED", label: "Returned Goods" },
  { value: "ACCIDENTAL", label: "Accidental Charges" },
  { value: "CANCEL", label: "Cancelled Order" },
];

const IssueRefundModal = (props) => {
  const {
    transaction,
    shopData,
    isOpen,
    closeModal,
    onCreateRefund,
    currentUser,
  } = props;
  const [cash, setCash] = useState("");
  const [refundAmount, setRefundAmount] = useState(0);
  const [selectedScreen, setSelectedScreen] = useState("REFUND");
  const [selectedHeader, setSelectedHeader] = useState("ITEM");
  const [selectedServices, setSelectedServices] = useState<any>([]);
  const [selectedReason, setSelectedReason] = useState<any>({});
  const [maxRefundAmount, setMaxRefundAmount] = useState(0);
  const [purchaseItems, setPurchaseItems] = useState<any>([]);

  useEffect(() => {
    let items = [];
    if (!isEmpty(get(transaction, "serviceDetails", null))) {
      items = _.get(transaction, "productDetails", []).concat(
        _.get(transaction, "serviceDetails", [])
      );
    } else {
      items = get(transaction, "productDetails", []);
    }
    const total = items
      ?.map((item: any) => item.totalPrice)
      .reduce((prev, curr) => prev + curr, 0);

    let totalDiscount = 0;
    if (!isEmpty(transaction?.discountDetails)) {
      totalDiscount = _.get(transaction, "discountDetails", [])
        .map((item: any) => item.amount)
        .reduce((prev, curr) => prev + curr, 0);
    }
    setPurchaseItems(items);
    setMaxRefundAmount(total - totalDiscount);
  }, [transaction]);

  useEffect(() => {
    const total = selectedServices
      .map((item: any) => item.totalPrice)
      .reduce((prev, curr) => prev + curr, 0);
    const amount = total > maxRefundAmount ? maxRefundAmount : total;
    const totalPrice: any = getCurrencyWithPrice(amount, shopData.currency);
    setRefundAmount(totalPrice);
  }, [selectedServices]);

  const onChangeCash = (text) => {
    setCash(text);
    setSelectedServices([]);
  };

  const setInitialState = () => {
    setCash("");
    setRefundAmount(0);
    setSelectedScreen("REFUND");
    setSelectedHeader("ITEM");
    setSelectedServices([]);
    setSelectedReason({});
  };

  const onClickNext = () => {
    if (selectedScreen === "REFUND") {
      if (
        selectedHeader === "ITEM"
          ? !_.isEmpty(selectedServices)
          : !_.isEmpty(cash)
      ) {
        setSelectedScreen("REASON");
      }
    } else if (!_.isEmpty(selectedReason) && selectedScreen === "REASON") {
      let amount = 0;
      let services: any = [];
      let products: any = [];
      if (selectedHeader === "ITEM") {
        const total = selectedServices
          .map((item: any) => item.totalPrice)
          .reduce((prev, curr) => prev + curr, 0);
        amount = total > maxRefundAmount ? maxRefundAmount : total;

        selectedServices.forEach((item: any) => {
          if (!_.isEmpty(item.productName)) {
            products.push({
              id: item.id,
              quantity: item.quantity,
              amount: item.totalPrice,
            });
          } else {
            services.push({
              id: item.id,
              quantity: item.quantity,
              amount: item.totalPrice,
            });
          }
        });
      } else {
        amount = parseInt(cash);
      }
      const params = {
        amount,
        services,
        products,
        shopId: shopData.shopId,
        empId: currentUser.empId,
        voidType: "PARTIAL_REFUND",
        batchId: transaction.batchId,
        orderId: transaction.orderId,
        reason: selectedReason.value,
      };
      onCreateRefund(params);
      closeModal();
      setInitialState();
    }
  };

  const onClickBack = () => {
    setSelectedScreen("REFUND");
    setSelectedHeader("AMOUNT");
  };

  const onSelectAll = () => {
    const allSelected = purchaseItems.length === selectedServices.length;
    setSelectedServices(allSelected ? [] : purchaseItems);
  };

  const onClickHeader = (type) => {
    setSelectedHeader(type);
  };

  const onSelectService = (service: any) => {
    const isSelected =
      _.findIndex(selectedServices, (item: any) => item.id === service.id) > -1;
    if (isSelected) {
      const filterService = _.filter(
        selectedServices,
        (item) => item.id !== service.id
      );
      setSelectedServices([...filterService]);
    } else {
      selectedServices.push(service);
      setSelectedServices([...selectedServices]);
    }
    setCash("");
  };

  const onSelectReason = (reason: any) => {
    const isSelected = _.get(selectedReason, "value", "") === reason.value;
    setSelectedReason(isSelected ? {} : reason);
  };

  const total = selectedServices
    .map((item: any) => item.totalPrice)
    .reduce((prev, curr) => prev + curr, 0);
  const refundPrice = !_.isEmpty(selectedServices)
    ? total > maxRefundAmount
      ? maxRefundAmount
      : total
    : parseInt(cash);
  const allSelected = purchaseItems?.length === selectedServices?.length;

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.cashInOutModal}
    >
      <IssueRefundModalContainer>
        <IssueRefundModalHeader>
          {selectedScreen === "REFUND" ? (
            <div>{"Issue refund"}</div>
          ) : (
            <img onClick={() => onClickBack()} src={IconArrowBack} />
          )}
          <img onClick={() => closeModal()} src={CANCEL} />
        </IssueRefundModalHeader>

        {selectedScreen === "REFUND" ? (
          <>
            <IssueRefundModalHeaderButton>
              <HeaderLeftItemButton
                onClick={() => onClickHeader("ITEM")}
                isSelected={selectedHeader === "ITEM"}
              >
                {"Items"}
              </HeaderLeftItemButton>
              <HeaderRightItemButton
                onClick={() => onClickHeader("AMOUNT")}
                isSelected={selectedHeader === "AMOUNT"}
              >
                {"Amount"}
              </HeaderRightItemButton>
            </IssueRefundModalHeaderButton>

            {selectedHeader === "ITEM" ? (
              <>
                <div>
                  <IssueRefundModalItemContainer>
                    <IssueRefundModalItemCheckContainer
                      onClick={() => onSelectAll()}
                    >
                      <Checkbox
                        checked={allSelected}
                        icon={<img src={IconRadioUncheck} />}
                        checkedIcon={<img src={IconRadioCheck} />}
                        // onChange={(event) => onSelectAll(event)}
                      />
                      <div>{"Select All Items"}</div>
                    </IssueRefundModalItemCheckContainer>
                  </IssueRefundModalItemContainer>
                  <IssueRefundModalItemLine />
                </div>

                <IssueRefundModalItemMainContainer>
                  {!isEmpty(purchaseItems) &&
                    purchaseItems.map((item: any) => {
                      const price = getCurrencyWithPrice(
                        item.price || item.sellingPrice,
                        shopData.currency
                      );
                      const isSelected =
                        _.findIndex(
                          selectedServices,
                          (data: any) => data.id === item.id
                        ) > -1;
                      return (
                        <div>
                          <IssueRefundModalItemContainer>
                            <IssueRefundModalItemCheckContainer
                              onClick={() => onSelectService(item)}
                            >
                              <Checkbox
                                checked={isSelected}
                                icon={<img src={IconRadioUncheck} />}
                                checkedIcon={<img src={IconRadioCheck} />}
                                // onChange={() => onSelectService(item)}
                              />
                              <div>
                                {startCase(item.title || item.productName)}
                              </div>
                            </IssueRefundModalItemCheckContainer>
                            <IssueRefundModalItemText>{`${item.quantity} X ${price}`}</IssueRefundModalItemText>
                          </IssueRefundModalItemContainer>
                          <IssueRefundModalItemLine />
                        </div>
                      );
                    })}
                </IssueRefundModalItemMainContainer>

                <IssueRefundModalItemContainer style={{ height: "43px" }}>
                  <IssueRefundModalItemTotalContainer>
                    <div style={{ width: "36px" }} />
                    <div>{"Refund amount"}</div>
                  </IssueRefundModalItemTotalContainer>
                  <IssueRefundModalItemText>
                    {refundAmount}
                  </IssueRefundModalItemText>
                </IssueRefundModalItemContainer>
              </>
            ) : (
              <>
                <IssueRefundModalAmountContainer>
                  <IssueRefundModalAmountLabel>
                    {"Amount to refund"}
                  </IssueRefundModalAmountLabel>
                  <IssueRefundModalAmountCurrency>{`${shopData.currency}`}</IssueRefundModalAmountCurrency>
                  <input
                    value={cash}
                    onChange={(number) => onChangeCash(number.target.value)}
                    placeholder="Cash"
                  />
                </IssueRefundModalAmountContainer>
                <IssueRefundModalMaxRefundValue>{`Maximum refund value: ${maxRefundAmount}`}</IssueRefundModalMaxRefundValue>
              </>
            )}
          </>
        ) : (
          <>
            <IssueRefundModalRefundPrice>
              <div>{"Refund Total"}</div>
              <IssueRefundModalItemText>{`${getCurrencyWithPrice(
                refundPrice,
                shopData.currency
              )}`}</IssueRefundModalItemText>
            </IssueRefundModalRefundPrice>

            <IssueRefundModalReasonHeader>
              {"Reason*"}
            </IssueRefundModalReasonHeader>
            <IssueRefundModalItemMainContainer>
              {reasons.map((reason) => {
                const isSelected = selectedReason.value === reason.value;
                return (
                  <IssueRefundModalItemContainer>
                    <IssueRefundModalItemCheckContainer
                      onClick={() => onSelectReason(reason)}
                    >
                      <Checkbox
                        checked={isSelected}
                        icon={<img src={IconRadioUncheck} />}
                        checkedIcon={<img src={IconRadioCheck} />}
                        // onChange={() => onSelectReason(reason)}
                      />
                      <div>{reason.label}</div>
                    </IssueRefundModalItemCheckContainer>
                  </IssueRefundModalItemContainer>
                );
              })}
            </IssueRefundModalItemMainContainer>
          </>
        )}

        {selectedScreen === "REASON" ? (
          <IssueRefundModalNextContainer valid={!_.isEmpty(selectedReason)}>
            <div onClick={() => onClickNext()}>
              <div>{"Refund"}</div>
            </div>
          </IssueRefundModalNextContainer>
        ) : (
          <IssueRefundModalNextContainer
            valid={
              selectedHeader === "ITEM"
                ? !_.isEmpty(selectedServices)
                : !_.isEmpty(cash)
            }
          >
            <div onClick={() => onClickNext()}>
              <div>{"Next"}</div>
            </div>
          </IssueRefundModalNextContainer>
        )}
      </IssueRefundModalContainer>
    </ReactModal>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
  }),
  {}
)(IssueRefundModal);
