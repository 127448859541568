import gql from 'graphql-tag';

export const GetEmployee = gql`
    query GetEmployee($shopId: String!, $empId: String!) {
        getEmployee(shopId: $shopId, empId: $empId) {
            empId
            shopId
            createdUser
            firstName
            lastName
            email
            mobileNumber
            address
            bio
            userId
            name
            createdTime
            updatedTime
            profileImage
            homeImage
            employeeStatus
            isPrimary
            role
            userPosition
            pin
            viewMode
            specializations{
                id
                title
            }
        }
    }`;

export const UpdateEmployee = gql`
    mutation UpdateEmployee($input: UpdateEmployeeInput!) {
        updateEmployee(input: $input) {
            empId
            shopId
            createdUser
            firstName
            lastName
            email
            mobileNumber
            address
            bio
            userId
            name
            createdTime
            role
            updatedTime
            profileImage
            homeImage
            employeeStatus
            isPrimary
            userPosition
            pin
            viewMode
            specializations{
                id
                title
            }
        }
    }`;
