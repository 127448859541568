import gql from 'graphql-tag';

export const CreateOffer = gql`
  mutation CreateOffer($input: CreateOfferInput!) {
    createOffer(input: $input) {
    	id
      shopId
      title
      displayTitle
      isEveryDay
      createdTime
      updatedTime
      timePeriod {
        startTime
        endTime
      }
      timePeriods {
        date
        startTime
        endTime
      }
      offerItems {
        pid
        name
        type
        quantity
        discount {
          amount
          amountType
        }
        products {
          pid
          name
          quantity
        }
      }
    }
  }
`;

export const UpdateOffer = gql`
  mutation UpdateOffer($input: UpdateOfferInput!) {
    updateOffer(input: $input) {
      id
      shopId
      title
      displayTitle
      isEveryDay
      createdTime
      updatedTime
      timePeriod {
        startTime
        endTime
      }
      timePeriods {
        date
        startTime
        endTime
      }
      offerItems {
        pid
        name
        type
        quantity
        discount {
          amount
          amountType
        }
        products {
          pid
          name
          quantity
        }
      }
    }
  }
`;

export const DeleteOffer = gql`
  mutation DeleteOffer($input: DeleteOfferInput!) {
    deleteOffer(input: $input) {
      id
      shopId
    }
  }
`;

export const FetchOffers = gql`
  query FetchOffers($shopId: String!, $limit: Int, $nextToken: String) {
    fetchOffers(shopId: $shopId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopId
        title
        displayTitle
        isEveryDay
        createdTime
        updatedTime
        timePeriod {
          startTime
          endTime
        }
        timePeriods {
          date
          startTime
          endTime
        }
        offerItems {
          pid
          name
          type
          quantity
          discount {
            amount
            amountType
          }
          products {
            pid
            name
            quantity
          }
        }
      }
      nextToken
    }
  }
`;