import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  position: absolute;
  background: rgba(0,0,0, 0.45);
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    border: 2px solid: #fff;
    background: rgba(0,0,0, 0.65);
    height: 200px;
    width: 300px;
    border-radius: 24px;
    paddign: 40px;


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & > div > h1 {
    text-align: center;
    color: #fff;
    font-size: 24px;
  }

  & > div > div > svg {
    color: #fff;
  }
`;


