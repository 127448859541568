import React from "react";
import { SettingOpWrapper } from "./Styled";

const SettingOptionInput = ({ label, value, onChange, disabled = false }) => {
  return (
    <SettingOpWrapper>
      <div>{label}</div>
      <input
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </SettingOpWrapper>
  );
};

export default SettingOptionInput;
