import React, {Component, useState} from 'react';
import styles from '../../Styles/Styles.css';

const GenerateReceipt = (props) => {
  const {printReciept} = props;
  return (
    <button onClick={() => printReciept()} className={styles.generateReceiptButton}>Generate a receipt</button>
  );
};
export default GenerateReceipt;
