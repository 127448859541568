import {ModuleEvents} from './Actions';
import {Actions} from './../../internal/app/Actions';

export default {
  [ModuleEvents.CREATE_CASH_OPERATION]: ({dispatch, payload, appState}) => {
    if (payload && !payload.error) {
      const batchSession = appState.login.get('batchSession');
      const shopData = appState.login.get('merchantShop');
      dispatch(Actions.closeCash.getBatchSession(batchSession.batchId));
      dispatch(Actions.closeCash.getBatchSummary(batchSession.batchId));
      dispatch(Actions.closeCash.completedCashAppointments(batchSession.batchId, shopData.shopId));
    }
  },
  [ModuleEvents.UPDATE_CASH_OPERATION]: ({dispatch, payload, appState}) => {
    if (payload && !payload.error) {
      const batchSession = appState.login.get('batchSession');
      const shopData = appState.login.get('merchantShop');
      dispatch(Actions.closeCash.getBatchSession(batchSession.batchId));
      dispatch(Actions.closeCash.getBatchSummary(batchSession.batchId));
      dispatch(Actions.closeCash.completedCashAppointments(batchSession.batchId, shopData.shopId));
    }
  },
  [ModuleEvents.CLOSE_CASH]: ({dispatch, payload, appState}) => {
    if (payload && !payload.error) {
      const shopData = appState.login.get('merchantShop');
      const currentUser = appState.login.get('currentUser');
      if (payload.status === 'ACTIVE') {
        dispatch(Actions.closeCash.getBatchSession(payload.batchId));
        dispatch(Actions.closeCash.completedCashAppointments(payload.batchId, shopData.shopId));
      } else {
        dispatch(Actions.closeCash.createBatchSession(shopData.shopId, currentUser.empId));
      }
      setTimeout(() => {
        dispatch(Actions.closeCash.getBatchSummary(payload.batchId));
      }, 2000);
    }
  },

  [ModuleEvents.CREATE_BATCH_SESSION]: ({dispatch, payload, appState}) => {
    if (payload && !payload.error) {
      const shopData = appState.login.get('merchantShop');
      const currentUser = appState.login.get('currentUser');

      dispatch(Actions.closeCash.completedCashAppointments(payload.batchId, shopData.shopId));
      dispatch(Actions.closeCash.getPastBatchSummary(shopData.shopId, currentUser.empId));
      setTimeout(() => {
        dispatch(Actions.closeCash.getBatchSummary(payload.batchId));
      }, 2000);
    }
  },
}
