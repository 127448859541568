import * as wifi from "node-wifi";
// import scanner  from 'node-wifi-scanner';

// wifi.init({
//   iface: null,
//   debug: true,
//   delay: 5,
// });

class WifiService {
  async getCurrentConnection() {
    // scanner.scan((err, networks) => {
    //   if (err) {
    //     console.error(err);
    //     return;
    //   }
    //   console.log(networks);
    // });
    try {
      return new Promise((res) => {
        res(null);
        // wifi.getCurrentConnections((error: any, currentConnections: any) => {
        //   console.log({ currentConnections });
        //   if (error) {
        //     console.warn(error);
        //     res(error);
        //   } else {
        //     res(currentConnections[0]);
        //   }
        // });
      });
    } catch (error) {
      return { error };
    }
  }

  async getAllWifiConnections() {
    try {
      return new Promise((res) => {
        res([]);
        // wifi.scan((error: any, networks: any) => {
        //   if (error) {
        //     console.warn(error);
        //     res(error);
        //   } else {
        //     res(networks);
        //   }
        // });
      });
    } catch (error) {
      return { error };
    }
  }
}

export default new WifiService();
