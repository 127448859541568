import React from "react";
import { Container, Row, Col } from "react-grid-system";
import styles from "../../../components/Common/Style/Styles.css";
import { CenterContainer } from "../../../components";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import "../Styles.css";

const LoadingView = (props) => {
  const { progressCount } = props;

  return (
    <div className={styles.background}>
      <CenterContainer width="592px">
        <Container>
          <Row>
            <Col xs={12} className="loadingProgressHeader">
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  marginTop: "40%",
                }}
              >
                <h1 className={styles.welcomeText}>Loading</h1>
                <LinearProgress variant="determinate" value={progressCount} />
                <div className="loading-please-wait-text">
                  <div className="please-wait-text">Please wait</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </CenterContainer>
    </div>
  );
};

export default connect(
  (state) => ({
    isStopProgress: state.login.get("isStopProgress"),
    progressCount: state.login.get("progressCount"),
  }),
  {}
)(LoadingView);
