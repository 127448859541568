import React, { useEffect, useState } from "react";
import _ from "lodash";
import styles from "./Styles.css";
import { connect } from "react-redux";
import { Row, Col } from "react-grid-system";
import { Actions } from "../../../internal/app/Actions";
import Invoice from "../../../components/table/views/Invoice";
import ReceiptCard from "../../../components/card/ReceiptCard";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";

const { innerHeight } = window;

const InvoiceView = (props) => {
  const {
    salon,
    fetchSalonAppointment,
    fetchEmployeesForSalonId,
    salonAppointments,
    fetchPaginateAppointment,
  } = props;
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState({});

  useEffect(() => {
    fetchSalonAppointment(salon.shopId);
    fetchEmployeesForSalonId(salon.shopId);
  }, [salon]);

  useEffect(() => {
    setAppointments(_.get(salonAppointments, "appointments", []));
    const lastKey = _.get(salonAppointments, "lastKey", null);
    if (!_.isEmpty(lastKey)) {
      fetchPaginateAppointment(salon.shopId, 50, lastKey);
    }
  }, [salonAppointments]);

  return (
    <div style={{ backgroundColor: "#EFF3F6" }}>
      <Row>
        <Col xs={12}>
          <TopNavigationBar viewName={"Invoices"} path="/menu" />
        </Col>
      </Row>

      <div className={styles.invoiceWrapper}>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            height: innerHeight - 100,
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "3px",
          }}
        >
          <Row>
            <Col xs={!_.isEmpty(selectedAppointment) ? 8 : 12}>
              <Invoice
                appointments={appointments}
                onPressAppointment={(data) => setSelectedAppointment(data)}
              />
            </Col>
            {!_.isEmpty(selectedAppointment) ? (
              <Col xs={4}>
                <ReceiptCard appointment={selectedAppointment} />
              </Col>
            ) : null}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    salon: state.salon.get("salon"),
    salonAppointments: state.orders.get("salonAppointments"),
  }),
  {
    fetchSalonAppointment: Actions.orders.fetchSalonAppointment,
    fetchPaginateAppointment: Actions.orders.fetchPaginateAppointment,
    fetchEmployeesForSalonId: Actions.employee.fetchEmployeesForSalonId,
  }
)(InvoiceView);
