import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { DiscountRowItem } from "./Styled";
import {
  getCurrencyWithPrice,
  getAppointmentTotalPrice,
} from "../../../internal/manager/PaymentManager";
import { DiscountContainer } from "../../Styles/Styled";
import { Actions } from "../../../internal/app/Actions";
import AddDiscountModal from "../../model/views/AddDiscountModal";
import ProductDeleteModal from "../../model/views/ProductDeleteModal";
import IconDelete from "../../../../assets/svg/IconDiscountDelete.svg";
import { getDiscountTitle } from "../../../internal/manager/AppointmentManager";

const Discounts = (props) => {
  const {
    shopData,
    orderOffers,
    appDiscounts,
    purchaseProducts,
    purchaseServices,
    appointmentDiscounts,
    setAppointmentDiscount,
    removeAppointmentDiscount,
  } = props;
  const [isOpenDiscountModal, setIsOpenDiscountModal] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [openDiscountModal, setOpenDiscountModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    setDiscounts(appointmentDiscounts);
  }, [appointmentDiscounts]);

  const toggleDiscountModal = () => {
    setSelectedItem({});
    setIsOpenDiscountModal(!isOpenDiscountModal);
  };

  const toggleDiscountDelete = () => {
    setOpenDiscountModal(!openDiscountModal);
  };
  const onClickRemove = (discount) => {
    setSelectedItem(discount);
    toggleDiscountDelete();
  };

  const onClickEdit = (discount) => {
    setSelectedItem(discount);
    setIsOpenDiscountModal(!isOpenDiscountModal);
  };

  const removeSelectedItem = () => {
    removeAppointmentDiscount(selectedItem);
    toggleDiscountDelete();
  };

  const onAddDiscount = (discount) => {
    let purchaseList = purchaseServices.concat(purchaseProducts);
    let { total: totalPrice } = getAppointmentTotalPrice(
      purchaseList,
      appointmentDiscounts,
      shopData,
      orderOffers
    );
    const percentage =
      discount.amountType === "PERCENTAGE"
        ? discount.amount
        : ((parseFloat(discount.amount) / totalPrice) * 100).toFixed(2);
    const amount =
      discount.amountType === "FIXED_AMOUNT"
        ? discount.amount
        : ((parseFloat(discount.amount) * totalPrice) / 100).toFixed(2);

    const params = {
      id: discount.discId,
      title: `${discount.title} - ${getDiscountTitle(
        discount,
        shopData.currency
      )}`,
      amount,
      amountType: discount.amountType,
      percentage,
    };
    setAppointmentDiscount([params]);
  };

  return (
    <div>
      <DiscountContainer>
        <button onClick={() => toggleDiscountModal()}>{"Add Discount"}</button>
        {!_.isEmpty(discounts)
          ? discounts.map((discount) => {
              const price = getCurrencyWithPrice(
                discount.amount,
                shopData.currency
              );
              return (
                <div>
                  <div onClick={() => onClickEdit(discount)}>
                    <div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickRemove(discount);
                        }}
                      >
                        <img src={IconDelete} />
                      </div>
                      <div>{discount.title}</div>
                    </div>
                    <div>{price}</div>
                  </div>
                  <div />
                </div>
              );
            })
          : null}
      </DiscountContainer>
      {appDiscounts?.discounts?.map((disc) => (
        <DiscountRowItem key={disc.id}>
          <h3>
            {disc.title} - {getDiscountTitle(disc, shopData.currency)}
          </h3>
          <div>
            <p>{disc.desc}</p>
            <button onClick={() => onAddDiscount(disc)}>Apply</button>
          </div>
        </DiscountRowItem>
      ))}
      <AddDiscountModal
        isOpen={isOpenDiscountModal}
        closeModal={() => toggleDiscountModal()}
        discountItem={selectedItem}
      />
      <ProductDeleteModal
        isOpen={openDiscountModal}
        closeModal={() => toggleDiscountDelete()}
        removeProduct={() => removeSelectedItem()}
        status={"DISCOUNT"}
      />
    </div>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    appDiscounts: state.orders.get("discounts"),
    orderOffers: state.offer.get("orderOffers"),
    purchaseProducts: state.product.get("purchaseProducts"),
    purchaseServices: state.service.get("purchaseServices"),
    appointmentDiscounts: state.product.get("appointmentDiscounts"),
  }),
  {
    setAppointmentDiscount: Actions.product.setAppointmentDiscount,
    removeAppointmentDiscount: Actions.product.removeAppointmentDiscount,
  }
)(Discounts);
