import { createAction } from "../../internal/app/AppUtils";
import OrderRepository from "../../internal/repos/OrderRepository";
import PaymentRepository from "../../internal/repos/PaymentRepository";
import AppointmentRepository from "../../internal/repos/AppointmentRepository";

export const ModuleEvents = {
  REOPEN_ORDER: "REOPEN_ORDER",
  CREATE_REFUND: "CREATE_REFUND",
  CREATE_VEHICLE: "CREATE_VEHICLE",
  FETCH_VEHICLES: "FETCH_VEHICLES",
  UPDATE_VEHICLE: "UPDATE_VEHICLE",
  FETCH_DISCOUNTS: "FETCH_DISCOUNTS",
  OPEN_SAVE_MODAl: "OPEN_SAVE_MODAl",
  CHANGE_VIEW_MODE: "CHANGE_VIEW_MODE",
  ON_SELECT_VEHICLE: "ON_SELECT_VEHICLE",
  CREATE_APPOINTMENT: "CREATE_APPOINTMENT",
  SEARCH_TRANSACTIONS: "SEARCH_TRANSACTIONS",
  SELECTED_APPOINTMENT: "SELECTED_APPOINTMENT",
  ON_SUBSCRIPTION_ORDER: "ON_SUBSCRIPTION_ORDER",
  FETCH_ALL_APPOINTMENTS: "FETCH_ALL_APPOINTMENTS",
  SEARCH_SERVICE_AND_PRODUCT: "SEARCH_SERVICE_AND_PRODUCT",
  FETCH_APPOINTMENT_FOR_DATE: "FETCH_APPOINTMENT_FOR_DATE",
  FETCH_PAGINATE_APPOINTMENTS: "FETCH_PAGINATE_APPOINTMENTS",
  UPDATE_COMPLETED_APPOINTMENT: "UPDATE_COMPLETED_APPOINTMENT",
  ON_SUBSCRIPTION_DELETE_ORDER: "ON_SUBSCRIPTION_DELETE_ORDER",
  FETCH_COMPLETED_APPOINTMENTS: "FETCH_COMPLETED_APPOINTMENTS",
  FETCH_UN_COMPLETED_APPOINTMENTS: "FETCH_UN_COMPLETED_APPOINTMENTS",
  FILTER_UN_COMPLETED_APPOINTMENTS: "FILTER_UN_COMPLETED_APPOINTMENTS",
  PAGINATE_UN_COMPLETED_APPOINTMENTS: "PAGINATE_UN_COMPLETED_APPOINTMENTS",
};

const Actions = {
  createAppointment: createAction(
    ModuleEvents.CREATE_APPOINTMENT,
    async () => {
      const appointmentResult = await PaymentRepository.createAppointment();
      return appointmentResult;
    },
    { loading: true }
  ),

  fetchPaginateAppointment: createAction(
    ModuleEvents.FETCH_PAGINATE_APPOINTMENTS,
    async (shopId, limit = 50, lastKey = null) => {
      const result = await AppointmentRepository.fetchShopOrders(
        shopId,
        limit,
        lastKey
      );
      return result;
    },
    { loading: true }
  ),

  fetchCompletedAppointments: createAction(
    ModuleEvents.FETCH_COMPLETED_APPOINTMENTS,
    async (shopId, limit = 10, lastKey = null) => {
      const result = await AppointmentRepository.fetchCompletedAppointments(
        shopId,
        limit,
        lastKey
      );
      return {result, lastKey};
    },
    { loading: true }
  ),

  fetchUnCompletedAppointments: createAction(
    ModuleEvents.FETCH_UN_COMPLETED_APPOINTMENTS,
    async (shopId, limit = 50, lastKey = null) => {
      const result = await AppointmentRepository.fetchUnCompletedAppointments(
        shopId,
        limit,
        lastKey
      );

      return result;
    },
    { loading: true }
  ),
  paginateUnCompletedAppointments: createAction(
    ModuleEvents.PAGINATE_UN_COMPLETED_APPOINTMENTS,
    async (shopId, limit = 50, lastKey = null) => {
      const result = await AppointmentRepository.fetchUnCompletedAppointments(
        shopId,
        limit,
        lastKey
      );
      return result;
    },
    { loading: true }
  ),
  filterUnCompletedAppointments: createAction(
    ModuleEvents.FILTER_UN_COMPLETED_APPOINTMENTS,
    async (params: any) => {
      const result = await AppointmentRepository.filterUnCompletedAppointments(
        params
      );
      return result;
    },
    { loading: true }
  ),
  selectedAppointment: createAction(
    ModuleEvents.SELECTED_APPOINTMENT,
    (appointment) => {
      return appointment;
    },
    { loading: true }
  ),
  createRefund: createAction(
    ModuleEvents.CREATE_REFUND,
    async (params) => {
      const result = await AppointmentRepository.createRefund(params);
      return result;
    },
    { loading: true }
  ),
  fetchAppointmentForDate: createAction(
    ModuleEvents.FETCH_APPOINTMENT_FOR_DATE,
    async (shopId, date, limit = 50, lastKey = null) => {
      const result = await AppointmentRepository.fetchAppointmentForDate(
        shopId,
        date,
        limit,
        lastKey
      );
      return result;
    },
    { loading: true }
  ),
  searchTransactions: createAction(
    ModuleEvents.SEARCH_TRANSACTIONS,
    async (shopId, searchText, limit = 50, lastKey = null) => {
      const result = await AppointmentRepository.searchTransactions(
        shopId,
        searchText,
        limit,
        lastKey
      );
      return result;
    },
    { loading: true }
  ),
  updateCompletedAppointment: createAction(
    ModuleEvents.UPDATE_COMPLETED_APPOINTMENT,
    async (data) => {
      data["shopOrderStatus"] = `${data.shopId}_${data.orderStatus}`;
      const appointmentResult = await AppointmentRepository.updateOrder(data);
      return appointmentResult;
    },
    { loading: true }
  ),
  searchServiceAndProduct: createAction(
    ModuleEvents.SEARCH_SERVICE_AND_PRODUCT,
    async (shopId, searchText) => {
      const result = await OrderRepository.searchServiceAndProduct(
        shopId,
        searchText
      );
      return result;
    },
    { loading: true }
  ),

  fetchVehicles: createAction(
    ModuleEvents.FETCH_VEHICLES,
    async (shopId, clientId, limit = 20, lastKey = null) => {
      const result = await OrderRepository.fetchVehicles(
        shopId,
        clientId,
        limit,
        lastKey
      );
      return result;
    },
    { loading: true }
  ),

  onSelectVehicle: createAction(
    ModuleEvents.ON_SELECT_VEHICLE,
    async (data) => {
      return data;
    },
    { loading: true }
  ),

  createVehicle: createAction(
    ModuleEvents.CREATE_VEHICLE,
    async (params) => {
      const result = await OrderRepository.createVehicle(params);
      return result;
    },
    { loading: true }
  ),

  updateVehicle: createAction(
    ModuleEvents.UPDATE_VEHICLE,
    async (params) => {
      const result = await OrderRepository.updateVehicle(params);
      return result;
    },
    { loading: true }
  ),

  changeViewMode: createAction(ModuleEvents.CHANGE_VIEW_MODE, async (data) => {
    return data;
  }),

  fetchDiscounts: createAction(ModuleEvents.FETCH_DISCOUNTS, async (shopId) => {
    const discounts = await OrderRepository.fetchDiscounts(shopId);
    return discounts;
  }),
  reopenOrder: createAction(
    ModuleEvents.REOPEN_ORDER,
    async (data) => {
      data["shopOrderStatus"] = `${data.shopId}_${data.orderStatus}`;
      const appointmentResult = await AppointmentRepository.updateOrder(data);
      return appointmentResult;
    },
    { loading: true }
  ),
  openSaveModal: createAction(ModuleEvents.OPEN_SAVE_MODAl, async (status) => {
    return status;
  }),
  onSubscriptionOrder: createAction(
    ModuleEvents.ON_SUBSCRIPTION_ORDER,
    async (order) => {
      return order;
    }
  ),
  onSubscriptionDeleteOrder: createAction(
    ModuleEvents.ON_SUBSCRIPTION_DELETE_ORDER,
    async (order) => {
      return order;
    }
  ),
};
export default Actions;
