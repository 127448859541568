import React, {useState} from 'react';
import {connect} from "react-redux";
import 'react-dropdown/style.css';
import masterCardIcon from '../../../assets/icons/masterCardIcon.svg';
import visaCardIcon from '../../../assets/icons/visaCardIcon.svg';
import {CardComponentContainer} from '../../components/Styles/Styled';

const cardTypes = [
  {value: "Master", image: masterCardIcon, label: "Master card"},
  {value: "Visa", image: visaCardIcon, label: "Visa card"}
];

const CardComponent = (props) => {
  const {isClickComplete, onChangeNumber, onChangeName} = props;
  const [isValid, setIsValid] = useState(false);

  const onChangeCardNumber = (text) => {
    setIsValid(text.length > 3);
    onChangeNumber(text)
  };

  return (
    <CardComponentContainer>
      <div>
        <div>
          <div>Card last 4 digits</div>
          <input onChange={(event) => onChangeCardNumber(event.target.value)} placeholder="Card last 4 digits"/>
          {
            !isValid && isClickComplete ?
              <div>*Please enter last 4 digits</div>
              : null
          }
        </div>

        <div>
          <div>Card type</div>
          <div>
            <img src={masterCardIcon}/>
            <div id={'cardDetailSelectText'}>{'Master card'}</div>
          </div>
        </div>
      </div>

      <div>
        <div>Name</div>
        <input onChange={(event) => onChangeName(event.target.value)} placeholder="Card holder name"/>
      </div>
    </CardComponentContainer>
  );
};
export default connect(
  state => ({}),
  ({})
)(CardComponent);
