import React, { useEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";
import "antd/dist/antd.css";
import { connect } from "react-redux/es/index";
import "../Styles.style.css";
import { Actions } from "../../../internal/app/Actions";
import EditProductItemTopHeader from "../../../components/product/views/EditProductItemTopHeader";
import _ from "lodash";
import ServiceWarningModal from "../../../components/model/views/ServiceWarningModal";
import { navigate } from "../../../internal/service/Navigation.service";
import { nanoid } from "nanoid";

const ProductTypeAddView = (props) => {
  const {
    shop,
    deleteProductType,
    productType,
    createProductType,
    updateProductType,
    currentUser,
  } = props;

  const [typeName, setTypeName] = useState("");
  const [description, setDescription] = useState("");
  const [isOpenDiscardModel, setIsOpenDiscardModel] = useState(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(productType)) {
      setTypeName(productType.typeName);
      setDescription(productType.description);
    }
  }, [productType]);

  const isEdit = !_.isEmpty(productType);

  const onCreateProductType = () => {
    const time = Date.now();
    let param = {
      typeName,
      description,
      id: nanoid(8),
      shopId: shop.shopId,
      updatedTime: time,
      createdUserId: currentUser.empId,
    };
    if (isEdit) {
      param.id = productType.id;
      updateProductType(param);
    } else {
      param.createdTime = time;
      createProductType(param);
    }
  };

  const onDeleteType = () => {
    const param = {
      id: productType.id,
      shopId: shop.shopId,
    };

    deleteProductType(param);
  };

  const isValid = !_.isEmpty(typeName);
  const isHasntProducts = true;

  return (
    <div className="addCategoryMainContainer">
      <TopNavigationBar viewName="Products" path="/menu" />
      <div className="addServiceViewContainer">
        <div className="addServiceViewWrapper">
          <EditProductItemTopHeader
            onCreateService={() => {
              if (isValid) {
                onCreateProductType();
              }
            }}
            isEdit={isEdit}
            onBack={() => {
              if (isEdit) {
                setIsOpenDiscardModel(true);
              } else {
                navigate("/products-customized");
              }
            }}
            isValid={isValid}
            buttonText={"Save Product Type"}
            backBtnText={isEdit ? "Edit Brand" : "Add Product Type"}
          />
          <Row>
            <Col xs={12}>
              <div className="serviceAddFormContainer">
                <div className="addServiceDetailsText">Details</div>
                <div className="addCategoryInputContainer">
                  <Row>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">Product Type Name*</div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input
                        onChange={(text) => setTypeName(text.target.value)}
                        value={typeName}
                        className="serviceTextInput"
                        placeholder="Ex: Alcohol, Beverages, Kitchen"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "30px" }}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">Description</div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <textarea
                        onChange={(text) => setDescription(text.target.value)}
                        value={description}
                        className="serviceTextAreaInput"
                        placeholder=""
                      />
                    </Col>
                  </Row>
                  {isEdit ? (
                    <Row style={{ marginTop: "20px" }}>
                      <Col xs={2} className="serviceTitleCol" />
                      <Col xs={10} className="serviceTitleColInput">
                        <div
                          onClick={() => {
                            setIsOpenDeleteModel(true);
                          }}
                          className="categoryDeleteBtnContainer"
                          style={{
                            backgroundColor: isHasntProducts
                              ? "#FFD6D6"
                              : "#8A9CA5",
                            border: isHasntProducts
                              ? "1px solid #FF5F5F"
                              : "1px solid #8A9CA5",
                          }}
                        >
                          <div
                            className="serviceDeleteBtnText"
                            style={{
                              color: isHasntProducts ? "#FF5F5F" : "#ffffff",
                            }}
                          >
                            Delete Type
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ServiceWarningModal
        isOpen={isOpenDiscardModel}
        headerText={"Are you sure?"}
        bodText={"All your changes will be discarded"}
        cancelBtnText={"Discard"}
        onCancel={() => {
          setIsOpenDiscardModel(false);
          navigate("/products-customized");
        }}
        closeModal={() => setIsOpenDiscardModel(false)}
      />
      <ServiceWarningModal
        isOpen={isOpenDeleteModel}
        headerText={"Warning!"}
        bodText={
          "Your Product Type will be deleted and no longer\n available to order for the users"
        }
        cancelBtnText={"Delete"}
        onCancel={() => {
          onDeleteType();
          setIsOpenDeleteModel(false);
        }}
        closeModal={() => setIsOpenDeleteModel(false)}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    shop: state.login.get("merchantShop"),
    productType: state.product.get("productType"),
    currentUser: state.login.get("currentUser"),
  }),
  {
    updateProductType: Actions.product.updateProductType,
    createProductType: Actions.product.createProductType,
    deleteProductType: Actions.product.deleteProductType,
  }
)(ProductTypeAddView);
