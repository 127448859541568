import React from "react";
import _ from "lodash";
import ReactModal from "react-modal";
import "../../Styles/ModelStyles.css";
import { connect } from "react-redux";
import styles from "../../Styles/ModelStyles.css";
import SaveButton from "../../Buttons/Views/SaveButton";
import CANCEL from "../../../../assets/icons/cancel.svg";
import { NeedApprovalContainer } from "../../Styles/Styled";
import CancelButton from "../../Buttons/Views/CancelButton";
import UserDefaultImage from "../../../../assets/images/default-user.png";
import { getCanApprovalUsers } from "../../../internal/manager/EmployeeManager";

const customStyles = {
  content: {
    top: "20%",
    left: "calc(50% - 266px)",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const NeedApprovalModel = (props) => {
  const {
    onClose,
    isOpen,
    loginEmployees,
    selectedEmployee,
    onSelectEmployee,
    onOpenPinModal,
    onCancel,
  } = props;
  const isValid = !_.isEmpty(selectedEmployee);
  const filterEmployees = getCanApprovalUsers(loginEmployees);

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.approvalEmployeeModal}
    >
      <NeedApprovalContainer>
        <div
          onClick={() => {
            onClose();
          }}
        >
          <div>Need Approval</div>
          <img src={CANCEL} />
        </div>
      </NeedApprovalContainer>
      <div>
        <div className={styles.welcomeEmployeeCardContainer}>
          {filterEmployees.map((employee) => {
            const isSelected = selectedEmployee?.empId === employee?.empId;
            let specializationText =
              employee.specializations &&
              employee.specializations.map((item) => item.title).join(", ");

            return (
              <div
                style={{
                  backgroundColor: isSelected ? "#49a4d4" : "#FFFFFF",
                  border: `1px solid ${!isSelected ? "#8A9CA5" : "#49A4D4"}`,
                }}
                className={styles.approvalEmployeeBox}
                onClick={() => onSelectEmployee(employee)}
              >
                <div className={styles.approvalUserImageContainer}>
                  <img
                    className={styles.approvalUserImageContainer}
                    src={UserDefaultImage}
                  />
                </div>
                <div style={{ marginLeft: "20px", marginRight: "10px" }}>
                  <div
                    style={{
                      color: isSelected ? "rgba(255,255,255,.85)" : "#353C42",
                    }}
                    className={styles.welcomeEmployeeName}
                  >
                    {employee.name}
                  </div>
                  <div
                    style={{
                      color: isSelected ? "rgba(255,255,255,.85)" : "#353C42",
                    }}
                    className={styles.welcomeEmployeeType}
                  >
                    {specializationText}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.approvalModalBottomBtn}>
          <CancelButton onClick={() => onCancel()} />
          <SaveButton
            text={"Enter Pin"}
            isValid={isValid}
            onClick={() => {
              if (isValid) {
                onOpenPinModal();
              }
            }}
          />
        </div>
      </div>
    </ReactModal>
  );
};

export default connect(
  (state) => ({
    loginEmployees: state.employee.get("loginEmployees"),
  }),
  {}
)(NeedApprovalModel);
