import React, {Component} from 'react';
import styles from '../../../components/Styles/Styles.css';
import {Actions} from '../../../internal/app/Actions';
import {connect} from "react-redux";
import {Col, Row} from "react-grid-system";

const data = [
  {
    label: 'Sales',
    value: 'SALES',
  },
];


const ReportsSideNav = (props) => {
  const {selectedView, onClick} = props;

  return (
    <Row style={{height: '100%', boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)', borderTopLeftRadius: '3px'}}>
      <Col style={{padding: 0}} xs={12}>
        <ul>
          {
            data.map((item, index) => {
              const isSelected = selectedView === item.value;
              return (
                <li>
                  <a style={{
                    borderTopLeftRadius: index === 0 ? '3px' : 0,
                    backgroundColor: isSelected ? '#49a4d4' : '',
                    color: isSelected ? '#FFFFFF' : '#000000'
                  }} onClick={() => onClick(item.value)} className={styles.addAppointmentSideNav}>{item.label}</a>
                </li>
              )
            })
          }
        </ul>
      </Col>
    </Row>
  );
};
export default connect(
  state => ({}),
  ({})
)(ReportsSideNav);
