import React from "react";
import { RadioWrapper } from "./Styled";
import Toggle from "react-toggle";

const RadioInput = ({ onChange, value, children, disabled = false }) => {
  return (
    <RadioWrapper>
      <div>
        <Toggle disabled={disabled} onChange={(e) => onChange(e.target.checked)} defaultChecked={Boolean(value)} />
      </div>
      {children}
    </RadioWrapper>
  );
};

export default RadioInput;
