import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { ModuleEvents as PaymentModule } from "../payment/Actions";
import { filter, isEmpty, orderBy, findIndex } from "lodash";

const initialState = Map({
  offer: {
    offers: [],
    nextToken: null,
  },
  orderOffers: [],
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.FETCH_OFFERS: {
      const offer: any = state.get("offer");
      if (payload && !payload.error) {
        const sortOffers = orderBy(payload.items, ["updatedTime"], ["desc"]);
        offer.offers = sortOffers;
        offer.nextToken = payload.nextToken;
        return state.set("offer", { ...offer });
      }
      return state;
    }
    case ModuleEvents.CREATE_OFFER: {
      if (payload && !payload.error) {
        const offer: any = state.get("offer");
        if (!isEmpty(offer.offers)) {
          offer.offers.push(payload);
        }
        const sortOffers = orderBy(offer.offers, ["updatedTime"], ["desc"]);
        offer.offers = sortOffers;
        return state.set("offer", { ...offer });
      }
      return state;
    }
    case ModuleEvents.UPDATE_OFFER: {
      if (payload && !payload.error) {
        const offer: any = state.get("offer");
        const index = findIndex(offer.offers, ({ id }) => id === payload.id);
        offer.offers[index] = payload;
        const sortOffers = orderBy(offer.offers, ["updatedTime"], ["desc"]);
        offer.offers = sortOffers;
        return state.set("offer", { ...offer });
      }
      return state;
    }
    case ModuleEvents.DELETE_OFFER: {
      if (payload && !payload.error) {
        const offer: any = state.get("offer");
        const offers = filter(offer.offers, ({ id }) => id != payload.id);
        const sortOffers = orderBy(offers, ["updatedTime"], ["desc"]);
        offer.offers = sortOffers;
        return state.set("offer", { ...offer });
      }
      return state;
    }

    case ModuleEvents.SET_ORDER_OFFER: {
      const orderOffers: any = state.get("orderOffers");
      if (payload && payload.length > 0) {
        payload.forEach((offer: any) => {
          const index = orderOffers.findIndex(
            (item: any) => item.offerId === offer.offerId
          );
          if (index > -1) {
            orderOffers[index] = {
              ...orderOffers[index],
              ...offer,
            };
          } else {
            orderOffers.push(offer);
          }
        });
        return state.set("orderOffers", [...orderOffers]);
      }
      return state;
    }

    case ModuleEvents.DELET_ORDER_OFFER: {
      const orderOffers: any = state.get("orderOffers");
      if (payload) {
        const filterOffers: any = filter(
          orderOffers,
          (item) => item.offerId !== payload.offerId
        );
        return state.set("orderOffers", [...filterOffers]);
      }
      return state;
    }

    case PaymentModule.CLEAR_CURRENT_APPOINTMENT: {
      return state.set("orderOffers", []);
    }

    default:
      return state;
  }
};

export default Reducer;
