import styled, {css} from 'styled-components';
// import _ from 'lodash';
import BaseColors from '../../../internal/helpers/BaseColors';

export const TableButton = styled.div`
  align-self: flex-end;
  margin-left: auto;
  margin-right: 1rem;
  // box-shadow: inset 0 0 10px rgba(0,0,0, .20);
  background-color: ${({selected}) => selected ? BaseColors.pictonBlue : BaseColors.regentGray};
  
  // box-shadow:inset 0px 1px 0px 0px #54a3f7;
  // text-shadow:inset 0px 1px 0px #154682;
  // background:linear-gradient(#49A4D4, #1B74A3);
  
  width: 34px;
  height: 34px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`