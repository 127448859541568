import React from 'react';
import Styles from './Styles.css';

export default ({children, width = '100%'}) => {

  return (
    <div className={Styles.flexCenter}>
      <div style={{width}}>
        {children}
      </div>
    </div>
  );
}
