import { get } from "lodash";
import {
  setPrinters,
  fetchOrders,
  setLocalData,
  getLocalData,
  fetchPrinters,
  createReceipt,
  setShopOrders,
  getActivityLogs,
  setActivityLogs,
  createShopOrder,
  updateShopOrder,
  deleteShopOrder,
  onCreateShopOrder,
  onUpdateShopOrder,
  onDeleteShopOrder,
  deleteActivityLog,
  createActivityLog,
  fetchOfflineOrders,
  createOfflineOrder,
  updateOfflineOrder,
  deleteOfflineOrder,
  fetchProductOptionSets,
  setProductOptionSets,
} from "../repos/schema/Order.schema";
import {
  fetchTables,
  updateTable,
  createTable,
  onCreateTable,
  onUpdateTable,
  setShopTables,
  serviceMessage,
  onServiceMessage,
  fetchOfflineTables,
  updateOfflineTable,
  createOfflineTable,
  deleteOfflineTable,
} from "../repos/schema/TableAreas.schema";

class GraphqlService {

  client: any = {};
  init(client: any) {
    this.client = client;
  }

  createOrder = async (order: any) => {
    try {
      const response = await this.client.mutate({
        mutation: createShopOrder,
        variables: { input: order },
      });
      return response.data.createShopOrder;
    } catch (error) {
      console.warn("create order error", error);
      return { error };
    }
  };
  createOfflineShopOrder = async (order: any) => {
    try {
      const response = await this.client.mutate({
        mutation: createOfflineOrder,
        variables: { input: order },
      });
      return response.data.createOfflineOrder;
    } catch (error) {
      console.warn("create offline order error", error);
      return { error };
    }
  };
  updateOrder = async (order: any) => {
    try {
      const response = await this.client.mutate({
        mutation: updateShopOrder,
        variables: { input: order },
      });
      return response.data.updateShopOrder;
    } catch (error) {
      console.warn("update order error", error);
      return { error };
    }
  };
  updateOfflineShopOrder = async (order: any) => {
    try {
      const response = await this.client.mutate({
        mutation: updateOfflineOrder,
        variables: { input: order },
      });
      return response.data.updateOfflineOrder;
    } catch (error) {
      console.warn("create offline order error", error);
      return { error };
    }
  };
  deleteOrder = async (order: any) => {
    const input = {
      _deleted: true,
      shopId: order.shopId,
      orderId: order.orderId,
      _lastChangedAt: Date.now(),
      _version: get(order, "_version", 0),
    };
    try {
      const response = await this.client.mutate({
        mutation: deleteShopOrder,
        variables: { input },
      });
      return response.data.deleteShopOrder;
    } catch (error) {
      console.warn("delete order error", error);
      return { error };
    }
  };
  deleteOfflineOrder = async (order: any) => {
    const input = {
      _deleted: true,
      shopId: order.shopId,
      orderId: order.orderId,
      _lastChangedAt: Date.now(),
      _version: get(order, "_version", 0),
      isRemoved: get(order, "isRemoved", false),
    };
    try {
      const response = await this.client.mutate({
        mutation: deleteOfflineOrder,
        variables: { input },
      });
      return response.data.deleteOfflineOrder;
    } catch (error) {
      console.warn("create offline order error", error);
      return { error };
    }
  };
  printReceipt = async (params: any) => {
    try {
      const response = await this.client.mutate({
        mutation: createReceipt,
        variables: { input: params },
      });
      return response.data.createReceipt;
    } catch (error) {
      console.warn("print order receipt error", error);
      return { error };
    }
  };
  fetchOrders = async (shopId: string, orderStatus: string) => {
    try {
      const response = await this.client.query({
        query: fetchOrders,
        fetchPolicy: "no-cache",
        variables: { shopId, orderStatus },
      });
      return get(response, "data.fetchOrders.items", []);
    } catch (error) {
      console.warn("fetch order error", error);
      return { error };
    }
  };
  fetchOfflineOrders = async (key: string) => {
    try {
      const response = await this.client.query({
        query: fetchOfflineOrders,
        fetchPolicy: "no-cache",
        variables: { key },
      });
      return get(response, "data.fetchOfflineOrders.items", []);
    } catch (error) {
      console.warn("fetch order error", error);
      return { error };
    }
  };
  setPrinters = async (printers: any) => {
    try {
      const response = await this.client.mutate({
        mutation: setPrinters,
        variables: { printers },
      });
      return response.data.setPrinters;
    } catch (error) {
      console.warn("set printers error", error);
      return { error };
    }
  };
  fetchPrinters = async () => {
    try {
      const response = await this.client.query({
        query: fetchPrinters,
        fetchPolicy: "no-cache",
        variables: { type: true },
      });
      return get(response, "data.fetchPrinters.items", []);
    } catch (error) {
      console.warn("fetch order error", error);
      return { error };
    }
  };
  setShopOrders = async (orders: any) => {
    try {
      const response = await this.client.mutate({
        mutation: setShopOrders,
        variables: { orders },
      });
      return response.data.setShopOrders;
    } catch (error) {
      console.warn("set shop order error", error);
      return { error };
    }
  };
  setLocalData = async (key: string, value: string) => {
    try {
      const response = await this.client.mutate({
        mutation: setLocalData,
        variables: { key, value },
      });
      return get(response, "data.setLocalData.value", "");
    } catch (error) {
      console.warn("set local data error", error);
      return { error };
    }
  };
  getLocalData = async (key: string) => {
    try {
      const response = await this.client.query({
        query: getLocalData,
        fetchPolicy: "no-cache",
        variables: { key },
      });
      return get(response, "data.getLocalData.value", "");
    } catch (error) {
      console.warn("fetch local data error", error);
      return { error };
    }
  };
  onCreateShopOrder = async (shopId: string, callback: (data: any) => void) => {
    try {
      let data: any = {};
      await this.client
        .subscribe({
          query: onCreateShopOrder,
          variables: { shopId },
        })
        .subscribe(
          (response) => callback(response),
          (error) => console.log("create shop order subscribe error...", error),
          () => console.log("complete")
        );
      return data;
    } catch (error) {
      console.warn("create shop order subscription error", error);
      return { error };
    }
  };
  onUpdateShopOrder = async (shopId: string, callback: (data: any) => void) => {
    try {
      let data: any = {};
      await this.client
        .subscribe({
          query: onUpdateShopOrder,
          variables: { shopId },
        })
        .subscribe(
          (response) => callback(response),
          (error) => console.log("update shop order subscribe error...", error),
          () => console.log("complete")
        );
      return data;
    } catch (error) {
      console.warn("update shop order subscription error", error);
      return { error };
    }
  };
  onDeleteShopOrder = async (shopId: string, callback: (data: any) => void) => {
    try {
      let data: any = {};
      await this.client
        .subscribe({
          query: onDeleteShopOrder,
          variables: { shopId },
        })
        .subscribe(
          (response) => callback(response),
          (error) => console.log("delete shop order subscribe error...", error),
          () => console.log("complete")
        );
      return data;
    } catch (error) {
      console.warn("delete shop order subscription error", error);
      return { error };
    }
  };

  createTable = async (table: any) => {
    try {
      const response = await this.client.mutate({
        mutation: createTable,
        variables: { input: table },
      });
      return response.data.createTable;
    } catch (error) {
      console.warn("create table error", error);
      return { error };
    }
  };
  createOfflineTable = async (table: any) => {
    try {
      const response = await this.client.mutate({
        mutation: createOfflineTable,
        variables: { input: table },
      });
      return response.data.createOfflineTable;
    } catch (error) {
      console.warn("create offline table error", error);
      return { error };
    }
  };

  updateTable = async (table: any) => {
    try {
      const response = await this.client.mutate({
        mutation: updateTable,
        variables: { input: table },
      });
      return response.data.updateTable;
    } catch (error) {
      console.warn("update table error", error);
      return { error };
    }
  };
  updateOfflineTable = async (table: any) => {
    try {
      const response = await this.client.mutate({
        mutation: updateOfflineTable,
        variables: { input: table },
      });
      return response.data.updateOfflineTable;
    } catch (error) {
      console.warn("update offline table error", error);
      return { error };
    }
  };
  onCreateTable = async (shopId: string, callback: (data: any) => void) => {
    try {
      let data: any = {};
      await this.client
        .subscribe({
          query: onCreateTable,
          variables: { shopId },
        })
        .subscribe(
          (response) => callback(response),
          (error) => console.log("create shop table subscribe error...", error),
          () => console.log("complete")
        );
      return data;
    } catch (error) {
      console.warn("create shop table subscription error", error);
      return { error };
    }
  };
  onUpdateTable = async (shopId: string, callback: (data: any) => void) => {
    try {
      let data: any = {};
      await this.client
        .subscribe({
          query: onUpdateTable,
          variables: { shopId },
        })
        .subscribe(
          (response) => callback(response),
          (error) => console.log("update shop table subscribe error...", error),
          () => console.log("complete")
        );
      return data;
    } catch (error) {
      console.warn("update shop table subscription error", error);
      return { error };
    }
  };
  setShopTables = async (tables: any) => {
    try {
      const response = await this.client.mutate({
        mutation: setShopTables,
        variables: { tables },
      });
      return response.data.setShopTables;
    } catch (error) {
      console.warn("set shop table error", error);
      return { error };
    }
  };
  fetchTables = async (shopId: string) => {
    try {
      const response = await this.client.query({
        query: fetchTables,
        fetchPolicy: "no-cache",
        variables: { shopId },
      });
      return get(response, "data.fetchTables", {});
    } catch (error) {
      console.warn("fetch table error", error);
      return { error };
    }
  };
  fetchOfflineTables = async (key: string) => {
    try {
      const response = await this.client.query({
        query: fetchOfflineTables,
        fetchPolicy: "no-cache",
        variables: { key },
      });
      return get(response, "data.fetchOfflineTables", {});
    } catch (error) {
      console.warn("fetch offline table error", error);
      return { error };
    }
  };
  deleteOfflineTable = async () => {
    const input = { key: "OFFLINE_TABLES" };
    try {
      const response = await this.client.mutate({
        mutation: deleteOfflineTable,
        variables: { input },
      });
      return response.data.deleteOfflineTable;
    } catch (error) {
      console.warn("delete offline table error", error);
      return { error };
    }
  };
  serviceMessage = async (key: string, value: string) => {
    const input = { key, value };
    try {
      const response = await this.client.mutate({
        mutation: serviceMessage,
        variables: { input },
      });
      return response.data.serviceMessage;
    } catch (error) {
      console.warn("service message error", error);
      return { error };
    }
  };
  onServiceMessage = async (shopId: string, callback: (data: any) => void) => {
    try {
      let data: any = {};
      await this.client
        .subscribe({
          query: onServiceMessage,
          variables: { shopId },
        })
        .subscribe(
          (response) => callback(response),
          (error) =>
            console.log("service message subscription error...", error),
          () => console.log("complete")
        );
      return data;
    } catch (error) {
      console.warn("service message subscription error", error);
      return { error };
    }
  };
  createActivityLog = async (params: any) => {
    try {
      const response = await this.client.mutate({
        mutation: createActivityLog,
        variables: { input: params.activity, type: params.type },
      });
      return response.data.createActivityLog;
    } catch (error) {
      console.warn("create activity log error", error);
      return { error };
    }
  };
  deleteActivityLog = async (params: any) => {
    try {
      const response = await this.client.mutate({
        mutation: deleteActivityLog,
        variables: { input: params.activity, type: params.type },
      });
      return response.data.deleteActivityLog;
    } catch (error) {
      console.warn("delete activity log error", error);
      return { error };
    }
  };
  setActivityLogs = async (params: any) => {
    try {
      const response = await this.client.mutate({
        mutation: setActivityLogs,
        variables: { activities: params.activities, type: params.type },
      });
      return response.data.setActivityLogs;
    } catch (error) {
      console.warn("set activity error", error);
      return { error };
    }
  };
  getActivityLogs = async (params: any) => {
    try {
      const response = await this.client.query({
        query: getActivityLogs,
        fetchPolicy: "no-cache",
        variables: { shopId: params.shopId, type: params.type },
      });
      return get(response, "data.getActivityLogs.items", []);
    } catch (error) {
      console.warn("fetch activity log error", error);
      return { error };
    }
  };

  setProductOptionSet = async (params: any) => {
    try {
      const response = await this.client.mutate({
        mutation: setProductOptionSets,
        variables: { productOptions: params.productOptions, type: params.type },
      });
      return response.data.setProductOptionSets;
    } catch (error) {
      console.warn("set product options error", error);
      return { error };
    }
  };
  getProductOptionSet = async (params: any) => {
    try {
      const response = await this.client.query({
        query: fetchProductOptionSets,
        fetchPolicy: "no-cache",
        variables: { shopId: params.shopId, type: params.type },
      });
      return get(response, "data.fetchProductOptionSets.items", []);
    } catch (error) {
      console.warn("fetch activity log error", error);
      return { error };
    }
  };
}
export default new GraphqlService();
