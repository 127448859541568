import React from "react";
import _, { capitalize, get, isEmpty } from "lodash";
import { getSelectedModifiersItems } from "../../../internal/manager/ProductManager";

const KitchentReceipt58 = ({
  details,
  bar,
  kitchen,
}: {
  details: any;
  bar?: boolean;
  kitchen?: boolean;
}) => {
  const {
    recieptId,
    appointmentDate,
    tableOrder,
    // image,
    // selectedVehicle,
    // selectedCustomer,
    selectedEmployee,
    purchaseProducts,
    // purchaseServices,
    // givenPrice,
    // shopData,
    // total,
    // subTotal,
    // appointmentDiscounts,
  } = details;

  const printProducts = purchaseProducts.filter(
    ({ sendToKitchen, prepQuantity, sendToBar }) =>
      ((bar && sendToBar) || (kitchen && sendToKitchen)) && prepQuantity > 0
  );

  return (
    <div className="kit-wrapper">
      <div className="kit-wrapper-info">
        <div className="title-wrapper">
          <p>RECEIPT: {recieptId}</p>
          {tableOrder?.tbId ? <p>TABLE: {tableOrder.tbNumber}</p> : null}
        </div>
        <div className="date-wrapper">
          <p>{appointmentDate}</p>
        </div>
        <div className="date-wrapper">
          <p>{`${capitalize(selectedEmployee?.firstName)} ${capitalize(
            selectedEmployee?.lastName.charAt(0)
          )}`}</p>
        </div>
        <hr className="seperator-line" />
        {printProducts.map((line: any) => {
          let optionName: string = "";
          let modifierNames: string = "";
          const isOptions = !isEmpty(get(line, "priceVariants", null));
          if (isOptions) {
            optionName = get(line.priceVariants, "[0].optionUnit", "");
          }
          const modifierItems = getSelectedModifiersItems(line?.modifiers);
          if (!isEmpty(modifierItems)) {
            modifierNames = modifierItems
              .map((modifier: any) =>
                modifier?.items?.map(
                  (item) => `${item.name} X ${item.quantity}`
                )
              )
              .join(", ");
          }

          return (
            <div className="kit-table-row">
              <div>
                <div>{_.startCase(line.productName)}</div>
                <div>{optionName}</div>
                <div>{modifierNames}</div>
              </div>
              <div>
                {`-${line.prepQuantity}`
                  .padStart(4, "-")
                  .replaceAll("-", "\u00A0")}
              </div>
            </div>
          );
        })}
        <hr className="seperator-line" />
      </div>
    </div>
  );
};

export default KitchentReceipt58;
