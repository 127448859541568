import React from 'react';
import {connect} from "react-redux";
import {Row, Col} from 'react-grid-system';
import PaymentDetailsView from './PaymentDetailsView';
import {BookingContainer} from '../../../components/Styles/Styled';
import BookingComponent from '../../../components/bookings/BookingDetailsComponent';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import { useIntl } from 'react-intl';

const BookingDetailsView = (props) => {
  const {formatMessage: f} = useIntl();

  return (
    <BookingContainer>
      <TopNavigationBar viewName={f({id: "CHECKOUT.TITLE"})} path="/menu"/>

      <div className={'bookingBottomWrapper'}>
        <Row style={{height: '100%', margin: 0}}>
          <Col md={6} style={{height: '100%', padding: '10px'}}>
            <BookingComponent/>
          </Col>
          <Col md={6} style={{height: '100%', padding: '10px'}}>
            <PaymentDetailsView />
          </Col>
        </Row>
      </div>
    </BookingContainer>
  );
};
export default connect(
  state => ({}),
  ({})
)(BookingDetailsView);
