import React, { useEffect, useState } from "react";
import styles from "../../Styles/Styles.css";

const ProductSubHeader = (props) => {
  const { selectedSidebar, onClear} = props;
  const [path, setPath] = useState('')

  useEffect(() => {
    const pathUrl =  getPath(selectedSidebar)
    setPath(pathUrl)
  }, [selectedSidebar]);

  const getPath = (selectedSidebar) => {
    switch (selectedSidebar) {
      case "BRANDS":
        return "/add-brand";
      case "CATEGORIES":
        return "/add-product-category";
      case "SUPPLIERS":
        return "/add-supplier";
      case "PRODUCT_TYPES":
        return "/add-product-type";
      case "MENU":
        return "/catalogue";
      case "STOCK":
        return "/add-stock";
      default:
        return "";
    }
  };

  const getHeader = () => {
    switch (selectedSidebar) {
      case "BRANDS":
        return "Edit Brands";
      case "ALL_PRODUCTS":
        return "Edit Products";
      case "CATEGORIES":
        return "Edit Categories";
      case "SUPPLIERS":
        return "Edit Suppliers";
      case "FAVOURITES":
        return "Add Favourite";
      case "MENU":
        return "Edit Menu";
      case "STOCK":
        return "Edit Stock";
      default:
        return "";
    }
  };

  const getButton = () => {
    switch (selectedSidebar) {
      case "BRANDS":
        return "Add Brand";
      case "CATEGORIES":
        return "Add Categories";
      case "PRODUCT_TYPES":
        return "Add Product Type";
      case "SUPPLIERS":
        return "Add Supplier";
      case "STOCK":
        return "Add Stock";
      default:
        return "";
    }
  };

  const isValidButton =
    selectedSidebar === "BRANDS" ||
    selectedSidebar === "CATEGORIES" ||
    selectedSidebar === "PRODUCT_TYPES" ||
    selectedSidebar === "SUPPLIERS" ||
    selectedSidebar === "STOCK";

  return (
    <div className={styles.serviceSubHeaderContainer}>
      <div className={styles.subHeaderWrapper}>
        <div className={styles.subHeaderNewText}>{getHeader()}</div>
      </div>
      {isValidButton ? (
        <div
          onClick={() => onClear(path)}
          className={styles.subHeaderAddCategoryBtn}
        >
          <div className={styles.subHeaderAddCategoryBtnText}>{getButton()}</div>
        </div>
      ) : null}
    </div>
  );
};
export default ProductSubHeader;
