import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-grid-system';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import 'antd/dist/antd.css';
import {connect} from "react-redux/es/index";
import '../Styles.style.css';
import {Actions} from "../../../internal/app/Actions";
import {mapCreateCategory, mapUpdateCategory, mapDeleteCategory} from '../../../internal/manager/CategoryManager';
import EditCategoryTopHeader from '../../../components/services/views/EditCategoryTopHeader';
import _ from "lodash";
import ServiceWarningModal from '../../../components/model/views/ServiceWarningModal';
import {navigate} from "../../../internal/service/Navigation.service";

const CategoryAddView = (props) => {
  const { categories, updateServiceCategory, selectedCategoryItem } = props;

  const [categoryTitle, setCategoryTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isOpenDiscardModel, setIsOpenDiscardModel] = useState(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(selectedCategoryItem)) {
      setCategoryTitle(selectedCategoryItem.category);
      setDescription(selectedCategoryItem.description);
    }
  }, []);

  const onCreateCategory = () => {
    const param = mapCreateCategory(categories, categoryTitle, description);
    updateServiceCategory(param);
  };

  const onUpdateCategory = () => {
    const param = mapUpdateCategory(categories, selectedCategoryItem, categoryTitle, description);
    updateServiceCategory(param);
  };

  const onDeleteCategory = () => {
    const param = mapDeleteCategory(selectedCategoryItem, categories);
    updateServiceCategory(param);
  };

  const isValid = !_.isEmpty(categoryTitle);
  const isEdit = !_.isEmpty(selectedCategoryItem);
  const isHasntService = _.get(selectedCategoryItem, 'services', []).length === 0;

  return (
    <div className="addCategoryMainContainer">
      <TopNavigationBar viewName="Services" path="/menu"/>
      <div className="addServiceViewContainer">
        <div className="addServiceViewWrapper">
          <EditCategoryTopHeader
            onCreateService={() => {
              if (isValid) {
                if (isEdit) {
                  onUpdateCategory();
                } else {
                  onCreateCategory();
                }
              }
            }}
            isEdit={isEdit}
            onBack={() => {
              if (isEdit) {
                setIsOpenDiscardModel(true);
              } else {
                navigate('/services-customized');
              }
            }}
            isValid={isValid}
          />
          <Row>
            <Col xs={12}>
              <div className="serviceAddFormContainer">
                <div className="addServiceDetailsText">
                  Details
                </div>
                <div className="addCategoryInputContainer">
                  <Row>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Category*
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input onChange={(text) => setCategoryTitle(text.target.value)} value={categoryTitle} className='serviceTextInput' placeholder="Category Name"/>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '30px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Description
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <textarea onChange={(text) => setDescription(text.target.value)} value={description} className='serviceTextAreaInput' placeholder="Description"/>
                    </Col>
                  </Row>
                  {
                    isEdit ?
                      <Row style={{marginTop: '20px'}}>
                        <Col xs={2} className="serviceTitleCol" />
                        <Col xs={10} className="serviceTitleColInput">
                          <div onClick={() => {
                            if (isHasntService){
                              setIsOpenDeleteModel(true);
                            }
                          }} className="categoryDeleteBtnContainer" style={{backgroundColor: isHasntService ? '#FFD6D6' : '#8A9CA5', border: isHasntService ? '1px solid #FF5F5F' : '1px solid #8A9CA5'}}>
                            <div className="serviceDeleteBtnText" style={{color: isHasntService ? '#FF5F5F' : '#ffffff'}}>
                              Delete Category
                            </div>
                          </div>
                        </Col>
                      </Row>
                      : null
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ServiceWarningModal
        isOpen={isOpenDiscardModel}
        headerText={'Are you sure?'}
        bodText={'All your changes will be discarded'}
        cancelBtnText={'Discard'}
        onCancel={() => {
          setIsOpenDiscardModel(false);
          navigate('/services-customized');
        }}
        closeModal={() => setIsOpenDiscardModel(false)}
      />
      <ServiceWarningModal
        isOpen={isOpenDeleteModel}
        headerText={'Warning!'}
        bodText={'Your category will be deleted and no longer\n available to order for the users'}
        cancelBtnText={'Delete'}
        onCancel={() => {
          onDeleteCategory();
          setIsOpenDeleteModel(false);
        }}
        closeModal={() => setIsOpenDeleteModel(false)}
      />
    </div>
  );
};

export default connect(
  state => ({
    categories: state.service.get('categories'),
    selectedCategoryItem: state.service.get('selectedCategoryItem')
  }),
  {
    updateServiceCategory: Actions.service.updateServiceCategory
  }
)(CategoryAddView);
