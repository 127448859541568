import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  TopNavigationContainer,
  TopNavigationIconContainer,
  TopNavigationHeaderContainer,
} from "../../../components/Styles/Styled";
import MENU from "../../../../assets/icons/menu.svg";
import NoWifiIcon from "../../../../assets/icons/noWifiIcon.svg";
import LowWifiIcon from "../../../../assets/icons/lowWifiIcon.svg";
import FullWifiIcon from "../../../../assets/icons/fullWifiIcon.svg";
import MediumWifiIcon from "../../../../assets/icons/mediumWifiIcon.svg";
import WifiIndicator, { DBMToSignalStrength } from "react-wifi-indicator";
import DisableWifiIcon from "../../../../assets/icons/disableWifiIcon.svg";
import NotificationIcon from "../../../../assets/icons/notificationIcon.svg";
import DisconectWifiIcon from "../../../../assets/svg/IconWifiDisconect.svg";
import { MdOutlineWifiOff } from "react-icons/md";

const TopNavigationBar = (props: any) => {
  const { viewName, path, onSaveOrder, isOnline, currentConnection } = props;
  const [online, setOnline] = useState<boolean>(true);

  useEffect(() => {
    if (isOnline != online) {
      setOnline(isOnline);
    }
  }, [isOnline]);


  let signalLevel = get(currentConnection, "signal_level", -89);

  return (
    <TopNavigationContainer>
      <div>
        <div>
          <Link to={path} style={{ textDecoration: "none" }}>
            <TopNavigationHeaderContainer>
              <img src={MENU} />
              <p>{viewName}</p>
            </TopNavigationHeaderContainer>
          </Link>
        </div>
        <TopNavigationIconContainer>
          {online ? (
            <WifiIndicator
              style={{
                height: 44,
                padding: 12,
              }}
              statusImages={{
                WEAK: LowWifiIcon,
                GREAT: FullWifiIcon,
                OKAY: MediumWifiIcon,
                UNUSABLE: NoWifiIcon,
                EXCELLENT: FullWifiIcon,
                DISCONNECTED: DisableWifiIcon,
              }}
              strength={DBMToSignalStrength(signalLevel)}
            />
          ) : (
            <div>
              <div>{"Offline Mode"}</div>
              <MdOutlineWifiOff size={24} color="#ff5f5f" />
            </div>
          )}
          <img src={NotificationIcon} />
        </TopNavigationIconContainer>
      </div>
    </TopNavigationContainer>
  );
};
export default connect(
  (state: any) => ({
    isOnline: state.common.get("isOnline"),
    currentConnection: state.common.get("currentConnection"),
  }),
  {}
)(TopNavigationBar);
