import styled from "styled-components";
import BaseColors from "../../../internal/helpers/BaseColors";
import _ from "lodash";

export const OrderLineContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #eff3f6;

  &>div: nth-child(2) {
    height: calc(100vh - 61px);
    display: flex;
    flex-direction: row;
    padding: 10px;

    & > div {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background-color: #ffffff;
    }
  }
`;

export const OrderLineTableContainer = styled.div`
  width: 100%;
  display: flex;
  border-radius: 3px;
  flex-direction: column;
  max-height: calc(100vh - 81px);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
`;

export const OrderLineSearchContainer = styled.div<{ length: string }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  // z-index: 100;
  // bottom: 10px;
  // left: 300px;

  & > div {
    height: 100%;
    max-height: 240px;
    width: 303px;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
    overflow-y: scroll;
    overflow-x: hidden;

    :hover {
      cursor: pointer;
    }

    :focus {
      background-color: #49a4d4;
    }

    & > div {
      width: 100%;
      & > p {
        width: 100%;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        color: #262626;
        font-size: 14px;
        padding: 12px 16px;
        margin: 0px;

        :hover {
          border-radius: 3px;
          background-color: ${BaseColors.solitude};
        }
      }

      & > div {
        width: 100%;
        height: 0.5px;
        align-items: center;
        background-color: #f5f5f5;
        border: none;
      }
    }
  }
`;

export const OrderLineDetailContainer = styled.div`
  max-height: calc(100vh - 131px);

  &>div: first-child {
    display: flex;
    height: 45px;
    display: grid;
    align-items: center;
    background-color: ${BaseColors.alabaster};
  }
`;

export const OrderLineDetailHeaderContainer = styled.div<{ flexEnd: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.flexEnd ? "flex-end" : "flex-start")};

  & > div {
    font-family: Montserrat;
    font-weight: 500;
    color: #000000;
    font-size: 14px;
  }
`;

export const OrderLineDetailQtyHeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > div {
    font-family: Montserrat;
    font-weight: 500;
    color: #000000;
    font-size: 14px;
  }
`;

export const OrderLineDetailValueContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 176px);
  background-color: #ffffff;

  & > div {
    align-items: center;
    background-color: #ffffff;

    :hover {
      background-color: #f8f9fb;
    }

    &>div: last-child {
      height: 1px;
      background-color: rgba(32, 37, 58, 0.06);
      margin-left: 8px;
      margin-right: 8px;
    }
  }
`;

export const OrderLineDetailAddSubContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &>div: first-child {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: ${BaseColors.cornflowerBlue};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-right: 17px;
  }
  &>div: nth-child(3) {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: ${BaseColors.cornflowerBlue};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
  &>div: nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Montserrat;
    font-weight: 600;
    color: ${BaseColors.nero};
    font-size: 12px;
    text-align: center;
    margin-right: 17px;
  }
`;

export const OrderLineDetailValueImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &>div: first-child {
    width: 24px;
    height: 24px;
    background-color: ${BaseColors.monaLisa};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  &>div: last-child {
    font-family: Montserrat;
    font-weight: 600;
    color: ${BaseColors.nero};
    font-size: 12px;
    text-align: center;
    margin-left: 9px;
  }
`;

export const OrderLineDetailValue = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-family: Montserrat;
  font-weight: 600;
  color: ${BaseColors.nero};
  font-size: 12px;
  text-align: center;
`;

export const CalendarContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${BaseColors.aquaHaze};
`;

export const CalendarBottomContainer = styled.div`
  // width: 100%;
  // height: calc(100vh - 61px);
  padding: 10px;
  background-color: ${BaseColors.aquaHaze};

  & > div {
    width: 100%;
    height: calc(100vh - 81px);
    border-radius: 3px;
    background-color: ${BaseColors.white};

    & > div:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 17px;
      padding-bottom: 30px;
      padding-left: 10px;
      padding-right: 10px;

      & > div:first-child {
        width: 183px;
      }

      & > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 310px;
        height: 34px;
        background-color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        outline: none;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: 0px 0px 10px 5px #00000016;

        & > div {
          color: ${BaseColors.black};
          font-size: 14px;
          font-family: "Montserrat";
          font-weight: 500;
        }
      }

      & > div:last-child {
        width: 190px;
        height: 34px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #49a4d4;
        padding-left: 20px;
        padding-right: 10px;
        border: none;
        border-radius: 4px;
        outline: none;
        cursor: pointer;

        & > div {
          color: ${BaseColors.white};
          font-size: 14px;
          font-family: "Montserrat";
          font-weight: 500;
        }
      }
    }

    & > div:last-child {
      width: 100%;
      height: calc(100vh - 162px);

      .calendarEmptyAppointment {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 4px;

        & > div:first-child {
          font-family: Montserrat;
          font-weight: bold;
          font-size: 21px;
          color: ${BaseColors.black};
        }

        & > div:last-child {
          font-family: Montserrat;
          font-weight: 500;
          font-size: 18px;
          color: ${BaseColors.black85};
          margin-top: 10px;
        }
      }
    }
  }
`;

export const LineTableHeader = styled.div`
  color: ${BaseColors.regentGray};

  & > div {
    height: 50px;
    font-size: 14px;
    font-weight: 500;
  }
  & > div > div:nth-child(4) {
    text-align: right;
  }
`;
