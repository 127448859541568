import React, { useEffect, useState } from "react";
import {
  SelectModiferCancelButton,
  SelectModifierItemWrapper,
  SelectModiferCreateButton,
  SelectModifierModalWrapper,
  SelectModifierPriceWrapper,
} from "./Styled";
import { nanoid } from "nanoid";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { Actions } from "../../../internal/app/Actions";
import CANCEL from "../../../../assets/icons/cancel.svg";
import {
  getModifierTotal,
  getIsSameModifiers,
  updateStockItemCount,
  getValidModifiersSelected,
} from "../../../internal/manager/ProductManager";
import styles from "../../../components/Styles/ModelStyles.css";
import IconPlus from "../../../../assets/svg/icon-plus-gray.svg";
import IconMinus from "../../../../assets/svg/icon-minus-gray.svg";
import _, { get, find, filter, isEqual, isEmpty, findIndex } from "lodash";
import { getCurrencyWithPrice } from "../../../internal/manager/PaymentManager";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 266px)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const SelectModifierModal = (props) => {
  const {
    shop,
    isOpen,
    closeModal,
    productList,
    selectedProduct,
    purchaseProducts,
    currentStockList,
    selectProductItem,
    onBackFromModifier,
    setPurchaseProducts,
    changeProductQuantity,
    setPurchaseProductItem,
    selectedPurchaseProduct,
    updateCurrentStockCount,
  } = props;
  const [selectedModifiers, setSelectedModifiers] = useState<Array<any>>([]);

  useEffect(() => {
    if (selectedPurchaseProduct) {
      setSelectedModifiers(get(selectedPurchaseProduct, "modifiers", []));
    }
  }, [selectedPurchaseProduct]);

  const updateModifierQuantity = (modifier: any, item: any, status: string) => {
    if (status === "PLUS") {
      item.quantity = item.quantity + 1;
      item.total = item.total + parseFloat(item.price);
    } else {
      if (item.quantity != 0) {
        item.quantity = item.quantity - 1;
        item.total = item.total - item.price;
      }
    }
    setSelectedModifiers([...selectedModifiers]);
  };

  const onUpdateStockCount = (
    productId: string,
    isRemoved: boolean,
    param: any = null
  ) => {
    const productData = isRemoved
      ? _.find(purchaseProducts, (item) => item.id === productId)
      : param;
    const particularProduct = _.find(
      productList,
      (product) => product.pid === productData.id
    );
    const stockItems = _.get(particularProduct, "stockItems", []);
    const newStock = updateStockItemCount(
      stockItems,
      currentStockList,
      productData.quantity,
      isRemoved
    );
    updateCurrentStockCount(newStock);
  };

  const onSetModifiers = () => {
    const product = selectedPurchaseProduct;
    product.modifiers = selectedModifiers;

    const isEqualModifiers = getIsSameModifiers(
      purchaseProducts,
      selectedPurchaseProduct,
      selectedModifiers
    );

    if (!isEmpty(selectedPurchaseProduct?.priceVariants)) {
      const optionSelected = product.priceVariants[0].options.sort();
      const isSelected =
        findIndex(purchaseProducts, (item: any) => {
          if (item.opId === get(product, "opId", nanoid())) {
            if (item.priceVariants) {
              return (
                item.priceVariants.findIndex(({ options }) =>
                  isEqual(optionSelected, options)
                ) > -1
              );
            }
          }
          return false;
        }) > -1;

      if (isSelected && isEqualModifiers) {
        changeProductQuantity(product, "INCREMENT");
      } else {
        const modifiersPrice = getModifierTotal(selectedModifiers);
        product.productPrice += modifiersPrice;
        product.productSalePrice += modifiersPrice;
        product.price += modifiersPrice;
        setPurchaseProducts([product]);
      }
    } else {
      if (isEqualModifiers) {
        changeProductQuantity(product, "INCREMENT");
      } else {
        const modifiersPrice = getModifierTotal(selectedModifiers);
        product.productPrice += modifiersPrice;
        product.productSalePrice += modifiersPrice;
        product.price += modifiersPrice;
        setPurchaseProducts([product]);
      }
    }
    onUpdateStockCount(product.id, false, product);
    setPurchaseProductItem({});
    selectProductItem({});
    closeModal();
  };

  const modifierTotal = getModifierTotal(selectedModifiers);
  const isValidModifiersSelected = getValidModifiersSelected(selectedModifiers);
  const isMandatoryModifiers =
    filter(selectedProduct?.modifiers, (modifier: any) => modifier.isMandatory)
      .length > 0;

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.selectOptionModal}
    >
      <SelectModifierModalWrapper>
        <div
          onClick={() => {
            // if (!isValidModifiersSelected && isMandatoryModifiers) {
            //   return;
            // }
            // closeModal();
            onBackFromModifier();
          }}
        >
          <div>{"Select Modifiers"}</div>
          <img src={CANCEL} />
        </div>
        {!isEmpty(selectedProduct) && !isEmpty(selectedProduct.modifiers) && (
          <div>
            {selectedProduct?.modifiers?.map((modifier: any, index: number) => {
              const productModifier = find(
                selectedModifiers,
                ({ id }: any) => id === modifier.id
              );
              const mandatoryItem = productModifier?.items.filter(
                ({ quantity }: any) => quantity > 0
              );
              let isInvalid = false;
              if (modifier?.isMandatory) {
                isInvalid =
                  mandatoryItem?.length === 1 &&
                  get(mandatoryItem, "[0].quantity", 0) === 1;
              }
              const title = `${modifier.title} ${
                modifier?.isMandatory ? "*" : ""
              }`;

              return (
                <SelectModifierItemWrapper>
                  <div>{title}</div>
                  <div>
                    {modifier?.items?.map((item: any) => {
                      const productItem = find(
                        productModifier?.items,
                        ({ name }: any) => name === item.itemName
                      );

                      return (
                        <div>
                          <div>{item.itemName}</div>
                          <div>
                            <img
                              onClick={() => {
                                if (!isInvalid) {
                                  updateModifierQuantity(
                                    modifier,
                                    productItem,
                                    "MINUS"
                                  );
                                }
                              }}
                              src={IconMinus}
                            />
                            <div>{get(productItem, "quantity", 0)}</div>
                            <img
                              onClick={() =>
                                updateModifierQuantity(
                                  modifier,
                                  productItem,
                                  "PLUS"
                                )
                              }
                              src={IconPlus}
                            />
                          </div>
                          <div>{getCurrencyWithPrice(item.price)}</div>
                        </div>
                      );
                    })}
                  </div>
                </SelectModifierItemWrapper>
              );
            })}
            <SelectModifierPriceWrapper>
              <p>
                Price:- <strong>{getCurrencyWithPrice(modifierTotal)}</strong>
              </p>
              <div>
                <SelectModiferCancelButton
                  onClick={() => {
                    onBackFromModifier();
                  }}
                >
                  Cancel
                </SelectModiferCancelButton>
                <SelectModiferCreateButton
                  isFill={true}
                  onClick={() => onSetModifiers()}
                  disabled={!isValidModifiersSelected && isMandatoryModifiers}
                >
                  {isValidModifiersSelected
                    ? "Select Modifiers"
                    : "No Modifiers"}
                </SelectModiferCreateButton>
              </div>
            </SelectModifierPriceWrapper>
          </div>
        )}
      </SelectModifierModalWrapper>
    </ReactModal>
  );
};
export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
    productList: state.product.get("productList"),
    currentStockList: state.product.get("currentStockList"),
    purchaseProducts: state.product.get("purchaseProducts"),
    selectedProduct: state.product.get("choossedProductItem"),
    selectedPurchaseProduct: state.product.get("selectedPurchaseProduct"),
  }),
  {
    selectProductItem: Actions.product.selectProductItem,
    setPurchaseProducts: Actions.product.setPurchaseProducts,
    changeProductQuantity: Actions.product.changeProductQuantity,
    updateCurrentStockCount: Actions.product.updateCurrentStockCount,
    setPurchaseProductItem: Actions.product.setPurchaseProductItem,
  }
)(SelectModifierModal);
