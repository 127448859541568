import React, {useState} from 'react';
import _ from "lodash";
import AppointmentCard from "../card/AppointmentCard";
import {generateImageUrl} from "../../internal/manager/ImageManager";
import defaultStylistImage from "../../../assets/icons/user4.svg";
import {AppointmentCalendarContainer} from '../Styles/Styled';

const AppointmentCalendarDetails = (props) => {
  const {data, timeColumn, stylists} = props;

  return (
    <AppointmentCalendarContainer>
      <div>
        {
          !_.isEmpty(timeColumn) && timeColumn.map((item) => {
            return (
              <div>{item}</div>
            )
          })
        }
      </div>
      <hr/>
      <div>
        <div>
          {
            stylists.map((stylist) => {
              const dataMap = data.get(stylist.stid);
              return (
                <div>
                  <div>
                    <img src={stylist.profileImage ? generateImageUrl(stylist.profileImage) : defaultStylistImage}/>
                    <div>{stylist.name}</div>
                  </div>
                  <div>
                    {
                      !_.isEmpty(timeColumn) && timeColumn.map((item, index) => {

                        return (
                          <div>
                            {
                              !_.isEmpty(dataMap) && !_.isNil(dataMap.get(item)) && dataMap.get(item).map((valueItem) => {

                                const majorHeight = Math.floor(valueItem.duration / 60);
                                const componentHeight = ((majorHeight * 109) - 6);
                                const componentTop = (index * 111);
                                return (
                                  <AppointmentCard data={valueItem} componentHeight={componentHeight}
                                                   componentTop={componentTop}/>
                                )
                              })
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }

        </div>

      </div>
    </AppointmentCalendarContainer>
  );
};
export default AppointmentCalendarDetails;
