import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-grid-system';
import {connect} from "react-redux";
import {Actions} from "../../../internal/app/Actions";
import {
  ReportChartTableContainer,
  ReportChartTableTitleTextContainer,
  ReportChartTableValue,
} from '../../Styles/Styled';
import {getReportDescriptions} from "../../../internal/manager/ReportsManager";

const ReportDetails = (props) => {
    const {reportsTotal, shop} = props;
    const [data, setData] = useState([]);

    useEffect(() => {
      setData(getReportDescriptions(reportsTotal, shop));
    }, [reportsTotal]);

    return (
      <ReportChartTableContainer>
        <div>
          <Row style={{height: '100%', margin: 0}}>
            <Col md={6}>
              <ReportChartTableTitleTextContainer>
                <div>Description</div>
              </ReportChartTableTitleTextContainer>
            </Col>
            <Col md={2}>
              <ReportChartTableTitleTextContainer>
                <div>Sales</div>
              </ReportChartTableTitleTextContainer>
            </Col>
            <Col md={2}>
              <ReportChartTableTitleTextContainer>
                <div>Refunds</div>
              </ReportChartTableTitleTextContainer>
            </Col>
            <Col md={2}>
              <ReportChartTableTitleTextContainer>
                <div>Net</div>
              </ReportChartTableTitleTextContainer>
            </Col>
          </Row>
        </div>

        <div>
          {
            data.map((data, index) => {
              return (
                <div>
                  <Row style={{height: '100%', margin: 0, minHeight: '60px'}}>
                    <Col md={6}>
                      <ReportChartTableValue index={index}>{data.description}</ReportChartTableValue>
                    </Col>
                    <Col md={2}>
                      <ReportChartTableValue index={index}>{data.sales}</ReportChartTableValue>
                    </Col>
                    <Col md={2}>
                      <ReportChartTableValue index={index}>{data.refunds}</ReportChartTableValue>
                    </Col>
                    <Col md={2}>
                      <ReportChartTableValue index={index}>{data.net}</ReportChartTableValue>
                    </Col>
                  </Row>
                  <div className="orderQueueBottomLine"/>
                </div>
              )
            })
          }
        </div>
      </ReportChartTableContainer>
    );
  }
;
export default connect(
  state => ({
    shop: state.login.get('merchantShop'),
    reportsTotal: state.reports.get('reportsTotal'),
  }),
  {
  }
)(ReportDetails);
