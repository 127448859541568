import React, { useEffect, useState } from "react";
import {
  AddWarrantyDataWrapper,
  AddWarrantyModalWrapper,
} from "../../Styles/Styled";
import moment from "moment";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import styles from "../../Styles/ModelStyles.css";
import { Actions } from "../../../internal/app/Actions";
import CANCEL from "../../../../assets/icons/cancel.svg";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import IconDate from "../../../../assets/svg/icon-date.svg";
import _, { isEmpty, findIndex, remove, capitalize } from "lodash";
import IconRadioCheck from "../../../../assets/svg/icon-check-black.svg";
import IconRadioUncheck from "../../../../assets/svg/icon-uncheck-gray.svg";

import { DatePicker } from "antd";

const customStyles = {
  content: {
    top: "10vh",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 264px)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const CreateWarrantyModal = (props) => {
  const {
    shop,
    isOpen,
    warranty,
    closeModal,
    setOrderWarranty,
    purchaseProducts,
  } = props;
  const [title, setTitle] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<any>(
    moment().format("DD/MM/yyyy")
  );
  const [selectedProducts, setSelectedProducts] = useState<any>([]);

  const initialState = () => {
    setTitle("");
    setDescription("");
    setSelectedProducts([]);
    setSelectedDate(moment().format("DD/MM/yyyy"));
  };

  useEffect(() => {
    setIsEdit(!isEmpty(warranty));
    if (!isEmpty(warranty)) {
      setTitle(warranty.title);
      setSelectedDate(warranty.endDate);
      setSelectedProducts([warranty.pid]);
      setDescription(warranty.description);
    }else{
      initialState()
    }
  }, [warranty]);

  const onSelectProduct = (product: any) => {
    const isSelected =
      findIndex(selectedProducts, (item: any) => item === product.id) > -1;
    if (isSelected) {
      remove(selectedProducts, (item: any) => item === product.id);
    } else {
      selectedProducts.push(product.id);
    }
    setSelectedProducts([...selectedProducts]);
  };

  const addWarranty = () => {
    const params = {
      title,
      description,
      date: selectedDate,
      shopId: shop.shopId,
      products: selectedProducts,
    };
    if(isEdit){
      params['wrid'] = warranty.wrid;
      params['createdTime'] = warranty.createdTime;
    }
    setOrderWarranty(params);
    closeModal();
    initialState();
  };


  const validate = () => {
    return !isEmpty(title) && !isEmpty(selectedProducts);
  };

  const disabledDate = (current: any) => {
    const minDate = moment();
    return current && current < minDate;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.warrantyModalWrapper}
    >
      <AddWarrantyModalWrapper disabled={!validate()}>
        <div>
          <div>{isEdit ? "Edit Warranty" : "Add New Warranty"}</div>
          <img onClick={() => closeModal()} src={CANCEL} />
        </div>
        <AddWarrantyDataWrapper disabled={!validate()}>
          <div>{"Warranty Items"}</div>
          <div>
            {purchaseProducts.map((product: any) => {
              const isSelected =
                findIndex(selectedProducts, (item) => item === product.id) > -1;
              return (
                <div onClick={() => onSelectProduct(product)}>
                  <Checkbox
                    checked={isSelected}
                    icon={<img src={IconRadioUncheck} />}
                    checkedIcon={<img src={IconRadioCheck} />}
                  />
                  <div>{capitalize(product.productName)}</div>
                </div>
              );
            })}
          </div>
          <div>
            <div>{"Warranty End Date"}</div>
            <div>
              <DatePicker
                name="date"
                bordered={false}
                clearIcon={null}
                format={"DD/MM/yyyy"}
                defaultValue={moment()}
                disabledDate={disabledDate}
                value={moment(selectedDate, "DD/MM/yyyy")}
                onChange={(value: any, dateString: string) => {
                  setSelectedDate(dateString);
                }}
                suffixIcon={<img src={IconDate} />}
              />
            </div>
          </div>
          <div>
            <div>{"Warranty title"}</div>
            <input
              value={title}
              placeholder={"Warranty title"}
              onChange={(event) => setTitle(event.target.value)}
            />
          </div>
          <div>
            <div>{"Description"}</div>
            <textarea
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
          <div>
            <button disabled={!validate()} onClick={() => addWarranty()}>
              {"Add Warranty"}
            </button>
            <button onClick={() => closeModal()}>{"Cancel"}</button>
          </div>
        </AddWarrantyDataWrapper>
      </AddWarrantyModalWrapper>
    </ReactModal>
  );
};
export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    purchaseProducts: state.product.get("purchaseProducts"),
  }),
  {
    setOrderWarranty: Actions.product.setOrderWarranty,
  }
)(CreateWarrantyModal);
