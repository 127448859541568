import _, { isString, get, orderBy, uniqBy, findIndex } from "lodash";
import { AnyAction } from "redux";
import { Map } from "immutable";
import { ModuleEvents } from "./Actions";

const initialState = Map({
  nextToken: {},
  activities: [],
});

export const Reducer = (state = initialState, action: AnyAction) => {
  const { payload, type, error } = action;

  if (error) {
    console.warn("Error handled in Reports Reducer", payload);
    return state;
  }
  switch (type) {
    case ModuleEvents.FETCH_ACTIVITIES: {
      if (payload && !payload.error) {
        const activities: any = state.get("activities");
        const nextToken = get(payload, "nextToken", {});
        const newData: any = uniqBy([...activities, ...payload.data], "logId");
        let newActivities: any = orderBy(
          newData,
          ["updatedTime"],
          ["desc"]
        );
        return state
          .set("activities", [...newActivities])
          .set("nextToken", nextToken);
      }
      return state;
    }
    case ModuleEvents.CREATE_ACTIVITY_LOG: {
      const activities: any = state.get("activities");
      if (payload && !payload.error) {
        activities.push(payload);
        let newActivities: any = orderBy(activities, ["updatedTime"], ["desc"]);
        return state.set("activities", [...newActivities]);
      }
      return state;
    }
    case ModuleEvents.RESTORE_ACTIVITY_LOG: {
      const activities: any = state.get("activities");
      if (payload && !payload.error) {
        activities.push(payload);
        let newActivities: any = orderBy(activities, ["updatedTime"], ["desc"]);
        return state.set("activities", [...newActivities]);
      }
      return state;
    }
    default:
      return state;
  }
};

export default Reducer;
