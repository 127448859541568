import React, { useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { Actions } from "../../../internal/app/Actions";
import EditIcon from '../../../../assets/svg/categoryEditIcon.svg';
import { ListRoomWrapper } from "./Styled";
import { getCurrencyWithPrice } from "../../../internal/manager/AppointmentManager";

const ListRoomTypes = connect(
  (state) => ({
    shop: state.login.get("merchantShop"),
    roomTypes: state.rooms.get("roomTypes"),
  }),
  {
    selectRoomType: Actions.rooms.selectRoomType,
    fetchRoomTypes: Actions.rooms.fetchRoomTypes,
  }
)(({ data = [], fetchRoomTypes, shop, roomTypes, selectRoomType }) => {
  useEffect(() => {
    if (shop.shopId) {
      fetchRoomTypes(shop.shopId);
    }
  }, [shop]);

  const navigateEditScreen = (rt) => {
    selectRoomType(rt);
  };

  return (
    <ListRoomWrapper>
      <Row>
        <Col>Room Type</Col>
        <Col>ID</Col>
        <Col>Base Price</Col>
        <Col>Description</Col>
      </Row>
      {Array.isArray(roomTypes) && roomTypes.map((rt) => (
        <Row>
          <Col xs={3}>{rt.title}</Col>
          <Col xs={3}>{rt.rtId}</Col>
          <Col xs={3}>{getCurrencyWithPrice(rt.basePrice, 'LKR')}</Col>
          <Col xs={2.5}>{rt.desc}</Col>
          <Col xs={0.5} className="categoryTableHeaderTextContainer">
            <div
              className="categoryEditIcon"
              onClick={() => navigateEditScreen(rt)}
            >
              <img src={EditIcon} />
            </div>
          </Col>
        </Row>
      ))}
    </ListRoomWrapper>
  );
});

export default ListRoomTypes;
