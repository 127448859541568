import React, { Component, useState } from "react";
import styles from "../../Common/Style/Styles.css";

class formInput extends Component {
  render() {
    const {
      onChangeText,
      text = "User Name *",
      isValid = true,
      validationText = null,
      value,
      type = "text",
      placeholder,
    } = this.props;
    return (
      <div>
        <label className={styles.labelName}>{text}</label>
        <input
          placeholder={placeholder}
          className={styles.inputName}
          style={{
            borderColor: !isValid ? "rgba(255,95,95,.67)" : "white",
            borderWidth: "1px",
          }}
          value={value}
          onChange={(text) => onChangeText(text.target.value)}
          type={type}
          name="firstname"
        />
        {validationText ? (
          <div className={styles.validationText}>{validationText}</div>
        ) : null}
      </div>
    );
  }
}

export default formInput;
