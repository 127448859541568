import React, { useEffect, useState } from "react";
import _, { isEmpty, round } from "lodash";
import styles from "../Styles/Styles.css";
import useImage from "../../internal/hooks/useImage";
import { calculateItemCount } from "../../internal/manager/ProductManager";
import itemSelectedIcon from "../../../assets/icons/itemSelectedIcon.svg";

const ProductCard = (props) => {
  const {
    index,
    item,
    isEdit,
    onDelete,
    stockList,
    isSelected,
    onSelectProduct,
    onSelectServiceItem,
  } = props;
  const [nameIcon, setNameIcon] = useState("");
  const [base64Image, setBase64Image] = useState(null);
  const image = useImage(item.productImage || item.image);

  useEffect(() => {
    const imageId = item.productImage || item.image;
    if (!isEmpty(imageId)) {
      getImage(imageId);
    }
    let character = _.get(item, "productName", "").match(/\b(\w)/g);
    setNameIcon(_.toUpper(character[0]) + _.toUpper(character[1]));
  }, [item]);

  const getImage = async (path: any) => {
    if (window?.api) {
      await window.api.getFilePath(path, async (result) => {
        const newImage: any = await new Buffer(result).toString("base64");
        setBase64Image(newImage);
        return newImage;
      });
    } else {
      console.log("Image for ipad");
    }
  };

  const imageData = base64Image
    ? `data:image/png;base64,${base64Image}`
    : image;
  const stockItems = _.get(item, "stockItems", []);
  const itemCount = round(calculateItemCount(stockItems, stockList), 2);

  return (
    <div
      className={styles.serviceCardWrapper}
      onClick={() => {
        // if (index > -1 && isEmpty(item.priceVariants)) {
        //   onDelete();
        // } else {
        //   onSelectServiceItem(item);
        // }
        onSelectServiceItem(item);
        if (isEdit) {
          onSelectProduct(item);
        }
      }}
    >
      <div
        style={{
          backgroundColor: itemCount < 4 && !isEdit ? "#FFD6D6" : "#FFFFFF",
        }}
        className={styles.serviceCardImageWrapper}
      >
        {item.productImage || item.image ? (
          <img
            src={imageData || undefined}
            className={styles.productImageCard}
          />
        ) : (
          <div className={styles.serviceCardImageTitle}>{nameIcon}</div>
        )}
        {!isEdit ? (
          <div className={styles.stockRemainingCount}>{itemCount}</div>
        ) : null}
      </div>
      <div className={styles.itemText}>{_.startCase(item.productName)}</div>
      {index > -1 || isSelected ? (
        <div className={styles.serviceCardSelectedItemWrapper}>
          <img src={itemSelectedIcon} />
        </div>
      ) : null}
    </div>
  );
};

export default ProductCard;
