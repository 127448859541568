import styled from 'styled-components';
import BaseColors from '../../internal/helpers/BaseColors';

export const LoaderWrapper = styled.div`
width: 100%;
height: 60vw;
display: flex;
justify-content: center;
align-items: center;

& > div {
  padding: 24px;
  border-radius: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > div {
    display: flex;
    & > button {
      margin: 24px 8px;
      padding: 8px 16px;
      background:  ${BaseColors.pictonBlue};
      border: 1px solid ${BaseColors.pictonBlue};
      border-radius: 8px;
    }
    & > button.close {
      background:  ${BaseColors.wildSand};
      border: 1px solid ${BaseColors.baliHai};
    }
  }
}



`