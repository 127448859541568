import React, {Component} from 'react';
import styles from '../../../components/Styles/Styles.css';
import {Container, Row, Col} from 'react-grid-system';

const CashDrawer = () => {
  return (
    <Row>
      <Col xs={12}>
        <div className={styles.printerSetupWrapper}>
          <div className={styles.printerSetupText}>Device type</div>
          <input
            className={styles.printerSetupDeviceSelect}
            placeholder={'TCP / IP Device'}
          />
        </div>

        <div className={styles.printerSetupWrapper}>
          <div className={styles.printerSetupText}>Hostname or IP address</div>
          <input className={styles.printerSetupDeviceInput}/>
        </div>

        <div className={styles.printerSetupWrapper}>
          <div className={styles.printerSetupText}>Associated registers</div>
          <input className={styles.printerSetupDeviceInput}/>
        </div>

      </Col>
    </Row>
  )
};
export default CashDrawer;
