import React, {useEffect, useState} from 'react';
import selectedCustomerIcon from '../../../assets/icons/selectedCustomerIcon.svg';
import IconEdit from '../../../assets/svg/IconEdit.svg';
import _ from 'lodash';
import {CustomerCardMainContainer} from '../Styles/Styled';

const CustomerCard = (props) => {
  const {customer, isSelected, onSelectCustomer, onClickEditCustomer} = props;

  return (
    <CustomerCardMainContainer isSelected={isSelected}>
      <div id={'SELECT_CUSTOMER'} onClick={(event) => onSelectCustomer(event)}>
        <div>{_.upperFirst(customer.firstName)} {_.upperFirst(customer.lastName)}</div>
        {
          isSelected ?
            <img src={selectedCustomerIcon}/>
            : null
        }
        {
          isSelected ?
            <img id={'EDIT_CUSTOMER'}  onClick={(event)=> onClickEditCustomer(event)} src={IconEdit}/>
            : null
        }
      </div>
      <div>{customer.mobileNumber}</div>
    </CustomerCardMainContainer>
  )
};

export default CustomerCard;
