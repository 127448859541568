import React, { useEffect } from "react";
import _, { isArray } from "lodash";
import styles from "../Styles.css";
import { connect } from "react-redux";
import { Actions } from "../../../internal/app/Actions";
import { navigate } from "../../../internal/service/Navigation.service";
import UserDefaultImage from "../../../../assets/images/default-user.png";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const WelcomeView = (props) => {
  const {
    selectLoginEmployee,
    loginEmployees,
    fetchShopEmployees,
    merchantShop,
    loadingAction,
    masterSignOut,
    trialDetails,
    activeSubscriptions,
  } = props;
  const { action, loading } = loadingAction;

  useEffect(() => {
    if (!_.isEmpty(merchantShop)) {
      fetchShopEmployees(merchantShop.shopId);
    }
  }, []);

  useEffect(() => {
    if (isArray(activeSubscriptions)) {
      const isActiveSubscription = activeSubscriptions.length > 0;
      // const days = moment(trialDetails?.isExpired).diff(moment(), 'days');
      if (
        (!trialDetails && !isActiveSubscription) ||
        (trialDetails?.isExpired && !isActiveSubscription)
      ) {
        // if (!includes(PathsWithoutSubscription, pathname)) {
        navigate("/subscription-check");
        // }
      }
    }
  }, [trialDetails, activeSubscriptions]);

  return (
    <div className={styles.welcomeBackground}>
      <div className={styles.welcomeTopBoxContainer}>
        <div className={styles.welcomeToNetliseText}>
          Welcome to Netlise Cloud
        </div>
      </div>
      <div className={styles.welcomeEmployeeCardContainer}>
        {loading && action.type === "FETCH_SHOP_EMPLOYEES" ? (
          <div className={styles.productLoading}>
            <CircularProgress color="primary" size={40} value={5} />
          </div>
        ) : (
          loginEmployees.map((employee: any, index: number) => {
            let specializationText =
              employee.specializations &&
              employee.specializations.map((item) => item.title).join(", ");

            return (
              <div
                key={index}
                data-testid={`employee-${index}`}
                className={styles.welcomeEmployeeBox}
                onClick={() => selectLoginEmployee(employee)}
              >
                <div className={styles.welcomeImageContainer}>
                  <img
                    className={styles.welcomeImageContainer}
                    src={UserDefaultImage}
                  />
                </div>
                <div style={{ marginLeft: "20px", marginRight: "10px" }}>
                  <div className={styles.welcomeEmployeeName}>
                    {employee.name}
                  </div>
                  <div className={styles.welcomeEmployeeType}>
                    {specializationText}
                  </div>
                </div>
              </div>
            );
          })
        )}
        {!loading && loginEmployees?.length === 0 ? (
          <div className={styles.employeeNoUsers}>
            <h1>No employees found</h1>
            <div>
              Log into your dashboard and create new Employees to login to the
              Point of Sales application
            </div>
          </div>
        ) : null}
        {/* {loading && action.type === "FETCH_SHOP_EMPLOYEES" && (
          <div className={"productLoading"}>
            <CircularProgress color="primary" size={40} value={5} />
          </div>
        )} */}
      </div>
      <div onClick={() => masterSignOut()} className={styles.masterSignOut}>
        Sign Out
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    merchantShop: state.login.get("merchantShop"),
    loadingAction: state.common.get("loadingAction"),
    loginEmployees: state.employee.get("loginEmployees"),
    trialDetails: state.login.get("trialDetails"),
    activeSubscriptions: state.login.get("activeSubscriptions"),
  }),
  {
    masterSignOut: Actions.login.masterSignOut,
    selectLoginEmployee: Actions.login.selectLoginEmployee,
    fetchShopEmployees: Actions.employee.fetchShopEmployees,
  }
)(WelcomeView);
