import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { ModuleEvents as LoginModule } from "../login/Actions";
import _, { compact, concat, findIndex, uniqBy } from "lodash";
import { ModuleEvents as CommonModule } from "../common/Actions";
import { ModuleEvents as ProfileModule } from "../profile/Actions";
import { ModuleEvents as PaymentModule } from "../payment/Actions";
import { getShopSystemSetting } from "../../internal/manager/CommonManager";

const initialState = Map({
  orders: [],
  vehicles: [],
  dicounts: [],
  customerList: [],
  selectedSalon: {},
  selectedVehicle: {},
  selectedCustomer: {},
  salonAppointments: {
    appointments: [],
    lastKey: {},
  },
  transactionsList: {
    transactionsList: [],
    lastKey: null,
  },
  selectedView: "Service",
  selectedAppointment: {},
  appointmentsForDate: [],
  salonAppointmentList: [],
  completedAppointments: [],
  isOpenOrderSaveModal: false,
  unCompletedAppointments: [],
  appointmentsForDateLastKey: {},
  completedAppointmentsLastKey: {},
  unCompletedAppointmentsLastKey: {},
  orderView: "TABLE", // 'ORDER_QUEUE',
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case LoginModule.USER_LOGOUT: {
      return initialState;
    }
    case CommonModule.SELECT_VIEW: {
      return state.set("selectedView", payload);
    }
    case ModuleEvents.OPEN_SAVE_MODAl: {
      return state.set("isOpenOrderSaveModal", payload);
    }
    case ModuleEvents.CHANGE_VIEW_MODE: {
      return state.set("orderView", payload);
    }
    case LoginModule.MERCHANT_ONBOARD: {
      if (payload && payload.statusCode === 201) {
        const { data } = payload;
        const shopSetting = getShopSystemSetting(data);

        if (
          (data?.shopCategory === "FOOD_AND_BEVERAGES" ||
            data?.shopCategory === "HOSPITALITY") &&
          shopSetting?.isQueueTables
        ) {
          return state.set("orderView", "TABLE");
        } else {
          return state.set("orderView", "ORDER_QUEUE");
        }
      }
      return state;
    }
    case ProfileModule.UPDATE_SHOP:
    case LoginModule.GET_MERCHANT: {
      if (payload) {
        const shopSetting = getShopSystemSetting(payload);
        if (
          (payload.category === "FOOD_AND_BEVERAGES" ||
            payload?.category === "HOSPITALITY") &&
          shopSetting?.isQueueTables
        ) {
          return state.set("orderView", "TABLE");
        }
        return state.set("orderView", "ORDER_QUEUE");
      }
      return state;
    }
    case LoginModule.MASTER_SIGNOUT: {
      return state.set("orderView", "TABLE");
    }
    case ModuleEvents.SELECTED_APPOINTMENT: {
      return state.set("selectedAppointment", payload);
    }

    case PaymentModule.CLEAR_CURRENT_APPOINTMENT: {
      return state.set("selectedAppointment", {}).set("selectedVehicle", {});
    }

    case ModuleEvents.FETCH_ALL_APPOINTMENTS: {
      if (payload && !payload.error) {
        return state.set("salonAppointmentList", [
          ..._.orderBy(payload, ["createdTime"], ["desc"]),
        ] as any);
      }
      return state;
    }

    case ModuleEvents.ON_SUBSCRIPTION_ORDER: {
      if (payload && !payload.error) {
        const isComplete = payload.orderStatus === "COMPLETED";
        const transactionsList: any = state.get("transactionsList");
        const completedAppointments: any = state.get("completedAppointments");
        const unCompletedAppointments: any = state.get(
          "unCompletedAppointments"
        );

        // remove exisiting order and replace new one
        let indexC = completedAppointments.findIndex(
          ({ orderId }) => orderId === payload.orderId
        );
        if (indexC > -1) {
          completedAppointments.splice(indexC, 1);
        }
        let indexU = unCompletedAppointments.findIndex(
          ({ orderId }) => orderId === payload.orderId
        );
        if (indexU > -1) {
          unCompletedAppointments.splice(indexU, 1);
        }

        const index = findIndex(
          transactionsList.transactionsList,
          (item: any) => item.orderId === payload.orderId
        );
        if (isComplete) {
          if (index > -1) {
            transactionsList.transactionsList[index] = payload;
          } else {
            transactionsList.transactionsList.push(payload);
          }
          transactionsList.transactionsList = _.orderBy(
            transactionsList.transactionsList,
            ["updatedTime"],
            ["desc"]
          );
          return state
            .set("transactionsList", { ...transactionsList })
            .set("completedAppointments", [payload, ...completedAppointments])
            .set("unCompletedAppointments", [...unCompletedAppointments]);
        } else {
          if (payload.orderStatus === "REOPEN") {
            transactionsList.transactionsList.splice(index, 1);
          }
          return state
            .set("transactionsList", { ...transactionsList })
            .set("unCompletedAppointments", [
              payload,
              ...unCompletedAppointments,
            ])
            .set("completedAppointments", [...completedAppointments]);
        }
      }
      return state;
    }

    case ModuleEvents.ON_SUBSCRIPTION_DELETE_ORDER: {
      if (payload && !payload.error) {
        const completedAppointments: Array<any> = state.get(
          "completedAppointments"
        );
        const unCompletedAppointments: Array<any> = state.get(
          "unCompletedAppointments"
        );
        const cIndex = completedAppointments.findIndex(
          ({ orderId }) => orderId === payload.orderId
        );
        if (cIndex > -1) {
          completedAppointments.splice(cIndex, 1);
        }
        const pIndex = unCompletedAppointments.findIndex(
          ({ orderId }) => orderId === payload.orderId
        );
        if (pIndex > -1) {
          unCompletedAppointments.splice(pIndex, 1);
        }
        return state
          .set("unCompletedAppointments", [...unCompletedAppointments])
          .set("completedAppointments", [...completedAppointments]);
        // }
      }
      return state;
    }

    case ModuleEvents.FETCH_PAGINATE_APPOINTMENTS: {
      const salonAppointments: any = state.get("salonAppointments");
      if (payload && !payload.error) {
        const salonAppointmentLastKey = _.get(payload, "lastKey", {});
        const appointments = _.get(payload, "items", []);
        salonAppointments.appointments = [
          ...salonAppointments.appointments,
          ...appointments,
        ];
        salonAppointments.lastKey = salonAppointmentLastKey;
        return state.set("salonAppointments", { ...salonAppointments });
      }
      return state;
    }

    case ModuleEvents.FETCH_COMPLETED_APPOINTMENTS: {
      let transactionsList: any = state.get("transactionsList");
      const { result, lastKey } = payload;
      if (result && !result.error) {
        let transactions: any = [];
        let data: any = [...result.data];
        if (lastKey) {
          data = compact(
            concat(transactionsList.transactionsList, result.data)
          );
        }
        transactions = uniqBy(data, "orderId");
        transactionsList.transactionsList = _.orderBy(
          transactions,
          ["updatedTime"],
          ["desc"]
        );
        transactionsList.lastKey = _.get(result, "nextToken", null);
      }
      return state.set("transactionsList", { ...transactionsList });
    }

    case ModuleEvents.SEARCH_TRANSACTIONS: {
      let transactionsList: any = state.get("transactionsList");
      if (payload && !payload.error) {
        transactionsList.lastKey = _.get(payload, "LastEvaluatedKey", null);
        transactionsList.transactionsList = _.orderBy(
          _.get(payload, "transactionsList", []),
          ["updatedTime"],
          ["desc"]
        );
      }
      return state.set("transactionsList", { ...transactionsList });
    }

    case ModuleEvents.FETCH_UN_COMPLETED_APPOINTMENTS: {
      if (payload && !payload.error) {
        const lastKey = _.get(payload, "nextToken", {});
        const appointments: any = _.orderBy(
          payload.data,
          ["updatedTime"],
          ["desc"]
        );
        return state
          .set("unCompletedAppointments", appointments)
          .set("unCompletedAppointmentsLastKey", lastKey);
      }
      return state;
    }

    case ModuleEvents.PAGINATE_UN_COMPLETED_APPOINTMENTS: {
      if (payload && !payload.error) {
        const appointments: any = state.get("unCompletedAppointments");
        const lastKey = _.get(payload, "LastEvaluatedKey", {});
        const sortedAppointments = _.orderBy(
          _.get(payload, "Items", []),
          ["updatedTime"],
          ["desc"]
        );
        const allAppointments: any = [...appointments, ...sortedAppointments];
        return state
          .set("unCompletedAppointments", [...allAppointments])
          .set("unCompletedAppointmentsLastKey", lastKey);
      }
      return state;
    }

    case ModuleEvents.FETCH_APPOINTMENT_FOR_DATE: {
      if (payload && !payload.error) {
        const lastKey = _.get(payload, "LastEvaluatedKey", {});
        const sortedAppointments: any = _.orderBy(
          _.get(payload, "Items", []),
          ["updatedTime"],
          ["desc"]
        );
        return state
          .set("appointmentsForDate", sortedAppointments)
          .set("appointmentsForDateLastKey", lastKey);
      }
      return state;
    }

    case ModuleEvents.FILTER_UN_COMPLETED_APPOINTMENTS: {
      if (payload && !payload.error) {
        const lastKey = _.get(payload, "nextToken", {});
        const appointments: any = _.orderBy(
          payload.data,
          ["updatedTime"],
          ["desc"]
        );
        return state
          .set("unCompletedAppointments", appointments)
          .set("unCompletedAppointmentsLastKey", lastKey);
      }
      return state;
    }

    case ModuleEvents.SEARCH_SERVICE_AND_PRODUCT: {
      if (payload && !payload.error) {
        const sortOrders: any = _.orderBy(
          payload,
          ["productName"] || ["serviceTitle"],
          ["asc"]
        );
        return state.set("orders", [...sortOrders]);
      }
      return state;
    }

    case ModuleEvents.FETCH_VEHICLES: {
      if (payload && !payload.error) {
        const vehicles: any = _.orderBy(
          _.get(payload, "Items", []),
          ["brand"],
          ["desc"]
        );
        return state.set("vehicles", vehicles);
      }
      return state;
    }

    case ModuleEvents.ON_SELECT_VEHICLE: {
      if (payload) {
        return state.set("selectedVehicle", payload);
      }
      return state;
    }

    case ModuleEvents.CREATE_VEHICLE: {
      const vehicles: any = state.get("vehicles");
      if (payload && !payload.error) {
        vehicles.push(payload);
        return state.set("vehicles", [...vehicles]);
      }
      return state;
    }

    case ModuleEvents.UPDATE_VEHICLE: {
      const vehicles: any = state.get("vehicles");
      if (payload && !payload.error) {
        const index = _.findIndex(
          vehicles,
          (item: any) => item.vid === payload.vid
        );
        vehicles[index] = payload;
        return state.set("vehicles", [...vehicles]);
      }
      return state;
    }

    case ModuleEvents.FETCH_DISCOUNTS: {
      if (payload && !payload.error) {
        return state.set("discounts", payload);
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
