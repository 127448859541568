import React from "react";
import useImage from "../../internal/hooks/useImage";
import CloseIcon from "../../../assets/images/closeIcon.png";
import IconProductPlus from "../../../assets/svg/IconProductPlus.svg";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const ImageUploadCard = (props) => {
  const { imageId, onRemoveImage, onUploadProductImage, loadingAction } = props;
  const { action, loading } = loadingAction;
  const image = useImage(imageId);

  const getImage = (picture) => {
    onUploadProductImage(picture);
  };

  return (
    <div>
      {imageId ? (
        <div className="addProductImageBox">
          <img className="productImageContainer" src={image || undefined} />
          <div
            className="imageRemoveIconContainer"
            onClick={() => onRemoveImage()}
          >
            <img className="imageRemoveIcon" src={CloseIcon} />
          </div>
        </div>
      ) : (
        <div
          className="addServiceImageBox"
          onClick={() => {
            document.getElementById("getFile").click();
          }}
        >
          <input
            style={{ display: "none" }}
            id={"getFile"}
            type="file"
            accept="image/*"
            onChange={(image) => {
              getImage(image.target.files[0]);
            }}
          />
          {loading &&
          (action.type === "UPLOAD_PRODUCT_IMAGE" ||
            action.type === "UPLOAD_SERVICE_IMAGE") ? (
            <div style={{ position: "absolute" }}>
              <CircularProgress color="primary" size={30} value={5} />
            </div>
          ) : (
            <img src={IconProductPlus} />
          )}
        </div>
      )}
    </div>
  );
};
export default ImageUploadCard;
