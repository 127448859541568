import moment from "moment";
import { nanoid } from "nanoid";
import { isEmpty } from "lodash";
import { ModuleEvents } from "./Actions";
import { Actions } from "../../internal/app/Actions";
import { navigate } from "../../internal/service/Navigation.service";
import { mapPaymentDetails } from "../../internal/manager/PaymentManager";

const Handlers = {
  [ModuleEvents.SELECT_ROOM_TYPE]: ({ payload }) => {
    if (payload && !payload.error) {
      navigate("/edit-room");
    }
  },
  [ModuleEvents.SAVE_ROOMS]: ({ dispatch, payload }) => {
    if (payload && !payload.error) {
      dispatch(Actions.rooms.setRoomMode("saved"));
    } else {
      // return warning("Error occurred while saving tables");
    }
  },
  [ModuleEvents.EDIT_ROOM_TYPES]: ({ payload }) => {
    if (payload && !payload.error) {
      navigate("/rooms");
    }
  },
  [ModuleEvents.CREATE_ROOM_TYPE]: ({ payload }) => {
    if (payload && !payload.error) {
      navigate("/rooms");
    }
  },
  [ModuleEvents.CREATE_ROOM_ORDER]: async ({ dispatch, payload, appState }) => {
    if (payload.rmId) {
      const shopData = appState.login.get("merchantShop");
      const currentUser = appState.login.get("currentUser");
      const batchSession = appState.login.get("batchSession");
      const quickCustomer = appState.customer.get("quickCustomer");
      const selectedCustomer = appState.customer.get("selectedCustomer");
      const loginEmployees = appState.employee.get("loginEmployees");
      const selectedAppointment = appState.orders.get("selectedAppointment");

      const employee = loginEmployees.find(
        ({ empId }) => empId === currentUser.empId
      );
      const date = moment(new Date(Date.now()).toDateString()).valueOf();

      const params = {
        date,
        shopData,
        totalPrice: 0,
        isSendSms: false,
        offerDetails: [],
        status: "PENDING",
        serviceCharge: null,
        selectedVehicle: {},
        purchaseServices: [],
        purchaseProducts: [],
        selectedEmployee: employee,
        roomOrder: {
          rmId: payload.rmId,
          rmNumber: payload.rmNumber,
        },
        // selectedAppointment,
        userId: currentUser.empId,
        batchId: batchSession.batchId,
        time: moment().format("hh:mm A"),
        // discounts: appointmentDiscounts,
        selectedCustomer: !isEmpty(selectedCustomer)
          ? selectedCustomer
          : quickCustomer,
        recieptId: selectedAppointment.recieptId || nanoid(5),
      };
      const orderData = await mapPaymentDetails(params);
      const activityLog = {
        logId: nanoid(),
        actType: "CHECKOUT",
        empId: employee.empId,
        shopId: shopData.shopId,
        createdTime: Date.now(),
        updatedTime: Date.now(),
        metaData: [
          {
            key: "ORDER",
            value: orderData,
          },
          {
            key: "EMPLOYEE",
            value: employee,
          },
        ],
      };
      dispatch(Actions.payment.checkout(params, "/product"));
      dispatch(Actions.activityLog.createActivityLog(activityLog));
    }
  },
};

export default Handlers;
