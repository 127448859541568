import React from "react";
import moment from "moment";
import _, {
  get,
  isEmpty,
  groupBy,
  isString,
  startCase,
  lowerCase,
  capitalize,
} from "lodash";
import {
  getCurrencyWithPrice,
  mapProductWarranties,
} from "../../../internal/manager/PaymentManager";
import DefaultImage from "../../../../assets/tap-house.jpeg";

const PaymentReciept80 = ({ details, merchantShop, base64Image }) => {
  const {
    params,
    // image,
    // selectedVehicle,
    // selectedCustomer,
    purchaseProducts,
    purchaseServices,
    givenPrice,
    shopData,
    total,
    subTotal,
    preview = false,
    appointmentDiscounts,
    tableOrder,
  } = details;
  const { serviceCharge, selectedEmployee } = params;
  const date = moment(params.date).format("Do MMMM YYYY");
  let greet = "Thank you for the order and see you next time!";
  if (shopData.receiptEnd && isString(shopData.receiptEnd)) {
    greet = shopData.receiptEnd.split("\n").map((str) => <div>{str}</div>);
  }

  const imageSource = base64Image
    ? `data:image/png;base64,${base64Image}`
    : DefaultImage;

  const tableNumber =
    !isEmpty(params?.tableOrder) ||
    !isEmpty(get(params, "selectedAppointment.tableOrder", null))
      ? params?.tableOrder?.tbNumber ||
        get(params, "selectedAppointment.tableOrder.tbNumber", "")
      : null;

  const groupProducts = groupBy(purchaseProducts, "id");
  const warranties: any = mapProductWarranties(purchaseProducts);

  return (
    <div className="reciept-wrapper">
      <div className="reciept-logo-area">
        <div>
          <img src={imageSource} />
        </div>
        <h1>{startCase(merchantShop.shopName)}</h1>
        <h1>{startCase(merchantShop.address)}</h1>
        <h1>{startCase(merchantShop.phoneNumber)}</h1>
      </div>
      <hr className="seperator-line" />
      <div className="reciept-details">
        <div>
          <div>Receipt Number:</div>
          <div>{params.recieptId}</div>
        </div>
        <div>
          <div>{date}</div>
          <div>{!isEmpty(tableNumber) ? `Table: ${tableNumber}` : ""}</div>
        </div>
        <div>
          <div>{`${capitalize(selectedEmployee?.firstName)} ${capitalize(
            selectedEmployee?.lastName.charAt(0)
          )}`}</div>
          <div>{""}</div>
        </div>
        {/*<div>*/}
        {/*  <div>Receipt Date</div>*/}
        {/*  <div>{date}</div>*/}
        {/*</div>*/}
      </div>
      {/* <div className="reciept-invoice-title">
        <h1>INVOICE: {params.recieptId}</h1>
        <div>
          {/* <p>{shopData.fbLink}</p>
            <p>{shopData.phoneNumber}</p>
            <p>{shopData.address}</p> /}
        </div>
      </div> */}
      <div className="reciept-table-head">
        <div>Description</div>
        <div>QTY</div>
        {/* <div>Unit</div> */}
        <div>Total</div>
      </div>
      <hr className="seperator-line" />

      {Object.keys(groupProducts).map((key) => {
        const productName = get(groupProducts[key], "[0].productName", "");

        return (
          <div className="reciept-table-main-wrapper">
            {/* {isOptions && (
              <div className="reciept-table-header-text">
                {startCase(productName)}
              </div>
            )} */}
            <div className="reciept-table-details-wrapper">
              {groupProducts[key] &&
                groupProducts[key].map((item: any) => {
                  let title: string;
                  const productOptionName = get(
                    item,
                    "priceVariants[0].optionUnit",
                    ""
                  );
                  const price = getCurrencyWithPrice(
                    item.price,
                    shopData.currency,
                    false
                  );
                  
                  const isOptions = !isEmpty(
                    get(item, "priceVariants", null)
                  );

                  if (isOptions) {
                    title = `${startCase(productName)} - ${productOptionName}`;
                  } else {
                    title = startCase(get(item, "productName", ""));
                  }


                  if (!isEmpty(item.modifiers)) {
                    const modifierNames = item.modifiers
                      .map(
                        (modifier: any) =>
                          modifier.items?.map((item) => `${item.name} X ${item.quantity}`)
                      )
                      .join(", ");
                    title = `${title} - ${modifierNames}`
                  }
            
                  return (
                    <div className="reciept-table-row">
                      <div>{title}</div>
                      <div>{item.quantity}</div>
                      <div>{price}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
      {purchaseServices.map((line) => (
        <div className="reciept-table-row">
          <div>{line.serviceTitle}</div>
          <div>{line.quantity}</div>
          {/* <div>
            {getCurrencyWithPrice(line.servicePrice, shopData.currency, false)}
          </div> */}
          <div>
            {getCurrencyWithPrice(line.price, shopData.currency, false)}
          </div>
        </div>
      ))}
      <hr className="seperator-line" />
      {/* <div className="reciept-details">
          <div>
            <div>RECEIEPT NUMBER</div>
            <div>{params.recieptId}</div>
          </div>
          <div>
            <div>RECEIEPT DATE</div>
            <div>{date}</div>
          </div>
        </div>
      </div>

      <div className="reciept-table-head">
        <div>Description</div>
        <div>QTY</div>
        <div>Unit Price</div>
        <div>Total</div>
      </div>
      {purchaseServices.map((line) => (
          <div className="reciept-table-row">
            <div>{line.serviceTitle}</div>
            <div>{line.quantity}</div>
            <div>{getCurrencyWithPrice(line.servicePrice, shopData.currency, false)}</div>
            <div>{getCurrencyWithPrice(line.price, shopData.currency, false)}</div>
          </div>
      ))}
      {purchaseProducts.map((line) => (
          <div className="reciept-table-row">
            <div>{line.productName}</div>
            <div>{line.quantity}</div>
            <div>{getCurrencyWithPrice(line.productSalePrice, shopData.currency, false)}</div>
            <div>{getCurrencyWithPrice(line.price, shopData.currency, false)}</div>
          </div>
      ))}
      */}
      <div className="reciept-order-summary">
        <div>
          <div>
            <div>Sub Total</div>
            <div>{getCurrencyWithPrice(subTotal, shopData.currency)}</div>
          </div>
          {appointmentDiscounts.map((item) => (
            <div>
              <div>{item.title}</div>
              <div>{getCurrencyWithPrice(item.amount, shopData.currency)}</div>
            </div>
          ))}
          {serviceCharge ? (
            <div className="">
              <div>Service Charge</div>
              <div>
                {getCurrencyWithPrice(serviceCharge.amount, shopData.currency)}
              </div>
            </div>
          ) : null}
          <div className="total">
            <div>Total</div>
            <div>{getCurrencyWithPrice(total, shopData.currency)}</div>
          </div>
          <hr className="seperator-line" />
          {preview ? null : (
            <>
              <div>
                <div>Paid Amount</div>
                <div>{getCurrencyWithPrice(givenPrice, shopData.currency)}</div>
              </div>
              <div>
                <div>Remainder</div>
                <div>
                  {getCurrencyWithPrice(
                    `${givenPrice - total}`,
                    shopData.currency
                  )}
                </div>
              </div>
            </>
          )}

          <div className="warranty-wrapper">
            <div className="warranty-header-text">Warranty</div>
            <div className="warranty-item-wrapper">
              {!isEmpty(warranties) &&
                warranties.map((item: any) => {
                  return (
                    <div className="warranty-item">
                      <div>
                        <div>{item.title}</div>
                        <div>{`${item.duration} ${lowerCase(
                          item.durationType
                        )}`}</div>
                      </div>
                      <div>{item.description}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <hr className="seperator-line" />
      <div className="reciept-customer-sign">
        <p>{greet}</p>
        {/* <div>
          <hr />
          <p>Customer Signature</p>
        </div> */}
      </div>
      <p className="reciept-footer">Powered by Netlise +94 760 847 848</p>
    </div>
  );
};
export default PaymentReciept80;
