import { useEffect, useState } from "react";
import { generateImageUrl } from "../manager/ImageManager";

const useImage = (uri: string | null) => {
  const [image, setImage] = useState<null | string>(null);

  const getLink = async (uri) => {
    const link = await generateImageUrl(uri);
    if (link) {
      setImage(link);
    }
  }

  useEffect(() => {
    if (uri) {
      getLink(uri);
    }
  }, [uri]);

  return image;
}

export default useImage;