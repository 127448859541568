// import { ipcRenderer } from 'electron';
import { get } from "lodash";

const MYSTORAGE_KEY_PREFIX = "@Netlise:";
let dataMemory: any = {};

export class Storage {
  static syncPromise: any = null;

  /**
   * This is used to set a specific item in storage
   */
  static setItem(key: string, value: any) {
    // @ts-ignore
    if (window.api) {
      // ipcRenderer.send('set', {key: MYSTORAGE_KEY_PREFIX + key, value});
    // @ts-ignore
      window.api.set({ key: MYSTORAGE_KEY_PREFIX + key, value });
      dataMemory[key] = value;
      return dataMemory[key];
    // @ts-ignore
    } else if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
    // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({type: 'SET', data: { key: MYSTORAGE_KEY_PREFIX + key, value }}))
      dataMemory[key] = value;
      return dataMemory[key];
    }
    return null;
  }

  /**
   * This is used to get a specific key from storage
   */
  static getItem(key: string) {
    return get(dataMemory, key, undefined);
  }

/**
   * This is used to remove an item from storage
   */
  static removeItem(key: string) {
    // @ts-ignore
    if (window.api) {
    // @ts-ignore
      window.api.send("remove", { key: MYSTORAGE_KEY_PREFIX + key });
    // @ts-ignore
    } else if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
    // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({type: 'REMOVE', data: { key: MYSTORAGE_KEY_PREFIX + key }}))
    }
    // ipcRenderer.send('remove', {key: MYSTORAGE_KEY_PREFIX + key});
    return delete dataMemory[key];
  }

  /**
   * This is used to clear the storage
   */
  static clear() {
    dataMemory = {};
    return dataMemory;
  }

  static asyncUpdate(data) {
    Object.keys(data).forEach((key) => {
      if (key.startsWith(MYSTORAGE_KEY_PREFIX)) {
        const value = data[key];
        const memoryKey = key.replace(MYSTORAGE_KEY_PREFIX, "");
        dataMemory[memoryKey] = value;
      }
    });
  }

  /**
   * Will sync the MyStorage data from AsyncStorage to storageWindow MyStorage
   */
  static sync() {
    // @ts-ignore
    if (!Storage.syncPromise && window.api) {
      // @ts-ignore
      Storage.syncPromise = new Promise((res) => {
      // @ts-ignore
        window.api.getStore((store) => {
          Object.keys(store).forEach((key) => {
            if (key.startsWith(MYSTORAGE_KEY_PREFIX)) {
              const value = store[key];
              const memoryKey = key.replace(MYSTORAGE_KEY_PREFIX, "");
              dataMemory[memoryKey] = value;
            }
          });
          res(store);
        });
      });
    // @ts-ignore
    } else if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({type: 'GET_ALL'}));
      return Promise.resolve();
    }
    return Storage.syncPromise;
  }
}

export default Storage;
