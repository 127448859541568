import _ from 'lodash';
import Repository from './Repository';
import {isNetworkError} from "../manager/AppointmentManager";
if (typeof Buffer === 'undefined') global.Buffer = require('buffer').Buffer;
class ReportRepository extends Repository {
  client: any = null;
  initialized: any = false;

  init(store, client) {
    this.store = store;
    this.client = client;
    this.initialized = true;
  }

  fetchReportSummary = async (pointId: string, reportMonth: string) => {
    const isOnline = await this.isCheckOnline();
    if (!isOnline) {
      return;
    }

    const message = this.buildMessage({pointId, reportMonth});
    const result = await this.apiPost({
      apiName: this.API_REPORTS,
      path: `/reports/summary`,
      message,
    });
    return result;
  };

  fetchReportForDate = async (shopId: string, startDate: bigint, retryCount = 1) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        return;
      }

      const message = this.buildMessage({shopId, startDate});
      const result = await this.apiPost({
        apiName: this.API_REPORTS,
        path: `/reports/summary`,
        message,
      });

      return result.report;
    } catch (error) {
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchReportForDate(shopId, startDate, ++retryCount);
      }

      return {error};
    }
  };

  fetchReportForDateRange = async (params) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        return;
      }
      const message = this.buildMessage(params);

      const result = await this.apiPost({
        apiName: this.API_REPORTS,
        path: `/reports/summary/range`,
        message,
      });
      return result.reportsSummary;
    } catch (error) {
      console.warn('fetch reports appointment', error);
      return {error};
    }
  };

  fetchAppointmentsForDate = async (shopId, date, limit, lastKey, retryCount = 1) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        return;
      }

      const params = {
        shopId,
        appointmentDate: date,
        limit,
        lastKey,
        endpointType: 'FILTER_APPOINTMENT_DATE'
      };
      const message = this.buildMessage(params);

      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/orders`,
        message,
      });
      return result.appointmentResult;
    } catch (error) {
      console.warn('fetch appointment', error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchAppointmentsForDate(shopId, date, limit, lastKey, ++retryCount);
      }
      return {error};
    }
  };

  fetchAppointmentsForDateRange = async (shopId, startDate, endDate, limit, lastKey) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        return null;
      }
      const message = this.buildMessage({shopId, startDate, endDate, limit, lastKey});

      const result = await this.apiPost({apiName: this.DASHBOARD_API_V2, path: `/appointments/date-range`, message});
      const appointments = _.get(result, 'appointmentsResult.items', []);
      const totalCount = _.get(result, 'appointmentsCount', 0);
      return {appointments, totalCount};
    } catch (error) {
      console.warn("error", error);
      return {error}
    }
  };
}
export default new ReportRepository();
