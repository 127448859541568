import React, {Component, useRef, useEffect, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import {Container, Row, Col} from 'react-grid-system';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import {connect} from "react-redux";
import {Actions} from "../../../internal/app/Actions";
import _ from 'lodash';
import '../../Styles/ModelStyles.css'

const customStyles = {
  content: {
    top: '20%',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
    left: "calc(50% - 265px)",
    border: '1px solid rgba(112, 112, 112, 0.1)',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },
};

const cashInReason = [
  {id: 1, title: 'Starting cash for drawer', value: 'STARTING_CASH'},
  {id: 2, title: 'Reload cash drawer', value: 'RELOAD_CASH'},
  {id: 3, title: 'Refill drawer', value: 'REFILL_CASH'},
];

const cashOutReason = [
  {id: 1, title: 'Pay vendor', value: 'PAY_VENDOR'},
  {id: 2, title: 'Creating change', value: 'CREATE_CHANGE'},
  {id: 3, title: 'Ending cash for drawer', value: 'END_CASH'},
];

const CashInOutModal = (props) => {
  const {type, salon, isOpen, closeModal, onCreateCash, selectedCashData, onDeleteCash} = props;
  const [header, setHeader] = useState('Cash In');
  const [inputLabel, setInputLabel] = useState('CASH IN AMOUNT*');
  const [cash, setCash] = useState(0);
  const [reasons, setReasons] = useState(cashInReason);
  const [selectedReason, setSelectedReason] = useState({});

  useEffect(() => {
    setHeader(type === 'CASH_IN' ? 'Cash In' : 'Cash Out');
    setInputLabel(type === 'CASH_IN' ? 'CASH IN AMOUNT*' : 'CASH OUT AMOUNT*');
    setReasons(type === 'CASH_IN' ? cashInReason : cashOutReason);
  }, [type]);

  const onChangeCash = (text) => {
    setCash(text);
  };

  useEffect(() => {
    if (!_.isEmpty(selectedCashData)) {
      const type = selectedCashData.type;
      const amount = selectedCashData.amount ? (selectedCashData.amount).toString() : 0;
        setHeader(type === 'CASH_IN' ? 'Cash In' : 'Cash Out');
      setInputLabel(type === 'CASH_IN' ? 'CASH IN AMOUNT*' : 'CASH OUT AMOUNT*');
      setReasons(type === 'CASH_IN' ? cashInReason : cashOutReason);
      onChangeCash(amount);
      setSelectedReason(selectedCashData.reason);
    }
  }, [selectedCashData]);

  const onClick= (event) => {
    if(!_.isEmpty(cash) && !_.isEmpty(selectedReason)){
      const data = {
        cash: parseFloat(cash),
        event,
        reason: selectedReason,
      };
      onCreateCash(data)
    }
  };

  const onClickReason = (reson) => {
    setSelectedReason(reson);
  };

  const isUpdate = !_.isEmpty(selectedCashData);

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.cashInOutModalContainer}
    >
      <div>
        <div className={styles.cashInOutModalHeaderWrapper} onClick={() => closeModal()}>
          <div className={styles.cashInOutModalHeaderText}>{header}</div>
          <img alt="icon" src={CANCEL}/>
        </div>

        <Container>
          <Row className={styles.cashInOutModalBottomWrapper}>
            <Col md={12}>
              <div className={styles.cashInOutModalInputWrapper}>
                <div className={styles.cashInOutModalLabelText}>{inputLabel}</div>
                <div className={styles.cashInOutModalCurrencyText}>{`${salon.currency}`}</div>
                <input value={cash} className={styles.cashInOutModalInput} onChange={(number) => onChangeCash(number.target.value)} placeholder="Cash"/>
              </div>

            </Col>

            <Col md={12} className={styles.cashInOutModalColWrapper}>
              <div className={styles.cashInOutModalLabelText}>{'REASON*'}</div>
              <div className={styles.cashInOutModalFilterButtonWrapper}>
                {
                  reasons.map((item) => {
                    const isSelected = _.get(selectedReason, 'id', '') === item.id;
                    return (
                      <div className={isSelected ? styles.cashInOutModalFilterSelectButton : styles.cashInOutModalFilterButton} onClick={() => onClickReason(item)}>
                        <div onClick={() => onClickReason(item)} className={isSelected ? styles.cashInOutModalFilterSelectButtonText : styles.cashInOutModalFilterButtonText}>{item.title}</div>
                      </div>
                    )
                  })
                }
              </div>
            </Col>

            <Col md={12}>
              <div className={isUpdate ? styles.cashInOutModalBottomDeleteButtonWrapper : styles.cashInOutModalBottomButtonWrapper}>
                {
                  isUpdate ?
                    <div className={styles.cashInOutModalDeleteButton} onClick={() => onDeleteCash()}>
                      <div className={styles.cashInOutModalSaveText}>Delete</div>
                    </div>
                    : null
                }
                <div className={styles.cashInOutModalOpenButton} onClick={() => onClick('DRAWER')}>
                  <div className={styles.cashInOutModalOpenText}>Open drawer</div>
                </div>
                <div className={!_.isEmpty(cash) && !_.isEmpty(selectedReason) ? styles.cashInOutModalSaveButton : styles.cashInOutModalSaveButtonDisabled} onClick={() => onClick('SAVE')}>
                  <div className={styles.cashInOutModalSaveText}>Save</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ReactModal>
  );
};
export default connect(
  state => ({
    salon: state.salon.get('salon'),
  }),
  ({
  })
)(CashInOutModal);
