import Repository from "./Repository";
import {getValueFromLocalStorage, setValueToLocalStorage} from "../manager/CommonManager";

class PaymentRepository extends Repository{

  async createAppointment() {

  };

  async fetchAllAppointments() {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list =  await getValueFromLocalStorage('APPOINTMENTS');
        if (list) {
          return JSON.parse(list);
        }

        return;
      }

      const result:any = await this.getData('http://localhost:7001/appointment/appointments');
      await setValueToLocalStorage('APPOINTMENTS', JSON.stringify(result.data.data));
      return result.data.data;
    } catch (error) {
      return {error};
    }
  };

  async fetchTrial(shopId: string) {
    try {
      const result = await this.apiGet({
        apiName: this.DASHBOARD_API,
        path: `/shop/trial/${shopId}`,
      });
      return result.result;
    } catch (error) {
      console.warn('error', error);
      return {error};
    }
  }
}

export default new PaymentRepository();
