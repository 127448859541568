import styled from 'styled-components';
import BaseColors from '../../../internal/helpers/BaseColors';

export const DeleteButton = styled.button`
  font-size: 18px;
  font-weight: 700;
  margin: auto 1rem;
  padding: 1rem .8rem;
  width: calc(100% - 2rem);
  color: ${BaseColors.RedOrange};
  border: 1px solid ${BaseColors.RedOrange};
  
  &:hover {
    cursor: pointer;
  }
`