import React from 'react';
import styles from "../Styles/Styles.css";
import _, { startCase } from 'lodash';
import {generateImageUrl} from "../../internal/manager/ImageManager";
import defaultStylistImage from "../../../assets/images/default-user.png";
import itemSelectedIcon from "../../../assets/icons/itemSelectedIcon.svg";

const EmployeeComponent = (props) => {
  const {employeeList, onSelectEmployee, selectedEmployee} = props;

  const onSelectedStylist = (employee) => {
    let isSelected = _.get(selectedEmployee, 'empId') === employee.empId;
    onSelectEmployee(isSelected ? {} : employee);
  };

  return (
    <div className={styles.stylistComponentWrapper}>
      {
        employeeList && employeeList.map((item) => {
          let isSelected = _.get(selectedEmployee, 'empId') === item.empId;
          return (
            <div className={styles.stylistComponentImageWrapper} onClick={() => onSelectedStylist(item)}>
              <img
                className={styles.stylistComponentImage}
                src={item.profileImage ? generateImageUrl(item.profileImage) : defaultStylistImage}
              />
              <div className={styles.stylistComponentNameText}>{startCase(item.name)}</div>

              {
                isSelected ?
                  <div className={styles.stylistComponentSelectedItem}>
                    <img src={itemSelectedIcon}/>
                  </div>
                  : null
              }
            </div>
          )
        })
      }
    </div>
  )
};

export default EmployeeComponent;
