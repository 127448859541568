import React from 'react';
import {ModuleEvents} from './Actions';
import {navigate} from "../../internal/service/Navigation.service";
import swal from 'sweetalert';
import {Actions} from "../../internal/app/Actions";

export default {
  [ModuleEvents.CREATE_CUSTOMER]: ({dispatch, payload, appState}) => {
    if(payload && payload.code === "SUCCESSFULLY_CREATED") {
      return success("Customer create successfully");
    } else if (payload && payload.code === "CLIENT_ALREADY_CREATED") {
      return warning(payload.message);
    } else {
      return warning("Customer create unsuccessful");
    }
  },

  [ModuleEvents.ON_SELECT_CUSTOMER]: ({dispatch, payload, appState}) => {
    if(payload) {
      const shop = appState.login.get('merchantShop');
      dispatch(Actions.orders.fetchVehicles(shop.shopId, payload.id));
    }
  },

}

const success = (alert) => {
  return swal({
    title: alert,
    icon: "success"
  });
};

const warning = (alert) => {
  return swal({
    title: alert,
    icon: "warning",
    buttons: true,
    dangerMode: true
  });
};


