import React, { useEffect, useState } from "react";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import ReactModal from "react-modal";
import {
  InputWrapper,
  RadioWrapper,
  ButtonWrapper,
  CounterWrapper,
  TableModeWrapper,
  TableModalWrapper,
} from "./Styled";
import { nanoid } from "nanoid";
import { connect } from "react-redux";
import { Col, Row } from "react-grid-system";
import { Actions } from "../../../internal/app/Actions";
import CANCEL from "../../../../assets/icons/cancel.svg";
import SubIcon from "../../../../assets/svg/IconSub.svg";
import AddIcon from "../../../../assets/svg/IconAddItem.svg";
import styles from "../../../components/Styles/ModelStyles.css";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 265px)",
    border: "1px solid rgba(112, 112, 112, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const TableModal = ({
  isOpen = true,
  closeModal = () => {},
  editTables,
  tableAreas,
  selectedArea,
  selectedTable,
}) => {
  const [seats, setSeats] = useState(1);
  const [tableNumber, setTableNumber] = useState("1");
  const [isActive, setActive] = useState<boolean>(true);
  const [type, setType] = useState<"SQUARE" | "ROUND">("SQUARE");

  const onChangeEvent = (type = "INCREMENT") => {
    if (type === "INCREMENT") {
      setSeats(seats < 50 ? seats + 1 : seats);
    } else if (type === "DECREMENT") {
      setSeats(seats > 0 ? seats - 1 : 0);
    }
  };

  const setInitialState = () => {
    setTableNumber("");
    setSeats(1);
    setActive(true);
    setType("SQUARE");
  };

  useEffect(() => {
    if (selectedTable) {
      setTableNumber(selectedTable.tbNumber);
      setSeats(selectedTable.seats ? parseInt(selectedTable.seats) : 0);
      setActive(selectedTable.tbType === "ACTIVE");
      setType(selectedTable.tbShape);
    } else {
      setInitialState();
    }
  }, [selectedTable]);

  const onModifyTable = (deleteTable = false) => {
    const index = tableAreas.tableAreas.findIndex(
      (tb: any) => tb.areaId === selectedArea
    );
    if (index > -1) {
      const tables = tableAreas.tableAreas[index].tables;

      if (deleteTable) {
        const filteredTables = tables.filter(
          (tb) => tb.tbId !== selectedTable.tbId
        );
        tableAreas.tableAreas[index].tables = filteredTables;
      } else if (selectedTable) {
        const idx = tables.findIndex((tb) => tb.tbId === selectedTable.tbId);
        const updatedTable = {
          ...tables[idx],
          seats,
          tbShape: type,
          tbNumber: tableNumber,
          tbType: isActive ? "ACTIVE" : "INACTIVE",
        };
        tables[idx] = updatedTable;
        tableAreas.tableAreas[index].tables = tables;
      } else {
        const updatedTables = [
          ...tables,
          {
            seats,
            x: 100,
            y: 100,
            h: 100,
            w: 100,
            tbShape: type,
            tbId: nanoid(),
            tbNumber: tableNumber,
            tbType: isActive ? "ACTIVE" : "INACTIVE",
          },
        ];
        tableAreas.tableAreas[index].tables = updatedTables;
      }
      editTables({ ...tableAreas });
      setInitialState();
      closeModal();
    } else {
      closeModal();
      console.log("No area");
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.addAppointmentModel}
    >
      <TableModalWrapper>
        <div className="modal-header">
          <div className={styles.addAppointmentHeaderText}>Add Table</div>
          <img alt="icon" src={CANCEL} onClick={closeModal} />
        </div>

        <div className="modal-content">
          <Row>
            <Col>
              <Input
                {...{
                  label: "TABLE NUMBER",
                  value: tableNumber,
                  onChange: setTableNumber,
                  placeholder: "Table identity",
                }}
              />
            </Col>
            <Col>
              <Counter
                {...{
                  label: "SEATS",
                  value: seats,
                  changeItemCount: onChangeEvent,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TableModeWrapper type={type}>
                <div>TABLE TYPE</div>
                <div>
                  <div onClick={() => setType("SQUARE")}></div>
                  <div onClick={() => setType("ROUND")}></div>
                </div>
              </TableModeWrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              <RadioInput
                label={"ACTIVE TABLE"}
                onChange={setActive}
                value={isActive}
              />
            </Col>
          </Row>
          <ButtonWrapper>
            <button onClick={() => onModifyTable()}>
              <div>{selectedTable ? "Save" : "Add"} Table</div>
            </button>
            <button onClick={() => closeModal()}>
              <div>Cancel</div>
            </button>
            {selectedTable ? (
              <button
                className="delete-button"
                onClick={() => onModifyTable(true)}
              >
                <div>Delete</div>
              </button>
            ) : null}
          </ButtonWrapper>
        </div>
      </TableModalWrapper>
    </ReactModal>
  );
};

const Input = ({ label, value, onChange, placeholder }) => {
  return (
    <InputWrapper>
      <div>{label}</div>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </InputWrapper>
  );
};

const Counter = ({ label, changeItemCount, value }) => {
  return (
    <CounterWrapper>
      <div>{label}</div>
      <div>
        <img src={SubIcon} onClick={() => changeItemCount("DECREMENT")} />
        <div>{value}</div>
        <img src={AddIcon} onClick={() => changeItemCount("INCREMENT")} />
      </div>
    </CounterWrapper>
  );
};

const RadioInput = ({ label, onChange, value }) => {
  return (
    <RadioWrapper>
      <div>{label}</div>
      <label>
        <Toggle onChange={(e) => onChange(e.target.checked)} checked={value} />
      </label>
    </RadioWrapper>
  );
};

export default connect(
  (state: any) => ({
    tableAreas: state.table.get("tableAreas"),
    selectedArea: state.table.get("selectedArea"),
  }),
  {
    editTables: Actions.table.editTables,
  }
)(TableModal);
