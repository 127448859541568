import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  ServiceContainer,
  ServiceCardContainer,
  ServiceCategoryContainer,
} from "../../../components/Styles/Styled";
import { Actions } from "../../../internal/app/Actions";
import ProductCard from "../../../components/card/ProductCard";
import IconProductPlus from "../../../../assets/svg/IconProductPlus.svg";
import SearchComponent from "../../../components/search/SearchComponent";
import ServiceCategoryCard from "../../../components/card/ServiceCategoryCard";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const AllProductsView = (props) => {
  const {
    shop,
    productList,
    loadingAction,
    searchProduct,
    fetchShopProducts,
    fetchProductTypes,
    productCategoryList,
    selectProductCategory,
    navigateEditProductView,
    selectedProductCategory,
    clearSelectedProductItem,
    stockList,
  } = props;
  const { action, loading } = loadingAction;
  const [searchText, setSearchText] = useState("");
  const [categoryProducts, setCategoryProducts] = useState([]);

  useEffect(() => {
    if (searchText?.length > 0) {
      debouncedSearch(searchText);
    } else {
      // setSearchText("");
      // selectProductCategory({
      //   categoryId: selectedProductCategory?.categoryId,
      //   shopId: shop.shopId,
      // });
    }
  }, [searchText]);

  useEffect(() => {
    if (shop.shopId) {
      selectProductCategory({ categoryId: "ALL", shopId: shop.shopId });
    }
  }, [shop]);

  useEffect(() => {
    if (shop.shopId) {
      fetchProductTypes(shop.shopId);
    }
  }, [shop.shopId, fetchProductTypes]);

  const debouncedSearch = useCallback(
    _.debounce((text) => searchProductForText(text), 600, {
      leading: false,
      trailing: true,
    }),
    [selectedProductCategory, searchText]
  );

  const searchProductForText = (text: string) => {
    if (text && text.length > 0) {
      searchProduct(text, selectedProductCategory.categoryId, shop.shopId);
    } else {
      selectProductCategory({
        categoryId: selectedProductCategory?.categoryId,
        shopId: shop.shopId,
      });
      // fetchShopProducts(shop.shopId);
    }
  };

  useEffect(() => {
    if (selectProductCategory?.categoryId) {
      const categoryProducts = productList.filter(
        ({ categoryId }) => categoryId === selectedProductCategory?.categoryId
      );
      setCategoryProducts(categoryProducts);
    } else {
      setCategoryProducts([]);
    }
  }, [productList, selectedProductCategory]);

  const deleteSearchText = () => {
    setSearchText("");
    fetchShopProducts(shop.shopId, selectedProductCategory.categoryId);
  };

  const onSelectCategory = (data: any) => {
    selectProductCategory(data);
  };

  return (
    <ServiceContainer>
      <SearchComponent
        searchText={searchText}
        title="Search by product name"
        deleteSearchText={() => deleteSearchText()}
        onSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <ServiceCategoryContainer>
        <ServiceCategoryCard
          onSelectCategory={(data: any) => onSelectCategory(data)}
          isSelected={selectedProductCategory.categoryId === "ALL"}
          category={{ category: "ALL", categoryId: "ALL", shopId: shop.shopId }}
        />
        {productCategoryList.map((category) => {
          const isSelected =
            selectedProductCategory.categoryId === category.categoryId;
          return (
            <ServiceCategoryCard
              category={category}
              isSelected={isSelected}
              onSelectCategory={(data) => {
                setSearchText("");
                selectProductCategory(data);
              }}
            />
          );
        })}
      </ServiceCategoryContainer>
      {loading &&
        (action.type === "FETCH_SHOP_PRODUCTS" ||
          action.type === "SEARCH_PRODUCT") && (
          <div className={"productLoading"}>
            <CircularProgress color="primary" size={40} value={5} />
          </div>
        )}
      <ServiceCardContainer>
        <div
          className={"addCategoryBtn"}
          onClick={() => clearSelectedProductItem("/add-product")}
        >
          <img src={IconProductPlus} />
        </div>
        {productList.map((item) => {
          return (
            <ProductCard
              item={item}
              isEdit={true}
              onDelete={() => {}}
              category={selectedProductCategory}
              onSelectServiceItem={(data) => {}}
              onSelectProduct={(data) => navigateEditProductView(data)}
            />
          );
        })}
      </ServiceCardContainer>
    </ServiceContainer>
  );
};
export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    productList: state.product.get("productList"),
    loadingAction: state.common.get("loadingAction"),
    selectedProduct: state.product.get("selectedProduct"),
    productCategoryList: state.product.get("productCategoryList"),
    selectedProductCategory: state.product.get("selectedProductCategory"),
  }),
  {
    searchProduct: Actions.product.searchProduct,
    fetchProductTypes: Actions.product.fetchProductTypes,
    fetchShopProducts: Actions.product.fetchShopProducts,
    selectProductCategory: Actions.product.selectProductCategory,
    navigateEditProductView: Actions.product.navigateEditProductView,
    clearSelectedProductItem: Actions.product.clearSelectedProductItem,
  }
)(AllProductsView);
