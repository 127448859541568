export const mapServiceCalculator = (symbol1, symbol2, number, enteredNumber1, enteredNumber2, servicePrice) => {
  if (!symbol1 || (number === 0 && (enteredNumber1 === '' || (symbol2 === 'X' && enteredNumber2 === '')))) {
    return;
  } else {
    let newNumber1 = enteredNumber1 && !symbol2 ? enteredNumber1 + number.toString() : !symbol2 ? number : enteredNumber1;
    let newNumber2 = enteredNumber2 ? enteredNumber2 + number.toString() : number;
    if (!symbol2) {
      return {enteredNumber1: enteredNumber1 ? enteredNumber1 + number.toString() : number};
    } else {
      return {enteredNumber2: enteredNumber2 ? enteredNumber2 + number.toString() : number};
    }

    if (!symbol2) {
      return {total: symbol1 === "X" ? parseFloat(newNumber1) * servicePrice : parseFloat(newNumber1) + servicePrice};
    } else {
      return {
        total: symbol1 === "X" ?
          parseFloat(newNumber1) * parseFloat(servicePrice) + parseFloat(newNumber2) :
          (parseFloat(newNumber1) + parseFloat(servicePrice)) * parseFloat(newNumber2)
      };
    }
  }
};

export const getServiceCalculatorText = (symbol1, symbol2, enteredNumber1, enteredNumber2, selectedService, selectedServiceItem) => {
  if (symbol2 === 'X') {
    return `( ${selectedService.serviceTitle || selectedServiceItem.serviceTitle} ${symbol1} ${enteredNumber1} ) ${symbol2} ${enteredNumber2}`
  } else {
    return `${selectedService.serviceTitle || selectedServiceItem.serviceTitle} ${symbol1} ${enteredNumber1} ${symbol2} ${enteredNumber2}`
  }
};

export const getProductCalculatorText = (symbol1, symbol2, enteredNumber1, enteredNumber2, selectedItem, selectedProduct) => {
  if (symbol2 === 'X') {
    return `( ${selectedItem.productName || selectedProduct.productName} ${symbol1} ${enteredNumber1} ) ${symbol2} ${enteredNumber2}`
  } else {
    return `${selectedItem.productName || selectedProduct.productName} ${symbol1} ${enteredNumber1} ${symbol2} ${enteredNumber2}`
  }
};
