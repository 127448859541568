import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ProductCard from '../../../components/card/ProductCard';
import {AllServiceContainer, ServiceCardContainer} from '../../../components/Styles/Styled';
import IconProductPlus from '../../../../assets/svg/IconProductPlus.svg';
import {Actions} from "../../../internal/app/Actions";

const FavouriteProductsView = (props) => {
    const {loadingAction, navigateEditProductView, clearSelectedProductItem, favouriteProductList} = props;
    const {action, loading} = loadingAction;

    return (
      <AllServiceContainer categoryHeight={favouriteProductList.length > 5 ? '120px' : '70px'}>
        {/*<SearchComponent*/}
        {/*  searchText={searchText}*/}
        {/*  title="Search by service name"*/}
        {/*  deleteSearchText={() => setSearchText('')}*/}
        {/*  onSearchText={(text) => searchServiceForName(text)}*/}
        {/*/>*/}
        {/*<div className={'categoryContainer'}>*/}
        {/*  {*/}
        {/*    categoryList.map((category) => {*/}
        {/*      const isSelected = selectedCategory.id === category.id;*/}
        {/*      return (*/}
        {/*        <ServiceCategoryCard*/}
        {/*          category={category}*/}
        {/*          isSelected={isSelected}*/}
        {/*          onSelectCategory={(data) => setSelectedCategory(data)}*/}
        {/*        />*/}
        {/*      )*/}
        {/*    })*/}
        {/*  }*/}
        {/*</div>*/}
        {
          loading && (action.type === 'FETCH_FAVOURITE_PRODUCTS' || action.type === 'CREATE_PRODUCT' || action.type === 'UPDATE_PRODUCT') &&
          <div className={'productLoading'}>
            <CircularProgress color="primary" size={60} value={5}/>
          </div>
        }
        <div id={'serviceContainer'}>
          {
            favouriteProductList.map((item) => {
              return (
                <ProductCard
                  item={item}
                  onDelete={() => {}}
                  onSelectServiceItem={(data) => {}}
                  isEdit={true}
                  onSelectProduct={(data) => navigateEditProductView(data)}
                />
              )
            })
          }
          <div className={'addCategoryBtn'} onClick={() => clearSelectedProductItem("/add-product")}>
            <img src={IconProductPlus}/>
          </div>
        </div>
      </AllServiceContainer>
    );
  }
;
export default connect(
  state => ({
    favouriteProductList: state.product.get('favouriteProductList'),
    loadingAction: state.common.get('loadingAction')
  }),
  {
    navigateEditProductView: Actions.product.navigateEditProductView,
    clearSelectedProductItem: Actions.product.clearSelectedProductItem,
  }
)(FavouriteProductsView);

