import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, } from "react-grid-system";
import { Actions } from "../../../internal/app/Actions";
import OfferView from "../../../components/Offer/OfferView";
import Warranty from "../../../components/warranty/Warranty";
import ShopOrderRooms from "../../rooms/views/ShopOrderRooms";
import ShopOrderTables from "../../table/views/ShopOrderTables";
import { ProductContainer, ProductBottomContainer } from "./Styled";
import Products from "../../../components/product/views/Products";
import Customer from "../../../components/customer/views/Customer";
import Services from "../../../components/services/views/Services";
import Discount from "../../../components/discounts/views/Discounts";
import SalonStylists from "../../../components/salonStylists/views/SalonStylists";
import AppointmentDetailsBill from "../../orders/views/AppointmentDetailsBillView";
import DateAndTimeView from "../../../components/dateAndTime/views/DateAndTimeView";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";
import AddAppointmentSideNav from "../../../components/sideNavBars/views/AddAppointmentSideNav";

const ProductView = (props: any) => {
  const [selectedView, setSelectedView] = useState("PRODUCTS");

  const renderMiddleComponent = () => {
    switch (selectedView) {
      case "SERVICES":
        return <Services />;
      case "CUSTOMER":
        return <Customer />;
      case "EMPLOYEES":
        return <SalonStylists />;
      case "DATE_TIME":
        return <DateAndTimeView />;
      case "DISCOUNTS":
        return <Discount />;
      case "TABLE":
        return <ShopOrderTables />;
      case "ROOM":
        return <ShopOrderRooms />;
      case "OFFERS":
        return <OfferView />;
      case "PRODUCTS":
        return <Products />;
      case "WARRANTY":
        return <Warranty />;
      default:
        return <Services />;
    }
  };

  return (
    <ProductContainer>
      <TopNavigationBar viewName={"Add Orders"} path="/menu" />
      <ProductBottomContainer>
          <Row style={{ height: "100%", margin: 0 }} wrap="nowrap">
            <Col style={{ height: "100%", maxWidth: "120px" }} xs={2}>
              <AddAppointmentSideNav
                onClick={(type) => setSelectedView(type)}
                selectedView={selectedView}
              />
            </Col>
            <Col style={{ height: "100%", paddingRight: 0 }} width={'100%'}>
              {renderMiddleComponent()}
            </Col>
            <Col style={{ height: "100%", maxWidth: "320px" }} xs={4}>
              <AppointmentDetailsBill />
            </Col>
          </Row>
      </ProductBottomContainer>
    </ProductContainer>
  );
};
export default connect(
  (state: any) => ({
    currentUser: state.login.get("currentUser"),
    selectedView: state.common.get("selectedView"),
    selectedAppointment: state.orders.get("selectedAppointment"),
  }),
  {
    openSaveModal: Actions.orders.openSaveModal,
  }
)(ProductView);
