import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import styles from "../../../components/Styles/ModelStyles.css";
import {
  ButtonWrapper,
  CounterWrapper,
  InputWrapper,
  RadioWrapper,
  TableModalWrapper,
  TableModeWrapper,
} from "./Styled";
import CANCEL from "../../../../assets/icons/cancel.svg";
import SubIcon from "../../../../assets/svg/IconSub.svg";
import AddIcon from "../../../../assets/svg/IconAddItem.svg";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { Actions } from "../../../internal/app/Actions";
import { nanoid } from "nanoid";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    // overflow: 'auto',
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 265px)",
    // WebkitOverflowScrolling: 'touch',
    border: "1px solid rgba(112, 112, 112, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const TableModal = ({
  isOpen = true,
  closeModal = () => {},
  editRooms,
  roomAreas,
  selectedArea,
  selectedRoom,
}) => {
  const [roomNumber, setRoomNumber] = useState("1");
  const [seats, setSeats] = useState(1);
  const [isActive, setActive] = useState<boolean>(true);
  const [type, setType] = useState<"SQUARE" | "ROUND">("SQUARE");

  const onChangeEvent = (type = "INCREMENT") => {
    if (type === "INCREMENT") {
      setSeats(seats < 50 ? seats + 1 : seats);
    } else if (type === "DECREMENT") {
      setSeats(seats > 0 ? seats - 1 : 0);
    }
  };

  useEffect(() => {
    if (selectedRoom) {
      setRoomNumber(selectedRoom.rmNumber);
      setSeats(selectedRoom.seats ? parseInt(selectedRoom.seats) : 0);
      setActive(selectedRoom.rmType === "ACTIVE");
      setType(selectedRoom.rmShape);
    } else {
      setRoomNumber('');
      setSeats(1);
      setActive(true);
      setType('SQUARE');
    }
  }, [selectedRoom]);

  const onModifyTable = (deleteTable = false) => {
    const index = roomAreas.roomAreas.findIndex(
      (rm) => rm.areaId === selectedArea
    );

    if (index > -1) {
      const rooms = roomAreas.roomAreas[index].rooms;

      if (deleteTable) {
        const filteredRooms = rooms.filter((rm) => rm.rmId !== selectedRoom.rmId);
        roomAreas.roomAreas[index].rooms = filteredRooms;
      } else if (selectedRoom) {
        const idx = rooms.findIndex((rm) => rm.rmId === selectedRoom.rmId);
        const updatedRooms = {
          ...rooms[idx],
          rmNumber: roomNumber,
          rmType: isActive ? "ACTIVE" : "INACTIVE",
          rmShape: type,
        };
        rooms[idx] = updatedRooms;
        roomAreas.roomAreas[index].rooms = rooms;
      } else {
        const updatedRooms = [
          ...rooms,
          {
            rmId: nanoid(),
            rmNumber: roomNumber,
            rmType: isActive ? "ACTIVE" : "INACTIVE",
            rmShape: type,
            seats,
            x: 100,
            y: 100,
            h: 100,
            w: 100,
          },
        ];
        roomAreas.roomAreas[index].rooms = updatedRooms;
      }

      editRooms({ ...roomAreas });
      closeModal();
    } else {
      closeModal();
      console.log("No area");
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.addAppointmentModel}
    >
      <TableModalWrapper>
        <div className="modal-header">
          <div className={styles.addAppointmentHeaderText}>Add Room</div>
          <img alt="icon" src={CANCEL} onClick={closeModal} />
        </div>

        <div className="modal-content">
          <Row>
            <Col>
              <Input
                {...{
                  label: "ROOM NUMBER",
                  value: roomNumber,
                  onChange: setRoomNumber,
                  placeholder: "Room identity",
                }}
              />
            </Col>
            <Col>
              <Counter
                {...{
                  label: "ROOM HEADS",
                  value: seats,
                  changeItemCount: onChangeEvent,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TableModeWrapper type={type}>
                <div>ROOM LAYOUT</div>
                <div>
                  <div onClick={() => setType("SQUARE")}></div>
                  <div onClick={() => setType("ROUND")}></div>
                </div>
              </TableModeWrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              <RadioInput
                label={"ACTIVE ROOM"}
                onChange={setActive}
                value={isActive}
              />
            </Col>
          </Row>
          <ButtonWrapper>
            <button onClick={() => onModifyTable()}>
              <div>{selectedRoom ? "Save" : "Add"} Room</div>
            </button>
            <button onClick={() => closeModal()}>
              <div>Cancel</div>
            </button>
            {selectedRoom ? (
              <button className="delete-button" onClick={() => onModifyTable(true)}>
                <div>Delete</div>
              </button>
            ) : null}
          </ButtonWrapper>
        </div>
      </TableModalWrapper>
    </ReactModal>
  );
};

const Input = ({ label, value, onChange, placeholder }) => {
  return (
    <InputWrapper>
      <div>{label}</div>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </InputWrapper>
  );
};

const Counter = ({ label, changeItemCount, value }) => {
  return (
    <CounterWrapper>
      <div>{label}</div>
      <div>
        <img src={SubIcon} onClick={() => changeItemCount("DECREMENT")} />
        <div>{value}</div>
        <img src={AddIcon} onClick={() => changeItemCount("INCREMENT")} />
      </div>
    </CounterWrapper>
  );
};

const RadioInput = ({ label, onChange, value }) => {
  return (
    <RadioWrapper>
      <div>{label}</div>
      <label>
        <Toggle onChange={(e) => onChange(e.target.checked)} checked={value} />
      </label>
    </RadioWrapper>
  );
};

export default connect(
  (state) => ({
    roomAreas: state.rooms.get("roomAreas"),
    selectedArea: state.rooms.get("selectedArea"),
  }),
  {
    editRooms: Actions.rooms.editRooms,
    saveRooms: Actions.rooms.saveRooms,
  }
)(TableModal);
