import gql from 'graphql-tag';

export const GetShop = gql`
  query getShop($shopId: String!) {
    getShop(
      shopId: $shopId
    ) {
      shopId
      shopName
      email
      phoneNumber
      country
      companyType
      employees
      lat
      lng
      shopStatus
      address
      startTime
      image
      billImage
      createdTime
      updatedTime
      createdBy
      place_id
      claimStatus
      paymentMethod
      shopCategory
      userFeedBack{
        ratingAverage
        ratingCount
      }
      schedule {
        day
        start
        end
        isClosed
      }
      currency
      rating
      reviewCount
      specialization
      salonCategory
      description
      minPrice
      maxPrice
      averagePrice
      salonPriceCount
      pin
      token
      systemSettings {
        key
        value
      }
      receiptEnd
    }
  }
`;

export const UpdateShop = gql`
  mutation UpdateShop($input: UpdateShopInput!) {
    updateShop(
      input: $input
    ) {
      shopId
      shopName
      email
      phoneNumber
      country
      companyType
      employees
      lat
      lng
      shopStatus
      address
      startTime
      image
      createdTime
      updatedTime
      createdBy
      place_id
      claimStatus
      paymentMethod
      shopCategory
      userFeedBack{
        ratingAverage
        ratingCount
      }
      schedule {
        day
        start
        end
        isClosed
      }
      currency
      rating
      reviewCount
      specialization
      salonCategory
      description
      minPrice
      maxPrice
      averagePrice
      salonPriceCount
      pin
      token
      systemSettings {
        key
        value
      }
    }
  }
`;

