import { nanoid } from "nanoid";
import { createAction } from "../../internal/app/AppUtils";
import { mapRooms } from "../../internal/manager/AppointmentManager";
import RoomRepository from "../../internal/repos/RoomRepository";

export const ModuleEvents = {
  EDIT_ROOM_TYPES: "EDIT_ROOM_TYPES",
  DELETE_ROOM: "DELETE_ROOMS",
  CREATE_ROOM_TYPE: "CREATE_ROOM_TYPE",
  FETCH_ROOM_TYPES: "FETCH_ROOM_TYPES",
  SELECT_ROOM_TYPE: "SELECT_ROOM_TYPE",

  EDIT_ROOMS: "EDIT_ROOMS",
  SAVE_ROOMS: "SAVE_ROOMS",
  FETCH_ROOMS: "FETCH_ROOMS",
  SELECT_AREA: "SELECT_AREA",
  SET_ROOM_MODE: "SET_ROOM_MODE",
  CREATE_ROOM_ORDER: "CREATE_ROOM_ORDER",
  SELECT_ROOM: "SELECT_ROOM",
};

const Actions = {
  fetchRoomTypes: createAction(
    ModuleEvents.FETCH_ROOM_TYPES,
    async (shopId) => {
      const response = await RoomRepository.fetchRoomsTypes(shopId);
      return response;
    }
  ),
  editRoomTypes: createAction(
    ModuleEvents.EDIT_ROOM_TYPES,
    async (roomType) => {
      const response = await RoomRepository.createRoomType(roomType);
      return response;
    }
  ),
  deleteRoom: createAction(ModuleEvents.DELETE_ROOM, async (roomType) => {
    const response = await RoomRepository.deleteRoomType(roomType);
    return response;
  }),
  selectRoomType: createAction(
    ModuleEvents.SELECT_ROOM_TYPE,
    async (roomType) => {
      return roomType;
    }
  ),
  createRoomType: createAction(
    ModuleEvents.CREATE_ROOM_TYPE,
    async (roomType) => {
      const response = await RoomRepository.createRoomType(roomType);
      return response;
    }
  ),
  fetchRooms: createAction(ModuleEvents.FETCH_ROOMS, async (shopId) => {
    const tables = await RoomRepository.fetchRooms(shopId);

    if (!tables) {
      return {
        roomAreas: [
          {
            areaName: "First Floor",
            areaId: nanoid(),
            rooms: [],
          },
        ],
      };
    }
    return tables;
  }),
  selectArea: createAction(ModuleEvents.SELECT_AREA, async (area) => {
    return area;
  }),

  editRooms: createAction(ModuleEvents.EDIT_ROOMS, async (area) => {
    return area;
  }),

  setRoomMode: createAction(ModuleEvents.SET_ROOM_MODE, async (mode) => {
    return mode;
  }),
  createRoomOrder: createAction(
    ModuleEvents.CREATE_ROOM_ORDER,
    async (room) => {
      return room;
    }
  ),
  selectRoom: createAction(ModuleEvents.SELECT_ROOM, async (room: any) => {
    return room;
  }),

  saveRooms: createAction(
    ModuleEvents.SAVE_ROOMS,
    async (rooms, isEdit = false) => {
      const roomAreas = rooms.roomAreas.map((rm) => {
        rm.rooms = mapRooms(rm.rooms);
        return rm;
      });
      rooms.roomAreas = roomAreas;
      if (isEdit) {
        const tablesResponse = await RoomRepository.updateRooms(rooms);
        return tablesResponse;
      }
      const tablesResponse = await RoomRepository.createRooms(rooms);
      return tablesResponse;
    }
  ),
};

export default Actions;
