import ServiceRepository from '../../internal/repos/ServiceRepository';
import ProductRepository from "../../internal/repos/ProductRepository";
import StockRepository from "../../internal/repos/StockRepository";

export const ModuleEvents = {
  CREATE_STOCK_RECORD: 'CREATE_STOCK_RECORD',
  FETCH_STOCK_RECORD: 'FETCH_STOCK_RECORD',
  NAVIGATE_EDIT_STOCK_RECORD: 'NAVIGATE_EDIT_STOCK_RECORD',
  UPDATE_STOCK_RECORD: 'UPDATE_STOCK_RECORD',
  CLEAR_SELECTED_STOCK_RECORD: 'CLEAR_SELECTED_STOCK_RECORD',
  DELETE_STOCK_RECORD: 'DELETE_STOCK_RECORD'
};

const createAction:any = (type, action:any = () => {}, meta) => {
  return (...args) => ({ type, payload: action(...args), meta });
};

export default {
  createStockRecord: createAction(ModuleEvents.CREATE_STOCK_RECORD, async (param) => {
    const result = await StockRepository.createStockRecord(param);
    return result;
  }, {loading: true}),
  updateStockRecord: createAction(ModuleEvents.UPDATE_STOCK_RECORD, async (param) => {
    const result = await StockRepository.updateStockRecord(param);
    return result;
  }, {loading: true}),
  fetchStockRecord: createAction(ModuleEvents.FETCH_STOCK_RECORD, async (shopId) => {
    const result = await StockRepository.fetchStockRecords(shopId);
    return result;
  }, {loading: true}),
  navigateToEditStockRecordView: createAction(ModuleEvents.NAVIGATE_EDIT_STOCK_RECORD, async (item) => {
    return item;
  }),
  clearSelectedStockRecord: createAction(ModuleEvents.CLEAR_SELECTED_STOCK_RECORD, async () => {
    return true;
  }),
  deleteStockRecord: createAction(ModuleEvents.DELETE_STOCK_RECORD, async (param) => {
    const result = await StockRepository.deleteStockRecord(param);
    return result;
  }, {loading: true}),
}
