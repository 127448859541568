import React, { useEffect, useState } from "react";
import {
  SelectOptionButton,
  SelectOptionItemWrapper,
  SelectOptionModalWrapper,
  SelectOptionPriceWrapper,
  SelectOptionCreateButton,
} from "../../Styles/Styled";
import { nanoid } from "nanoid";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import "../../Styles/ModelStyles.css";
import styles from "../../Styles/ModelStyles.css";
import { Actions } from "../../../internal/app/Actions";
import CANCEL from "../../../../assets/icons/cancel.svg";
import _, { get, isEqual, isEmpty, compact, findIndex } from "lodash";
import { getCurrencyWithPrice } from "../../../internal/manager/PaymentManager";
import { getInitialModifiers } from "../../../internal/manager/ProductManager";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 266px)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const SelectOptionModal = (props: any) => {
  const {
    shop,
    isOpen,
    closeModal,
    onBackFromOption,
    onOpenModifier,
    selectedProduct,
    updateStockCount,
    purchaseProducts,
    setPurchaseProducts,
    changeProductQuantity,
    setPurchaseProductItem,
  } = props;
  const [priceVariant, setPriceVariant] = useState<any>(null);
  const [enabledPrices, setEnabledPrices] = useState<Array<any>>([]);
  const [selectedSetOptions, setSelectedSetOptions] = useState<any>({});

  useEffect(() => {
    return () => {
      setEnabledPrices([]);
      setPriceVariant(null);
      setSelectedSetOptions({});
    };
  }, [isOpen]);

  useEffect(() => {
    if (isEmpty(enabledPrices)) {
      setEnabledPrices(selectedProduct.priceVariants);
    }
  }, [enabledPrices]);

  useEffect(() => {
    if (isEmpty(enabledPrices)) {
      setEnabledPrices(selectedProduct.priceVariants);
    }
  }, [enabledPrices]);

  useEffect(() => {
    if (!isEmpty(selectedProduct)) {
      setEnabledPrices(selectedProduct.priceVariants);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (!isEmpty(selectedSetOptions)) {
      const sortedOptions = Object.values(selectedSetOptions)
        .map(({ id }: any) => id)
        .sort();
      enabledPrices?.forEach((prices: { options: Array<any> }) => {
        prices?.options.sort();
        if (isEqual(prices.options, sortedOptions)) {
          setPriceVariant(prices);
        }
      });
    } else {
      setPriceVariant(null);
    }
  }, [selectedSetOptions, enabledPrices]);

  const onClickOptions = (option: any, data: any) => {
    setSelectedSetOptions({ ...selectedSetOptions, ...{ [data.id]: option } });
  };

  const setInitialState = () => {
    setEnabledPrices([]);
    setSelectedSetOptions({});
  };

  const onSelectOption = () => {
    const price = get(priceVariant, "price", "");
    const id = get(priceVariant, "sku", nanoid(6));
    const initialValue = getInitialModifiers(selectedProduct);

    const param: any = {
      tax: 0,
      opId: id,
      quantity: 1,
      type: "PRODUCT",
      id: selectedProduct.pid,
      modifiers: initialValue,
      price: parseFloat(price),
      image: selectedProduct.image,
      priceVariants: [priceVariant],
      productPrice: parseFloat(price),
      productSalePrice: parseFloat(price),
      sendToBar: !!selectedProduct.sendToBar,
      productName: selectedProduct.productName,
      warranty: get(selectedProduct, "warranty", {}),
      sendToKitchen: !!selectedProduct.sendToKitchen,
      sendPrepTicket: selectedProduct.sendPrepTicket,
    };
    const optionSelected = param.priceVariants[0].options.sort();
    const isSelected =
      findIndex(purchaseProducts, (product: any) => {
        if (product.opId === id) {
          if (product.priceVariants) {
            return (
              product.priceVariants.findIndex(({ options }) =>
                isEqual(optionSelected, options)
              ) > -1
            );
          }
        }
        return false;
      }) > -1;
    setPurchaseProductItem(param);

    if (!isEmpty(initialValue)) {
      onOpenModifier();
    } else {
      if (isSelected) {
        changeProductQuantity(param, "INCREMENT");
      } else {
        updateStockCount(param);
        setPurchaseProducts([param]);
      }
    }
    closeModal();
    setInitialState();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.selectOptionModal}
    >
      <SelectOptionModalWrapper>
        <div onClick={() => onBackFromOption()}>
          <div>Select Option</div>
          <img src={CANCEL} />
        </div>
        {!isEmpty(selectedProduct) && (
          <div>
            {selectedProduct?.productOptions?.map(
              (data: any, index: number) => {
                return (
                  <SelectOptionItemWrapper>
                    <div>{data.setDisplayName}</div>
                    <div>
                      {data?.options.map((option: any) => {
                        const isSelected =
                          selectedSetOptions[data.id]?.id === option?.id;

                        const newValues = enabledPrices?.map((item: any) => {
                          const isHave = item.options.some(
                            (someId: string) => someId === option.id
                          );
                          if (isHave) {
                            return true;
                          }
                        });
                        const isDisabled = isEmpty(compact(newValues));
                        return (
                          <SelectOptionButton
                            disabled={isDisabled}
                            isDisabled={isDisabled}
                            isSelected={isSelected}
                            onClick={() => onClickOptions(option, data)}
                          >
                            {option.optionName}
                          </SelectOptionButton>
                        );
                      })}
                    </div>
                  </SelectOptionItemWrapper>
                );
              }
            )}
            <SelectOptionPriceWrapper>
              <p>
                Price:{" "}
                <strong>
                  {priceVariant
                    ? getCurrencyWithPrice(
                        get(priceVariant, "price", "-"),
                        shop.currency
                      )
                    : "-"}
                </strong>
              </p>
              <div>
                <SelectOptionCreateButton
                  isFill={false}
                  onClick={() => onBackFromOption()}
                >
                  Cancel
                </SelectOptionCreateButton>
                <SelectOptionCreateButton
                  disabled={!priceVariant}
                  isFill={!!priceVariant}
                  onClick={() => onSelectOption()}
                >
                  Select Option
                </SelectOptionCreateButton>
              </div>
            </SelectOptionPriceWrapper>
          </div>
        )}
      </SelectOptionModalWrapper>
    </ReactModal>
  );
};
export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
    categories: state.service.get("categories"),
    productList: state.product.get("productList"),
    purchaseProducts: state.product.get("purchaseProducts"),
    selectedProduct: state.product.get("choossedProductItem"),
  }),
  {
    setPurchaseService: Actions.service.setPurchaseService,
    setPurchaseProducts: Actions.product.setPurchaseProducts,
    deletePurchaseService: Actions.service.deletePurchaseService,
    deletePurchaseProduct: Actions.product.deletePurchaseProduct,
    changeProductQuantity: Actions.product.changeProductQuantity,
    updateCurrentStockCount: Actions.product.updateCurrentStockCount,
    setPurchaseProductItem: Actions.product.setPurchaseProductItem,
  }
)(SelectOptionModal);
