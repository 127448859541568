export default {
  white: '#FFFFFF',
  black: '#000000',
  black85: '#00000085',
  cornflowerBlue: '#49A4D4',
  ceruleanBlue: '#3446AC',
  mediumSeaGreen: '#39B97D',
  RedOrange: '#FF2626',
  goldenPoppy: '#FFC400',
  aquaHaze: '#EFF3F6',
  shakespeare: '#49A4D4',
  wildSand: '#f5f5f5',
  silver: '#cccccc',
  bittersweet: '#FF5F5F',
  cosmos: '#FFD6D6',
  outerSpace: '#353C42',
  outerSpace70: '#353C4270',
  regentGray: '#8A9CA5',
  oceanGreen: '#39B97D',
  alabaster: '#FAFAFA',
  gunsmoke: '#88938F',
  mischka: '#CCCFDB',
  pictonBlue: '#49A4D4',
  balticSea: '#353c42',
  monaLisa: '#FF9696',
  nero: '#1F1F1F',
  midnightExpress06: '#20253A06',
  charcoal: '#434343',
  baliHai: '#8A9CA5',
  baliHai25: '#8A9CA525',
  nobel: '#979797',

  tableNav: '#CCD4D8',
  tableFree: '#E4E8EA',
  whisper: '#EAEAEA',
};
