import React, { useState, useEffect, useRef } from "react";
import {
  ReportTableText,
  ServiceContainer,
  CloseCashContainer,
  ReportTableContainer,
  CloseCashHeaderFilter,
  ReportTableLineContainer,
  CloseCashBottomContainer,
  ReportTableValueContainer,
  CloseCashHeaderFilterImage,
} from "./Styled";
import moment from "moment";
import { nanoid } from "nanoid";
import _, { isEmpty } from "lodash";
import { connect } from "react-redux";
import { Row, Col } from "react-grid-system";
import CloseCashDetails from "./CloseCashDetails";
import { Actions } from "../../../internal/app/Actions";
import IconDots from "../../../../assets/svg/IconDots.svg";
import styles from "../../../components/Common/Style/Styles.css";
import IconFilter from "../../../../assets/svg/IconCloshcashFilter.svg";
import PrinterRepository from "../../../internal/repos/PrinterRepository";
import IconPrinter from "../../../../assets/svg/IconCloshcashPrinter.svg";
import IconCalendar from "../../../../assets/svg/IconCloshcashCalendar.svg";
import CashInOutModal from "../../../components/model/views/CashInOutModal";
import { getSalesSummary } from "../../../internal/manager/CloseCashManager";
import ServiceSummary from "../../../components/closeCash/views/ServiceSummary";
import FilterComponent from "../../../components/closeCash/views/FilterComponent";
import CloseCashSummary from "../../../components/closeCash/views/CloseCashSummary";
import IconSelectFilter from "../../../../assets/svg/IconCloshcashSelectFilter.svg";
import GenerateReportModal from "../../../components/model/views/GenerateReportModal";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";
import CloseCashSummaryValues from "../../../components/closeCash/views/CloseCashSummaryValues";

const filterOptions = [
  {
    label: "Sales",
    value: "SALES",
  },
  {
    label: "Reports",
    value: "REPORTS",
  },
  // {
  //   label: "Close Cash",
  //   value: "CLOSE_CASH",
  // },
];

const serviceOptions = [
  {
    label: "Sales",
    value: "SALES",
  },
  {
    label: "Cash In/Out",
    value: "CASH",
  },
  {
    label: "Service Summary",
    value: "SERVICE",
  },
];

const CloseCash = (props) => {
  const {
    shopData,
    currentUser,
    getBatchSummary,
    batchSession,
    createCashOperation,
    completedCashAppointments,
    batchSummary,
    pastBatchSummary,
    fetchOrderSummary,
    updateCashOperation,
    pastBatchSummaryLastKey,
    paginatePastBatchSummary,
  } = props;
  const cashInnerRef = useRef<any>();

  const [serviceType, setServiceType] = useState(serviceOptions[2].value);
  const [visibleCashType, setVisibleCashType] = useState("");
  const [visibleCashIn, setVisibleCashIn] = useState(false);
  const [visibleCashOut, setVisibleCashOut] = useState(false);
  const [visibleReport, setVisibleReport] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [visibleServiceFilter, setVisibleServiceFilter] = useState(false);
  const [filterType, setFilterType] = useState("SALES");
  const [selectedCashData, setSelectedCashData] = useState({});
  const [selectedBatchSummary, setSelectedBatchSummary] = useState(
    _.get(pastBatchSummary, "[0].batchSummary", {})
  );

  useEffect(() => {
    if (filterType === "REPORTS" && selectedBatchSummary?.batchId) {
      fetchOrderSummary(shopData.shopId, selectedBatchSummary.batchId);
    } else if (batchSession?.batchId) {
      fetchOrderSummary(shopData.shopId, batchSession.batchId);
    }
  }, [
    selectedBatchSummary,
    shopData,
    fetchOrderSummary,
    visibleReport,
    batchSession.batchId,
  ]);

  useEffect(() => {
    if (!_.isEmpty(batchSession)) {
      getBatchSummary(batchSession.batchId);
    }
  }, [navigator]);

  const onClickFilter = () => {
    setVisibleFilter(!visibleFilter);
  };

  const onClickPrinter = () => {
    let element = document
      .getElementById("close-cash-summary")
      ?.cloneNode(true) as HTMLElement;
    if (element) {
      try {
        if (element?.style) {
          element.style.width = "76mm";
        }
        PrinterRepository.printPOS(element?.outerHTML || "");
      } catch (e) {
        console.log("Printing error");
      }
    }
  };

  const onClickCalendar = () => {};

  const onClickCashIn = () => {
    setVisibleCashType("CASH_IN");
    setVisibleCashIn(!visibleCashIn);
  };

  const onClickCashOut = () => {
    setVisibleCashType("CASH_OUT");
    setVisibleCashOut(!visibleCashOut);
  };

  const onClickReport = () => {
    setVisibleReport(!visibleReport);
  };

  const getServiceType = (type) => {
    switch (type) {
      case "SERVICE":
        return "Service Summary";
      case "CASH":
        return "Cash In/Out";
      case "SALES":
        return "Sales";
      case "INCOME":
        return "Income";
      default:
        return "Service Summary";
    }
  };

  const onToggleVisibleCash = () => {
    if (visibleCashIn) {
      onClickCashIn();
    } else if (visibleCashOut) {
      onClickCashOut();
    }
  };

  const onCreateCash = (data) => {
    if (data.event === "DRAWER") {
    }
    const params = {
      batchId: batchSession.batchId,
      cashOperation: {
        id: nanoid(),
        type: visibleCashType,
        reason: data.reason,
        amount: data.cash,
        createdTime: Date.now(),
      },
    };
    if (_.isEmpty(selectedCashData)) {
      createCashOperation(params);
    } else {
      updateCashOperation({ params, selectedCashData, isUpdate: true });
    }
    setSelectedCashData({});
    onToggleVisibleCash();
  };

  const onToggleService = () => {
    setVisibleServiceFilter(!visibleServiceFilter);
  };

  const onClickServiceFilter = (item) => {
    setServiceType(item.value);
    onToggleService();
  };

  const onClickMainFilter = (item) => {
    setFilterType(item.value);
    onClickFilter();
  };

  const onDeleteCash = () => {
    const params = {
      batchId: batchSession.batchId,
    };
    updateCashOperation({ params, selectedCashData });
    setSelectedCashData({});
    onToggleVisibleCash();
  };
  const onScrollCash = () => {
    if (cashInnerRef && cashInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = cashInnerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        !isEmpty(pastBatchSummaryLastKey)
      ) {
        paginatePastBatchSummary(
          shopData.shopId,
          currentUser.empId,
          10,
          pastBatchSummaryLastKey
        );
      }
    }
  };

  const renderFilterView = (type) => {
    switch (type) {
      case "SALES":
        return (
          <>
            <Col style={{ padding: 0, height: "calc(100vh - 132px)" }} xs={8}>
              <CloseCashSummary />
              <CloseCashSummaryValues onClickReport={() => onClickReport()} />
              <ServiceContainer>
                <div>{getServiceType(serviceType)}</div>
                <img
                  className={styles.closeCashHeaderIcon}
                  onClick={() => onToggleService()}
                  src={IconDots}
                />
              </ServiceContainer>
              <FilterComponent
                options={serviceOptions}
                paddingRight={"33px"}
                visible={visibleServiceFilter}
                onClick={(item) => onClickServiceFilter(item)}
              />

              <ServiceSummary
                shopData={shopData}
                serviceType={serviceType}
                appointments={completedCashAppointments}
                batchSummary={batchSummary}
                onClickCashIn={(param) => {
                  setSelectedCashData(param);
                  onClickCashIn();
                }}
                onClickCashOut={() => onClickCashOut()}
              />
            </Col>
            <Col
              xs={0.1}
              style={{
                width: "100%",
                padding: 0,
                height: "calc(100vh - 142px)",
              }}
            >
              <div className={styles.line} />
            </Col>
            <Col
              xs={3.9}
              style={{
                width: "100%",
                paddingLeft: 0,
                height: "calc(100vh - 132px)",
              }}
            >
              <CloseCashDetails type={filterType} batchSummary={batchSummary} />
            </Col>
          </>
        );
      case "REPORTS":
        return (
          <>
            <Col style={{ padding: 0, height: "calc(100vh - 132px)" }} xs={8}>
              <ReportTableContainer>
                <Row style={{ width: "100%" }}>
                  <Col md={3}>
                    <ReportTableText>Start Time</ReportTableText>
                  </Col>
                  <Col md={3}>
                    <ReportTableText>End Time</ReportTableText>
                  </Col>
                  <Col md={2}>
                    <ReportTableText>Cash Sales</ReportTableText>
                  </Col>
                  <Col md={2}>
                    <ReportTableText>Card Sales</ReportTableText>
                  </Col>
                  <Col md={2}>
                    <ReportTableText>Total</ReportTableText>
                  </Col>
                </Row>
              </ReportTableContainer>
              <div
                ref={cashInnerRef}
                onScroll={() => onScrollCash()}
                style={{
                  padding: 0,
                  height: "calc(100vh - 200px)",
                  overflowY: "scroll",
                }}
              >
                {!isEmpty(pastBatchSummary)
                  ? pastBatchSummary?.map((data, index) => {
                      const startDate = moment(data.createdTime).format(
                        "dddd DD, YYYY hh:mm A"
                      );
                      const endDate = moment(data.updatedTime).format(
                        "dddd DD, YYYY hh:mm A"
                      );
                      const summary = getSalesSummary(
                        data.batchSummary,
                        shopData
                      );
                      const isSelected =
                        data.batchId === selectedBatchSummary.batchId;
                      return (
                        <>
                          <ReportTableValueContainer
                            isSelected={isSelected}
                            onClick={() => setSelectedBatchSummary(data)}
                          >
                            <Row style={{ width: "100%" }}>
                              <Col md={3}>
                                <ReportTableText>{startDate}</ReportTableText>
                              </Col>
                              <Col md={3}>
                                <ReportTableText>{endDate}</ReportTableText>
                              </Col>
                              <Col md={2}>
                                <ReportTableText>
                                  {summary.cashSales}
                                </ReportTableText>
                              </Col>
                              <Col md={2}>
                                <ReportTableText>
                                  {summary.cardSales}
                                </ReportTableText>
                              </Col>
                              <Col md={2}>
                                <ReportTableText>
                                  {summary.totalAmount}
                                </ReportTableText>
                              </Col>
                            </Row>
                          </ReportTableValueContainer>
                          <ReportTableLineContainer />
                        </>
                      );
                    })
                  : null}
              </div>
            </Col>
            <Col
              xs={0.1}
              style={{
                width: "100%",
                padding: 0,
                height: "calc(100vh - 142px)",
              }}
            >
              <div className={styles.line} />
            </Col>
            <Col
              xs={3.9}
              style={{
                width: "100%",
                paddingLeft: 0,
                height: "calc(100vh - 132px)",
              }}
            >
              <CloseCashDetails
                batchSummary={selectedBatchSummary.batchSummary}
                type={filterType}
              />
            </Col>
          </>
        );
      case "CLOSE_CASH":
      default:
        return null;
    }
  };

  const getHeaderValue = (type) => {
    switch (type) {
      case "REPORTS":
        return "Reports";
      // case "CLOSE_CASH":
      //   return "Close cash";
      case "SALES":
      default:
        return "Sales";
    }
  };

  return (
    <CloseCashContainer>
      <TopNavigationBar viewName="CloseCash" path="/menu" />
      <CloseCashBottomContainer>
        <div>
          <CloseCashHeaderFilter>
            <div className={"closeCashFilterHeaderText"}>
              {getHeaderValue(filterType)}
            </div>
            <div className={"closeCashFilterHeaderIconWrapper"}>
              <CloseCashHeaderFilterImage
                style={{
                  backgroundColor: visibleFilter ? "#49A4D4" : "#FAFAFA",
                }}
                onClick={() => onClickFilter()}
                src={visibleFilter ? IconSelectFilter : IconFilter}
              />
              <CloseCashHeaderFilterImage
                onClick={() => onClickPrinter()}
                src={IconPrinter}
              />
              <CloseCashHeaderFilterImage
                onClick={() => onClickCalendar()}
                src={IconCalendar}
              />
            </div>
          </CloseCashHeaderFilter>
          <FilterComponent
            selected={filterType}
            paddingRight={"140px"}
            options={filterOptions}
            visible={visibleFilter}
            onClick={(item) => onClickMainFilter(item)}
          />
          <Row style={{ margin: 0 }}>{renderFilterView(filterType)}</Row>
        </div>
      </CloseCashBottomContainer>
      {visibleCashIn || visibleCashOut ? (
        <CashInOutModal
          type={visibleCashType}
          isOpen={visibleCashIn || visibleCashOut}
          closeModal={() => {
            onToggleVisibleCash();
            setSelectedCashData({});
          }}
          onCreateCash={(data) => onCreateCash(data)}
          onDeleteCash={() => onDeleteCash()}
          selectedCashData={selectedCashData}
        />
      ) : null}
      {visibleReport ? (
        <GenerateReportModal
          shopData={shopData}
          isOpen={visibleReport}
          appointment={completedCashAppointments}
          closeModal={() => onClickReport()}
        />
      ) : null}
    </CloseCashContainer>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
    batchSession: state.login.get("batchSession"),
    batchSummary: state.closeCash.get("batchSummary"),
    selectedSalon: state.orders.get("selectedSalon"),
    pastBatchSummary: state.closeCash.get("pastBatchSummary"),
    pastBatchSummaryLastKey: state.closeCash.get("pastBatchSummaryLastKey"),
    completedCashAppointments: state.closeCash.get("completedCashAppointments"),
  }),
  {
    getBatchSummary: Actions.closeCash.getBatchSummary,
    fetchOrderSummary: Actions.closeCash.fetchOrderSummary,
    updateCashOperation: Actions.closeCash.updateCashOperation,
    createCashOperation: Actions.closeCash.createCashOperation,
    fetchPaginateAppointment: Actions.orders.fetchPaginateAppointment,
    fetchEmployeesForSalonId: Actions.employee.fetchEmployeesForSalonId,
    paginatePastBatchSummary: Actions.closeCash.paginatePastBatchSummary,
  }
)(CloseCash);
