import React from 'react';
import { List, Map } from 'immutable';
import { ModuleEvents } from './Actions';
import { isArray, split } from 'lodash';

const initialState = Map({
  splitPayments: [],
});

export const Reducer = (state = initialState, action) => {
  const {payload, type, error} = action;

  switch (action.type) {
    case ModuleEvents.ADD_SPLIT_PAYMENT: {
      if (payload) {
        const splits: Array<any> = state.get('splitPayments');
        splits.push(payload);
        return state.set('splitPayments', [...splits])
      }
      return state;
    }
    case ModuleEvents.UPDATE_SPLIT_PAYMENT: {
      if (payload && isArray(payload)) {
        return state.set('splitPayments', [...payload])
      }
      return state;
    }
    default:
      return state;
  }

};

export default Reducer;
