import React, { useState } from "react";
import _ from "lodash";
import { nanoid } from "nanoid";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { PaymentAmounts } from "./styled";
import {
  SplitBillWrapper,
  PaymentCardCashWrapper,
  PaymentDetailsContainer,
  PaymentDetailsHeaderContainer,
  PaymentDetailsBottomContainer,
  PaymentDetailsCardCashContainer,
  PaymentDetailsTextMessageWrapper,
  PaymentDetailsBottomCashCardButton,
} from "../../../components/Styles/Styled";
import {
  getTotalPrice,
  getCashPaymentValues,
  getCurrencyWithPrice,
  getAppointmentTotalPrice,
  mapPaymentDetails,
} from "../../../internal/manager/PaymentManager";
import { Actions } from "../../../internal/app/Actions";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import cardIcon from "../../../../assets/icons/cardIcon.svg";
import cashIcon from "../../../../assets/icons/cashIcon.svg";
import PaymentSplitIcon from "../../../../assets/svg/PaymentSplit.svg";
import CashComponent from "../../../components/bookings/CashComponent";
import CardComponent from "../../../components/bookings/CardComponent";
import { navigate } from "../../../internal/service/Navigation.service";
import PrinterRepository from "../../../internal/repos/PrinterRepository";
import IconBlueCheck from "../../../../assets/svg/IconRegisterSelect.svg";
import IconRegisterUnselect from "../../../../assets/svg/IconRegisterUnselect.svg";

const PaymentDetailsView = (props) => {
  const {
    shopData,
    checkout,
    orderOffers,
    currentUser,
    batchSession,
    selectedTable,
    quickCustomer,
    loadingAction,
    selectedVehicle,
    selectedEmployee,
    selectedCustomer,
    purchaseServices,
    purchaseProducts,
    createActivityLog,
    selectedAppointment,
    updateSplitPayments,
    appointmentWarranty,
    appointmentDiscounts,
    appointmentDateAndTime,
  } = props;
  const { formatMessage: f } = useIntl();
  const [isCash, setIsCash] = useState(true);
  const [isCard, setIsCard] = useState(false);
  const [givenPrice, setGivenPrice] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [balancePrice, setBalancePrice] = useState(0);
  const [isPrintReciept, setPrintReciept] = useState(true);
  const [cardHolderName, setCardHolderName] = useState("");
  const [isClickSendSms, setIsClickSendSms] = useState(false);
  const [isClickComplete, setIsClickComplete] = useState(false);
  const [validCardNumber, setValidCardNumber] = useState(false);

  const balance = (price: any, totalPrice: any) => {
    setGivenPrice(price);
    setBalancePrice(price - totalPrice);
  };

  const printBill = async (total, params) => {
    const subTotal = getTotalPrice([...purchaseServices, ...purchaseProducts]);
    // const imageData = await axios.get(generateImageUrl(shopData.image), {responseType: 'arraybuffer'});
    // let image = null;
    // if (imageData.data) {
    //   image = 'data:image/png;base64,' + Buffer.from(imageData.data, 'binary').toString('base64');
    // }
    try {
      await PrinterRepository.printReciept({
        params,
        // image,
        selectedVehicle,
        selectedCustomer,
        purchaseProducts,
        purchaseServices,
        givenPrice,
        shopData,
        total,
        subTotal,
        appointmentDiscounts,
      });
    } catch (e) {
      // Error message
      console.warn({ e });
    }
  };

  const validateButton = () => {
    if (isCard) {
      return true;
    }
    return (
      parseFloat(givenPrice) >= totalPrice &&
      totalPrice > 0 &&
      (isCard
        ? validCardNumber && cardHolderName.length > 2
        : balancePrice >= 0)
    );
  };

  const onCheckout = async (totalPrice, serviceCharge) => {
    if (validateButton() && !loadingAction?.loading) {
      setIsClickComplete(true);
      const params = {
        shopData,
        totalPrice,
        selectedVehicle,
        purchaseServices,
        selectedEmployee,
        purchaseProducts,
        status: "COMPLETED",
        selectedAppointment,
        isSendSms: isClickSendSms,
        offerDetails: orderOffers,
        userId: currentUser.empId,
        batchId: batchSession.batchId,
        warranty: appointmentWarranty,
        discounts: appointmentDiscounts,
        paidPrice: isCash ? givenPrice : 0,
        paymentType: isCash ? "CASH" : "CARD",
        date: _.get(appointmentDateAndTime, "date"),
        time: _.get(appointmentDateAndTime, "time"),
        recieptId: selectedAppointment.recieptId || nanoid(5),
        cardDetails: { last4: cardNumber, name: cardHolderName },
        serviceCharge: {
          amount: `${serviceCharge}`,
        },
        tableOrder: selectedTable?.tbId
          ? {
              tbId: selectedTable.tbId,
              areaId: null,
              tbNumber: selectedTable.tbNumber,
            }
          : null,
        selectedCustomer: !_.isEmpty(selectedCustomer)
          ? selectedCustomer
          : quickCustomer,
      };

      const orderData = await mapPaymentDetails(params);
      const activityLog = {
        logId: nanoid(),
        actType: "CHECKOUT",
        shopId: shopData.shopId,
        createdTime: Date.now(),
        updatedTime: Date.now(),
        empId: selectedEmployee.empId,
        metaData: [
          {
            key: "ORDER",
            value: orderData,
          },
          {
            key: "EMPLOYEE",
            value: selectedEmployee,
          },
        ],
      };
      createActivityLog(activityLog);
      checkout(params);
      if (isPrintReciept) {
        printBill(totalPrice, params);
      }
    }
  };

  const toggleCash = () => {
    setIsCash(!isCash);
    setIsCard(false);
  };

  const toggleCard = () => {
    setIsCard(!isCard);
    setIsCash(false);
  };

  const onClickSendSms = () => {
    setIsClickSendSms(!isClickSendSms);
  };

  let purchaseList = purchaseServices.concat(purchaseProducts);
  let { total: totalPrice, serviceCharge } = getAppointmentTotalPrice(
    purchaseList,
    appointmentDiscounts,
    shopData,
    orderOffers
  );
  let price = getCurrencyWithPrice(totalPrice, shopData.currency);
  let isValid = validateButton();

  const cashValues = getCashPaymentValues(totalPrice);

  return (
    <PaymentDetailsContainer>
      <PaymentDetailsHeaderContainer>
        <div className={"paymentDetailsHeaderPriceText"}>{price}</div>
        <div className={"paymentDetailsCardCashScroll"}>
          <PaymentCardCashWrapper>
            <PaymentDetailsCardCashContainer
              isSelect={isCash}
              onClick={() => toggleCash()}
            >
              <div className={"paymentDetailsCardCashImage"}>
                <img src={cashIcon} />
                <div>{f({ id: "CHECKOUT.CASH" })}</div>
              </div>
              {/* <div className={"paymentDetailsCardCashPrice"}>{price}</div> */}
            </PaymentDetailsCardCashContainer>

            <PaymentDetailsCardCashContainer
              isSelect={isCard}
              onClick={() => toggleCard()}
            >
              <div className={"paymentDetailsCardCashImage"}>
                <img src={cardIcon} />
                <div>{f({ id: "CHECKOUT.CARD" })}</div>
              </div>
              {/* <div className={"paymentDetailsCardCashPrice"}>{price}</div> */}
            </PaymentDetailsCardCashContainer>
          </PaymentCardCashWrapper>
          <div className={"paymentDetailsCardCashBottomContainer"}>
            {isCard ? (
              <CardComponent
                isClickComplete={isClickComplete}
                onChangeNumber={(number) => {
                  setCardNumber(number);
                  setValidCardNumber(number.length === 4);
                }}
                onChangeName={(text) => setCardHolderName(text)}
              />
            ) : isCash ? (
              <CashComponent
                totalPrice={totalPrice}
                balancePrice={balancePrice}
                givenPrice={givenPrice}
                onChangePrice={(price) => balance(price, totalPrice)}
              />
            ) : null}
          </div>
          {isCash ? (
            <PaymentAmounts>
              {cashValues.map((value) => (
                <div onClick={() => balance(value, totalPrice)}>
                  {getCurrencyWithPrice(value, shopData.currency, false)}
                </div>
              ))}
              <div onClick={() => balance(totalPrice, totalPrice)}>Exact</div>
            </PaymentAmounts>
          ) : null}

          <PaymentDetailsTextMessageWrapper>
            <Checkbox
              checked={isClickSendSms}
              checkedIcon={<img src={IconBlueCheck} />}
              icon={<img src={IconRegisterUnselect} />}
              onChange={() => onClickSendSms()}
            />
            <div>{f({ id: "CHECKOUT.SEND_INVOICE" })}</div>
          </PaymentDetailsTextMessageWrapper>
          <PaymentDetailsTextMessageWrapper>
            <Checkbox
              checked={isPrintReciept}
              checkedIcon={<img src={IconBlueCheck} alt="" />}
              icon={<img src={IconRegisterUnselect} alt="" />}
              onChange={() => setPrintReciept(!isPrintReciept)}
            />
            <div>{f({ id: "CHECKOUT.PRINT_RECIEPT" })}</div>
          </PaymentDetailsTextMessageWrapper>

          <SplitBillWrapper
            isSelect={false}
            onClick={() => {
              updateSplitPayments([]);
              navigate("/split-bill");
            }}
          >
            <img src={PaymentSplitIcon} />
            <div>{f({ id: "SPLIT_BILL.SPLIT_BILL" })}</div>
          </SplitBillWrapper>
        </div>
      </PaymentDetailsHeaderContainer>

      <PaymentDetailsBottomContainer>
        {isCash ? (
          <div className={"paymentDetailsBottomExpectedChange"}>
            <div className={"paymentDetailsBottomExpectedChangeText"}>
              {f({ id: "CHECKOUT.EXPECTED_BALANCE" })} :
            </div>
            <div className={"paymentDetailsBottomExpectedChangeValue"}>
              {getCurrencyWithPrice(balancePrice, shopData.currency)}
            </div>
          </div>
        ) : (
          <div style={{ height: "59px" }} />
        )}
        <PaymentDetailsBottomCashCardButton
          isValid={validateButton() && !loadingAction?.loading}
        >
          <div
            onClick={() => navigate("/product")}
            className={"paymentDetailsBottomCashCardCancelBtn"}
          >
            <div>{f({ id: "CHECKOUT.CANCEL" })}</div>
          </div>
          <div
            className={`paymentDetailsBottomCashCardOrderBtn`}
            onClick={() => onCheckout(totalPrice, serviceCharge)}
          >
            <div>{f({ id: "CHECKOUT.COMPLETE_ORDER" })}</div>
          </div>
        </PaymentDetailsBottomCashCardButton>
      </PaymentDetailsBottomContainer>
    </PaymentDetailsContainer>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    orderOffers: state.offer.get("orderOffers"),
    currentUser: state.login.get("currentUser"),
    batchSession: state.login.get("batchSession"),
    selectedTable: state.table.get("selectedTable"),
    loadingAction: state.common.get("loadingAction"),
    quickCustomer: state.customer.get("quickCustomer"),
    selectedVehicle: state.orders.get("selectedVehicle"),
    purchaseProducts: state.product.get("purchaseProducts"),
    purchaseServices: state.service.get("purchaseServices"),
    selectedEmployee: state.employee.get("selectedEmployee"),
    selectedCustomer: state.customer.get("selectedCustomer"),
    selectedAppointment: state.orders.get("selectedAppointment"),
    appointmentWarranty: state.product.get("appointmentWarranty"),
    appointmentDiscounts: state.product.get("appointmentDiscounts"),
    appointmentDateAndTime: state.salon.get("appointmentDateAndTime"),
  }),
  {
    checkout: Actions.payment.checkout,
    createActivityLog: Actions.activityLog.createActivityLog,
    updateSplitPayments: Actions.payment.updateSplitPayments,
  }
)(PaymentDetailsView);
