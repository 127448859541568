import _ from "lodash";
import styled from "styled-components";
import BaseColors from "../../../internal/helpers/BaseColors";

export const ActivityLogWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

export const ActivityLogBottomWrapper = styled.div`
  padding: 10px;
  background-color: #eff3f6;
  height: calc(100vh - 61px);
`;

export const LogWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 81px);
`;
export const LogHeaderWrapper = styled.div`
  height: 45px;
  display: grid;
  align-items: center;
  background-color: #f4f8fb;
`;

export const LogHeaderTextWrapper = styled.div<{ justifyContent: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: ${(props: any) => props.justifyContent};

  & > div {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    font-family: Montserrat;
  }
`;

export const LogDetailsWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #ffffff;
  height: calc(100vh - 126px);

  & > div {
    align-items: center;
    background-color: #ffffff;

    :hover {
      background-color: #f8f9fb;
      cursor: pointer;
    }
    .orderQueueBottomLine {
      height: 1px;
      margin-left: 15px;
      margin-right: 15px;
      background-color: rgba(32, 37, 58, 0.08);
    }
  }
`;

export const LogDetailsTextWrapper = styled.div<{ justifyContent: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  color: #434343;
  font-size: 14px;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  font-family: Montserrat;
  justify-content: ${(props: any) => props.justifyContent};
`;

export const ActionButton = styled.button<{ isRestored: boolean }>`
  height: 40px;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  max-width: 289px;
  border-radius: 8px;
  padding: 8px 14px;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition-duration: 0.4s;
  font-family: "Montserrat";
  background-color: #ffffff;
  color: ${(props: any) => (props.isRestored ? `#FF5F5F` : `#49a4d4`)};
  border: ${(props: any) =>
    props.isRestored ? `1px solid #FF5F5F` : `1px solid #49a4d4`};

  :focus {
    outline: none;
  }
`;
