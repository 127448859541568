import React from 'react';
import SideNavBar from './SideNavBar';

const settingData = [
  {
    label: 'Menu',
    value: 'MENU',
  },
  // {
  //   label: 'Favourites',
  //   value: 'FAVOURITES',
  // },
  {
    label: 'All Products',
    value: 'ALL_PRODUCTS',
  },
  {
    label: 'Categories',
    value: 'CATEGORIES',
  },
  {
    label: 'Brands',
    value: 'BRANDS',
  },
  // {
  //   label: 'Suppliers',
  //   value: 'SUPPLIERS',
  // },
  {
    label: 'Product Types',
    value: 'PRODUCT_TYPES',
  },
  {
    label: 'Stock Items',
    value: 'STOCK',
  },
];

const ProductCustomizedSideNav = (props) => {
  const {onClick, selectedSidebar} = props;

  return (
    <SideNavBar
    {...{
      options: settingData,
      onClick,
      selectedSidebar,
    }}
    />
  );
};
export default ProductCustomizedSideNav;
