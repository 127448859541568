import React, { useEffect, useState } from "react";
import {
  WarrantyContainer,
  WarrantyItemWrapper,
  WarrantyApplyButton,
  SelectedWarrantyWrapper,
} from "./Styled";
import { connect } from "react-redux";
import { Actions } from "../../internal/app/Actions";
import IconDelete from "../../../assets/svg/IconDiscountDelete.svg";
import CreateWarrantyModal from "../model/views/CreateWarrantyModal";
import ServiceWarningModal from "../model/views/ServiceWarningModal";
import { getWarrantyEndDate } from "../../internal/manager/AppointmentManager";
import _, { get, find, isEmpty, compact, findIndex, capitalize } from "lodash";

const Warranty = (props) => {
  const {
    warranty,
    productList,
    orderDateAndTime,
    purchaseProducts,
    removeOrderWarranty,
    setPurchaseProducts,
  } = props;
  const [warranties, setWarranties] = useState<any>([]);
  const [selectedWarranty, setSelectedWarranty] = useState<any>({});
  const [productWarranties, setProductWarranties] = useState<any>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isOpenWarrantyModal, setIsOpenWarrantyModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (warranty) {
      setWarranties(warranty.warranties);
    }
  }, [warranty]);

  useEffect(() => {
    if (!isEmpty(purchaseProducts)) {
      const data = compact(
        purchaseProducts.map((product: any) => {
          if (!isEmpty(product.warranty)) {
            const warranty = product.warranty;
            warranty["pid"] = product.id;
            warranty["productName"] = product.productName;
            return warranty;
          }
          return null;
        })
      );
      setProductWarranties([...data]);
      const newWarranties = warranty.warranties.filter((cv: any) => {
        return !data.find((e: any) => {
          return e.wrid == cv.wrid;
        });
      });
      setWarranties([...newWarranties]);
    }
  }, [purchaseProducts]);

  const toggleWarrantyModal = () => {
    setSelectedWarranty({});
    setIsOpenWarrantyModal(!isOpenWarrantyModal);
  };

  const onClickRemove = (item: any) => {
    setSelectedWarranty(item);
    toggleWarrantyDelete();
  };

  const onSelectWarranty = (warranty: any, product: any) => {
    const data = find(purchaseProducts, ({ id }) => id === product.pid);
    const params = {
      ...data,
      warranty,
    };
    setPurchaseProducts([params]);
  };

  const toggleWarrantyDelete = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const removeSelectedItem = () => {
    removeOrderWarranty(selectedWarranty);
    setSelectedWarranty({});
    toggleWarrantyDelete();
  };

  const onClickWarranty = (warranty: any) => {
    setSelectedWarranty(warranty);
    setIsOpenWarrantyModal(true);
  };

  return (
    <WarrantyContainer disabled={isEmpty(purchaseProducts)}>
      <button
        disabled={isEmpty(purchaseProducts)}
        onClick={() => {
          toggleWarrantyModal();
        }}
      >
        {"Add Warranty"}
      </button>
      <div>
        {!isEmpty(productWarranties) &&
          productWarranties.map((warranty: any) => {
            const isNewWarranty = isEmpty(warranty?.durationType);
            const endDate = getWarrantyEndDate(warranty, orderDateAndTime);
            const description = !isNewWarranty
              ? `${warranty.duration} ${capitalize(warranty.durationType)}`
              : warranty.description;
            const title = `${warranty.title} - ${capitalize(
              warranty.productName
            )}`;

            const isDisabledWarranty =
              findIndex(
                productList,
                (product: any) =>
                  get(product, "warranty.wrid", "") === warranty.wrid
              ) > -1;

            console.log(
              "--------999998888877666isDisabledWarranty",
              isDisabledWarranty
            );

            return (
              <SelectedWarrantyWrapper disabled={isDisabledWarranty}>
                <div
                  onClick={() => {
                    if (!isDisabledWarranty) {
                      onClickWarranty(warranty);
                    }
                  }}
                >
                  <div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickRemove(warranty);
                      }}
                    >
                      <img src={IconDelete} />
                    </div>
                    <div>
                      <div>{title}</div>
                      <div>{description}</div>
                    </div>
                  </div>
                  <div>{endDate}</div>
                </div>
                <div />
              </SelectedWarrantyWrapper>
            );
          })}
      </div>
      {!_.isEmpty(warranties)
        ? warranties.map((warranty: any) => {
            const endDate = getWarrantyEndDate(warranty, orderDateAndTime);
            const isDisabled =
              findIndex(
                productWarranties,
                (item: any) => item?.wrid === warranty.wrid
              ) > -1;
            const activeWarrantyProduct = find(
              productList,
              (product: any) =>
                get(product, "warranty.wrid", "") === warranty.wrid
            );
            const isActiveWarranty =
              findIndex(
                purchaseProducts,
                (product: any) => product.id === activeWarrantyProduct?.pid
              ) > -1;

            const data = find(
              purchaseProducts,
              (product: any) => product.id === activeWarrantyProduct?.pid
            );
            const isAddedWarranty = !isEmpty(data?.warranty);
            const isUnSelectProduct =
              isEmpty(purchaseProducts) || !isActiveWarranty;

            return (
              <WarrantyItemWrapper>
                <div>
                  <div>
                    <div>{warranty.title}</div>
                    <div>{endDate}</div>
                  </div>
                  <WarrantyApplyButton
                    disabled={
                      isDisabled || isAddedWarranty || isUnSelectProduct
                    }
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onSelectWarranty(warranty, activeWarrantyProduct);
                    }}
                  >
                    Apply
                  </WarrantyApplyButton>
                </div>
                <div />
              </WarrantyItemWrapper>
            );
          })
        : null}
      <CreateWarrantyModal
        warranty={selectedWarranty}
        isOpen={isOpenWarrantyModal}
        closeModal={() => toggleWarrantyModal()}
      />
      <ServiceWarningModal
        isOpen={openDeleteModal}
        cancelBtnText={"Delete"}
        headerText={"Are you sure?"}
        bodText={
          "Are you sure you want to remove this selected \n warranty from bill ?"
        }
        onCancel={() => removeSelectedItem()}
        closeModal={() => toggleWarrantyDelete()}
      />
    </WarrantyContainer>
  );
};
export default connect(
  (state: any) => ({
    warranty: state.warranty.get("warranty"),
    productList: state.product.get("productList"),
    purchaseProducts: state.product.get("purchaseProducts"),
    orderDateAndTime: state.product.get("orderDateAndTime"),
  }),
  {
    setPurchaseProducts: Actions.product.setPurchaseProducts,
    removeOrderWarranty: Actions.product.removeOrderWarranty,
  }
)(Warranty);
