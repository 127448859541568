import gql from 'graphql-tag';

export const createShopOrder = gql`
  mutation CreateShopOrder($input: CreateShopOrderInput!) {
    createShopOrder(input: $input) {
      orderId
      createdUserId
      empId
      shopId
      firstName
      lastName
      email
      date
      time
      isSendSms
      mobileNumber
      createdTime
      updatedTime
      orderStatus
      paymentMethod
      tax
      notes
      clientId
      recieptId
      shopOrderStatus
      serviceDetails {
        id
        title
        serviceTitle
        price
        totalPrice
        quantity
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
      }
      offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
      productDetails {
         id
         opId
         sellingPrice
         totalPrice
         productPrice
         quantity
         productName
         sendToKitchen
         sendToBar
         sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
         }
         priceVariants {
            options
            price
            sku
            optionUnit
         }
         warranty {
          wrid
          title
          shopId
          endDate
          duration
          description
          durationType
          createdTime
          updatedTime
        }
        modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
      }
      discountDetails {
        id
        amount
        percentage
        title
      }
      name
      employeeName
      shopName
      batchId
      totalPrice
      paidPrice
      orderType
      cardDetails {
        last4
        name
      }
      customerDetails {
        key
        value
      }
      warrantyDetails {
        id
        date
        description
        items {
           id
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
        }
      }
      tableOrder {
        areaId
        tbId
        tbNumber
      }
      serviceCharge {
        amount
      }
      roomOrder {
        areaId
        rmId
        rmNumber
      }
      orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createOfflineOrder = gql`
  mutation CreateOfflineOrder($input: CreateOfflineOrderInput!) {
    createOfflineOrder(input: $input) {
      orderId
      createdUserId
      empId
      shopId
      firstName
      lastName
      email
      date
      time
      isSendSms
      mobileNumber
      createdTime
      updatedTime
      orderStatus
      paymentMethod
      tax
      notes
      clientId
      recieptId
      shopOrderStatus
      serviceDetails {
        id
        title
        serviceTitle
        price
        totalPrice
        quantity
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
      productDetails {
         id
         opId
         sellingPrice
         totalPrice
         productPrice
         quantity
         productName
          sendToKitchen
          sendToBar
          sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
          wrid
          title
          duration
          shopId
          endDate
          description
          durationType
          createdTime
          updatedTime
        }
        modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
      }
      discountDetails {
        id
        amount
        percentage
        title
      }
      refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
      }
      name
      employeeName
      shopName
      batchId
      totalPrice
      paidPrice
      orderType
      cardDetails {
        last4
        name
      }
      customerDetails {
        key
        value
      }
      warrantyDetails {
        id
        date
        description
        items {
           id
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
        }
      }
      tableOrder {
        areaId
        tbId
        tbNumber
      }
      serviceCharge {
        amount
      }
      roomOrder {
        areaId
        rmId
        rmNumber
      }
      orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateShopOrder = gql`
  mutation UpdateShopOrder($input: UpdateShopOrderInput!) {
    updateShopOrder(input: $input) {
      orderId
      createdUserId
      empId
      shopId
      firstName
      lastName
      email
      date
      time
      mobileNumber
      createdTime
      updatedTime
      orderStatus
      paymentMethod
      tax
      notes
      isSendSms
      clientId
      shopOrderStatus
      serviceDetails {
        id
        title
        serviceTitle
        price
        totalPrice
        quantity
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
      productDetails {
         id
         opId
         sellingPrice
         totalPrice
         productPrice
         quantity
         productName
         sendPrepTicket{
            quantity
            printer
            sendToKitchen
            sendToBar
        }
        priceVariants {
          options
          price
          sku
          optionUnit
        }
        warranty {
          wrid
          title
          duration
          shopId
          endDate
          description
          durationType
          createdTime
          updatedTime
        }
        modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
      }
      discountDetails {
        id
        amount
        percentage
        title
      }
      name
      employeeName
      shopName
      batchId
      totalPrice
      paidPrice
      orderType
      recieptId
      voidType
      orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
      }
      refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
      }
      cardDetails {
        last4
        name
      }
      customerDetails {
        key
        value
      }
      warrantyDetails {
        id
        date
        description
        items {
           id
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
        }
      }
      serviceCharge {
        amount
      }
      tableOrder {
        areaId
        tbId
        tbNumber
      }
      roomOrder {
        areaId
        rmId
        rmNumber
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateOfflineOrder = gql`
  mutation UpdateOfflineOrder($input: UpdateOfflineOrderInput!) {
    updateOfflineOrder(input: $input) {
      orderId
      createdUserId
      empId
      shopId
      firstName
      lastName
      email
      date
      time
      mobileNumber
      createdTime
      updatedTime
      orderStatus
      paymentMethod
      tax
      notes
      isSendSms
      clientId
      shopOrderStatus
      serviceDetails {
        id
        title
        serviceTitle
        price
        totalPrice
        quantity
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
      productDetails {
         id
         opId
         sellingPrice
         totalPrice
         productPrice
         quantity
         productName
         sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
            wrid
            title
            duration
            shopId
            endDate
            description
            durationType
            createdTime
            updatedTime
          }
          modifiers {
            id
            price
            name
            quantity
            total
            items {
              id
              price
              name
              quantity
              total
            }
          }
      }
      discountDetails {
        id
        amount
        percentage
        title
      }
      name
      employeeName
      shopName
      batchId
      totalPrice
      paidPrice
      orderType
      recieptId
      voidType
      orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
      }
      refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
      }
      cardDetails {
        last4
        name
      }
      customerDetails {
        key
        value
      }
      warrantyDetails {
        id
        date
        description
        items {
           id
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
        }
      }
      serviceCharge {
        amount
      }
      tableOrder {
        areaId
        tbId
        tbNumber
      }
      roomOrder {
        areaId
        rmId
        rmNumber
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteShopOrder = gql`
  mutation DeleteShopOrder($input: DeleteShopOrderInput!) {
    deleteShopOrder(input: $input) {
      orderId
      shopId
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteOfflineOrder = gql`
  mutation DeleteOfflineOrder($input: DeleteOfflineOrderInput!) {
    deleteOfflineOrder(input: $input) {
      orderId
      shopId
      _version
      _deleted
      isRemoved
      _lastChangedAt
    }
  }
`;

export const fetchOrders = gql`
  query FetchOrders($shopId: String!, $orderStatus: String) {
    fetchOrders(shopId: $shopId, orderStatus: $orderStatus) {
      items {
        orderId
        shopId
        empId
        recieptId
        createdUserId
        firstName
        lastName
        mobileNumber
        email
        date
        time
        shopOrderStatus
        serviceDetails {
          id
          title
          serviceTitle
          price
          totalPrice
          quantity
          duration
          isPrimary
          type {
            id
            title
          }
          status
        }
        discountDetails {
          id
          amount
          percentage
          title
        }
        orderStatus
        createdTime
        paymentMethod
        updatedTime
        orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
        }
        tax
        notes
        clientId
        name
        employeeName
        shopName
        batchId
        totalPrice
        paidPrice
        orderType
        refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
        }
        offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
        productDetails {
           id
           opId
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
           sendPrepTicket {
            quantity
            printer
            sendToKitchen
            sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
            wrid
            title
            duration
            shopId
            endDate
            description
            durationType
            createdTime
            updatedTime
          }
          modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
        }
        voidType
        isSendSms
        cardDetails {
          last4
          name
        }
        customerDetails {
          key
          value
        }
        warrantyDetails {
          id
          date
          description
          items {
             id
             sellingPrice
             totalPrice
             productPrice
             quantity
             productName
          }
        }
        tableOrder {
          areaId
          tbId
          tbNumber
        }
        roomOrder {
          areaId
          rmId
          rmNumber
        }
        serviceCharge {
          amount
        }
        splitPayments {
          method
          amount
          last4
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const fetchOrderStatus = gql`
  query FetchOrderStatus($shopOrderStatus: String, $limit: Int, $nextToken: String) {
    fetchOrderStatus(shopOrderStatus: $shopOrderStatus, limit: $limit, nextToken: $nextToken) {
      items {
        orderId
        shopId
        empId
        recieptId
        createdUserId
        firstName
        lastName
        mobileNumber
        email
        date
        time
        shopOrderStatus
        serviceDetails {
          id
          title
          serviceTitle
          price
          totalPrice
          quantity
          duration
          isPrimary
          type {
            id
            title
          }
          status
        }
        discountDetails {
          id
          amount
          percentage
          title
        }
        orderStatus
        createdTime
        paymentMethod
        updatedTime
        orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
        }
        tax
        notes
        clientId
        name
        employeeName
        shopName
        batchId
        totalPrice
        paidPrice
        orderType
        refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
        }
        offerDetails {
          pid
          title
          amount
          offerId
          offerType
          isEveryDay
          offerDate
          offerTime {
            endTime
            startTime
          }
          discount {
            amount
            amountType
          }
          offerItems {
            name
            pid
            price
            quantity
          }
        }
        productDetails {
           id
           opId
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
          sendPrepTicket {
            quantity
            printer
            sendToKitchen
            sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
            wrid
            title
            duration
            shopId
            endDate
            description
            durationType
            createdTime
            updatedTime
          }
          modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
        }
        voidType
        isSendSms
        cardDetails {
          last4
          name
        }
        customerDetails {
          key
          value
        }
        warrantyDetails {
          id
          date
          description
          items {
             id
             sellingPrice
             totalPrice
             productPrice
             quantity
             productName
          }
        }
        tableOrder {
          areaId
          tbId
          tbNumber
        }
        roomOrder {
          areaId
          rmId
          rmNumber
        }
        serviceCharge {
          amount
        }
        splitPayments {
          method
          amount
          last4
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const fetchOfflineOrders = gql`
  query FetchOfflineOrders($key: String!) {
    fetchOfflineOrders(key: $key) {
      items {
        orderId
        createdUserId
        empId
        shopId
        firstName
        lastName
        email
        date
        time
        mobileNumber
        createdTime
        updatedTime
        orderStatus
        paymentMethod
        tax
        notes
        isSendSms
        clientId
        shopOrderStatus
        serviceDetails {
          id
          title
          serviceTitle
          price
          totalPrice
          quantity
          duration
          isPrimary
          type {
            id
            title
          }
          status
        }
        offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
        productDetails {
           id
           opId
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
           sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
            wrid
            title
            duration
            shopId
            endDate
            description
            durationType
            createdTime
            updatedTime
          }
          modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
        }
        discountDetails {
          id
          amount
          percentage
          title
        }
        refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
        }
        voidType
        name
        employeeName
        shopName
        batchId
        totalPrice
        paidPrice
        orderType
        recieptId
        cardDetails {
          last4
          name
        }
        customerDetails {
          key
          value
        }
        warrantyDetails {
          id
          date
          description
          items {
             id
             sellingPrice
             totalPrice
             productPrice
             quantity
             productName
          }
        }
        serviceCharge {
          amount
        }
        tableOrder {
          areaId
          tbId
          tbNumber
        }
        roomOrder {
          areaId
          rmId
          rmNumber
        }
        orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
      }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
    }
  }
`;

export const onCreateShopOrder = gql`
subscription OnCreateShopOrder($shopId: String) {
  onCreateShopOrder(shopId: $shopId) {
      orderId
      createdUserId
      empId
      shopId
      firstName
      lastName
      email
      date
      time
      isSendSms
      mobileNumber
      createdTime
      updatedTime
      orderStatus
      paymentMethod
      tax
      notes
      clientId
      recieptId
      shopOrderStatus
      serviceDetails {
        id
        title
        serviceTitle
        price
        totalPrice
        quantity
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
      }
      offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
      productDetails {
         id
         opId
         sellingPrice
         totalPrice
         productPrice
         quantity
         productName
         sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
          wrid
          title
          duration
          shopId
          endDate
          description
          durationType
          createdTime
          updatedTime
        }
        modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
      }
      discountDetails {
        id
        amount
        percentage
        title
      }
      voidType
      name
      employeeName
      shopName
      batchId
      totalPrice
      paidPrice
      orderType
      cardDetails {
        last4
        name
      }
      customerDetails {
        key
        value
      }
      warrantyDetails {
        id
        date
        description
        items {
           id
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
        }
      }
      tableOrder {
        areaId
        tbId
        tbNumber
      }
      serviceCharge {
        amount
      }
      roomOrder {
        areaId
        rmId
        rmNumber
      }
      orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
      }
      splitPayments {
          method
          amount
          last4
        }
      _version
      _deleted
      _lastChangedAt
   }
}
`;

export const onUpdateShopOrder = gql`
subscription OnUpdateShopOrder($shopId: String) {
  onUpdateShopOrder(shopId: $shopId) {
      orderId
      createdUserId
      empId
      shopId
      firstName
      lastName
      email
      date
      time
      isSendSms
      mobileNumber
      createdTime
      updatedTime
      orderStatus
      paymentMethod
      tax
      notes
      clientId
      recieptId
      shopOrderStatus
      serviceDetails {
        id
        title
        serviceTitle
        price
        totalPrice
        quantity
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
      }
      refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
      }
      offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
      productDetails {
         id
         opId
         sellingPrice
         totalPrice
         productPrice
         quantity
         productName
         sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
          wrid
          title
          duration
          shopId
          endDate
          description
          durationType
          createdTime
          updatedTime
        }
        modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
      }
      discountDetails {
        id
        amount
        percentage
        title
      }
      voidType
      name
      employeeName
      shopName
      batchId
      totalPrice
      paidPrice
      orderType
      cardDetails {
        last4
        name
      }
      customerDetails {
        key
        value
      }
      warrantyDetails {
        id
        date
        description
        items {
           id
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
        }
      }
      tableOrder {
        areaId
        tbId
        tbNumber
      }
      serviceCharge {
        amount
      }
      roomOrder {
        areaId
        rmId
        rmNumber
      }
      splitPayments {
          method
          amount
          last4
        }
        _version
        _deleted
        _lastChangedAt
   }
}
`;

export const onDeleteShopOrder = gql`
subscription OnDeleteShopOrder($shopId: String) {
  onDeleteShopOrder(shopId: $shopId) {
      orderId
      createdUserId
      empId
      shopId
      firstName
      lastName
      email
      date
      time
      isSendSms
      mobileNumber
      createdTime
      updatedTime
      orderStatus
      paymentMethod
      tax
      notes
      clientId
      recieptId
      shopOrderStatus
      serviceDetails {
        id
        title
        serviceTitle
        price
        totalPrice
        quantity
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
      }
      offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
      productDetails {
         id
         opId
         sellingPrice
         totalPrice
         productPrice
         quantity
         productName
         sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
          wrid
          title
          duration
          shopId
          endDate
          description
          durationType
          createdTime
          updatedTime
        }
        modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
      }
      discountDetails {
        id
        amount
        percentage
        title
      }
      name
      employeeName
      shopName
      batchId
      totalPrice
      paidPrice
      orderType
      cardDetails {
        last4
        name
      }
      customerDetails {
        key
        value
      }
      warrantyDetails {
        id
        date
        description
        items {
           id
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
        }
      }
      orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
      }
      tableOrder {
        areaId
        tbId
        tbNumber
      }
      serviceCharge {
        amount
      }
      roomOrder {
        areaId
        rmId
        rmNumber
      }
   }
}
`;

export const createReceipt = gql`
  mutation CreateReceipt($input: CreateReceiptInput!) {
    createReceipt(input: $input) {
      content
      deviceType
      destination
      receiptType
    }
  }
`;

export const fetchPrinters = gql`
  query FetchPrinters($type: Boolean!) {
    fetchPrinters(type: $type) {
      items {
        id
        printType
        deviceType
        usb
        ipAddress
        printerName
      }
    }
  }
`;

export const setShopOrders = gql`
  mutation SetShopOrders($orders: [SetShopOrdersInput]) {
    setShopOrders(orders: $orders) {
        orderId
        shopId
        empId
        recieptId
        createdUserId
        firstName
        lastName
        mobileNumber
        email
        date
        time
        shopOrderStatus
        serviceDetails {
          id
          title
          serviceTitle
          price
          totalPrice
          quantity
          duration
          isPrimary
          type {
            id
            title
          }
          status
        }
        discountDetails {
          id
          amount
          percentage
          title
        }
        orderStatus
        createdTime
        paymentMethod
        updatedTime
        orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
        }
        tax
        notes
        clientId
        name
        employeeName
        shopName
        batchId
        totalPrice
        paidPrice
        orderType
        refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
        }
        offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
        productDetails {
           id
           opId
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
           sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
          wrid
          title
          duration
          shopId
          endDate
          description
          durationType
          createdTime
          updatedTime
        }
        modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
        }
        voidType
        isSendSms
        cardDetails {
          last4
          name
        }
        customerDetails {
          key
          value
        }
        warrantyDetails {
          id
          date
          description
          items {
             id
             sellingPrice
             totalPrice
             productPrice
             quantity
             productName
          }
        }
        tableOrder {
          areaId
          tbId
          tbNumber
        }
        roomOrder {
          areaId
          rmId
          rmNumber
        }
        serviceCharge {
          amount
        }
        splitPayments {
          method
          amount
          last4
        }
        _version
        _deleted
        _lastChangedAt
    }
  }
`;

export const setPrinters = gql`
  mutation SetPrinters($printers: [SetPrinterInput]) {
    setPrinters(printers: $printers) {
      id
      printType
      deviceType
      usb
      ipAddress
      printerName
    }
  }
`;

export const setLocalData = gql`
  mutation SetLocalData($key: String, $value: String) {
    setLocalData(key: $key, value: $value) {
      value
    }
  }
`;

export const getLocalData = gql`
  query GetLocalData($key: String!) {
    getLocalData(key: $key) {
      value
    }
  }
`;

export const CreateVehicle = gql`
  mutation CreateVehicle($input: CreateVehicleInput!) {
    createVehicle(input: $input) {
      clientId
      shopId
      vid
      vehicleType
      brand
      modal
      plateNumber
      nextService
      mileage
      createdTime
      updatedTime
    }
  }
`;

export const UpdateVehicle = gql`
  mutation UpdateVehicle($input: UpdateVehicleInput!) {
    updateVehicle(input: $input) {
      clientId
      shopId
      vid
      vehicleType
      brand
      modal
      plateNumber
      nextService
      mileage
      createdTime
      updatedTime
    }
  }
`;

export const GetDiscounts = gql`
  query GetDiscounts($shopId: String!) {
    getDiscounts(shopId: $shopId) {
      shopId
	    updatedAt
	    discounts {
        discId
        title
        desc
        discType
        amountType
        amount
      }
    }
  }
`;

export const SubscribeCreateOrder = gql`
subscription OnCreateShopOrder($shopId: String) {
  onCreateShopOrder(shopId: $shopId) {
      orderId
      createdUserId
      empId
      shopId
      firstName
      lastName
      email
      date
      time
      isSendSms
      mobileNumber
      createdTime
      updatedTime
      orderStatus
      paymentMethod
      tax
      notes
      clientId
      recieptId
      shopOrderStatus
      serviceDetails {
        id
        title
        serviceTitle
        price
        totalPrice
        quantity
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
      productDetails {
         id
         opId
         sellingPrice
         totalPrice
         productPrice
         quantity
         productName
         sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
          wrid
          title
          duration
          shopId
          endDate
          description
          durationType
          createdTime
          updatedTime
        }
        modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
      }
      discountDetails {
        id
        amount
        percentage
        title
      } 
      refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
      }
      name
      employeeName
      shopName
      batchId
      totalPrice
      paidPrice
      orderType
      cardDetails {
        last4
        name
      }
      customerDetails {
        key
        value
      }
      warrantyDetails {
        id
        date
        description
        items {
           id
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
        }
      }
      tableOrder {
        areaId
        tbId
        tbNumber
      }
      serviceCharge {
        amount
      }
      orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
      }
      roomOrder {
        areaId
        rmId
        rmNumber
      }
   }
}
`;

export const SubscribeUpdateOrder = gql`
subscription OnUpdateShopOrder($shopId: String) {
  onUpdateShopOrder(shopId: $shopId) {
    orderId
      createdUserId
      empId
      shopId
      firstName
      lastName
      email
      date
      time
      isSendSms
      mobileNumber
      createdTime
      updatedTime
      orderStatus
      paymentMethod
      tax
      notes
      clientId
      recieptId
      shopOrderStatus
      serviceDetails {
        id
        title
        serviceTitle
        price
        totalPrice
        quantity
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
      productDetails {
         id
         opId
         sellingPrice
         totalPrice
         productPrice
         quantity
         productName
         sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
          wrid
          title
          duration
          shopId
          endDate
          description
          durationType
          createdTime
          updatedTime
        }
        modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
      }
      refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
      }
      discountDetails {
        id
        amount
        percentage
        title
      }
      name
      employeeName
      shopName
      batchId
      totalPrice
      paidPrice
      orderType
      cardDetails {
        last4
        name
      }
      customerDetails {
        key
        value
      }
      warrantyDetails {
        id
        date
        description
        items {
           id
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
        }
      }
      tableOrder {
        areaId
        tbId
        tbNumber
      }
      serviceCharge {
        amount
      }
      orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
      }
      roomOrder {
        areaId
        rmId
        rmNumber
      }
   }
}
`;

export const SubscribeDeleteOrder = gql`
subscription OnDeleteShopOrder($shopId: String) {
  onDeleteShopOrder(shopId: $shopId) {
    orderId
      createdUserId
      empId
      shopId
      firstName
      lastName
      email
      date
      time
      isSendSms
      mobileNumber
      createdTime
      updatedTime
      orderStatus
      paymentMethod
      tax
      notes
      clientId
      recieptId
      shopOrderStatus
      serviceDetails {
        id
        title
        serviceTitle
        price
        totalPrice
        quantity
        duration
        isPrimary
        type {
          id
          title
        }
        status
      }
      refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
      }
      offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
      productDetails {
         id
         opId
         sellingPrice
         totalPrice
         productPrice
         quantity
         productName
         sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
          wrid
          title
          duration
          shopId
          endDate
          description
          durationType
          createdTime
          updatedTime
        }
        modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
      }
      discountDetails {
        id
        amount
        percentage
        title
      }
      name
      employeeName
      shopName
      batchId
      totalPrice
      paidPrice
      orderType
      cardDetails {
        last4
        name
      }
      customerDetails {
        key
        value
      }
      warrantyDetails {
        id
        date
        description
        items {
           id
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
        }
      }
      tableOrder {
        areaId
        tbId
        tbNumber
      }
      serviceCharge {
        amount
      }
      orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
      }
      roomOrder {
        areaId
        rmId
        rmNumber
      }
   }
}
`;

export const getShopOrder = gql`
  query GetShopOrder($shopId: String!, $orderId: String!) {
    getShopOrder(shopId: $shopId, orderId: $orderId) {
        orderId
        createdUserId
        empId
        shopId
        firstName
        lastName
        email
        date
        time
        mobileNumber
        createdTime
        updatedTime
        orderStatus
        paymentMethod
        tax
        notes
        isSendSms
        clientId
        shopOrderStatus
        serviceDetails {
          id
          title
          serviceTitle
          price
          totalPrice
          quantity
          duration
          isPrimary
          type {
            id
            title
          }
          status
        }
        refunds {
          batchId
          amount
          createdTime
          services {
            id
            amount
            quantity
          }
          products {
            id
            amount
            quantity
          }
          servicePrice
          productPrice
          serviceCount
          productCount
      }
      offerDetails {
        pid
        title
        amount
        offerId
        offerType
        isEveryDay
        offerDate
        offerTime {
          endTime
          startTime
        }
        discount {
          amount
          amountType
        }
        offerItems {
          name
          pid
          price
          quantity
        }
      }
        productDetails {
           id
           opId
           sellingPrice
           totalPrice
           productPrice
           quantity
           productName
           sendPrepTicket{
              quantity
              printer
              sendToKitchen
              sendToBar
          }
          priceVariants {
            options
            price
            sku
            optionUnit
          }
          warranty {
            wrid
            title
            duration
            shopId
            endDate
            description
            durationType
            createdTime
            updatedTime
          }
          modifiers {
          id
          price
          name
          quantity
          total
          items {
            id
            price
            name
            quantity
            total
          }
        }
        }
        discountDetails {
          id
          amount
          percentage
          title
        }
        name
        employeeName
        shopName
        batchId
        totalPrice
        paidPrice
        orderType
        recieptId
        cardDetails {
          last4
          name
        }
        customerDetails {
          key
          value
        }
        warrantyDetails {
          id
          date
          description
          items {
             id
             sellingPrice
             totalPrice
             productPrice
             quantity
             productName
          }
        }
        serviceCharge {
          amount
        }
        tableOrder {
          areaId
          tbId
          tbNumber
        }
        roomOrder {
          areaId
          rmId
          rmNumber
        }
        orderReason {
          createdTime
          createdUser
          reason
          createdUserType
          reasonStatus
          proposeTime
          proposeDate
          cancelType {
            id
	          title
          }
          createdUserName
        }
        _version
        _deleted
        _lastChangedAt
    }
  }
`;

export const createActivityLog = gql`
  mutation CreateActivityLog($input: CreateActivityLogInput!, $type: String!) {
    createActivityLog(input: $input, type: $type) {
      shopId
      logId
      empId
      actType
      createdTime
      updatedTime
      payloadId
      metaData {
        key
        value
      }
    }
  }
`;


export const CreateActivityLog = gql`
  mutation CreateActivityLog($input: CreateActivityLogInput!) {
    createActivityLog(input: $input) {
      shopId
      logId
      empId
      actType
      createdTime
      updatedTime
      payloadId
      metaData {
        key
        value
      }
    }
  }
`;

export const DeleteActivityLog = gql`
  mutation DeleteActivityLog($input: DeleteActivityLogInput!) {
    deleteActivityLog(input: $input) {
      shopId
      logId
    }
  }
`;

export const createOfflineActivityLog = gql`
  mutation CreateOfflineActivityLog($input: CreateOfflineActivityLogInput!) {
    createOfflineActivityLog(input: $input) {
      shopId
      logId
      empId
      actType
      createdTime
      updatedTime
      payloadId
      metaData {
        key
        value
      }
    }
  }
`;

export const deleteActivityLog = gql`
  mutation DeleteActivityLog($input: DeleteActivityLogInput!, $type: String! ) {
    deleteActivityLog(input: $input, type: $type) {
      shopId
      logId
      empId
      actType
      createdTime
      updatedTime
      payloadId
      metaData {
        key
        value
      }
    }
  }
`;

export const getActivityLogs = gql`
  query GetActivityLogs($shopId: String!, $type: String!) {
    getActivityLogs(shopId: $shopId, type: $type) {
      items {
        shopId
        logId
        empId
        createdTime
        updatedTime
        actType
        payloadId
        metaData {
          key
          value
        }
      }
    }
  }
`;

export const setActivityLogs = gql`
  mutation SetActivityLogs($activities: [SetActivityLogInput], $type: String!) {
    setActivityLogs(activities: $activities, type: $type) {
      shopId
      logId
      empId
      actType
      createdTime
      updatedTime
      payloadId
      metaData {
        key
        value
      }
    }
  }
`;

export const fetchActivities = gql`
  query FetchActivities($shopId: String, $limit: Int, $nextToken: String) {
    fetchActivities(shopId: $shopId, limit: $limit, nextToken: $nextToken) {
      items {
        shopId
        logId
        empId
        actType
        createdTime
        updatedTime
        payloadId
        metaData {
          key
          value
        }
      }
      nextToken
    }
  }
`;

export const setProductOptionSets = gql`
  mutation SetProductOptionSets($productOptions: [ProductOptionSetInput], $type: String!) {
    setProductOptionSets(productOptions: $productOptions, type: $type) {
        id
        shopId
        setName
        setDisplayName
        options {
          id
          optionName
        }
    }
  }
`;

export const fetchProductOptionSets = gql`
  query FetchProductOptionSets($shopId: String!, $type: String!) {
    fetchProductOptionSets(shopId: $shopId, type: $type) {
      items {
        id
        shopId
        setName
        createdTime
        updatedTime
        setDisplayName
        options {
          id
          optionName
        }
      }
      nextToken
    }
  }
`;