import React, {useEffect, useState} from 'react';
import styles from '../../../components/Common/Style/Styles.css';
import {Row, Col} from 'react-grid-system';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import ServiceCustomizedSideNav from '../../../components/sideNavBars/views/ServiceCustomizedSideNav';
import AllServicesView from './AllServicesView';
import ServiceSubHeader from '../../../components/headers/Views/ServiceSubHeader';
import {connect} from "react-redux/es/index";
import CategoryTableView from './CategoryTableView';
import {Actions} from "../../../internal/app/Actions";

const ServiceCustomizedView = (props) => {
  const { selectServiceSideBarView, selectedServiceSideBarView, clearSelectedServiceCategory } = props;
  const [selectedSidebar, setSelectedSidebar] = useState(selectedServiceSideBarView);

  useEffect(() => {
    setSelectedSidebar(selectedServiceSideBarView);
  }, [selectedServiceSideBarView]);

  const onClickSideBar = (type) => {
    selectServiceSideBarView(type);
  };

  const renderSidebarValue = () => {
    switch (selectedSidebar) {
      case 'CATEGORIES':
        return (
          <div className={styles.categoryTableWrapper}>
            <CategoryTableView />
          </div>
        );
      case 'ALL_SERVICES':
        return (
          <div className={styles.allServiceWrapper}>
            <AllServicesView/>
          </div>
        );

      default: {
        return (
          <div />
        )
      }
    }
  };

  const headerText = selectedSidebar === "ALL_SERVICES" ? 'Edit Services' : selectedSidebar === "CATEGORIES" ? 'Edit Categories' : 'Add Favourites';

  return (
    <div className={styles.serviceCustomizedContainer}>
      <TopNavigationBar viewName="Services" path="/menu"/>
      <div className={styles.settingsWrapper}>
        <div className={styles.serviceCustomizedWrapper}>
          <Row>
            <Col xs={12}>
              <ServiceSubHeader header={headerText} selectedSidebar={selectedSidebar} onClear={()=> clearSelectedServiceCategory("/add-category")}/>
            </Col>
          </Row>
          <Row>
            <Col xs={2} style={{paddingRight: 0}}>
              <ServiceCustomizedSideNav selectedSidebar={selectedSidebar} onClick={(type) => onClickSideBar(type)}/>
            </Col>
            <Col xs={10} style={{paddingLeft: 0}}>
              {
                renderSidebarValue()
              }
            </Col>
          </Row>

        </div>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    selectedServiceSideBarView: state.service.get('selectedServiceSideBarView')
  }),
  {
    selectServiceSideBarView: Actions.service.selectServiceSideBarView,
    clearSelectedServiceCategory: Actions.service.clearSelectedServiceCategory
  }
)(ServiceCustomizedView);

