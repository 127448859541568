import _ from "lodash";
import {AnyAction} from 'redux';
import { Map } from 'immutable';
import { ModuleEvents } from './Actions';

const initialState = Map({
  reportsSummary: null,
  reportsTotal: {},
  appointmentsForDate: [],
  appointmentsForDateLastKey: {},
  appointmentsForDateRange: [],
  appointmentsForDateRangeLastKey: {},
});

export const Reducer = (state = initialState, action: AnyAction) => {
  const {payload, type, error} = action;

  if (error) {
    console.warn('Error handled in Reports Reducer', payload);
    return state;
  }
  switch (type) {
    case ModuleEvents.FETCH_REPORTS: {
      if (payload && !payload.error) {
        return state.set('reportsSummary', payload?.Items);
      }
      return state;
    }
    case ModuleEvents.FETCH_APPOINTMENTS_FOR_DATE: {
      if (payload && !payload.error) {
        const lastKey = _.get(payload, 'LastEvaluatedKey', {});
        const sortedAppointments = _.orderBy(_.get(payload, 'Items', []), ['updatedTime'], ['desc']);
        return state.set('appointmentsForDate', sortedAppointments)
          .set('appointmentsForDateLastKey',lastKey);
      }
      return state;
    }
    case ModuleEvents.FETCH_APPOINTMENTS_FOR_DATE_RANGE: {
      if (payload && !payload.error) {
        const appointments = _.orderBy(_.get(payload, 'appointments', []), ['updatedTime'], ['desc']);
        return state.set('appointmentsForDateRange', appointments)
          .set('appointmentCount', payload.totalCount)
      }
      return state;
    }
    case ModuleEvents.FETCH_REPORT_FOR_DATE: {
      if (payload && !payload.error) {
        return state.set('reportsTotal', payload);
      }
      return state;
    }
    case ModuleEvents.FETCH_REPORT_FOR_DATE_RANGE: {
      if (payload && !payload.error) {
        return state.set('reportsTotal', payload);
      }
      return state;
    }
    default:
      return state;
  }
};

export default Reducer;

