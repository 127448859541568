import _, { delay } from "lodash";
import moment from "moment";
import { ModuleEvents } from "./Actions";
import {
  isCheckOnline,
  downloadShopImages,
  setValueToLocalStorage,
} from "../../internal/manager/CommonManager";
import { Actions } from "./../../internal/app/Actions";
import Storage from "../../internal/service/Storage.service";
import UserRepository from "../../internal/repos/UserRepository";
import TableRepository from "../../internal/repos/TableRepository";
import { navigate } from "../../internal/service/Navigation.service";
import { generateImageUrl } from "../../internal/manager/ImageManager";
import AppointmentRepository from "../../internal/repos/AppointmentRepository";

const Handlers = {
  [ModuleEvents.USER_SIGNIN]: async ({ dispatch, payload, appState }) => {
    const isOnline = await isCheckOnline();
    if (!isOnline) {
      if (payload) {
        dispatch(
          Actions.login.setLoginIds({
            shopId: payload.shopId,
            empId: payload.empId,
          })
        );
      } else {
        dispatch(Actions.login.openAuthenticationModel(true, "Incorrect pin!"));
      }
    } else {
      const user = _.get(payload, "signInUserSession.idToken.payload");
      const accessToken = _.get(
        payload,
        "signInUserSession.accessToken.jwtToken"
      );
      if (user) {
        // const empId = user["cognito:username"];
        const empId = appState.login.get("selectedLoginEmployee").empId;
        const shopId = user["custom:id"];
        dispatch(Actions.login.setLoginIds({ shopId, empId }));
        // dispatch(Actions.login.getCurrentUser(shopId, empId));
        dispatch(Actions.login.setAccessToken(accessToken));
        if (window?.standlone) {
          dispatch(Actions.login.getCurrentUser(shopId, empId));
        }
      } else {
        dispatch(
          Actions.login.openAuthenticationModel(
            true,
            _.get(payload, "error.message", "")
          )
        );
      }
    }
  },
  [ModuleEvents.GET_MERCHANT]: async ({ dispatch, payload, appState }) => {
    let progress = 0;
    if (payload && payload.shopId) {
      navigate("/loading");
      const timer = setInterval(() => {
        if (progress < 100) {
          dispatch(Actions.login.setLoadingProgress(progress + 25));
          progress = progress + 25;
        }
        if (progress === 100) {
          clearInterval(timer);
        }
      }, 500);
      dispatch(Actions.login.authenticateStoredUser());
      dispatch(Actions.login.fetchTrial(payload.shopId));
      // dispatch(Actions.employee.fetchShopEmployees(payload.shopId));
    } else {
      // await dispatch(Actions.login.authenticateStoredUser());
      navigate("/onboard");
    }
  },
  [ModuleEvents.FETCH_TRIAL]: ({ payload, pathname }) => {
    if (payload && !payload.error) {
      const trialDetails = payload.trialDetails;
      const activeSubscriptions = payload.subscriptions;
      const isActiveSubscription = activeSubscriptions.length > 0;
      // const days = moment(trialDetails?.isExpired).diff(moment(), 'days');
      if ((!trialDetails && !isActiveSubscription) || (trialDetails?.isExpired && !isActiveSubscription)) {
        // if (!includes(PathsWithoutSubscription, pathname)) {
          navigate("/subscription-check");
        // }
      }
    }
  },
  [ModuleEvents.USER_SIGNIN_AGAIN]: async ({ dispatch, payload, appState }) => {
    const { result, username, password, tries } = payload;
    const shop = appState.login.get("merchantShop");
    const user = _.get(result, "signInUserSession.idToken.payload");
    if (user) {
      dispatch(Actions.common.updateAllOfflineActions({ shopId: shop.shopId }));
    } else if (tries < 3) {
      const isOnline = await isCheckOnline();
      if (isOnline) {
        // TODO: avoid repetition of this one when online, clear delay
        // delay(() => {
        //   dispatch(Actions.login.userSignInAgain(username, password, tries + 1));
        // }, 2000);
      }
    }
  },
  [ModuleEvents.AUTHENTICATE_STORED_USER]: ({
    dispatch,
    payload,
    appState,
  }) => {
    const shop = appState.login.get("merchantShop");
    const user = _.get(payload, "signInUserSession.idToken.payload");
    const accessToken = _.get(
      payload,
      "signInUserSession.accessToken.jwtToken"
    );
    if (user) {
      const shopId = user["custom:id"];
      const empId = user["cognito:username"];

      dispatch(Actions.login.setLoginIds({ shopId, empId }));
      // dispatch(Actions.login.getCurrentUser(shopId, empId));
      dispatch(Actions.login.setAccessToken(accessToken));
      downloadShopImages(shop);
    } else {
      navigate("/subscription-check");
    }
  },
  [ModuleEvents.USER_LOGOUT]: ({ dispatch, payload, appState }) => {
    if (payload) {
      navigate("/subscription-check");
    }
  },
  [ModuleEvents.GET_CURRENT_USER]: async ({ dispatch, payload, appState }) => {
    const shop = appState.login.get("merchantShop");
    const date = moment(new Date(Date.now()).toDateString()).valueOf();
    const shopId = shop.shopId;
    // Storage.removeItem('merchant');

    if (payload && !payload.error) {
      dispatch(Actions.service.fetchCategories(shopId));
      dispatch(Actions.product.fetchShopProducts(shopId));
      dispatch(Actions.product.fetchShopProductCategories(shopId));
      dispatch(Actions.product.fetchProductTypes(shopId));
      dispatch(Actions.product.fetchShopBrands(shopId));
      dispatch(Actions.table.fetchTables(shopId));
      dispatch(Actions.product.fetchMenu(shopId));
      dispatch(Actions.rooms.fetchRoomTypes(shopId));
      dispatch(Actions.rooms.fetchRooms(shopId));
      dispatch(Actions.login.createBatch(payload.empId, shopId));
      dispatch(Actions.employee.fetchEmployees(shopId));
      dispatch(Actions.orders.fetchCompletedAppointments(shopId));
      dispatch(Actions.orders.fetchDiscounts(shopId));
      dispatch(Actions.orders.fetchUnCompletedAppointments(shopId));
      dispatch(Actions.closeCash.getPastBatchSummary(shopId, payload.empId));
      dispatch(Actions.customer.fetchCustomers(shopId));
      dispatch(Actions.rooms.fetchRooms(shopId));
      dispatch(Actions.reports.fetchReportForDate(shopId, date));
      dispatch(Actions.reports.fetchAppointmentsForDate(shopId, date));
      dispatch(Actions.product.fetchStock(shopId));
      dispatch(Actions.stock.fetchStockRecord(shopId));
      dispatch(Actions.stock.fetchStockRecord(shopId));
      dispatch(Actions.activityLog.fetchActivities(shopId));
      dispatch(Actions.warranty.fetchWarranties(shopId));
      dispatch(Actions.offer.fetchOffers(shopId));
      await UserRepository.getNetworks((data: any) => {
        dispatch(Actions.common.getNetworks(data));
      });
      await UserRepository.getCurrentWifi((data: any) => {
        dispatch(Actions.common.getCurrentConnection(data));
      });
      navigate("/order");
      downloadShopImages(shop);

      await AppointmentRepository.subscribeCreateOrder(shopId, (data) => {
        if (data && !data.error) {
          dispatch(Actions.orders.onSubscriptionOrder(data));
        }
      });
      await AppointmentRepository.subscribeUpdateOrder(shopId, (data) => {
        if (data && !data.error) {
          dispatch(Actions.orders.onSubscriptionOrder(data));
        }
      });
      await AppointmentRepository.subscribeDeleteOrder(shopId, (data: any) => {
        if (data && !data.error) {
          dispatch(Actions.orders.onSubscriptionDeleteOrder(data));
        }
      });
      await TableRepository.subscribeCreateTable(shopId, (data) => {
        if (data && !data.error) {
          dispatch(Actions.table.onSubscriptionTable(data));
        }
      });
      await TableRepository.subscribeUpdateTable(shopId, (data) => {
        if (data && !data.error) {
          dispatch(Actions.table.onSubscriptionTable(data));
        }
      });
      await UserRepository.subscribeServiceMessage(shopId, (data) => {
        if (data && !data.error) {
          const payload = JSON.parse(data.value);
          const shopData = appState.login.get("merchantShop");
          const currentUser = appState.login.get("currentUser");
          switch (data.key) {
            case "CLOSE_CASH": {
              if (payload.status === "SUCCESS") {
                dispatch(
                  Actions.closeCash.createBatchSession(
                    shopData.shopId,
                    currentUser.empId
                  )
                );
              }
              return;
            }
            default: {
              return;
            }
          }
        }
      });
      // ipcRenderer.send('get-printers');

      if (window.api) {
        window.api.setPrinters((printers) => {
          dispatch(Actions.common.setPrinters(printers));
        });
        window.api.getPrinters();
        Storage.sync();
        if (shop.billImage) {
          const url = await generateImageUrl(shop.billImage);
          if (window.api) {
            window.api.download({ url, image: shop.billImage });
          }
        }
      }

      // ipcRenderer.on('result-printers', (event, arg) => {
      //   console.log(event);
      //   console.log(arg); // prints "pong"
      // });

      // const reportMonth = getReportMonth();
      // dispatch(Actions.reports.fetchReportSummary(shopId, reportMonth));

      // ipcRenderer.send('get-printers');

      // ipcRenderer.on('result-printers', (event, arg) => {
      //   console.log(event);
      //   console.log(arg); // prints "pong"
      // });
      //dispatch(Actions.common.setPrinters(printers));
      //console.log({ printers: JSON.stringify(printers) });

      // dispatch(Actions.product.fetchAllProduct());
      // dispatch(Actions.salon.fetchSalonHolidays(salon.shopId, "APPOINTMENT"));
    } else {
      navigate("/subscription-check");
    }
  },
  [ModuleEvents.CREATE_BATCH]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      const shop = appState.login.get("merchantShop");
      dispatch(Actions.closeCash.getBatchSummary(payload.batchId));
      dispatch(
        Actions.closeCash.completedCashAppointments(
          payload.batchId,
          shop.shopId
        )
      );
    }
  },
  [ModuleEvents.SELECT_LOGIN_EMPLOYEE]: ({ dispatch, payload, appState }) => {
    if (payload) {
      navigate("/login");
    }
  },
  [ModuleEvents.SET_LOGIN_IDS]: ({ dispatch, payload, appState }) => {
    if (payload && payload.shopId) {
      if (!window?.standlone) {
        dispatch(
          Actions.common.updateAllOfflineActions({ shopId: payload.shopId })
        );
      }
    }
  },
  [ModuleEvents.MERCHANT_ONBOARD]: async ({ dispatch, payload, appState }) => {
    if (payload && (payload.statusCode === 404 || payload.statusCode === 400)) {
      dispatch(Actions.login.openAuthenticationModel(true, payload.message));
      dispatch(Actions.login.stopProgress(true));
    } else if (payload && payload.data && payload.statusCode === 201) {
      dispatch(Actions.employee.fetchShopEmployees(payload.shopId));
      await setValueToLocalStorage("merchant", JSON.stringify(payload.data));
      // Storage.setItem("merchant", JSON.stringify(payload.data));
      navigate("/subscription-check");
      dispatch(Actions.login.getMerchant());
    } else {
      dispatch(
        Actions.login.openAuthenticationModel(
          true,
          "Unkown error, please try again or contact support"
        )
      );
      dispatch(Actions.login.stopProgress(true));
    }
  },
  [ModuleEvents.SET_LOADING_PROGRESS]: ({ dispatch, payload, appState }) => {
    if (payload && payload === 100) {
      navigate("/subscription-check");
    }
  },
  [ModuleEvents.MASTER_SIGNOUT]: ({ dispatch, payload, appState }) => {
    navigate("/onboard");
  },
};

export default Handlers;
