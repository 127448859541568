import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import {
  getCashPaymentValues,
  getCurrencyWithPrice,
} from "../../internal/manager/PaymentManager";
import { PaymentAmountInputs, PaymentAmounts } from "../Styles/Styled";

export const SplitMethod = ({
  split,
  setSplit,
  method,
  total = 0,
  shop,
  splitTotal = 0,
}) => {
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    setSplit({ ...split, method });
  }, []);

  const onUpdate = (name, value) => {
    let updatingValue = value;
    if (name === "amount") {
      // if (isNumber(value) && value.length > 0) {
      //   updatingValue = isNaN(parsed) ? '' : value.replaceAll("\\D+","")
      // }
      updatingValue = value.replace(/[^0-9.]/g, "");
    }

    setSplit({
      ...split,
      [name]: updatingValue,
    });
  };

  const paymentValues = getCashPaymentValues(total);

  if (method === "CASH") {
    return (
      <>
        <div>{f({ id: "SPLIT_BILL.CASH_PAYMENT" })}</div>
        <PaymentAmountInputs>
          <div>
            <div>{f({ id: "SPLIT_BILL.CASH_AMOUNT" })}</div>
            <input
              value={split.amount}
              onChange={(event) => onUpdate("amount", event.target.value)}
            />
          </div>
        </PaymentAmountInputs>
        <PaymentAmounts>
          {total - splitTotal > 0 ? (
            <div
              onClick={() =>
                onUpdate(
                  "amount",
                  `${getCurrencyWithPrice(
                    total - splitTotal,
                    shop.currency,
                    false
                  )}`
                )
              }
            >
              {getCurrencyWithPrice(total - splitTotal, shop.currency, false)}
            </div>
          ) : null}
          {paymentValues.map((value) => (
            <div onClick={() => onUpdate("amount", `${value}`)}>
              {getCurrencyWithPrice(value, shop.currency, false)}
            </div>
          ))}
          <div onClick={() => onUpdate("amount", `${total}`)}>Exact</div>
        </PaymentAmounts>
      </>
    );
  }

  return (
    <>
      <div>{f({ id: "SPLIT_BILL.CARD_PAYMENT" })}</div>
      <PaymentAmountInputs>
        <div>
          <div>{f({ id: "SPLIT_BILL.CARD_AMOUNT" })}</div>
          <input
            value={split.amount}
            onChange={(event) => onUpdate("amount", event.target.value)}
          />
        </div>
        <div>
          <div>{f({ id: "SPLIT_BILL.LAST_FOUR" })}</div>
          <input
            value={split.last4}
            onChange={(event) => onUpdate("last4", event.target.value)}
          />
        </div>
      </PaymentAmountInputs>
      <PaymentAmounts>
        {total - splitTotal > 0 ? (
          <div
            onClick={() =>
              onUpdate(
                "amount",
                `${getCurrencyWithPrice(
                  total - splitTotal,
                  shop.currency,
                  false
                )}`
              )
            }
          >
            {getCurrencyWithPrice(total - splitTotal, shop.currency, false)}
          </div>
        ) : null}
      </PaymentAmounts>
    </>
  );
};
