import React, { useState } from "react";
import { Row, Col } from "react-grid-system";
import SystemSettings from "./SystemSettings";
import { connect } from "react-redux/es/index";
import Wifi from "../../../components/settings/views/Wifi";
import WifiZone from "../../../components/settings/views/WifiZone";
import Register from "../../../components/settings/views/Register";
import { SettingsContainer, SettingsDataContainer } from "./Styled";
import CashDrawer from "../../../components/settings/views/CashDrawer";
import PrinterSetup from "../../../components/settings/views/PrinterSetup";
import UserInformation from "../../../components/profile/views/UserInformation";
import ServiceSubHeader from "../../../components/headers/Views/ServiceSubHeader";
import WifiPasswordModal from "../../../components/model/views/WifiPasswordModal";
import SettingsSideNav from "../../../components/sideNavBars/views/SettingsSideNav";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";

const Settings = (props: any) => {
  const { networksList } = props;
  const { wifiList, currentWifi } = networksList;

  const [isChecked, setIsChecked] = useState(true);
  const [joinedWifi, setJoinedWifi] = useState({});
  const [selectedWifi, setSelectedWifi] = useState({});
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [selectedSidebar, setSelectedSidebar] = useState("PROFILE");

  const onClickJoin = () => {
    setJoinedWifi(1);
  };

  const onClickToggle = (value) => {
    setIsChecked(value);
  };

  const onClickSelectedWifi = (item) => {
    onToggleVisiblePassword();
    setSelectedWifi(item);
  };

  const onClickSideBar = (type) => {
    setSelectedSidebar(type);
  };

  const onToggleVisiblePassword = () => {
    setVisiblePassword(!visiblePassword);
  };

  const renderSidebarValue = () => {
    switch (selectedSidebar) {
      case "PROFILE":
        return <UserInformation />;
      // case "WIFI":
      //   return (
      //     <>
      //       <Wifi
      //         checked={isChecked}
      //         onClickToggle={(value) => {
      //           onClickToggle(value);
      //         }}
      //       />
      //       <SettingsDataContainer>
      //         {isChecked
      //           ? wifiList?.map((item) => {
      //               return (
      //                 <WifiZone
      //                   data={item}
      //                   wifiList={wifiList}
      //                   currentWifi={currentWifi}
      //                   joinedWifi={joinedWifi}
      //                   onClickSelectedWifi={() => onClickSelectedWifi(item)}
      //                 />
      //               );
      //             })
      //           : null}
      //       </SettingsDataContainer>
      //     </>
      //   );
      case "PRINTER_SETUP":
        return (
          <SettingsDataContainer>
            <PrinterSetup />
          </SettingsDataContainer>
        );
      case "SYSTEM_SETTINGS":
        return <SystemSettings />;
      case "CASH_DRAWER":
        return (
          <SettingsDataContainer>
            <CashDrawer />
          </SettingsDataContainer>
        );
      case "REGISTER":
        return (
          <SettingsDataContainer>
            <Register />
          </SettingsDataContainer>
        );
      default: {
        return <div />;
      }
    }
  };

  return (
    <SettingsContainer>
      <TopNavigationBar viewName="Settings" path="/menu" />
      <div>
        <div>
          <Row style={{ margin: 0 }}>
            <Col style={{ padding: 0 }} xs={12}>
              <ServiceSubHeader header={"Settings"} />
            </Col>
          </Row>
          <Row style={{ margin: 0 }}>
            <Col style={{ padding: 0 }} xs={2}>
              <SettingsSideNav
                selectedSidebar={selectedSidebar}
                onClick={(type) => onClickSideBar(type)}
              />
            </Col>
            <Col style={{ padding: 0 }} xs={10}>
              {renderSidebarValue()}
            </Col>
          </Row>
        </div>
      </div>
      <WifiPasswordModal
        type={visiblePassword}
        isOpen={visiblePassword}
        onClickJoin={() => onClickJoin()}
        closeModal={() => onToggleVisiblePassword()}
      />
    </SettingsContainer>
  );
};

export default connect(
  (state: any) => ({
    networksList: state.common.get("networksList"),
  }),
  {}
)(Settings);
