import React, { useCallback, useState } from "react";
import moment from "moment";
import { nanoid } from "nanoid";
import _, { delay, get } from "lodash";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {
  CheckoutButton,
  OrderQueueButton,
  AppointmentDetailContainer,
} from "../../../components/Styles/Styled";
import { Row, Col } from "react-grid-system";
import {
  getTotalPrice,
  getAbsoluteTotal,
  mapPaymentDetails,
  getCurrencyWithPrice,
  getAppointmentTotalPrice,
} from "../../../internal/manager/PaymentManager";
import {
  isValidOrder,
  isValidKitchenOrder,
} from "../../../internal/manager/AppointmentManager";
import { Actions } from "../../../internal/app/Actions";
import { navigate } from "../../../internal/service/Navigation.service";
import { isCanAccess } from "../../../internal/manager/EmployeeManager";
import PrinterRepository from "../../../internal/repos/PrinterRepository";
import AddDiscountModal from "../../../components/model/views/AddDiscountModal";
import CreateVehicleModel from "../../../components/model/views/CreateVehicleModel";
import SelectVehicleModal from "../../../components/model/views/SelectVehicleModal";
import SelectCustomerModal from "../../../components/model/views/SelectCustomerModal";
import SelectEmployeeModal from "../../../components/model/views/SelectEmployeeModal";
import ServiceCalculatorModal from "../../../components/model/views/ServiceCalculatorModel";
import ProductCalculatorModel from "../../../components/model/views/ProductCalculatorModel";
import SelectDateAndTimeModal from "../../../components/model/views/SelectDateAndTimeModal";
import CheckoutDetailsComponent from "../../../components/appointmentDetailsBill/CheckoutDetailsComponent";
import AppointmentDetailsComponent from "../../../components/appointmentDetailsBill/AppointmentDetailsComponent";
import AppointmentDropDownComponent from "../../../components/appointmentDetailsBill/AppointmentDropDownComponent";

const AppointmentDetailsBillView = (props) => {
  const {
    shopData,
    checkout,
    currentUser,
    productList,
    orderOffers,
    batchSession,
    quickCustomer,
    loadingAction,
    createVehicle,
    selectedTable,
    onSelectVehicle,
    selectedVehicle,
    setSendItemData,
    onSelectCustomer,
    onSelectEmployee,
    selectedEmployee,
    selectedCustomer,
    purchaseServices,
    purchaseProducts,
    orderDateAndTime,
    openServiceKeyPad,
    createActivityLog,
    isOpenProductKeypad,
    isOpenServiceKeypad,
    setPurchaseProducts,
    setOrderDateAndTime,
    selectedAppointment,
    appointmentDiscounts,
    removeAppointmentDiscount,
  } = props;
  const [isSelectCheckoutDropDown, setIsSelectCheckoutDropDown] =
    useState(true);
  const [isSelectDetailsDropDown, setIsSelectDetailsDropDown] = useState(false);
  const [isOpenEmployeeModal, setIsOpenEmployeeModal] = useState(false);
  const [isOpenCustomerModal, setIsOpenCustomerModal] = useState(false);
  const [isOpenVehicleModal, setIsOpenVehicleModal] = useState(false);
  const [isOpenSelectVehicleModal, setIsOpenSelectVehicleModal] =
    useState(false);
  const [isOpenDateTimeModal, setIsOpenDateTimeModal] = useState(false);
  const [isOpenDiscountModal, setIsOpenDiscountModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const { formatMessage: f } = useIntl();

  // const setSelectedOrder = async (data: any) => {
  //   setTimeout(() => {
  //     const paymentResult = mapPaymentDetails(data);
  //     onSelectedAppointment(paymentResult);
  //   }, 200);
  // };
  const onOrderQueue = useCallback(
    async (
      totalPrice,
      serviceCharge,
      printReciept = false,
      stopNavigation = false,
      type = null
    ) => {
      let serviceChargeObj: any = null;
      if (serviceCharge?.length > 0 && shopData.systemSettings) {
        serviceChargeObj = {
          amount: `${serviceCharge}`,
          // amountType: shopData.systemSettings[]
        };
      }
      const params = {
        shopData,
        totalPrice,
        selectedVehicle,
        purchaseServices,
        selectedEmployee,
        purchaseProducts,
        status: "PENDING",
        isSendSms: false,
        selectedAppointment,
        offerDetails: orderOffers,
        userId: currentUser.empId,
        batchId: batchSession.batchId,
        discounts: appointmentDiscounts,
        date: _.get(orderDateAndTime, "date"),
        time: _.get(orderDateAndTime, "time"),
        recieptId: selectedAppointment.recieptId || nanoid(5),
        tableOrder: selectedTable?.tbId
          ? {
              tbId: selectedTable.tbId,
              areaId: null,
              tbNumber: selectedTable.tbNumber,
            }
          : null,
        serviceCharge: serviceChargeObj,
        selectedCustomer: !_.isEmpty(selectedCustomer)
          ? selectedCustomer
          : quickCustomer,
        stopNavigation: false,
      };

      if (printReciept) {
        const subTotal = getTotalPrice([
          ...purchaseServices,
          ...purchaseProducts,
        ]);
        PrinterRepository.printReciept({
          params,
          shopData,
          subTotal,
          preview: true,
          givenPrice: 0,
          selectedCustomer,
          purchaseProducts,
          purchaseServices,
          total: totalPrice,
          appointmentDiscounts,
        });
      }
      const orderData = await mapPaymentDetails(params);
      const activityLog = {
        logId: nanoid(),
        shopId: shopData.shopId,
        createdTime: Date.now(),
        updatedTime: Date.now(),
        empId: selectedEmployee.empId,
        actType: type === "ORDER_QUEUE" ? "ADD_TO_QUEUE" : type,
        metaData: [
          {
            key: "ORDER",
            value: orderData,
          },
          {
            key: "EMPLOYEE",
            value: selectedEmployee,
          },
        ],
      };
      createActivityLog(activityLog);
      checkout(params, null, stopNavigation, type);
    },
    [
      shopData,
      selectedTable,
      selectedEmployee,
      appointmentDiscounts,
      currentUser,
      batchSession,
      purchaseServices,
      purchaseProducts,
      selectedAppointment,
      selectedCustomer,
    ]
  );

  const appointmentDate = !_.isEmpty(orderDateAndTime)
    ? moment(orderDateAndTime.date).format("dddd DD, YYYY") +
      " " +
      orderDateAndTime.time
    : "";

  // Send to kitchen
  const sendToKitchen = async (totalPrice, serviceCharge) => {
    let shouldSendToKitchen = false;
    let shouldSendToBar = false;
    let sendToKitchenData: any = [];

    const products = purchaseProducts.map((p: any, index: number) => {
      const prod = productList.find(({ pid }) => p.id === pid);
      if (prod) {
        const sendPrepTicket = purchaseProducts[index].sendPrepTicket || [];
        let sentQty = sendPrepTicket.reduce((acc, { quantity }) => {
          return acc + quantity;
        }, 0);
        const quantity = p.quantity - sentQty;
        const prepObject = {
          quantity,
          sendToBar: get(prod, "sendToBar", false),
          sendToKitchen: get(prod, "sendToKitchen", false),
        };
        if (quantity > 0) {
          sendPrepTicket.push(prepObject);
          sendToKitchenData.push(prepObject);
        }
        purchaseProducts[index] = {
          ...p,
          sendPrepTicket,
          prepQuantity: quantity,
          sendToBar: get(prod, "sendToBar", false),
          sendToKitchen: get(prod, "sendToKitchen", false),
        };

        if (prod.sendToKitchen && quantity > 0) {
          shouldSendToKitchen = true;
        }
        if (prod.sendToBar && quantity > 0) {
          shouldSendToBar = true;
        }
      }
      return purchaseProducts[index];
    });

    if (!shouldSendToKitchen && !shouldSendToBar) {
      return;
    }

    const params = {
      shopData,
      totalPrice,
      selectedVehicle,
      appointmentDate: !_.isEmpty(orderDateAndTime)
        ? moment(orderDateAndTime.date).format("DD/MM/YYYY") +
          " " +
          orderDateAndTime.time
        : "",
      purchaseServices,
      selectedEmployee,
      purchaseProducts: products,
      status: "PENDING",
      tableOrder: selectedTable
        ? {
            tbId: selectedTable.tbId,
            areaId: null,
            tbNumber: selectedTable.tbNumber,
          }
        : null,
      selectedAppointment,
      userId: currentUser.empId,
      recieptId: selectedAppointment.recieptId || nanoid(5),
      selectedCustomer: !_.isEmpty(selectedCustomer)
        ? selectedCustomer
        : quickCustomer,
    };
    try {
      if (shouldSendToKitchen) {
        await PrinterRepository.printKitchen(params);
      }
    } catch (e) {
      console.warn({ e });
    }
    delay(async () => {
      try {
        if (shouldSendToBar) {
          await PrinterRepository.printBar(params);
        }
      } catch (e) {
        console.warn({ e });
      }
    }, 2500);
    setSendItemData(sendToKitchenData);
    setPurchaseProducts(products);
    delay(
      () =>
        onOrderQueue(totalPrice, serviceCharge, false, true, "SEND_TO_KITCHEN"),
      50
    );
  };

  let purchaseList = purchaseServices.concat(purchaseProducts);
  let { total: totalPrice, serviceCharge } = getAppointmentTotalPrice(
    purchaseList,
    appointmentDiscounts,
    shopData,
    orderOffers
  );
  const absoluteTotal = getAbsoluteTotal(purchaseList);

  let price = getCurrencyWithPrice(`${totalPrice}`, shopData.currency);

  const isValid = isValidOrder({
    purchaseProducts,
    purchaseServices,
    selectedCustomer,
    quickCustomer,
    selectedEmployee,
    totalPrice,
    appointmentDate,
  });

  const isValidSendToKitchen = isValidKitchenOrder({
    purchaseProducts,
  });

  // const printCheckout = useCallback(() => {
  //   ;
  // }, [totalPrice, serviceCharge]);

  const toggleDropdown = () => {
    setIsSelectCheckoutDropDown(!isSelectCheckoutDropDown);
    setIsSelectDetailsDropDown(!isSelectDetailsDropDown);
  };

  const toggleEmployee = () => {
    setIsOpenEmployeeModal(!isOpenEmployeeModal);
  };

  const toggleCustomer = () => {
    setIsOpenCustomerModal(!isOpenCustomerModal);
  };

  const toggleVehicle = () => {
    setIsOpenVehicleModal(!isOpenVehicleModal);
  };

  const toggleSelectVehicle = () => {
    setIsOpenSelectVehicleModal(!isOpenSelectVehicleModal);
  };

  const toggleDateTime = () => {
    setIsOpenDateTimeModal(!isOpenDateTimeModal);
  };

  const toggleDiscountModal = () => {
    setSelectedItem({});
    setIsOpenDiscountModal(!isOpenDiscountModal);
  };

  const onSelectedEmployee = (employee) => {
    onSelectEmployee(employee);
    toggleEmployee();
  };

  const onSelectedCustomer = (customer) => {
    onSelectCustomer(customer);
    toggleCustomer();
  };

  const onCreateVehicle = (vehicle) => {
    createVehicle(vehicle);
    toggleVehicle();
    toggleSelectVehicle();
  };

  const onSelectedVehicle = (vehicle) => {
    onSelectVehicle(vehicle);
    toggleSelectVehicle();
  };

  const onSelectedDateTime = (dateTime) => {
    setOrderDateAndTime(dateTime);
    toggleDateTime();
  };

  const onDeleteDetails = (type, data = {}) => {
    switch (type) {
      case "CUSTOMER":
        return onSelectCustomer({});
      case "EMPLOYEE":
        return onSelectEmployee({});
      case "VEHICLE":
        return onSelectVehicle({});
      case "DATE":
        return setOrderDateAndTime({});
      case "DISCOUNT":
        return removeAppointmentDiscount(data);
      default:
        return;
    }
  };

  const role = currentUser?.specializations;

  return (
    <AppointmentDetailContainer>
      <Row style={{ margin: 0 }}>
        <Col style={{ padding: 0 }} xs={12}>
          <AppointmentDropDownComponent
            showPrint={true}
            title={f({ id: "ORDER.CHECKOUT_DETAILS" })}
            isSelected={isSelectCheckoutDropDown}
            onClickPrint={() =>
              onOrderQueue(totalPrice, serviceCharge, true, true)
            }
            onSelected={() => toggleDropdown()}
          />
          <AppointmentDropDownComponent
            title={f({ id: "ORDER.DETAILS" })}
            isSelected={isSelectDetailsDropDown}
            onSelected={() => toggleDropdown()}
          />
          {isSelectCheckoutDropDown ? (
            <CheckoutDetailsComponent
              {...{ totalPrice, serviceCharge, absoluteTotal }}
            />
          ) : (
            <AppointmentDetailsComponent
              onClickAddCustomer={() => toggleCustomer()}
              onClickAddEmployee={() => toggleEmployee()}
              onClickAddDateTime={() => toggleDateTime()}
              onClickAddVehicle={() => toggleSelectVehicle()}
              onDeleteDateTime={() => onDeleteDetails("DATE")}
              onClickAddDiscount={() => toggleDiscountModal()}
              onDeleteVehicle={() => onDeleteDetails("VEHICLE")}
              onDeleteCustomer={() => onDeleteDetails("CUSTOMER")}
              onDeleteEmployee={() => onDeleteDetails("EMPLOYEE")}
              onDeleteDiscount={(data) => onDeleteDetails("DISCOUNT", data)}
            />
          )}
        </Col>
      </Row>

      <Row style={{ margin: 0 }}>
        <Col style={{ padding: 0 }} xs={12}>
          {/* <OrderQueueButton
            onClick={() => {
              if (isValid && !loadingAction.loading) {
                onOrderQueue(totalPrice);
              }
            }}
            valid={isValid}
          >
            {f({id: "ORDER.ADD_TO_ORDER_QUEUE"})}
          </OrderQueueButton> */}
          <Row gutterWidth={8}>
            <Col>
              <OrderQueueButton
                onClick={() => {
                  if (isValidSendToKitchen && !loadingAction.loading) {
                    sendToKitchen(totalPrice, serviceCharge);
                  }
                }}
                valid={isValidSendToKitchen}
              >
                {f({ id: "ORDER.SEND_TO_KITCHEN" })}
              </OrderQueueButton>
            </Col>
            <Col>
              <OrderQueueButton
                onClick={() => {
                  if (isValid && !loadingAction.loading) {
                    onOrderQueue(
                      totalPrice,
                      serviceCharge,
                      false,
                      false,
                      "ORDER_QUEUE"
                    );
                  }
                }}
                valid={isValid}
              >
                {f({ id: "ORDER.ADD_TO_ORDER_QUEUE" })}
              </OrderQueueButton>
            </Col>
          </Row>
          {isCanAccess(role, "isCheckout") ? (
            <CheckoutButton
              onClick={() => {
                if (isValid && !loadingAction.loading) {
                  navigate("/checkout");
                }
              }}
              valid={isValid && !loadingAction.loading}
            >
              {f({ id: "ORDER.CHECKOUT" })}
              {` ${price}`}
            </CheckoutButton>
          ) : null}
        </Col>
      </Row>
      {isOpenServiceKeypad ? (
        <ServiceCalculatorModal
          isOpen={isOpenServiceKeypad}
          closeModal={() => openServiceKeyPad(false)}
        />
      ) : null}
      {isOpenProductKeypad ? (
        <ProductCalculatorModel isOpen={isOpenProductKeypad} />
      ) : null}

      <SelectEmployeeModal
        isOpen={isOpenEmployeeModal}
        closeModal={() => setIsOpenEmployeeModal(false)}
        onSelectedEmployee={(employee) => onSelectedEmployee(employee)}
      />

      <SelectCustomerModal
        isOpen={isOpenCustomerModal}
        closeModal={() => setIsOpenCustomerModal(false)}
        onSelectedCustomer={(customer) => onSelectedCustomer(customer)}
      />

      <SelectVehicleModal
        isOpen={isOpenSelectVehicleModal}
        closeModal={() => setIsOpenSelectVehicleModal(false)}
        onSelectedVehicle={(vehicle) => onSelectedVehicle(vehicle)}
        onCreateVehicle={() => {
          setIsOpenSelectVehicleModal(false);
          toggleVehicle();
        }}
      />
      <CreateVehicleModel
        isOpen={isOpenVehicleModal}
        closeModal={() => setIsOpenVehicleModal(false)}
        createVehicle={(vehicle) => onCreateVehicle(vehicle)}
      />

      <SelectDateAndTimeModal
        isOpen={isOpenDateTimeModal}
        closeModal={() => setIsOpenDateTimeModal(false)}
        onSelectedDateTime={(dateTime) => onSelectedDateTime(dateTime)}
      />

      <AddDiscountModal
        isOpen={isOpenDiscountModal}
        closeModal={() => toggleDiscountModal()}
        discountItem={selectedItem}
      />
    </AppointmentDetailContainer>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
    orderOffers: state.offer.get("orderOffers"),
    productList: state.product.get("productList"),
    batchSession: state.login.get("batchSession"),
    selectedTable: state.table.get("selectedTable"),
    loadingAction: state.common.get("loadingAction"),
    quickCustomer: state.customer.get("quickCustomer"),
    selectedVehicle: state.orders.get("selectedVehicle"),
    purchaseProducts: state.product.get("purchaseProducts"),
    purchaseServices: state.service.get("purchaseServices"),
    orderDateAndTime: state.product.get("orderDateAndTime"),
    selectedCustomer: state.customer.get("selectedCustomer"),
    selectedEmployee: state.employee.get("selectedEmployee"),
    selectedEmployees: state.employee.get("selectedEmployees"),
    selectedAppointment: state.orders.get("selectedAppointment"),
    isOpenServiceKeypad: state.service.get("isOpenServiceKeypad"),
    isOpenProductKeypad: state.product.get("isOpenProductKeypad"),
    selectedServiceItem: state.service.get("selectedServiceItem"),
    appointmentDiscounts: state.product.get("appointmentDiscounts"),
  }),
  {
    checkout: Actions.payment.checkout,
    updateVehicle: Actions.orders.updateVehicle,
    createVehicle: Actions.orders.createVehicle,
    onSelectVehicle: Actions.orders.onSelectVehicle,
    setSendItemData: Actions.product.setSendItemData,
    onSelectEmployee: Actions.employee.onSelectEmployee,
    onSelectCustomer: Actions.customer.onSelectCustomer,
    openServiceKeyPad: Actions.service.openServiceKeyPad,
    selectedDateAndTime: Actions.salon.selectedDateAndTime,
    setOrderDateAndTime: Actions.product.setOrderDateAndTime,
    createActivityLog: Actions.activityLog.createActivityLog,
    setPurchaseProducts: Actions.product.setPurchaseProducts,
    onSelectedAppointment: Actions.orders.selectedAppointment,
    removeAppointmentDiscount: Actions.product.removeAppointmentDiscount,
  }
)(AppointmentDetailsBillView);
