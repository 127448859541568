import React, {Component, useEffect, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import {connect} from "react-redux";
import {Actions} from './../../../internal/app/Actions';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import PhoneInput, {formatPhoneNumberIntl} from 'react-phone-number-input';
import _ from 'lodash';
import {CreateCustomerModelContainer} from '../../Styles/Styled';
import { useIntl } from 'react-intl';


const customStyles = {
  content: {
    top: '10vh',
    outline: 'none',
    overflow: 'auto',
    borderRadius: '5px',
    position: 'absolute',
    left: 'calc(50% - 266px)',
    WebkitOverflowScrolling: 'touch',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },
};

const CreateCustomerModel = (props) => {
  const {isEdit, isOpen, createCustomer, closeModal, shopData, currentUser, selectedCustomer, updateCustomer, deleteCustomer} = props;
  const {formatMessage: f} = useIntl();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('+94');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (isEdit) {
      setFirstName(selectedCustomer.firstName);
      setLastName(selectedCustomer.lastName);
      setMobileNumber(selectedCustomer.mobileNumber);
      setEmail(selectedCustomer.email);
    }else {
      initialState();
    }
  }, [isEdit, selectedCustomer]);

  const initialState = ()=> {
    setEmail('');
    setLastName('');
    setFirstName('');
    setMobileNumber('');
  };

  const onCreateCustomer = () => {
    const number = formatPhoneNumberIntl(mobileNumber);
    const splits = _.split(number, ' ');
    const name = `${firstName} ${lastName}`;
    const param = {
      shopId: shopData.shopId,
      firstName,
      lastName,
      mobileNumber,
      email,
      createdUser: currentUser.empId,
      countryCode: splits[0],
      name
    };
    if (isEdit) {
      param.id = selectedCustomer.id;
      param.shopId = selectedCustomer.shopId;
      param.createdUser = selectedCustomer.createdUser;
      updateCustomer(param);
    } else {
      createCustomer(param);
    }
    closeModal();
    initialState();
  };

  const onClickDelete = () => {
    deleteCustomer(selectedCustomer.id, selectedCustomer.shopId);
    closeModal();
    initialState();
  };

  const isValid = !_.isEmpty(firstName) && !_.isEmpty(lastName);

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.createCustomerModal}
    >
      <CreateCustomerModelContainer isValid={isValid}>
        <div>
          <div>{f({id: "CUSTOMER.CREATE.CREATE_CUSTOMER"})}</div>
          <img onClick={() => closeModal()} alt="icon" src={CANCEL}/>
        </div>

        <div>{f({id: "CUSTOMER.CREATE.CUSTOMER_INFO"})}</div>

        <div>
          <div>
            <div>{f({id: "CUSTOMER.CREATE.FIRST_NAME"})} :</div>
            <input
              type="text"
              value={firstName}
              placeholder="John"
              onChange={(text) => setFirstName(text.target.value)}
            />
          </div>

          <div>
            <div>{f({id: "CUSTOMER.CREATE.LAST_NAME"})} :</div>
            <input
              type="text"
              value={lastName}
              placeholder="Doe"
              onChange={(text) => setLastName(text.target.value)}
            />
          </div>

          <div>
            <div>{f({id: "CUSTOMER.CREATE.MOBILE_NUMBER"})} :</div>
            <div>
              <PhoneInput
                defaultCountry="LK"
                value={mobileNumber}
                placeholder="+94123456789"
                onChange={phone => setMobileNumber(phone)}
              />
            </div>
          </div>
          <div>
            <div>{f({id: "CUSTOMER.CREATE.EMAIL"})} :</div>
            <input
              type="text"
              value={email}
              placeholder="abc@gmail.com"
              onChange={(text) => setEmail(text.target.value)}
            />
          </div>
        </div>

        <div>
          {
            isEdit ?
              <button onClick={() => onClickDelete()}>{'Delete'}</button>
              : <div/>
          }
          <div>
            <div onClick={() => {
              if (isValid) {
                onCreateCustomer()
              }
            }}>
              <div>{isEdit ? f({id: "CUSTOMER.CREATE.EDIT"}) : f({id: "CUSTOMER.CREATE.ADD_CUSTOMER"})}</div>
            </div>
            <div onClick={() => closeModal()}>
              <div className={styles.cancelBtn}>{f({id: "CUSTOMER.CREATE.CANCEL"})}</div>
            </div>
          </div>
        </div>
      </CreateCustomerModelContainer>
    </ReactModal>
  );
};
export default connect((state) => ({
    shopData: state.login.get('merchantShop'),
    currentUser: state.login.get('currentUser'),
    selectedCustomer: state.customer.get('selectedCustomer'),
  }),
  {
    createCustomer: Actions.customer.createCustomer,
    updateCustomer: Actions.customer.updateCustomer,
    deleteCustomer: Actions.customer.deleteCustomer
  })(CreateCustomerModel);

