import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-grid-system';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import { TimePicker } from 'antd';
import moment from 'moment';
import {connect} from "react-redux/es/index";
import '../Styles.style.css';
import {Actions} from "../../../internal/app/Actions";
import Select from 'react-select';
import {mapCategory, mapDuration, mapCreateService, mapUpdateService, mapDeleteService} from '../../../internal/manager/CategoryManager';
import EditServiceTopHeader from '../../../components/services/views/EditServiceTopHeader';
import _ from "lodash";
import ServiceWarningModal from '../../../components/model/views/ServiceWarningModal';
import {navigate} from "../../../internal/service/Navigation.service";
import ImageUploadCard from "../../../components/card/ImageUploadCard";
import CreateCategoryModel from "../../../components/model/views/CreateCategoryModel";

const style = {
  control: (base, state) => ({
    ...base,
    height: '45px',
    width: '544px',
    paddingRight: "20px",
    border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    '&:hover': {
      border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea"
    },
    borderRadius: "6px",
    color: "#434343",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    paddingLeft: "5px"
  })
};

const ServiceAddView = (props) => {
  const { categories, updateServiceCategory, selectedCategoryService, serviceImageId, uploadServiceImage,
    removeServiceImage, loadingAction, merchantShop, fetchCategories } = props;

  const [serviceTitle, setServiceTitle] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState('');
  const [description, setDescription] = useState('');
  const [isOpenDiscardModel, setIsOpenDiscardModel] = useState(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);

  useEffect(() => {
    const {service, category} = selectedCategoryService;
    if (!_.isEmpty(service)) {
      setServiceTitle(service.serviceTitle);
      setPrice(service.price);
      setDescription(service.description);

      let categoryData = _.cloneDeep(category);
      categoryData.value =  category.category;
      categoryData.label = category.category;
      setSelectedCategory(categoryData);
      if (service && service.duration) {
        setDuration(moment(mapDuration(service.duration), '"HH:mm"'))
      }
    }
    fetchCategories(merchantShop.shopId);
  }, []);

  const {loading} = loadingAction;

  const onCreateService = () => {
    const param = mapCreateService(categories, selectedCategory, duration, price,
      serviceTitle, description, serviceImageId);

    updateServiceCategory(param);
  };

  const onUpdateService = () => {
    const param = mapUpdateService(selectedCategoryService, categories, selectedCategory, duration,
      price, serviceTitle, description, serviceImageId);

    updateServiceCategory(param);
  };

  const onDeleteService = () => {
    const param = mapDeleteService(selectedCategoryService, categories);

    updateServiceCategory(param);
  };

  const mapCategoryList = mapCategory(categories?.categories || []);
  const isValid = !_.isEmpty(serviceTitle) && !_.isEmpty(selectedCategory) && !_.isEmpty(price.toString()) && !_.isEmpty(duration);
  const isEdit = !_.isEmpty(selectedCategoryService);

  const toggleCreateCategory = ()=> {
    setIsOpenCategoryModal(!isOpenCategoryModal)
  };

  return (
    <div className="addCategoryMainContainer">
      <TopNavigationBar viewName="Services" path="/menu"/>
      <div className="addServiceViewContainer">
        <div className="addServiceViewWrapper">
          <EditServiceTopHeader
            onCreateService={() => {
              if (isValid && !loading) {
                if (isEdit) {
                  onUpdateService();
                } else {
                  onCreateService();
                }
              }
            }}
            isEdit={isEdit}
            onBack={() => {
              if (isEdit) {
                setIsOpenDiscardModel(true);
              } else {
                navigate('/services-customized');
              }
            }}
            isValid={isValid}
          />
          <Row>
            <Col xs={12}>
              <div className="serviceAddFormContainer">
                <div className="addServiceDetailsText">
                  Details
                </div>
                <div className="addServiceInputContainer">
                  <Row>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Service Title*
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input onChange={(text) => setServiceTitle(text.target.value)} value={serviceTitle} className='serviceTextInput' placeholder="Service Title"/>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '10px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Category*
                      </div>
                    </Col>
                    <Col xs={7} className="serviceTitleColInput">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        onChange={(data)=> setSelectedCategory(data)}
                        styles={style}
                        value={selectedCategory}
                        placeholder={<div className="categoryPlaceholderText">Category</div>}
                        name="color"
                        options={mapCategoryList}
                      />
                    </Col>
                    <Col xs={3}>
                      <div className="addCategoryBtnContainer">
                        <div onClick={()=> toggleCreateCategory()} className="addCategoryBtnText">
                          Add Category
                        </div>
                      </div>
                    </Col>
                    <Col xs={2}/>
                  </Row>
                  <Row style={{marginTop: '25px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Price*
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input onChange={(text) => setPrice(text.target.value)} value={price} className='serviceTextInput' placeholder="Price" />
                    </Col>
                  </Row>
                  <Row style={{marginTop: '15px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Duration*
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <TimePicker
                        format={"HH:mm"}
                        onChange={(text)=> setDuration(text)}
                        placeholder="Duration"
                        value={duration}
                        className={'timePickerServiceStyle'}
                      />
                    </Col>
                  </Row>
                  <Row style={{marginTop: '15px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Image
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <ImageUploadCard
                        imageId={serviceImageId}
                        onRemoveImage={() => removeServiceImage()}
                        loadingAction={loadingAction}
                        onUploadProductImage={(file) => uploadServiceImage(file)}
                      />
                    </Col>
                  </Row>
                  <Row style={{marginTop: '20px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Description
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <textarea onChange={(text) => setDescription(text.target.value)} value={description} className='serviceTextAreaInput' placeholder="Description"/>
                    </Col>
                  </Row>
                  {
                    isEdit ?
                      <Row style={{marginTop: '20px'}}>
                        <Col xs={2} className="serviceTitleCol" />
                        <Col xs={10} className="serviceTitleColInput">
                          <div onClick={() => setIsOpenDeleteModel(true)} className="serviceDeleteBtnContainer">
                            <div className="serviceDeleteBtnText">
                              Delete Service
                            </div>
                          </div>
                        </Col>
                      </Row>
                      : null
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ServiceWarningModal
        isOpen={isOpenDiscardModel}
        headerText={'Are you sure?'}
        bodText={'All your changes will be discarded'}
        cancelBtnText={'Discard'}
        onCancel={() => {
          setIsOpenDiscardModel(false);
          navigate('/services-customized');
        }}
        closeModal={() => setIsOpenDiscardModel(false)}
      />
      <ServiceWarningModal
        isOpen={isOpenDeleteModel}
        headerText={'Warning!'}
        bodText={'Your service will be deleted and no longer\n available to order for the users'}
        cancelBtnText={'Delete'}
        onCancel={() => {
          onDeleteService();
          setIsOpenDeleteModel(false);
        }}
        closeModal={() => setIsOpenDeleteModel(false)}
      />
      <CreateCategoryModel
        type={'SERVICE'}
        isOpen={isOpenCategoryModal}
        closeModal={() => toggleCreateCategory()}
      />
    </div>
  );
};

export default connect(
  state => ({
    categories: state.service.get('categories'),
    selectedCategoryService: state.service.get('selectedCategoryService'),
    serviceImageId: state.service.get('serviceImageId'),
    loadingAction: state.common.get('loadingAction'),
    merchantShop: state.login.get('merchantShop'),
  }),
  {
    updateServiceCategory: Actions.service.updateServiceCategory,
    uploadServiceImage: Actions.service.uploadServiceImage,
    removeServiceImage: Actions.service.removeServiceImage,
    fetchCategories: Actions.service.fetchCategories,
  }
)(ServiceAddView);
