import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import { connect } from "react-redux";
import { DeleteButton } from "./Styled";
import { Col, Row } from "react-grid-system";
import { isEmpty, get, filter, uniqBy } from "lodash";
import { Actions } from "../../../internal/app/Actions";
import styles from "../../../components/Styles/Styles.css";
import ApprovalView from "../../../modules/orders/views/ApprovalView";
import { isCanAccess } from "../../../internal/manager/EmployeeManager";
import ServiceWarningModal from "../../model/views/ServiceWarningModal";
import { getShopSystemSetting } from "../../../internal/manager/CommonManager";
import { getActiveOfferCount } from "../../../internal/manager/PaymentManager";

const data = [
  {
    label: "Products",
    value: "PRODUCTS",
  },
  {
    label: "Services",
    value: "SERVICES",
  },
  {
    label: "Customer",
    value: "CUSTOMER",
  },
  {
    label: "Employees",
    value: "EMPLOYEES",
  },
  {
    label: "Date & Time",
    value: "DATE_TIME",
  },
  {
    label: "Discounts",
    value: "DISCOUNTS",
  },
  // {
  //   label: "Room",
  //   value: "ROOM",
  // },
  // {
  //   label: "Table",
  //   value: "TABLE",
  // },
  // {
  //   label: "Warranty",
  //   value: "WARRANTY",
  // },
  {
    label: "Offers",
    value: "OFFERS",
  },
];

const AddAppointmentSideNav = (props) => {
  const {
    offer,
    onClick,
    shopData,
    deleteOrder,
    currentUser,
    selectedView,
    purchaseProducts,
    selectedEmployee,
    createActivityLog,
    selectedAppointment,
  } = props;
  const [isOpenApprovalModal, setIsOpenApprovalModal] =
    useState<boolean>(false);
  const [sendPrepItem, setSendPrepItem] = useState<number>(0);
  const [offerValidCount, setOfferValidCount] = useState<number>(0);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [navigationList, setNavigationList] = useState<Array<any>>([...data]);

  useEffect(() => {
    if (!isEmpty(offer.offers)) {
      const validCount = getActiveOfferCount(offer.offers, purchaseProducts);
      setOfferValidCount(validCount);
    }
  }, [purchaseProducts]);
  
  useEffect(() => {
    if (shopData) {
      let newNavigation = [...navigationList];
      const category = get(shopData, "shopCategory", "");
      const shopSetting = getShopSystemSetting(shopData);
      switch (category) {
        case "RETAILS":
          if (shopSetting.isWarranty) {
            newNavigation = [
              ...navigationList,
              {
                label: "Warranty",
                value: "WARRANTY",
              },
            ];
          }
          newNavigation = [...uniqBy(newNavigation, "value")];
          return setNavigationList([...newNavigation]);
        case "HOSPITALITY":
          if (shopSetting.isQueueRooms) {
            newNavigation = [
              ...navigationList,
              {
                label: "Room",
                value: "ROOM",
              },
            ];
          }
          newNavigation = [...uniqBy(newNavigation, "value")];
          return setNavigationList([...newNavigation]);
        case "FOOD_AND_BEVERAGES":
          if (shopSetting.isQueueTables) {
            newNavigation = [
              ...navigationList,
              {
                label: "Table",
                value: "TABLE",
              },
            ];
          }
          newNavigation = [...uniqBy(newNavigation, "value")];
          return setNavigationList([...newNavigation]);
      }
      const filterData = filter(newNavigation, (nav) => {
        if (!shopSetting.isOffers) {
          return nav.value != "OFFERS";
        }
        return nav;
      });
      setNavigationList([...filterData]);
    }
  }, [shopData]);

  const onDelete = async () => {
    const isAceess = isCanAccess(
      currentUser?.specializations,
      "isCanDeleteItem"
    );
    if (isAceess) {
      const confirmed = await swal({
        title: "Are you sure?",
        text: "Order will be permenantly deleted.",
        icon: "warning",
        buttons: [true, true],
        dangerMode: true,
      });
      if (confirmed) {
        const activityLog = {
          logId: nanoid(),
          shopId: shopData.shopId,
          createdTime: Date.now(),
          updatedTime: Date.now(),
          actType: "DELETE_ORDER",
          empId: selectedEmployee.empId,
          metaData: [
            {
              key: "ORDER",
              value: selectedAppointment,
            },
            {
              key: "EMPLOYEE",
              value: selectedEmployee,
            },
          ],
        };
        createActivityLog(activityLog);
        deleteOrder({
          orderId: selectedAppointment.orderId,
          shopId: selectedAppointment.shopId,
        });
      }
    } else {
      let sentQty: any = 0;
      selectedAppointment.productDetails?.map((p: any, index: number) => {
        sentQty = p?.sendPrepTicket.reduce((acc, { quantity }) => {
          return acc + quantity;
        }, 0);
      });
      setSendPrepItem(sentQty);
      toggleDeleteModal();
    }
  };

  const toggleDeleteModal = () => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const removeSelectedProduct = () => {
    deleteOrder({
      orderId: selectedAppointment.orderId,
      shopId: selectedAppointment.shopId,
    });
    setIsOpenApprovalModal(false);
  };

  return (
    <Row
      style={{
        height: "calc(100% - 0px)",
        boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.16)",
        borderTopLeftRadius: "3px",
      }}
    >
      <Col
        style={{
          padding: 0,
          justifyContent: "space-between",
          background: "#f1f1f1",
        }}
        xs={12}
      >
        <ul style={{ height: "calc(100% - 100px)" }}>
          {navigationList.map((item, index) => {
            const isSelected = selectedView === item.value;

            return (
              <li>
                <a
                  style={{
                    borderTopLeftRadius: index === 0 ? "3px" : 0,
                    backgroundColor: isSelected ? "#49a4d4" : "",
                    color: isSelected ? "#FFFFFF" : "#000000",
                  }}
                  onClick={() => onClick(item.value)}
                  className={styles.orderSidenavItemWrapper}
                >
                  <div className={styles.addAppointmentSideNav}>
                    {item.label}
                  </div>
                  {item.value === "OFFERS" && offerValidCount > 0 && (
                    <div
                      style={{
                        color: !isSelected ? "#FFFFFF" : "#000000",
                        backgroundColor: !isSelected ? "#49a4d4" : "#FFFFFF",
                      }}
                      className={styles.orderSidenavOfferCount}
                    >
                      {offerValidCount}
                    </div>
                  )}
                </a>
              </li>
            );
          })}
        </ul>
        {!isEmpty(selectedAppointment) && (
          <DeleteButton onClick={onDelete}>Delete</DeleteButton>
        )}
      </Col>
      <ApprovalView
        onOpenEditModal={() => {}}
        deleteItem={() => {
          removeSelectedProduct();
        }}
        isOpenApprovalModal={isOpenApprovalModal}
        onSetIsOpenApprovalModal={(status: boolean) =>
          setIsOpenApprovalModal(status)
        }
      />
      <ServiceWarningModal
        isOpen={isOpenDeleteModal}
        headerText={"Warning!"}
        bodText={`You have ${sendPrepItem} items sent to the kitchen. Are you sure to delete this order?`}
        cancelBtnText={"Delete"}
        onCancel={() => {
          toggleDeleteModal();
          setIsOpenApprovalModal(true);
        }}
        closeModal={() => toggleDeleteModal()}
      />
    </Row>
  );
};
export default connect(
  (state: any) => ({
    offer: state.offer.get("offer"),
    shopData: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
    purchaseProducts: state.product.get("purchaseProducts"),
    selectedEmployee: state.employee.get("selectedEmployee"),
    selectedAppointment: state.orders.get("selectedAppointment"),
  }),
  {
    deleteOrder: Actions.payment.deleteOrder,
    createActivityLog: Actions.activityLog.createActivityLog,
  }
)(AddAppointmentSideNav);
