import React, { useEffect } from "react";
import { ServiceCategoryCardContainer } from "../../components/Styles/Styled";

const ServiceCategoryCard = (props) => {
  const { onSelectCategory, isSelected, category } = props;

  return (
    <ServiceCategoryCardContainer
      isSelected={isSelected}
      onClick={() => onSelectCategory(category)}
    >
      <div>
        {category.category || category.brandName || category.categoryName}
      </div>
    </ServiceCategoryCardContainer>
  );
};
export default ServiceCategoryCard;
