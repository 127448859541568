import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import { ServiceOptions } from "./Styled";
import { Row, Col, Container } from "react-grid-system";

import ServiceChargeOptions from "./ServiceChargeOptions";

const Settings = [
  {
    value: "SERVICE_CHARGES",
    label: "Service Charges",
  },
];

const SystemSettings = ({}) => {
  const [selected, setSelected] = useState<string>(Settings[0].value);

  return (
    <Container style={{ padding: 0 }}>
      <Row>
        <Col xs={3}>
          <ServiceOptions>
            {Settings.map(({ label, value }) => (
              <li
                key={value}
                onClick={() => setSelected(value)}
                className={selected === value ? "selected" : ""}
              >
                <a>{label}</a>
              </li>
            ))}
          </ServiceOptions>
        </Col>
        <Col xs={9}>
          <ServiceChargeOptions />
        </Col>
      </Row>
    </Container>
  );
};

export default connect(
  (state) => ({
    printers: state.common.get("printers"),
  }),
  {}
)(SystemSettings);
