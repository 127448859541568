import React from 'react';
import _ from 'lodash';
import {getTotalPrice} from "../../internal/manager/ProductManager";
import styles from './Styles.css';

const StockPriceCard = ({stockList}) => {
  const validList = _.filter(stockList, item => item.totalPrice > 0);
  const total = getTotalPrice(validList);
  return (
    <div className={styles.stockPriceCardContainer}>
      <div className={styles.stockTotalText}>
        Stock Total
      </div>
      <div className={styles.stockTotalPriceValue}>
        {total > 0 ? (Math.round(total * 100) / 100).toFixed(2) : "0.00"}
      </div>
    </div>
  );
};
export default StockPriceCard;
