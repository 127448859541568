import React from 'react';
import {List, Map} from 'immutable';
import {ModuleEvents} from './Actions';
import {ModuleEvents as PaymentModule} from "../payment/Actions";
import _ from 'lodash';
import { AnyMessageParams } from 'yup/lib/types';

const initialState = Map({
  serviceCategoryList: [],
  serviceList: [],
  isOpenServiceKeypad: false,
  purchaseServices: <any>[],
  selectedServiceItem: {},
  chossedServiceItem: {},
  categories: {},
  selectedCategoryService: {},
  selectedServiceSideBarView: 'ALL_SERVICES',
  selectedCategoryItem: {},
  selectedCategory: {},
  serviceImageId: ''
});

export const Reducer = (state = initialState, action) => {
  const {payload, type, error} = action;

  switch (action.type) {
    case ModuleEvents.FETCH_CATEGORIES: {
      const selectedCategory = state.get('selectedCategory');
      if (payload && !payload.error) {
        let sortResult = _.orderBy(_.get(payload, 'categories', []), ['createdTime'], ['desc'],);
        payload.categories = sortResult;

        if (!_.isEmpty(selectedCategory)) {
          const index = _.findIndex(sortResult, item => item.id === selectedCategory.id);
          if (index > -1) {
            return state.set('categories', payload).set('selectedCategory', {...sortResult[index]});
          }
        }

        return state.set('categories', payload).set('selectedCategory', sortResult[0]);
      }
      return state;
    }

    case ModuleEvents.UPLOAD_SERVICE_IMAGE: {
      if (payload && !payload.error) {
        return state.set('serviceImageId', payload);
      }

      return state;
    }

    case ModuleEvents.REMOVE_SERVICE_IMAGE: {
      return state.set('serviceImageId', '');
    }

    case ModuleEvents.SEARCH_CATEGORIES: {
      if (payload && !payload.error) {
        return state.set('selectedCategory', {...payload});
      }
      return state;
    }

    case ModuleEvents.SELECT_CATEGORY: {
      return state.set('selectedCategory', {...payload});
    }

    case ModuleEvents.NAVIGATE_EDIT_CATEGORY_VIEW: {
      return state.set('selectedCategoryItem', {...payload});
    }

    case ModuleEvents.CLEAR_SELECTED_SERVICE_CATEGORY: {
      return state.set('selectedCategoryService', {}).set('selectedCategoryItem', {}).set('serviceImageId', '');
    }

    case ModuleEvents.SELECT_SERVICE_SIDE_BAR_VIEW: {
      return state.set('selectedServiceSideBarView', payload);
    }

    case ModuleEvents.NAVIGATE_EDIT_SERVICE_VIEW: {
      const categoryList = state.get('categories');
      let { service, category } = payload;
      const index = _.findIndex(categoryList.categories, item => item.id === category.id);
      if (index > -1) {
        payload.category = categoryList.categories[index];
      }

      return state.set('selectedCategoryService', {...payload}).set('serviceImageId', service.image);
    }


    case ModuleEvents.CHANGE_SERVICE_QUANTITY: {
      const {data, status} = payload;
      const purchaseServices = state.get('purchaseServices');
      if (data && status) {
        const index = _.findIndex(purchaseServices, item => item.id === data.id);
        if (index > -1) {
          let itemQuantity = purchaseServices[index].quantity;
          let price = purchaseServices[index].price;
          let servicePrice = purchaseServices[index].servicePrice;
          purchaseServices[index].quantity = status === "INCREMENT" ? ++itemQuantity : itemQuantity > 0 ? --itemQuantity : itemQuantity;
          purchaseServices[index].price = status === "INCREMENT" ? price + servicePrice : price >= servicePrice ? price - servicePrice : price;
        }
        return state.set('purchaseServices', [...purchaseServices]);
      }

      return state;
    }

    case PaymentModule.CLEAR_CURRENT_APPOINTMENT: {
      return state.set('purchaseServices', [])
        .set('selectedServiceItem', {})
        .set('chossedServiceItem', {})
    }

    case ModuleEvents.REMOVE_SELECTED_PRODUCT: {
      return state.set('selectedService', {}).set('isOpenServiceKeypad', false);
    }

    case ModuleEvents.SELECT_SERVICE_ITEM: {
      return state.set('chossedServiceItem', payload);
    }

    case ModuleEvents.SET_PURCHASE_SERVICE: {
      const purchaseServices = state.get('purchaseServices');
      if (payload) {
        payload.forEach((service) => {
          const index = _.findIndex(purchaseServices, item => item.id === service.id);
          if (index > -1) {
            purchaseServices[index].price = service.price;
            purchaseServices[index].quantity = service.quantity;
            purchaseServices[index].servicePrice = service.servicePrice;
            purchaseServices[index].symbol1 = service.symbol1;
            purchaseServices[index].symbol2 = service.symbol2;
            purchaseServices[index].tax = service.tax;
          } else {
            purchaseServices.push(service);
          }
        });
        return state.set('purchaseServices', [...purchaseServices]);
      }
      return state;
    }

    case ModuleEvents.DELETE_PURCHASE_SERVICE: {
      const purchaseServices = state.get('purchaseServices');
      if (payload) {
        const index = _.findIndex(purchaseServices, item => item.id === payload.id);
        if (index > -1) {
          purchaseServices.splice(index, 1);
        }
      }
      return state.set('purchaseServices', [...purchaseServices]);
    }

    case ModuleEvents.SELECT_SERVICE: {
      if (payload) {
        return state.set('selectedServiceItem', payload).set('isOpenServiceKeypad', true);
      }

      return state;
    }

    case ModuleEvents.UPDATE_SERVICE_CATEGORY: {
      const purchaseServices = state.get('purchaseServices');
      if (payload.result && !payload.result.error && payload.type === 'ADD_QUICK_SERVICE') {
        const category = _.find(payload.result.categories, (category) => category.id === payload.serviceData.categoryId);
        const service = _.find(category.services, (service) => service.id === payload.serviceData.id);
        const params = {
          tax: 0,
          quantity: 1,
          symbol1: '',
          symbol2: '',
          id: service.id,
          type: "SERVICE",
          enteredNumber1: '',
          enteredNumber2: '',
          price: service.price,
          duration: service.duration,
          servicePrice: service.price,
          serviceTitle: service.serviceTitle
        };
        purchaseServices.push(params);
        return state.set('purchaseServices', [...purchaseServices]);
      }
      return state;
    }

    case ModuleEvents.CREATE_SERVICE_CATEGORY: {
      if (payload.result && !payload.result.error){
        return state.set('categories', payload.result)
          .set('selectedCategory', payload.result.categories[0]);
      }
      return state;
    }


    case ModuleEvents.OPEN_SERVICE_KEYPAD: {
      return state.set('isOpenServiceKeypad', payload);
    }

    default:
      return state;
  }
};

export default Reducer;
