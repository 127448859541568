import React, { useEffect, useState } from "react";
import {
  CloseCashButton,
  SummaryContainer,
  CloseCashReceiptButton,
} from "./Styled";
import "./Styles.css";
import moment from "moment";
import { nanoid } from "nanoid";
import { connect } from "react-redux";
import { isArray, startCase } from "lodash";
import { Actions } from "../../../internal/app/Actions";
import LogoutModal from "../../../components/model/views/LogoutModal";
import { getSalesSummary } from "../../../internal/manager/CloseCashManager";
import { getCurrencyWithPrice } from "../../../internal/manager/PaymentManager";

const CloseCashDetails = (props) => {
  const {
    closeCash,
    merchantShop,
    batchSummary,
    batchSession,
    type = "SALES",
    selectedEmployee,
    batchOrderSummary,
    createActivityLog,
  } = props;
  const [time, setTime] = useState("10:00 AM - 11:00 PM");
  const [date, setDate] = useState(moment().format("MMM DD, YYYY"));
  const [commission, setCommission] = useState(0);
  const [summary, setSummary] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [filterType, seFilterType] = useState("");

  useEffect(() => {
    seFilterType(type);
  }, [type]);

  const toggleLogout = () => {
    setIsOpen(!isOpen);
  };

  const createCloseCash = (newBatch) => {
    const params = {
      isCreateNewBatch: newBatch,
      batchId: batchSession.batchId,
    };
    const activityLog = {
      logId: nanoid(),
      actType: "CLOSE_CASH",
      createdTime: Date.now(),
      updatedTime: Date.now(),
      shopId: merchantShop.shopId,
      empId: selectedEmployee.empId,
      metaData: [
        {
          key: "BATCH_ID",
          value: batchSession.batchId,
        },
        {
          key: "EMPLOYEE",
          value: selectedEmployee,
        },
      ],
    };
    createActivityLog(activityLog);
    closeCash(params);
    toggleLogout();
  };

  const renderButton = (type) => {
    switch (type) {
      case "SALES":
        return (
          <CloseCashButton valid={true} onClick={() => toggleLogout()}>
            <div>{"Close Cash"}</div>
          </CloseCashButton>
        );
      default:
      case "REPORTS": {
        return (
          <CloseCashReceiptButton Click={() => {}}>
            <div>{"Print Receipt"}</div>
          </CloseCashReceiptButton>
        );
      }
    }
  };

  useEffect(() => {
    const serviceSummary = getSalesSummary(batchSummary, merchantShop);
    setSummary(serviceSummary);
  }, [batchSummary]);

  let totalServiceQty = 0,
    totalService = 0,
    totalProductQty = 0,
    totalProducts = 0,
    totalDiscounts = 0,
    totalProductPrice = 0;

  return (
    <SummaryContainer valid={true}>
      <div
        id="close-cash-summary"
        style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
      >
        <div className="close-cash-merchantShop-summary">
          <div className="close-cash-salon-name">{merchantShop.shopName}</div>
          <div className="close-cash-summary-line" />
        </div>
        <div className="close-cash-date-wrapper">
          <div>
            <div>{"Date"}</div>
            <div>{date}</div>
          </div>
          {/*<div>*/}
          {/*  <div>{'Time'}</div>*/}
          {/*  <div>{time}</div>*/}
          {/*</div>*/}
        </div>

        <div className="salesSummaryWrapper">
          <div className="salesSummaryText">{"Drawer Summary"}</div>
          <div className="salesSummaryWrapperInfo">
            <div>Starting Cash</div>
            <div>{summary.startingCash}</div>
          </div>
          <div className="salesSummaryWrapperInfo">
            <div>Cash Sales</div>
            <div>{summary.cashSales}</div>
          </div>
          <div className="salesSummaryWrapperInfo">
            <div>Card Sales</div>
            <div>{summary.cardSales}</div>
          </div>
          <div className="salesSummaryWrapperInfo">
            <div>{"Refunds"}</div>
            <div>{summary.refunds}</div>
          </div>
          <div className="salesSummaryWrapperInfo">
            <div>{"Total Sale"}</div>
            <div>{summary.totalSale}</div>
          </div>
          <div className="salesSummaryWrapperInfo">
            <div>{"Cash In/Out"}</div>
            <div>{summary.cashInOut}</div>
          </div>
          <div className="salesSummaryWrapperInfo">
            <div>{"Drawer Expected Amount"}</div>
            <div>{summary.expectedAmount}</div>
          </div>
          <div className="salesSummaryWrapperInfo">
            <div>{"Drawer Actual Amount"}</div>
            <div>{summary.expectedAmount}</div>
          </div>
          <div className="salesSummaryWrapperInfo">
            <div>{"Net Amount"}</div>
            <div>{summary.totalAmount}</div>
          </div>

          <div className="salesSummaryText">Sales Summary</div>
          {summary.salesCount !== 0 ? (
            <div className="salesSummaryWrapperInfo">
              <div>{`Sales x ${summary.salesCount}`}</div>
              <div>{summary.salesPrice}</div>
            </div>
          ) : null}
          {summary.productsCount !== 0 ? (
            <div className="salesSummaryWrapperInfo">
              <div>{`Products x ${summary.productCount}`}</div>
              <div>{summary.productPrice}</div>
            </div>
          ) : null}

          <div className="salesSummaryWrapperInfo">
            <div>{"Commission"}</div>
            <div>{commission}</div>
          </div>
          <div className="salesSummaryWrapperInfo">
            <div>{"Gross Sales"}</div>
            <div>{summary.grossPrice}</div>
          </div>

          <div className={"salesSummaryPaymentCashWrapper"}>
            <div className="salesSummaryWrapperInfo">
              <div>{`Cash Payments`}</div>
              <div>{summary.cashSales}</div>
            </div>
            <div className="salesSummaryWrapperInfo">
              <div>{"Card Payments"}</div>
              <div>{summary.cardSales}</div>
            </div>
          </div>
          <div className="salesSummaryText">Product Summary</div>
          {isArray(batchOrderSummary?.productSummary) &&
          batchOrderSummary.productSummary.length > 0 ? (
            <>
              {batchOrderSummary?.productSummary.map(
                ({ productName, quantity, productPrice }) => {
                  totalProductQty += quantity;
                  totalProducts += productPrice;
                  return (
                    <div className="salesSummaryWrapperInfo">
                      <div>{startCase(productName)}</div>
                      {/* <div>
                        {getCurrencyWithPrice(
                          productPrice,
                          merchantShop.currency,
                          false
                        )}
                      </div> */}
                      <div>{quantity}</div>
                    </div>
                  );
                }
              )}
              <div className="salesSummaryWrapperInfo">
                <div>{`Total Products`}</div>
                <div>{totalProductQty}</div>
                {/* <div>
                  {getCurrencyWithPrice(
                    totalProducts,
                    merchantShop.currency,
                    false
                  )}
                </div> */}
              </div>
            </>
          ) : (
            <div className="salesSummaryWrapperInfo">
              <div>** No Products **</div>
            </div>
          )}
          <div className="salesSummaryText">Service Summary</div>
          {isArray(batchOrderSummary?.serviceSummary) &&
          batchOrderSummary?.serviceSummary.length > 0 ? (
            <>
              {batchOrderSummary?.serviceSummary.map(
                ({ serviceName, quantity, servicePrice }) => {
                  totalServiceQty += quantity;
                  totalService += servicePrice;
                  return (
                    <div className="salesSummaryWrapperInfo">
                      <div>{startCase(serviceName)}</div>
                      <div>{quantity}</div>
                      {/* <div>
                        {getCurrencyWithPrice(
                          servicePrice,
                          merchantShop.currency,
                          false
                        )}
                      </div> */}
                    </div>
                  );
                }
              )}
              <div className="salesSummaryWrapperInfo">
                <div>{`Total Services`}</div>
                <div>{totalServiceQty}</div>
                {/* <div>
                  {getCurrencyWithPrice(
                    totalService,
                    merchantShop.currency,
                    false
                  )}
                </div> */}
              </div>
            </>
          ) : (
            <div className="salesSummaryWrapperInfo">
              <div>** No Services **</div>
            </div>
          )}
          <div className="salesSummaryText">Discount Summary</div>
          {isArray(batchOrderSummary?.discountSummary) ? (
            <>
              {batchOrderSummary?.discountSummary.map(({ amount, title }) => {
                totalDiscounts += amount;

                return (
                  <div className="salesSummaryWrapperInfo">
                    <div>{`${title}`}</div>
                    <div>
                      {getCurrencyWithPrice(
                        amount,
                        merchantShop.currency,
                        false
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="salesSummaryWrapperInfo">
                <div>{`Total Discounts`}</div>
                <div>
                  {getCurrencyWithPrice(
                    totalDiscounts,
                    merchantShop.currency,
                    false
                  )}
                </div>
              </div>
            </>
          ) : null}
          <div className="salesSummaryText">Service Charge Summary</div>
          <div className="salesSummaryWrapperInfo">
            <div>{`Total Service Charges`}</div>
            <div>
              {getCurrencyWithPrice(
                batchOrderSummary?.serviceCharges || 0,
                merchantShop.currency,
                false
              )}
            </div>
          </div>
          <div className="salesSummaryText">Product Type Summary</div>
          {isArray(batchOrderSummary?.productTypesSummary) ? (
            <>
              {batchOrderSummary?.productTypesSummary.map(
                ({ typeName, productPrice, quantity }) => {
                  totalProductPrice += productPrice;

                  return (
                    <div className="salesSummaryWrapperInfo">
                      <div>{typeName}</div>
                      <div>{quantity}</div>
                      {/* <div>
                        {getCurrencyWithPrice(
                          productPrice,
                          merchantShop.currency,
                          false
                        )}
                      </div> */}
                    </div>
                  );
                }
              )}
              <div className="salesSummaryWrapperInfo">
                <div>{`Total Product Type Amount`}</div>
                <div>
                  {getCurrencyWithPrice(
                    totalProductPrice,
                    merchantShop.currency,
                    false
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {renderButton(filterType)}
      {
        <LogoutModal
          isOpen={isOpen}
          onClose={() => toggleLogout()}
          closeCash={() => createCloseCash(false)}
          logout={() => createCloseCash(true)}
        />
      }
    </SummaryContainer>
  );
};
export default connect(
  (state: any) => ({
    merchantShop: state.login.get("merchantShop"),
    batchSession: state.login.get("batchSession"),
    selectedEmployee: state.employee.get("selectedEmployee"),
    batchOrderSummary: state.closeCash.get("batchOrderSummary"),
  }),
  {
    closeCash: Actions.closeCash.closeCash,
    createActivityLog: Actions.activityLog.createActivityLog,
  }
)(CloseCashDetails);
