import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Row, Col } from "react-grid-system";
import {
  getSalesSummary,
  getServiceSummary,
} from "../../../internal/manager/CloseCashManager";
import styles from "../../../components/Styles/Styles.css";
import EditIcon from '../../../../assets/svg/IconEdit.svg';
import { ServiceSummaryContainer } from "../../Styles/Styled";
import { getCurrencyWithPrice } from "../../../internal/manager/PaymentManager";

const ServiceSummary = (props) => {
  const {
    shopData,
    serviceType,
    appointments,
    onClickCashIn,
    onClickCashOut,
    batchSummary,
  } = props;
  const [services, setServices] = useState([]);
  const [groupServices, setGroupServices] = useState([]);
  const [summary, setSummary] = useState(0);

  useEffect(() => {
    setServices([]);
    if (!_.isEmpty(appointments)) {
      appointments.forEach((item) => {
        item.serviceDetails.forEach((value) => {
          services.push(value);
        });
        setServices([...services]);
      });
    }
    const group = _.groupBy(services, "id");
    setGroupServices(group);
  }, [serviceType]);

  useEffect(() => {
    const serviceSummary = getSalesSummary(batchSummary, shopData);
    setSummary(serviceSummary);
  }, [batchSummary]);

  const renderSummary = (type) => {
    switch (type) {
      case "SERVICE":
        return (
          <>
            {Object.keys(groupServices).map((key) => {
              const summary = getServiceSummary(groupServices[key], shopData);
              return (
                <>
                  <Row>
                    <Col xs={12}>
                      <div className={styles.serviceSummaryRow}>
                        <div
                          className={styles.serviceSummaryText}
                        >{`${summary.service} X ${summary.count}`}</div>
                        <div className={styles.serviceSummaryText}>
                          {summary.price}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className={styles.serviceSummaryLine} />
                </>
              );
            })}
          </>
        );
      case "CASH":
        return (
          <>
            {summary?.drawerOperations.map(
              ({ amount, createdTime, reason, type, id }) => (
                <Row>
                  <Col xs={6}>
                    <>
                      <div className={styles.serviceSummaryRow}>
                        <div className={styles.serviceSummaryText}>
                          {reason?.title || ""}
                        </div>
                      </div>
                      <div className={styles.serviceSummaryLine} />
                    </>
                  </Col>
                  <Col xs={3}>
                    <>
                      <div className={styles.serviceSummaryRowEnd}>
                        <div className={styles.serviceSummaryTextRight}>
                          {type === "CASH_IN"
                            ? getCurrencyWithPrice(
                                amount,
                                shopData.currency,
                                false
                              )
                            : "-"}
                        </div>
                      </div>
                      <div className={styles.serviceSummaryLine} />
                    </>
                  </Col>
                  <Col xs={2}>
                    <>
                      <div className={styles.serviceSummaryRowEnd}>
                        <div className={styles.serviceSummaryText}>
                          {type === "CASH_OUT"
                            ? `(${getCurrencyWithPrice(
                                amount,
                                shopData.currency,
                                false
                              )})`
                            : "-"}
                        </div>
                      </div>
                      <div className={styles.serviceSummaryLine} />
                    </>
                  </Col>
                  <Col xs={1}>
                    <>
                      <div className={styles.cashInRowEnd}>
                        <div onClick={() => onClickCashIn({amount, createdTime, reason, type, id})} className={styles.serviceSummaryText}>
                          <img src={EditIcon}/>
                        </div>
                      </div>
                    </>
                  </Col>
                </Row>
              )
            )}
            <Row>
              <Col xs={12}>
                <>
                  <div className={styles.serviceSummaryRow}>
                    <div className={styles.serviceSummaryText}>
                      {"Total float"}
                    </div>
                    <div className={styles.serviceSummaryText}>
                      {summary.cashInOut}
                    </div>
                  </div>
                  <div className={styles.serviceSummaryLine} />
                </>
              </Col>
            </Row>
          </>
        );
      case "SALES":
        return (
          <Row>
            <Col xs={12}>
              <>
                <div className={styles.serviceSummaryRow}>
                  <div className={styles.serviceSummaryText}>
                    {"Cash payments"}
                  </div>
                  <div className={styles.serviceSummaryText}>
                    {summary.cashSales}
                  </div>
                </div>
                <div className={styles.serviceSummaryLine} />
              </>
              <>
                <div className={styles.serviceSummaryRow}>
                  <div className={styles.serviceSummaryText}>
                    {"Card payments"}
                  </div>
                  <div className={styles.serviceSummaryText}>
                    {summary.cardSales}
                  </div>
                </div>
                <div className={styles.serviceSummaryLine} />
              </>
            </Col>
          </Row>
        );
      default:
      case "INCOME":
        return "Income";
    }
  };

  return (
    <ServiceSummaryContainer>
      {renderSummary(serviceType)}
      {serviceType === "CASH" ? (
        <div className={styles.serviceSummaryCashButton}>
          <div
            onClick={() => onClickCashIn()}
            className={styles.serviceSummaryCashInWrapper}
          >
            <div className={styles.serviceSummaryCashInText}>{"Cash In"}</div>
          </div>
          <div
            onClick={() => onClickCashOut()}
            className={styles.serviceSummaryCashOutWrapper}
          >
            <div className={styles.serviceSummaryCashOutText}>{"Cash Out"}</div>
          </div>
        </div>
      ) : null}
    </ServiceSummaryContainer>
  );
};
export default ServiceSummary;
