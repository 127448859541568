import React from 'react';
import '../Styles.css';
import {connect} from "react-redux";
import Box from '@material-ui/core/Box';
import {Actions} from "../../../internal/app/Actions";
import { CenterContainer } from '../../../components';
import { Container, Row, Col } from 'react-grid-system';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from '../../../components/Common/Style/Styles.css';
import {navigate} from "../../../internal/service/Navigation.service";
import AuthenticationAlertModel from "../../../components/model/views/AuthenticationAlertModel";

const ProgressView = (props) => {
  const { isStopProgress, openAuthenticationModelStatus, openAuthenticationModel } = props;

  const { isOpen, message } = openAuthenticationModelStatus;

  return (
    <div className={styles.background}>
      <CenterContainer width="592px">
        <Container>
          <Row>
            <Col xs={12} className={styles.progressHeader}>
              <div>
                <h1 className={styles.welcomeText}>Onboarding</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className={styles.forPasscode}>
              {
                !isStopProgress ?
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                  :
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={0} />
                  </Box>
              }
              {
                !isStopProgress ?
                  <div style={{textAlign: 'center'}} className="please-wait-text">
                    <div className="please-wait-text">
                      Please wait while your station is
                    </div>
                    <div className="onboarded-text">
                      onboarded
                    </div>
                  </div>
                  :
                  <div className="please-wait-text">
                    <div className="please-wait-text">
                      Onboarding failed
                    </div>
                  </div>
              }
            </Col>
          </Row>
        </Container>
      </CenterContainer>
      <AuthenticationAlertModel
        isOpen={isOpen}
        closeModel={() => {
          openAuthenticationModel(false);
          navigate('/onboard');
        }}
        message={message}
      />
    </div>
  );
};

export default connect(
  state => ({
    isStopProgress: state.login.get('isStopProgress'),
    openAuthenticationModelStatus: state.login.get('openAuthenticationModelStatus')
  }),
  {
    merchantOnboard: Actions.login.merchantOnboard,
    openAuthenticationModel: Actions.login.openAuthenticationModel,
  }
)(ProgressView);
