import React, { useCallback, useEffect, useState } from "react";
import { isNil } from "lodash";
import { connect } from "react-redux";
import { ServiceOption } from "./Styled";
import { serviceChargeSchema } from "./Validator";
import { Actions } from "../../../internal/app/Actions";
import { Col, Container, Row } from "react-grid-system";
import RadioInput from "../../../components/input/Views/RadioInput";
import SettingOptionInput from "../../../components/input/Views/SettingOptionInput";
import { mapSystemOptions } from "../../../internal/manager/AppointmentManager";

const ServiceChargeSettings = {
  "serviceCharges.isEnabled": "false",
  "serviceCharges.chargeAmount": "5",
  "serviceCharges.isGuestCountEnabled": "false",
  "serviceCharges.guestCount": "1",
  "serviceCharges.isOrderMinAmount": "false",
  "serviceCharges.orderMinAmount": "1",
  "serviceCharges.isBeforeDiscount": "true",
};

const ServiceChargeOptions = connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
  }),
  {
    updateShop: Actions.profile.updateShop,
  }
)(({ updateShop, shop }: any) => {
  const getMappedSettings = useCallback(() => {
    if (shop.systemSettings) {
      let settings = ServiceChargeSettings;
      shop.systemSettings.forEach(({ key, value }) => {
        if (!isNil(settings[key])) {
          settings[key] = value;
        }
      });
      return settings;
    }
    return ServiceChargeSettings;
  }, [shop]);

  const [serviceSettings, setServiceSettings] = useState(getMappedSettings());

  useEffect(() => {
    setServiceSettings(getMappedSettings());
  }, [shop, getMappedSettings]);

  const onChangeField = (field, value) => {
    const options = {
      ...serviceSettings,
      [field]: `${value}`.trim(),
    };
    if (!serviceChargeSchema.isValidSync(options)) {
      console.log("not valid");
      return;
    }

    setServiceSettings(options);

    const settings = mapSystemOptions(shop.systemSettings, options);
    shop.systemSettings = settings;
    updateShop(shop);
  };

  return (
    <Container>
      <Row>
        <Col xs={6}>
          <ServiceOption>
            <div>Service Charge</div>
            <div>Apply service charge to the orders</div>
          </ServiceOption>
        </Col>
        <Col xs={6}>
          <RadioInput
            onChange={(val) => onChangeField("serviceCharges.isEnabled", val)}
            value={serviceSettings["serviceCharges.isEnabled"] === "true"}
          >
            <SettingOptionInput
              value={serviceSettings["serviceCharges.chargeAmount"]}
              disabled={
                serviceSettings["serviceCharges.isEnabled"] === "false"
              }
              onChange={(val) =>
                onChangeField("serviceCharges.chargeAmount", val)
              }
              label="Service Charge %"
            ></SettingOptionInput>
          </RadioInput>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <ServiceOption>
            <div>Guest Minimum Count</div>
            <div>
              Set minimum count of guests to apply service charge (Max: 99)
            </div>
          </ServiceOption>
        </Col>
        <Col xs={6}>
          <RadioInput
            onChange={(val) =>
              onChangeField("serviceCharges.isGuestCountEnabled", val)
            }
            disabled={serviceSettings["serviceCharges.isEnabled"] === "false"}
            value={
              serviceSettings["serviceCharges.isGuestCountEnabled"] === "true"
            }
          >
            <SettingOptionInput
              value={serviceSettings["serviceCharges.guestCount"]}
              disabled={
                serviceSettings["serviceCharges.isEnabled"] === "false" ||
                serviceSettings["serviceCharges.isGuestCountEnabled"] ===
                  "false"
              }
              onChange={(val) =>
                onChangeField("serviceCharges.guestCount", val)
              }
              label="Min. Guests"
            ></SettingOptionInput>
          </RadioInput>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <ServiceOption>
            <div>Order Amount Minimum</div>
            <div>Minimum amount to apply service charge</div>
          </ServiceOption>
        </Col>
        <Col xs={6}>
          <RadioInput
            onChange={(val) =>
              onChangeField("serviceCharges.isOrderMinAmount", val)
            }
            disabled={serviceSettings["serviceCharges.isEnabled"] === "false"}
            value={
              serviceSettings["serviceCharges.isOrderMinAmount"] === "true"
            }
          >
            <SettingOptionInput
              value={serviceSettings["serviceCharges.orderMinAmount"]}
              disabled={
                serviceSettings["serviceCharges.isEnabled"] === "false" ||
                serviceSettings["serviceCharges.isOrderMinAmount"] === "false"
              }
              onChange={(val) =>
                onChangeField("serviceCharges.orderMinAmount", val)
              }
              label="Min. Amount"
            ></SettingOptionInput>
          </RadioInput>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <ServiceOption>
            <div>Apply Service Charge Before Discount</div>
            <div>
              The discount will be applied to the total of the bill before
              applying discounts
            </div>
          </ServiceOption>
        </Col>
        <Col xs={6}>
          <RadioInput
            onChange={(val) =>
              onChangeField("serviceCharges.isBeforeDiscount", val)
            }
            disabled={serviceSettings["serviceCharges.isEnabled"] === "false"}
            value={
              serviceSettings["serviceCharges.isBeforeDiscount"] === "true"
            }
          />
        </Col>
      </Row>
    </Container>
  );
});

export default ServiceChargeOptions;
