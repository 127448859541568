import _, { get } from "lodash";
import isOnline from "is-online";
import Storage from "../service/Storage.service";
import { generateImageUrl } from "./ImageManager";
import { keyValueToObject, mapRefundAmount } from "./AppointmentManager";
import GraphqlService from "../service/Graphql.service";

const path = require("path");

export const isCheckOnline = async () => {
  return await isOnline();
  // return navigator.onLine;
};

export const setValueToLocalStorage = async (key: string, value: any) => {
  if (!window?.standlone) {
    await GraphqlService.setLocalData(key, value);
    // await localStorage.setItem(key, value);
  } else {
    return await Storage.setItem(key, value);
  }
};

export const getValueFromLocalStorage = async (key: string) => {
  if (!window?.standlone) {
    return await GraphqlService.getLocalData(key);
    // return await localStorage.getItem(key);
  } else {
    return await Storage.getItem(key);
  }
};

export const updateCurrentStorageData = async (
  key,
  param,
  id,
  keyName,
  isUpdate = false
) => {
  const isOnline = await isCheckOnline();
  console.log({isUpdate})
  if (!isOnline) {
    const list = await getValueFromLocalStorage(key);
    if (list) {
      param.isOffline = true;
      const newList = JSON.parse(list);
      const dataArray = _.isArray(newList) ? newList : newList.Items;
      const index = _.findIndex(dataArray, (item) => item[keyName] === id);
      if (index > -1) {
        const currentData = _.isArray(newList)
          ? newList[index]
          : newList.Items[index];
        const isCreate = currentData.isCreate;
        param.isCreate = isCreate;
        if (_.isArray(newList)) {
          newList[index] = param;
        } else {
          newList.Items[index] = param;
        }
      } else {
        param.isCreate = true;
        if (_.isArray(newList)) {
          newList.push(param);
        } else {
          newList.Items.push(param);
        }
      }
      await setValueToLocalStorage(key, JSON.stringify(newList));
    }
    return param;
  }
};

export const deleteCurrentStorageData = async (key, param, id, keyName) => {
  const isOnline = await isCheckOnline();

  if (!isOnline) {
    const list = await getValueFromLocalStorage(key);
    const deletedData = await getValueFromLocalStorage(`deleted-${key}`);
    const deletedList = deletedData ? JSON.parse(deletedData) : [];
    if (list) {
      param.isOffline = true;
      const newList = JSON.parse(list);
      const dataArray = _.isArray(newList) ? newList : newList.Items;
      const index = _.findIndex(dataArray, (item) => item[keyName] === id);
      deletedList.push(param);
      if (index > -1) {
        if (_.isArray(newList)) {
          newList.splice(index, 1);
        } else {
          newList.Items.splice(index, 1);
        }
      }
      await setValueToLocalStorage(key, JSON.stringify(newList));
      await setValueToLocalStorage(
        `deleted-${key}`,
        JSON.stringify(deletedList)
      );
    }

    return param;
  }
};

export const updateCurrentSCStorageData = async (key, param, id, keyName) => {
  const isOnline = await isCheckOnline();

  if (!isOnline) {
    const list = await getValueFromLocalStorage(key);
    if (list) {
      const newList = JSON.parse(list);
      const index = _.findIndex(newList, (item) => item[keyName] === id);
      if (index > -1) {
        newList[index].categories = param.categories;
      }

      await setValueToLocalStorage(key, JSON.stringify(newList));
    }

    return param;
  }
};

export const updateCurrentOrderStorageData = async (key, id, keyName) => {
  const isOnline = await isCheckOnline();

  if (!isOnline) {
    const list = await getValueFromLocalStorage(key);
    if (list) {
      const newList = JSON.parse(list);
      const dataArray = newList.Items || [];
      const index = _.findIndex(dataArray, (item) => item[keyName] === id);
      if (index > -1) {
        newList.Items.splice(index, 1);
      }

      await setValueToLocalStorage(key, JSON.stringify(newList));
    }
  }
  return;
};

export const pushDataToStorage = async (key, data) => {
  let list: any = await getValueFromLocalStorage(key);
  if (list) {
    const parsedList = JSON.parse(list) || [];
    parsedList.push(data);
    list = parsedList;
  } else {
    list = [data];
  }
  await setValueToLocalStorage(key, JSON.stringify(list));
  return;
};

export const shiftDataFromStorage = async (key) => {
  let item: any = undefined;
  let list: any = await getValueFromLocalStorage(key);
  if (list) {
    const parsedList = JSON.parse(list) || [];
    item = parsedList.shift();
    list = parsedList;
  } else {
    list = [];
  }
  await setValueToLocalStorage(key, JSON.stringify(list));
  return item;
};

export const unshiftDataFromStorage = async (key, data) => {
  let item: any = undefined;
  let list: any = await getValueFromLocalStorage(key);
  if (list) {
    const parsedList = JSON.parse(list) || [];
    item = parsedList.unshift(data);
    list = parsedList;
  } else {
    list = [];
  }
  await setValueToLocalStorage(key, JSON.stringify(list));
  return item;
};

export const updateSuccessOrderStorageData = async (
  key,
  param,
  id,
  keyName,
  orderStatus,
  isUpdate = false
) => {
  const isOnline = await isCheckOnline();

  if (!isOnline) {
    const list = await getValueFromLocalStorage(key);
    if (list) {
      param.isOffline = true;
      // for orders added to order queue and closing again
      // isCreate: true, and it will not be updating
      param.isCreate = get(param, "isCreate", !isUpdate);
      const newList = JSON.parse(list);
      const dataArray = newList.Items;
      const index = _.findIndex(dataArray, (item) => item[keyName] === id);
      if (index > -1) {
        newList.Items[index] = param;
      } else {
        if (orderStatus === "REOPEN") {
          const list = await getValueFromLocalStorage(
            "COMPLETED_APPOINTMENT_LIST"
          );
          const completedList = list ? JSON.parse(list) : { Items: [] };
          const comIndex = _.findIndex(
            completedList.Items,
            (order: any) => order.orderId === id
          );
          const newParam = completedList.Items[comIndex];
          newParam.isCreate = get(newParam, "isCreate", !isUpdate);
          newParam.isOffline = true;
          newParam.orderStatus = param.orderStatus;
          newParam.updatedTime = param.updatedTime;
          newList.Items.push(newParam);
        } else {
          newList.Items.push(param);
        }
      }
      await setValueToLocalStorage(key, JSON.stringify(newList));
    }
    return param;
  }
};

export const mapRefundOrder = async (request) => {
  const {
    batchId,
    amount,
    voidType,
    services,
    products,
    orderId,
    empId,
    reason,
    shopId,
  } = request;
  const completedOrders = await getValueFromLocalStorage(
    "COMPLETED_APPOINTMENT_LIST"
  );
  const refundOrders = await getValueFromLocalStorage("REFUND_ORDERS");
  const refundList = refundOrders ? JSON.parse(refundOrders) : [];
  const orderList = JSON.parse(completedOrders);
  const findOrder = _.find(orderList.Items, (item) => item.orderId === orderId);
  const refundsData = _.get(findOrder, "refunds", []);
  const { serviceCount, productCount, servicePrice, productPrice } =
    await mapRefundAmount(services, products);
  refundsData.push({
    batchId,
    amount,
    services,
    products,
    createdTime: Date.now(),
    serviceCount,
    reason,
    productCount,
    servicePrice,
    productPrice,
  });
  const index = _.findIndex(
    orderList.Items,
    (item: any) => item.orderId === orderId
  );
  if (index > -1) {
    orderList.Items[index].refunds = refundsData;
    orderList.Items[index].voidType = voidType;
  }
  refundList.push(request);
  await setValueToLocalStorage(
    "COMPLETED_APPOINTMENT_LIST",
    JSON.stringify(orderList)
  );
  await setValueToLocalStorage("REFUND_ORDERS", JSON.stringify(refundList));
  return request;
};

export const updateOrderAmount = async (orderId, totalPrice) => {
  const completedOrders = await getValueFromLocalStorage(
    "COMPLETED_APPOINTMENT_LIST"
  );
  const orderList = JSON.parse(completedOrders);
  const index = _.findIndex(
    orderList.Items,
    (item: any) => item.orderId === orderId
  );
  if (index > -1) {
    orderList.Items[index].totalPrice = totalPrice;
  }
  await setValueToLocalStorage(
    "COMPLETED_APPOINTMENT_LIST",
    JSON.stringify(orderList)
  );
  return orderId;
};

export const toLowerCase = (name) => {
  return _.lowerCase(name);
};

export const filterEmployeesForSpec = (stylistArray, specializationId) => {
  return _.filter(stylistArray.items, (item) => {
    const specializationsData: any = _.get(item, "specializations", []);
    const data = _.find(
      specializationsData,
      (specialization) => specialization.id === specializationId
    );
    return !_.isEmpty(data);
  });
};

export const filterUnCompletedOrders = async (
  clientId,
  empId,
  orderStatus,
  list
) => {
  if (orderStatus === "ALL") {
    return filterOrders(empId, clientId, list);
  } else {
    const newList = filterOrders(empId, clientId, list);
    return _.filter(newList, (item) => item.orderStatus === orderStatus);
  }
};

export const filterOrders = (empId, clientId, list) => {
  if (!empId && !clientId) {
    return list;
  } else if (empId && clientId) {
    return _.filter(
      list,
      (item) => item.clientId === clientId && item.empId === empId
    );
  } else if (empId) {
    return _.filter(list, (item) => item.empId === empId);
  } else {
    return _.filter(list, (item) => item.clientId === clientId);
  }
};

const cacheImage = async (image: string) => {
  if (image) {
    const url = await generateImageUrl(image);
    if (window?.api) {
      window.api.download({ url, image });
    }
  }
};

export const downloadImages = async (list: any) => {
  list.Items.forEach(async (item: any) => {
    const image = item.productImage || item.image;
    if (image) {
      await cacheImage(image);
    }
  });
};

export const downloadServiceImages = async (list: any) => {
  const categories = _.get(list, "0.categories", []);
  categories.forEach(async (item) => {
    const services = item.services || [];
    const filterService = _.filter(services, (service) => service.image);
    await downloadImages({ Items: filterService });
  });
};

export const downloadShopImages = async (shop: any) => {
  const logoImage = shop?.billImage;
  const shopImage = shop?.image;
  await cacheImage(logoImage);
  await cacheImage(shopImage);
};

export const getActivityStatus = (status: string) => {
  switch (status) {
    case "ADD_TO_QUEUE":
      return "Add to Queue";
    case "DELETE_ORDER":
      return "Delete Order";
    case "CHECKOUT":
      return "Checkout";
    case "SEND_TO_KITCHEN":
      return "Send to kitchen";
    case "CLOSE_CASH":
      return "Close Cash";
    case "RESTORED":
      return "Restored";
    default:
      return "Add to Queue";
  }
};

export const getShopSystemSetting = (shop: any) => {
  const settingObj = keyValueToObject(shop.systemSettings);
  const isRooms = settingObj["orderOptions.isRooms"] === "true";
  const isOffers = settingObj["orderOptions.isOffers"] === "true";
  const isWarranty = settingObj["orderOptions.isWarranty"] === "true";
  const isQueueRooms = settingObj["orderQueueOptions.isQueueRooms"] === "true";
  const isQueueTables =
    settingObj["orderQueueOptions.isQueueTables"] === "true";
  return { isRooms, isOffers, isWarranty, isQueueRooms, isQueueTables };
};
declare global {
  interface Window {
    api: any;
  }
}
