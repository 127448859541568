import _, { get, orderBy, unionBy } from "lodash";
import { Map } from "immutable";
import { ModuleEvents } from "./Actions";

const initialState = Map({
  completedCashAppointments: [],
  batchSummary: {},
  pastBatchSummary: [],
  pastBatchSummaryLastKey: {},
  batchOrderSummary: {},
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.COMPLETED_CASH_APPOINTMENTS: {
      if (payload && !payload.error) {
        const appointments = _.orderBy(
          _.get(payload, "Items", []),
          ["updatedTime"],
          ["desc"]
        );
        return state.set("completedCashAppointments", appointments);
      }
      return state;
    }
    case ModuleEvents.GET_BATCH_SUMMARY: {
      if (payload && !payload.error) {
        return state.set("batchSummary", payload);
      }
      return state;
    }
    case ModuleEvents.FETCH_ORDER_SUMMARY: {
      if (payload && !payload.error) {
        return state.set("batchOrderSummary", payload);
      }
      return state;
    }
    case ModuleEvents.GET_PAST_BATCH_SUMMARY: {
      if (payload && !payload.error) {
        const lastKey = _.get(payload, "LastEvaluatedKey", {});
        const summary = _.orderBy(
          _.get(payload, "Items", []),
          ["updatedTime"],
          ["desc"]
        );
        return state
          .set("pastBatchSummary", summary)
          .set("pastBatchSummaryLastKey", lastKey);
      }
      return state;
    }
    case ModuleEvents.PAGINATE_PAST_BATCH_SUMMARY: {
      if (payload && !payload.error) {
        const pastBatchSummary: any = state.get("pastBatchSummary");
        const list = unionBy(
          [...pastBatchSummary, ...payload.Items],
          "batchId"
        );
        const sortTransaction = orderBy(list, ["updatedTime"], ["desc"]);
        const lastKey = _.get(payload, "LastEvaluatedKey", {});
        return state
          .set("pastBatchSummary", sortTransaction)
          .set("pastBatchSummaryLastKey", lastKey);
      }
      return state;
    }
    default:
      return state;
  }
};

export default Reducer;
