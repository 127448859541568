import React from "react";
import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import _, { get, isEmpty, orderBy, uniqBy } from "lodash";
import { ModuleEvents as ProductEvents } from "../product/Actions";

const initialState = Map({
  warranty: {
    warranties: [],
    nextToken: null,
  },
  productWarranty: {},
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;
  switch (action.type) {
    case ModuleEvents.SET_PRODUCT_WARRANTY: {
      const warranty: any = state.get("warranty");
      const productWarranty: any = state.get("productWarranty");
      if (payload) {
        if (
          !isEmpty(productWarranty) &&
          productWarranty?.wrid === payload.wrid
        ) {
          payload["status"] = "UPDATE";
        } else {
          payload["status"] = "CREATE";
        }
        warranty.warranties.push(payload);
        return state
          .set("warranty", { ...warranty })
          .set("productWarranty", payload);
      }
      return state;
    }

    case ProductEvents.CREATE_PRODUCT: {
      if (payload && !payload.error) {
        return state.set("productWarranty", {});
      }
      return state;
    }
    case ProductEvents.UPDATE_PRODUCT: {
      if (payload && !payload.error) {
        return state.set("productWarranty", {});
      }
      return state;
    }

    case ModuleEvents.FETCH_WARRANTIES: {
      const warranty: any = state.get("warranty");
      if (payload && !payload.error) {
        const nextToken = get(payload, "nextToken", {});
        const newData: any = uniqBy([...payload.items], "wrid");
        let newWarrantyies: any = orderBy(newData, ["updatedTime"], ["desc"]);
        warranty.nextToken = nextToken;
        warranty.warranties = newWarrantyies;
        return state.set("warranty", { ...warranty });
      }
      return state;
    }

    case ProductEvents.NAVIGATE_EDIT_PRODUCT_VIEW: {
      if (payload) {
        const warranty: any = get(payload, "warranty", {});
        return state.set("productWarranty", { ...warranty });
      }
      return state;
    }
    case ProductEvents.CLEAR_SELECTED_PRODUCT_ITEMS: {
      return state.set("productWarranty", {});
    }

    default:
      return state;
  }
};

export default Reducer;
