import styled from "styled-components";
import BaseColors from "../../../internal/helpers/BaseColors";

export const RadioWrapper = styled.div`
  padding: 1rem;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }  
`;

export const SettingOpWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 22px;

  & > div {
    color: #11221c;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
  }

  & > input {
    margin-left: 1rem;
    width: 120px;
    height: 44px;
    background-color: #ffffff;
    border: 0.2px solid #ebebeb;
    padding-left: 10px;
    border-radius: 4px;
  }
`