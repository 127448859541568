import _ from "lodash";
import { Map } from "immutable";
import { ModuleEvents } from "./Actions";
import { ModuleEvents as PaymentModule } from "../payment/Actions";

const initialState = Map({
  stylistCategoryList: [],
  selectedEmployees: {},
  stylistList: [],
  employees: [],
  employeesLastKey: {},
  selectedEmployee: {},
  loginEmployees: [],
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.FETCH_EMPLOYEES: {
      if (payload && !payload.error) {
        const stylistsLastKey = _.get(payload, "lastKey", {});
        const stylists = _.sortBy(_.get(payload, "items", []), ["name"]);
        return state
          .set("employees", stylists)
          .set("employeesLastKey", stylistsLastKey);
      }
      return state;
    }
    case ModuleEvents.ON_SELECT_EMPLOYEE: {
      return state.set("selectedEmployee", payload);
    }

    case ModuleEvents.FETCH_EMPLOYEES_FOR_SPEC_ID: {
      if (payload && !payload.error) {
        const stylists = _.sortBy(_.get(payload, "Items", []), "name");
        return state.set("employees", stylists);
      }
      return state;
    }

    case ModuleEvents.FETCH_SALON_EMPLOYEE: {
      if (payload && !payload.error) {
        return state.set("stylistList", [...payload]);
      }

      return state;
    }

    case ModuleEvents.FETCH_SHOP_EMPLOYEES: {
      if (payload && !payload.error) {
        return state.set("loginEmployees", [...payload.Items]);
      }

      return state;
    }

    case ModuleEvents.FETCH_EMPLOYEE_FOR_CATEGORYID: {
      if (payload && !payload.error) {
        return state.set("stylistList", [...payload]);
      }

      return state;
    }

    case PaymentModule.CLEAR_CURRENT_APPOINTMENT: {
      return state.set("selectedEmployee", {});
    }

    case ModuleEvents.SELECT_EMPLOYEE: {
      return state.set("selectedEmployees", { ...payload });
    }

    case ModuleEvents.SEARCH_EMPLOYEE: {
      if (payload && !payload.error) {
        return state.set("stylistList", payload);
      }

      return state;
    }
    case ModuleEvents.FETCH_EMPLOYEES_FOR_SALON_ID: {
      if (payload && !payload.error) {
        const sortedItems = _.sortBy(payload, "createdTime");
        return state.set("employees", sortedItems);
      }

      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
