import React, { useEffect, useState } from "react";
import _ from "lodash";
import { nanoid } from "nanoid";
import Select from "react-select";
import ReactModal from "react-modal";
import "../../Styles/ModelStyles.css";
import { connect } from "react-redux";
import {
  mapStock,
  mapStockMetrics,
} from "../../../internal/manager/ProductManager";
import styles from "../../Styles/ModelStyles.css";
import { Actions } from "../../../internal/app/Actions";
import CANCEL from "../../../../assets/icons/cancel.svg";
import { CreateCategoryContainer } from "../../Styles/Styled";

const customStyles = {
  content: {
    top: "10vh",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 264px)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const style = {
  control: (base, state) => ({
    ...base,
    height: "45px",
    width: "100%",
    paddingRight: "20px",
    border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    "&:hover": {
      border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    },
    borderRadius: "6px",
    color: "#434343",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    paddingLeft: "5px",
  }),
};

const CreateStockItemModel = (props) => {
  const {
    type,
    shop,
    isOpen,
    stockList,
    closeModal,
    stockItems,
    fetchStock,
    onAddStockItem,
    productWarranty,
    selectedStockItem,
  } = props;

  const [header, setHeader] = useState("");
  const [titlePlaceholder, setTitlePlaceholder] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [stockInputPlaceholder, setStockInputPlaceholder] = useState("");
  const [stockItem, setStockItem] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [quantity, setQuantity] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(selectedStockItem)) {
      setQuantity(selectedStockItem.quantity);
      setStockItem({
        value: selectedStockItem.itemName,
        label: selectedStockItem.itemName,
        metric: selectedStockItem.metric,
        stockId: selectedStockItem.id,
        metricValue: selectedStockItem.metricValue,
      });
    }
  }, [selectedStockItem]);

  const isEdit = !_.isEmpty(selectedStockItem);

  useEffect(() => {
    switch (type) {
      case "STOCK_ITEM":
        setHeader(isEdit ? "Edit Stock Item" : "Add Stock Item");
        setTitlePlaceholder("STOCK ITEM");
        setButtonText(isEdit ? "Update Stock Item" : "Add Stock Item");
        setStockInputPlaceholder("Stock Item");
        return;
      default:
        return;
    }
  }, [type, selectedStockItem]);

  // useEffect(() => {
  //   if (!isEmpty(shop.shopId)) {
  //     fetchStock(shop.shopId);
  //   }
  // }, [shop]);

  const initialState = () => {
    setStockItem(null);
    setQuantity("");
  };

  const addStockItem = () => {
    onAddStockItem({
      quantity,
      metric: stockItem.metric,
      itemName: stockItem.value,
      id: stockItem.stockId,
      metricValue: stockItem.metricValue,
      itemId: nanoid(),
    });
    initialState();
    closeModal();
  };

  const differenceItems = _.filter(
    stockList,
    (stock) =>
      _.findIndex(stockItems, (item) => item.id === stock.stockId) === -1
  );
  const mapStockList = mapStock(differenceItems);

  const getOptions = (inputType) => {
    switch (inputType) {
      case "STOCK_ITEM":
        return mapStockList;
      case "METRIC":
        return mapStockMetrics;
      default:
        return;
    }
  };

  const onInputChange = (option, { action }) => {
    if (action === "input-change") {
      const optionLength = option.length;
      const inputValueLength = inputValue.length;
      const newInputValue =
        optionLength < inputValueLength
          ? option
          : inputValue + option[option.length - 1];
      setInputValue(newInputValue);
    }
  };

  const isValid = quantity && !_.isEmpty(stockItem);

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.createCategoryModal}
    >
      <CreateCategoryContainer isValid={!_.isEmpty(inputValue)}>
        <div
          onClick={() => {
            closeModal();
            initialState();
          }}
        >
          <div>{header}</div>
          <img src={CANCEL} />
        </div>
        <div>
          <div>
            <div>{titlePlaceholder}</div>
            <Select
              value={stockItem}
              styles={style}
              onChange={(data) => {
                setStockItem(data);
                setInputValue("");
              }}
              options={getOptions("STOCK_ITEM")}
              inputValue={inputValue}
              onInputChange={onInputChange}
              placeholder={
                <div className="categoryPlaceholderText">
                  {stockInputPlaceholder}
                </div>
              }
            />
          </div>

          <div style={{ width: "100%" }}>
            <div>{"QUANTITY PER PORTION"}</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "80%",
                justifyContent: "space-between",
              }}
            >
              <input
                type={"number"}
                onChange={(text) => setQuantity(text.target.value)}
                value={quantity}
                className="stockItemTextInput"
                placeholder="Quantity per potion"
              />
              <div style={{ width: "180px" }}>
                <input
                  onChange={(text) => {}}
                  value={stockItem?.metric}
                  disabled={true}
                  className="stockItemTextInput"
                  placeholder="Quantity per potion"
                />
              </div>
            </div>
          </div>
          <div>
            <button
              disabled={!isValid}
              style={{ backgroundColor: isValid ? "#49A4D4" : "#8A9CA5" }}
              onClick={() => {
                addStockItem();
              }}
            >
              <div>{buttonText}</div>
            </button>
          </div>
        </div>
      </CreateCategoryContainer>
    </ReactModal>
  );
};
export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    stockList: state.product.get("stockList"),
    currentUser: state.login.get("currentUser"),
    productWarranty: state.warranty.get("productWarranty"),
  }),
  {
    fetchStock: Actions.product.fetchStock,
  }
)(CreateStockItemModel);
