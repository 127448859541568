import React, { Component, useEffect, useState } from "react";
import styles from "../../../components/Common/Style/Styles.css";
import { Container, Row, Col } from "react-grid-system";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";
import ProductCustomizedSideNav from "../../../components/sideNavBars/views/ProductCustomizedSideNav";
import ProductSubHeader from "../../../components/headers/Views/ProductSubHeader";
import { connect } from "react-redux/es/index";
import { Actions } from "../../../internal/app/Actions";
import AllProductsView from "./AllProductsView";
import BrandsTableView from "./BrandsTableView";
import ProductCategoryTableView from "./ProductCategoryTableView";
import FavouriteProductsView from "./FavouriteProductsView";
import SupplierTableView from "./SupplierTableView";
import {
  Catalogue
} from '../../index';
import ProductTypesTableView from "./ProductTypesTableView";
import StockTableView from "./StockTableView";

const ProductCustomizedView = (props) => {
  const {
    selectProductSideBarView,
    selectedServiceSideBarView,
    clearSelectedProductItem,
  } = props;
  const [selectedSidebar, setSelectedSidebar] = useState(
    selectedServiceSideBarView
  );


  useEffect(() => {
    setSelectedSidebar(selectedServiceSideBarView);
  }, [selectedServiceSideBarView]);

  const onClickSideBar = (type) => {
    selectProductSideBarView(type);
  };

  const renderSidebarValue = () => {
    switch (selectedSidebar) {
      case "FAVOURITES":
        return (
          <div className={styles.allServiceWrapper}>
            <FavouriteProductsView />
          </div>
        );
      case "CATEGORIES":
        return (
          <div className={styles.categoryTableWrapper}>
            <ProductCategoryTableView />
          </div>
        );
      case "ALL_PRODUCTS":
        return (
          <div className={styles.allServiceWrapper}>
            <AllProductsView />
          </div>
        );

      case "BRANDS":
        return (
          <div className={styles.allServiceWrapper}>
            <BrandsTableView />
          </div>
        );
      case "PRODUCT_TYPES":
        return (
          <div className={styles.allServiceWrapper}>
            <ProductTypesTableView />
          </div>
        );

      case "SUPPLIERS":
        return (
          <div className={styles.allServiceWrapper}>
            <SupplierTableView />
          </div>
        );
      case "MENU":
        return (
          <div className={styles.menuWrapper}>
            <Catalogue />
          </div>
        );

      case "STOCK":
        return (
          <div className={styles.menuWrapper}>
            <StockTableView />
          </div>
        );

      default: {
        return <div />;
      }
    }
  };

  return (
    <div className={styles.serviceCustomizedContainer}>
      <TopNavigationBar viewName="Products" path="/menu" />
      <div className={styles.settingsWrapper}>
        <div className={styles.serviceCustomizedWrapper}>
          <Row>
            <Col xs={12}>
              <ProductSubHeader
                selectedSidebar={selectedSidebar}
                onClear={(path) => clearSelectedProductItem(path)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={2} style={{ paddingRight: 0 }}>
              <ProductCustomizedSideNav
                selectedSidebar={selectedSidebar}
                onClick={(type) => onClickSideBar(type)}
              />
            </Col>
            <Col xs={10} style={{ paddingLeft: 0 }}>
              {renderSidebarValue()}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    selectedServiceSideBarView: state.product.get("selectedServiceSideBarView"),
  }),
  {
    selectProductSideBarView: Actions.product.selectProductSideBarView,
    clearSelectedProductItem: Actions.product.clearSelectedProductItem,
  }
)(ProductCustomizedView);
