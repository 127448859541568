export const isProd = false;
export const VERSION = '2.0.1-2';
export const DISPLAY_VERSION = '2.0.1-2';

const prodConfig = {
  Auth: {
  }
};

const config = {
  Auth: {
    // identityPoolId: 'ap-southeast-1:8cfeb438-2ba1-4664-aeae-08afe1ca4bdf',
    mandatorySignIn: false,
    region: 'ap-southeast-1',
    identityPoolRegion: 'ap-southeast-1',
    userPoolId: 'ap-southeast-1_iE3UHh6w4',
    userPoolWebClientId: '3e5k0ck0epb5ri1ljujqkmj1l6',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    identityPoolId: 'ap-southeast-1:507d358d-5fcb-4006-8815-bbca98942818',
  },
  Storage: {
    AWSS3: {
      bucket: "netlise-app-bucket",
      region: "ap-southeast-1",
    },
    // AWSS3_ACTIVITY: {
    //   bucket: "netlise-activity-logs",
    //   region: "ap-southeast-1",
    // }
  },
  "aws_appsync_graphqlEndpoint": "https://r5denx53k5evvawmy6c3wmf22q.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  "aws_appsync_region": "ap-southeast-1",
  "aws_appsync_authenticationType": "AWS_IAM",
  API: {
    region: 'ap-southeast-1',
    endpoints: [
      {
        "id": "mj8lxajnve",
        "name": "dev-netlise-pos-lambda-api",
        "description": "global ",
        "endpoint": "https://mj8lxajnve.execute-api.ap-southeast-1.amazonaws.com/dev",
        "region": "ap-southeast-1",
        "paths": []
      },
      // {
      //   id: 'vri1ubif20',
      //   name: 'dev-cloud-pos-api',
      //   description: '',
      //   endpoint:
      //     'https://vri1ubif20.execute-api.ap-southeast-1.amazonaws.com/dev',
      //   region: 'ap-southeast-1',
      //   paths: [],
      // },
      {
        id: '4qawsqx576',
        name: 'dev-admin-dashboard-api',
        description: '',
        endpoint:
          'https://4qawsqx576.execute-api.ap-southeast-1.amazonaws.com/dev',
        region: 'ap-southeast-1',
        paths: [],
      },
      {
        id: 'ik1md8pcjk',
        name: 'dev-netlise-api-v2',
        description: '',
        endpoint:
          'https://ik1md8pcjk.execute-api.ap-southeast-1.amazonaws.com/dev',
        region: 'ap-southeast-1',
        paths: [],
      },
      {
        id: '7aqkhkggh5',
        name: 'dev-netlise-app-api',
        description: '',
        endpoint:
          'https://7aqkhkggh5.execute-api.ap-southeast-1.amazonaws.com/dev',
        region: 'ap-southeast-1',
        paths: [],
      },
      {
        id: 'rvmipj7w17',
        name: 'netlise-report-api-dev',
        description: '',
        endpoint:
          'https://rvmipj7w17.execute-api.ap-southeast-1.amazonaws.com/dev',
        region: 'ap-southeast-1',
        paths: [],
      },
      // {
      //   id: 'l9qr6ot4n3',
      //   name: 'netlise-order-api-dev',
      //   description: '',
      //   endpoint: 'https://l9qr6ot4n3.execute-api.ap-southeast-1.amazonaws.com/dev',
      //   region: 'ap-southeast-1',
      //   paths: [],
      // },
    ],
  },
};

export default isProd ? prodConfig : config;
