import React, {useEffect} from 'react';
import '../Styles.style.css';
import _ from 'lodash';
import {connect} from "react-redux";
import {Row, Col} from 'react-grid-system';
import {Actions} from "../../../internal/app/Actions";
import EditIcon from '../../../../assets/svg/categoryEditIcon.svg';

const StockTableView = (props) => {
  const {shop, stockList, navigateToEditStockView, fetchStock} = props;

  useEffect(() => {
    if (!_.isEmpty(shop.shopId)) {
      fetchStock(shop.shopId);
    }
  }, [shop]);

  const navigateEditScreen = (category) => {
    navigateToEditStockView(category)
  };

  return (
    <div className="categoryTableContainer">
      <div className="tableHeaderContainer">
        <Row>
          <Col xs={3} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">
              Stock Item Name
            </div>
          </Col>
          <Col xs={3} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">
              ID
            </div>
          </Col>
          <Col xs={3} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">
              Metric
            </div>
          </Col>
          <Col xs={1}/>
        </Row>
      </div>
      <div className="categoryTableBodyContainer">
        {
          stockList.map((stock) => {
            return (
              <div className="categoryTableBody">
                <Row>
                  <Col xs={3} className="categoryTableHeaderTextContainer">
                    <div className="categoryTableBodyText">
                      {stock.itemName}
                    </div>
                  </Col>
                  <Col xs={3} className="categoryTableHeaderTextContainer">
                    <div className="categoryTableBodyText">
                      {stock.stockId}
                    </div>
                  </Col>
                  <Col xs={3} className="categoryTableHeaderTextContainer">
                    <div className="categoryTableBodyText">
                      {stock.metric}
                    </div>
                  </Col>
                  <Col xs={1} className="categoryTableHeaderTextContainer">
                    <div className="categoryEditIcon" onClick={() => navigateEditScreen(stock)}>
                      <img src={EditIcon}/>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })
        }
        <div className="stockRecordEmptyContainer">
          {
            stockList.length === 0 ?
              <div className="noStockRecordText">
                No stock items available!
              </div>
              : null
          }
        </div>
      </div>
    </div>
  );
};
export default connect(
  state => ({
    stockList: state.product.get('stockList'),
    loadingAction: state.common.get('loadingAction'),
    shop: state.login.get('merchantShop'),
  }),
  {
    navigateToEditStockView: Actions.product.navigateToEditStockView,
    fetchStock: Actions.product.fetchStock
  }
)(StockTableView);

