import gql from 'graphql-tag';

export const CreateBrand = gql`
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      id
      brandName
      shopId
      description
      createdTime
      createdUserId
      updatedTime
    }
  }
`;

export const UpdateBrand = gql`
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      id
      brandName
      shopId
      description
      createdTime
      createdUserId
      updatedTime
    }
  }
`;

export const DeleteBrand = gql`
  mutation DeleteBrand($input: DeleteBrandInput!) {
    deleteBrand(input: $input) {
      id
      brandName
      shopId
      description
      createdTime
      createdUserId
      updatedTime
    }
  }
`;

export const FetchProductTypes = gql`
  query FetchProductTypes($shopId: String!) {
    fetchProductTypes(shopId: $shopId) {
      shopId
      id
      typeName
      description
      updatedTime
      createdTime
      createdUserId
    }
  }
`;


export const CreateProductType = gql`
  mutation CreateProductType($input: CreateProductTypeInput!) {
    createProductType(input: $input) {
      shopId
      id
      typeName
      description
      updatedTime
      createdTime
      createdUserId
    }
  }
`;

export const UpdateProductType = gql`
  mutation UpdateProductType($input: UpdateProductTypeInput!) {
    updateProductType(input: $input) {
      shopId
      id
      typeName
      description
      updatedTime
      createdTime
      createdUserId
    }
  }
`;

export const DeleteProductType = gql`
  mutation DeleteProductType($input: DeleteProductTypeInput!) {
    deleteProductType(input: $input) {
      shopId
      id
      typeName
      description
      updatedTime
      createdTime
      createdUserId
    }
  }
`;
