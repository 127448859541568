import { _ } from './AppUtils';
import { AppModules } from './AppModules';

const Reducers = _(AppModules)
  .keyBy(module => module)
  .mapValues(module => {
    return require(`../../modules/${module}/Reducer`);
  })
  .mapValues(module => module.Reducer)
  .value();

export default Reducers;
