import { Map } from "immutable";
import _, { get, isEmpty } from "lodash";
import { ModuleEvents } from "./Actions";
import { ModuleEvents as PaymentModule } from "../payment/Actions";

const initialState = Map({
  tables: [],
  tableAreas: [],
  mode: "saved", // edit
  selectedArea: null,
  orderView: "TABLE",
  selectedTable: null,
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.EDIT_TABLES: {
      const selectedArea = state.get("selectedArea");
      const tableAreas: any = payload;

      const index = tableAreas.tableAreas.findIndex(
        (tb) => tb.areaId === selectedArea
      );
      if (index > -1) {
        const tables = tableAreas.tableAreas[index].tables;
        return state.set("tableAreas", payload).set("tables", [...tables]);
      } else {
        const tables = tableAreas.tableAreas[0].tables;
        return state
          .set("tableAreas", payload)
          .set("tables", [...tables])
          .set("selectedArea", get(payload, "tableAreas[0].areaId", null));
      }
    }
    case ModuleEvents.SET_EDIT_MODE: {
      return state.set("mode", payload);
    }
    case ModuleEvents.SAVE_TABLES:
    case ModuleEvents.FETCH_TABLES:
    case ModuleEvents.ON_SUBSCRIPTION_TABLE: {
      const selectedArea = state.get("selectedArea");

      if (!isEmpty(selectedArea) && !payload.error) {
        const index = payload.tableAreas?.findIndex(
          (tb: any) => tb.areaId === selectedArea
        );
        if (index > -1) {
          const tables = payload.tableAreas[index].tables;
          return state.set("tableAreas", payload).set("tables", [...tables]);
        }
        return state
          .set("tableAreas", payload)
          .set("tables", get(payload, "tableAreas[0].tables", []));
      } else {
        return state
          .set("tableAreas", payload)
          .set("selectedArea", get(payload, "tableAreas[0].areaId", null))
          .set("tables", get(payload, "tableAreas[0].tables", []));
      }
    }
    case ModuleEvents.SELECT_AREA: {
      const tableAreas: any = state.get("tableAreas");
      const selectedArea = payload;
      const index = tableAreas.tableAreas.findIndex(
        (tb: any) => tb.areaId === selectedArea
      );
      let tables: any = [];
      if (index > -1) {
        tables = tableAreas.tableAreas[index].tables;
      }
      return state.set("selectedArea", selectedArea).set("tables", tables);
    }
    case ModuleEvents.SELECT_TABLE: {
      return state.set("selectedTable", payload);
    }

    case PaymentModule.CLEAR_CURRENT_APPOINTMENT: {
      return state.set("selectedTable", null);
    }

    default:
      return state;
  }
};

export default Reducer;
