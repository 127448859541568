import React, {Component, useEffect, useState} from 'react';
import styles from '../../../components/Styles/Styles.css';
import {Container, Row, Col} from 'react-grid-system';

const settingData = [
  {
    label: 'Stock Items',
    value: 'STOCK_ITEMS',
  },
  {
    label: 'Stock Records',
    value: 'STOCK_RECORDS',
  }
];

const StockCustomizedSideNav = (props) => {
  const {onClick, selectedSidebar} = props;
  const [value, setValue] = useState(settingData);

  return (
    <Row>
      <Col xs={12}>
        <ul style={{backgroundColor: '#F5F5F5', height: '84vh'}}>
          {
            value.map((item) => {
              const isSelected = item.value === selectedSidebar;
              return(
                <li style={{backgroundColor: isSelected ? "#49A4D4" : '#F5F5F5'}}>
                  <a onClick={() => onClick(item.value)} className={styles.settingsSideNavHeader} style={{color: isSelected ? "#ffffff" : "#434343"}}>{item.label}</a>
                </li>
              )
            })
          }
        </ul>
      </Col>
    </Row>
  );
};
export default StockCustomizedSideNav;
