import {salonHolidays} from './Constant';

export const ModuleEvents = {
  FETCH_SALON_HOLIDAYS: 'FETCH_SALON_HOLIDAYS',
  SELECTED_DATE_AND_TIME: 'SELECTED_DATE_AND_TIME'
};

const createAction = (type, action = () => {}, meta) => {
  return (...args) => ({ type, payload: action(...args), meta });
};

export default {
  fetchSalonHolidays: createAction(ModuleEvents.FETCH_SALON_HOLIDAYS, async (shopId, type) => {
    return {result: salonHolidays, type, selectedSalonId: shopId}
  }),
  selectedDateAndTime: createAction(ModuleEvents.SELECTED_DATE_AND_TIME, data => data)
};
