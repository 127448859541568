import React from 'react';
import BackIcon from "../../../../assets/svg/backIcon.svg";
import '../Styles.style.css';

const EditCategoryTopHeader = (props) => {
    const {onCreateService, isValid, onBack, isEdit} = props;

    return (
      <div className={"editServiceTopHeaderContainer"}>
        <div className={"editServiceTopHeader"}>
          <img src={BackIcon} onClick={() => onBack()}/>
          <div onClick={() => onBack()} className={"addServiceBtnContainer"}>
            {isEdit ? "Edit Category" : "Add Category"}
          </div>
          <div className={"saveServiceBtnContainer"}>
            <div onClick={() => onCreateService()} className={"editServiceBtnContainer"} style={{backgroundColor: isValid ? '#49A4D4' : '#8A9CA5'}}>
              <div className={"saveServiceBtnText"}>
                Save Category
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
;
export default EditCategoryTopHeader;

