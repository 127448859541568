import { Map } from "immutable";
import { nanoid } from "nanoid";
import { ModuleEvents } from "./Actions";
import {
  getModifierItems,
  checkArrayEquality,
  generatePriceCombinations,
} from "../../internal/manager/ProductManager";
import { ModuleEvents as PaymentModule } from "../payment/Actions";
import _, {
  get,
  filter,
  uniqBy,
  omit,
  isEmpty,
  orderBy,
  findIndex,
  compact,
} from "lodash";

const initialState = Map({
  stockList: [],
  catalogue: [],
  modifiers: [],
  brandsList: [],
  optionSets: [],
  productList: [],
  productType: {},
  productTypes: [],
  categoryList: [],
  selectedBrand: {},
  productImageId: "",
  supplierList: {
    supplierList: [],
    lastKey: null,
  },
  selectedProduct: {},
  orderDateAndTime: {},
  selectedSupplier: {},
  purchaseProducts: [],
  currentStockList: [],
  productModifiers: [],
  sendToKitchenData: [],
  selectedStockItem: {},
  createdProductData: {},
  modifierNextToken: null,
  productCategoryList: [],
  choossedProductItem: {},
  appointmentWarranty: [],
  productPriceOptions: [],
  favouriteProductList: [],
  selectedProductBrand: {},
  appointmentDiscounts: [],
  productPriceVariants: [],
  isOpenProductKeypad: false,
  selectedPurchaseProduct: {},
  selectedProductCategoryItem: {},
  selectedStockSideBarView: "STOCK_ITEMS",
  selectedServiceSideBarView: "ALL_PRODUCTS",
  selectedProductCategory: { categoryId: "ALL" },
});

export const Reducer = (state = initialState, action) => {
  const { payload, type, error } = action;

  switch (action.type) {
    case ModuleEvents.CREATE_BRAND: {
      if (payload && !payload.error) {
        const brandsList: any = state.get("brandsList");
        brandsList.push(payload.result);
        const sortedBrands: any = orderBy(
          brandsList,
          ["updatedTime"],
          ["desc"]
        );
        return state
          .set("brandsList", [...sortedBrands])
          .set("selectedProductBrand", _.get(sortedBrands, "[0]", {}));
      }
      return state;
    }

    case ModuleEvents.CLEAR_SELECTED_STOCK: {
      return state.set("selectedStockItem", {});
    }

    case ModuleEvents.UPDATE_CURRENT_STOCK_COUNT: {
      return state.set("currentStockList", [...payload]);
    }

    case ModuleEvents.SELECTED_STOCK_SIDE_BAR_VIEW: {
      return state.set("selectedStockSideBarView", payload);
    }

    case ModuleEvents.FETCH_SHOP_BRANDS: {
      if (payload && !payload.error) {
        const sortedBrands = _.orderBy(
          _.get(payload, "Items", []),
          ["updatedTime"],
          ["desc"]
        );
        return state
          .set("brandsList", [...sortedBrands])
          .set("selectedProductBrand", _.get(payload, "Items[0]", {}));
      }
      return state;
    }

    case ModuleEvents.CREATE_PRODUCT_CATEGORY: {
      if (payload.result && !payload.result.error) {
        const productCategoryList: any = state.get("productCategoryList");
        productCategoryList.push(payload.result);
        const sortedProductCategories = _.orderBy(
          productCategoryList,
          ["updatedTime"],
          ["desc"]
        );
        return state
          .set("productCategoryList", [...sortedProductCategories])
          .set(
            "selectedProductCategory",
            _.get(sortedProductCategories, "[0]", {})
          );
      }
      return state;
    }

    case ModuleEvents.FETCH_SHOP_PRODUCT_CATEGORIES: {
      if (payload && !payload.error) {
        const sortedProductCategories = _.orderBy(
          payload,
          ["updatedTime"],
          ["desc"]
        );
        return state
          .set("productCategoryList", [...sortedProductCategories])
          .set("selectedProductCategory", _.get(payload, "[0]", {}));
      }
      return state;
    }

    case ModuleEvents.FETCH_SHOP_PRODUCTS: {
      if (payload && !payload.error) {
        const sortedProduct = orderBy(
          _.get(payload, "Items", []),
          ["updatedTime"],
          ["desc"]
        );
        return state.set("productList", [...sortedProduct]);
      }
      return state;
    }

    case ModuleEvents.FETCH_STOCK: {
      if (payload && !payload.error) {
        const stocks = _.get(payload, "Items", []);
        const currentStockList: any = state.get("currentStockList");
        const purchaseProducts: any = state.get("purchaseProducts");
        return state
          .set("stockList", [...stocks])
          .set(
            "currentStockList",
            purchaseProducts?.length > 0 ? [...currentStockList] : [...stocks]
          );
      }
      return state;
    }

    case ModuleEvents.NAVIGATE_EDIT_STOCK_VIEW: {
      return state.set("selectedStockItem", payload);
    }

    case ModuleEvents.SELECT_PRODUCT_CATEGORY: {
      const { selectedCategory, productResult } = payload;
      const sortedProduct = orderBy(
        _.get(productResult, "Items", []),
        ["updatedTime"],
        ["desc"]
      );
      return state
        .set("selectedProductCategory", { ...selectedCategory })
        .set("productList", [...sortedProduct]);
    }

    case ModuleEvents.SEARCH_PRODUCT: {
      if (payload && !payload.error) {
        const sortedProduct = orderBy(payload, ["updatedTime"], ["desc"]);
        return state.set("productList", [...sortedProduct]);
      }
      return state;
    }

    case ModuleEvents.SET_PURCHASE_PRODUCTS: {
      const purchaseProducts: any = state.get("purchaseProducts");
      if (payload) {
        payload.forEach((product: any) => {
          let index: number;
          let filterProducts: any = [];
          if (!isEmpty(product?.opId)) {
            index = _.findIndex(
              purchaseProducts,
              (item: any) => item.opId === product.opId
            );
            filterProducts = filter(
              purchaseProducts,
              (item: any) => item.opId === product.opId
            );
          } else {
            index = _.findIndex(
              purchaseProducts,
              (item: any) => item.id === product.id
            );
            filterProducts = filter(
              purchaseProducts,
              (item: any) => item.id === product.id
            );
          }
          if (!isEmpty(product?.modifiers) && !isEmpty(filterProducts)) {
            index = findIndex(purchaseProducts, (purchaseProduct: any) => {
              let array1: any = getModifierItems(purchaseProduct.modifiers);
              let array2: any = getModifierItems(product.modifiers);
              const result = checkArrayEquality(array1, array2);
              return result;
            });
          }
          if (index > -1) {
            purchaseProducts[index] = {
              ...purchaseProducts[index],
              ...product,
            };
          } else {
            purchaseProducts.push(product);
          }
        });
        return state.set("purchaseProducts", [...purchaseProducts]);
      }
      return state;
    }

    case ModuleEvents.SELECT_PRODUCT_ITEM: {
      return state.set("choossedProductItem", payload);
    }

    case ModuleEvents.DELETE_PURCHASE_PRODUCT: {
      const purchaseProducts: any = state.get("purchaseProducts");
      if (payload) {
        const filterProduct: any = _.filter(purchaseProducts, (item: any) => {
          if (payload.pid) {
            return item.id !== payload.pid;
          } else {
            if (payload?.opId) {
              if (!isEmpty(payload.modifiers)) {
                let array1: any = getModifierItems(item.modifiers);
                let array2: any = getModifierItems(payload.modifiers);
                const result = checkArrayEquality(array1, array2);
                return !result;
              }
              return item.opId !== payload.opId;
            } else {
              if (!isEmpty(payload.modifiers)) {
                let array1: any = getModifierItems(item.modifiers);
                let array2: any = getModifierItems(payload.modifiers);
                const result = checkArrayEquality(array1, array2);
                return !result;
              }
              return item.id !== payload.id;
            }
          }
        });
        return state
          .set("purchaseProducts", [...filterProduct])
          .set("choossedProductItem", {});
      }
      return state;
    }

    case ModuleEvents.REMOVE_SELECTED_PRODUCT: {
      return state
        .set("selectedProduct", {})
        .set("productModifiers", [])
        .set("productPriceOptions", [])
        .set("productPriceVariants", [])
        .set("isOpenProductKeypad", false);
    }

    case ModuleEvents.SELECT_PRODUCT_BRAND: {
      if (payload.product && !payload.product.error) {
        const { selectedBrand, product } = payload;
        const sortedProduct = orderBy(product, ["updatedTime"], ["desc"]);
        return state
          .set("selectedProductBrand", selectedBrand)
          .set("productList", [...sortedProduct]);
      }
      return state;
    }

    case ModuleEvents.SET_APPOINTMENT_DISCOUNT: {
      const appointmentDiscounts: any = state.get("appointmentDiscounts");
      if (payload && payload.length > 0) {
        payload.forEach((discount) => {
          const index = appointmentDiscounts.findIndex(
            (item) => item.id === discount.id
          );
          if (index > -1) {
            appointmentDiscounts[index] = {
              ...appointmentDiscounts[index],
              ...discount,
            };
          } else {
            appointmentDiscounts.push(discount);
          }
        });
        return state.set("appointmentDiscounts", [...appointmentDiscounts]);
      }
      return state;
    }

    case ModuleEvents.REMOVE_APPOINTMENT_DISCOUNT: {
      const appointmentDiscounts: any = state.get("appointmentDiscounts");
      if (payload) {
        const filterProduct = _.filter(
          appointmentDiscounts,
          (item) => item.id !== payload.id
        );
        return state.set("appointmentDiscounts", [...filterProduct]);
      }
      return state;
    }

    case ModuleEvents.SET_APPOINTMENT_WARRANTY: {
      const appointmentWarranty: any = state.get("appointmentWarranty");
      if (payload && payload.length > 0) {
        payload.forEach((warranty) => {
          const index = appointmentWarranty.findIndex(
            (item) => item.id === warranty.id
          );
          if (index > -1) {
            appointmentWarranty[index] = {
              ...appointmentWarranty[index],
              ...warranty,
            };
          } else {
            appointmentWarranty.push(warranty);
          }
        });
        return state.set("appointmentWarranty", [...appointmentWarranty]);
      }
      return state;
    }

    case ModuleEvents.REMOVE_APPOINTMENT_WARRANTY: {
      const appointmentWarranty: any = state.get("appointmentWarranty");
      if (payload) {
        const filterProduct = _.filter(
          appointmentWarranty,
          (item) => item.id !== payload.id
        );
        return state.set("appointmentWarranty", [...filterProduct]);
      }
      return state;
    }

    case ModuleEvents.NAVIGATE_EDIT_SUPPLIER_VIEW: {
      if (payload) {
        return state.set("selectedSupplier", { ...payload });
      }
      return state;
    }

    case ModuleEvents.FETCH_SALON_SUPPLIERS: {
      let supplierList: any = state.get("supplierList");
      if (payload && !payload.error) {
        supplierList.supplierList = payload.Items;
        supplierList.lastKey = payload.LastEvaluatedKey;
      }
      return state.set("supplierList", { ...supplierList });
    }

    case ModuleEvents.FETCH_FAVOURITE_PRODUCTS: {
      if (payload && !payload.error) {
        return state.set("favouriteProductList", [...payload]);
      }
      return state;
    }

    case ModuleEvents.NAVIGATE_EDIT_PRODUCT_CATEGORY_VIEW: {
      return state.set("selectedProductCategoryItem", { ...payload });
    }

    case ModuleEvents.UPLOAD_PRODUCT_IMAGE: {
      if (payload && !payload.error) {
        return state.set("productImageId", payload);
      }

      return state;
    }

    case ModuleEvents.SELECT_PRODUCT_SIDE_BAR_VIEW: {
      return state.set("selectedServiceSideBarView", payload);
    }

    case ModuleEvents.REMOVE_PRODUCT_IMAGE: {
      return state.set("productImageId", "");
    }

    case ModuleEvents.CLEAR_SELECTED_PRODUCT_ITEMS: {
      return state
        .set("productType", {})
        .set("selectedBrand", {})
        .set("productImageId", "")
        .set("selectedProduct", {})
        .set("productModifiers", [])
        .set("selectedSupplier", {})
        .set("createdProductData", {})
        .set("productPriceOptions", [])
        .set("productPriceVariants", [])
        .set("selectedProductCategoryItem", {});
    }

    case ModuleEvents.NAVIGATE_EDIT_BRAND_VIEW: {
      return state.set("selectedBrand", { ...payload });
    }

    case ModuleEvents.NAVIGATE_EDIT_PRODUCT_VIEW: {
      if (payload) {
        const priceVariants: any = get(payload, "priceVariants", []);
        const productOptions: any = get(payload, "productOptions", []);
        const productModifiers: any = get(payload, "modifiers", []);
        return state
          .set("selectedProduct", { ...payload })
          .set("productModifiers", [...productModifiers])
          .set("productPriceOptions", [...productOptions])
          .set("productPriceVariants", [...priceVariants])
          .set("productImageId", payload.productImage);
      }
      return state;
    }

    case ModuleEvents.CHANGE_PRODUCT_QUANTITY: {
      const { data, status } = payload;
      const purchaseProducts: any = state.get("purchaseProducts");
      if (data && status) {
        let index: any;
        let filterProducts: any = [];
        if (!isEmpty(data?.opId)) {
          index = findIndex(
            purchaseProducts,
            (item: any) => item.opId === data.opId
          );
          filterProducts = filter(
            purchaseProducts,
            (item: any) => item.opId === data.opId
          );
        } else {
          index = findIndex(
            purchaseProducts,
            (item: any) => item.id === data.id
          );
          filterProducts = filter(
            purchaseProducts,
            (item: any) => item.id === data.id
          );
        }
        if (!isEmpty(data?.modifiers) && !isEmpty(filterProducts)) {
          index = findIndex(purchaseProducts, (product: any) => {
            let array1: any = getModifierItems(product.modifiers);
            let array2: any = getModifierItems(data.modifiers);
            const result = checkArrayEquality(array1, array2);
            return result;
          });
        }
        if (index > -1) {
          let itemQuantity = purchaseProducts[index].quantity;
          let price = purchaseProducts[index].price;
          let itemPrice = purchaseProducts[index].productSalePrice;
          purchaseProducts[index].quantity =
            status === "INCREMENT"
              ? ++itemQuantity
              : itemQuantity > 0
              ? --itemQuantity
              : itemQuantity;
          purchaseProducts[index].price =
            status === "INCREMENT"
              ? price + itemPrice
              : price > itemPrice
              ? price - itemPrice
              : price;
        }
        return state.set("purchaseProducts", [...purchaseProducts]);
      }
      return state;
    }

    case PaymentModule.CLEAR_CURRENT_APPOINTMENT: {
      return state
        .set("selectedProduct", {})
        .set("purchaseProducts", [])
        .set("productModifiers", [])
        .set("choossedProductItem", {})
        .set("appointmentWarranty", [])
        .set("productPriceOptions", [])
        .set("appointmentDiscounts", [])
        .set("productPriceVariants", [])
        .set("selectedPurchaseProduct", {});
    }

    case ModuleEvents.SELECT_PRODUCT: {
      if (payload) {
        const priceVariants = get(payload, "priceVariants", []);
        const productOptions = get(payload, "productOptions", []);
        const modifiers = get(payload, "modifiers", []);
        return state
          .set("selectedProduct", payload)
          .set("isOpenProductKeypad", true)
          .set("productModifiers", modifiers)
          .set("productPriceOptions", productOptions)
          .set("productPriceVariants", priceVariants);
      }
      return state;
    }

    case ModuleEvents.OPEN_PRODUCT_KEYPAD: {
      return state.set("isOpenProductKeypad", payload);
    }

    case ModuleEvents.CREATE_PRODUCT: {
      const purchaseProducts: any = state.get("purchaseProducts");
      if (
        payload.result &&
        !payload.result.error &&
        payload.type === "ADD_QUICK_ITEM"
      ) {
        const param = {
          tax: 0,
          quantity: 1,
          symbol1: "",
          symbol2: "",
          id: payload.result.pid,
          type: "PRODUCT",
          enteredNumber1: "",
          enteredNumber2: "",
          price: parseFloat(payload.result.itemPrice),
          productSalePrice: parseFloat(payload.result.itemPrice),
          productPrice: parseFloat(payload.result.itemPrice),
          productName: payload.result.productName,
        };
        purchaseProducts.push(param);
        return state.set("purchaseProducts", [...purchaseProducts]);
      }
      return state;
    }

    case ModuleEvents.FETCH_MENU: {
      if (payload && !payload.error) {
        const catalogue: any = _.orderBy(payload, ["position"], ["asc"]);
        return state.set("catalogue", catalogue);
      }
      return state;
    }

    case ModuleEvents.CREATE_MENU: {
      if (payload) {
        const catalogue: any = state.get("catalogue");
        catalogue.push(payload);
        const sortedCatalogue: any = _.orderBy(
          catalogue,
          ["position"],
          ["asc"]
        );
        return state.set("catalogue", sortedCatalogue);
      }
      return state;
    }

    case ModuleEvents.UPDATE_MENU: {
      if (payload) {
        const catalogue: any = state.get("catalogue");
        const index = _.findIndex(
          catalogue,
          (item: any) => item?.menuId === payload.menuId
        );
        catalogue[index] = payload;
        const sortedCatalogue: any = orderBy(catalogue, ["position"], ["asc"]);

        if (catalogue.categories) {
          catalogue.categories = orderBy(
            catalogue.categories,
            ["position"],
            ["asc"]
          );
          catalogue.categories.map((item) => {
            if (item.products) {
              item.products = orderBy(item.products, ["position"], ["asc"]);
            }
          });
        }
        return state.set("catalogue", [...sortedCatalogue]);
      }
      return state;
    }

    case ModuleEvents.DELETE_MENU: {
      if (payload) {
        const catalogue: any = state.get("catalogue");
        _.remove(catalogue, (item: any) => item?.menuId === payload.menuId);
        return state.set("catalogue", [...catalogue]);
      }
      return state;
    }

    case ModuleEvents.FETCH_PRODUCT_TYPES: {
      if (payload && !payload.error) {
        return state.set("productTypes", payload);
      }
      return state;
    }

    case ModuleEvents.NAVIGATE_EDIT_PRODUCT_TYPE_VIEW: {
      if (payload && !payload.error) {
        return state.set("productType", payload);
      }
      return state;
    }
    case ModuleEvents.SET_SEND_ITEM_DATA: {
      return state.set("sendToKitchenData", payload);
    }

    case ModuleEvents.SET_PRODUCT_OPTIONS: {
      if (payload) {
        let priceVariants: any = [];
        const selectedProduct: any = state.get("selectedProduct");
        const productPriceVariants: any = state.get("productPriceVariants");
        let oprionsArray: any = [];
        payload.forEach((productOption: any) => {
          oprionsArray.push([...productOption.options]);
        });

        if (!isEmpty(selectedProduct) || !isEmpty(productPriceVariants)) {
          let variants: any = !isEmpty(selectedProduct)
            ? selectedProduct.priceVariants
            : productPriceVariants;
          priceVariants = generatePriceCombinations(
            oprionsArray,
            variants,
            true
          );
        } else {
          priceVariants = generatePriceCombinations(oprionsArray);
        }
        return state
          .set("productPriceOptions", payload)
          .set("productPriceVariants", priceVariants);
      }
      return state;
    }

    case ModuleEvents.SET_PRICE_VARIANTS: {
      const { data } = payload;
      if (data) {
        return state.set("productPriceVariants", data);
      }
      return state;
    }
    case ModuleEvents.FETCH_SHOP_OPTION_SET: {
      if (payload && !payload.error) {
        const nextToken = get(payload, "nextToken", {});
        let newData: any = filter(
          payload.data,
          (item: any) => !isEmpty(item.id)
        );
        let newSets: any = orderBy(uniqBy(newData, "id"), ["setName"], ["asc"]);
        return state
          .set("optionSets", [...newSets])
          .set("nextToken", nextToken);
      }
      return state;
    }
    case ModuleEvents.FETCH_MODIFIERS: {
      if (payload && !payload.error) {
        const nextToken = get(payload, "nextToken", {});
        let newModifiers: any = orderBy(
          uniqBy(payload.items, "id"),
          ["setName"],
          ["asc"]
        );
        return state
          .set("modifiers", [...newModifiers])
          .set("modifierNextToken", nextToken);
      }
      return state;
    }

    case ModuleEvents.CREATE_PRODUCT_DATA: {
      if (payload) {
        return state.set("createdProductData", payload);
      }
      return state;
    }
    case ModuleEvents.ORDER_DATE_AND_TIME: {
      return state.set("orderDateAndTime", payload);
    }

    case ModuleEvents.REMOVE_ORDER_WARRANTY: {
      const purchaseProducts: any = state.get("purchaseProducts");
      if (payload) {
        const index = findIndex(
          purchaseProducts,
          (product: any) => get(product, "warranty.wrid", "") === payload.wrid
        );
        const product = omit(purchaseProducts[index], ["warranty"]);
        purchaseProducts[index] = product;
        return state.set("purchaseProducts", [...purchaseProducts]);
      }
      return state;
    }

    case ModuleEvents.SET_ORDER_WARRANTY: {
      const purchaseProducts: any = state.get("purchaseProducts");
      if (payload) {
        payload.products.map((product: any) => {
          let index = findIndex(
            purchaseProducts,
            (item: any) => item.id === product
          );
          let params = {
            wrid: nanoid(),
            title: payload.title,
            endDate: payload.date,
            shopId: payload.shopId,
            createdTime: Date.now(),
            updatedTime: Date.now(),
            description: payload.description,
          };
          if (!isEmpty(payload.wrid)) {
            params.wrid = payload.wrid;
            params.createdTime = payload.createdTime;
          }
          purchaseProducts[index]["warranty"] = params;
        });
        return state.set("purchaseProducts", [...purchaseProducts]);
      }

      return state;
    }

    case ModuleEvents.SET_OFFER_PRODUCTS: {
      const purchaseProducts: any = state.get("purchaseProducts");
      if (payload) {
        payload.forEach((product: any) => {
          purchaseProducts.push(product);
        });
        return state.set("purchaseProducts", [...purchaseProducts]);
      }

      return state;
    }

    case ModuleEvents.DELETE_OFFER_PRODUCT: {
      const purchaseProducts: any = state.get("purchaseProducts");
      if (payload) {
        const products = purchaseProducts.map((item: any) => {
          if (get(item, "offerProduct", false) && item.id === payload.id) {
            return null;
          }
          return item;
        });
        const data = compact(products);
        return state.set("purchaseProducts", [...data]);
      }
      return state;
    }
    case ModuleEvents.SET_PRODCUT_MODIFIERS: {
      if (payload) {
        return state.set("productModifiers", payload);
      }
      return state;
    }

    case ModuleEvents.SET_PURCHASE_PRODUCT_ITEM: {
      if (payload) {
        return state.set("selectedPurchaseProduct", payload);
      }
    }
    default:
      return state;
  }
};

export default Reducer;
