import React, { useEffect, useState } from 'react';
import styles from '../../Styles/ModelStyles.css';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import { connect } from 'react-redux';
import { Actions } from '../../../internal/app/Actions';
import _ from 'lodash';
import { SelectVehicleModalContainer } from '../../Styles/Styled';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { useIntl } from 'react-intl';
import IconRadioCheck from '../../../../assets/svg/IconRadioCheck.svg'
import IconRadioUncheck from '../../../../assets/svg/IconRadioUncheck.svg'

const customStyles = {
  content: {
    top: '10vh',
    left: 'calc(50% - 264px)',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const SelectVehicleModal = (props) => {
  const {
    isOpen,
    closeModal,
    onSelectedVehicle,
    onCreateVehicle,
    vehicles,
    selectedVehicle,
  } = props;
  const [clickedVehicle, setClickedVehicle] = useState({});
  const [mileage, setMileage] = useState('');
  const [nextService, setNextService] = useState('');
  const {formatMessage: f} = useIntl();

  useEffect(() => {
    if (!_.isEmpty(selectedVehicle)) {
      setClickedVehicle(selectedVehicle);
      setMileage(selectedVehicle.mileage);
    }
  }, [selectedVehicle]);

  const onClickVehicle = (vehicle) => {
    const isSelected = _.get(clickedVehicle, 'vid', '') === vehicle.vid;
    if (!isSelected) {
      setMileage(vehicle.mileage);
      setClickedVehicle(vehicle);
    } else {
      setClickedVehicle({});
      setMileage('');
    }
  };

  const addVehicle = () => {
    clickedVehicle.mileage = parseFloat(mileage);
    clickedVehicle.nextService = parseFloat(nextService);
    onSelectedVehicle(clickedVehicle);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.selectVehicleModal}
    >
      <SelectVehicleModalContainer>
        <div>
          <div>{f({id: "ORDER.VEHICLES.SELECT_VEHICLES"})}</div>
          <img onClick={() => closeModal()} src={CANCEL} />
        </div>
        <div>
          <button onClick={() => onCreateVehicle()}>{f({id: "ORDER.VEHICLES.CREATE_VEHICLE"})}</button>
        </div>

        <div>
          {!_.isEmpty(vehicles)
            ? vehicles.map((vehicle) => {
                const isSelected =
                  _.get(clickedVehicle, 'vid', '') === vehicle.vid;
                const title = `${vehicle.modal} ${vehicle.plateNumber}`;

                return (
                  <div onClick={() => onClickVehicle(vehicle)}>
                    <Checkbox
                      checked={isSelected}
                      icon={<img src={IconRadioUncheck}/>}
                      checkedIcon={<img src={IconRadioCheck}/>}
                      onChange={() => onClickVehicle(vehicle)}
                    />
                    <div>{title}</div>
                  </div>
                );
              })
            : null}
        </div>

        <div>
          {clickedVehicle?.vid && (
            <>
              <div>
                <div>{f({id: "ORDER.VEHICLES.CURRENT_MILEAGE"})}</div>
                <input
                  value={mileage}
                  onChange={(number) => setMileage(number.target.value)}
                />
              </div>
              <div>
                <div>{f({id: "ORDER.VEHICLES.NEXT_SERVICE"})}</div>
                <input
                  value={nextService}
                  onChange={(number) => setNextService(number.target.value)}
                />
              </div>
            </>
          )}
        </div>

        <div>
          <button onClick={() => addVehicle()}>
            <div>{f({id: "ORDER.VEHICLES.ADD_VEHICLE"})}</div>
          </button>
          <button onClick={() => closeModal()}>
            <div>{f({id: "ORDER.VEHICLES.CANCEL"})}</div>
          </button>
        </div>
      </SelectVehicleModalContainer>
    </ReactModal>
  );
};
export default connect(
  (state) => ({
    shopData: state.login.get('merchantShop'),
    vehicles: state.orders.get('vehicles'),
    selectedVehicle: state.orders.get('selectedVehicle'),
  }),
  {}
)(SelectVehicleModal);
