import { _ } from './AppUtils';
import { AppModules } from './AppModules';

export const Actions = _(AppModules)
  .keyBy(module => module)
  .mapValues(module => {
    return require(`../../modules/${module}/Actions`);
  })
  .mapValues(module => module.default)
  .value();

