import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import moment from 'moment';
import {AppointmentCardContainer} from '../Styles/Styled';

const AppointmentCard = (props) => {
  const {data, componentHeight, componentTop} = props;
  const clientName = _.get(data, 'name', '');
  const end = moment(`${_.get(data, 'time', [])}`, 'HH:mm').add(data.duration, 'm').format('hh:mmA');
  const start = moment(`${_.get(data, 'time', [])}`, 'HH:mm').format('hh:mmA');

  return (
    <AppointmentCardContainer componentHeight={`${componentHeight}px`} componentTop={`${componentTop}px`} status={data.orderStatus}>
      <div>
        <hr/>
        <div>
          <div>{clientName}</div>
          <div className={'startEndTime'}>{`${start}-${end}`}</div>
          {
            _.get(data, 'serviceDetails', []).map((item) => {
              return (
                <div>{item.serviceTitle || item.title}</div>
              )
            })
          }
        </div>
      </div>
    </AppointmentCardContainer>

  );
};
export default connect(state =>
    ({}),
  ({}),
)(AppointmentCard);
