import React, {useEffect, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import {connect} from "react-redux";
import {Actions} from "../../../internal/app/Actions";
import _ from 'lodash';
import {SelectDateAndTimeModalContainer} from '../../Styles/Styled';
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import Calendar from "react-calendar";
import {TimePicker} from "antd";
import moment from 'moment';

const customStyles = {
  content: {
    top: '10vh',
    left: 'calc(50% - 400px)',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },
};

const SelectDateAndTimeModal = (props) => {
  const {isOpen, closeModal, onSelectedDateTime, appointmentDateAndTime} = props;
  const [dateTime, setDateTime] = useState({});
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(appointmentDateAndTime)) {
      setAppointmentDate(moment(appointmentDateAndTime.date)._d);
      setDuration(moment(appointmentDateAndTime.time, 'hh:mm A'));
    } else {
      setAppointmentDate(new Date());
      setDuration(null);
    }
  }, [appointmentDateAndTime]);

  const setTime = (text) => {
    setDuration(text);
    const date = moment(appointmentDate.toDateString()).valueOf();
    setDateTime({date: date, time: moment(text).format('hh:mm A')});
  };

  const setDate = (date) => {
    setAppointmentDate(date);
    const dateValue = moment(date.toDateString()).valueOf();
    setDateTime({
      date: dateValue,
      time: !_.isEmpty(duration) ? moment(duration).format('hh:mm A') : moment().format('hh:mm A')
    });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.selectDateAndTimeModal}
    >
      <SelectDateAndTimeModalContainer>
        <div>
          <div>{'Select Date And Time'}</div>
          <img onClick={() => closeModal()} src={CANCEL}/>
        </div>
        <div>
          <Calendar
            minDate={new Date()}
            value={appointmentDate}
            style={{width: '100%'}}
            className={"react-calendar"}
            onChange={(date) => setDate(date)}
          />
          <div/>

          <div>
            <TimePicker
              value={duration}
              format={"HH:mm"}
              placeholder="Duration"
              className={'timePickerStyle'}
              onChange={(text) => setTime(text)}
            />
          </div>
        </div>
        <div>
          <button onClick={() => onSelectedDateTime(dateTime)}>
            <div>{'Select Date And Time'}</div>
          </button>
          <button onClick={() => closeModal()}>
            <div>{'Cancel'}</div>
          </button>
        </div>
      </SelectDateAndTimeModalContainer>
    </ReactModal>
  );
};
export default connect(
  state => ({
    shopData: state.login.get('merchantShop'),
    currentUser: state.login.get('currentUser'),
    employees: state.employee.get('employees'),
    selectedEmployee: state.employee.get('selectedEmployee'),
    appointmentDateAndTime: state.salon.get('appointmentDateAndTime'),
  }),
  ({})
)(SelectDateAndTimeModal);
