import React, {useEffect} from 'react';
import '../../product/Styles.style.css';
import _ from 'lodash';
import {connect} from "react-redux";
import {Row, Col} from 'react-grid-system';
import {Actions} from "../../../internal/app/Actions";
import EditIcon from '../../../../assets/svg/categoryEditIcon.svg';
import {getTotalPrice, mapStockItemText} from "../../../internal/manager/ProductManager";
import moment from 'moment';

const StockRecordTableView = (props) => {
  const {shop, stockRecordList, navigateToEditStockRecordView, fetchStock} = props;

  useEffect(() => {
    if (!_.isEmpty(shop.shopId)) {
      fetchStock(shop.shopId);
    }
  }, [shop]);

  const navigateEditScreen = (category) => {
    navigateToEditStockRecordView(category)
  };

  return (
    <div className="categoryTableContainer">
      <div className="tableHeaderContainer">
        <Row>
          <Col xs={3} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">
              Created Date
            </div>
          </Col>
          <Col xs={5} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">
              Items
            </div>
          </Col>
          <Col xs={3} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">
              Total
            </div>
          </Col>
          <Col xs={1}/>
        </Row>
      </div>
      <div className="categoryTableBodyContainer">
        {
          stockRecordList.map((stock) => {
            const stockRecords = _.get(stock, 'stockRecords', []);
            const total = getTotalPrice(stockRecords);
            const itemText = mapStockItemText(stockRecords);
            return (
              <div className="categoryTableBody">
                <Row>
                  <Col xs={3} className="categoryTableHeaderTextContainer">
                    <div className="categoryTableBodyText">
                      {moment(stock.createdTime).format("DD/MM/YYYY - LT")}
                    </div>
                  </Col>
                  <Col xs={5} className="categoryTableHeaderTextContainer">
                    <div className="categoryTableBodyText">
                      {_.truncate(itemText, {length: 50})}
                    </div>
                  </Col>
                  <Col xs={3} className="categoryTableHeaderTextContainer">
                    <div className="categoryTableBodyText">
                      {total > 0 ? (Math.round(total * 100) / 100).toFixed(2) : "0.00" }
                    </div>
                  </Col>
                  <Col xs={1} className="categoryTableHeaderTextContainer">
                    <div className="categoryEditIcon" onClick={() => navigateEditScreen(stock)}>
                      <img src={EditIcon}/>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })
        }
        <div className="stockRecordEmptyContainer">
          {
            stockRecordList.length === 0 ?
              <div className="noStockRecordText">
                No stock records available!
              </div>
              : null
          }
        </div>
      </div>
    </div>
  );
};
export default connect(
  state => ({
    stockRecordList: state.stock.get('stockRecordList'),
    loadingAction: state.common.get('loadingAction'),
    shop: state.login.get('merchantShop'),
  }),
  {
    navigateToEditStockRecordView: Actions.stock.navigateToEditStockRecordView,
    fetchStock: Actions.product.fetchStock
  }
)(StockRecordTableView);

