import React from 'react';
import styles from '../../Styles/Styles.css';

const ServiceSubHeader = (props) => {
  const {header, selectedSidebar, onClear} = props;

  return (
    <div className={styles.serviceSubHeaderContainer}>
      <div className={styles.subHeaderWrapper}>
        <div className={styles.subHeaderNewText}>{header}</div>
      </div>
      {
        selectedSidebar === "CATEGORIES" ?
          <div onClick={() => onClear()} className={styles.subHeaderAddCategoryBtn}>
            <div className={styles.subHeaderAddCategoryBtnText}>
              Add Category
            </div>
          </div>
          : null
      }
    </div>
  );
};
export default ServiceSubHeader;
