import ProductRepository from "../../internal/repos/ProductRepository";
import { mapMenu } from "../../internal/manager/CategoryManager";
import { createAction } from "../../internal/app/AppUtils";
import ModifierRepository from "../../internal/repos/ModifierRepository";

export const ModuleEvents = {
  CREATE_BRAND: "CREATE_BRAND",
  FETCH_SHOP_BRANDS: "FETCH_SHOP_BRANDS",
  CREATE_PRODUCT_CATEGORY: "CREATE_PRODUCT_CATEGORY",
  FETCH_SHOP_PRODUCT_CATEGORIES: "FETCH_SHOP_PRODUCT_CATEGORIES",
  UPLOAD_PRODUCT_IMAGE: "UPLOAD_PRODUCT_IMAGE",
  CREATE_PRODUCT: "CREATE_PRODUCT",
  FETCH_SHOP_PRODUCTS: "FETCH_SHOP_PRODUCTS",

  SEARCH_PRODUCT: "SEARCH_PRODUCT",
  SELECT_PRODUCT_CATEGORY: "SELECT_PRODUCT_CATEGORY",
  SET_PURCHASE_PRODUCTS: "SET_PURCHASE_PRODUCTS",
  DELETE_PURCHASE_PRODUCT: "DELETE_PURCHASE_PRODUCT",
  SELECT_PRODUCT_ITEM: "SELECT_PRODUCT_ITEM",
  SELECT_PRODUCT_BRAND: "SELECT_PRODUCT_BRAND",
  SET_APPOINTMENT_DISCOUNT: "SET_APPOINTMENT_DISCOUNT",
  REMOVE_APPOINTMENT_DISCOUNT: "REMOVE_APPOINTMENT_DISCOUNT",

  SELECT_PRODUCT: "SELECT_PRODUCT",
  OPEN_PRODUCT_KEYPAD: "OPEN_PRODUCT_KEYPAD",
  REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
  CHANGE_PRODUCT_QUANTITY: "CHANGE_PRODUCT_QUANTITY",
  SELECT_PRODUCT_SIDE_BAR_VIEW: "SELECT_PRODUCT_SIDE_BAR_VIEW",
  CLEAR_SELECTED_PRODUCT_ITEMS: "CLEAR_SELECTED_PRODUCT_ITEMS",
  FETCH_SALON_BRAND: "FETCH_SALON_BRAND",
  NAVIGATE_EDIT_BRAND_VIEW: "NAVIGATE_EDIT_BRAND_VIEW",
  UPDATE_BRAND: "UPDATE_BRAND",
  DELETE_BRAND: "DELETE_BRAND",
  NAVIGATE_EDIT_PRODUCT_VIEW: "NAVIGATE_EDIT_PRODUCT_VIEW",
  CLEAR_SELECTED_PRODUCT_CATEGORY: "CLEAR_SELECTED_PRODUCT_CATEGORY",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  REMOVE_PRODUCT_IMAGE: "REMOVE_PRODUCT_IMAGE",
  NAVIGATE_EDIT_PRODUCT_CATEGORY_VIEW: "NAVIGATE_EDIT_PRODUCT_CATEGORY_VIEW",
  UPDATE_PRODUCT_CATEGORY: "UPDATE_PRODUCT_CATEGORY",
  DELETE_PRODUCT_CATEGORY: "DELETE_PRODUCT_CATEGORY",
  FETCH_FAVOURITE_PRODUCTS: "FETCH_FAVOURITE_PRODUCTS",
  FETCH_SALON_SUPPLIERS: "FETCH_SALON_SUPPLIERS",
  CREATE_SUPPLIER: "CREATE_SUPPLIER",
  UPDATE_SUPPLIER: "UPDATE_SUPPLIER",
  DELETE_SUPPLIER: "DELETE_SUPPLIER",
  NAVIGATE_EDIT_SUPPLIER_VIEW: "NAVIGATE_EDIT_SUPPLIER_VIEW",

  SET_APPOINTMENT_WARRANTY: "SET_APPOINTMENT_WARRANTY",
  REMOVE_APPOINTMENT_WARRANTY: "REMOVE_APPOINTMENT_WARRANTY",

  FETCH_MENU: "FETCH_MENU",
  CREATE_MENU: "CREATE_MENU",
  UPDATE_MENU: "UPDATE_MENU",
  DELETE_MENU: "DELETE_MENU",

  FETCH_PRODUCT_TYPES: "FETCH_PRODUCT_TYPES",
  CREATE_PRODUCT_TYPES: "CREATE_PRODUCT_TYPES",
  UPDATE_PRODUCT_TYPES: "UPDATE_PRODUCT_TYPES",
  DELETE_PRODUCT_TYPES: "DELETE_PRODUCT_TYPES",
  NAVIGATE_EDIT_PRODUCT_TYPE_VIEW: "NAVIGATE_EDIT_PRODUCT_TYPE_VIEW",

  FETCH_STOCK: "FETCH_STOCK",
  CREATE_STOCK: "CREATE_STOCK",
  DELETE_STOCK: "DELETE_STOCK",
  CLEAR_SELECTED_STOCK: "CLEAR_SELECTED_STOCK",
  NAVIGATE_EDIT_STOCK_VIEW: "NAVIGATE_EDIT_STOCK_VIEW",
  UPDATE_CURRENT_STOCK_COUNT: "UPDATE_CURRENT_STOCK_COUNT",
  SELECTED_STOCK_SIDE_BAR_VIEW: "SELECTED_STOCK_SIDE_BAR_VIEW",

  SET_SEND_ITEM_DATA: "SET_SEND_ITEM_DATA",

  SET_PRICE_VARIANTS: "SET_PRICE_VARIANTS",
  SET_PRODUCT_OPTIONS: "SET_PRODUCT_OPTIONS",

  FETCH_SHOP_OPTION_SET: "FETCH_SHOP_OPTION_SET",

  SET_OFFER_PRODUCTS: "SET_OFFER_PRODUCTS",
  DELETE_OFFER_PRODUCT: "DELETE_OFFER_PRODUCT",

  SET_ORDER_WARRANTY: "SET_ORDER_WARRANTY",
  CREATE_PRODUCT_DATA: "CREATE_PRODUCT_DATA",
  ORDER_DATE_AND_TIME: "ORDER_DATE_AND_TIME",
  REMOVE_ORDER_WARRANTY: "REMOVE_ORDER_WARRANTY",

  SET_PURCHASE_MODIFIERS: "SET_PURCHASE_MODIFIERS",

  SET_PURCHASE_PRODUCT_ITEM: "SET_PURCHASE_PRODUCT_ITEM",

  FETCH_MODIFIERS: "FETCH_MODIFIERS",
  SET_PRODCUT_MODIFIERS: "SET_PRODCUT_MODIFIERS",
};

const Actions = {
  createBrand: createAction(
    ModuleEvents.CREATE_BRAND,
    async (param, type = "") => {
      const result = await ProductRepository.createBrand(param);
      return { result, type };
    },
    { loading: true }
  ),
  fetchShopBrands: createAction(
    ModuleEvents.FETCH_SHOP_BRANDS,
    async (shopId) => {
      const result = await ProductRepository.fetchBrandsForShopId({
        shopId,
        endPointName: "FETCH_SHOP_BRANDS",
      });
      return result;
    },
    { loading: true }
  ),
  createProductCategory: createAction(
    ModuleEvents.CREATE_PRODUCT_CATEGORY,
    async (param, type = "") => {
      const result = await ProductRepository.createProductCategory(param);
      return { result, type };
    },
    { loading: true }
  ),
  fetchShopProductCategories: createAction(
    ModuleEvents.FETCH_SHOP_PRODUCT_CATEGORIES,
    async (shopId) => {
      const result = await ProductRepository.fetchProductCategories(shopId);
      return result;
    },
    { loading: true }
  ),
  uploadProductImage: createAction(
    ModuleEvents.UPLOAD_PRODUCT_IMAGE,
    async (file) => {
      const result = await ProductRepository.uploadProductImage(file);
      return result;
    },
    { loading: true }
  ),
  createProduct: createAction(
    ModuleEvents.CREATE_PRODUCT,
    async (param, type = "") => {
      const result = await ProductRepository.createProduct(param);
      return { result, type };
    },
    { loading: true, blocking: true, type: "loading" }
  ),
  fetchShopProducts: createAction(
    ModuleEvents.FETCH_SHOP_PRODUCTS,
    async (shopId) => {
      const result = await ProductRepository.fetchAllProducts(shopId);
      return result;
    },
    { loading: true }
  ),
  navigateEditProductCategoryView: createAction(
    ModuleEvents.NAVIGATE_EDIT_PRODUCT_CATEGORY_VIEW,
    async (data) => {
      return data;
    },
    { loading: true }
  ),
  navigateEditBrandView: createAction(
    ModuleEvents.NAVIGATE_EDIT_BRAND_VIEW,
    async (data) => {
      return data;
    }
  ),
  searchProduct: createAction(
    ModuleEvents.SEARCH_PRODUCT,
    async (searchText, categoryId, shopId) => {
      const result = await ProductRepository.searchProduct({
        searchText,
        categoryId,
        shopId,
      });
      return result;
    },
    { loading: true }
  ),
  selectProductCategory: createAction(
    ModuleEvents.SELECT_PRODUCT_CATEGORY,
    async (selectedCategory) => {
      let productResult = [];
      if (selectedCategory.categoryId === "ALL") {
        productResult = await ProductRepository.fetchAllProducts(
          selectedCategory.shopId
        );
      } else {
        productResult = await ProductRepository.fetchProductForCategoryId(
          selectedCategory.shopId,
          selectedCategory.categoryId
        );
      }
      return { selectedCategory, productResult };
    }
  ),
  setPurchaseProducts: createAction(
    ModuleEvents.SET_PURCHASE_PRODUCTS,
    async (data: any) => {
      return data;
    }
  ),
  deletePurchaseProduct: createAction(
    ModuleEvents.DELETE_PURCHASE_PRODUCT,
    async (data: any) => {
      return data;
    }
  ),
  setOfferProducts: createAction(
    ModuleEvents.SET_OFFER_PRODUCTS,
    async (data: any) => {
      return data;
    }
  ),
  deleteOfferProduct: createAction(
    ModuleEvents.DELETE_OFFER_PRODUCT,
    async (data: any) => {
      return data;
    }
  ),
  selectProductItem: createAction(
    ModuleEvents.SELECT_PRODUCT_ITEM,
    async (data: any) => {
      return data;
    }
  ),
  updateBrand: createAction(ModuleEvents.UPDATE_BRAND, async (param) => {
    const result = await ProductRepository.updateBrand(param);
    return result;
  }),
  deleteBrand: createAction(ModuleEvents.DELETE_BRAND, async (param) => {
    const result = await ProductRepository.deleteBrand(param);
    return result;
  }),
  selectProductBrand: createAction(
    ModuleEvents.SELECT_PRODUCT_BRAND,
    async (selectedBrand: any) => {
      const product = await ProductRepository.fetchProductForBrandId(
        selectedBrand.shopId,
        selectedBrand.id
      );
      return { selectedBrand, product };
    }
  ),
  selectProduct: createAction(ModuleEvents.SELECT_PRODUCT, async (product) => {
    return product;
  }),
  openProductKeyPad: createAction(
    ModuleEvents.OPEN_PRODUCT_KEYPAD,
    async (status: any) => {
      return status;
    }
  ),
  removeSelectedProduct: createAction(
    ModuleEvents.REMOVE_SELECTED_PRODUCT,
    async () => {
      return true;
    }
  ),
  changeProductQuantity: createAction(
    ModuleEvents.CHANGE_PRODUCT_QUANTITY,
    async (data: any, status: any) => {
      return { data, status };
    }
  ),
  selectProductSideBarView: createAction(
    ModuleEvents.SELECT_PRODUCT_SIDE_BAR_VIEW,
    async (view: any) => {
      return view;
    }
  ),

  clearSelectedProductItem: createAction(
    ModuleEvents.CLEAR_SELECTED_PRODUCT_ITEMS,
    async (path: any) => {
      return path;
    }
  ),

  navigateEditProductView: createAction(
    ModuleEvents.NAVIGATE_EDIT_PRODUCT_VIEW,
    async (data: any) => {
      return data;
    }
  ),
  clearSelectedProductCategory: createAction(
    ModuleEvents.CLEAR_SELECTED_PRODUCT_CATEGORY,
    async (path: any) => {
      return path;
    }
  ),
  updateProduct: createAction(
    ModuleEvents.UPDATE_PRODUCT,
    async (param) => {
      const result = await ProductRepository.updateProduct(param);
      return result;
    },
    { loading: true }
  ),
  deleteProduct: createAction(
    ModuleEvents.DELETE_PRODUCT,
    async (param) => {
      const result = await ProductRepository.deleteProduct(param);
      return result;
    },
    { loading: true }
  ),

  removeProductImage: createAction(
    ModuleEvents.REMOVE_PRODUCT_IMAGE,
    async () => {
      return true;
    }
  ),

  updateProductCategory: createAction(
    ModuleEvents.UPDATE_PRODUCT_CATEGORY,
    async (param) => {
      const result = await ProductRepository.updateProductCategory(param);
      return result;
    },
    { loading: true }
  ),
  deleteProductCategory: createAction(
    ModuleEvents.DELETE_PRODUCT_CATEGORY,
    async (param) => {
      const result = await ProductRepository.deleteProductCategory(param);
      return result;
    },
    { loading: true }
  ),
  fetchFavoriteProducts: createAction(
    ModuleEvents.FETCH_FAVOURITE_PRODUCTS,
    async (salonId) => {
      const result = await ProductRepository.fetchFavoriteProducts(salonId);
      return result;
    },
    { loading: true }
  ),
  fetchSalonSuppliers: createAction(
    ModuleEvents.FETCH_SALON_SUPPLIERS,
    async (salonId) => {
      const result = await ProductRepository.fetchSalonSuppliers(
        salonId,
        "FETCH_SALON_SUPPLIERS"
      );
      return result;
    }
  ),
  createSupplier: createAction(ModuleEvents.CREATE_SUPPLIER, async (param) => {
    const result = await ProductRepository.createSupplier(param);
    return result;
  }),
  updateSupplier: createAction(ModuleEvents.UPDATE_SUPPLIER, async (param) => {
    const result = await ProductRepository.updateSupplier(param);
    return result;
  }),
  deleteSupplier: createAction(ModuleEvents.DELETE_SUPPLIER, async (param) => {
    const result = await ProductRepository.deleteSupplier(param);
    return result;
  }),
  navigateEditSupplierView: createAction(
    ModuleEvents.NAVIGATE_EDIT_SUPPLIER_VIEW,
    async (data) => {
      return data;
    }
  ),

  setAppointmentDiscount: createAction(
    ModuleEvents.SET_APPOINTMENT_DISCOUNT,
    async (data) => {
      return data;
    },
    { loading: true }
  ),
  removeAppointmentDiscount: createAction(
    ModuleEvents.REMOVE_APPOINTMENT_DISCOUNT,
    async (data) => {
      return data;
    },
    { loading: true }
  ),

  setAppointmentWarranty: createAction(
    ModuleEvents.SET_APPOINTMENT_WARRANTY,
    async (data) => {
      return data;
    },
    { loading: true }
  ),

  removeAppointmentWarranty: createAction(
    ModuleEvents.REMOVE_APPOINTMENT_WARRANTY,
    async (data) => {
      return data;
    },
    { loading: true }
  ),

  fetchMenu: createAction(
    ModuleEvents.FETCH_MENU,
    async (shopId) => {
      const result = await ProductRepository.fetchMenu(shopId);
      return result;
    },
    { loading: true }
  ),

  createMenu: createAction(
    ModuleEvents.CREATE_MENU,
    async (params) => {
      const result = await ProductRepository.createMenu(params);
      return result;
    },
    { loading: true }
  ),

  updateMenu: createAction(
    ModuleEvents.UPDATE_MENU,
    async (param) => {
      const menu = mapMenu(param);
      const result = await ProductRepository.updateMenu(menu);
      return result;
    },
    { loading: true }
  ),

  deleteMenu: createAction(
    ModuleEvents.DELETE_MENU,
    async (param) => {
      const result = await ProductRepository.deleteMenu(param);
      return result;
    },
    { loading: true }
  ),

  fetchProductTypes: createAction(
    ModuleEvents.FETCH_PRODUCT_TYPES,
    async (shopId) => {
      const result = await ProductRepository.fetchProductTypes(shopId);
      return result;
    },
    { loading: true }
  ),

  createProductType: createAction(
    ModuleEvents.CREATE_PRODUCT_TYPES,
    async (params, type = null) => {
      const result = await ProductRepository.createProductType(params);
      if (result && !result.error) {
        result.type = type;
      }

      return result;
    },
    { loading: true }
  ),

  updateProductType: createAction(
    ModuleEvents.UPDATE_PRODUCT_TYPES,
    async (param) => {
      const result = await ProductRepository.updateProductType(param);
      return result;
    },
    { loading: true }
  ),

  deleteProductType: createAction(
    ModuleEvents.DELETE_PRODUCT_TYPES,
    async (param) => {
      const result = await ProductRepository.deleteProductType(param);
      return result;
    },
    { loading: true }
  ),

  navigateToEditProductType: createAction(
    ModuleEvents.NAVIGATE_EDIT_PRODUCT_TYPE_VIEW,
    async (params) => {
      return params;
    },
    { loading: true }
  ),
  navigateToEditStockView: createAction(
    ModuleEvents.NAVIGATE_EDIT_STOCK_VIEW,
    async (params) => {
      return params;
    },
    { loading: true }
  ),
  createStock: createAction(
    ModuleEvents.CREATE_STOCK,
    async (param, isNavigate = true) => {
      const result = await ProductRepository.createStock(param);
      return { result, isNavigate };
    },
    { loading: true }
  ),
  fetchStock: createAction(
    ModuleEvents.FETCH_STOCK,
    async (shopId) => {
      const result = await ProductRepository.fetchStock(shopId);
      return result;
    },
    { loading: true }
  ),
  deleteStock: createAction(
    ModuleEvents.DELETE_STOCK,
    async (param) => {
      const result = await ProductRepository.deleteStock(param);
      return result;
    },
    { loading: true }
  ),
  clearSelectedStock: createAction(
    ModuleEvents.CLEAR_SELECTED_STOCK,
    async () => {
      return true;
    }
  ),
  selectStockSideBarView: createAction(
    ModuleEvents.SELECTED_STOCK_SIDE_BAR_VIEW,
    async (type) => {
      return type;
    }
  ),
  updateCurrentStockCount: createAction(
    ModuleEvents.UPDATE_CURRENT_STOCK_COUNT,
    async (stock) => {
      return stock;
    }
  ),
  setSendItemData: createAction(
    ModuleEvents.SET_SEND_ITEM_DATA,
    async (data: any) => {
      return data;
    }
  ),
  setProductOptions: createAction(
    ModuleEvents.SET_PRODUCT_OPTIONS,
    async (data: any) => {
      return data;
    }
  ),
  setPriceVariants: createAction(
    ModuleEvents.SET_PRICE_VARIANTS,
    async (data: any, navigation: boolean = true) => {
      return { data, navigation };
    }
  ),
  createProductData: createAction(
    ModuleEvents.CREATE_PRODUCT_DATA,
    async (data: any) => {
      return data;
    }
  ),
  fetchShopOptionSets: createAction(
    ModuleEvents.FETCH_SHOP_OPTION_SET,
    async (shopId: string, limit: number = 30, nextToken: any = null) => {
      const result = await ProductRepository.fetchShopOptionSet(
        shopId,
        limit,
        nextToken
      );
      return result;
    },
    { loading: true }
  ),
  setOrderDateAndTime: createAction(
    ModuleEvents.ORDER_DATE_AND_TIME,
    async (data: any) => {
      return data;
    }
  ),
  removeOrderWarranty: createAction(
    ModuleEvents.REMOVE_ORDER_WARRANTY,
    async (data: any) => {
      return data;
    },
    { loading: true }
  ),
  setOrderWarranty: createAction(
    ModuleEvents.SET_ORDER_WARRANTY,
    async (data: any) => {
      return data;
    },
    { loading: true }
  ),
  setProductModifiers: createAction(
    ModuleEvents.SET_PRODCUT_MODIFIERS,
    async (data: any) => {
      return data;
    },
    { loading: true }
  ),
  fetchModifiers: createAction(
    ModuleEvents.FETCH_MODIFIERS,
    async (shopId: string, limit: number = 100, nextToken: any = null) => {
      const result = await ModifierRepository.fetchModifiers(
        shopId,
        limit,
        nextToken
      );
      return result;
    }
  ),
  setPurchaseProductItem: createAction(ModuleEvents.SET_PURCHASE_PRODUCT_ITEM, async (data: any) => {
    return data;
  }),
};

export default Actions;
