import styled from "styled-components";
import BaseColors from "../../../internal/helpers/BaseColors";

export const DiscountRowItem = styled.div`
  width: 100%;
  padding-top: 1rem;
  & > h2 {
  }

  & > div {
    display: flex;
    justify-content: space-between;
  }
  
  & > div > p {
    margin-right: 2rem;
  }

  & > div > button {
    border-radius: 1rem;
    border: 1px solid ${BaseColors.gunsmoke};
    color: ${BaseColors.gunsmoke};
    background: ${BaseColors.white};
    padding: 0.3rem 1rem;
  }
  & > div > button:hover {
    cursor: pointer;
    background-color: ${BaseColors.gunsmoke};
    color: ${BaseColors.white};
  }
`;
