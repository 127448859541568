import React, { useState } from "react";
import { connect } from "react-redux";
import { CenterContainer } from "../../../components";
import { Actions } from "../../../internal/app/Actions";
import { Container, Row, Col } from "react-grid-system";
import styles from "../../../components/Common/Style/Styles.css";
import FormInput from "../../../components/input/Views/formInput";
import { validateName } from "../../../internal/validator/Validate";
import StorageService from "../../../internal/service/Storage.service";
import { navigate } from "../../../internal/service/Navigation.service";
import SignInButton from "../../../components/Buttons/Views/SignInButton";
import SignInHeader from "../../../components/headers/Views/signInHeader";

const OnboardView = (props: any) => {
  const { merchantOnboard, loadingAction } = props;
  const [pin, setPin] = useState("");
  const [token, setToken] = useState("");
  const [isValidPin, setIsValidPin] = useState(true);
  const [isValidToken, setIsValidToken] = useState(true);

  const setValue = async (type, text) => {
    if (type === "PIN") {
      const isValid = await validateName(text);
      setPin(text);
      setIsValidPin(isValid.isValid);
    } else if (type === "TOKEN") {
      const isValid = await validateName(text);
      setToken(text);
      setIsValidToken(isValid.isValid);
    }
  };

  const checkValid = async () => {
    const isPinValid = await validateName(pin);
    const isTokenValid = await validateName(token);
    setIsValidPin(isPinValid.isValid);
    setIsValidToken(isTokenValid.isValid);
    return isPinValid.isValid && isTokenValid.isValid;
  };

  const isLoading =
    loadingAction.loading && loadingAction.action.type === "MERCHANT_ONBOARD";

  const userLogin = async () => {
    const isValid = await checkValid();
    if (!isValid || isLoading) {
      return;
    }
    navigate("/progress");
    merchantOnboard(pin, token);
  };

  const togglePreference = () => {
    StorageService.setItem("showPreference", true);
  };

  return (
    <div className={styles.background}>
      <CenterContainer width="592px">
        <Container>
          <Row>
            <Col xs={12} className={styles.headerAligh}>
              <SignInHeader />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={styles.forUserName}>
              <FormInput
                onChangeText={(text) => setValue("PIN", text)}
                isValid={isValidPin}
                validationText={!isValidPin ? "Pin must be required" : null}
                value={pin}
                placeholder="Pin"
                text={"PIN *"}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={styles.forPasscode}>
              <FormInput
                type={"password"}
                text={"TOKEN *"}
                placeholder="Token"
                value={token}
                onChangeText={(text) => setValue("TOKEN", text)}
                isValid={isValidToken}
                validationText={
                  !isValidToken
                    ? !token
                      ? "Token must be required"
                      : "Enter valid token"
                    : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              className={styles.forPasscode}
              onClick={() => userLogin()}
            >
              <SignInButton loading={isLoading} text="Onboard" />
            </Col>
          </Row>
        </Container>
      </CenterContainer>
      {!window?.api ? (
        <div
          onClick={() => togglePreference()}
          className={styles.preferenceText}
        >
          {"Change preference"}
        </div>
      ) : null}
    </div>
  );
};

export default connect(
  (state: any) => ({
    loadingAction: state.common.get("loadingAction"),
  }),
  {
    merchantOnboard: Actions.login.merchantOnboard,
  }
)(OnboardView);
