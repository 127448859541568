import React from 'react';
import {List, Map} from 'immutable';
import {ModuleEvents} from './Actions';
import {ModuleEvents as PaymentModule} from "../payment/Actions";
import _ from 'lodash';

const initialState = Map({
  selectedView: "Service",
  customerList: [],
  selectedCustomer: {},
  selectedSalon: {},
  salonAppointments: {
    appointments: [],
    lastKey: {},
  },
});

export const Reducer = (state = initialState, action) => {
  const {payload, type, error} = action;

  switch (action.type) {
    case ModuleEvents.SELECT_VIEW: {
      return state.set('selectedView', payload);
    }
    
    case ModuleEvents.FETCH_PAGINATE_APPOINTMENTS: {
      const salonAppointments = state.get('salonAppointments');
      if (payload && !payload.error) {
        const salonAppointmentLastKey = _.get(payload, 'lastKey', {});
        const appointments = _.get(payload, 'items', []);
        salonAppointments.appointments = [...salonAppointments.appointments, ...appointments];
        salonAppointments.lastKey = salonAppointmentLastKey;
        return state.set('salonAppointments', {...salonAppointments});
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
