import EmployeeRepository from '../../internal/repos/EmployeeRepository';

export const ModuleEvents = {
  FETCH_ALL_EMPLOYEE_CATEGORY: 'FETCH_ALL_EMPLOYEE_CATEGORY',
  FETCH_EMPLOYEE_FOR_CATEGORYID: 'FETCH_EMPLOYEE_FOR_CATEGORYID',
  SEARCH_EMPLOYEE: 'SEARCH_EMPLOYEE',
  SELECT_EMPLOYEE: 'SELECT_EMPLOYEE',
  FETCH_SALON_EMPLOYEE: 'FETCH_SALON_EMPLOYEE',
  REMOVE_SELECTED_EMPLOYEE: 'REMOVE_SELECTED_EMPLOYEE',
  FETCH_EMPLOYEES_FOR_SALON_ID: 'FETCH_EMPLOYEES_FOR_SALON_ID',
  ON_EMPLOYEE_CREATE_SUBSCRIPTION: 'ON_EMPLOYEE_CREATE_SUBSCRIPTION',
  ON_EMPLOYEE_UPDATE_SUBSCRIPTION: 'ON_EMPLOYEE_UPDATE_SUBSCRIPTION',
  FETCH_EMPLOYEES: 'FETCH_EMPLOYEES',
  ON_SELECT_EMPLOYEE: 'ON_SELECT_EMPLOYEE',
  FETCH_EMPLOYEES_FOR_SPEC_ID: 'FETCH_EMPLOYEES_FOR_SPEC_ID',
  FETCH_SHOP_EMPLOYEES: 'FETCH_SHOP_EMPLOYEES'
};

const createAction = (type, action = () => {}, meta) => {
  return (...args) => ({ type, payload: action(...args), meta });
};

export default {
  onStylistCreateSubscription: createAction(ModuleEvents.ON_EMPLOYEE_CREATE_SUBSCRIPTION, async (data) => {
    return data;
  }),
  onStylistUpdateSubscription: createAction(ModuleEvents.ON_EMPLOYEE_UPDATE_SUBSCRIPTION, async (data) => {
    return data;
  }),
  fetchEmployeesForSalonId: createAction(ModuleEvents.FETCH_EMPLOYEES_FOR_SALON_ID, async (shopId, limit = 50, lastKey = null) => {
    const result = await EmployeeRepository.fetchEmployees(shopId, limit, lastKey);
    return result
  }, {loading: true}),
  fetchEmployees: createAction(ModuleEvents.FETCH_EMPLOYEES, async (shopId, limit = 50, lastKey = null) => {
    const result = await EmployeeRepository.fetchEmployees(shopId, limit, lastKey);
    return result;
  }, {loading: true}),
  onSelectEmployee: createAction(ModuleEvents.ON_SELECT_EMPLOYEE, data => data),
  fetchEmployeesForSpecId: createAction(ModuleEvents.FETCH_EMPLOYEES_FOR_SPEC_ID, async (shopId, specialization) => {
    const result = await EmployeeRepository.fetchEmployeesForSpecId(shopId, specialization);
    return result;
  }, {loading: true}),
  searchStylist: createAction(ModuleEvents.SEARCH_EMPLOYEE, async (searchText, categoryId) => {
    const result = await EmployeeRepository.searchStylistForCategoryId(searchText, categoryId);
    return result;
  }, {loading: true}),
  fetchShopEmployees: createAction(ModuleEvents.FETCH_SHOP_EMPLOYEES, async (shopId) => {
    const result = await EmployeeRepository.fetchShopEmployees(shopId);
    return result
  }, {loading: true}),

}
