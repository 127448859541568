import _ from "lodash";
import moment from "moment";
import swal from "sweetalert";
import { find } from "lodash";
import { ModuleEvents } from "./Actions";
import {
  showAlert,
  getSendToItemMessage,
} from "../../internal/manager/AlertManager";
import { Actions } from "../../internal/app/Actions";
import { navigate } from "../../internal/service/Navigation.service";

const Handlers = {
  [ModuleEvents.DELETE_ORDER]: async ({ dispatch, payload, appState }) => {
    const batch = appState.login.get("batchSession");
    const shopId = appState.login.get("merchantShop").shopId;
    dispatch(Actions.payment.clearCurrentAppointment());
    dispatch(Actions.orders.fetchCompletedAppointments(shopId));
    dispatch(Actions.orders.fetchUnCompletedAppointments(shopId));
    // dispatch(Actions.closeCash.completedCashAppointments(batch.batchId, shopId));
    dispatch(Actions.closeCash.getBatchSummary(batch.batchId));
    if (payload && !payload.error) {
      navigate("/order");
    } else {
      await warning("Error occurred while deleting the order");
      navigate("/order");
    }
  },
  [ModuleEvents.CHECKOUT]: async ({ dispatch, payload, appState }) => {
    const { appointmentResult, status, nextView, stopNavigation, type } =
      payload;
    const batch = appState.login.get("batchSession");
    const shopId = appState.login.get("merchantShop").shopId;

    if (appointmentResult && !appointmentResult.error) {
      dispatch(Actions.orders.fetchCompletedAppointments(shopId));
      dispatch(Actions.orders.fetchUnCompletedAppointments(shopId));
      // dispatch(Actions.closeCash.completedCashAppointments(batch.batchId, shopId));
      dispatch(Actions.closeCash.getBatchSummary(batch.batchId));

      if (type === "ORDER_QUEUE") {
        showAlert("Order saved");
      } else if (type === "SEND_TO_KITCHEN") {
        const sendToKitchenData = appState.product.get("sendToKitchenData");
        let sendToBarCount = 0;
        let sendToKitchenCount = 0;
        sendToKitchenData.map((data: any) => {
          if (data.sendToKitchen) {
            sendToKitchenCount += data.quantity;
          }
          if (data.sendToBar) {
            sendToBarCount += data.quantity;
          }
        });
        dispatch(Actions.orders.selectedAppointment(appointmentResult));
        showAlert(getSendToItemMessage(sendToKitchenCount, sendToBarCount));
        dispatch(Actions.product.setSendItemData([]));
      }

      if (stopNavigation) {
        // dispatch(Actions.payment.clearCurrentAppointment());
        setTimeout(() => {
          dispatch(Actions.product.fetchStock(shopId));
        }, 3000);
        return;
      }

      if (nextView) {
        if (nextView === "/product") {
          dispatch(Actions.orders.selectedAppointment(appointmentResult));
        }
        navigate(nextView);
      } else {
        dispatch(Actions.payment.clearCurrentAppointment());
        setTimeout(() => {
          dispatch(Actions.product.fetchStock(shopId));
        }, 3000);
        navigate("/order");
      }

      //const str = ReactDomServer.renderToString(<PaymentReciept/>);
      //await PrinterRepository.printPOS(str);
      // if (status === "PENDING") {
      //   navigate("/order");
      // } else {
      //   navigate("/transactions");
      // }
      // return success("Order create successfully");
    } else {
      if (type === "ORDER_QUEUE") {
        showAlert("Error occurred while saving order", "error");
      } else if (type === "SEND_TO_KITCHEN") {
        showAlert("Error occurred while sending prep tickets", "error");
      } else {
        showAlert("Error occurred while creating order");
      }
    }
  },
  [ModuleEvents.CLEAR_CURRENT_APPOINTMENT]: async ({
    payload,
    appState,
    dispatch,
  }) => {
    if (payload?.options?.navigate) {
      navigate(payload?.options?.navigate);
    }

    const currentUser = appState.login.get("currentUser");
    const employees: Array<any> = appState.employee.get("employees");
    if (currentUser?.empId) {
      const employee = find(
        employees,
        (item) => item.empId === currentUser.empId
      );
      if (employee) {
        dispatch(Actions.employee.onSelectEmployee(employee));
      }
    }
    // date time
    const date = moment(new Date().toDateString()).valueOf();
    const time = moment().format("hh:mm A");
    dispatch(Actions.product.setOrderDateAndTime({ date, time }));
  },
  [ModuleEvents.DELETE_SHOP_ORDER]: async ({ dispatch, payload, appState }) => {
    const batch = appState.login.get("batchSession");
    const shopId = appState.login.get("merchantShop").shopId;

    dispatch(Actions.payment.clearCurrentAppointment());
    dispatch(Actions.orders.fetchCompletedAppointments(shopId));
    dispatch(Actions.orders.fetchUnCompletedAppointments(shopId));
    dispatch(Actions.closeCash.getBatchSummary(batch.batchId));
    if (payload && payload.error) {
      await warning("Error occurred while deleting the order");
    }
  },
};

export default Handlers;

const success = (alert) => {
  return swal({
    title: alert,
    icon: "success",
  });
};

const warning = (alert) => {
  return swal({
    marginBottom: "140%",
    title: alert,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  });
};
