import React from 'react';
import BackIcon from "../../../../assets/svg/backIcon.svg";
import styles from '../../product/Styles.css';

const EditStockRecordTopHeader = (props) => {
    const {onCreateService, isValid, onBack, backBtnText, buttonText, isEdit, onDeleteStockRecord} = props;

    return (
      <div className={styles.editServiceTopHeaderContainer}>
        <div className={styles.editServiceTopHeader}>
          <img src={BackIcon} onClick={() => onBack()}/>
          <div onClick={() => onBack()} className={styles.addServiceBtnContainer}>
            {backBtnText}
          </div>
          <div className={styles.saveServiceBtnContainer}>
            {
              isEdit ?
                <div onClick={() => onDeleteStockRecord()} className={styles.editServiceBtnContainer} style={{backgroundColor: isValid ? '#FF9696' : '#8A9CA5'}}>
                  <div className={styles.saveServiceBtnText}>
                    Delete
                  </div>
                </div>
                :  null
            }
            <div onClick={() => onCreateService()} className={styles.editServiceBtnContainer} style={{backgroundColor: isValid ? '#49A4D4' : '#8A9CA5', marginLeft: '10px'}}>
              <div className={styles.saveServiceBtnText}>
                {buttonText}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
;
export default EditStockRecordTopHeader;

