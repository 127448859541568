import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-grid-system';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import 'antd/dist/antd.css';
import {connect} from "react-redux/es/index";
import '../Styles.style.css';
import {Actions} from "../../../internal/app/Actions";
import _ from "lodash";
import ServiceWarningModal from '../../../components/model/views/ServiceWarningModal';
import {navigate, goBack} from "../../../internal/service/Navigation.service";
import {nanoid} from "nanoid";
import {
  calculateStockPrice,
  mapStock,
  mapStockRecords
} from "../../../internal/manager/ProductManager";
import Select from "react-select";
import EditStockRecordTopHeader from "../../../components/stock/views/EditStockRecordTopHeader";
import StockRemoveIcon from '../../../../assets/svg/stockRemoveIcon.svg';
import StockPriceCard from "../../../components/card/StockPriceCard";
import AddStockModel from "../../../components/model/views/AddStockModel";

const style = {
  control: (base, state) => ({
    ...base,
    height: "42px",
    width: "100%",
    paddingRight: "20px",
    border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    "&:hover": {
      border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    },
    borderRadius: "6px",
    color: "#434343",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    paddingLeft: "5px",
  }),
};

const StockRecordAddView = (props) => {
  const { shop, selectedStockRecord, currentUser, deleteStockRecord, createStockRecord, clearSelectedStockRecord,
    stockList, updateStockRecord } = props;

  const [isOpenDiscardModel, setIsOpenDiscardModel] = useState(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [selectedStockList, setSelectedStockList] = useState([{itemData: null, qty: '', unitPrice: '', totalPrice: '', id: nanoid()}]);
  const [inputValue, setInputValue] = useState('');
  const [isOpenStockModal, setIsOpenStockModal] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(selectedStockRecord)) {
      const stockRecords = _.cloneDeep(_.get(selectedStockRecord, 'stockRecords', []));
      stockRecords.push({itemData: null, qty: '', unitPrice: '', totalPrice: '', id: nanoid()});
      setSelectedStockList(stockRecords);
    }
  }, [selectedStockRecord]);

  const isEdit = !_.isEmpty(selectedStockRecord);

  const onCreateStock = (list) => {
    const param:any = {
      shopId: shop.shopId,
      id: nanoid(8),
      updatedTime: Date.now(),
      createdUserId: currentUser.empId,
      stockRecords: mapStockRecords(list)
    };

    if (isEdit) {
      param.id = selectedStockRecord.id;
      updateStockRecord(param);
    } else {
      param.createdTime = Date.now();
      createStockRecord(param);
    }
  };

  const onDeleteStockRecord = () => {
    deleteStockRecord({id: selectedStockRecord.id, shopId: shop.shopId});
  };

  const mapStockList = mapStock(stockList);

  const onInputChange = (option, {action}) => {
    if (action === "input-change") {
      const optionLength = option.length;
      const inputValueLength = inputValue.length;
      const newInputValue =
        optionLength < inputValueLength
          ? option
          : inputValue + option[option.length - 1];
      setInputValue(newInputValue);
    }
  };

  const onRemoveItem = (index) => {
    selectedStockList.splice(index, 1);
    setSelectedStockList([...selectedStockList]);
  };

  const addItem = (item, index, type) => {
    const selectedItem:any = selectedStockList[index];
    const totalItems = selectedStockList.length;
    if (!_.isEmpty(selectedItem)) {
      switch (type) {
        case "STOCK_ITEM": {
          if (index == totalItems - 1) {
            selectedStockList.push({itemData: null, qty: '', unitPrice: '', totalPrice: '', id: nanoid()});
          }
          selectedItem.itemData = item;
          selectedStockList[index] = selectedItem;
          setInputValue('');
        }
        break;
        case "QUANTITY": {
          selectedItem.qty = item;
          const { unitPrice, totalPrice } = calculateStockPrice(selectedItem, type);
          selectedItem.unitPrice = unitPrice;
          selectedItem.totalPrice = totalPrice;
          selectedStockList[index] = selectedItem;
        }
        break;
        case "UNIT_PRICE": {
          selectedItem.unitPrice = item;
          const { totalPrice } = calculateStockPrice(selectedItem, type);
          selectedItem.totalPrice = totalPrice;
          selectedStockList[index] = selectedItem;
        }
        break;
        case "TOTAL_PRICE": {
          selectedItem.totalPrice = item;
          const { unitPrice } = calculateStockPrice(selectedItem, type);
          selectedItem.unitPrice = unitPrice;
          selectedStockList[index] = selectedItem;
        }
        break;
      }
    }

    setSelectedStockList([...selectedStockList]);
  };

  const validList = _.filter(selectedStockList, item => item.qty && item.unitPrice && item.totalPrice && !_.isEmpty(item.itemData));
  const isValid = validList.length > 0;

  return (
    <div className="addCategoryMainContainer">
      <TopNavigationBar viewName="Stock Record" path="/menu"/>
      <div className="addServiceViewContainer">
        <div className="addServiceViewWrapper">
          <EditStockRecordTopHeader
            onCreateService={() => {
              if (isValid) {
                onCreateStock(validList);
              }
            }}
            onDeleteStockRecord={() => setIsOpenDeleteModel(true)}
            isEdit={isEdit}
            onBack={() => {
              if (isEdit) {
                setIsOpenDiscardModel(true);
              } else {
                navigate('/stock-customized');
              }
            }}
            isValid={isValid}
            buttonText={"Save stock"}
            backBtnText={isEdit ? "Edit stock" : "Add stock"}
          />
          <Row style={{padding: '10px'}}>
            <Col md={10} xs={10}>
              <div className="categoryTableContainer">
                <div className="tableHeaderContainer">
                  <Row>
                    <Col xs={4} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableHeaderText">
                        Stock Item
                      </div>
                    </Col>
                    <Col xs={3} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableHeaderText">
                        Qty
                      </div>
                    </Col>
                    <Col xs={2} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableHeaderText">
                        Unit Price
                      </div>
                    </Col>
                    <Col xs={3} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableHeaderText">
                        Total Price
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="categoryTableBodyContainer" style={{paddingTop: 3}}>
                  {
                    selectedStockList.map((stock, index) => {
                      return (
                        <div className="categoryTableBody">
                          <Row>
                            <Col xs={4} className="categoryTableHeaderTextContainer" style={{paddingRight: 0}}>
                              <div style={{width: '85%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                {
                                  selectedStockList.length -1 !== index ?
                                    <div onClick={() => onRemoveItem(index)}>
                                      <img style={{marginRight: '10px'}} src={StockRemoveIcon} />
                                    </div>
                                    : null
                                }
                                <Select
                                  value={stock.itemData}
                                  styles={style}
                                  onChange={(data)=> addItem(data, index, "STOCK_ITEM")}
                                  options={mapStockList}
                                  inputValue={inputValue}
                                  onInputChange={onInputChange}
                                  placeholder={<div className="categoryPlaceholderText">Stock Item</div>}
                                />
                                {
                                  selectedStockList.length -1 === index ?
                                    <div onClick={() => setIsOpenStockModal(true)} className="addNewText">
                                      (Add New)
                                    </div>
                                     : null
                                }
                              </div>
                            </Col>
                            <Col xs={3} className="categoryTableHeaderTextContainer">
                              <Row style={{width: '90%'}}>
                                <Col md={10} xs={10} style={{paddingRight: 0}}>
                                  <div className="categoryTableHeaderTextContainer">
                                    <input
                                      type={"number"}
                                      onChange={(text) => addItem(text.target.value, index, "QUANTITY")}
                                      value={stock.qty}
                                      className="stockItemTextInput"
                                      placeholder="Quantity"
                                    />
                                  </div>
                                </Col>
                                <Col md={2} xs={2} className="categoryTableHeaderTextContainer" style={{paddingLeft: 0}}>
                                  <div style={{marginLeft: 3}}>
                                    {_.get(stock, 'itemData.metricValue', '')}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={2} className="categoryTableHeaderTextContainer">
                              <div className="categoryTableHeaderTextContainer">
                                <input
                                  type={"number"}
                                  onChange={(text) => addItem(text.target.value, index, "UNIT_PRICE")}
                                  value={stock.unitPrice}
                                  className="stockItemTextInput"
                                  placeholder="Unit Price"
                                />
                              </div>
                            </Col>
                            <Col xs={3} className="categoryTableHeaderTextContainer">
                              <div className="categoryTableHeaderTextContainer">
                                <input
                                  type={"number"}
                                  onChange={(text) => addItem(text.target.value, index, "TOTAL_PRICE")}
                                  value={stock.totalPrice}
                                  className="stockItemTextInput"
                                  placeholder="Total Price"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </Col>
            <Col md={2} xs={2}>
              <StockPriceCard
                stockList={selectedStockList}
              />
            </Col>
          </Row>
        </div>
      </div>
      <ServiceWarningModal
        isOpen={isOpenDiscardModel}
        headerText={'Are you sure?'}
        bodText={'All your changes will be discarded'}
        cancelBtnText={'Discard'}
        onCancel={() => {
          setIsOpenDiscardModel(false);
          setSelectedStockList([{itemData: null, qty: '', unitPrice: '', totalPrice: ''}]);
          navigate('/stock-customized');
          clearSelectedStockRecord();
        }}
        closeModal={() => setIsOpenDiscardModel(false)}
      />
      <ServiceWarningModal
        isOpen={isOpenDeleteModel}
        headerText={'Warning!'}
        bodText={'Your stock record will be deleted and no longer\n available to order for the users'}
        cancelBtnText={'Delete'}
        onCancel={() => {
          onDeleteStockRecord();
          setIsOpenDeleteModel(false);
        }}
        closeModal={() => setIsOpenDeleteModel(false)}
      />
      <AddStockModel
        isOpen={isOpenStockModal}
        type={"STOCK_ITEM"}
        closeModal={() => setIsOpenStockModal(false)}
      />
    </div>
  );
};

export default connect(
  state => ({
    shop: state.login.get('merchantShop'),
    currentUser: state.login.get('currentUser'),
    selectedStockRecord: state.stock.get('selectedStockRecord'),
    stockList: state.product.get("stockList"),
  }),
  {
    createStockRecord: Actions.stock.createStockRecord,
    updateStockRecord: Actions.stock.updateStockRecord,
    deleteStockRecord: Actions.stock.deleteStockRecord,
    clearSelectedStockRecord: Actions.stock.clearSelectedStockRecord
  }
)(StockRecordAddView);
