import {Map} from 'immutable';
import {ModuleEvents} from './Actions';
import _ from "lodash";

const initialState = Map({
  stockRecordList: [],
  selectedStockRecord: {}
});

export const Reducer = (state = initialState, action) => {
  const {payload, type, error} = action;

  switch (action.type) {
    case ModuleEvents.FETCH_STOCK_RECORD: {
      if (payload && !payload.error) {
        const stocks = _.get(payload, 'Items', []);
        return state.set('stockRecordList', [...stocks]);
      }
      return state;
    }

    case ModuleEvents.NAVIGATE_EDIT_STOCK_RECORD: {
      return state.set('selectedStockRecord', payload);
    }

    case ModuleEvents.CLEAR_SELECTED_STOCK_RECORD: {
      return state.set('selectedStockRecord', {});
    }

    default:
      return state;
  }
};

export default Reducer;
