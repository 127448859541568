import React from "react";
import { ModuleEvents } from "./Actions";
import { navigate } from "../../internal/service/Navigation.service";
import swal from "sweetalert";
import { Actions } from "../../internal/app/Actions";

export default {
  [ModuleEvents.SET_PRODUCT_WARRANTY]: ({ dispatch, payload, appState }) => {
    if (payload) {
      // history.push("/add-product", { isAddWarranty: true });
      // navigate("/add-product", { isAddWarranty: true });
      navigate("/add-product");
    }
  },
  [ModuleEvents.CREATE_WARRANTY]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
    } else {
    }
  },
};

const success = (alert) => {
  return swal({
    title: alert,
    icon: "success",
  });
};

const warning = (alert) => {
  return swal({
    title: alert,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  });
};
