import swal from "sweetalert";
import _, { find, get } from "lodash";
import { ModuleEvents } from "./Actions";
import { Actions } from "../../internal/app/Actions";
import { navigate } from "../../internal/service/Navigation.service";
import { isCheckOnline } from "../../internal/manager/CommonManager";
import { mapModifiersForPaymentDetails } from "../../internal/manager/ProductManager";

export default {
  [ModuleEvents.SELECTED_APPOINTMENT]: ({ dispatch, payload, appState }) => {
    if (payload?.orderId) {
      const selectedAppointment = payload;
      const productList = appState.product.get("productList");
      const customers = appState.customer.get("customers");
      const currentUser = appState.login.get("currentUser");
      const purchaseProducts = appState.product.get("purchaseProducts");
      const employees: Array<any> = appState.employee.get("employees");

      // select employee
      if (!_.isEmpty(selectedAppointment)) {
        let employee = _.find(
          employees,
          (item) => item.empId === selectedAppointment.empId
        );
        if (!employee) {
          employee = currentUser;
        }
        dispatch(Actions.employee.onSelectEmployee(employee));
      } else if (currentUser?.empId) {
        const employee = _.find(
          employees,
          (item) => item.empId === currentUser.empId
        );
        if (employee) {
          dispatch(Actions.employee.onSelectEmployee(employee));
        }
      }
      // order items
      if (!_.isEmpty(selectedAppointment?.serviceDetails)) {
        const services = selectedAppointment.serviceDetails.map((service) => ({
          tax: 0,
          symbol1: "",
          symbol2: "",
          id: service.id,
          type: "SERVICE",
          enteredNumber1: "",
          enteredNumber2: "",
          duration: service.duration,
          servicePrice: service.price,
          quantity: _.get(service, "quantity", 1),
          price: _.get(service, "totalPrice", service.price),
          serviceTitle: _.get(service, "serviceTitle", service.title),
        }));
        dispatch(Actions.service.setPurchaseService(services));
      }
      // order products
      if (!_.isEmpty(selectedAppointment?.productDetails)) {
        const products = selectedAppointment?.productDetails.map(
          (product: any) => {
            // let purchaseItem = find(
            //   purchaseProducts,
            //   ({ id }) => id === product.id
            // );

            console.log(product)
            return {
              tax: 0,
              id: product.id,
              type: "PRODUCT",
              opId: product.opId,
              enteredNumber1: "",
              enteredNumber2: "",
              productName: product.productName,
              price: parseFloat(product.totalPrice),
              warranty: get(product, "warranty", {}),
              quantity: _.get(product, "quantity", 1),
              modifiers: mapModifiersForPaymentDetails(productList, product.id, get(product, "modifiers", [])),
              priceVariants: product.priceVariants || [],
              productPrice: parseFloat(product.productPrice),
              sendToBar: get(product, "sendToBar", false),
              productSalePrice: parseFloat(product.sellingPrice),
              sendPrepTicket: get(product, "sendPrepTicket", []),
              sendToKitchen: get(product, "sendToKitchen", false),
            };
          }
        );
        dispatch(Actions.product.setPurchaseProducts(products));
      }
      if (selectedAppointment?.clientId) {
        const customer = _.find(
          customers,
          (item) => item.id === selectedAppointment.clientId
        );
        dispatch(Actions.customer.onSelectCustomer(customer));
      }
      if (selectedAppointment.tableOrder) {
        dispatch(Actions.table.selectTable(selectedAppointment?.tableOrder));
      }
      if (selectedAppointment.roomOrder) {
        dispatch(Actions.rooms.selectRoom(selectedAppointment?.roomOrder));
      }
      // date time
      if (selectedAppointment?.date) {
        dispatch(
          Actions.product.setOrderDateAndTime({
            date: selectedAppointment.date,
            time: selectedAppointment.time,
          })
        );
      }
      // discounts
      if (selectedAppointment?.discountDetails) {
        dispatch(
          Actions.product.setAppointmentDiscount(
            selectedAppointment.discountDetails
          )
        );
      }
      const gridMode = _.get(currentUser, "viewMode", "GRID") === "GRID";
      return navigate(gridMode ? "/product" : "/order-list");
    }
  },

  [ModuleEvents.CREATE_APPOINTMENT]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error && payload.statusCode === 200) {
      const shopId = appState.login.get("merchantShop").shopId;
      dispatch(Actions.orders.fetchSalonAppointment(shopId));
      return success("Appointment create successfully");
    } else {
      return warning("Appointment create unsuccessful");
    }
  },

  [ModuleEvents.CREATE_REFUND]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error && payload.status === "SUCCESS") {
      const shop = appState.login.get("merchantShop");
      dispatch(Actions.orders.fetchCompletedAppointments(shop.shopId));
      return success("Order refunded successfully");
    } else {
      return warning("Error occurred while creating refund");
    }
  },
  [ModuleEvents.REOPEN_ORDER]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      const shop = appState.login.get("merchantShop");
      dispatch(Actions.orders.fetchCompletedAppointments(shop.shopId));
      dispatch(Actions.orders.fetchUnCompletedAppointments(shop.shopId));
      return success("Order re-opened successfully");
    } else {
      return warning("Error occurred while re-opening order");
    }
  },
  [ModuleEvents.ON_SUBSCRIPTION_ORDER]: async ({
    dispatch,
    payload,
    appState,
  }) => {
    const isOnline = await isCheckOnline();
    if (isOnline) {
      if (payload && !payload.error) {
        setTimeout(() => {
          dispatch(Actions.closeCash.getBatchSummary(payload.batchId));
          dispatch(
            Actions.closeCash.fetchOrderSummary(payload.shopId, payload.batchId)
          );
        }, 3000);
      } else {
        return warning("Error occurred while subscriptions order");
      }
    }
  },
};

const success = (alert) => {
  return swal({
    title: alert,
    icon: "success",
  });
};

const warning = (alert) => {
  return swal({
    title: alert,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  });
};
