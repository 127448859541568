import React from 'react';
import {Row, Col} from 'react-grid-system';
import IconCross from "../../../assets/svg/IconCross.svg";
import IconSearch from "../../../assets/svg/IconSearch.svg";
import {IconSearchContainer} from "./Styled";
import _ from 'lodash';
import { useIntl } from 'react-intl';

const IconSearchBar = (props) => {
  const {title, searchText, onSearchText, deleteSearchText, onClickAddNewItem} = props;
  const {formatMessage: f} = useIntl();

  const onClickDelete = () => {
    if(!_.isEmpty(searchText)){
      deleteSearchText()
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <IconSearchContainer>
          <img onClick={() => onClickDelete()} src={_.isEmpty(searchText) ? IconSearch : IconCross}/>
          <input value={searchText} onChange={(event) => onSearchText(event.target.value)} type={"text"} placeholder={title}/>
          <button onClick={()=> onClickAddNewItem()}>{f({id: "ORDER.ADD_NEW"})}</button>
        </IconSearchContainer>
      </Col>
    </Row>
  );
};
export default IconSearchBar;
