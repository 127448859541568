import React, { useEffect } from "react";
import _, { isArray } from "lodash";
import { connect } from "react-redux";
import { CenterContainer } from "../../../components";
import { Actions } from "../../../internal/app/Actions";
import { Container, Row, Col } from "react-grid-system";
import styles from "../../../components/Common/Style/Styles.css";
import LoginStyles from "../Styles.css";
import { navigate } from "../../../internal/service/Navigation.service";
import SignInHeader from "../../../components/headers/Views/signInHeader";
import SignInButton from "../../../components/Buttons/Views/SignInButton";
import BackToWelcomeIcon from "../../../../assets/icons/backToWelcomeIcon.svg";
import { LoadingWrapper } from "../../../components/loading/styled";
import { CircularProgress } from "@material-ui/core";

const CheckSubscriptionView = ({
  trialDetails,
  activeSubscriptions,
  trialLoading,
  masterSignOut,
}) => {
  useEffect(() => {
    if (isArray(activeSubscriptions)) {
      const isActiveSubscription = activeSubscriptions.length > 0;
      // const days = moment(trialDetails?.isExpired).diff(moment(), 'days');
      if (
        (!trialDetails && !isActiveSubscription) ||
        (trialDetails?.isExpired && !isActiveSubscription)
      ) {
        // if (!includes(PathsWithoutSubscription, pathname)) {
        // navigate("/subscription-check");
        // }
      } else {
        navigate("/welcome");
      }
    }
  }, [trialDetails, activeSubscriptions]);

  if (trialLoading) {
    return (
      <LoadingWrapper>
        <div>
          <h1>Loading</h1>
          <CircularProgress />
        </div>
      </LoadingWrapper>
    );
  }

  return (
    <div className={styles.background}>
      <CenterContainer width="592px">
        <Container>
          <Row>
            <Col xs={12} className={styles.headerAligh}>
              <SignInHeader title="Subscription Failed" />
              <div className={LoginStyles.subscriptionsCheck}>
                <div>
                  Your shop subscription has failed. Please contact your shop
                  adminstrators or login to{" "}
                  <a href="https://dashboard.netlise.com" target="_blank">
                    dashboard
                  </a>{" "}
                  to get more information
                </div>
              </div>
            </Col>
          </Row>
          <div
            onClick={() => masterSignOut()}
            className={LoginStyles.subsSignOut}
          >
            Sign Out
          </div>
        </Container>
      </CenterContainer>
    </div>
  );
};

export default connect(
  (state: any) => ({
    loadingAction: state.common.get("loadingAction"),
    selectedLoginEmployee: state.login.get("selectedLoginEmployee"),
    openAuthStatus: state.login.get("openAuthenticationModelStatus"),
    trialDetails: state.login.get("trialDetails"),
    activeSubscriptions: state.login.get("activeSubscriptions"),
    trialLoading: state.login.get("trialLoading"),
  }),
  {
    userSignIn: Actions.login.userSignIn,
    openAuthenticationModel: Actions.login.openAuthenticationModel,
    masterSignOut: Actions.login.masterSignOut,
  }
)(CheckSubscriptionView);
