import React from "react";
import { get } from "lodash";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Actions } from "../../../internal/app/Actions";
import { AddAppointmentContainer } from "../../Styles/Styled";
import IconAddSmall from "../../../../assets/svg/IconAddSmall.svg";

const AddAppointment = (props: any) => {
  const { clearCurrentAppointment, currentUser } = props;
  const { formatMessage: f } = useIntl();

  const clickAdd = () => {
    const gridMode = get(currentUser, "viewMode", "GRID") === "GRID";
    clearCurrentAppointment(true, {
      navigate: gridMode ? "/product" : "/order-list",
    });
  };

  return (
    <AddAppointmentContainer>
      <div onClick={() => clickAdd()} className={"addAppointmentButtonWrapper"}>
        <div>{f({ id: "ORDER_QUEUE.FILTER.ADD_ODER" })}</div>
        <img src={IconAddSmall} />
      </div>
    </AddAppointmentContainer>
  );
};
export default connect(
  (state: any) => ({
    currentUser: state.login.get("currentUser"),
  }),
  {
    clearCurrentAppointment: Actions.payment.clearCurrentAppointment,
  }
)(AddAppointment);
