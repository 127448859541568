import {connect} from "react-redux";
import React from 'react';
import {FilterContainer} from "../../Styles/Styled";

const FilterComponent = (props) => {
  const {visible, options, onClick, paddingRight, selected} = props;

  return (
    <FilterContainer paddingRight={paddingRight}>
      <p/>
      <>
        {
          visible ?
            <div>
              {
                options.map((item, index) => {
                    return (
                      <div className={selected === item.value ? 'selected-filter-item' : ''}>
                        <p onClick={() => onClick(item)}>{item.label}</p>
                        {
                          index !== options.length - 1 ?
                            <div/>
                            : null
                        }
                      </div>
                    )
                  }
                )
              }
            </div>
            : null
        }
      </>
    </FilterContainer>
  );
};
export default connect(state =>
    ({}),
  ({})
)(FilterComponent);
