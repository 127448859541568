import React, { Component } from "react";
import { connect } from "react-redux";
import { Actions } from "../../internal/app/Actions";
import _ from "lodash";
import {
  SplitPaymentWrapper,
  BookingDetailsHeader,
  BookingDetailsContainer,
  SplitPaymentButtonWrapper,
  BookingDetailsAppointmentHeader,
} from "../Styles/Styled";
import { useIntl } from "react-intl";
import IconCash from "../../../assets/svg/IconCash.svg";
import IconCard from "../../../assets/svg/IconCard.svg";
import IconGift from "../../../assets/svg/IconGift.svg";
import backIcon from "../../../assets/icons/backIcon.svg";
import IconSplitCheck from "../../../assets/svg/IconSplitCheck.svg";
import { navigate } from "../../internal/service/Navigation.service";

const paymentTypes = [
  { icon: IconCard, title: "Card", id: "CARD" },
  { icon: IconCash, title: "Cash", id: "CASH" },
  { icon: IconSplitCheck, title: "Check", id: "CHECK" },
  { icon: IconGift, title: "Gift Card", id: "GIFT" },
];

const SplitPaymentCard = (props) => {
  const {selectedPayment, onClickItem} = props;
  const { formatMessage: f } = useIntl();

  const onClickPaymentCard = (id: string)=> {
    const isDisable = id === 'GIFT';
    if(!isDisable){
      onClickItem(id);
    }
  }

  return (
    <BookingDetailsContainer>
      <BookingDetailsHeader>
        <div>
          <BookingDetailsAppointmentHeader
            onClick={() => navigate("/checkout")}
          >
            <img src={backIcon} />
            <div>{f({ id: "SPLIT_BILL.CHECKOUT" })}</div>
          </BookingDetailsAppointmentHeader>
        </div>
      </BookingDetailsHeader>

      <SplitPaymentWrapper>
        <div>{f({ id: "SPLIT_BILL.PAYMENT" })}</div>
        {paymentTypes.map((item) => {
          const isSelect = item.id === selectedPayment;
          const isDisable = item.id === 'GIFT' || item.id === 'CHECK';
          return (
            <SplitPaymentButtonWrapper isDisable={isDisable} isSelect={isSelect} onClick={()=>  onClickPaymentCard(item.id)}>
              <img src={item.icon} />
              <div>{item.title}</div>
            </SplitPaymentButtonWrapper>
          );
        })}
      </SplitPaymentWrapper>
    </BookingDetailsContainer>
  );
};
export default connect((state) => ({}), {})(SplitPaymentCard);
