import styled from "styled-components";
import BaseColors from "../../../internal/helpers/BaseColors";

export const SettingsContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${BaseColors.aquaHaze};
    
    &> div: nth-child(2){
        width: 100%;
        height: calc(100vh - 61px);
        display: flex;
        flex-direction: row;
        padding: 10px;
        border-radius: 3px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
        
         &> div {
         width: 100%;
         border-radius: 3px;
         background-color: ${BaseColors.white};
         }
        
    }
`;

export const SettingsDataContainer = styled.div`
  padding: 32px;
`;

export const ServiceOptions = styled.ul`
  & > li {
    margin: 0px;
    font-weight: 500 !important;
    font-size: 1rem;
  }

  & > li > a:hover {
    font-weight: 500 !important;
  }
  & > li.selected > a {
    color: ${BaseColors.white};
    background-color: ${BaseColors.pictonBlue};
  }
`;

export const ServiceOption = styled.div`
  padding: 0.8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  & > div:first-child {
    font-size: 1.2rem;
    font-weight: 500;
  }
  & > div:last-child {
    font-size: 0.75rem;
    font-weight: 400;
    color: ${BaseColors.regentGray};
  }
`;
