import React, { Component } from "react";
import styles from "../../Styles/ModelStyles.css";
import ReactModal from "react-modal";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    overflow: "auto",
    borderRadius: "4px",
    position: "absolute",
    left: "calc(50% - 150px)",
    WebkitOverflowScrolling: "touch",
    border: "1px solid rgba(112, 112, 112, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

class ProductSelectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      symbol: "",
      number: "",
    };
  }

  render() {
    const { isOpen, closeModal, status } = this.props;

    return (
      <div>
        <ReactModal
          isOpen={isOpen}
          style={customStyles}
          className={styles.productSelectModel}
        >
          <div className={styles.productSelectModalContainer}>
            <div className={styles.selectProductText}>
              Select a {status === "PRODUCT" ? "product" : "service"}
            </div>
            <div className={styles.youShouldText}>
              You should select a {status === "PRODUCT" ? "product" : "service"}{" "}
              before
            </div>
            <div className={styles.openTheKeyboardText}>open the keyboard</div>
            <div className={styles.selectProductBottomBtnContainer}>
              <div
                className={styles.productOkBtnContainer}
                onClick={() => closeModal()}
              >
                <div className={styles.selectProductCancelBtnText}>Okay</div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default ProductSelectModal;
