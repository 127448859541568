import React, { useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  SplitPaymentButton,
  PaymentAmountInputs,
  BookingDetailsHeader,
  BookingDetailsContainer,
  SplitPaymentDetailsItem,
  BookingDetailsAppointmentHeader,
} from "../Styles/Styled";
import { useIntl } from "react-intl";
import { SplitMethod } from "./SplitMethods";
import {
  getSplitTotal,
  getAppointmentTotalPrice,
} from "../../internal/manager/PaymentManager";
import { Actions } from "../../internal/app/Actions";
import backIcon from "../../../assets/icons/backIcon.svg";

const SplitPaymentDetailsCard = (props) => {
  const {
    shop,
    orderOffers,
    onClickBack,
    splitPayments,
    selectedPayment,
    addSplitPayment,
    purchaseServices,
    purchaseProducts,
    appointmentDiscounts,
  } = props;
  const { formatMessage: f } = useIntl();
  const [split, setSplit] = useState<any>({});
  const [amount, setAmount] = useState<string>("");
  const [last4, setLast4] = useState<string>("");
  const [number, setNumber] = useState<string>("");

  let purchaseList = purchaseServices.concat(purchaseProducts);
  let { total: totalPrice } = getAppointmentTotalPrice(
    purchaseList,
    appointmentDiscounts,
    shop,
    orderOffers
  );
  const splitTotal = getSplitTotal(splitPayments);

  const isValid = () => {
    const splitTotal = getSplitTotal(splitPayments);
    if (split.method === "CARD") {
      return parseFloat(split.amount) + splitTotal <= totalPrice;
    } else if (split.method === "CASH") {
      return true;
    }
    return false;
  };

  const onAddPayment = () => {
    addSplitPayment(split);
    setSplit({});
    onClickBack();
  };

  const renderPaymentScreen = () => {
    switch (selectedPayment) {
      case "CARD":
        return (
          <SplitMethod
            {...{
              split,
              setSplit,
              method: "CARD",
              total: totalPrice,
              shop,
              splitTotal,
            }}
          />
        );
      case "CASH":
        return (
          <SplitMethod
            {...{
              split,
              setSplit,
              method: "CASH",
              total: totalPrice,
              shop,
              splitTotal,
            }}
          />
        );
      case "CHECK":
        return (
          <>
            <div>{f({ id: "SPLIT_BILL.CASH_PAYMENT" })}</div>
            <PaymentAmountInputs>
              <div>
                <div>{f({ id: "SPLIT_BILL.CHECK_AMOUNT" })}</div>
                <input
                  value={amount}
                  onChange={(event) => setAmount(event.target.value)}
                />
              </div>
              <div>
                <div>{f({ id: "SPLIT_BILL.CHECK_NUMBER" })}</div>
                <input
                  value={number}
                  onChange={(event) => setNumber(event.target.value)}
                />
              </div>
            </PaymentAmountInputs>
          </>
        );
      case "GIFT":
        return (
          <>
            <div>{f({ id: "SPLIT_BILL.CASH_PAYMENT" })}</div>
            <PaymentAmountInputs>
              <div>
                <div>{"Cash Amount"}</div>
                <input onChange={(event) => {}} />
              </div>
            </PaymentAmountInputs>
          </>
        );
    }
  };

  const onClickCancel = () => {
    setAmount("");
    setLast4("");
    setNumber("");
    onClickBack();
  };

  return (
    <BookingDetailsContainer>
      <BookingDetailsHeader>
        <div>
          <BookingDetailsAppointmentHeader onClick={() => onClickBack()}>
            <img src={backIcon} />
            <div>{f({ id: "SPLIT_BILL.PAYMENT" })}</div>
          </BookingDetailsAppointmentHeader>
        </div>
      </BookingDetailsHeader>
      <SplitPaymentDetailsItem>
        {renderPaymentScreen()}
        <SplitPaymentButton isValid={isValid()}>
          <div onClick={() => onClickCancel()}>
            {f({ id: "CHECKOUT.CANCEL" })}
          </div>
          <button disabled={!isValid()} onClick={() => onAddPayment()}>
            Add Payment
          </button>
        </SplitPaymentButton>
      </SplitPaymentDetailsItem>
    </BookingDetailsContainer>
  );
};

export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    orderOffers: state.offer.get("orderOffers"),
    splitPayments: state.payment.get("splitPayments"),
    purchaseProducts: state.product.get("purchaseProducts"),
    purchaseServices: state.service.get("purchaseServices"),
    appointmentDiscounts: state.product.get("appointmentDiscounts"),
  }),
  {
    addSplitPayment: Actions.payment.addSplitPayment,
  }
)(SplitPaymentDetailsCard);
