import React, { Component } from 'react';
import styles from '../../../components/Styles/Styles.css';
import moment from 'moment';

const CloseCashSummary = () => {
  const today = moment().format('Do MMMM YYYY');

    return (
      <div className={styles.closeCashSummaryWrapper}>
        <div className={styles.closeCashDate}>{`Today, ${today}`}</div>
        <div className={styles.closeCashWorkingHoursTag}>
          <div className={styles.closeCashWorkingHoursTagText}>Working hours :</div>
          {/*<div className={styles.closeCashWorkingHoursValueText}>{'8h 20m'}</div>*/}
        </div>
      </div>
    );
}
export default CloseCashSummary;
