import styled from 'styled-components';
import BaseColors from '../../../internal/helpers/BaseColors';

export const UpsertPrinterWrapper = styled.div`
width: 100%;
height: 100%;

& > div:first-child {
  height: 61px;
  display: flex;
  flex-direction: row;
  background-color: ${BaseColors.balticSea};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding-right: 14px;
  padding-left: 20px;

  & > div {
    color: ${BaseColors.white};
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
  }
  & > img:hover {
    cursor: pointer;
  }
}

`