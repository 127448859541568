import moment from 'moment';
import { nanoid } from 'nanoid';
import { keyValueToObject } from './AppointmentManager';
import _, { uniq, get, filter, find, isEmpty, compact, omit, uniqBy } from 'lodash';
import { getSelectedModifiersItems } from './ProductManager';

export const getTotalPrice = (purchaseList) => {
  let total = purchaseList.reduce((amount, item) => {
    if (item.type === 'PRODUCT') {
      return amount + item.productSalePrice * item.quantity;
    } else if (item.type === 'SERVICE') {
      return amount + item.servicePrice * item.quantity;
    } else {
      throw new Error('Error in product values');
    }
  }, 0);
  return total;
};

export const getTransactionSubTotal = (services, products) => {
  const serviceTotal = services?.reduce((tot, service) => tot + service.totalPrice, 0);
  const productTotal = products?.reduce((tot, product) => tot + product.totalPrice, 0);
  return serviceTotal + productTotal;
};

export const getAppointmentTotalPrice = (
  purchaseList,
  appointmentDiscounts,
  shop,
  orderOffers
) => {
  let total = 0;
  let totalDiscount = 0;
  let products = removeOfferProducts(purchaseList);
  let purchaseTotal = getAbsoluteTotal(products);
  // let purchaseTotal = getAbsoluteTotal(purchaseList);
  let offersTotal = getOffersTotal(products, orderOffers);

  if (!isEmpty(appointmentDiscounts)) {
    appointmentDiscounts.forEach((discount) => {
      if (discount.amountType === 'PERCENTAGE') {
        totalDiscount += Math.floor(purchaseTotal * parseFloat(discount.percentage)) / 100;
      } else {
        totalDiscount += parseFloat(discount.amount);
      }
    });
  }
  const serviceCharge = getServiceCharge(purchaseTotal, totalDiscount, shop.systemSettings);
  total = offersTotal - totalDiscount + parseFloat(serviceCharge);
  return { total, serviceCharge, };
};

export const removeOfferProducts = (purchaseList) => {
  const results = purchaseList?.filter((list) => !get(list, 'offerProduct', false));
  return results
}

export const getAbsoluteTotal = (purchaseList) => {
  return purchaseList.reduce((acc, item) => {
    return acc + parseFloat(item.price);
  }, 0);
}


export const getOffersTotal = (purchaseList, orderOffers) => {
  return purchaseList.reduce((acc, item) => {
    let price = item.price
    const offerItem = find(orderOffers, (offer) => offer.pid === item.id)
    if (!isEmpty(offerItem) && offerItem.offerType === 'DISCOUNT') {
      price = item.price - offerItem.amount
    }
    return acc + parseFloat(price);
  }, 0);
}

export const getOffersPrice = (orderOffers, purchaseList) => {
  return orderOffers.reduce((acc, item) => {
    let price = 0;
    if (item.offerType === 'PRODUCT') {
      let products = [];
      item.offerItems.forEach((data) => {
        const product = find(
          purchaseList,
          (product) => product.id === data.pid && product.offerProduct
        );
        products.push(product)
      });
      const productPrice = products.reduce((acc, product) => {
        return acc + parseFloat(product.price);
      }, 0);
      price = productPrice
    } else {
      price = item.amount
    }
    return acc + parseFloat(price);
  }, 0);
}

export const getServiceCharge = (total, discount, settings) => {
  if (!settings) {
    return `${0}`;
  }
  const systemSettings = keyValueToObject(settings);
  const isEnabled = systemSettings['serviceCharges.isEnabled'] === 'true';
  if (!isEnabled) {
    return `${0}`;
  }
  const isOrderMinAmount = systemSettings['serviceCharges.isOrderMinAmount'] === 'true';
  if (isOrderMinAmount) {
    const orderMinAmount = parseFloat(systemSettings['serviceCharges.orderMinAmount']);
    if (total < orderMinAmount) {
      return `${0}`;
    }
  }
  // TODO: 
  // const isGuestCountEnabled = parseFloat(systemSettings['serviceCharges.isGuestCountEnabled']);
  // if (isGuestCountEnabled) {
  // }
  const chargeAmount = parseFloat(systemSettings['serviceCharges.chargeAmount']);
  const isBeforeDiscount = systemSettings['serviceCharges.isBeforeDiscount'] === 'true';
  if (isBeforeDiscount) {
    return `${(total * chargeAmount / 100).toFixed(2)}`;
  } else {
    return `${((total - discount) * chargeAmount / 100).toFixed(2)}`;
  }
}

export const mapPaymentDetails = async (data) => {
  const {
    selectedEmployee,
    selectedCustomer,
    selectedAppointment,
    purchaseServices,
    purchaseProducts,
    cardDetails,
    totalPrice,
    status,
    paymentType,
    paidPrice,
    shopData,
    userId,
    date,
    time,
    batchId,
    discounts,
    recieptId,
    selectedVehicle,
    isSendSms,
    tableOrder,
    roomOrder,
    serviceCharge,
    splitPayments,
    offerDetails
  } = data;
  const discountData = discounts?.map((data) => {
    data.amount = parseFloat(data.amount)
    data.percentage = parseFloat(data.percentage)
    return data
  })
  return {
    time,
    date,
    batchId,
    isSendSms,
    recieptId,
    paidPrice,
    totalPrice,
    cardDetails,
    offerDetails,
    serviceCharge,
    orderStatus: status,
    createdUserId: userId,
    shopId: shopData.shopId,
    paymentMethod: paymentType,
    shopName: shopData.shopName,
    discountDetails: discountData,
    email: selectedCustomer.email,
    empId: selectedEmployee.empId,
    lastName: selectedCustomer.lastName || '',
    firstName: selectedCustomer.firstName || '',
    shopOrderStatus: `${shopData.shopId}_${status}`,
    roomOrder: roomOrder ? roomOrder : { rmId: null },
    mobileNumber: selectedCustomer.mobileNumber || '',
    _version: _.get(selectedAppointment, '_version', 0),
    tableOrder: tableOrder ? tableOrder : { tbId: null },
    clientId: selectedCustomer.id || selectedCustomer.uid,
    serviceDetails: await mapServiceDetails(purchaseServices),
    productDetails: await mapProductDetails(purchaseProducts),
    customerDetails: await mapVehicleDetails(selectedVehicle),
    orderId: _.get(selectedAppointment, 'orderId', nanoid(21)),
    splitPayments: paymentType === 'SPLIT' ? splitPayments : [],
    name: `${selectedCustomer.firstName || ''} ${selectedCustomer.lastName || ''}`,
    employeeName: `${selectedEmployee.firstName || ''} ${selectedEmployee.lastName || ''}`,
  };
};

const mapVehicleDetails = async (selectedVehicle) => {
  const data = Object.keys(selectedVehicle).map((item) => {
    return { key: item, value: selectedVehicle[item] };
  });
  return data;
};

const mapServiceDetails = async (purchaseServices) => {
  const result = await purchaseServices.map((service) => {
    return {
      id: service.id,
      totalPrice: service.price,
      quantity: service.quantity,
      duration: service.duration,
      price: service.servicePrice,
      title: service.serviceTitle || service.title,
    };
  });
  return _.filter(result, (item) => item.quantity > 0);
};

const mapProductDetails = async (purchaseProducts) => {
  const result = await purchaseProducts.map((product) => {
    let warranty = get(product, 'warranty', null);
    if (!isEmpty(warranty)) {
      const data = omit(warranty, ['productName', 'status', 'pid'])
      data.duration = parseInt(warranty.duration)
      warranty = data
    }
    const modifierItems = getSelectedModifiersItems(product?.modifiers);
    const data = {
      warranty,
      id: product.id,
      totalPrice: product.price,
      quantity: product.quantity,
      sendToBar: product.sendToBar,
      opId: get(product, 'opId', null),
      productName: product.productName,
      productPrice: product.productPrice,
      sendToKitchen: product.sendToKitchen,
      sellingPrice: product.productSalePrice,
      modifiers: product?.modifiers || [],
      priceVariants: get(product, 'priceVariants', null),
      sendPrepTicket: get(product, 'sendPrepTicket', []).map(({ quantity, printer, sendToKitchen, sendToBar }) => ({ quantity, printer, sendToKitchen, sendToBar })),
    };
    if (!isEmpty(modifierItems)) {
      // data['modifiers'] = modifierItems
    }

    return data;
  });
  return _.filter(result, (item) => item.quantity > 0);
};

export const getCurrencyWithPrice = (price = '', currency = 'LKR', showCurrency = true) => {
  const formattedPrice = _.isFunction(price?.replace) ? price.replace(/[^.,\d]/g, '') : price;
  if (!currency) {
    return Number(formattedPrice).toFixed(2).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  const format = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: currency,
  }).format(price);
  if (!showCurrency) {
    return format.replace(/[^.,\d]/g, '');
  }
  return format;
};

export const getCashPaymentValues = (value) => {
  let array = [10, 20, 50, 100, 500, 1000, 5000].map((val) => {
    return Math.ceil(value / val) * val;
  });
  array = array.filter((val) => val !== value);
  return uniq(array);
}

export const getSplitTotal = (splits = []) => {
  return splits.reduce((acc, { amount }) => {
    return acc + parseFloat(amount)
  }, 0);
}


export const getOfferDetails = (
  offer, shop, purchaseProducts, products
) => {
  const offerDetails = purchaseProducts.map((product) => {
    const offerItem = find(offer.offerItems, (offer) => offer.pid === product.id)
    let amount = 0;
    let validDate = moment().format('yyyy/MM/DD'), validTime;
    if (!isEmpty(offerItem)) {
      if (offerItem.type === "DISCOUNT") {
        if (offerItem.discount.amountType === "FIXED_AMOUNT") {
          amount = offerItem.discount.amount;
        } else {
          amount = (product.productPrice / 100) * offerItem.discount.amount;
        }
      }
      if (!offer.isEveryDay) {
        const validDates = filter(offer.timePeriods, (timePeriod) => {
          if (moment().isSame(timePeriod.date, "day")) {
            return timePeriod;
          }
        });
        validDates?.map((timePeriod) => {
          const beforeTime = moment(timePeriod.startTime, "hh:mm A");
          const afterTime = moment(timePeriod.endTime, "hh:mm A");
          const isBetween = moment().isBetween(beforeTime, afterTime);
          if (isBetween) {
            validDate = timePeriod.date;
            validTime = { startTime: timePeriod.startTime, endTime: timePeriod.endTime };
          }
        })
      } else {
        const beforeTime = moment(offer.timePeriod.startTime, "hh:mm A");
        const afterTime = moment(offer.timePeriod.endTime, "hh:mm A");
        const isBetween = moment().isBetween(beforeTime, afterTime);
        if (isBetween) {
          validTime = { startTime: offer.timePeriod.startTime, endTime: offer.timePeriod.endTime };
        }
      }

      let offerItems = [];
      if (!isEmpty(offerItem.products)) {
        offerItems = offerItem.products.map((item) => {
          const findProduct = find(
            products,
            (product) => product.pid === item.pid
          );
          return {
            pid: item.pid,
            name: item.name,
            quantity: item.quantity,
            price: parseFloat(findProduct.itemPrice),
          }
        })

      }
      return {
        amount,
        offerItems,
        offerId: offer.id,
        pid: offerItem.pid,
        title: offer.title,
        offerDate: validDate,
        offerTime: validTime,
        offerType: offerItem.type,
        isEveryDay: offer.isEveryDay,
        discount: get(offerItem, 'discount', null),
      }
    }
    return null;
  })
  return compact(offerDetails);
};


export const getOfferPrice = (orderOffers) => {
  return orderOffers?.reduce((acc, item) => {
    let price = 0;
    if (item.offerType === 'PRODUCT') {
      price = item.offerItems?.reduce((acc, product) => {
        return acc + parseFloat(product.price);
      }, 0);
    } else {
      price = item.amount
    }
    return acc + parseFloat(price);
  }, 0);
}


export const getActiveOfferCount = (offers, products) => {
  const data = offers?.map((offer) => {
    let isValidDate = true;
    let isValidTime = false;
    if (!offer.isEveryDay) {
      isValidDate = offer.timePeriods.some((timePeriod) =>
        moment().isSame(timePeriod.date, "day")
      );
      const validDates = filter(offer.timePeriods, (timePeriod) => {
        if (moment().isSame(timePeriod.date, "day")) {
          return timePeriod;
        }
      });
      isValidTime = validDates.some((timePeriod) => {
        const beforeTime = moment(timePeriod.startTime, "hh:mm A");
        const afterTime = moment(timePeriod.endTime, "hh:mm A");
        const isBetween = moment().isBetween(beforeTime, afterTime);
        return isBetween;
      });
    } else {
      const beforeTime = moment(offer.timePeriod.startTime, "hh:mm A");
      const afterTime = moment(offer.timePeriod.endTime, "hh:mm A");
      isValidTime = moment().isBetween(beforeTime, afterTime);
    }
    if (!isEmpty(products) && isValidDate && isValidTime) {
      const results = products?.filter(({ id }) =>
        offer.offerItems.every(({ pid }) => pid === id)
      );
      return !isEmpty(results);
    }
    return false;
  })
  let count = 0;
  data.forEach(value => value ? count++ : value);
  return count
};

export const mapProductWarranties = (purchaseProducts) => {
  const result = purchaseProducts.map((product) => {
    let warranty = get(product, 'warranty', null);
    return warranty;
  });
  return uniqBy(compact(result), "wrid");
};