// @ts-nocheck
import {
  fetchOrders,
  getShopOrder,
  updateShopOrder,
  createShopOrder,
  deleteShopOrder,
  fetchOrderStatus,
  SubscribeCreateOrder,
  SubscribeUpdateOrder,
  SubscribeDeleteOrder,
} from "./schema/Order.schema";
import {
  pushDataToStorage,
  getValueFromLocalStorage,
} from "../manager/CommonManager";
import Repository from "./Repository";
import EmployeeRepository from "./EmployeeRepository";
import GraphqlService from "../service/Graphql.service";
import GraphqlFunctions from "../service/Graphql.functions";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import AppStorageInstance from "../service/AppStorageService";
import { isNetworkError } from "../manager/AppointmentManager";
import _, { delay, isArray, get, uniqBy, concat, filter } from "lodash";

let subscribeCreateOrder = null;
let subscribeUpdateOrder = null;
let subscribeDeleteOrder = null;

class AppointmentRepository extends Repository {

  createOrder = async (data: any, retryCount: number = 1) => {
    const isOnline = await this.isCheckOnline();
    try {
      data.orderType = "POS";
      data.createdTime = Date.now();
      data.updatedTime = Date.now();
      data.paidPrice = parseFloat(get(data, "paidPrice", 0));
      const input = _.omitBy(data, _.isNil);
      const userParams = {
        input,
      };
      console.log(">>> create order", data);
      let order = data;
      // order = await GraphqlService.createOrder(data);
      order = await GraphqlFunctions.commonGraphqlService("CREATE_ORDER", data);
      if (!isOnline) {
        // await GraphqlService.createOfflineShopOrder(data);
        await GraphqlFunctions.commonGraphqlService(
          "CREATE_ORDER_OFFLINE",
          data
        );
      } else {
        const result = await this.API.graphql(
          graphqlOperation(createShopOrder, userParams)
        );
        order = _.get(result, "data.createShopOrder", {});
        if (!order) {
          console.log("issue here");
        }
      }
      const employee = await EmployeeRepository.getEmployee(
        order.shopId,
        order.empId
      );
      order.employee = employee;
      return order;
    } catch (error) {
      console.warn("create appointment catched: ", error, data);
      if (isNetworkError(error) && retryCount <= 3) {
        return new Promise((res) => {
          delay(() => {
            res(this.createOrder(data, ++retryCount));
          }, 500 * retryCount);
        });
      }
      return { error };
    }
  };

  createOrderStorage = async (data: any, retryCount: number = 1) => {
    const input = _.omitBy(data, _.isNil);
    const userParams = {
      input,
    };
    console.log(">>> create order", data);
    let order: any;
    try {
      const result = await this.API.graphql(
        graphqlOperation(createShopOrder, userParams)
      );
      order = _.get(result, "data.createShopOrder", {});
      if (!order) {
        console.log("issue here");
      }
      return order;
    } catch (error) {
      if (isArray(error?.errors)) {
        const conditional = error.errors.find(
          ({ errorType }) =>
            errorType === "DynamoDB:ConditionalCheckFailedException"
        );
        if (conditional) {
          return { isUpdated: true };
        } else {
          console.warn("create appointment catched: ", error, data);
          if (isNetworkError(error) && retryCount <= 3) {
            return new Promise((res) => {
              delay(() => {
                res(this.createOrder(data, ++retryCount));
              }, 500 * retryCount);
            });
          }
          return { error };
        }
      } else {
        return { error };
      }
    }
  };

  subscribeCreateOrder = async (
    shopId: string,
    callback: (data: any) => void
  ) => {
    const isOnline = await this.isCheckOnline();
    if (isOnline) {
      const param = { shopId };
      if (subscribeCreateOrder && subscribeCreateOrder.unsubscribe) {
        subscribeCreateOrder.unsubscribe();
      }
      subscribeCreateOrder = await this.API.graphql(
        graphqlOperation(SubscribeCreateOrder, param)
      ).subscribe({
        next: (data: any) => {
          console.log(">>> subscribe create order", data);
          const shopOrder = get(data, "value.data.onCreateShopOrder", {});
          if (shopOrder) {
            callback(shopOrder);
          } else {
            console.log("Unhandled subscription "); // MIXPANEL
          }
        },
        error: (error) => {
          delay(() => {
            this.subscribeCreateOrder(shopId, callback);
          }, 1000);
        },
        completed: () => {
          console.log("completed");
        },
      });
    } else {
      if (!window.standlone) {
        await GraphqlService.onCreateShopOrder(shopId, (data) => {
          console.log(">>> subscribe create order", data);
          const shopOrder = get(data, "data.onCreateShopOrder", {});
          if (shopOrder && !shopOrder.error) {
            callback(shopOrder);
          }
        });
      }
    }
  };

  updateOrder = async (data: any, retryCount: number = 1) => {
    data.orderType = "POS";
    data.updatedTime = Date.now();
    data._lastChangedAt = Date.now();
    const input = _.omitBy(data, _.isNil);
    data["_version"] = _.get(data, "_version", 0) + 1;
    const userParams = {
      input,
    };
    console.log(">>> update order", data);
    const isOnline = await this.isCheckOnline();
    try {
      let order = data;
      // order = await GraphqlService.updateOrder(data);
      order = await GraphqlFunctions.commonGraphqlService("UPDATE_ORDER", data);
      if (!isOnline) {
        // await GraphqlService.updateOfflineShopOrder(data);
        await GraphqlFunctions.commonGraphqlService(
          "UPDATE_ORDER_OFFLINE",
          data
        );
      } else {
        try {
          const result = await this?.API.graphql(
            graphqlOperation(updateShopOrder, userParams)
          );
          order = _.get(result, "data.updateShopOrder", {});
          if (!order) {
            console.log("Update order not went through");
          }
        } catch (error) {
          if (isArray(error?.errors)) {
            const conditional = error.errors.find(
              ({ errorType }) =>
                errorType === "DynamoDB:ConditionalCheckFailedException"
            );
            if (conditional) {
              this.createOrder(data);
            } else {
              throw error;
            }
          }
          console.log(error);
        }
      }
      const employee = await EmployeeRepository.getEmployee(
        order.shopId,
        order.empId
      );
      order.employee = employee;
      return order;
    } catch (error) {
      console.warn("update appointment catched", error, data);
      if (isNetworkError(error) && retryCount <= 3) {
        return new Promise((res) => {
          delay(() => {
            res(this.updateOrder(data, ++retryCount));
          }, 500 * retryCount);
        });
      }
      return { error };
    }
  };

  updateOrderStorage = async (data: any, retryCount: number = 1) => {
    const input = _.omitBy(data, _.isNil);
    const userParams = {
      input,
    };
    console.log(">>> update order", data);
    let order: any;
    try {
      const result = await this?.API.graphql(
        graphqlOperation(updateShopOrder, userParams)
      );
      order = _.get(result, "data.updateShopOrder", {});
      if (!order) {
        console.log("Update order not went through");
      }
    } catch (error) {
      if (isArray(error?.errors)) {
        const conditional = error.errors.find(
          ({ errorType }) =>
            errorType === "DynamoDB:ConditionalCheckFailedException"
        );
        if (conditional) {
          this.createOrder(data);
        } else {
          throw error;
        }
      }
      console.log(error);
    }
    return order;
  };

  subscribeUpdateOrder = async (
    shopId: string,
    callback: (data: any) => void
  ) => {
    const isOnline = await this.isCheckOnline();
    if (isOnline) {
      const param = { shopId };
      if (subscribeUpdateOrder && subscribeUpdateOrder.unsubscribe) {
        subscribeUpdateOrder.unsubscribe();
      }
      subscribeUpdateOrder = this.API.graphql(
        graphqlOperation(SubscribeUpdateOrder, param)
      ).subscribe({
        next: (data: any) => {
          console.log(">>> subscribe update order", data);
          const shopOrder = get(data, "value.data.onUpdateShopOrder", {});
          if (shopOrder) {
            callback(shopOrder);
          } else {
            console.log("Unhandled subscription "); // MIXPANEL
          }
        },
        error: (error) => {
          delay(() => {
            this.subscribeUpdateOrder(shopId, callback);
          }, 1000);
        },
        completed: () => {
          console.log("completed");
        },
      });
    } else {
      if (!window.standlone) {
        await GraphqlService.onUpdateShopOrder(shopId, (data) => {
          console.log(">>> subscribe update order", data);
          const shopOrder = get(data, "data.onUpdateShopOrder", {});
          if (shopOrder && !shopOrder.error) {
            callback(shopOrder);
          }
        });
      }
    }
  };

  deleteOrder = async (data: any, retryCount: number = 1) => {
    const isOnline = await this.isCheckOnline();
    console.log(">>> delete order", data);
    let order = data;

    const input = _.omitBy(order, _.isNil);
    const userParams = {
      input,
    };
    try {
      const params = {
        orderId: data.orderId,
        shopId: data.shopId,
      };
      // order = await GraphqlService.deleteOrder({
      //   orderId: data.orderId,
      //   shopId: data.shopId,
      // });
      order = await GraphqlFunctions.commonGraphqlService(
        "DELETE_ORDER",
        params
      );
      if (!isOnline) {
        // await GraphqlService.deleteOfflineOrder(data);
        await GraphqlFunctions.commonGraphqlService(
          "DELETE_ORDER_OFFLINE",
          data
        );
      } else {
        const result = await this?.API.graphql(
          graphqlOperation(deleteShopOrder, userParams)
        );
        order = _.get(result, "data.deleteShopOrder", {});
        if (!order) {
          console.log("Delete unsuccessfull");
        }
      }
      return order;
    } catch (error) {
      console.warn("delete shop order", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteOrder(order, ++retryCount);
      }
      return { error };
    }
  };

  deleteOrderStorage = async (data: any, retryCount: number = 1) => {
    const input = _.omitBy(data, _.isNil);
    const userParams = {
      input,
    };
    let order: any;
    console.log(">>> delete order", data);
    try {
      const result = await this?.API.graphql(
        graphqlOperation(deleteShopOrder, userParams)
      );
      return order;
    } catch (error) {
      console.warn("delete shop order", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteOrder(order, ++retryCount);
      }
      return { error };
    }
  };

  subscribeDeleteOrder = async (
    shopId: string,
    callback: (data: any) => void
  ) => {
    const isOnline = await this.isCheckOnline();
    if (isOnline) {
      const param = { shopId };
      if (subscribeDeleteOrder && subscribeDeleteOrder.unsubscribe) {
        subscribeDeleteOrder.unsubscribe();
      }
      subscribeDeleteOrder = this.API.graphql(
        graphqlOperation(SubscribeDeleteOrder, param)
      ).subscribe({
        next: (data: any) => {
          console.log(">>> subscribe delete order", data);
          const shopOrder = get(data, "value.data.onDeleteShopOrder", {});
          if (shopOrder) {
            callback(shopOrder);
          } else {
            console.log("Unhandled subscription delete"); // MIXPANEL
          }
        },
        error: (error) => {
          delay(() => {
            this.subscribeDeleteOrder(shopId, callback);
          }, 1000);
        },
        completed: () => {
          console.log("completed");
        },
      });
    } else {
      if (!window.standlone) {
        await GraphqlService.onDeleteShopOrder(shopId, (data) => {
          console.log(">>> subscribe delete order", data);
          const shopOrder = get(data, "data.onDeleteShopOrder", {});
          if (shopOrder && !shopOrder.error) {
            callback(shopOrder);
          }
        });
      }
    }
  };

  fetchShopOrders = async (
    shopId: string,
    limit: number,
    lastKey: nay = null,
    retryCount: number = 1
  ) => {
    try {
      let data: any = [];
      data = await GraphqlService.fetchOrders(shopId, "PENDING");
      return { appointments: data, lastKey: null };
    } catch (error) {
      console.warn("fetch uncomplete orders", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchShopOrders(shopId, limit, lastKey, ++retryCount);
      }
      return { error };
    }
  };

  fetchCompletedAppointments = async (
    shopId: string,
    limit: number,
    lastKey: any = null,
    retryCount: number = 1
  ) => {
    console.log("${shopId}_COMPLETED", `${shopId}_COMPLETED`);
    const isOnline = await this.isCheckOnline();
    try {
      let data: any = [];
      let nextToken: any;
      if (isOnline) {
        try {
          const params = {
            limit,
            nextToken: lastKey,
            shopOrderStatus: `${shopId}_COMPLETED`,
          };
          const result = await this.API.graphql(
            graphqlOperation(fetchOrderStatus, params)
          );
          const completeOrders = _.get(result, "data.fetchOrderStatus.items", [
            { orderStatus: "COMPLETED" },
          ]);
          const storageData = await GraphqlFunctions.commonGraphqlService(
            "FETCH_ORDERS_COMPLETED",
            shopId
          );
          const shopOrders = filter(
            storageData,
            (order: any) => order.shopId === shopId
          );
          const newData = uniqBy([...shopOrders, ...completeOrders], "orderId");
          // data = await GraphqlService.setShopOrders(completeOrders);
          await GraphqlFunctions.commonGraphqlService("SET_ORDER", newData);
          data = get(result, "data.fetchOrderStatus.items", []);
          nextToken = get(result, "data.fetchOrderStatus.nextToken", null);
        } catch (error) {
          throw error;
        }
      } else {
        // data = await GraphqlService.fetchOrders(shopId, "COMPLETED");
        data = await GraphqlFunctions.commonGraphqlService(
          "FETCH_ORDERS_COMPLETED",
          shopId
        );
      }
      return { data, nextToken };
    } catch (error) {
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchCompletedAppointments(
          shopId,
          limit,
          lastKey,
          ++retryCount
        );
      }
      console.warn("fetch completed orders", error);
      return { error };
    }
  };

  fetchUnCompletedAppointments = async (
    shopId: string,
    limit: number,
    lastKey: any = null,
    retryCount: number = 1
  ) => {
    const isOnline = await this.isCheckOnline();
    try {
      let data: any = [];
      let nextToken: any;
      if (isOnline) {
        try {
          const pendingParams = {
            limit,
            nextToken: lastKey,
            shopOrderStatus: `${shopId}_PENDING`,
          };
          const pendingOrders = await this.API.graphql(
            graphqlOperation(fetchOrderStatus, pendingParams)
          );
          const reopenParams = {
            limit,
            nextToken: lastKey,
            shopOrderStatus: `${shopId}_REOPEN`,
          };
          const reopenOrders = await this.API.graphql(
            graphqlOperation(fetchOrderStatus, reopenParams)
          );
          const uncompleteOrders = [
            ..._.get(pendingOrders, "data.fetchOrderStatus.items", [
              { orderStatus: "PENDING" },
            ]),
            ..._.get(reopenOrders, "data.fetchOrderStatus.items", []),
          ];
          // data = await GraphqlService.setShopOrders(uncompleteOrders);
          await GraphqlFunctions.commonGraphqlService(
            "SET_ORDER",
            uncompleteOrders
          );
          data = concat(
            get(pendingOrders, "data.fetchOrderStatus.items", []),
            get(reopenOrders, "data.fetchOrderStatus.items", [])
          );
          nextToken = get(
            pendingOrders,
            "data.fetchOrderStatus.nextToken",
            null
          );
        } catch (error) {
          throw error;
        }
      } else {
        // data = await GraphqlService.fetchOrders(shopId, "PENDING");
        data = await GraphqlFunctions.commonGraphqlService(
          "FETCH_ORDERS_PENDING",
          shopId
        );
      }
      return { data, nextToken };
    } catch (error) {
      console.warn("fetch uncompleted appointment", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchUnCompletedAppointments(
          shopId,
          limit,
          lastKey,
          ++retryCount
        );
      }
      return { error };
    }
  };

  filterUnCompletedAppointments = async (
    params: any,
    retryCount: number = 1
  ) => {
    const isOnline = await this.isCheckOnline();
    try {
      let data: any = [];
      let nextToken: any;
      if (isOnline) {
        try {
          const shopId = params.shopId;
          const pendingParams = {
            limit: 100,
            nextToken: null,
            shopOrderStatus: `${shopId}_PENDING`,
          };
          const pendingOrders = await this.API.graphql(
            graphqlOperation(fetchOrderStatus, pendingParams)
          );
          const reopenParams = {
            limit: 100,
            nextToken: null,
            shopOrderStatus: `${shopId}_REOPEN`,
          };
          const reopenOrders = await this.API.graphql(
            graphqlOperation(fetchOrderStatus, reopenParams)
          );

          // const pendingOrders = await this.API.graphql(
          //   graphqlOperation(fetchOrders, {
          //     shopId: params.shopId,
          //     orderStatus: "PENDING",
          //   })
          // );
          // const reopenOrders = await this.API.graphql(
          //   graphqlOperation(fetchOrders, {
          //     shopId: params.shopId,
          //     orderStatus: "REOPEN",
          //   })
          // );
          const uncompleteOrders = [
            ..._.get(pendingOrders, "data.fetchOrderStatus.items", [
              { orderStatus: "PENDING" },
            ]),
            ..._.get(reopenOrders, "data.fetchOrderStatus.items", []),
          ];
          // data = await GraphqlService.setShopOrders(uncompleteOrders);
          await GraphqlFunctions.commonGraphqlService(
            "SET_ORDER",
            uncompleteOrders
          );
          data = concat(
            get(pendingOrders, "data.fetchOrderStatus.items", []),
            get(reopenOrders, "data.fetchOrderStatus.items", [])
          );
          nextToken = get(
            pendingOrders,
            "data.fetchOrderStatus.nextToken",
            null
          );
        } catch (error) {
          throw error;
        }
      } else {
        const shopId = params.shopId;
        // data = await GraphqlService.fetchOrders(params.shopId, "PENDING");
        data = await GraphqlFunctions.commonGraphqlService(
          "FETCH_ORDERS_PENDING",
          shopId
        );
      }
      return { data, nextToken };
    } catch (error) {
      console.warn("filter un completed appointment", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.filterUnCompletedAppointments(params, ++retryCount);
      }
      return { error };
    }
  };

  getOfflineData = async () => {
    // const data = await GraphqlService.fetchOfflineOrders("OFFLINE_ORDERS");
    const data = await GraphqlFunctions.commonGraphqlService(
      "FETCH_OFFLINE_ORDERS"
    );
    AppStorageInstance.storePush(data, "OFFLINE_ORDERS");
    return data;
  };

  getShopOrder = async (shopId: string, orderId: string) => {
    try {
      const result: any = await this.API.graphql(
        graphqlOperation(getShopOrder, { shopId, orderId })
      );
      return result.data.getShopOrder;
    } catch (error) {
      console.warn("get shop order for id error", error);
      return { error };
    }
  };

  removeOfflineItem = async (id, value) => {
    return this.storeRemoveItem("OFFLINE_ORDERS", id, value);
  };

  shiftOfflineItme = async () => {
    return this.storeShift("OFFLINE_ORDERS");
  };

  fetchAppointmentForDate = async (
    shopId,
    date,
    limit,
    lastKey,
    retryCount = 1
  ) => {
    const params = {
      shopId,
      appointmentDate: date,
      limit,
      lastKey,
      endpointType: "FILTER_APPOINTMENT_DATE",
    };
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/orders`,
        message,
      });
      return result.appointmentResult;
    } catch (error) {
      console.warn("fetch appointment", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchAppointmentForDate(
          shopId,
          date,
          limit,
          lastKey,
          ++retryCount
        );
      }
      return { error };
    }
  };

  createRefund = async (params: any, retryCount: number = 1) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        pushDataToStorage("ORDER_OPERATION", {
          data: params,
          action: "REFUND",
        });
        return { status: "SUCCESS" };
      }
      const message = this.buildMessage(params);
      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/refund`,
        message,
      });
      return result;
    } catch (error) {
      console.warn("create refund", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createRefund(params, ++retryCount);
      }
      return { error };
    }
  };

  searchTransactions = async (shopId, searchText, limit, lastKey) => {
    const isOnline = await this.isCheckOnline();
    if (isOnline) {
      const message = this.buildMessage({ shopId, searchText, limit, lastKey });
      try {
        const result = await this.apiPost({
          apiName: this.CLOUD_POS_LAMBDA_API,
          path: `/transactions`,
          message,
        });
        return result;
      } catch (error) {
        console.warn("search transactions", error);
        return { error };
      }
    } else {
      const list = await getValueFromLocalStorage("COMPLETED_APPOINTMENT_LIST");
      if (list) {
        const newList = JSON.parse(list);
        const filterList = _.filter(
          newList.Items,
          (item) =>
            item.shopId === shopId &&
            (item.name.includes(searchText) ||
              item.recieptId.includes(searchText))
        );
        return { transactionsList: filterList };
      }
      return { transactionsList: [] };
    }
  };
}

export default new AppointmentRepository();
