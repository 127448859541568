import React from "react";
import ReactModal from "react-modal";
import styles from "../../Styles/ModelStyles.css";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    borderRadius: "4px",
    position: "absolute",
    left: "calc(50% - 230px)",
    border: "1px solid rgba(112, 112, 112, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.16)",
  },
};

const ServiceWarningModal = (props: any) => {
  const {
    isOpen,
    bodText,
    onCancel,
    closeModal,
    headerText,
    cancelBtnText,
    closeText = "Cancel",
  } = props;

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.productDeleteModalWrapper}
    >
      <div className={styles.productDeleteModalWrapperInfo}>
        <div className={styles.productDeleteModalHeaderText}>{headerText}</div>
        <div className={styles.productDeleteModalTitleText}>{bodText}</div>
        <div className={styles.productDeleteModalBtnWrapper}>
          <button
            className={styles.productDeleteModalBtnRemove}
            onClick={() => onCancel()}
          >
            <div className={styles.productDeleteModalBtnText}>
              {cancelBtnText}
            </div>
          </button>
          <button
            className={styles.productDeleteModalBtnCancel}
            onClick={() => closeModal()}
          >
            <div className={styles.productDeleteModalBtnText}>{closeText}</div>
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
export default ServiceWarningModal;
