import React, {useEffect, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import {connect} from "react-redux";
import {Actions} from "../../../internal/app/Actions";
import _ from 'lodash';
import '../../Styles/ModelStyles.css'
import {SelectEmployeeModalContainer} from '../../Styles/Styled';
import Checkbox from '@material-ui/core/Checkbox';
import IconRadioCheck from '../../../../assets/svg/IconRadioCheck.svg'
import IconRadioUncheck from '../../../../assets/svg/IconRadioUncheck.svg'

const customStyles = {
  content: {
    top: '15%',
    left: 'calc(50% - 264px)',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },
};

const SelectEmployeeModal = (props) => {
  const {isOpen, closeModal, employees, onSelectedEmployee, selectedEmployee} = props;
  const [clickedEmployee, setClickedEmployee] = useState({});

  useEffect(()=> {
    setClickedEmployee(selectedEmployee);
  }, [selectedEmployee]);

  const onClickEmployee = (employee) => {
    const isSelected = _.get(clickedEmployee, 'empId', '') === employee.empId;
    setClickedEmployee(isSelected ? {} : employee);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.selectEmployeeModal}
    >
      <SelectEmployeeModalContainer>
        <div>
          <div>{'Select Employee'}</div>
          <img onClick={() => closeModal()} src={CANCEL}/>
        </div>
        <div>
          {
            employees.map((employee) => {
              const isSelected = clickedEmployee?.empId === employee.empId;
              const name = `${employee.firstName} ${employee.lastName}`;
              return (
                  <div onClick={() => onClickEmployee(employee)}>
                    <Checkbox
                      checked={isSelected}
                      icon={<img src={IconRadioUncheck}/>}
                      checkedIcon={<img src={IconRadioCheck}/>}
                      onChange={() => onClickEmployee(employee)}
                    />
                    <div>{name}</div>
                  </div>
              )
            })
          }
        </div>

        <div>
          <button onClick={() => onSelectedEmployee(clickedEmployee)}>
            <div>{'Select Employee'}</div>
          </button>
          <button onClick={() => closeModal()}>
            <div>{'Cancel'}</div>
          </button>
        </div>

      </SelectEmployeeModalContainer>
    </ReactModal>
  );
};
export default connect(
  state => ({
    shopData: state.login.get('merchantShop'),
    currentUser: state.login.get('currentUser'),
    employees: state.employee.get('employees'),
    selectedEmployee: state.employee.get('selectedEmployee'),
  }),
  ({})
)(SelectEmployeeModal);
