import {
  AiOutlineEye,
  AiOutlineClose,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { isEmpty } from "lodash";
import React, { useState } from "react";

const TextInput = ({
  id,
  label,
  error,
  value,
  touched,
  onFocus,
  onChange,
  placeholder,
  type = "text",
  disabled = false,
  closeItem = false,
}: {
  id: string;
  value?: string;
  type?: string;
  label?: string;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  closeItem?: boolean;
  placeholder?: string;
  onFocus?: (() => void) | undefined;
  onChange: (text: string) => void;
}) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  return (
    <div className="w-full">
      {label && (
        <div className="flex flex-row justify-between w-full">
          <label
            htmlFor={id}
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          {closeItem && (
            <button
              type="button"
              onClick={() => {}}
              className="flex items-center w-[22px] h-[22px] rounded-xl justify-center bg-deactivate hover:bg-deactivate-focus"
            >
              <AiOutlineClose size={15} color="#fff" />
            </button>
          )}
        </div>
      )}
      <div className="w-full relative flex flex-row">
        <input
          id={id}
          name={id}
          value={value}
          autoComplete={id}
          onFocus={onFocus}
          disabled={disabled}
          placeholder={placeholder}
          type={isVisiblePassword ? "text" : type}
          onChange={(e) => onChange(e.target.value)}
          className="p-2.5 h-10 block w-full rounded-md outline-none border-gray-300 border shadow sm:text-sm"
        />
        {type === "password" ? (
          <>
            {isVisiblePassword ? (
              <AiOutlineEye
                onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                className="absolute right-4 top-3"
              />
            ) : (
              <AiOutlineEyeInvisible
                onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                className="absolute right-4 top-3"
              />
            )}
          </>
        ) : null}
      </div>
      {!isEmpty(error) && touched && (
        <div className="mt-1 text-xs font-normal text-rose-400 leading-3">
          {error}
        </div>
      )}
    </div>
  );
};

export default TextInput;
