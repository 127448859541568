import { isServer } from "./AppUtils";
import React from "react";
import { compose, createStore, combineReducers, applyMiddleware } from "redux";
import { createBrowserHistory, createMemoryHistory } from "history";
import Reducers from "./Reducers";
import actionMiddleware from "./middleware/ActionMiddleware";
import PromiseMiddleware from "./middleware/PromiseMiddleware";
import logger from "redux-logger";
import { routerMiddleware } from "react-router-redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import immutableTransform from 'redux-persist-transform-immutable';

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  storage,
};

const appHistory = createBrowserHistory({
      // forceRefresh: false,      // Set true to force full page refreshes
      // keyLength: 2,             // The length of location.key
    });

// const reduxRouterMiddleware = syncHistory(appHistory);

const rootReducer = combineReducers({
  ...Reducers,
});

const routeMiddleware = routerMiddleware(appHistory);

const enhancer = compose(
  applyMiddleware(
    PromiseMiddleware,
    logger,
    actionMiddleware(),
    //reduxRouterMiddleware,
    routeMiddleware
  )
);

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, {}, enhancer);

const persistor = persistStore(store);

//InitApp(store);

export { appHistory, store, persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
