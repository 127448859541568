import React, {Component, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import {Container, Row, Col} from 'react-grid-system';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import {connect} from "react-redux";
import {Actions} from "../../../internal/app/Actions";
import _ from 'lodash';
import {mapServiceCalculator, getServiceCalculatorText} from '../../../internal/manager/CalculatorManager';
import {getCurrencyWithPrice} from "../../../internal/manager/PaymentManager";

const customStyles = {
  content: {
    top: '15%',
    outline: 'none',
    overflow: 'auto',
    borderRadius: '4px',
    position: 'absolute',
    left: "calc(50% - 285px)",
    WebkitOverflowScrolling: 'touch',
    border: '1px solid rgba(112, 112, 112, 0.1)',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },
};

class ProductCalculatorModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      symbol1: '',
      symbol2: '',
      enteredNumber1: '',
      enteredNumber2: '',
      total: 0,
      servicePrice: ''
    };
  };

  componentDidMount() {
    const {selectedService, selectedServiceItem} = this.props;

    if (!_.isEmpty(selectedServiceItem)) {
      const {symbol1, symbol2, tax, price, quantity, servicePrice} = selectedServiceItem;
      this.setState({
        symbol1,
        symbol2,
        enteredNumber1: quantity,
        enteredNumber2: tax > 0 ? tax : '',
        total: price,
        servicePrice: servicePrice
      })
    } else {
      this.setState({servicePrice: selectedService.price})
    }
  }

  setEnteredNumber = (number, enteredNumber1, enteredNumber2) => {
    const {symbol1, symbol2, servicePrice} = this.state;
    const mapData = mapServiceCalculator(symbol1, symbol2, number, enteredNumber1, enteredNumber2, servicePrice);
    if (!_.isEmpty(mapData)) {
      this.setState(mapData);
    }
  };

  getProductText = (symbol1, symbol2, enteredNumber1, enteredNumber2) => {
    const {selectedService, selectedServiceItem} = this.props;
    return getServiceCalculatorText(symbol1, symbol2, enteredNumber1, enteredNumber2, selectedService, selectedServiceItem);
  };

  closeModal = () => {
    const {openServiceKeyPad, removeSelectedService, selectServiceItem} = this.props;
    openServiceKeyPad(false);
    removeSelectedService();
    selectServiceItem({});
  };

  doneCalculateService = () => {
    const {selectedService, setPurchaseService, selectedServiceItem, removeSelectedService, selectServiceItem} = this.props;
    const {symbol1, symbol2, enteredNumber1, total, enteredNumber2, servicePrice} = this.state;
    const param = {
      serviceTitle: selectedService.serviceTitle,
      id: selectedService.id || selectedServiceItem.id,
      price: total > 0 ? total : servicePrice,
      tax: symbol1 === '+' ? enteredNumber1 : symbol2 === '+' ? enteredNumber2 : 0,
      quantity: symbol1 === 'X' ? enteredNumber1 || 1 : symbol2 === 'X' ? enteredNumber2 || 0 : 1,
      symbol1: symbol1 ? symbol1 : 'X',
      symbol2,
      servicePrice: servicePrice,
      image: selectedService.image,
      type: "SERVICE"
    };
    setPurchaseService(param);
    removeSelectedService();
    selectServiceItem({});
  };

  render() {
    const {isOpen, closeModal, salon} = this.props;
    const {symbol1, symbol2, enteredNumber1, total, enteredNumber2, servicePrice} = this.state;

    const totalPrice = getCurrencyWithPrice(total > 0 ? total : servicePrice, salon.currency);

    return (
      <ReactModal
        isOpen={isOpen}
        style={customStyles}
        className={styles.Calmodel}
      >
        <div>
          <div className={styles.modelStyle} onClick={() => closeModal()}>
            <div className={styles.modelTitle}>KeyPad</div>
            <img style={{paddingRight: '14px'}} alt="icon" src={CANCEL}/>
          </div>

          <div className={styles.calculatorInputFiledRow}>
            <div className={styles.modelSubTitle}>{this.getProductText(symbol1, symbol2, enteredNumber1, enteredNumber2)}</div>
            <div
              className={styles.modelSubTitle}
              style={{paddingRight: '14px'}}
            >
              {totalPrice}
            </div>
          </div>

          <Container fluid style={{marginTop: 30}}>
            <div className={styles.calculationModalContainer}>
              <div className={styles.calculationBoxContainer}>
                <div onClick={() => {
                  this.setEnteredNumber(1, enteredNumber1, enteredNumber2)
                }} className={styles.calculationBox}>
                  1
                </div>
                <div onClick={() => {
                  this.setEnteredNumber(4, enteredNumber1, enteredNumber2)
                }} className={styles.calculationBox}>
                  4
                </div>
                <div onClick={() => {
                  this.setEnteredNumber(7, enteredNumber1, enteredNumber2)
                }} className={styles.calculationBox}>
                  7
                </div>
                <div onClick={() => {
                  if (symbol1 && !symbol2 && symbol1 !== 'X') {
                    this.setState({symbol2: 'X'})
                  } else {
                    this.setState({symbol1: 'X'})
                  }
                }}
                     className={styles.calculationBox}
                >&times;</div>
              </div>
              <div className={styles.middleCalculationBox}>
                <div onClick={() => {
                  this.setEnteredNumber(2, enteredNumber1, enteredNumber2)
                }} className={styles.calculationBox}>
                  2
                </div>
                <div onClick={() => {
                  this.setEnteredNumber(5, enteredNumber1, enteredNumber2)
                }} className={styles.calculationBox}>
                  5
                </div>
                <div onClick={() => {
                  this.setEnteredNumber(8, enteredNumber1, enteredNumber2)
                }} className={styles.calculationBox}>
                  8
                </div>
                <div onClick={() => {
                  this.setEnteredNumber(0, enteredNumber1, enteredNumber2)
                }} className={styles.calculationBox}>
                  0
                </div>
              </div>
              <div className={styles.middleCalculationBox}>
                <div onClick={() => {
                  this.setEnteredNumber(3, enteredNumber1, enteredNumber2)
                }} className={styles.calculationBox}>
                  3
                </div>
                <div onClick={() => {
                  this.setEnteredNumber(6, enteredNumber1, enteredNumber2)
                }} className={styles.calculationBox}>
                  6
                </div>
                <div onClick={() => {
                  this.setEnteredNumber(9, enteredNumber1, enteredNumber2)
                }} className={styles.calculationBox}>
                  9
                </div>
                <div onClick={() => {
                  if (symbol1 && symbol1 !== '+') {
                    this.setState({symbol2: '+'})
                  } else {
                    this.setState({symbol1: '+'})
                  }
                }}
                     className={styles.calculationBox}
                >+
                </div>
              </div>
              <div className={styles.middleCalculationBox}>
                <div onClick={() => this.setState({
                  enteredNumber1: '',
                  enteredNumber2: '',
                  symbol1: '',
                  symbol2: '',
                  total: ''
                })}
                     className={styles.calculationCText}
                >C
                </div>
                <div onClick={() => {
                  this.doneCalculateService();
                  this.closeModal();
                  this.setState({
                    symbol1: '',
                    symbol2: '',
                    enteredNumber1: '',
                    enteredNumber2: '',
                    total: 0,
                    servicePrice: servicePrice
                  })
                }}
                     className={styles.calculationCText}
                >DONE
                </div>
              </div>
            </div>
          </Container>
        </div>
      </ReactModal>
    );
  }
}

export default connect(
  state => ({
    salon: state.salon.get('salon'),
    purchaseService: state.service.get('purchaseService'),
    selectedServiceItem: state.service.get('selectedServiceItem'),
    selectedService: state.service.get('chossedServiceItem'),
  }),
  {
    selectProduct: Actions.service.selectProduct,
    openServiceKeyPad: Actions.service.openServiceKeyPad,
    removeSelectedService: Actions.service.removeSelectedService,
    selectServiceItem: Actions.service.selectServiceItem,
    setPurchaseService: Actions.service.setPurchaseService,
  }
)(ProductCalculatorModel);
