import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux/es/index";
import { DateCardContainer } from "../Styles/Styled";

const AppointmentDateCard = (props) => {
  const { orderDateAndTime, purchaseServices } = props;
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    const totalDuration = getTotalDuration(purchaseServices);
    const startTime = _.get(orderDateAndTime, "time", "");
    const end = moment(`${startTime}`, "HH:mm")
      .add(totalDuration, "m")
      .format("hh:mm A");
    const start = moment(`${startTime}`, "HH.mm A").format("hh:mm");
    const date = moment(orderDateAndTime.date).format("dddd DD, YYYY");
    setStartTime(start);
    setEndTime(end);
    setDate(date);
  }, [orderDateAndTime]);

  const getTotalDuration = (service) => {
    let totalDuration = 0;
    service.forEach((item) => {
      const splits = _.split(item.duration, ":");
      const time = parseInt(splits[0]) * 60 + parseInt(splits[1]);
      totalDuration = totalDuration + time;
    });
    return totalDuration;
  };

  return (
    <DateCardContainer>
      <div className={"appointmentDateText"}>Order date</div>
      <div className={"bottomWrapper"}>
        <div className={"dateText"}>{date}</div>
        <div className={"dateText"}>{`${startTime}- ${endTime}`}</div>
      </div>
    </DateCardContainer>
  );
};
export default connect(
  (state) => ({
    purchaseServices: state.service.get("purchaseServices"),
    orderDateAndTime: state.product.get("orderDateAndTime"),
  }),
  {}
)(AppointmentDateCard);
