import _ from 'lodash';
import { getCurrencyWithPrice } from "./PaymentManager";

export const getServiceSummary = (service, shop) => {
  const title = service[0].title || service[0].serviceTitle;
  const total = service.map(service => service.totalPrice).reduce((prev, curr) => prev + curr, 0);
  const count = service.map(service => service.quantity).reduce((prev, curr) => prev + curr, 0);
  const price = getCurrencyWithPrice(total, shop.currency);
  return {
    count,
    price,
    service: title
  }
};

export const getSalesSummary = (summary, shop) => {
  let commission, salesCount, productCount, grossPrice, cardPayment, cashPayment, salesPrice, productPrice, startingCash, cashSales, cardSales, refunds, cashInOut, expectedAmount, actualAmount;

  commission = _.get(summary, 'salesSummary.commission', 0);
  salesCount = _.get(summary, 'salesSummary.salesCount', 0);
  productCount = _.get(summary, 'salesSummary.productCounts', 0);
  const cardTotalNum = _.get(summary, 'drawerSummary.totalCardSalesPrice', 0);
  const cashTotalNum = _.get(summary, 'drawerSummary.totalCashSalesPrice', 0);

  grossPrice = getCurrencyWithPrice(_.get(summary, 'salesSummary.grossSales', 0), shop.currency);
  cardPayment = getCurrencyWithPrice(_.get(summary, 'drawerSummary.cardSales', 0), shop.currency);
  cashPayment = getCurrencyWithPrice(_.get(summary, 'drawerSummary.cashSales', 0), shop.currency);
  salesPrice = getCurrencyWithPrice(_.get(summary, 'salesSummary.totalSales', 0), shop.currency);
  productPrice = getCurrencyWithPrice(_.get(summary, 'salesSummary.totalProducts', 0), shop.currency);
  const startingCashValue = _.get(summary, 'drawerSummary.startingCash', 0);

  startingCash = getCurrencyWithPrice(startingCashValue, shop.currency);
  cashSales = getCurrencyWithPrice(cashTotalNum, shop.currency);
  cardSales = getCurrencyWithPrice(cardTotalNum, shop.currency);
  refunds = getCurrencyWithPrice(_.get(summary, 'drawerSummary.totalRefundPrice', 0), shop.currency);
  cashInOut = getCurrencyWithPrice(_.get(summary, 'drawerSummary.cashIn/cashOut', 0) - startingCashValue, shop.currency);
  const total = (_.get(summary, 'drawerSummary.cashIn/cashOut', 0) + _.get(summary, 'drawerSummary.totalCashSalesPrice', 0));
  const totalSale = getCurrencyWithPrice(cashTotalNum + cardTotalNum, shop.currency);

  expectedAmount = getCurrencyWithPrice(total, shop.currency);
  actualAmount = getCurrencyWithPrice(total, shop.currency);

  const totalAmount = getCurrencyWithPrice(total + cardTotalNum, shop.currency); // net amount
  const drawerOperations = _.get(summary, 'drawerSummary.drawerOperations', []);

  return { totalSale, totalAmount, commission, salesCount, productCount, grossPrice, cardPayment, cashPayment, salesPrice, productPrice, startingCash, cashSales, cardSales, refunds, cashInOut, expectedAmount, actualAmount, drawerOperations }
};


