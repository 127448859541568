import React, {useEffect, useState, useCallback} from 'react';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import _ from 'lodash';
import ServiceCard from '../../../components/card/ServiceCard';
import ServiceCategoryCard from '../../../components/card/ServiceCategoryCard';
import SearchComponent from '../../../components/search/SearchComponent';
import {
  ServiceCardContainer,
  ServiceCategoryContainer,
  ServiceContainer
} from '../../../components/Styles/Styled';
import IconProductPlus from '../../../../assets/svg/IconProductPlus.svg';
import {Actions} from "../../../internal/app/Actions";

const AllServicesView = (props) => {
    const {categories, loadingAction, navigateEditServiceView, clearSelectedServiceCategory, fetchCategories,
      merchantShop, searchCategories, selectedCategory, selectCategory} = props;
    const {action, loading} = loadingAction;
    const [searchText, setSearchText] = useState('');

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText]);

  const debouncedSearch = useCallback(_.debounce((text) =>
      searchCategoryForText(text), 600, {leading: false, trailing: true}),
    [selectedCategory, searchText]);

  const searchCategoryForText = (text) => {
    if (text && text.length > 0) {
      searchCategories(merchantShop.shopId, selectedCategory.id, text);
    } else {
      fetchCategories(merchantShop.shopId);
    }
  };

  const deleteSearchText = () => {
    setSearchText('');
    fetchCategories(merchantShop.shopId);
  };

    return (
      <ServiceContainer>
        <SearchComponent
          searchText={searchText}
          title="Search by service name"
          deleteSearchText={() => deleteSearchText()}
          onSearchText={(text) => setSearchText(text)}
        />
        <ServiceCategoryContainer>
          {
            _.get(categories, 'categories', []).map((category) => {
              const isSelected = selectedCategory.id === category.id;
              return (
                <ServiceCategoryCard
                  category={category}
                  isSelected={isSelected}
                  onSelectCategory={(data) => {
                    setSearchText('');
                    selectCategory(data);
                  }}
                />
              )
            })
          }
        </ServiceCategoryContainer>
        {
          loading && (action.type === 'FETCH_CATEGORIES') &&
          <div className={'productLoading'}>
            <CircularProgress color="primary" size={40} value={5}/>
          </div>
        }
        <ServiceCardContainer>
          {
            _.get(selectedCategory, 'services', []).map((item) => {

              return (
                <ServiceCard
                  item={item}
                  onDelete={() => {}}
                  category={selectedCategory}
                  onSelectServiceItem={(data) => {}}
                  isEdit={true}
                  onSelectService={(service) => navigateEditServiceView({service, category: selectedCategory})}
                />
              )
            })
          }
          <div className={'addCategoryBtn'} onClick={() => clearSelectedServiceCategory("/add-service")}>
            <img src={IconProductPlus}/>
          </div>
        </ServiceCardContainer>
      </ServiceContainer>
    );
  }
;
export default connect(
  state => ({
    categories: state.service.get('categories'),
    loadingAction: state.common.get('loadingAction'),
    merchantShop: state.login.get('merchantShop'),
    selectedCategory: state.service.get('selectedCategory')
  }),
  {
    navigateEditServiceView: Actions.service.navigateEditServiceView,
    clearSelectedServiceCategory: Actions.service.clearSelectedServiceCategory,
    fetchCategories: Actions.service.fetchCategories,
    searchCategories: Actions.service.searchCategories,
    selectCategory: Actions.service.selectCategory
  }
)(AllServicesView);

