import _, { get, find, findIndex, isEmpty } from "lodash";
import { USER_ROLE_PERMISSION } from "../../modules/common/Constant";

export const getCanApprovalUsers = (employees) => {
  return _.filter(
    employees,
    (item) =>
      _.filter(
        item.specializations,
        (spec) => spec.id === "1" || spec.id === "3"
      )?.length > 0
  );
};

export const isCanAccess = (roles, accessType) => {
  let isAccess = false;
  Array.isArray(roles) &&
    roles.forEach((role) => {
      const particularRole = _.find(
        specializationList,
        (spec) => spec.id === role.id
      );
      const roleKey = particularRole?.key;
      if (USER_ROLE_PERMISSION?.[roleKey]?.[accessType]) {
        isAccess = USER_ROLE_PERMISSION[roleKey][accessType];
      }
    });

  return isAccess;
};

const specializationList = [
  { id: "1", title: "Admin", key: "ADMIN" },
  { id: "2", title: "Front desk", key: "FRONT_DESK" },
  { id: "3", title: "Manager", key: "MANAGER" },
  { id: "4", title: "Waiter", key: "WAITER" },
];

const tableColors = [
  "#7dcea0",
  "#49A4D4",
  "#b3b6b7",
  "#cb4335",
  "#f4d03f",
  "#fae5d3",
  "#f8c471",
  "#17a589",
  "#7fb3d5",
  "#5d6d7e",
  "#f4d03f",
  "#bfc9ca",
  "#82e0aa",
  "#dc7633",
  "#d35400",
];

export const getTableColor = ({ orders, employees }: any) => {
  const userId = get(orders, "[0].empId", "");
  const user = find(employees, (employee: any) => employee.empId === userId);
  if (!isEmpty(user?.color)) {
    return user.color;
  } else {
    const index = findIndex(
      employees,
      (item: any) => item?.empId === user?.empId
    );
    return tableColors[index % 20];
  }
};

export const getHiPriorityUserRole = (roles: any) => {
  if (!isEmpty(roles)) {
    let sort = ["Admin", "Front desk", "Manager", "Waiter"],
      sortObj = {};
    sort.forEach((a: any, i: any) => {
      sortObj[a] = i + 1;
    });
    roles?.sort((a: any, b: any) => {
      return sortObj[a.title] - sortObj[b.title];
    });
    return get(roles, "[0].title", "");
  }
};
