import React from "react";
import moment from "moment";
import {
  OrderWarpper,
  OrderHeaderWarpper,
  OrderButtonWrapper,
  OrderDetailsWrapper,
} from "../../Styles/Styled";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import styles from "../../Styles/ModelStyles.css";
import CANCEL from "../../../../assets/icons/cancel.svg";
import { getCurrencyWithPrice } from "../../../internal/manager/PaymentManager";

const customStyles = {
  content: {
    top: "15%",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 265px)",
    border: "1px solid rgba(112, 112, 112, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const TableOrderModal = (props: any) => {
  const { isOpen, data, closeModal, shopData, addNewOrder, onSelectedOrder } =
    props;

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.multipleOrderModal}
    >
      <OrderWarpper>
        <OrderHeaderWarpper>
          <div>{"Multiple Orders"}</div>
          <img onClick={() => closeModal()} src={CANCEL} />
        </OrderHeaderWarpper>

        <OrderDetailsWrapper>
          <div>
            {data.orders &&
              data.orders?.map((order: any, index: number) => {
                const time = moment(order.updatedTime).format("DD/MM hh:mmA");
                const price = getCurrencyWithPrice(
                  order.totalPrice,
                  shopData.currency,
                  false
                );
                return (
                  <div key={index} onClick={() => onSelectedOrder(order)}>
                    <div>
                      <div>
                        {order.productDetails
                          .map(
                            (product: any) =>
                              `${product.productName} x ${product.quantity}`
                          )
                          .join(", ")}
                      </div>
                      <div>{order.recieptId}</div>
                      <div>{time}</div>
                      <div>{price}</div>
                    </div>
                    <hr />
                  </div>
                );
              })}
          </div>

          <OrderButtonWrapper>
            <button onClick={() => closeModal()}>{"Cancel"}</button>
            <button onClick={() => addNewOrder()}>{"Add New Order"}</button>
          </OrderButtonWrapper>
        </OrderDetailsWrapper>
      </OrderWarpper>
    </ReactModal>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
  }),
  {}
)(TableOrderModal);
