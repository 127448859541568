import {ENCRYPTED_KEY} from '../appconfig/security_key';
import CryptoJS from"crypto-js";

export const getDecryptedCode = (encrypted) => {
  const bytes = CryptoJS.AES.decrypt(encrypted, ENCRYPTED_KEY);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export const getEncryptedCode = (secret) => {
  const encrypted = CryptoJS.AES.encrypt(secret, ENCRYPTED_KEY).toString();
  return encrypted;
};
