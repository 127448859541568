import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-grid-system';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import 'antd/dist/antd.css';
import {connect} from "react-redux/es/index";
import '../Styles.style.css';
import {Actions} from "../../../internal/app/Actions";
import EditProductItemTopHeader from '../../../components/product/views/EditProductItemTopHeader';
import _ from "lodash";
import ServiceWarningModal from '../../../components/model/views/ServiceWarningModal';
import {navigate} from "../../../internal/service/Navigation.service";
import {nanoid} from "nanoid";

const BrandAddView = (props) => {
  const { shop, deleteBrand, selectedBrand, createBrand, updateBrand, currentUser } = props;

  const [brandName, setBrandName] = useState('');
  const [description, setDescription] = useState('');
  const [isOpenDiscardModel, setIsOpenDiscardModel] = useState(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(selectedBrand)) {
      setBrandName(selectedBrand.brandName);
      setDescription(selectedBrand.description);
    }
  }, []);

  const isEdit = !_.isEmpty(selectedBrand);

  const onCreateBrand = () => {
    let param = {
      brandName,
      description,
      id: nanoid(8),
      shopId: shop.shopId,
      updatedTime: Date.now(),
      createdUserId: currentUser.empId
    };
    if (isEdit) {
      param.id = selectedBrand.id;
      updateBrand(param);
    } else {
      param.createdTime = Date.now();
      createBrand(param);
    }
  };

  const onDeleteBrand = () => {
    const param = {
      id: selectedBrand.id,
      shopId: shop.shopId
    };

    deleteBrand(param);
  };

  const isValid = !_.isEmpty(brandName);
  const isHasntProducts = _.get(selectedBrand, 'totalProductCount', 0) === 0;

  return (
    <div className="addCategoryMainContainer">
      <TopNavigationBar viewName="Products" path="/menu"/>
      <div className="addServiceViewContainer">
        <div className="addServiceViewWrapper">
          <EditProductItemTopHeader
            onCreateService={() => {
              if (isValid) {
                onCreateBrand();
              }
            }}
            isEdit={isEdit}
            onBack={() => {
              if (isEdit) {
                setIsOpenDiscardModel(true);
              } else {
                navigate('/products-customized');
              }
            }}
            isValid={isValid}
            buttonText={"Save Brand"}
            backBtnText={isEdit ? "Edit Brand" : "Add Brand"}
          />
          <Row>
            <Col xs={12}>
              <div className="serviceAddFormContainer">
                <div className="addServiceDetailsText">
                  Details
                </div>
                <div className="addCategoryInputContainer">
                  <Row>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Brand Name*
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input onChange={(text) => setBrandName(text.target.value)} value={brandName} className='serviceTextInput' placeholder="Brand Name"/>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '30px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Description
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <textarea onChange={(text) => setDescription(text.target.value)} value={description} className='serviceTextAreaInput' placeholder="Description"/>
                    </Col>
                  </Row>
                  {
                    isEdit ?
                      <Row style={{marginTop: '20px'}}>
                        <Col xs={2} className="serviceTitleCol" />
                        <Col xs={10} className="serviceTitleColInput">
                          <div onClick={() => {
                            if (isHasntProducts){
                              setIsOpenDeleteModel(true);
                            }
                          }} className="categoryDeleteBtnContainer" style={{backgroundColor: isHasntProducts ? '#FFD6D6' : '#8A9CA5', border: isHasntProducts ? '1px solid #FF5F5F' : '1px solid #8A9CA5'}}>
                            <div className="serviceDeleteBtnText" style={{color: isHasntProducts ? '#FF5F5F' : '#ffffff'}}>
                              Delete Brand
                            </div>
                          </div>
                        </Col>
                      </Row>
                      : null
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ServiceWarningModal
        isOpen={isOpenDiscardModel}
        headerText={'Are you sure?'}
        bodText={'All your changes will be discarded'}
        cancelBtnText={'Discard'}
        onCancel={() => {
          setIsOpenDiscardModel(false);
          navigate('/products-customized');
        }}
        closeModal={() => setIsOpenDiscardModel(false)}
      />
      <ServiceWarningModal
        isOpen={isOpenDeleteModel}
        headerText={'Warning!'}
        bodText={'Your brand will be deleted and no longer\n available to order for the users'}
        cancelBtnText={'Delete'}
        onCancel={() => {
          onDeleteBrand();
          setIsOpenDeleteModel(false);
        }}
        closeModal={() => setIsOpenDeleteModel(false)}
      />
    </div>
  );
};

export default connect(
  state => ({
    shop: state.login.get('merchantShop'),
    selectedBrand: state.product.get('selectedBrand'),
    currentUser: state.login.get('currentUser')
  }),
  {
    updateBrand: Actions.product.updateBrand,
    createBrand: Actions.product.createBrand,
    deleteBrand: Actions.product.deleteBrand
  }
)(BrandAddView);
