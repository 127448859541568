import { nanoid } from "nanoid";
import _, {
  capitalize,
  cloneDeep,
  isNumber,
  compact,
  isEmpty,
  filter,
} from "lodash";

export const mapCategory = (categoryList) => {
  const result = categoryList.map((category) => {
    return {
      sid: category.shopId,
      value: category.categoryId,
      label: category.categoryName,
      categoryId: category.categoryId,
      updatedTime: category.updatedTime,
      description: category.description,
      createdTime: category.createdTime,
      createdUserId: category.createdUserId,
    };
  });
  return result;
};

export const mapBrand = (brandList) => {
  const result = brandList.map((brand) => {
    return {
      id: brand.id,
      value: brand.id,
      shopId: brand.shopId,
      label: brand.brandName,
      brandName: brand.brandName,
      createdTime: brand.createdTime,
      updatedTime: brand.updatedTime,
      description: brand.description,
      createdUserId: brand.createdUserId,
    };
  });
  return result;
};

export const mapWarranty = (warranties: any) => {
  const result = warranties.map((warranty: any) => {
    return {
      value: warranty.wrid,
      label: capitalize(warranty.title),
    };
  });
  return result;
};

export const mapWarrantyProducts = (products) => {
  const result = products.map((product) => {
    return {
      id: product.id,
      totalPrice: product.price,
      quantity: product.quantity,
      productName: product.productName,
      productPrice: product.productPrice,
      sellingPrice: product.productSalePrice,
    };
  });
  return result;
};

export const mapProductType = (types = []) => {
  return types.map((pt) => ({
    ...pt,
    label: pt.typeName,
    value: pt.id,
  }));
};

export const searchProduct = (list, text) => {
  let searchedList = [];
  list.forEach((item) => {
    if (item.productName.includes(text)) {
      searchedList.push(item);
    }
  });

  return searchedList;
};

export const searchCategoryServices = async (
  categoryResult,
  categoryId,
  searchText
) => {
  const categoryArray = _.get(categoryResult, "0.categories", []);
  let filterCategory = _.filter(
    categoryArray,
    (item) => item.id === categoryId
  );
  if (filterCategory.length > 0) {
    const serviceResult = await mapService(
      _.get(filterCategory, "0.services", []),
      searchText
    );
    filterCategory[0].services = serviceResult;
  }
  return filterCategory;
};

export const mapService = async (services, text) => {
  return _.filter(services, (service) => {
    return service.serviceTitle.toLowerCase().includes(text.toLowerCase());
  });
};

export const mapStock = (stocks) => {
  const result = stocks.map((stock) => {
    return {
      shopId: stock.shopId,
      value: stock.itemName,
      label: stock.itemName,
      metric: stock.metric,
      stockId: stock.stockId,
      metricValue: stock.metricValue,
    };
  });
  return result;
};

export const mapStockMetrics = [
  {
    value: "kg (Kilogram)",
    label: "kg (Kilogram)",
    metricValue: "kg",
  },
  {
    value: "l (Litre)",
    label: "l (Litre)",
    metricValue: "l",
  },
  {
    value: "m (Meter)",
    label: "m (Meter)",
    metricValue: "m",
  },
  {
    value: "ml (Millilitre)",
    label: "ml (Millilitre)",
    metricValue: "ml",
  },
  {
    value: "g (Gram)",
    label: "g (Gram)",
    metricValue: "g",
  },
  {
    value: "Item (per Item)",
    label: "Item (per Item)",
    metricValue: "Item",
  },
];

export const calculateStockPrice = (stock, type) => {
  const unitValue = _.get(stock, "unitPrice", "");
  const totalValue = _.get(stock, "totalPrice", "");
  const qtyValue = _.get(stock, "qty", "");
  let unitPrice = unitValue > 0 ? parseFloat(unitValue) : "";
  let totalPrice = totalValue > 0 ? parseFloat(totalValue) : "";
  let quantity = qtyValue > 0 ? parseFloat(qtyValue) : "";

  switch (type) {
    case "QUANTITY":
      {
        if (unitPrice > 0 && isNumber(quantity) && isNumber(unitPrice)) {
          totalPrice = quantity * unitPrice;
        }
      }
      break;
    case "UNIT_PRICE":
      {
        if (quantity > 0 && isNumber(quantity) && isNumber(unitPrice)) {
          totalPrice = quantity * unitPrice;
        }
      }
      break;
    case "TOTAL_PRICE":
      {
        if (quantity > 0 && isNumber(quantity) && isNumber(totalPrice)) {
          unitPrice = parseFloat((totalPrice / quantity).toFixed(2));
        }
      }
      break;
  }

  return {
    unitPrice:
      unitPrice > 0 && isNumber(unitPrice)
        ? (Math.round(unitPrice * 100) / 100).toFixed(2)
        : unitPrice,
    totalPrice:
      totalPrice > 0 && isNumber(totalPrice)
        ? (Math.round(totalPrice * 100) / 100).toFixed(2)
        : totalPrice,
    quantity,
  };
};

export const getTotalPrice = (list) => {
  let total = 0;
  list.forEach((item) => {
    total = total + parseFloat(item.totalPrice);
  });

  return total;
};

export const mapStockRecords = (list) => {
  return list.map((item) => {
    item.totalPrice = (Math.round(item.totalPrice * 100) / 100).toFixed(2);
    item.unitPrice = (Math.round(item.unitPrice * 100) / 100).toFixed(2);

    return item;
  });
};

export const mapStockItemText = (list) => {
  const textList = list.map((item) => {
    const itemName =
      item?.itemData?.label + " " + item.qty + item?.itemData?.metricValue;
    return itemName;
  });

  return textList.join(", ");
};

export const calculateItemCount = (stockItems, stockList) => {
  let itemCount: number = 0;
  stockItems.forEach((item) => {
    const stockItem = _.find(stockList, (stock) => stock.stockId === item.id);
    const itemQuantity: number = parseFloat(_.get(item, "quantity", 1));
    const remainingCount: number = parseFloat(
      _.get(stockItem, "remainingCount", 0)
    );
    const count: number = remainingCount / itemQuantity;
    if (itemCount === 0 || itemCount > count) {
      itemCount = parseInt(count.toFixed(0));
    }
  });

  return itemCount > 0 ? itemCount : 0;
};

export const updateStockItemCount = (
  stockItems,
  stockList,
  quantity,
  isRemoved
) => {
  const productCount = quantity > 0 ? quantity : 1;
  stockItems.forEach((item) => {
    const itemQuantity: number =
      parseFloat(_.get(item, "quantity", 1)) * productCount;
    const index = _.findIndex(
      stockList,
      (stock: any) => stock.stockId === item.id
    );
    if (index > -1) {
      const remainingCount: number = parseFloat(
        _.get(stockList[index], "remainingCount", 0)
      );
      stockList[index].remainingCount = isRemoved
        ? stockList[index].remainingCount + itemQuantity
        : stockList[index].remainingCount - itemQuantity;
    }
  });

  return stockList;
};

export const getStockRemainingCount = (productId, productList, stockList) => {
  const product = _.find(productList, (item) => item.pid === productId);
  if (!_.isEmpty(product)) {
    const stockItems = _.get(product, "stockItems", []);
    const itemCount = calculateItemCount(stockItems, stockList);
  }
};

const checkArrays = (arr1: any, arr2: any) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const result = arr1.every((element: string, index: number) => {
    return element === arr2[index];
  });
  return result;
};

export const generatePriceCombinations = (
  arrays: any,
  priceVariants: any = [],
  isEdit: boolean = false
) => {
  const combinations: any = [];
  const backtrack = (startIndex: any, currentCombination: any) => {
    if (currentCombination.length === arrays.length) {
      combinations.push(currentCombination.slice());
      return;
    }
    const currentArray = arrays[startIndex];
    for (let i = 0; i < currentArray.length; i++) {
      currentCombination.push(currentArray[i]);
      backtrack(startIndex + 1, currentCombination);
      currentCombination.pop();
    }
  };
  backtrack(0, []);

  let variants = combinations.map((item: any) => {
    const optionUnit = item.map((option: any) => option.optionName).join(" - ");
    const options = item.map((option: any) => option.id);
    let newVariants: any = {
      options,
      price: 0,
      optionUnit,
      sku: nanoid(6),
    };
    if (isEdit) {
      const priceValues = cloneDeep(priceVariants);
      priceValues.map((variant: any) => {
        const isEqueal = checkArrays(variant.options, options);
        if (isEqueal) {
          newVariants = {
            ...{
              sku: variant.sku,
              price: variant.price,
              options: variant.options,
              optionUnit: variant.optionUnit,
            },
          };
        }
      });
    }
    return newVariants;
  });
  return variants;
};

export const getProductOptionSets = async (data: any) => {
  const productOptions = data.map((option: any) => {
    const options = option.options.map((item: any) => {
      return {
        id: item.id,
        optionName: item.optionName,
      };
    });
    option.options = options;
    return option;
  });
  return productOptions;
};

export const getWarrantyDuration = [
  {
    value: "DAYS",
    label: "Days",
  },
  {
    value: "WEEKS",
    label: "Weeks",
  },
  {
    value: "MONTHS",
    label: "Months",
  },
  {
    value: "YEARS",
    label: "Years",
  },
];

export const mapProductOffers = (products) => {
  const result = products.map((product) => {
    return {
      label: product.productName,
      value: product.pid,
    };
  });
  return compact(result);
};

export const getModifierInitials = (shop: any, user: any) => {
  const modifier: any = {
    title: "",
    setName: "",
    id: nanoid(8),
    isMandatory: false,
    shopId: shop.shopId,
    createdTime: Date.now(),
    updatedTime: Date.now(),
    createdUser: user.userId,
    items: [
      {
        price: 0,
        itemName: "",
        itemId: nanoid(8),
      },
    ],
  };
  return modifier;
};


export const getInitialModifiers = (product: any) => {
  let modifiers: any = [];
  if (!isEmpty(product?.modifiers)) {
    const params = product?.modifiers?.map((modifier: any) => {
      const items = modifier.items.map((item: any) => {
        return {
          total: 0,
          quantity: 0,
          id: item.itemId,
          price: item.price,
          name: item.itemName,
        };
      });
      return {
        items,
        id: modifier.id,
        title: modifier.title,
        shopId: modifier.shopId,
      };
    });
    modifiers = [...params];
  }
  return modifiers;
};

export const getModifierTotal = (modifiers: any) => {
  let total: any = 0;
  if (!isEmpty(modifiers)) {
    modifiers.forEach((modifier: any) => {
      let subTotal = modifier.items.reduce((subAcc: any, { total }: any) => {
        return subAcc + parseFloat(total);
      }, 0);
      total += subTotal;
    });
  }
  return total;
};

export const getValidModifiersSelected = (modifiers: any) => {
  let valid: boolean = false;
  if (!isEmpty(modifiers)) {
    valid = modifiers?.some((modifier: any) => {
      return modifier?.items?.some(({ quantity }) => quantity > 0);
    });
  }
  return valid;
};

export const getSelectedModifiersItems = (modifiers: any) => {
  let items: any = [];
  if (!isEmpty(modifiers)) {
    // modifiers.map((modifier: any) => {
    //   if (!isEmpty(modifier?.items)) {
    //     const filterData = modifier.items.filter(
    //       ({ quantity }) => quantity > 0
    //     );
    //     items = [...items, ...filterData];
    //   } else {
    //     items = [...items, modifier];
    //   }
    // });

//     id: "brZsdb4m"
// name: "Extra Lettuce"
// price: 50
// quantity: 0
// total: 0
    items = modifiers.map((modifier) => {
      if (!isEmpty(modifier?.items)) {
        const filterData = modifier.items.filter(
          ({ quantity }) => quantity > 0
        );
        modifier.items = filterData;
        //   acc = [...acc, ...filterData];
      }
      return modifier;
      // return acc;
    });
    return compact(items);
  }
  return [];
};

export const getModifierItems = (modifiers: any) => {
  let purchaseModifiers: any = [];
  modifiers?.forEach((modifier: any) => {
    modifier.items?.forEach((item: any) => {
      if (item.quantity > 0) {
        purchaseModifiers.push(item);
      }
    });
  });
  return purchaseModifiers;
};

export const checkArrayEquality = (arr1: any, arr2: any) => {
  var equals =
    arr1.length === arr2.length &&
    arr1.every(
      (e: any, i: any) => e.id === arr2[i].id && e.quantity === arr2[i].quantity
    );
  return equals;
};

export const getIsSameModifiers = (
  purchaseProducts: any,
  selectedProduct: any,
  selectModifiers: any
) => {
  let filterProducts: any = [];
  if (!isEmpty(selectedProduct?.opId)) {
    filterProducts = filter(
      purchaseProducts,
      (item: any) => item.opId === selectedProduct.opId
    );
  } else {
    filterProducts = filter(
      purchaseProducts,
      (item: any) => item.id === selectedProduct.id
    );
  }
  if (!isEmpty(filterProducts)) {
    return filterProducts.some((product: any) => {
      let array1: any = getModifierItems(product.modifiers);
      let array2: any = getModifierItems(selectModifiers);
      const result = checkArrayEquality(array1, array2);
      return result;
    });
  } else {
    return false;
  }
};


export const mapModifiersForPaymentDetails = (products, id, modifiers) => {
  const product = products.find(({pid}) => pid === id);
  console.log({product, modifiers}, '>>>>>>>')
  // if (!isEmpty(product?.modifiers)) {
  //   product.modifiers.reduce((acc, modifier) => {
  //     // modifier.items.find({})
  //   }, [])
  // }
  return modifiers;
}