import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { connect } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragWrapper } from './Styled';
import RoomNavigation from './RoomNavigation';
import RoomEditArea from './RoomEditArea';

const RoomsViewComponent = connect()(() => {
  return (
    <DragWrapper id="rooms">
      <RoomNavigation />
      <RoomEditArea />
    </DragWrapper>
  );
});

const RoomsView = () => (
  <DndProvider backend={HTML5Backend}>
    <RoomsViewComponent />
  </DndProvider>
);

export default RoomsView;
