import gql from 'graphql-tag';

export const createServiceCategory = gql`
  mutation CreateServiceCategory($input: CreateServiceCategoryInput!) {
    createServiceCategory(input: $input) {
      scid
      shopId
      createdUserId
      categories {
        id
        category
        createdTime
        isPrimary
        specializations {
          id
          title
        }
        services {
          id
          serviceTitle
          price
          isPrimary
          duration
          description
          image
        }
      }
    }
  }
`;

export const updateServiceCategory = gql`
  mutation UpdateServiceCategory($input: UpdateServiceCategoryInput!) {
    updateServiceCategory(input: $input) {
      scid
      shopId
      createdUserId
      categories {
        id
        category
        createdTime
        isPrimary
        specializations {
          id
          title
        }
        services {
          id
          serviceTitle
          price
          isPrimary
          duration
          description
          image
        }
      }
    }
  }
`;
