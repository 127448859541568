import React, {Component} from 'react';
import { useIntl } from 'react-intl';
import {connect} from "react-redux";
import {CashComponentContainer} from '../../components/Styles/Styled';
import {getCurrencyWithPrice} from '../../internal/manager/PaymentManager';

const CashComponent = (props) => {
  const {salon, totalPrice, onChangePrice, balancePrice, givenPrice,} = props;
  const {formatMessage: f} = useIntl();

  return (
    <CashComponentContainer>
      <div id={'cashComponentTotalContainer'}>
        <div id={'cashTotalText'}>{f({id: "CHECKOUT.SUB_TOTAL"})}</div>
        <div id={'cashTotalInput'}>{getCurrencyWithPrice(totalPrice.toFixed(2), salon.currency)}</div>
      </div>
      <div id={'cashComponentCashContainer'}>
        <div id={'cashTotalText'}>{f({id: "CHECKOUT.PAID_CASH"})}</div>
        <div id={'cashCurrencyText'}>{`${salon.currency}`}</div>
        <input value={givenPrice} id={'cashTotalInput'} onChange={(number) => onChangePrice(number.target.value)} placeholder="Cash"/>
      </div>
    </CashComponentContainer>
  );
};
export default connect(
  state => ({
    salon: state.salon.get('salon')
  }),
  {}
)(CashComponent);
