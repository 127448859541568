import React from 'react';
import {ModuleEvents} from './Actions';
import {navigate} from "../../internal/service/Navigation.service";
import {Actions} from './../../internal/app/Actions';
import _ from 'lodash';
import swal from "sweetalert";

export default {
  [ModuleEvents.RESET_PASSWORD]: ({dispatch, payload, appState}) => {
    const errorCode = _.get(payload, 'error.code');
    if (payload && payload.error) {
      if (errorCode === "NotAuthorizedException") {
        return warning("Enter valid current password");
      } else {
        return warning("Catched error when resetting password");
      }
    }
  },
}

const warning = (alert) => {
  return swal({
    title: alert,
    icon: "warning",
    buttons: true,
    dangerMode: true
  });
};



