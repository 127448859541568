import React from 'react';
import {ModuleEvents} from './Actions';
import {navigate} from "../../internal/service/Navigation.service";
import swal from 'sweetalert';
import {Actions} from "../../internal/app/Actions";

export default {
  [ModuleEvents.CREATE_APPOINTMENT]: ({dispatch, payload, appState}) => {
    if(payload && !payload.error && payload.statusCode === 200) {
      const shopId = appState.common.get('shopId');
      dispatch(Actions.orders.fetchSalonAppointment(shopId));
      return success("Appointment create successfully");
    } else {
      return warning("Appointment create unsuccessful");
    }
  },
}

const success = (alert) => {
  return swal({
    title: alert,
    icon: "success"
  });
};

const warning = (alert) => {
  return swal({
    title: alert,
    icon: "warning",
    buttons: true,
    dangerMode: true
  });
};


