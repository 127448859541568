import React, { useEffect, useState } from "react";
import "./index.css";
import App from "./App";
import "./App.style.css";
import ReactDOM from "react-dom";
import { get, isEmpty } from "lodash";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { store, persistor } from "./internal/app/Store";
import Storage from "./internal/service/Storage.service";
import { getClient } from "./internal/manager/ClientManager";
import { PersistGate } from "redux-persist/integration/react";

const AppWrapper = () => {
  const [client, setClient] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [ipAddress, setIpAddress] = useState<string>("");

  const onMessage = (message: any) => {
    console.log({ message });
    if (message && message.data) {
      const data = JSON.parse(message.data);
      if (get(data, "workMode", "") === "CLUSTER") {
        const { master } = data;
        window["standlone"] = false;
        setIpAddress(master.address);
      } else if (get(data, "workMode", "") === "STANDALONE") {
        setLoading(false);
        window["standlone"] = true;
      } else if (!isEmpty(get(data, "network", null))) {
        localStorage.setItem("NETWORK", data.network);
      } else {
        try {
          Storage.asyncUpdate(data);
        } catch (e) {
          console.info("Disregarded", message);
        }
      }
    }
  };
  window.addEventListener("message", onMessage);

  useEffect(() => {
    if (window?.api) {
      const client = getClient("localhost");
      setClient(client);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(ipAddress)) {
      const client = getClient(ipAddress);
      setClient(client);
      setLoading(false);
    }
  }, [ipAddress]);

  if (loading) {
    return (
      <div style={{ position: "absolute", top: "48%", left: "48%" }}>
        <CircularProgress color="primary" size={40} value={5} />
      </div>
    );
  }

  return (
    <>
      {client ? (
        <ApolloProvider client={client}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </ApolloProvider>
      ) : (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      )}
    </>
  );
};

ReactDOM.render(<AppWrapper />, document.getElementById("root"));
