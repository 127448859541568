import { ModuleEvents } from "./Actions";
import { Actions } from "../../internal/app/Actions";
import { navigate } from "../../internal/service/Navigation.service";

export default {
  [ModuleEvents.FETCH_ALL_EMPLOYEE_CATEGORY]: ({
    dispatch,
    payload,
    appState,
  }) => {
    if (payload && payload[0] && !payload.error) {
      dispatch(Actions.employee.fetchStylistForCategoryId(payload[0].id));
    }
  },
  [ModuleEvents.FETCH_SHOP_EMPLOYEES]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      navigate("/welcome");
    }
  },
};
