import React, { useEffect, useState } from 'react';
import styles from '../../../components/Styles/Styles.css';
import _ from 'lodash';
import { Row, Col } from 'react-grid-system';
import { connect } from 'react-redux';
import { Actions } from '../../../internal/app/Actions';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { specializationDetails } from '../../../modules/employee/Constant';
import SearchComponent from '../../search/SearchComponent';
import StylistSpecializationComponent from '../../../components/salonStylists/StylistSpecializationComponent';
import StylistComponent from '../../../components/salonStylists/StylistComponent';

const SalonStylists = (props) => {
  const {
    merchantShop,
    employees = [],
    selectedAppointment,
    onSelectEmployee,
    selectedEmployee,
    fetchEmployeesForSpecId,
    fetchEmployees,
    loadingAction,
  } = props;
  const { action, loading } = loadingAction;
  const [searchText, setSearchText] = useState('');
  const [selectedSpecialization, setSelectedSpecialization] = useState({});
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    setEmployeeList([...employees]);
    if (_.isEmpty(selectedSpecialization)) {
      setSelectedSpecialization({ id: '0', title: 'All' });
    }
  }, [employees, selectedSpecialization]);

  const searchStylistForName = (text) => {
    setSearchText(text);
    if (text && text.length > 0) {
      const filterStylist = _.filter(employees, (employee) => {
        return employee.name.toLowerCase().match(text);
      });
      setEmployeeList(filterStylist);
    } else {
      fetchEmployeesForSpecId(merchantShop.shopId, selectedSpecialization);
    }
  };

  useEffect(() => {
    fetchEmployees(merchantShop.shopId);
  }, []);

  const onSelectSpecialization = (spec) => {
    setSelectedSpecialization(spec);
    if (spec && spec.title === 'All') {
      fetchEmployees(merchantShop.shopId);
    } else {
      fetchEmployeesForSpecId(merchantShop.shopId, spec);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <SearchComponent
          searchText={searchText}
          title={'Search for shop employee'}
          deleteSearchText={() => setSearchText('')}
          onSearchText={(text) => searchStylistForName(text)}
        />
        <StylistSpecializationComponent
          specializationDetails={specializationDetails}
          selectedSpecialization={selectedSpecialization}
          onSelect={(spec) => {
            setSearchText('');
            onSelectSpecialization(spec);
          }}
        />
        {loading &&
          (action.type === 'FETCH_EMPLOYEES' ||
            action.type === 'FETCH_EMPLOYEES_FOR_SPEC_ID') && (
            <div className={styles.productLoading}>
              <CircularProgress color="primary" size={60} value={5} />
            </div>
          )}
        {!_.isEmpty(employeeList) && (
          <StylistComponent
            employeeList={employeeList}
            selectedEmployee={selectedEmployee}
            onSelectEmployee={(data) => onSelectEmployee(data)}
          />
        )}
      </Col>
    </Row>
  );
};
export default connect(
  (state) => ({
    merchantShop: state.login.get('merchantShop'),
    employees: state.employee.get('employees'),
    loadingAction: state.common.get('loadingAction'),
    selectedEmployee: state.employee.get('selectedEmployee'),
    selectedAppointment: state.orders.get('selectedAppointment'),
  }),
  {
    fetchEmployees: Actions.employee.fetchEmployees,
    onSelectEmployee: Actions.employee.onSelectEmployee,
    fetchEmployeesForSpecId: Actions.employee.fetchEmployeesForSpecId,
  }
)(SalonStylists);
