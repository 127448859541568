import React, {Component} from 'react';
import styles from '../Styles/Styles.css';
import {Container, Row, Col} from 'react-grid-system';
import IconCross from "../../../assets/svg/IconCross.svg";

const SearchComponent = (props) => {
  const {title, searchText, onSearchText, deleteSearchText} = props;
  return (
    <Row>
      <Col xs={12}>
        <div className={styles.searchInputWrapper}>
          <input value={searchText} onChange={(event) => onSearchText(event.target.value)} type={"text"} placeholder={title} className={styles.searchInput}/>
          <img onClick={() => deleteSearchText()} src={IconCross}/>
        </div>
      </Col>
    </Row>
  );
};
export default SearchComponent;
