import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Catalogue } from "../../index";
import PubSub from "@aws-amplify/pubsub";
import "react-calendar/dist/Calendar.css";
import AmplifyJS from "@aws-amplify/core";
import { IntlProvider } from "react-intl";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import Menu from "../../menu/views/Menu";
import Profile from "../../profile/views/Profile";
import EditMenu from "../../editMenu/views/EditMenu";
import Settings from "../../settings/views/Settings";
import CloseCash from "../../closeCash/views/CloseCash";
import InvoiceView from "../../invoice/views/InvoiceView";
import ProductView from "../../product/views/ProductView";
import ReportsView from "../../reports/views/ReportsView";
import Transactions from "../../transactions/views/Transactions";
import OrderQueueView from "../../OrderQueue/Views/OrderQueueView";

import { appHistory } from "../../../internal/app/Store";
import awsconfig from "../../../internal/appconfig/aws-config";
import { loadLocaleData } from "../../../internal/manager/LanguageManager";

import CalendarView from "../../orders/views/CalendarView";
import OrderLineView from "../../orders/views/OrderLineView";

import ServiceAddView from "../../service/views/ServiceAddView";
import CategoryAddView from "../../service/views/CategoryAddView";
import ServiceCustomizedView from "../../service/views/ServiceCustomizedView";

import LoadingOverlay from "../../../components/loading/LoadingOverlay";
import SalonStylists from "../../../components/salonStylists/views/SalonStylists";
import DateAndTimeView from "../../../components/dateAndTime/views/DateAndTimeView";

import LoginView from "../../login/Views/LoginView";
import OnboardView from "../../login/Views/OnboardView";
import LoadingView from "../../login/Views/LoadingView";
import WelcomeView from "../../login/Views/WelcomeView";
import ProgressView from "../../login/Views/ProgressView";

import EditRoomType from "../../rooms/views/EditRoomType";
import RoomTypesView from "../../rooms/views/RoomTypesView";

import BrandAddView from "../../product/views/BrandAddView";
import ProductAddStock from "../../product/views/StockAddView";
import ProductAddView from "../../product/views/ProductAddView";
import AddWarrantyView from "../../product/views/AddWarrantyView";
import SupplierAddView from "../../product/views/SupplierAddView";
import ProductPriceView from "../../product/views/ProductPriceView";
import ProductOptionView from "../../product/views/ProductOptionView";
import ProductModifierView from "../../product/views/ProductModifierView";

import ProductTypeAddView from "../../product/views/ProductTypeAddView";
import ProductCustomizedView from "../../product/views/ProductCustomizedView";
import ProductCategoryAddView from "../../product/views/ProductCategoryAddView";

import ActivityLogView from "../../activityLog/views/ActivityLogView";

import SplitBillView from "../../payment/views/SplitBillView";
import BookingDetailsView from "../../payment/views/BookingDetailsView";

import StockRecordAddView from "../../stock/views/StockRecordAddView";
import StockCustomizedView from "../../stock/views/StockCustomizedView";
import CheckSubscription from "../../login/Views/CheckSubscription";

// awsconfig.Auth.storage = Storage;
AmplifyJS.configure(awsconfig);
PubSub.configure(awsconfig);

const Main = (props) => {
  const { currentUser, productCategoryList } = props;
  const data = loadLocaleData("en-US");
  const [locale, setLocale] = useState("en-US");
  const [messages, setMessages] = useState(data);

  const getMessages = async (locale) => {
    const data = await loadLocaleData(locale);
    setMessages(data);
  };

  useEffect(() => {
    const locale = _.get(currentUser, "language", "en-US");
    getMessages(locale);
    setLocale(locale);
  }, [currentUser]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <LoadingOverlay />
      <Router history={appHistory}>
        <Switch>
          <Route exact path="/" component={LoadingView} />
          <Route path="/login" component={LoginView} />
          <Route path="/welcome" component={WelcomeView} />
          <Route path="/subscription-check" component={CheckSubscription} />

          <Route path="/order" component={OrderQueueView} />
          <Route path="/product" component={ProductView} />
          <Route path="/order-list" component={OrderLineView} />
          <Route path="/menu" component={Menu} />
          <Route path="/reports" component={ReportsView} />
          <Route path="/profile" component={Profile} />
          <Route
            path="/services-customized"
            component={ServiceCustomizedView}
          />
          <Route
            path="/products-customized"
            component={ProductCustomizedView}
          />
          <Route path="/stock-customized" component={StockCustomizedView} />
          <Route path="/settings" component={Settings} />
          <Route path="/closeCash" component={CloseCash} />
          <Route path="/add-service" component={ServiceAddView} />
          <Route path="/add-brand" component={BrandAddView} />
          <Route path="/add-supplier" component={SupplierAddView} />
          <Route path="/add-product" component={ProductAddView} />
          <Route path="/add-product-options" component={ProductOptionView} />
          <Route path="/add-product-modifier" component={ProductModifierView} />
          <Route path="/add-product-price" component={ProductPriceView} />
          <Route path="/add-warranty" component={AddWarrantyView} />

          <Route path="/add-category" component={CategoryAddView} />
          <Route path="/add-product-type" component={ProductTypeAddView} />
          <Route path="/edit-room" component={EditRoomType} />
          <Route
            path="/add-product-category"
            component={ProductCategoryAddView}
          />
          <Route path="/transactions" component={Transactions} />
          <Route path="/add-stock" component={ProductAddStock} />
          <Route path="/add-stock-record" component={StockRecordAddView} />
          <Route path="/checkout" component={BookingDetailsView} />
          <Route path="/date-and-time" component={DateAndTimeView} />
          <Route path="/onboard" component={OnboardView} />
          <Route path="/loading" component={LoadingView} />
          <Route path="/progress" component={ProgressView} />
          <Route path="/stylist" component={SalonStylists} />
          <Route path="/appointmentCalendar" component={CalendarView} />
          <Route path="/invoice" component={InvoiceView} />
          <Route path="/rooms" component={RoomTypesView} />
          <Route path="/editMenu" component={EditMenu} />
          <Route path="/catalogue" component={Catalogue} />
          <Route path="/split-bill" component={SplitBillView} />
          <Route path="/activity-log" component={ActivityLogView} />
          <Redirect from="/" to="/" />
        </Switch>
      </Router>
    </IntlProvider>
  );
};
export default React.memo(
  connect(
    (state) => ({
      currentUser: state.login.get("currentUser"),
    }),
    {
      // getMerchant: Actions.login.getMerchant,
      // updateAllOfflineActions: Actions.common.updateAllOfflineActions
    }
  )(Main)
);
