import styled from "styled-components";
import BaseColors from "../../../internal/helpers/BaseColors";

export const TransactionsContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${BaseColors.aquaHaze};
`;

export const TransactionsBottomContainer = styled.div`
  width: 100%;
  height: calc(100vh - 61px);
  padding: 10px;
  background-color: ${BaseColors.aquaHaze};

  & > div {
    width: 100%;
    height: calc(100vh - 81px);
    border-radius: 3px;
    background-color: ${BaseColors.white};
  }
`;

export const TransactionsRightContainer = styled.div`
  // width: 100%;
  height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
  margin-right: 10px;
`;

export const TransactionsRightDetailsContainer = styled.div`
  width: 100%;
  height: calc(100vh - 217px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 20px;
`;

export const TransactionsRightDetailsContainerInfo = styled.div`
  width: 100%;
  height: calc(100vh - 248px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: ${BaseColors.white};
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.16);
`;

export const RefundButtonContainer = styled.div`
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ReopenButton = styled.div<{ valid: boolean }>`
  height: 54px;
  background-color: ${(props) =>
    props.valid ? BaseColors.shakespeare : BaseColors.wildSand};
  border: ${(props) =>
    props.valid ? "1px solid #ffffff" : "1px solid #cccccc"};
  color: ${(props) => (props.valid ? BaseColors.white : BaseColors.silver)};
  width: 244px;
  text-align: center;
  font-size: 16px;
  border-radius: 3px;
  transition-duration: 0.4s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 600;

  :focus {
    outline: none;
  }
`;

export const RefundButton = styled.button<{ valid: boolean }>`
  height: 54px;
  background-color: ${(props) =>
    props.valid ? BaseColors.cosmos : BaseColors.wildSand};
  border: ${(props) =>
    props.valid ? "1px solid #FF5F5F" : "1px solid #cccccc"};
  color: ${(props) =>
    props.valid ? BaseColors.bittersweet : BaseColors.silver};
  width: 244px;
  text-align: center;
  font-size: 16px;
  border-radius: 3px;
  transition-duration: 0.4s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 600;

  :focus {
    outline: none;
  }
`;
