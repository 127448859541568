import _, { isArray } from "lodash";
import axios from "axios";
import isOnline from "is-online";
import Auth from "@aws-amplify/auth";
import { RootState } from "../app/Store";
import Storage from "../service/Storage.service";
import API, { APIClass } from "@aws-amplify/api";
import AppStorageInstance from "../service/AppStorageService";

Auth.configure({
  storage: Storage,
});

const Config = {
  CLOUD_POS_LAMBDA_API: "dev-netlise-pos-lambda-api", //l9qr6ot4n3
  CLOUD_POS_API: "dev-cloud-pos-api", //vri1ubif20
  DASHBOARD_API: "dev-admin-dashboard-api", //89wlkrrxp4
  DASHBOARD_API_V2: "dev-netlise-api-v2", //yizhugwdbh
  API_NETLISE_APP: "dev-netlise-app-api", //aekt4a5ms5
  API_REPORTS: "netlise-report-api-dev", //um1pi9i45l
  API_ORDERS: "netlise-order-api-dev", //l9qr6ot4n3
};

export default class Repository {
  API: APIClass | null = null;
  API_ORDERS = Config.API_ORDERS;
  API_REPORTS = Config.API_REPORTS;
  CLOUD_POS_API = Config.CLOUD_POS_API;
  DASHBOARD_API = Config.DASHBOARD_API;
  API_NETLISE_APP = Config.API_NETLISE_APP;
  DASHBOARD_API_V2 = Config.DASHBOARD_API_V2;
  CLOUD_POS_LAMBDA_API = Config.CLOUD_POS_LAMBDA_API;

  Storage = Storage;
  store: RootState | null = null;
  constructor() {
    this.API = API;
  }

  isCheckOnline = async () => {
    return await isOnline();
    // return navigator.onLine;
  };

  getData = (url: string, data: any = {}) => {
    return this.data("get", url, data);
  };

  postData = (url, data) => {
    return this.data("post", url, data);
  };

  async _api(method = "get", apiName, path, message, trycount = 0) {
    if (trycount > 3) {
      throw Error("Try count exceeded");
    }
    let token = null;
    try {
      token = (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (e) {
      console.warn("Not authorized");
    }

    const headers: any = {};

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return this.API[method](apiName, path, { ...message, headers })
      .then((response) => {
        if (method === "get") {
          return response;
        }
        return response.data;
      })
      .catch((error) => {
        // console.log(error, error.code)
        // if (error && error.code === "NotAuthorizedException") {
        //   Auth.signOut();
        //   return this._api(method, apiName, path, message, trycount + 1);
        // }
        console.log(error);
        throw error;
      });
  }

  buildMessage(body) {
    return {
      headers: {},
      body,
      response: true,
    };
  }

  apiPost({ apiName = this.CLOUD_POS_LAMBDA_API, path, message }) {
    return this._api("post", apiName, path, message);
  }

  apiDelete({ apiName = this.CLOUD_POS_LAMBDA_API, path, message }) {
    return this._api("del", apiName, path, message);
  }

  apiPut({ apiName = this.CLOUD_POS_LAMBDA_API, path, message }) {
    return this._api("put", apiName, path, message);
  }

  apiGet({ apiName = this.CLOUD_POS_LAMBDA_API, path, message = {} }) {
    return this._api("get", apiName, path, message);
  }

  data = (method, url, data = null) => {
    return axios({
      method: method,
      url: url,
      data: data,
    })
      .then((response) => response)
      .catch((e) => {
        const error = JSON.stringify(e);
        console.warn(error);
        return e.response || null;
        //data: JSON.parse(error).response
      });
  };

  storeSet = async (key, value) => {
    return AppStorageInstance.storeSet(key, value);
  };

  storeGet = (key: string) => {
    return AppStorageInstance.storeGet(key);
  };

  storeGetResource = async (key: string) => {
    return AppStorageInstance.storeGetResource(key);
  };

  storePush = async (key: string, value: any) => {
    return AppStorageInstance.storePush(key, value);
  };

  storePushUnique = async (key: string, value: any, id: string) => {
    const data = (await AppStorageInstance.storeGet(key)) || [];
    console.log(">>>>>>", data, key);
    if (data && isArray(data)) {
      const index = data.findIndex((d) => d[id] === value[id]);
      if (index > -1) {
        data[index] = value; // conflict resolution
      } else {
        data.push(value);
      }
    } else {
      data.push(value);
    }
    console.log(">>>>>> after", data, key);
    const updated = await AppStorageInstance.storeSet(
      key,
      JSON.stringify(data)
    );
    if (isArray(updated)) {
      return updated.find((item) => item[id] === value[id]);
    }
    return value;
  };

  storeRemove = async (value: any, key: string) => {
    return AppStorageInstance.storeRemove(value, key);
  };

  storeRemoveItem = async (key: string, id: string, compare: string) => {
    return AppStorageInstance.storeRemoveItem(key, id, compare);
  };

  storeShift = async (key: string) => {
    return AppStorageInstance.storeShift(key);
  };

  sync = async () => {
    return AppStorageInstance.sync();
  };
}
