import { isArray } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Actions from "../Actions";
import { TableEditWrapper } from "./Styled";
import RoomModal from "./RoomModal";
import { Square } from "./Rooms";

const RoomEditArea = ({ mode, rooms, incompleteOrders }) => {
  const [areaRooms, setAreaRooms] = useState<any>([]);
  const [selectedRoom, setSelectedRoom] = useState(null);

  useEffect(() => {
    if (!isArray(rooms)) {
      return;
    }
    const orderRemovedRooms = rooms.map(({ order, ...rm }) => rm);
    
    if (isArray(rooms) && isArray(incompleteOrders)) {
      incompleteOrders.forEach((order) => {
        if (order.roomOrder) {
          const orderRmId = order.roomOrder.rmId;
          const index = orderRemovedRooms.findIndex(
            ({ rmId }) => rmId === orderRmId
          );
          if (index > -1) {
            orderRemovedRooms[index] = { ...orderRemovedRooms[index], order };
          }
        }
      });
    }
    setAreaRooms([...orderRemovedRooms]);
  }, [rooms, incompleteOrders]);

  // const tables = tableAreas.tableAreas[index].tables;

  return (
    <TableEditWrapper
      className={`table-edit-wrapper ${mode}`}
      isEdit={mode === "edit"}
    >
      {areaRooms.map((room) => {
        return (
          <Square
            room={room}
            key={`${room.rmId}-${room.rmNumber}`}
            onEdit={setSelectedRoom}
          />
        );
      })}
      <RoomModal
        isOpen={!!selectedRoom}
        closeModal={() => setSelectedRoom(null)}
        selectedRoom={selectedRoom}
      />
    </TableEditWrapper>
  );
};

export default connect(
  (state: any) => ({
    rooms: state.rooms.get("rooms"),
    mode: state.rooms.get("roomMode"),
    roomAreas: state.rooms.get("tableAreas"),
    selectedAreas: state.rooms.get("tableAreas"),
    incompleteOrders: state.orders.get("unCompletedAppointments"),
  }),
  {
    editRooms: Actions.editRooms,
  }
)(RoomEditArea);
