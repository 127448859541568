import styled from "styled-components";
import BaseColors from "../../../internal/helpers/BaseColors";

export const Title = styled.h3`
  font-size: 16px;
`;
export const TitleBold = styled.h3`
  font-size: 16px;
  font-weight: 700;
`;

export const PrinterContainer = styled.div`
  width: 80%;
  font-family: Montserrat;
  font-weight: 500;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;
  }
  & > div > div:first-child {
    width: 80%;
  }
  & > div > div:last-child {
    width: 20%;
  }
`;

export const RegisterContainer = styled.div`
  gap: 23px;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div:first-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div:first-child {
      font-size: 16px;
      font-weight: 500;
      font-family: Montserrat;
      color: ${BaseColors.nero};
    }
    & > div:last-child {
      font-size: 14px;
      font-weight: 500;
      font-family: Montserrat;
      color: ${BaseColors.nero};
    }
  }

  & > div:nth-child(2) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div:first-child {
      font-size: 16px;
      font-weight: 500;
      font-family: Montserrat;
      color: ${BaseColors.nero};
    }
    & > div:last-child {
      font-size: 14px;
      font-weight: 500;
      font-family: Montserrat;
      color: ${BaseColors.nero};
    }
  }

  & > div:nth-child(3) {
    font-size: 16px;
    font-weight: 600;
    font-family: Montserrat;
    color: ${BaseColors.nero};
  }

  & > div:nth-child(4) {
    display: flex;
    margin-left: 36px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    & > span {
      padding: 0;
    }
    & > div {
      font-size: 14px;
      font-weight: 400;
      margin-left: 16px;
      font-family: Montserrat;
      color: ${BaseColors.black85};
      :hover {
        cursor: pointer;
      }
    }
  }

  & > div:nth-child(5) {
    display: flex;
    margin-left: 36px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    & > span {
      padding: 0;
    }
    & > div {
      font-size: 14px;
      font-weight: 400;
      margin-left: 16px;
      font-family: Montserrat;
      color: ${BaseColors.black85};
      :hover {
        cursor: pointer;
      }
    }
  }

  & > div:nth-child(6) {
    width: 100%;
    height: 1px;
    background-color: ${BaseColors.baliHai25};
  }

  & > div:nth-child(7) {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    justify-content: space-between;

    & > div:first-child {
      font-size: 16px;
      font-weight: 600;
      font-family: Montserrat;
      color: ${BaseColors.nero};
    }
    & > div:last-child {
      width: 40%;
    }
  }
`;

export const RegisterOrderWrapper = styled.div`
  gap: 26px;
  width: 100%;
  display: flex;
  margin-bottom: 14px;
  flex-direction: column;
  justify-content: flex-start;

  & > div:first-child {
    margin-left: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 14px;
    font-family: Montserrat;
    color: ${BaseColors.nero};
  }

  & > div {
    width: 270px;
    display: flex;
    margin-left: 40px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    & > div {
      font-size: 14px;
      font-weight: 500;
      font-family: Montserrat;
      color: ${BaseColors.nero};

      :hover {
        cursor: pointer;
      }
    }
  }
`;

export const ServiceOptions = styled.ul`
  & > li {
    margin: 0px;
    font-weight: 500 !important;
    font-size: 1rem;
  }

  & > li > a:hover {
    font-weight: 500 !important;
  }
  & > li.selected > a {
    color: ${BaseColors.white};
    background-color: ${BaseColors.pictonBlue};
  }
`;

export const ServiceOption = styled.div`
  padding: 0.8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  & > div:first-child {
    font-size: 1.2rem;
    font-weight: 500;
  }
  & > div:last-child {
    font-size: 0.75rem;
    font-weight: 400;
    color: ${BaseColors.regentGray};
  }
`;
