import { nanoid } from "nanoid";
import { createAction } from "../../internal/app/AppUtils";
import TableRepository from "../../internal/repos/TableRepository";
import { mapTables } from "../../internal/manager/AppointmentManager";

export const ModuleEvents = {
  EDIT_TABLES: "EDIT_TABLES",
  SAVE_TABLES: "SAVE_TABLES",
  SELECT_AREA: "SELECT_AREA",
  FETCH_TABLES: "FETCH_TABLES",
  SELECT_TABLE: "SELECT_TABLE",
  SET_EDIT_MODE: "SET_EDIT_MODE",
  CREATE_TABLE_ORDER: "CREATE_TABLE_ORDER",
  ON_SUBSCRIPTION_TABLE: "ON_SUBSCRIPTION_TABLE",
};

const Actions = {
  editTables: createAction(ModuleEvents.EDIT_TABLES, async (tables) => {
    return tables;
  }),

  saveTables: createAction(
    ModuleEvents.SAVE_TABLES,
    async (tables, isEdit = false) => {
      const tableAreas = tables.tableAreas.map((ta) => {
        ta.tables = mapTables(ta.tables);
        return ta;
      });
      tables.tableAreas = tableAreas;
      if (isEdit) {
        const tablesResponse = await TableRepository.updateTables(tables);
        return tablesResponse;
      }
      const tablesResponse = await TableRepository.createTables(tables);
      return tablesResponse;
    }
  ),

  fetchTables: createAction(ModuleEvents.FETCH_TABLES, async (shopId) => {
    const tables = await TableRepository.fetchTables(shopId);
    if (!tables) {
      return {
        tableAreas: [
          {
            areaName: "Floor Area",
            areaId: nanoid(),
            tables: [],
          },
        ],
      };
    }
    return tables;
  }),

  selectArea: createAction(ModuleEvents.SELECT_AREA, async (area) => {
    return area;
  }),

  setEditMode: createAction(ModuleEvents.SET_EDIT_MODE, async (mode) => {
    return mode;
  }),
  createTableOrder: createAction(
    ModuleEvents.CREATE_TABLE_ORDER,
    async (table) => {
      return table;
    }
  ),
  selectTable: createAction(ModuleEvents.SELECT_TABLE, async (table) => {
    return table;
  }),
  onSubscriptionTable: createAction(
    ModuleEvents.ON_SUBSCRIPTION_TABLE,
    async (table: any) => {
      return table;
    }
  ),
};

export default Actions;
