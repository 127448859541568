import React, { useEffect, useState, useRef } from "react";
import _, {
  get,
  find,
  filter,
  isEmpty,
  compact,
  isString,
  cloneDeep,
} from "lodash";
import {
  LogWrapper,
  ActionButton,
  LogHeaderWrapper,
  LogDetailsWrapper,
  LogHeaderTextWrapper,
  LogDetailsTextWrapper,
} from "./Styled";
import moment from "moment";
import swal from "sweetalert";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Row, Col } from "react-grid-system";
import {
  getActivityStatus,
  getValueFromLocalStorage,
} from "../../../internal/manager/CommonManager";
import { Actions } from "../../../internal/app/Actions";
import { getCurrencyWithPrice } from "../../../internal/manager/PaymentManager";
import ServiceWarningModal from "../../../components/model/views/ServiceWarningModal";

const headerData = [
  { size: 2, title: "ACTIVITY_LOG.TABLE.EMPLOYEE", itemPosition: "flex-start" },
  {
    size: 2.5,
    itemPosition: "center",
    title: "ACTIVITY_LOG.TABLE.ACTIVITY_DATE",
  },
  { size: 1.5, title: "ACTIVITY_LOG.TABLE.ACTIVITY", itemPosition: "center" },
  { size: 2, title: "ACTIVITY_LOG.TABLE.TOTAL", itemPosition: "flex-end" },
  { size: 2, title: "ACTIVITY_LOG.TABLE.ORDER_NUMBER", itemPosition: "center" },
  { size: 2, title: "ACTIVITY_LOG.TABLE.ACTION", itemPosition: "center" },
];
const LogTable = (props: any) => {
  const listInnerRef: any = useRef();
  const { formatMessage: f } = useIntl();
  const {
    orders,
    shopData,
    checkout,
    nextToken,
    activities,
    fetchActivities,
    restoreActivityLog,
  } = props;
  const [activity, setActivity] = useState<any>({});
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [activityList, setActivityList] = useState<Array<any>>([]);

  const setActivityData = async (data: any) => {
    let newData: any = cloneDeep(data);

    const newValue = await Promise.all(
      newData.map(async (data: any) => {
        data.metaData = await Promise.all(
          data.metaData.map(async (meta: any) => {
            const jsonValue = await getValueFromLocalStorage(meta.value);
            if (!jsonValue) {
              return null;
            }
            const value: any =
              isString(jsonValue) && jsonValue.length > 0
                ? JSON.parse(jsonValue)
                : jsonValue;
            meta.value = value;
            return meta;
          })
        );
        return data;
      })
    );
    const filterArray = filter(
      newValue,
      (log: any) => compact(log.metaData).length > 0
    );
    setActivityList([...filterArray]);
  };

  useEffect(() => {
    if (activities) {
      setActivityData(activities);
    }
  }, [activities]);

  const renderHeader = () => {
    return (
      <Row style={{ height: "100%", margin: 0 }}>
        {headerData.map((data) => {
          return (
            <Col md={data.size}>
              <LogHeaderTextWrapper justifyContent={data.itemPosition}>
                <div>{f({ id: data.title })}</div>
              </LogHeaderTextWrapper>
            </Col>
          );
        })}
      </Row>
    );
  };

  const renderData = (
    text: string,
    justifyContent: string,
    size: number = 2
  ) => {
    return (
      <Col md={size}>
        <LogDetailsTextWrapper justifyContent={justifyContent}>
          {text}
        </LogDetailsTextWrapper>
      </Col>
    );
  };

  const renderTable = (
    activityList: any,
    openOrder: (activity: any) => void
  ) => {
    return (
      <>
        {!isEmpty(activityList) &&
          activityList.map((activity: any, index: number) => {
            const employee = find(
              activity.metaData,
              (meta: any) => meta.key === "EMPLOYEE"
            );
            const order = find(
              activity.metaData,
              (meta: any) => meta.key === "ORDER"
            );
            const name = `${employee?.value.firstName} ${employee?.value.lastName}`;
            const activityDate = moment(activity.updatedTime).format(
              "MMM DD, YYYY hh:mmA"
            );
            const activityStatus = getActivityStatus(activity.actType);
            const total = getCurrencyWithPrice(
              get(order, "value.totalPrice", 0),
              shopData.currency
            );
            const orderNumber = get(order, "value.recieptId", null);
            const isRestored = !isEmpty(
              filter(orders, (data) => data.orderId === order?.value.orderId)
            );

            return (
              <div>
                <Row style={{ height: "100%", margin: 0, minHeight: "60px" }}>
                  {renderData(name, "flex-start")}
                  {renderData(activityDate, "center", 2.5)}
                  {renderData(activityStatus, "center", 1.5)}
                  {renderData(total, "flex-end")}
                  {renderData(
                    !isEmpty(orderNumber) ? orderNumber : "",
                    "center"
                  )}
                  <Col
                    md={2}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {activity?.actType === "DELETE_ORDER" && (
                      <ActionButton
                        isRestored={isRestored}
                        onClick={() => openOrder(activity)}
                      >
                        {isRestored ? "Restored" : "Restore"}
                      </ActionButton>
                    )}
                  </Col>
                </Row>
                {activities.length - index !== 1 ? (
                  <div className="orderQueueBottomLine" />
                ) : null}
              </div>
            );
          })}
      </>
    );
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const openOrder = (activity: any) => {
    const order = find(
      activity.metaData,
      (meta: any) => meta.key === "ORDER"
    ).value;
    const isRestored = !isEmpty(
      filter(orders, (data) => data.orderId === order.orderId)
    );
    if (isRestored) {
      return swal({
        icon: "warning",
        title: "This order has already been restored",
      });
    } else {
      setActivity(activity);
      toggleModal();
    }
  };

  const restoreOrder = () => {
    const order = find(
      activity.metaData,
      (meta: any) => meta.key === "ORDER"
    ).value;
    const activityLog = find(
      activities,
      (data) => data.logId === activity.logId
    );
    checkout(order, null, false, null, true);
    restoreActivityLog(activityLog);
    toggleModal();
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight && nextToken) {
        fetchActivities(shopData.shopId, 50, nextToken);
      }
    }
  };

  return (
    <LogWrapper>
      <LogHeaderWrapper>{renderHeader()}</LogHeaderWrapper>
      <LogDetailsWrapper ref={listInnerRef} onScroll={() => onScroll()}>
        {renderTable(activityList, openOrder)}
      </LogDetailsWrapper>
      <ServiceWarningModal
        closeText={"No"}
        isOpen={isOpenModal}
        cancelBtnText={"Yes"}
        headerText={"Warning!"}
        bodText={`Are you sure you want to restore ${
          find(activity.metaData, (meta: any) => meta.key === "ORDER")?.value
            .orderId
        } order?`}
        onCancel={() => restoreOrder()}
        closeModal={() => toggleModal()}
      />
    </LogWrapper>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    nextToken: state.activityLog.get("nextToken"),
    activities: state.activityLog.get("activities"),
    orders: state.orders.get("unCompletedAppointments"),
  }),
  {
    checkout: Actions.payment.checkout,
    fetchActivities: Actions.activityLog.fetchActivities,
    restoreActivityLog: Actions.activityLog.restoreActivityLog,
  }
)(LogTable);
