import UserRepository from "../../internal/repos/UserRepository";
import MixpanelService from "../../internal/repos/MixpanelService";
import EmployeeRepository from "../../internal/repos/EmployeeRepository";
import { setValueToLocalStorage } from "../../internal/manager/CommonManager";
import { getValueFromLocalStorage } from "./../../internal/manager/CommonManager";
import PaymentRepository from "../../internal/repos/PaymentRepository";

export const ModuleEvents = {
  USER_SIGNIN: "USER_SIGNIN",
  OPEN_AUTHENTICATION_MODEL: "OPEN_AUTHENTICATION_MODEL",
  GET_MERCHANT: "GET_MERCHANT",
  MERCHANT_ONBOARD: "MERCHANT_ONBOARD",
  GET_CURRENT_USER: "GET_CURRENT_USER",
  USER_LOGOUT: "USER_LOGOUT",
  MASTER_SIGNOUT: "MASTER_SIGNOUT",
  USER_SIGNOUT: "USER_SIGNOUT",
  STOP_PROGRESS: "STOP_PROGRESS",
  AUTHENTICATE_STORED_USER: "AUTHENTICATE_STORED_USER",
  CREATE_BATCH: "CREATE_BATCH",
  SET_LOADING_PROGRESS: "SET_LOADING_PROGRESS",
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SELECT_LOGIN_EMPLOYEE: "SELECT_LOGIN_EMPLOYEE",
  AUTHENTICATE_STORED_USER_NEW: "AUTHENTICATE_STORED_USER_NEW",
  SET_LOGIN_IDS: "SET_LOGIN_IDS",
  USER_SIGNIN_AGAIN: "USER_SIGNIN_AGAIN",

  FETCH_TRIAL: "FETCH_TRIAL",
};

const createAction: any = (type, action: any = () => {}, meta) => {
  return (...args) => ({ type, payload: action(...args), meta });
};

const Actions = {
  userSignIn: createAction(
    ModuleEvents.USER_SIGNIN,
    async (username, password) => {
      const result = await UserRepository.userSignIn(username, password);
      return result;
    },
    { loading: true }
  ),
  getCurrentUser: createAction(
    ModuleEvents.GET_CURRENT_USER,
    async (shopId: string, empId: string) => {
      const result = await EmployeeRepository.getEmployee(shopId, empId);
      return result;
    },
    { loading: true }
  ),
  createBatch: createAction(
    ModuleEvents.CREATE_BATCH,
    async (empId, shopId) => {
      const result = await UserRepository.createBatch(empId, shopId);
      return result;
    }
  ),
  userSignOut: createAction(ModuleEvents.USER_SIGNOUT, async (param) => {
    return param;
  }),
  openAuthenticationModel: createAction(
    ModuleEvents.OPEN_AUTHENTICATION_MODEL,
    async (status, message) => {
      return { status, message };
    }
  ),
  getMerchant: createAction(ModuleEvents.GET_MERCHANT, async () => {
    // const list = await GraphqlService.getLocalData("EMPLOYEE_DATA");
    let merchantResult: any = await getValueFromLocalStorage("merchant");
    if (merchantResult) {
      const merchant = JSON.parse(merchantResult);
      try {
        const shop = await UserRepository.getShop(merchant.shopId);
        if (shop?.error) {
          return merchant;
        }
        await setValueToLocalStorage("merchant", JSON.stringify(shop));
        MixpanelService.setProfile(merchant);
        return shop;
      } catch (e) {
        console.log(e);
      }
    }
    return null;
  }),
  merchantOnboard: createAction(
    ModuleEvents.MERCHANT_ONBOARD,
    async (pin, token) => {
      const result = await UserRepository.merchantOnboard(pin, token);
      return result;
    },
    { loading: true }
  ),
  userLogout: createAction(ModuleEvents.USER_LOGOUT, async () => {
    const result = await UserRepository.userLogout();
    return result;
  }),
  masterSignOut: createAction(ModuleEvents.MASTER_SIGNOUT, async () => {
    try {
      await setValueToLocalStorage("merchant", null);
      await setValueToLocalStorage("SELECTED_EMPLOYEE", null);
      return {};
    } catch (e) {
      console.log(e);
    }
  }),
  stopProgress: createAction(ModuleEvents.STOP_PROGRESS, async (status) => {
    return status;
  }),
  authenticateStoredUser: createAction(
    ModuleEvents.AUTHENTICATE_STORED_USER,
    async () => {
      const user = await UserRepository.getStoredUser();
      return user;
    }
  ),
  authenticateStoredNewUser: createAction(
    ModuleEvents.AUTHENTICATE_STORED_USER_NEW,
    async () => {
      const user = await UserRepository.getStoredUser();
      return user;
    }
  ),
  setLoadingProgress: createAction(
    ModuleEvents.SET_LOADING_PROGRESS,
    async (count) => {
      return count;
    }
  ),
  setAccessToken: createAction(
    ModuleEvents.SET_ACCESS_TOKEN,
    async (token) => {
      await UserRepository.storeUserToken(token);
      return token;
    },
    { loading: true }
  ),
  selectLoginEmployee: createAction(
    ModuleEvents.SELECT_LOGIN_EMPLOYEE,
    async (data) => {
      await setValueToLocalStorage("SELECTED_EMPLOYEE", JSON.stringify(data));
      MixpanelService.setEmployee(data);
      return data;
    }
  ),
  setLoginIds: createAction(
    ModuleEvents.SET_LOGIN_IDS,
    async (param) => {
      return param;
    },
    { loading: true }
  ),
  userSignInAgain: createAction(
    ModuleEvents.USER_SIGNIN_AGAIN,
    async (username, password, tries = 0) => {
      const result = await UserRepository.userSignIn(username, password);
      return { result, username, password, tries };
    },
    { loading: true }
  ),

  fetchTrial: createAction(
    ModuleEvents.FETCH_TRIAL,
    async (shopId) => {
      const result = await PaymentRepository.fetchTrial(shopId);
      return result;
    },
    { loading: true }
  ),
};

export default Actions;
