import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TableAreas, TableWrapper } from "./Styled";
import { Actions } from "../../../internal/app/Actions";
import TableOrderModal from "../../../components/model/views/TableOrderModal";
import _, { isArray, isEmpty, slice } from "lodash";

const ShopOrderTables = (props) => {
  const {
    tables,
    tableAreas,
    selectTable,
    incompleteOrders,
    createTableOrder,
    selectAppointment,
    selectedAppointment,
  } = props;
  const [selectedArea, setSelectedArea] = useState<any>(null);
  const [selectedTable, setSelectedTable] = useState<any>(null);
  const [areaTables, setAreaTables] = useState<Array<any>>([]);

  const [openOrderList, setOpenOrderList] = useState<boolean>(false);

  useEffect(() => {
    // if (selectedAppointment.tableOrder) {
    const orderTbId = selectedAppointment.tableOrder?.tbId;
    let orderArea: any = tableAreas.tableAreas[0];

    if (orderTbId) {
      tableAreas.tableAreas.forEach((ta) => {
        const tb = ta.tables.find(({ tbId }) => tbId === orderTbId);
        if (tb) {
          orderArea = ta;
          // setSelectedArea(ta);
          setSelectedTable(tb);
        }
      });
    }
    const orderRemovedTables = mapTables(orderArea);
    setSelectedArea(orderArea);
    setAreaTables([...orderRemovedTables]);

    // setSelectedArea(tableAreas.tableAreas[0]);
    // } else if (tableAreas.tableAreas) {
    // setAreaTables(tableAreas.tableAreas[0].tables || []);
    // }
  }, [tableAreas, selectedAppointment, incompleteOrders]);

  const mapTables = (tableArea: any) => {
    const tables = tableArea.tables || [];
    const orderRemovedTables = tables
      .map(({ order, ...tb }) => tb)
      .filter(({ tbType }) => tbType === "ACTIVE");
    if (isArray(tables) && isArray(incompleteOrders)) {
      incompleteOrders.forEach((order) => {
        if (order.tableOrder) {
          const oTbId = order.tableOrder.tbId;
          const index = orderRemovedTables.findIndex(
            ({ tbId }) => tbId === oTbId
          );
          if (index > -1) {
            orderRemovedTables[index] = {
              ...orderRemovedTables[index],
              order,
            };
          }
        }
      });
    }
    return orderRemovedTables;

    // let orderTables = cloneDeep(tables);
    // if (isArray(tables) && isArray(incompleteOrders)) {
    //   incompleteOrders.forEach((order) => {
    //     if (order.tableOrder) {
    //       const orderTbId = order.tableOrder.tbId;
    //       const index = orderTables.findIndex(({ tbId }) => tbId === orderTbId);
    //       if (index > -1) {
    //         orderTables[index]["orders"] = compact(
    //           concat(orderTables[index]?.orders, [order])
    //         );
    //       }
    //     }
    //   });
    // }
    // return orderTables;
  };

  const onSelectArea = (ta) => {
    setSelectedArea(ta);
    setAreaTables(mapTables(ta));
  };

  const getClassName = (
    table: any,
    selectedTable: any,
    selectedAppointment: any
  ) => {
    if (table.tbId === selectedTable?.tbId) {
      return "selected";
    } else if (
      table.order &&
      selectedAppointment.orderId !== table.order.orderId
    ) {
      return "disabled";
    }
    return "";
  };

  const onSelectTable = (table) => {
    if (selectedTable && selectedTable.tbId === table.tbId) {
      setSelectedTable(null);
      selectTable(null);
    } else {
      setSelectedTable(table);
      selectTable(table);
    }
  };

  const toggleMultipleModal = () => {
    setOpenOrderList(!openOrderList);
  };

  const onClickTable = (table: any) => {
    setSelectedTable(table);
    if (!isEmpty(table.orders)) {
      toggleMultipleModal();
    } else {
    }
  };

  const addNewOrder = () => {
    createTableOrder(selectedTable);
  };

  const onSelectedOrder = (order: any) => {
    selectAppointment(order);
  };

  return (
    <div>
      <TableAreas>
        {tableAreas.tableAreas.map((ta) => (
          <div
            key={ta.areaId}
            className={
              selectedArea?.areaId === ta.areaId ? "selected" : "unselected"
            }
            onClick={() => onSelectArea(ta)}
          >
            {ta.areaName}
          </div>
        ))}
      </TableAreas>
      <TableWrapper>
        {areaTables?.map((table: any) => {
          return (
            <button
              disabled={
                table.tbId !== selectedTable?.tbId &&
                table.order &&
                selectedAppointment.orderId !== table.order.orderId
              }
              onClick={() => onSelectTable(table)}
              // onClick={() => onClickTable(table)}
              className={getClassName(
                table,
                selectedTable,
                selectedAppointment
              )}
            >
              {table.tbNumber}
              {!isEmpty(table.orders) &&
                slice(table.orders, 0, 1).map((order: any) => {
                  return (
                    <div className={"table-order-id"}>{order.recieptId}</div>
                  );
                })}
              {!isEmpty(table.orders) && table.orders.length > 2 && (
                <div className={"table-order-id"}>{"......"}</div>
              )}
            </button>
          );
        })}
      </TableWrapper>
      {selectedTable && (
        <TableOrderModal
          data={selectedTable}
          isOpen={openOrderList}
          addNewOrder={() => addNewOrder()}
          closeModal={() => toggleMultipleModal()}
          onSelectedOrder={(order: any) => onSelectedOrder(order)}
        />
      )}
    </div>
  );
};
export default connect(
  (state: any) => ({
    tables: state.table.get("tables"),
    shopData: state.login.get("merchantShop"),
    tableAreas: state.table.get("tableAreas"),
    selectedAppointment: state.orders.get("selectedAppointment"),
    incompleteOrders: state.orders.get("unCompletedAppointments"),
  }),
  {
    selectTable: Actions.table.selectTable,
    createTableOrder: Actions.table.createTableOrder,
    selectAppointment: Actions.orders.selectedAppointment,
    removeAppointmentDiscount: Actions.product.removeAppointmentDiscount,
  }
)(ShopOrderTables);
