import { createAction } from '../../internal/app/AppUtils';
import UserRepository from '../../internal/repos/UserRepository';

export const ModuleEvents = {
  RESET_PASSWORD: 'RESET_PASSWORD',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_EMPLOYEE: 'UPDATE_EMPLOYEE',

  UPDATE_SHOP: 'UPDATE_SHOP',
};

const Actions = {
  resetPassword: createAction(ModuleEvents.RESET_PASSWORD, async (param) => {
    const result = await UserRepository.resetPassword(param);
    return result;
  }),
  updateUser: createAction(ModuleEvents.UPDATE_USER, async (param) => {
    const result = await UserRepository.updateUser(param);
    return result;
  }),
  updateEmployee: createAction(ModuleEvents.UPDATE_EMPLOYEE, async (param) => {
    const result = await UserRepository.updateEmployee(param);
    return result;
  }),
  updateShop: createAction(ModuleEvents.UPDATE_SHOP, async (param) => {
    const result = await UserRepository.updateShop(param);
    return result;
  }),
};

export default Actions;