import styled from "styled-components";
import BaseColors from "../../../internal/helpers/BaseColors";

export const CloseCashContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${BaseColors.aquaHaze};
`;

export const CloseCashBottomContainer = styled.div`
  // width: 100%;
  // height: calc(100vh - 61px);
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.16);

  & > div {
    // overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: ${BaseColors.white};
  }
`;

export const CloseCashHeaderFilter = styled.div<{ isClicked: boolean }>`
  height: 51px;
  background-color: ${BaseColors.alabaster};
  align-items: center;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  .closeCashFilterHeaderText {
    color: ${BaseColors.gunsmoke};
    font-size: 14px;
    font-family: Montserrat;
    font-weight: normal;
    font-style: normal;
  }

  .closeCashFilterHeaderIconWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 150px;
  }
`;

export const CloseCashHeaderFilterImage = styled.img`
    padding: 10px 10px;

    :hover {
            cursor: pointer;
    }

    :focus {
      background-color: #49A4D4;
    }

    &>p {
      width: 100%;
      font-family: 'Montserrat';
      font-weight: normal;
      font-style: normal;
      color: #262626;
      font-size: 14px;
      padding-left: 16px;
    }

    &>div {
        width: 100%;
        height: 0.5px;
        align-items: center;
        background-color: #F5F5F5;
        margin-top: 5px;
        border: none;
    }

  }
`;

export const CloseCashButton = styled.div<{ valid: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  & > div {
    width: 100%;
    background-color: ${(props) => (props.valid ? "#49a4d4" : "#f5f5f5")};
    border: ${(props) =>
      props.valid ? "1px solid #ffffff" : "1px solid #cccccc"};
    color: ${(props) => (props.valid ? "#ffffff" : "#cccccc")};
    max-width: 289px;
    height: 59px;
    text-align: center;
    font-size: 16px;
    border-radius: 3px;
    transition-duration: 0.4s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat";
    font-weight: 600;

    :focus {
      outline: none;
    }
  }
`;

export const CloseCashReceiptButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  & > div {
    width: 100%;
    background-color: #f5f5f5;
    border: 1px solid #4a4a4a;
    color: #4a4a4a;
    max-width: 289px;
    height: 59px;
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
    transition-duration: 0.4s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat";
    font-weight: 600;

    :focus {
      outline: none;
    }
  }
`;

export const SummaryContainer = styled.div<{ valid: boolean; height: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div:first-child {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > div {
      // width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-right: 10px;

      .salonName {
        color: #000000;
        font-size: 16px;
        margin-top: 25px;
        text-align: center;
        font-family: "Montserrat";
        font-weight: 600;
      }

      .address {
        color: #000000;
        font-size: 14px;
        margin-top: 10px;
        text-align: center;
        font-family: Montserrat;
      }

      .line {
        height: 1px;
        width: 100%;
        margin-top: 10px;
        background-color: rgba(0, 0, 0, 0.25);
      }
    }

    .dateWrapper {
      padding-left: 14px;
      padding-right: 14px;

      .dateWrapperInfo {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .dateText {
          color: #000000;
          font-size: 12px;
          font-family: Montserrat;
        }
      }
    }

    .salesSummaryWrapper {
      height: calc(100vh - 362px);
      display: flex;
      overflow-y: auto;
      overflow-x: hidden;
      padding-left: 14px;
      padding-right: 14px;
      margin-bottom: 8px;
      margin-top: 8px;

      .salesSummaryText {
        margin-top: 40px;
        color: #000000;
        font-size: 17px;
        font-family: "Montserrat";
        font-weight: 600;
      }

      .salesSummaryWrapperInfo {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & > div:first-child {
          color: #000000;
          font-size: 14px;
          font-family: Montserrat;
        }

        & > div:last-child {
          color: #000000;
          font-size: 14px;
          font-family: "Montserrat";
          font-weight: 600;
        }
      }

      .salesSummaryPaymentCashWrapper {
        margin-top: 24px;

        .salesSummaryWrapperInfo {
          margin-top: 12px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .serviceText {
            color: #000000;
            font-size: 14px;
            font-family: Montserrat;
          }

          .salesText {
            color: #000000;
            font-size: 14px;
            font-family: "Montserrat";
            font-weight: 600;
          }
        }
      }
    }
  }

  .closeCashButtonWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .closeCashButton {
      height: "100%";
      background-color: ${(props) => (props.valid ? "#49a4d4" : "#f5f5f5")};
      border: ${(props) =>
        props.valid ? "1px solid #ffffff" : "1px solid #cccccc"};
      color: ${(props) => (props.valid ? "#ffffff" : "#cccccc")};
      padding: 16px 14px;
      max-width: 289px;
      height: 67px;
      text-align: center;
      font-size: 16px;
      border-radius: 3px;
      transition-duration: 0.4s;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: "Montserrat";
      font-weight: 600;

      :focus {
        outline: none;
      }
    }

    .closeCashReceiptButton {
      height: "100%";
      background-color: #f5f5f5;
      border: 1px solid #4a4a4a;
      color: #4a4a4a;
      padding: 16px 14px;
      max-width: 289px;
      height: 67px;
      text-align: center;
      font-size: 16px;
      border-radius: 4px;
      transition-duration: 0.4s;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: "Montserrat";
      font-weight: 600;
      :focus {
        outline: none;
      }
    }
  }
`;

export const ServiceContainer = styled.div`
  height: 41px;
  display: flex;
  margin-top: 37px;
  // margin-right: 32px;
  padding-left: 15px;
  align-items: center;
  align-items: center;
  flex-direction: row;
  padding-right: 15px;
  background-color: #fafafa;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  justify-content: space-between;

  & > div {
    color: #88938f;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: normal;
    font-style: normal;

    :hover {
      cursor: pointer;
    }
  }
`;

export const ServiceFilterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 45px;
  position: absolute;
  z-index: 100;

  & > p {
    width: 100px;
  }

  & > div {
    width: 196px;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);

    :hover {
      cursor: pointer;
    }

    :focus {
      background-color: #49a4d4;
    }

    & > p {
      width: 100%;
      font-family: "Montserrat";
      font-weight: normal;
      font-style: normal;
      color: #262626;
      font-size: 14px;
      padding-left: 16px;
    }

    & > div {
      width: 100%;
      height: 0.5px;
      align-items: center;
      background-color: #f5f5f5;
      margin-top: 5px;
      border: none;
    }
  }
`;

export const ReportTableContainer = styled.div`
  height: 41px;
  display: flex;
  margin-top: 10px;
  margin-right: 32px;
  padding-left: 15px;
  align-items: center;
  align-items: center;
  flex-direction: row;
  padding-right: 15px;
  background-color: #fafafa;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  justify-content: space-between;
`;

export const ReportTableText = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  color: #88938f;
  font-size: 12px;
`;

export const ReportTableValueContainer = styled.div<{ isSelected: boolean }>`
  min-height: 60px;
  background-color: ${(props) => (props.isSelected ? "#fafafa" : "#FFFFFF")};
  display: grid;
  align-items: center;
  padding-top: 5px;
  display: flex;
  margin-right: 32px;
  padding-left: 15px;
  align-items: center;
  align-items: center;
  flex-direction: row;
  padding-right: 15px;
  justify-content: space-between;

  :hover {
    cursor: pointer;
  }
`;

export const ReportTableLineContainer = styled.div`
  height: 1px;
  margin-right: 32px;
  background-color: rgba(32, 37, 58, 0.25);
`;
