import AppointmentRepository from '../../internal/repos/AppointmentRepository';
import PaymentResult from "../../internal/repos/PaymentRepository";

export const ModuleEvents = {
  FETCH_SHOP_ORDERS: 'FETCH_SHOP_ORDERS',
  FETCH_ALL_APPOINTMENTS: 'FETCH_ALL_APPOINTMENTS',
  CREATE_APPOINTMENT: 'CREATE_APPOINTMENT',
  FETCH_PAGINATE_APPOINTMENTS: 'FETCH_PAGINATE_APPOINTMENTS',
  SELECTED_APPOINTMENT: 'SELECTED_APPOINTMENT'
};

const createAction = (type, action = () => {}, meta) => {
  return (...args) => ({ type, payload: action(...args), meta });
};

export default {
  fetchSalonAppointment: createAction(ModuleEvents.FETCH_SHOP_ORDERS, async (shopId, limit = 50, lastKey = null) => {
    const result = await AppointmentRepository.fetchSalonAppointment({shopId, limit, lastKey});
    return result;
  },{loading: true}),
  fetchAllAppointments: createAction(ModuleEvents.FETCH_ALL_APPOINTMENTS, async () => {
    const result = await PaymentResult.fetchAllAppointments();
    return result;
  }, {loading: true}),
  createAppointment: createAction(ModuleEvents.CREATE_APPOINTMENT, async (data) => {
    const appointmentResult = await PaymentResult.createAppointment(data);
    return appointmentResult;
  }),
  fetchPaginateAppointment: createAction(ModuleEvents.FETCH_PAGINATE_APPOINTMENTS, async (shopId, limit = 50, lastKey = null) => {
    const result = await AppointmentRepository.fetchSalonAppointment({shopId, limit, lastKey});
    return result;
  },{loading: true}),
};
