import React, {useEffect, useState} from 'react';
import styles from '../../../components/Common/Style/Styles.css';
import {Row, Col} from 'react-grid-system';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import ServiceCustomizedSideNav from '../../../components/sideNavBars/views/ServiceCustomizedSideNav';
import AllServicesView from '../../service/views/AllServicesView';
import StockSubHeader from '../../../components/headers/Views/StockSubHeader';
import {connect} from "react-redux/es/index";
import CategoryTableView from '../../service/views/CategoryTableView';
import {Actions} from "../../../internal/app/Actions";
import StockCustomizedSideNav from "../../../components/sideNavBars/views/StockCustomizedSideNav";
import StockTableView from '../../product/views/StockTableView';
import StockRecordTableView from '../../stock/views/StockRecordTableView';

const StockCustomizedView = (props) => {
  const { selectStockSideBarView, selectedStockSideBarView, clearSelectedServiceCategory } = props;
  const [selectedSidebar, setSelectedSidebar] = useState(selectedStockSideBarView);

  useEffect(() => {
    setSelectedSidebar(selectedStockSideBarView);
  }, [selectedStockSideBarView]);

  const onClickSideBar = (type) => {
    selectStockSideBarView(type);
  };

  const renderSidebarValue = () => {
    switch (selectedSidebar) {
      case 'STOCK_ITEMS':
        return (
          <div className={styles.categoryTableWrapper}>
            <StockTableView />
          </div>
        );
      case 'STOCK_RECORDS':
        return (
          <div className={styles.allServiceWrapper}>
            <StockRecordTableView/>
          </div>
        );

      default: {
        return (
          <div />
        )
      }
    }
  };

  const path = selectedSidebar === "STOCK_ITEMS" ? "/add-stock" : "/add-stock-record";
  return (
    <div className={styles.serviceCustomizedContainer}>
      <TopNavigationBar viewName="Stocks" path="/menu"/>
      <div className={styles.settingsWrapper}>
        <div className={styles.serviceCustomizedWrapper}>
          <Row>
            <Col xs={12}>
              <StockSubHeader header={""} selectedSidebar={selectedSidebar} onClear={()=> clearSelectedServiceCategory(path)}/>
            </Col>
          </Row>
          <Row>
            <Col xs={2} style={{paddingRight: 0}}>
              <StockCustomizedSideNav selectedSidebar={selectedSidebar} onClick={(type) => onClickSideBar(type)}/>
            </Col>
            <Col xs={10} style={{paddingLeft: 0}}>
              {
                renderSidebarValue()
              }
            </Col>
          </Row>

        </div>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    selectedStockSideBarView: state.product.get('selectedStockSideBarView')
  }),
  {
    selectStockSideBarView: Actions.product.selectStockSideBarView,
    clearSelectedServiceCategory: Actions.service.clearSelectedServiceCategory
  }
)(StockCustomizedView);

