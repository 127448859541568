import { appHistory } from '../app/Store';

export const navigate = (path) => {
  appHistory.push(path);
  return path;
};

export const goBack = () => {
  appHistory.back();
};

