import React, {useEffect, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import {connect} from "react-redux";
import {Actions} from "../../../internal/app/Actions";
import _ from 'lodash';
import {CustomerDetailsWrapper, SelectCustomerModalContainer} from '../../Styles/Styled';
import Customer from '../../../components/customer/views/Customer';
import { useIntl } from 'react-intl';

const customStyles = {
  content: {
    top: '10vh',
    left: 'calc(50% - 264px)',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },
};

const SelectCustomerModal = (props) => {
  const {isOpen, closeModal, selectedCustomer, onSelectedCustomer} = props;
  const [clickedCustomer, setClickedCustomer] = useState(null);
  const {formatMessage: f} = useIntl();

  useEffect(() => {
    setClickedCustomer(selectedCustomer);
  }, [selectedCustomer]);

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.selectCustomerModal}
    >
      <SelectCustomerModalContainer>
        <div>
          <div>{f({id: "ORDER.CUSTOMER.SELECT_CUSTOMER"})}</div>
          <img onClick={() => closeModal()} src={CANCEL}/>
        </div>
        <CustomerDetailsWrapper>
          <Customer isModal />
        </CustomerDetailsWrapper>
        <div>
          <button onClick={() => onSelectedCustomer(clickedCustomer)}>
            <div>{f({id: "ORDER.CUSTOMER.ADD_CUSTOMER"})}</div>
          </button>
          <button onClick={() => closeModal()}>
            <div>{f({id: "ORDER.CUSTOMER.CANCEL"})}</div>
          </button>
        </div>
      </SelectCustomerModalContainer>
    </ReactModal>
  );
};
export default connect(
  state => ({
    shopData: state.login.get('merchantShop'),
    currentUser: state.login.get('currentUser'),
    employees: state.employee.get('employees'),
    selectedEmployee: state.employee.get('selectedEmployee'),
    customers: state.customer.get('customers'),
    selectedCustomer: state.customer.get('selectedCustomer'),
  }),
  ({})
)(SelectCustomerModal);
