import { ModuleEvents } from './Actions';
import { Map} from 'immutable';
import {ModuleEvents as PaymentModule} from "../payment/Actions";
import _ from 'lodash';

const initialState = Map({
  selectedView: "Service",
  customers: [],
  customersLastKey: {},
  selectedCustomer: {},
  quickCustomer: {},
});

export const Reducer = (state = initialState, action) => {
  const {payload, type, error} = action;

  switch (type) {
    case ModuleEvents.CREATE_CUSTOMER: {
      if (payload && !payload.error  && payload.code !== 'CLIENT_ALREADY_CREATED') {
        const customers = state.get('customers');
        const customer = _.get(payload, 'data', {});
        customers.push(customer);
        let sortResult = _.orderBy(customers, ['firstName'], ['asc'],);
        return state.set('customers', sortResult)
      }
      return state;
    }

    case ModuleEvents.UPDATE_CUSTOMER: {
      if (payload && !payload.error) {
        const customers = state.get('customers');
        const index = _.findIndex(customers, (data) => data.id === payload.id);
        customers[index] = payload;
        let sortResult = _.orderBy(customers, ['firstName'], ['asc'],);
        return state.set('customers', [...sortResult])
          .set('selectedCustomer', payload);
      }
      return state;
    }

    case ModuleEvents.DELETE_CUSTOMER: {
      if (payload && !payload.error) {
        const customers = state.get('customers');
        const filterCustomers = _.filter(customers, (data) => data.id !== payload.id);
        const sortResult = _.orderBy(filterCustomers, ['firstName'], ['asc'],);
        return state.set('customers', [...sortResult])
          .set('selectedCustomer', {});
      }
      return state;
    }

    case ModuleEvents.FETCH_CUSTOMERS: {
      if (payload && !payload.error) {
        const customersLastKey = _.get(payload, 'lastKey', {});
        const customers = _.sortBy(_.get(payload, 'Items', []), 'name');
        return state.set('customers', customers)
          .set('customersLastKey', customersLastKey);
      }
      return state;
    }

    case ModuleEvents.SEARCH_CUSTOMERS: {
      if (payload && !payload.error) {
        const filterCustomers = _.filter(payload, customer => customer.type !== 'STORE_CUSTOMER');
        return state.set('customers', filterCustomers)
      }
      return state;
    }

    case ModuleEvents.ON_SELECT_CUSTOMER: {
      if (payload) {
        return state.set('selectedCustomer', payload);
      }
      return state;
    }

    case PaymentModule.CLEAR_CURRENT_APPOINTMENT: {
      return state.set('selectedCustomer', {})
        .set('quickCustomer', {});
    }

    case ModuleEvents.SELECT_CUSTOMER: {
      if (payload) {
        return state.set('selectedCustomer', payload);
      }
      return state;
    }

    case ModuleEvents.CREATE_QUICK_CUSTOMER: {
      if (payload && !payload.error) {
        return state.set('quickCustomer', payload);
      }
      return state;
    }

    case ModuleEvents.REMOVE_QUICK_CUSTOMER: {
      if (payload && !payload.error) {
        return state.set('quickCustomer', {});
      }
      return state;
    }

    case ModuleEvents.REMOVE_SELECTED_CUSTOMER: {
      if (payload) {
        return state.set('selectedCustomer', {});
      }
      return state;
    }

    default:
      return state;
  }
};

export default Reducer;
