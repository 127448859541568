import { get, isEmpty } from "lodash";
import { split, HttpLink, ApolloClient, InMemoryCache } from "@apollo/client";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import GraphqlService from "../../internal/service/Graphql.service";

export const getClient = (ipAddress: string) => {
  const httpLink = new HttpLink({
    uri: `http://${ipAddress}:4000/graphql`,
    fetchOptions: {
      mode: "cors",
    },
  });
  const wsClient = createClient({
    url: `ws://${ipAddress}:4000/subscriptions`,
  });

  const wsLink = new GraphQLWsLink(wsClient);

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
      addTypename: false
    })
  });
  GraphqlService.init(client);
  return client;
};
