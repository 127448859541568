import React, { useState, useEffect } from "react";
import _, { get, filter, isEmpty } from "lodash";
import { nanoid } from "nanoid";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { MenuContainer } from "./styled";
import {
  mapPaymentDetails,
  getAppointmentTotalPrice,
} from "../../../internal/manager/PaymentManager";
import { Actions } from "../../../internal/app/Actions";
import IconCancel from "../../../../assets/svg/IconCancel.svg";
import IconLogout from "../../../../assets/svg/IconLogout.svg";
import OrderSaveModal from "../../../components/model/views/OrderSaveModal";
import { goBack, navigate } from "../../../internal/service/Navigation.service";
import { getHiPriorityUserRole } from "../../../internal/manager/EmployeeManager";
import backToAppointmentIcon from "../../../../assets/icons/backToAppointmentIcon.svg";
import { checkIsSaveCurrentOrder } from "../../../internal/manager/AppointmentManager";
const { innerHeight } = window;

const navigationList = [
  { title: "Order Queue", screen: "/order", id: "MENU.ORDER_QUEUE" },
  { title: "Close Cash", screen: "/closeCash", id: "MENU.CLOSE_CASH" },
  { title: "Transactions", screen: "/transactions", id: "MENU.TRANSACTION" },
  { title: "Products", screen: "/products-customized", id: "MENU.PRODUCTS" },
  { title: "Services", screen: "/services-customized", id: "MENU.SERVICES" },
  { title: "Rooms", screen: "/rooms", id: "MENU.ROOMS" },
  { title: "Stock", screen: "/stock-customized", id: "MENU.STOCK" },
  { title: "Settings", screen: "/settings", id: "MENU.SETTINGS" },
  { title: "Reports", screen: "/reports", id: "MENU.REPORTS" },
  { title: "Activity Log", screen: "/activity-log", id: "MENU.ACTIVITY_LOG" },
  // {title: 'Schedule', screen: '/appointmentCalendar', id: "MENU.SCHEDULE"},
  // {title: 'Calendar View', screen: '/appointmentCalendar', id: "MENU.CALENDAR_VIEW"},
];

const Menu = (props: any) => {
  const {
    shopData,
    checkout,
    userLogout,
    currentUser,
    orderOffers,
    batchSession,
    selectedTable,
    quickCustomer,
    openSaveModal,
    selectedVehicle,
    deleteShopOrder,
    purchaseServices,
    selectedCustomer,
    purchaseProducts,
    selectedEmployee,
    createActivityLog,
    selectMainMenuView,
    selectedAppointment,
    appointmentDiscounts,
    appointmentDateAndTime,
    clearCurrentAppointment,
  } = props;

  const { formatMessage: f } = useIntl();
  const [selectedView, setSelectedView] = useState(null);
  let [list, setList] = useState<Array<any>>(navigationList);

  useEffect(() => {
    const isHiddenRoom =
      get(shopData, "shopCategory", "") === "FOOD_AND_BEVERAGES";
    const userRole = getHiPriorityUserRole(currentUser.specializations);
    if (userRole === "Waiter") {
      list = filter(
        list,
        (data: any) => data.title != "Close Cash" && data.title != "Reports"
      );
    }
    if (isHiddenRoom) {
      list = filter(list, (data: any) => data.title != "Rooms");
    }
    setList([...list]);
  }, [currentUser, shopData]);

  const createAppointment = () => {
    const gridMode = _.get(currentUser, "viewMode", "GRID") === "GRID";
    navigate(gridMode ? "/product" : "/order-list");
    // clearCurrentAppointment(true, {
    //   navigate: gridMode ? "/product" : "/order-list",
    // });
  };

  let purchaseList = purchaseServices.concat(purchaseProducts);
  let { total: totalPrice, serviceCharge }: any = getAppointmentTotalPrice(
    purchaseList,
    appointmentDiscounts,
    shopData,
    orderOffers
  );

  const saveOrder = async (price, serviceChargeData, selectedPath) => {
    let serviceChargeObj: any = null;
    if (serviceChargeData?.length > 0 && shopData.systemSettings) {
      serviceChargeObj = {
        amount: `${serviceChargeData}`,
        // amountType: shopData.systemSettings[]
      };
    }

    const params = {
      shopData,
      selectedVehicle,
      purchaseServices,
      selectedEmployee,
      purchaseProducts,
      isSendSms: false,
      status: "PENDING",
      totalPrice: price,
      selectedAppointment,
      stopNavigation: false,
      offerDetails: orderOffers,
      userId: currentUser.empId,
      batchId: batchSession.batchId,
      serviceCharge: serviceChargeObj,
      discounts: appointmentDiscounts,
      date: _.get(appointmentDateAndTime, "date"),
      time: _.get(appointmentDateAndTime, "time"),
      recieptId: selectedAppointment.recieptId || nanoid(5),
      tableOrder: selectedTable?.tbId
        ? {
            tbId: selectedTable.tbId,
            areaId: null,
            tbNumber: selectedTable.tbNumber,
          }
        : null,
      selectedCustomer: !_.isEmpty(selectedCustomer)
        ? selectedCustomer
        : quickCustomer,
    };
    openSaveModal(false);

    const orderData = await mapPaymentDetails(params);
    const activityLog = {
      logId: nanoid(),
      shopId: shopData.shopId,
      createdTime: Date.now(),
      updatedTime: Date.now(),
      actType: "CHECKOUT",
      empId: selectedEmployee.empId,
      metaData: [
        {
          key: "ORDER",
          value: orderData,
        },
        {
          key: "EMPLOYEE",
          value: selectedEmployee,
        },
      ],
    };
    createActivityLog(activityLog);
    checkout(params, null, true, "ORDER_QUEUE");
    if (selectedPath) {
      navigate(selectedPath);
    }
    clearCurrentAppointment();
    setSelectedView(null);
  };

  const onDeleteOrder = () => {
    if (!_.isEmpty(selectedAppointment)) {
      const activityLog = {
        logId: nanoid(),
        shopId: shopData.shopId,
        createdTime: Date.now(),
        updatedTime: Date.now(),
        actType: "DELETE_ORDER",
        empId: selectedEmployee.empId,
        metaData: [
          {
            key: "ORDER",
            value: selectedAppointment,
          },
          {
            key: "EMPLOYEE",
            value: selectedEmployee,
          },
        ],
      };
      deleteShopOrder({
        shopId: selectedAppointment.shopId,
        orderId: selectedAppointment.orderId,
      });
      createActivityLog(activityLog);
    }
    if (selectedView) {
      navigate(selectedView);
    }
    openSaveModal(false);
    setSelectedView(null);
  };
  const isSaveOrder = checkIsSaveCurrentOrder(
    purchaseProducts,
    purchaseServices,
    selectedVehicle,
    selectedCustomer,
    selectedTable
  );

  return (
    <MenuContainer height={`${innerHeight}px`}>
      <div onClick={() => createAppointment()} className="backToTextWrapper">
        <img src={backToAppointmentIcon} />
        <div>{f({ id: "MENU.BACK" })}</div>
      </div>

      <div className="navigation-wrapper">
        <div className="navigation-item-wrapper">
          {list.map((item: any) => {
            return (
              <div
                onClick={() => {
                  if (isSaveOrder) {
                    openSaveModal(true);
                    setSelectedView(item.screen);
                  } else {
                    navigate(item.screen);
                  }
                  selectMainMenuView(item.id);
                }}
              >
                {f({ id: item.id })}
              </div>
            );
          })}
        </div>
        <div className="sign-out-wrapper">
          <div />
          <img onClick={() => goBack()} src={IconCancel} />
          <div
            onClick={() => {
              if (isSaveOrder) {
                openSaveModal(true);
                setSelectedView(null);
              } else {
                userLogout();
              }
            }}
          >
            <img src={IconLogout} />
            <div>{f({ id: "MENU.SIGN_OUT" })}</div>
          </div>
        </div>
      </div>
      <OrderSaveModal
        onSave={() => saveOrder(totalPrice, serviceCharge, selectedView)}
        onDeleteOrder={() => onDeleteOrder()}
        onNavigate={() => {
          if (selectedView) {
            navigate(selectedView);
          }
          setSelectedView(null);
        }}
      />
    </MenuContainer>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    orderOffers: state.offer.get("orderOffers"),
    currentUser: state.login.get("currentUser"),
    batchSession: state.login.get("batchSession"),
    selectedTable: state.table.get("selectedTable"),
    quickCustomer: state.customer.get("quickCustomer"),
    selectedVehicle: state.orders.get("selectedVehicle"),
    purchaseProducts: state.product.get("purchaseProducts"),
    purchaseServices: state.service.get("purchaseServices"),
    selectedCustomer: state.customer.get("selectedCustomer"),
    selectedEmployee: state.employee.get("selectedEmployee"),
    selectedAppointment: state.orders.get("selectedAppointment"),
    appointmentDiscounts: state.product.get("appointmentDiscounts"),
    appointmentDateAndTime: state.salon.get("appointmentDateAndTime"),
  }),
  {
    checkout: Actions.payment.checkout,
    userLogout: Actions.login.userLogout,
    openSaveModal: Actions.orders.openSaveModal,
    deleteShopOrder: Actions.payment.deleteShopOrder,
    createActivityLog: Actions.activityLog.createActivityLog,
    selectMainMenuView: Actions.common.selectMainMenuView,
    clearCurrentAppointment: Actions.payment.clearCurrentAppointment,
  }
)(Menu);
