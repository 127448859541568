import React from 'react';
import {ModuleEvents} from './Actions';
import {navigate} from "../../internal/service/Navigation.service";
import swal from 'sweetalert';
import {Actions} from "../../internal/app/Actions";

export default {
  [ModuleEvents.UPDATE_SERVICE_CATEGORY]: ({dispatch, payload, appState}) => {
    const merchantShop = appState.login.get('merchantShop');
    if(payload.result && !payload.result.error) {
      dispatch(Actions.service.fetchCategories(merchantShop.shopId));
      if(payload.type !== 'ADD_QUICK_SERVICE' && payload.type !== 'ADD_QUICK_ITEM'){
        navigate('/services-customized');
      }
    } else {
      return warning("Service updated failed");
    }
  },
  [ModuleEvents.NAVIGATE_EDIT_SERVICE_VIEW]: ({dispatch, payload, appState}) => {
    navigate('/add-service');
  },
  [ModuleEvents.NAVIGATE_EDIT_CATEGORY_VIEW]: ({dispatch, payload, appState}) => {
    navigate('/add-category');
  },
  [ModuleEvents.CLEAR_SELECTED_SERVICE_CATEGORY]: ({dispatch, payload, appState}) => {
    navigate(payload);
  },
}

const warning = (alert) => {
  return swal({
    title: alert,
    icon: "warning",
    buttons: true,
    dangerMode: true
  });
};
