import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Row, Col } from "react-grid-system";
import EditIcon from "../../../../assets/svg/categoryEditIcon.svg";
import { Actions } from "../../../internal/app/Actions";
import "../Styles.style.css";

const ProductTypesTableView = (props) => {
  const {
    navigateToEditProductType,
    // selectProductBrand,
    fetchProductTypes,
    shop,
    productTypes,
  } = props;

  const navigateEditProductTypeScreen = (productType) => {
    navigateToEditProductType(productType)
  };

  useEffect(() => {
    if (shop.shopId) {
      fetchProductTypes(shop.shopId);
    }
  }, [shop.shopId]);

  return (
    <div className="categoryTableContainer">
      <div className="tableHeaderContainer">
        <Row>
          <Col xs={3} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">Product Type</div>
          </Col>
          <Col xs={3} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">ID</div>
          </Col>         
          <Col xs={4} className="categoryTableHeaderTextContainer">
            <div className="categoryTableHeaderText">Description</div>
          </Col>
          <Col xs={2} />
        </Row>
      </div>
      <div className="categoryTableBodyContainer">
        {productTypes.map((prodType) => {
          return (
            <div className="categoryTableBody">
              <Row>
                <Col xs={3} className="categoryTableHeaderTextContainer">
                  <div className="categoryTableBodyText">{prodType.typeName}</div>
                </Col>
                <Col xs={3} className="categoryTableHeaderTextContainer">
                  <div className="categoryTableBodyText">{prodType.id}</div>
                </Col>
                
                <Col xs={4} className="categoryTableHeaderTextContainer">
                  <div className="categoryTableBodyText">
                    {prodType.description}
                  </div>
                </Col>
                <Col xs={2} className="categoryTableHeaderTextContainer">
                  <div
                    className="categoryEditIcon"
                    onClick={() => navigateEditProductTypeScreen(prodType)}
                  >
                    <img src={EditIcon} />
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default connect(
  (state) => ({
    shop: state.login.get("merchantShop"),
    productTypes: state.product.get("productTypes"),
    loadingAction: state.common.get("loadingAction"),
  }),
  {
    fetchProductTypes: Actions.product.fetchProductTypes,
    // selectProductBrand: Actions.product.selectProductBrand,
    navigateToEditProductType: Actions.product.navigateToEditProductType,
  }
)(ProductTypesTableView);
