import React, {Component, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import ReactModal from 'react-modal';

const customStyles = {
  content: {
    top: '20%',
    outline: 'none',
    borderRadius: '4px',
    position: 'absolute',
    left: "calc(50% - 230px)",
    border: '1px solid rgba(112, 112, 112, 0.1)',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.16)"
  },
};

const ProductDeleteModal = (props) => {
  const {isOpen, closeModal, removeProduct} = props;

  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        style={customStyles}
        className={styles.productDeleteModalWrapper}
      >
        <div className={styles.productDeleteModalWrapperInfo}>
          <div className={styles.productDeleteModalHeaderText}>{'Are you sure ?'}</div>
          <div className={styles.productDeleteModalTitleText}>{'Are you sure you want to remove this selected \n service from bill ?'}</div>
          <div className={styles.productDeleteModalBtnWrapper}>
            <button className={styles.productDeleteModalBtnRemove} onClick={() => removeProduct()}>
              <div className={styles.productDeleteModalBtnText}>{'Yes, remove'}</div>
            </button>
            <button className={styles.productDeleteModalBtnCancel} onClick={() => closeModal()}>
              <div className={styles.productDeleteModalBtnText}>{'Cancel'}</div>
            </button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};
export default ProductDeleteModal;
