import React, {useRef, useEffect, useState} from 'react';
import {getCurrencyWithPrice} from '../../../internal/manager/PaymentManager';
import {getReportTableData, getReportTableHeader} from '../../../internal/manager/ReportsManager';
import {Chart, registerables} from 'chart.js';
import BaseColors from "../../../internal/helpers/BaseColors";
import {ReportChartContainer} from '../../Styles/Styled';
import _ from 'lodash';

const initChart = (canvas, labels, data, currency) => {
  const chartData = {
    labels,
    datasets: [
      {
        label: '',
        data,
        backgroundColor: [`${BaseColors.white}`],
        borderColor: [`${BaseColors.mediumSeaGreen}`],
        tension: 0.3,
        borderWidth: 3,
      },
    ],
  };

  const chart = new Chart(canvas, {
    type: 'line',
    data: chartData,
    options: {
      plugins: {
        legend: false,
      },
      maintainAspectRatio: false,
      tooltips: {
        displayColors: false,
        callbacks: {
          title: tooltipItems => {
          },
        },
      },
      scales: {
        y: {
          ticks: {
            // Include a dollar sign in the ticks
            callback: (value, index, values) => getCurrencyWithPrice(value, currency),
            beginAtZero: true,
            maxTicksLimit: 6,
          },
        },
      },
    },
  });
  return chart;
};

export default (props) => {
  const {type, labels, shop, appointments, dateRange} = props;
  Chart.register(...registerables);
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const [header, setHeader] = useState('');

  useEffect(() => {
    if (canvasRef === null || canvasRef.current === null) return;
    if (chartRef === null || _.isEmpty(labels)) {
      return;
    }
    // eslint-disable-next-line no-unused-expressions
    chartRef.current?.destroy();
    const data = getReportTableData(labels, appointments, type);

    const currency = _.get(shop, 'currency', 'USD');
    chartRef.current = initChart(canvasRef.current, labels, data, currency);
    //}
  }, [canvasRef, chartRef, appointments]);

  useEffect(() => {
    const header = getReportTableHeader(dateRange);
    setHeader(header);
  }, [dateRange]);

  return (
    <ReportChartContainer>
      <div>
        <div>{header}</div>
        <div>{'Overall information'}</div>
      </div>
      <div>
        <canvas height={300} ref={canvasRef}/>
      </div>
    </ReportChartContainer>
  );
};

