import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import {
  CustomerContainer,
  CustomerQuickContainer,
  CustomerLoadingContainer,
  CustomerComponentContainer,
} from "../../Styles/Styled";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Actions } from "../../../internal/app/Actions";
import SearchComponent from "../../search/SearchComponent";
import IconPlus from "../../../../assets/svg/IconPlus.svg";
import IconMinus from "../../../../assets/svg/IconMinus.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomerComponent from "../../../components/customer/CustomerComponent";
import QuickCustomerModel from "../../../components/model/views/QuickCustomerModel";
import CreateCustomerModel from "../../../components/model/views/CreateCustomerModel";

const Customer = (props) => {
  const {
    customers,
    onSelectCustomer,
    selectedCustomer,
    loadingAction,
    shopData,
    fetchCustomers,
    selectedAppointment,
    searchCustomers,
    quickCustomer,
    removeQuickCustomer,
    removeSelectedCustomer,
    isModal = false,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isOpenQuickCustomer, setIsOpenQuickCustomer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText]);

  const debouncedSearch = useCallback(
    _.debounce((text) => searchByText(text), 600, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const searchByText = (text) => {
    if (text && text.length > 0) {
      searchCustomers(shopData.shopId, text);
    } else {
      fetchCustomers(shopData.shopId);
    }
  };

  const onSelectedCustomer = (event, data) => {
    if (event.target.id !== "EDIT_CUSTOMER") {
      const isSelected = data.id === _.get(selectedCustomer, "id", "");
      onSelectCustomer(isSelected ? {} : data);
    }
  };

  const filterCustomers = _.filter(
    customers,
    (customer) => customer.type !== "STORE_CUSTOMER"
  );
  let sortResult = _.orderBy(filterCustomers, ["firstName"], ["asc"]);
  const groupByResult = _.groupBy(sortResult, (item) =>
    _.toUpper(item.firstName[0])
  );
  const { action, loading } = loadingAction;

  const toggleCustomerModal = () => {
    setIsOpenQuickCustomer(!isOpenQuickCustomer);
  };

  const onClickQuickCustomer = (event) => {
    if (event.target.id !== "PLUS_MINUS") {
      removeSelectedCustomer(selectedCustomer);
      toggleCustomerModal();
    }
  };

  const removeStoredCustomer = (event) => {
    if (event.target.id === "PLUS_MINUS" && !_.isEmpty(quickCustomer)) {
      const params = {
        id: quickCustomer.id,
        shopId: quickCustomer.shopId,
      };
      removeQuickCustomer(params);
    } else {
      removeSelectedCustomer(selectedCustomer);
      toggleCustomerModal();
    }
  };

  const toggleCreateCustomerModal = () => {
    setIsEdit(false);
    setIsOpen(!isOpen);
  };
  const onClickEditCustomer = () => {
    setIsEdit(true);
    setIsOpen(!isOpen);
  };

  return (
    <CustomerContainer>
      <SearchComponent
        searchText={searchText}
        onSearchText={(text) => setSearchText(text)}
        title={f({ id: "ORDER.CUSTOMER.SEARCH" })}
        deleteSearchText={() => setSearchText("")}
      />
      <div>
        <button onClick={() => toggleCreateCustomerModal()}>
          {f({ id: "ORDER.CUSTOMER.CREATE_CUSTOMER" })}
        </button>
        {loading &&
          (action.type === "CREATE_CUSTOMER" ||
            action.type === "FETCH_CUSTOMERS" ||
            action.type === "SEARCH_MERCHANT_CUSTOMER") && (
            <CustomerLoadingContainer>
              <CircularProgress color="primary" size={40} value={5} />
            </CustomerLoadingContainer>
          )}
        <CustomerQuickContainer
          isCustomerSelected={!_.isEmpty(selectedCustomer)}
          isExist={!_.isEmpty(quickCustomer)}
          onClick={(event) => onClickQuickCustomer(event)}
        >
          <div>{f({ id: "ORDER.CUSTOMER.QUICK_CUSTOMER" })} </div>
          {!_.isEmpty(quickCustomer) ? (
            <div className={"quickCustomerIconName"}>
              {" "}
              {` - ${quickCustomer.firstName} ${quickCustomer.lastName}`}
            </div>
          ) : null}
          <div
            id={"PLUS_MINUS"}
            onClick={(event) => removeStoredCustomer(event)}
            className={"quickCustomerIconAdd"}
          >
            <img src={!_.isEmpty(quickCustomer) ? IconMinus : IconPlus} />
          </div>
        </CustomerQuickContainer>
        <CustomerComponentContainer isModal>
          {Object.keys(groupByResult).map((key) => {
            const customerResult = groupByResult[key];
            return (
              <CustomerComponent
                custometKey={key}
                customerResult={customerResult}
                selectedCustomer={selectedCustomer}
                onSelectCustomer={(event, data) =>
                  onSelectedCustomer(event, data)
                }
                onClickEditCustomer={(event) => onClickEditCustomer(event)}
              />
            );
          })}
        </CustomerComponentContainer>
      </div>
      <CreateCustomerModel
        isOpen={isOpen}
        isEdit={isEdit}
        closeModal={() => toggleCreateCustomerModal()}
      />
      <QuickCustomerModel
        isOpen={isOpenQuickCustomer}
        isEdit={!_.isEmpty(quickCustomer)}
        closeModal={() => toggleCustomerModal()}
      />
    </CustomerContainer>
  );
};

export default connect(
  (state: any) => ({
    salon: state.salon.get("salon"),
    shopData: state.login.get("merchantShop"),
    customers: state.customer.get("customers"),
    currentUser: state.login.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    quickCustomer: state.customer.get("quickCustomer"),
    selectedCustomer: state.customer.get("selectedCustomer"),
    selectedAppointment: state.orders.get("selectedAppointment"),
  }),
  {
    fetchCustomers: Actions.customer.fetchCustomers,
    searchCustomers: Actions.customer.searchCustomers,
    onSelectCustomer: Actions.customer.onSelectCustomer,
    removeQuickCustomer: Actions.customer.removeQuickCustomer,
    removeSelectedCustomer: Actions.customer.removeSelectedCustomer,
  }
)(Customer);
