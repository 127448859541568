import React, {Component, useState} from 'react';
import styles from '../../Styles/Styles.css';

const CancelButton = (props) => {
  const {onClick, text= 'Cancel'} = props;

  return (
    <div onClick={() => onClick()} className={styles.cancelButtonContainer}>
      <div className={styles.cancelButtonText}>{text}</div>
    </div>
  );
};
export default CancelButton;
