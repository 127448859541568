import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FloorAreaModal from "./FloorAreaModal";
import { store } from "../../../internal/app/Store";
import { Actions } from "../../../internal/app/Actions";
import EditIcon from "../../../../assets/svg/editTable.png";
import { TableNavButton, NavItem, TableNavWrapper } from "./Styled";

const TableNavigation = ({ tableAreas, selectedArea, selectArea, mode, shop }) => {
  const [isOpen, setOpen] = useState(false);
  const [editAreaId, setEdit] = useState(null);

  useEffect(()=> {
    if(mode !== 'edit'){
      const shopId = shop.shopId;
      store.dispatch(Actions.table.fetchTables(shopId));
    }
  }, [selectedArea])

  return (
    <TableNavWrapper>
      {tableAreas?.tableAreas?.map(({ areaName, areaId }) => (
        <NavItem
          onClick={() => selectArea(areaId)}
          selected={areaId === selectedArea}
        >
          {areaName}
          {mode === "edit" ? (
            <img
              alt=""
              src={EditIcon}
              onClick={() => {
                setEdit(areaId);
                setOpen(true);
              }}
            />
          ) : null}
        </NavItem>
      ))}
      {mode === "edit" ? (
        <TableNavButton disabled={false}>
          <button
            onClick={() => {
              setEdit(null);
              setOpen(true);
            }}
          >
            <div>Add Floor Area</div>
          </button>
        </TableNavButton>
      ) : null}
      <FloorAreaModal
        editAreaId={editAreaId}
        isOpen={isOpen}
        closeModal={() => {
          setEdit(null);
          setOpen(false);
        }}
      />
    </TableNavWrapper>
  );
};

export default connect(
  (state: any) => ({
    mode: state.table.get("mode"),
    tables: state.table.get("tables"),
    shop: state.login.get("merchantShop"),
    tableAreas: state.table.get("tableAreas"),
    selectedArea: state.table.get("selectedArea"),
  }),
  {
    selectArea: Actions.table.selectArea,
  }
)(TableNavigation);
