import React from 'react';
import {List, Map} from 'immutable';
import {ModuleEvents} from './Actions';
import {ModuleEvents as CloseCashEvents} from '../closeCash/Actions';
import _ from 'lodash';
import {Actions} from "../../internal/app/Actions";

const initialState = Map({
  currentUser: {},
});

export const Reducer = (state = initialState, action) => {
  const {payload, type, error} = action;

  switch (action.type) {
    default:
      return state;
  }
};

export default Reducer;
