import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import "./tostify.style.css";
import isOnline from "is-online";
import { delay, isEmpty } from "lodash";
import PubSub from "@aws-amplify/pubsub";
import AmplifyJS from "@aws-amplify/core";
import config from "../public/release.json";
import Main from "./modules/menu/views/Main";
import { store } from "./internal/app/Store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Actions } from "./internal/app/Actions";
import awsconfig from "./internal/appconfig/aws-config";
import AsyncUpdate from "./components/updates/AsyncUpdate";
import UserRepository from "./internal/repos/UserRepository";
import CommonRepository from "./internal/repos/CommonRepository";
import { getDecryptedCode } from "./internal/manager/SecurityManager";
import { getValueFromLocalStorage } from "./internal/manager/CommonManager";
import MixpanelService, { MP_EVENTS } from "./internal/repos/MixpanelService";
import "./App.style.css";
import "./components/css/Antd.style.css";
import "./components/css/Calendar.style.css";
import "./components/css/PhoneInput.style.css";
import "./components/css/DatePicker.style.css";
import "./components/css/SwipeableList.style.css";
import "./components/css/DateRangePicker.style.css";

AmplifyJS.configure(awsconfig);
PubSub.configure(awsconfig);
CommonRepository.init(store);

const App = () => {
  const [online, setOnline] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [updateAvailable, setUpdateAvailable] = useState<boolean>(false);

  const onOnline = async () => {
    const online = await isOnline();
    if (online) {
      store.dispatch(Actions.common.setOnline(online));
      MixpanelService.track(MP_EVENTS.ONLINE_MODE, {
        time: new Date().toDateString(),
      });
      const employeeData = await getValueFromLocalStorage("SELECTED_EMPLOYEE");
      if (!isEmpty(employeeData)) {
        const employee: any = JSON.parse(employeeData);
        if (employee?.pin) {
          const pin: string = getDecryptedCode(employee.pin);
          // await UserRepository.userLogout(); // This is an error scenario, if the login failed error
          store.dispatch(Actions.login.userSignInAgain(employee.empId, pin));
        }
      }
    } else {
      store.dispatch(Actions.common.setOnline(online));
      // store.dispatch(Actions.common.setOffline(true));
      MixpanelService.track(MP_EVENTS.OFFLINE_MODE, {
        time: new Date().toDateString(),
      });
    }
  };

  const checkUpdates = async () => {
    const online = await isOnline();
    if (online) {
      const result = await axios(
        "https://5y1hnsmcq9.execute-api.ap-southeast-1.amazonaws.com/dev/app/updates"
      );
      if (result.data?.config?.data?.VERSION !== config.VERSION) {
        setUpdateAvailable(true);
      }
      console.log(result.data?.config?.data?.VERSION, config.VERSION);
    }
  };

  const checkOnline =  useCallback(async () => {
    const status = await isOnline();
    if (status !== online) {
      setOnline(status);
    }
    delay(() => checkOnline(), 3000);
  }, [online]);

  useEffect(() => {
    checkOnline();
    checkUpdates();
    store.dispatch(Actions.login.getMerchant());
    // return () => {
    //   window.removeEventListener("message", onMessage);
    // };
  }, []);

  useEffect(() => {
    if (online) {
      onOnline();
    }
    // onOnline();
  }, [online]);

  const downloadUpdate = () => {
    if (window?.api?.downloadUpdate) {
      setLoading(true);
      window.api.downloadUpdate();
    }
  };

  if (updateAvailable) {
    return (
      <AsyncUpdate
        skip={() => setUpdateAvailable(false)}
        downloadUpdate={() => downloadUpdate()}
        loading={loading}
      />
    );
  }

  return (
    <div>
      <Main />
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
MixpanelService.init();

export default App;
// eslint-disable-next-line
// ipcRenderer.on('result-printers', (event, arg) => {
//   store.dispatch(Actions.common.setPrinters(arg));
// });
// https://netlise-src.s3.ap-southeast-1.amazonaws.com/public/2.0.1.0.zip?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHQaDmFwLXNvdXRoZWFzdC0xIkgwRgIhAJSuqf6QFpZDxEBHLdMlMUupHTjOGpGZvscHplOq%2FFkbAiEAqK8sYCVlXsH1rkSho%2F54E259DJ1CLGUko88de7%2FcDEoq7QIIzf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwxMTQzODkwMTg5MzAiDEwrPmVK950aZpYCQirBAgtYGovjZf8KRlFyr7sX%2FQY%2F8v449dL0naJtrUGstVN7PUGle53uTI1zSfD5ABh76eZPGHFUtbAyKXUcWGlZb%2Bg1Gi4h6lNbhbOOjwgccn2170fg8zkj%2FaPQxBDt3DE2Bh%2FvCnZ1BOTl1K2Ts1D32yGN%2Bh%2FTWB9fcFhdwcAGg6q94MiAkmeR0bx%2BPt%2BMf2u26jNUSaSwHaWd0spSHkwBfTcvAIYQohVSsFzuO15OBdXBWD8kCEFumFOOKeK2Jp%2Bwj6JxUs1ZB8XnF7vOrRjs6zWzw1ubNAaEFnNpSvLZ8JDZInkHMdY7A3AUWXg3%2FysoFRoKx%2BJjBTyOWmtDJFSSRGDQ4OpBNCT5yushMeUxQS1%2FVBguVgSWGDzHLyoabbgisHts8PUHofOS2546PRIL89IOYCQ7ORDBuGFUQzNyTmnwazChic%2BkBjqyAiyG2ny6SLPQhdFhvpjuJGx6G1Uymsy9K8pcevxJOQ09BF0XCdceXvlCAMmXe%2BV8QX8FqfY1LSKaY6fIKhDIryBtIxJG3n6yCedPA0ETKUzN7NCUyOQFhCts4K9%2Bv2%2BSXGIWwEIAgZON5qmxBP%2Fjsc0Jix5c1nqVSqErVEEIeO2FqypKmMJB%2BPbIwbuftLoBcD%2BslOAlcIYw%2BNZDxRAAT4ZVvxN3hl9MgL0BqMqhefdidYXEbzS1hiUPRoP3DwgGQ6k7TePwKYRYORTm%2BvihNLFiAv4CeoxyYDHA00WrpgAjXe8fPEPd8P%2F%2Bh5G0q0EUxYJdWeqMES5trMX1Xpm8KyGfnPLUsMZwsTgBEP38SbjPC%2Be%2BcV%2BUJQQhudYonQTaVHXO2vRaMlkiZoSExifuGM%2BwUg%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230622T063651Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIARVIQ55EZEYJZJM76%2F20230622%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Signature=4819d8113ff5188a39754e638f2f85018314fd5de52d08e4799eab2f073802fb

// https://netlise-src.s3.ap-southeast-1.amazonaws.com/public/2.0.1.1.zip?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHQaDmFwLXNvdXRoZWFzdC0xIkgwRgIhAJSuqf6QFpZDxEBHLdMlMUupHTjOGpGZvscHplOq%2FFkbAiEAqK8sYCVlXsH1rkSho%2F54E259DJ1CLGUko88de7%2FcDEoq7QIIzf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwxMTQzODkwMTg5MzAiDEwrPmVK950aZpYCQirBAgtYGovjZf8KRlFyr7sX%2FQY%2F8v449dL0naJtrUGstVN7PUGle53uTI1zSfD5ABh76eZPGHFUtbAyKXUcWGlZb%2Bg1Gi4h6lNbhbOOjwgccn2170fg8zkj%2FaPQxBDt3DE2Bh%2FvCnZ1BOTl1K2Ts1D32yGN%2Bh%2FTWB9fcFhdwcAGg6q94MiAkmeR0bx%2BPt%2BMf2u26jNUSaSwHaWd0spSHkwBfTcvAIYQohVSsFzuO15OBdXBWD8kCEFumFOOKeK2Jp%2Bwj6JxUs1ZB8XnF7vOrRjs6zWzw1ubNAaEFnNpSvLZ8JDZInkHMdY7A3AUWXg3%2FysoFRoKx%2BJjBTyOWmtDJFSSRGDQ4OpBNCT5yushMeUxQS1%2FVBguVgSWGDzHLyoabbgisHts8PUHofOS2546PRIL89IOYCQ7ORDBuGFUQzNyTmnwazChic%2BkBjqyAiyG2ny6SLPQhdFhvpjuJGx6G1Uymsy9K8pcevxJOQ09BF0XCdceXvlCAMmXe%2BV8QX8FqfY1LSKaY6fIKhDIryBtIxJG3n6yCedPA0ETKUzN7NCUyOQFhCts4K9%2Bv2%2BSXGIWwEIAgZON5qmxBP%2Fjsc0Jix5c1nqVSqErVEEIeO2FqypKmMJB%2BPbIwbuftLoBcD%2BslOAlcIYw%2BNZDxRAAT4ZVvxN3hl9MgL0BqMqhefdidYXEbzS1hiUPRoP3DwgGQ6k7TePwKYRYORTm%2BvihNLFiAv4CeoxyYDHA00WrpgAjXe8fPEPd8P%2F%2Bh5G0q0EUxYJdWeqMES5trMX1Xpm8KyGfnPLUsMZwsTgBEP38SbjPC%2Be%2BcV%2BUJQQhudYonQTaVHXO2vRaMlkiZoSExifuGM%2BwUg%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230622T044547Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIARVIQ55EZEYJZJM76%2F20230622%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Signature=13c62c584517acf1edde72f048468c41861826fed96d6a1a13c45d0889fb052f
