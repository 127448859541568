import { delay, concat } from "lodash";
import {
  setValueToLocalStorage,
  getValueFromLocalStorage,
} from "../manager/CommonManager";
import Repository from "./Repository";
import GraphqlFunctions from "../service/Graphql.functions";
import { isNetworkError } from "../manager/AppointmentManager";

class CloseCashRepository extends Repository {
  createCash = async (params) => {
    params.endpointType = "CASH_OPERATION";
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/close-cash`,
        message,
      });
      return result.batchResult;
    } catch (error) {
      console.warn("create cash", error);
      return { error };
    }
  };

  closeCash = async (params) => {
    params.endpointType = "CLOSED_CASH";
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/close-cash`,
        message,
      });
      // GraphqlService.serviceMessage(
      //   "CLOSE_CASH",
      //   JSON.stringify({ ...params, status: "SUCCESS" })
      // );
      const data = {
        message: "CLOSE_CASH",
        value: JSON.stringify({ ...params, status: "SUCCESS" }),
      };
      await GraphqlFunctions.commonGraphqlService("SERVICE_MESSAGE", data);
      return result.batchResult;
    } catch (error) {
      console.warn("close cash", error);
      return { error };
    }
  };

  updateCloseCash = async (params) => {
    const message = this.buildMessage(params);
    try {
      const result = await this.apiPut({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/close-cash`,
        message,
      });
      return result.batchResult;
    } catch (error) {
      console.warn("close cash", error);
      return { error };
    }
  };

  getBatchSession = async (batchId) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("BATCH_SESSION");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }

      const result = await this.apiGet({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/batch?batchId=${batchId}`,
      });
      await setValueToLocalStorage(
        "BATCH_SESSION",
        JSON.stringify(result.batchResult)
      );
      return result.batchResult;
    } catch (error) {
      console.warn("close cash", error);
      return { error };
    }
  };

  async completedCloseCash(batchId, shopId, limit, lastKey) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("BATCH_APPOINTMENT");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }
      const params = {
        limit,
        batchId,
        shopId,
        lastKey,
        endpointType: "BATCH_APPOINTMENT",
      };
      const message = this.buildMessage(params);

      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/orders`,
        message,
      });
      await setValueToLocalStorage(
        "BATCH_APPOINTMENT",
        JSON.stringify(result.appointmentResult)
      );
      return result.appointmentResult;
    } catch (error) {
      console.warn("close cash", error);
      return { error };
    }
  }

  async getBatchSummary(batchId: string, retryCount: number = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("CLOSED_CASH_SUMMARY");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }
      const params = {
        batchId,
        endpointType: "CLOSED_CASH_SUMMARY",
      };
      const message = this.buildMessage(params);
      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/close-cash`,
        message,
      });
      await setValueToLocalStorage(
        "CLOSED_CASH_SUMMARY",
        JSON.stringify(result.batchResult)
      );
      return result.batchResult;
    } catch (error) {
      console.warn("close cash", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return new Promise((res) => {
          delay(() => {
            res(this.getBatchSummary(batchId, ++retryCount));
          }, 500 * retryCount);
        });
      }
      return { error };
    }
  }

  async getPastBatchSummary(shopId, userId, limit, lastKey, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("USER_PASS_BATCH");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }

      const params = {
        shopId,
        userId,
        limit,
        lastKey,
        endpointType: "USER_PASS_BATCH",
      };
      const message = this.buildMessage(params);

      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/batches`,
        message,
      });
      await setValueToLocalStorage(
        "USER_PASS_BATCH",
        JSON.stringify(result.batchResult)
      );
      return result.batchResult;
    } catch (error) {
      console.warn("close cash", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.getPastBatchSummary(
          shopId,
          userId,
          limit,
          lastKey,
          ++retryCount
        );
      }
      return { error };
    }
  }

  async getPaginatePastBatchSummary(
    shopId,
    userId,
    limit,
    lastKey,
    retryCount = 1
  ) {
    try {
      const isOnline = await this.isCheckOnline();
      if (isOnline) {
        const params = {
          shopId,
          userId,
          limit,
          lastKey,
          endpointType: "USER_PASS_BATCH",
        };
        const message = this.buildMessage(params);
        const result = await this.apiPost({
          apiName: this.CLOUD_POS_LAMBDA_API,
          path: `/batches`,
          message,
        });
        const values = await getValueFromLocalStorage("USER_PASS_BATCH");
        const newList = JSON.parse(values);

        await setValueToLocalStorage(
          "USER_PASS_BATCH",
          JSON.stringify(concat(result.batchResult, newList))
        );
        return result.batchResult;
      }
    } catch (error) {
      console.warn("close cash", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.getPastBatchSummary(
          shopId,
          userId,
          limit,
          lastKey,
          ++retryCount
        );
      }
      return { error };
    }
  }

  async createBatch(shopId, empId) {
    const message = this.buildMessage({ shopId, empId });
    try {
      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/batch`,
        message,
      });
      return result.batchResult;
    } catch (error) {
      console.warn("close cash", error);
      return { error };
    }
  }

  async fetchBatchOrderSummary(
    shopId: string,
    batchId: string,
    retryCount: number = 1
  ) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("BATCH_ORDER_SUMMARY");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }
      const result = await this.apiGet({
        apiName: this.API_NETLISE_APP,
        path: `/batch/summary?shopId=${shopId}&batchId=${batchId}`,
        message: null,
      });
      await setValueToLocalStorage(
        "BATCH_ORDER_SUMMARY",
        JSON.stringify(result.data)
      );
      return result.data;
    } catch (error) {
      console.warn("fetch batch order summary error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return new Promise((res) => {
          delay(() => {
            res(this.fetchBatchOrderSummary(shopId, batchId, ++retryCount));
          }, 500 * retryCount);
        });
      }
      return { error };
    }
  }
}

export default new CloseCashRepository();
