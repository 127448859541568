import Command from "./Command";
import { isArray } from "lodash";
import GraphqlService from "../service/Graphql.service";
import { isCheckOnline } from "./../manager/CommonManager";
import AppointmentRepository from "../repos/AppointmentRepository";

class OrderCommand extends Command {
  /**
   * Get offline orders
   * execute one by one
   * retry by giving a backoff
   * version resolution
   */
  async execute() {
    const data = await AppointmentRepository.getOfflineData();
    if (isArray(data) && data.length > 0) {
      console.log("[OFFLINE] processing offline data: ", data);
      await data.reduce(async (acc, item) => {
        const isOnline = await isCheckOnline();
        if (isOnline) {
          const result: any = await acc;
          try {
            let isUpdated: boolean;
            let isDeleted: boolean = item?._deleted;

            if (isDeleted) {
              const params = {
                orderId: item.orderId,
                shopId: item.shopId,
              };
              await AppointmentRepository.deleteOrderStorage(params);
              item["isRemoved"] = true;
              await GraphqlService.deleteOfflineOrder(item);
              return Promise.resolve(result);
            } else {
              const response = await AppointmentRepository.createOrderStorage(
                item
              );
              isUpdated = response?.isUpdated;
              if (isUpdated) {
                const awsStoreData = await AppointmentRepository.getShopOrder(
                  item.shopId,
                  item.orderId
                );
                const isVersionUpdated = awsStoreData?._version < item._version;
                if (isVersionUpdated) {
                  const response =
                    await AppointmentRepository.updateOrderStorage(item);
                  if (!response.error) {
                    item["isRemoved"] = true;
                    await GraphqlService.deleteOfflineOrder(item);
                  }
                  return Promise.resolve(result);
                } else if (
                  awsStoreData?._version === item._version &&
                  awsStoreData._lastChangedAt < item._lastChangedAt
                ) {
                  const response =
                    await AppointmentRepository.updateOrderStorage(item);
                  if (!response.error) {
                    item["isRemoved"] = true;
                    await GraphqlService.deleteOfflineOrder(item);
                  }
                  return Promise.resolve(result);
                }
              } else {
                if (!response.error) {
                  item["isRemoved"] = true;
                  await GraphqlService.deleteOfflineOrder(item);
                }
              }
              return Promise.resolve(result);
            }

            return Promise.resolve(result);
          } catch (e) {
            console.log("Error syncing : ");
          }
          return Promise.resolve(acc);
        }
      }, Promise.resolve([]));

      // await data.reduce(async (acc, item) => {
      //   const result = await acc;
      //   const { action, data } = item;
      //   if (AppointmentRepository.isCheckOnline()) {
      //     try {
      //       switch (action) {
      //         case "CREATE": {
      //           const response = await AppointmentRepository.createOrder(data);
      //           console.log(">>>>>>> create", response.error);
      //           if (!response.error) {
      //             const offlineItem =
      //               await AppointmentRepository.shiftOfflineItme();
      //             return Promise.resolve([...result, offlineItem]);
      //           }
      //           return Promise.resolve(result);
      //         }
      //         case "UPDATE": {
      //           const response = await AppointmentRepository.updateOrder(data);
      //           console.log(">>>>>>> update", response.error);
      //           if (!response.error) {
      //             const offlineItem =
      //               await AppointmentRepository.shiftOfflineItme();
      //             return Promise.resolve([...result, offlineItem]);
      //           }
      //           return Promise.resolve(result);
      //         }
      //         default:
      //           break;
      //       }
      //     } catch (e) {
      //       console.log("Error syncing : ");
      //     }
      //     return Promise.resolve(acc);
      //   }
      // }, Promise.resolve([]));
    }
  }
}

export default OrderCommand;
