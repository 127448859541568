import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import styles from "../../../components/Styles/Styles.css";
import { getSalesSummary } from "../../../internal/manager/CloseCashManager";

const CloseCashSummaryValues = (props) => {
  const { shopData, batchSummary } = props;
  const [summary, setSummary] = useState({});

  useEffect(() => {
    const serviceSummary = getSalesSummary(batchSummary, shopData);
    setSummary(serviceSummary);
  }, [batchSummary]);

  return (
    <div className={styles.closeCashSummaryValuesWrapper}>
      <div>
        <div className={styles.closeCashSalesValue}>{summary.grossPrice}</div>
        <div className={styles.closeCashSalesText}>{"Gross Sales"}</div>
      </div>
      <div>
        <div className={styles.closeCashSalesValue}>{summary.salesCount}</div>
        <div className={styles.closeCashSalesText}>{"Sales"}</div>
      </div>
      <div>
        <div className={styles.closeCashSalesValue}>{summary.productCount}</div>
        <div className={styles.closeCashSalesText}>{"Products"}</div>
      </div>
    </div>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    batchSession: state.login.get("batchSession"),
    batchSummary: state.closeCash.get("batchSummary"),
    appointments: state.closeCash.get("completedCashAppointments"),
  }),
  {}
)(CloseCashSummaryValues);
