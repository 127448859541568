import React, { useCallback, useEffect, useState, useRef } from "react";
import axios from "axios";
import swal from "sweetalert";
import ReopenModel from "./ReopenModal";
import {
  RefundButton,
  ReopenButton,
  TransactionsContainer,
  RefundButtonContainer,
  TransactionsBottomContainer,
  TransactionsRightContainer,
  TransactionsRightDetailsContainer,
  TransactionsRightDetailsContainerInfo,
} from "./Styled";
import { Row, Col } from "react-grid-system";
import ReactDomServer from "react-dom/server";
import { connect } from "react-redux/es/index";
import _, { get, isEmpty, filter } from "lodash";
import { Actions } from "../../../internal/app/Actions";
import styles from "../../../components/Common/Style/Styles.css";
import PrinterRepository from "../../../internal/repos/PrinterRepository";
import { generateImageUrl } from "../../../internal/manager/ImageManager";
import PaymentReciept from "../../../modules/payment/views/PaymentRecieptA4";
import IssueRefundModal from "../../../components/model/views/IssueRefundModal";
import GenerateReceipt from "../../../components/Buttons/Views/GenerateReceipt";
import { getTransactionSubTotal } from "../../../internal/manager/PaymentManager";
import { getHiPriorityUserRole } from "../../../internal/manager/EmployeeManager";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import TransactionsList from "../../../components/transactions/views/TransactionsList";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";
import TransactionDetails from "../../../components/transactions/views/TransactionDetails";
import TransactionDataRows from "../../../components/transactions/views/TransactionDataRows";
import TransactionTableHeader from "../../../components/transactions/views/TransactionTableHeader";

const Transactions = (props) => {
  const {
    shopData,
    reopenOrder,
    currentUser,
    createRefund,
    loadingAction,
    transactionsList,
    searchTransactions,
    fetchCompletedAppointments,
    updateCompletedAppointment,
  } = props;
  const listInnerRef: any = useRef();
  const [searchText, setSearchText] = useState("");
  const [showReopen, setReopen] = useState<boolean>(false);
  const [openRefund, setOpenRefund] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<Array<any>>([]);
  const [selectedTransaction, setSelectedTransaction] = useState<any>({});
  const { action, loading } = loadingAction;

  useEffect(() => {
    setSelectedTransaction(get(transactions, "[0]", {}));
  }, [transactions]);

  useEffect(() => {
    if (!_.isEmpty(transactionsList.transactionsList)) {
      let validTransactions: any = transactionsList.transactionsList;
      const userRole = getHiPriorityUserRole(currentUser.specializations);
      if (userRole === "Waiter") {
        validTransactions = filter(
          transactionsList.transactionsList,
          (transaction: any) => transaction.empId == currentUser.empId
        );
      }
      setTransactions(validTransactions);
    }
  }, [transactionsList.transactionsList, currentUser]);

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText]);

  const toggleRefundModal = () => {
    setOpenRefund(!openRefund);
  };

  const onReopenOrder = () => {
    const data = {
      shopId: selectedTransaction.shopId,
      orderId: selectedTransaction.orderId,
      orderStatus: "REOPEN",
    };
    setReopen(!showReopen);
    reopenOrder(data);
    setSelectedTransaction({});
  };

  const onCreateRefund = (params: any) => {
    const order = selectedTransaction;
    const totalPrice = selectedTransaction.totalPrice - params.amount;
    let servicePrice = params.services?.reduce(
      (prev: any, item: any) => prev + item?.amount,
      0
    );
    let productPrice = params.products?.reduce(
      (prev: any, item: any) => prev + item?.amount,
      0
    );
    let serviceCount = params.services?.reduce(
      (prev: any, item: any) => prev + item?.quantity,
      0
    );
    let productCount = params.products?.reduce(
      (prev: any, item: any) => prev + item?.quantity,
      0
    );
    const refunds = {
      servicePrice,
      productPrice,
      serviceCount,
      productCount,
      amount: params.amount,
      batchId: params.batchId,
      createdTime: Date.now(),
      services: params.services,
      products: params.products,
    };

    // if (isArray(selectedTransaction.refunds)) {
    //   order.refunds.push(refunds);
    // } else {
    //   order.refunds = [refunds];
    // }
    order.totalPrice = totalPrice;
    order.voidType = params.voidType;
    if (order.serviceCharge && order.serviceCharge?.amount) {
      order.serviceCharge.amount = `${get(order, "serviceCharge.amount", "0")}`;
    }
    // const data = {
    //   shopId: selectedTransaction.shopId,
    //   orderId: selectedTransaction.orderId,
    //   totalPrice,
    // };
    updateCompletedAppointment(order);
    createRefund(params);
  };

  const debouncedSearch = useCallback(
    _.debounce((text) => searchTransactionsForText(text), 600, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const searchTransactionsForText = (text) => {
    if (text && text.length > 0) {
      searchTransactions(shopData.shopId, text);
    } else {
      fetchCompletedAppointments(shopData.shopId);
    }
  };

  const printBill = async () => {
    if (selectedTransaction.orderId) {
      const txServices = _.get(selectedTransaction, "serviceDetails", []);
      const txProducts = _.get(selectedTransaction, "productDetails", []);
      const txDiscounts = _.get(selectedTransaction, "discountDetails", []);
      const services = txServices.map((item) => ({
        serviceTitle: item.title,
        quantity: item.quantity,
        servicePrice: item.price,
        price: item.totalPrice,
      }));
      const products = txProducts.map((item) => ({
        productName: item.productName,
        quantity: item.quantity,
        productSalePrice: item.sellingPrice,
        price: item.totalPrice,
      }));
      const imageData = await axios.get(generateImageUrl(shopData.image), {
        responseType: "arraybuffer",
      });
      let image = null;
      if (imageData.data) {
        image =
          "data:image/png;base64," +
          Buffer.from(imageData.data, "binary").toString("base64");
      }
      const subTotal = getTransactionSubTotal(txServices, txProducts);
      const str = ReactDomServer.renderToString(
        <PaymentReciept
          params={selectedTransaction}
          image={image}
          selectedCustomer={{
            firstName: selectedTransaction.firstName,
            lastName: selectedTransaction.lastName,
            email: selectedTransaction.email,
            mobileNumber: selectedTransaction.mobileNumber,
          }}
          purchaseProducts={products}
          purchaseServices={services}
          givenPrice={selectedTransaction.paidPrice}
          shopData={shopData}
          total={selectedTransaction.totalPrice}
          subTotal={subTotal}
          appointmentDiscounts={txDiscounts}
        />
      );
      try {
        await PrinterRepository.printPOS(str);
        success("Printed reciept successfully");
      } catch (e) {
        console.warn({ e });
        success("Error occurred while printing", "warning");
      }
    }
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        transactionsList.lastKey
      ) {
        fetchCompletedAppointments(
          shopData.shopId,
          10,
          transactionsList.lastKey
        );
      }
    }
  };

  return (
    <TransactionsContainer>
      <TopNavigationBar viewName="Transactions" path="/menu" />

      <TransactionsBottomContainer>
        <div>
          <Row style={{ margin: 0, height: "calc(100vh - 81px)" }}>
            <Col style={{ height: "100%" }} xs={6}>
              <div style={{ width: "100%" }}>
                <TransactionsList
                  searchText={searchText}
                  onChangeSearch={(text) => setSearchText(text)}
                  deleteSearchText={() => setSearchText("")}
                />
                <div className={styles.transactionsHeaderWrapperInfo}>
                  <TransactionTableHeader onClickFilter={() => {}} />
                  <div
                    style={{
                      width: "100%",
                      overflow: "auto",
                      position: "relative",
                      height: window.innerHeight - 250,
                    }}
                    ref={listInnerRef}
                    onScroll={() => onScroll()}
                  >
                    {loading &&
                      (action.type === "FETCH_COMPLETED_APPOINTMENTS" ||
                        action.type === "SEARCH_TRANSACTIONS") && (
                        <div
                          style={{
                            top: "40%",
                            left: "50%",
                            position: "absolute",
                          }}
                        >
                          <CircularProgress
                            color="primary"
                            size={60}
                            value={5}
                          />
                        </div>
                      )}
                    {!isEmpty(transactions) &&
                      transactions.map((item) => {
                        return (
                          <TransactionDataRows
                            shopData={shopData}
                            transaction={item}
                            selectedTransaction={selectedTransaction}
                            onClickTransaction={(data) =>
                              setSelectedTransaction(data)
                            }
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </Col>
            <Col style={{ padding: 0, height: "100%" }} xs={6}>
              <TransactionsRightContainer>
                <GenerateReceipt printReciept={printBill} />
                <TransactionsRightDetailsContainer>
                  <TransactionsRightDetailsContainerInfo>
                    <TransactionDetails
                      selectedTransaction={selectedTransaction}
                      shopData={shopData}
                    />
                  </TransactionsRightDetailsContainerInfo>
                </TransactionsRightDetailsContainer>
                {!isEmpty(selectedTransaction) ? (
                  <RefundButtonContainer>
                    <RefundButton
                      onClick={() => toggleRefundModal()}
                      valid={true}
                    >
                      {"Refund Order"}
                    </RefundButton>
                    <ReopenButton valid onClick={() => setReopen(true)}>
                      {"Reopen Order"}
                    </ReopenButton>
                  </RefundButtonContainer>
                ) : null}
              </TransactionsRightContainer>
            </Col>
          </Row>
        </div>
      </TransactionsBottomContainer>

      <IssueRefundModal
        type={"CASH_OUT"}
        isOpen={openRefund}
        transaction={selectedTransaction}
        closeModal={() => toggleRefundModal()}
        onCreateRefund={(data) => onCreateRefund(data)}
      />
      <ReopenModel
        {...{
          isOpen: showReopen,
          onCancel: () => setReopen(false),
          onConfirm: onReopenOrder,
        }}
      />
    </TransactionsContainer>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
    merchantShop: state.login.get("merchantShop"),
    loadingAction: state.common.get("loadingAction"),
    transactionsList: state.orders.get("transactionsList"),
    salonAppointments: state.orders.get("salonAppointments"),
    completedAppointments: state.orders.get("completedAppointments"),
  }),
  {
    reopenOrder: Actions.orders.reopenOrder,
    createRefund: Actions.orders.createRefund,
    searchTransactions: Actions.orders.searchTransactions,
    fetchPaginateAppointment: Actions.orders.fetchPaginateAppointment,
    fetchEmployeesForSalonId: Actions.employee.fetchEmployeesForSalonId,
    fetchCompletedAppointments: Actions.orders.fetchCompletedAppointments,
    updateCompletedAppointment: Actions.orders.updateCompletedAppointment,
  }
)(Transactions);

const success = (alert: string, icon = "success") => {
  return swal({
    title: alert,
    icon: icon,
    dangerMode: icon === "warning",
  });
};