import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {AppointmentFilterContainer} from '../../Styles/Styled';

const OrderQueueFilterButtons = (props) => {
  const {type, options, onClick,} = props;
  const [filterType, setFilterType] = useState('');

  useEffect(()=> {
    setFilterType(type)
  }, [type]);

  const getText = (item) => {
    switch (filterType) {
      case 'APPOINTMENT': {
        return item.label;
      }
      case 'CUSTOMER': {
        return `${item.firstName} ${item.lastName}`;
      }
      default: {
        return item.name;
      }
    }
  }

  const renderFilter = (options)=> {
    return(
      <div>
        {
          options.map((item: any, index: number) => {

            const text = getText(item);
              return (
                <div key={text} onClick={() => onClick(item)}>
                  <p>{text}</p>
                  {
                    index !== options.length - 1 ?
                      <div/>
                      : null
                  }
                </div>
              )
            }
          )
        }
      </div>
    )
  };

  return (
    <AppointmentFilterContainer>
      {
        filterType === 'APPOINTMENT' ?
          renderFilter(options)
          : <div/>
      }
      {
        filterType === 'EMPLOYEE' ?
          renderFilter(options)
          : <div/>
      }
      {
        filterType === 'CUSTOMER' ?
          renderFilter(options)
          : <div/>
      }
      {
        filterType === 'SERVICE' ?
          renderFilter(options)
          : <div/>
      }
    </AppointmentFilterContainer>
  );
};
export default connect(
  state => ({}),
  ({})
)(OrderQueueFilterButtons);
