import Repository from "./Repository";
import { get } from "lodash";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { FetchModifiers } from "./schema/Modifier.schema";

class ModifierRepository extends Repository {
  fetchModifiers = async (shopId: string, limit: number, nextToken: any) => {
    try {
      const params = {
        shopId,
        limit,
        nextToken,
      };
      const result = await this.API.graphql(
        graphqlOperation(FetchModifiers, params)
      );
      return get(result, "data.fetchModifiers", {});
    } catch (error) {
      console.warn("fetch modifiers error", error);
      return { error };
    }
  };
}
export default new ModifierRepository();
