import * as Yup from "yup";

export const ModifierSchema = Yup.object({
  productModifiers: Yup.array().of(
    Yup.object().shape({
      setName: Yup.string()
        .min(1, "At least 1 characters or more")
        .required("Please enter modifier set name"),
      title: Yup.string()
        .min(1, "At least 1 characters or more")
        .required("Please enter modifier title"),
      isMandatory: Yup.boolean(),
      items: Yup.array().of(
        Yup.object().shape({
          itemName: Yup.string()
            .min(1, "At least 1 characters or more")
            .required("Please enter modifier item name"),
          price: Yup.number()
            .typeError("Please enter a valid price")
            .required("Please enter item price")
            .positive("Price must be more than 0"),
        })
      ),
    })
  ),
});
