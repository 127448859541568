// @ts-nocheck
import { useState } from "react";
import { nanoid } from "nanoid";
import Select from "react-select";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { capitalize, isEmpty } from "lodash";
import { UpsertPrinterWrapper } from "./Styled";
import ModalStyles from "../../Styles/ModelStyles.css";
import { Actions } from "../../../internal/app/Actions";
import { Col, Container, Row } from "react-grid-system";
import CANCEL from "../../../../assets/icons/cancel.svg";
import styles from "../../../components/Styles/Styles.css";
import SaveButton from "../../../components/Buttons/Views/SaveButton";
import CancelButton from "../../../components/Buttons/Views/CancelButton";
import GraphqlFunctions from "../../../internal/service/Graphql.functions";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 314px)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

export enum PrintTypes {
  RECEIPT = "RECEIPT",
  KITCHEN = "KITCHEN",
  BAR = "BAR",
}

export enum DeviceTypes {
  USB = "USB",
  NETWORK = "NETWORK",
}

const selectStyle = {
  control: (base, state) => ({
    ...base,
    height: "42px",
    width: "100%",
    paddingRight: "20px",
    border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5",
    "&:hover": {
      border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5",
    },
    borderRadius: "4px",
    color: "#00000085",
    fontSize: "14px",
    fontFamily: "Montserrat",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    paddingLeft: "5px",
  }),
};

const PrinterTypesData = [
  {
    label: "Receipt Printer",
    value: PrintTypes.RECEIPT,
  },
  {
    label: "Kitchen Printer(KOT)",
    value: PrintTypes.KITCHEN,
  },
  {
    label: "Bar Printer(BOT)",
    value: PrintTypes.BAR,
  },
];
const DeviceTypeData = [
  {
    label: "Network Printer TCP/IP",
    value: DeviceTypes.NETWORK,
  },
  {
    label: "USB Printer",
    value: DeviceTypes.USB,
  },
];

const UpsertPrinterModal = ({ isOpen, onClose, printers = [] }) => {
  const [printType, setPrintType] = useState<{
    label: string;
    value: PrintTypes;
  }>(PrinterTypesData[0]);
  const [deviceType, setDeviceType] = useState<{
    label: string;
    value: DeviceTypes;
  }>(DeviceTypeData[0]);
  const printerData = printers?.map((p: any) => ({
    value: p.name,
    label: p.displayName,
  }));
  const [usbPrinter, setUSBPrinter] = useState<{
    label: string;
    value: string;
  }>();

  const [ipAddress, setIPAddress] = useState("");
  const [printerName, setPrinterName] = useState("");

  const addPrinter = async () => {
    if (
      !printType.value ||
      !(
        (deviceType.value === DeviceTypes.USB && usbPrinter?.value) ||
        (deviceType.value === DeviceTypes.NETWORK && !isEmpty(ipAddress))
      )
    ) {
      return;
    }
    let printers: any = await GraphqlFunctions.commonGraphqlService(
      "FETCH_PRINTERS"
    );
    // const printers = StorageService.getItem("printers") || [];
    printers.push({
      id: nanoid(),
      printType: printType.value,
      deviceType: deviceType.value,
      usb: usbPrinter?.value,
      ipAddress,
      printerName: isEmpty(printerName)
        ? `${capitalize(printType.value)} Printer`
        : printerName,
    });
    await GraphqlFunctions.commonGraphqlService("SET_PRINTERS", printers);
    // StorageService.setItem("printers", printers);
    onClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={ModalStyles.addPrinterModal}
    >
      <UpsertPrinterWrapper>
        <div className="modal-header">
          <div className={styles.addAppointmentHeaderText}>Add Printer</div>
          <img alt="icon" src={CANCEL} onClick={onClose} />
        </div>
        <Container>
          <Row>
            <Col xs={12}>
              <div className={styles.printerSetupWrapper}>
                <div className={styles.printerSetupText}>Printer Type</div>
                <Select
                  styles={selectStyle}
                  value={printType}
                  options={PrinterTypesData}
                  onChange={(data) => setPrintType(data)}
                  placeholder={
                    <div className="languagePlaceholderText">
                      Reciept, Kitchen or Bar
                    </div>
                  }
                />
              </div>
              <div className={styles.printerSetupWrapper}>
                <div className={styles.printerSetupText}>Device Type</div>
                <Select
                  styles={selectStyle}
                  value={deviceType}
                  options={DeviceTypeData}
                  onChange={(data) => setDeviceType(data)}
                  placeholder={
                    <div className="languagePlaceholderText">Printer Type</div>
                  }
                />
              </div>
              {deviceType.value === DeviceTypes.USB ? (
                <div className={styles.printerSetupWrapper}>
                  <div className={styles.printerSetupText}>Printer</div>
                  <Select
                    styles={selectStyle}
                    value={usbPrinter}
                    options={printerData}
                    onChange={(data) => setUSBPrinter(data)}
                    placeholder={
                      <div className="languagePlaceholderText">USB Printer</div>
                    }
                  />
                </div>
              ) : (
                <div className={styles.printerSetupWrapper}>
                  <div className={styles.printerSetupText}>
                    Hostname or IP Address
                  </div>
                  <input
                    onChange={(e) => setIPAddress(e.target.value)}
                    className={styles.printerSetupDeviceInput}
                  />
                </div>
              )}
              <div className={styles.printerSetupWrapper}>
                <div className={styles.printerSetupText}>Printer Name</div>
                <input
                  onChange={(e) => setPrinterName(e.target.value)}
                  className={styles.printerSetupDeviceInput}
                />
              </div>

              <div
                className={styles.printerSetupButtonsWrapper}
                style={{ justifyContent: "flex-end" }}
              >
                <SaveButton text={"Add Printer"} onClick={addPrinter} />
                <CancelButton text={"Cancel"} onClick={() => onClose()} />
              </div>

              {/* <div className={styles.printerSetupWrapper}>
                <div className={styles.printerSetupText}>
                  Associated registers
                </div>
                <input className={styles.printerSetupDeviceInput} />
              </div> */}
            </Col>
            {/* <Col xs={8}>
          </Col> */}
          </Row>
        </Container>
      </UpsertPrinterWrapper>
    </ReactModal>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    printers: state.common.get("printers"),
  }),
  {
    setAppointmentDiscount: Actions.product.setAppointmentDiscount,
  }
)(UpsertPrinterModal);
