import React from 'react';
import ReactModal from 'react-modal';
import styles from '../../Styles/ModelStyles.css';

const AuthenticationAlertModel = (props) => {
  const { isOpen, closeModel, message } = props;

  const customStyles = {
    content: {
      top: '46%',
      outline: 'none',
      overflow: 'auto',
      position: 'absolute',
      borderRadius: '14px',
      left: "calc(50% - 150px)",
      WebkitOverflowScrolling: 'touch',
      border: '1px solid rgba(112, 112, 112, 0.1)',
    }
  };

  return (
    <div >
      <ReactModal isOpen={isOpen}  className={styles.authenticationModel} style={customStyles}>
        <div>
          <div style={{fontFamily: 'Montserrat', color: '#FFFFFF', fontSize: '20px', textAlign: 'center', marginTop: '20px', fontWeight: 'bold'}}>
            Warning
          </div>
          <div style={{fontFamily: 'Montserrat', color: '#FFFFFF', fontSize: '15px', textAlign: 'center', marginTop: '20px', lineHeight: '20px'}}>
            {message}
          </div>
          <div onClick={() => closeModel()} style={{fontFamily: 'Montserrat', color: '#0A84FF', fontSize: '20px', textAlign: 'center', marginTop: '30px', borderColor: '', fontWeight: 'bold'}}>
            Okay
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default AuthenticationAlertModel;
