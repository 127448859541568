import { createAction } from "../../internal/app/AppUtils";
import WarrantyRepository from "../../internal/repos/WarrantyRepository";

export const ModuleEvents = {
  CREATE_WARRANTY: "CREATE_WARRANTY",
  UPDATE_WARRANTY: "UPDATE_WARRANTY",
  DELETE_WARRANTY: "DELETE_WARRANTY",
  FETCH_WARRANTIES: "FETCH_WARRANTIES",
  SET_PRODUCT_WARRANTY: "SET_PRODUCT_WARRANTY",
};
const Actions = {
  fetchWarranties: createAction(
    ModuleEvents.FETCH_WARRANTIES,
    async (shopId: string, limit: number = 50, nextToken: any = null) => {
      const result = await WarrantyRepository.fetchWarranties(
        shopId,
        limit,
        nextToken
      );
      return result;
    },
    { loading: true }
  ),
  createWarranty: createAction(
    ModuleEvents.CREATE_WARRANTY,
    async (params: any) => {
      const result = await WarrantyRepository.createWarranty(params);
      return result;
    },
    { loading: true }
  ),
  updateWarranty: createAction(
    ModuleEvents.UPDATE_WARRANTY,
    async (params: any) => {
      const result = await WarrantyRepository.updateWarranty(params);
      return result;
    },
    { loading: true }
  ),
  deleteWarranty: createAction(
    ModuleEvents.DELETE_WARRANTY,
    async (params: any) => {
      const result = await WarrantyRepository.deleteWarranty(params);
      return result;
    },
    { loading: true }
  ),
  setProductWarranty: createAction(
    ModuleEvents.SET_PRODUCT_WARRANTY,
    async (data: any) => {
      return data;
    }
  ),
};

export default Actions;
