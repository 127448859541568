import Repository from "./Repository";
import { get } from "lodash";
import {
  CreateWarranty,
  DeleteWarranty,
  UpdateWarranty,
  FetchWarranties,
} from "./schema/Warranty.schema";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { isNetworkError } from "../manager/AppointmentManager";
import { getValueFromLocalStorage } from "../manager/CommonManager";

class WarrantyRepository extends Repository {
  fetchWarranties = async (
    shopId: string,
    limit: number,
    nextToken: any,
    retryCount: number = 1
  ) => {
    const isOnline = await this.isCheckOnline();
    try {
      const params = {
        shopId,
        limit,
        nextToken,
      };
      if (!isOnline) {
        const list = await getValueFromLocalStorage("SHOP_WARRANTIES");
        if (list) {
          return JSON.parse(list);
        }
        return { items: [] };
      }
      const result = await this.API.graphql(
        graphqlOperation(FetchWarranties, params)
      );
      return get(result, "data.fetchWarranties", {});
    } catch (error) {
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchWarranties(
          shopId,
          limit,
          nextToken,
          ++retryCount
        );
      }
      console.warn("fetch warranties error", error);
      return { error };
    }
  };

  createWarranty = async (params: any, retryCount: number = 1) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
      }
      const result = await this.API.graphql(
        graphqlOperation(CreateWarranty, { input: params })
      );
      return get(result, "data.createWarranty", []);
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createWarranty(params, ++retryCount);
      }
      return { error };
    }
  };

  updateWarranty = async (params: any, retryCount: number = 1) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
      }
      const result = await this.API.graphql(
        graphqlOperation(UpdateWarranty, { input: params })
      );
      return get(result, "data.updateWarranty", []);
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.updateWarranty(params, ++retryCount);
      }
      return { error };
    }
  };

  deleteWarranty = async (params: any, retryCount: number = 1) => {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
      }
      const result = await this.API.graphql(
        graphqlOperation(DeleteWarranty, { input: params })
      );
      return get(result, "data.deleteWarranty", []);
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteWarranty(params, ++retryCount);
      }
      return { error };
    }
  };
}

export default new WarrantyRepository();
