import React, {useEffect, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import {connect} from 'react-redux';
import {Actions} from '../../../internal/app/Actions';
import _ from 'lodash';
import '../../Styles/ModelStyles.css';
import {CreateCategoryContainer} from '../../Styles/Styled';
import {nanoid} from "nanoid";
import Select from 'react-select';
import {mapCategory as mapServiceCategory} from "../../../internal/manager/CategoryManager";
import {mapBrand, mapCategory, mapProductType} from "../../../internal/manager/ProductManager";

const customStyles = {
  content: {
    top: '10vh',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
    left: 'calc(50% - 264px)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const style = {
  control: (base, state) => ({
    ...base,
    height: '42px',
    width: '100%',
    paddingRight: "20px",
    border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5",
    '&:hover': {
      border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5"
    },
    borderRadius: "4px",
    color: "#00000085",
    fontSize: "14px",
    fontFamily: "Montserrat",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    paddingLeft: "5px"
  })
};

const CreateCategoryModel = (props) => {
  const {
    type, isOpen, closeModal, currentUser, shop, createProductCategory, createBrand, categories,
    createServiceCategory, updateServiceCategory, brandsList, productCategoryList,
    createProductType, productTypes,
  } = props;

  const [header, setHeader] = useState('');
  const [titlePlaceholder, setTitlePlaceholder] = useState('');
  const [descriptionPlaceholder, setDescriptionPlaceholder] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [inputPlaceholder, setInputPlaceholder] = useState('');
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    switch (type) {
      case 'CATEGORY':
        setHeader('Create Category');
        setTitlePlaceholder('CATEGORY TITLE *');
        setDescriptionPlaceholder('DESCRIPTION');
        setButtonText('Create Category');
        setInputPlaceholder('Product Category');
        return;
      case 'BRAND':
        setHeader('Create Brand');
        setTitlePlaceholder('BRAND TITLE *');
        setDescriptionPlaceholder('DESCRIPTION');
        setButtonText('Create Brand');
        setInputPlaceholder('Product Brand');
        return;
      case 'PRODUCT_TYPE':
        setHeader('Create Product Type');
        setTitlePlaceholder('PRODUCT TYPE NAME *');
        setDescriptionPlaceholder('DESCRIPTION');
        setButtonText('Create Product Type');
        setInputPlaceholder('Product Type');
        return;
      case 'SERVICE':
        setHeader('Create Category');
        setTitlePlaceholder('CATEGORY TITLE *');
        setDescriptionPlaceholder('DESCRIPTION');
        setButtonText('Create Category');
        setInputPlaceholder('Service Category');
        return;
      default:
        return;
    }
  }, [type]);

  const initialState = () => {
    setInputValue('');
    setDescription('');
  };

  const onClickCreate = () => {
    if (!_.isEmpty(inputValue)) {
      if (type === 'CATEGORY') {
        const param = {
          description,
          categoryName: inputValue,
          shopId: shop.shopId,
          updatedTime: Date.now(),
          createdTime: Date.now(),
          categoryId: nanoid(8),
          createdUserId: currentUser.empId
        };
        createProductCategory(param, 'ADD_QUICK_ITEM');
      } else if (type === 'PRODUCT_TYPE') {
        const param = {
          id: nanoid(8),
          description,
          typeName: inputValue,
          shopId: shop.shopId,
          updatedTime: Date.now(),
          createdTime: Date.now(),
          createdUserId: currentUser.empId
        };
        createProductType(param, 'ADD_QUICK_ITEM');
      } else if (type === 'BRAND') {
        let param = {
          description,
          id: nanoid(8),
          shopId: shop.shopId,
          brandName: inputValue,
          updatedTime: Date.now(),
          createdTime: Date.now(),
          createdUserId: currentUser.empId
        };
        createBrand(param, 'ADD_QUICK_ITEM');
      } else if (type === 'SERVICE') {
        let category = {
          description,
          id: nanoid(8),
          category: inputValue,
          createdTime: Date.now(),
        };
        if (!_.isEmpty(categories)) {
          categories.categories.push(category);
          let param = {
            scid: categories.scid,
            categories: categories.categories
          };
          updateServiceCategory(param, 'ADD_QUICK_ITEM');
        } else {
          let param = {
            scid: nanoid(8),
            shopId: shop.shopId,
            categories: [{...category}],
            createdUserId: currentUser.empId
          };
          createServiceCategory(param, 'ADD_QUICK_ITEM');
        }
      }
      initialState();
      closeModal();
    }
  };

  const mapServiceCategoryList = mapServiceCategory(categories?.categories || []);
  const mapProductCategoryList = mapCategory(productCategoryList);
  const mapProductBrandList = mapBrand(brandsList);
  const mappedProductTypes = mapProductType(productTypes);


  const getOptions = () => {
    switch (type) {
      case 'CATEGORY':
        return mapProductCategoryList;
      case 'BRAND':
        return mapProductBrandList;
      case 'SERVICE':
        return mapServiceCategoryList;
      case 'PRODUCT_TYPE':
        return mappedProductTypes;
      default:
        return;
    }
  };

  const onChange = option => {
    setValue(option);
  };

  const onInputChange = (option, {action}) => {
    if (action === "input-change") {
      const optionLength = option.length;
      const inputValueLength = inputValue.length;
      const newInputValue =
        optionLength < inputValueLength
          ? option
          : inputValue + option[option.length - 1];
      setInputValue(newInputValue);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.createCategoryModal}
    >
      <CreateCategoryContainer isValid={!_.isEmpty(inputValue)}>
        <div onClick={() => closeModal()}>
          <div>{header}</div>
          <img src={CANCEL}/>
        </div>
        <div>
          <div>
            <div>{titlePlaceholder}</div>
            <Select
              value={value}
              styles={style}
              onChange={onChange}
              options={getOptions()}
              inputValue={inputValue}
              onInputChange={onInputChange}
              placeholder={<div className="createVehiclePlaceholderText">{inputPlaceholder}</div>}
            />
          </div>

          <div>
            <div>{descriptionPlaceholder}</div>
            <textarea
              value={description}
              onChange={(number) => setDescription(number.target.value)}
            />
          </div>
          <div>
            <button onClick={() => onClickCreate()}>
              <div>{buttonText}</div>
            </button>
          </div>
        </div>
      </CreateCategoryContainer>
    </ReactModal>
  );
};
export default connect(
  (state) => ({
    shop: state.login.get('merchantShop'),
    currentUser: state.login.get('currentUser'),
    categories: state.service.get('categories'),
    brandsList: state.product.get('brandsList'),
    productTypes: state.product.get("productTypes"),
    productCategoryList: state.product.get('productCategoryList'),
  }),
  {
    createProductCategory: Actions.product.createProductCategory,
    createBrand: Actions.product.createBrand,
    createProductType: Actions.product.createProductType,
    updateServiceCategory: Actions.service.updateServiceCategory,
    createServiceCategory: Actions.service.createServiceCategory,

  }
)(CreateCategoryModel);
