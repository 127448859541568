import React, {useEffect, useState} from 'react';
import styles from '../../Styles/ModelStyles.css';
import ReactModal from 'react-modal';
import CANCEL from '../../../../assets/icons/cancel.svg';
import {connect} from 'react-redux';
import {Actions} from '../../../internal/app/Actions';
import _ from 'lodash';
import '../../Styles/ModelStyles.css';
import {CreateQuickItemContainer} from '../../Styles/Styled';
import {nanoid} from "nanoid";

import Select from 'react-select';
import {mapCategory as mapServiceCategory, mapCreateQuickService, setNumberOnly} from "../../../internal/manager/CategoryManager";
import {mapBrand, mapCategory} from "../../../internal/manager/ProductManager";
import { getCurrencyWithPrice } from '../../../internal/manager/PaymentManager';

const customStyles = {
  content: {
    top: '10%',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
    left: 'calc(50% - 400px)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const style = {
  control: (base, state) => ({
    ...base,
    height: '42px',
    width: '100%',
    paddingRight: "20px",
    border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5",
    '&:hover': {
      border: state.isFocused ? "1px solid #8A9CA5" : "1px solid #8A9CA5"
    },
    borderRadius: "4px",
    color: "#00000085",
    fontSize: "14px",
    fontFamily: "Montserrat",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    paddingLeft: "5px"
  })
};

const CreateQuickItemModel = (props) => {
  const {text, isOpen, closeModal, productCategoryList, brandsList, currentUser, createProduct,  categories, shop, updateServiceCategory,
    onClickCreateProductCategory, onClickCreateProductBrand, onClickCreateServiceCategory
  } = props;

  const [selectedHeader, setSelectedHeader] = useState('PRODUCT');
  const [productName, setProductName] = useState('');
  const [productCategory, setProductCategory] = useState(null);
  const [brand, setBrand] = useState(null);
  const [price, setPrice] = useState('');
  const [serviceTitle, setServiceTitle] = useState('');
  const [serviceCategory, setServiceCategory] = useState(null);

  useEffect(()=> {
    setProductName(text);
    setServiceTitle(text);
  }, [text]);

  const initialState = () => {
    setPrice('');
    setBrand(null);
    setProductName('');
    setServiceTitle('');
    setProductCategory(null);
    setServiceCategory(null);
    setSelectedHeader('PRODUCT');
  };

  const onClickCreateItem = () => {
    if(selectedHeader === 'PRODUCT'){
      const params = {
        pid: nanoid(8),
        shopId: shop.shopId,
        itemPrice: parseFloat(price),
        categoryId: productCategory.value,
        brandId: brand.value,
        updatedTime: Date.now(),
        createdUserId: currentUser.empId,
        productName: productName.toLowerCase(),
      };
      createProduct(params, 'ADD_QUICK_ITEM');
    }else {
      const {params, serviceData} = mapCreateQuickService(categories, serviceCategory, price, serviceTitle);
      updateServiceCategory(params, 'ADD_QUICK_SERVICE', serviceData);
    }
    closeModal();
    initialState();
  };

  const onClickHeader = (type) => {
    setSelectedHeader(type);
  };

  const mapCategoryList = mapServiceCategory(categories?.categories || []);
  const mapProductCategoryList = mapCategory(productCategoryList);
  const mapProductBrandList = mapBrand(brandsList);

  const isValidProduct = !_.isEmpty(productName) && !_.isEmpty(productCategory) && !_.isEmpty(brand) && !_.isEmpty(price);
  const isValidService = !_.isEmpty(serviceTitle) && !_.isEmpty(serviceCategory) && !_.isEmpty(price);

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.selectDateAndTimeModal}
    >
      <CreateQuickItemContainer isValidProduct={isValidProduct} isValidService={isValidService} selectedHeader={selectedHeader}>
        <div onClick={() => closeModal()}>
          <div>{selectedHeader === 'PRODUCT' ? 'Create Quick Product' : 'Create Quick Service'}</div>
          <img src={CANCEL}/>
        </div>

        <div>
          <div onClick={() => onClickHeader('PRODUCT')}>{'Product'}</div>
          <div onClick={() => onClickHeader('SERVICE')}>{'Service'}</div>
        </div>

        {
          selectedHeader === 'PRODUCT' ?
            <div>
              <div>
                <div>
                  <div>{'Product Name'}</div>
                  <input
                    value={productName}
                    onChange={(number) => setProductName(number.target.value)}
                  />
                </div>
                <div>
                  <div>{'Category'}</div>
                  <Select
                    styles={style}
                    value={productCategory}
                    options={mapProductCategoryList}
                    onChange={(data) => setProductCategory(data)}
                    placeholder={<div className="createVehiclePlaceholderText">Category</div>}
                  />
                </div>
                <div>
                  <div>{'Brand'}</div>
                  <Select
                    styles={style}
                    value={brand}
                    options={mapProductBrandList}
                    onChange={(data) => setBrand(data)}
                    placeholder={<div className="createVehiclePlaceholderText">Brand</div>}
                  />
                </div>

                <div>
                  <div>{'Price'}</div>
                  <input
                    value={price}
                    onChange={(evt) => setNumberOnly(evt.target.value, price, setPrice)}
                  />
                </div>

              </div>
              <div>
                <button onClick={() => onClickCreateProductCategory()}>
                  <div>{'Add Category'}</div>
                </button>
                <button onClick={() => onClickCreateProductBrand()}>
                  <div>{'Add Brand'}</div>
                </button>
              </div>
            </div>
            :
            <div>
              <div>
                <div>
                  <div>{'Service Title'}</div>
                  <input
                    value={serviceTitle}
                    onChange={(number) => setServiceTitle(number.target.value)}
                  />
                </div>

                <div>
                  <div>{'Category'}</div>
                  <Select
                    styles={style}
                    value={serviceCategory}
                    options={mapCategoryList}
                    onChange={(data) => setServiceCategory(data)}
                    placeholder={<div className="createVehiclePlaceholderText">Service category</div>}
                  />
                </div>

                <div>
                  <div>{'Price'}</div>
                  <input
                    value={price}
                    onChange={(evt) => setNumberOnly(evt.target.value, price, setPrice)}
                  />
                </div>

              </div>
              <div>
                <button style={{marginBottom: 82}} onClick={() => onClickCreateServiceCategory()}>
                  <div>{'Add Category'}</div>
                </button>
              </div>

            </div>
        }
        <div>
          <button disabled={selectedHeader === 'PRODUCT' ? !isValidProduct : !isValidService} onClick={() => onClickCreateItem()}>
            <div>{selectedHeader === 'PRODUCT' ? 'Create Product' : 'Create Service'}</div>
          </button>
          <button onClick={() => closeModal()}>
            <div>Cancel</div>
          </button>
        </div>


      </CreateQuickItemContainer>
    </ReactModal>
  );
};
export default connect(
  (state) => ({
    shop: state.login.get('merchantShop'),
    currentUser: state.login.get('currentUser'),
    selectedCustomer: state.customer.get('selectedCustomer'),
    categories: state.service.get('categories'),
    productCategoryList: state.product.get('productCategoryList'),
    brandsList: state.product.get('brandsList'),
  }),
  {
    createProduct: Actions.product.createProduct,
    createQuickCustomer: Actions.orders.createQuickCustomer,
    updateServiceCategory: Actions.service.updateServiceCategory
  }
)(CreateQuickItemModel);
