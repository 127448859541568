import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "react-toggle/style.css";
import styles from "../../../components/Styles/ModelStyles.css";
import {
  ButtonWrapper,
  InputWrapper,
  TableModalWrapper,
} from "./Styled";
import CANCEL from "../../../../assets/icons/cancel.svg";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { Actions } from "../../../internal/app/Actions";
import { nanoid } from "nanoid";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    // overflow: 'auto',
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 265px)",
    // WebkitOverflowScrolling: 'touch',
    border: "1px solid rgba(112, 112, 112, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const RoomAreaModal = ({
  isOpen = true,
  closeModal = () => {},
  editTables,
  tableAreas,
  selectedArea,
  editAreaId,
}) => {
  const [area, setArea] = useState("");
  useEffect(() => {
    if (editAreaId) {
      const editArea = tableAreas.tableAreas.find(
        ({ areaId }) => areaId === editAreaId
      );

      setArea(editArea.areaName);
    } else {
      setArea('');
    }
  }, [editAreaId, tableAreas.tableAreas]);

  const onModifyArea = (isDelete = false) => {
    if (isDelete) {
      const areas = tableAreas.tableAreas.filter(({areaId}) => areaId !== editAreaId);
      tableAreas.tableAreas = areas;
    } else if (editAreaId) {
      const areaIndex = tableAreas.tableAreas.findIndex(
        ({ areaId }) => areaId === editAreaId
      );

      tableAreas.tableAreas[areaIndex].areaName = area;
    } else {
      tableAreas.tableAreas.push({
        areaName: area,
        areaId: nanoid(),
        tables: [],
      });
    }

    editTables({ ...tableAreas });
    closeModal();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      className={styles.addAppointmentModel}
    >
      <TableModalWrapper>
        <div className="modal-header">
          <div className={styles.addAppointmentHeaderText}>Add Floor Area</div>
          <img alt="icon" src={CANCEL} onClick={closeModal} />
        </div>

        <div className="modal-content">
          <Row>
            <Col>
              <Input
                {...{
                  label: "FLOOR AREA TITLE",
                  value: area,
                  onChange: setArea,
                  placeholder: "First Floor, Bar Area etc.",
                }}
              />
            </Col>
          </Row>

          <ButtonWrapper>
            <button onClick={() => onModifyArea()}>
              <div>{editAreaId ? "Save" : "Add"} Area</div>
            </button>
            <button onClick={() => closeModal()}>
              <div>Cancel</div>
            </button>
            {editAreaId ? (
              <button
                disabled={tableAreas.tableAreas.length < 2}
                className={`delete-button ${tableAreas.tableAreas.length < 2 ? 'delete' : ''}`}
                onClick={() => onModifyArea(true)}
              >
                <div>Delete</div>
              </button>
            ) : null}
          </ButtonWrapper>
        </div>
      </TableModalWrapper>
    </ReactModal>
  );
};

const Input = ({ label, value, onChange, placeholder }) => {
  return (
    <InputWrapper>
      <div>{label}</div>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </InputWrapper>
  );
};

export default connect(
  (state) => ({
    tableAreas: state.table.get("tableAreas"),
    selectedArea: state.table.get("selectedArea"),
  }),
  {
    editTables: Actions.table.editTables,
    saveTables: Actions.table.saveTables,
  }
)(RoomAreaModal);
