import Repository from "./Repository";
import {
  updateServiceCategory,
  createServiceCategory,
} from "./schema/Category.schema";
import {
  downloadServiceImages,
  setValueToLocalStorage,
  getValueFromLocalStorage,
  updateCurrentSCStorageData,
} from "../manager/CommonManager";
import StorageRepository from "./StorageRepository";
import { generateFileId } from "../manager/ImageManager";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import { isNetworkError } from "../manager/AppointmentManager";
import { searchCategoryServices } from "../manager/ProductManager";
class ServiceRepository extends Repository {
  async fetchShopCategories(shopId: string, retryCount = 1) {
    const isOnline = await this.isCheckOnline();
    if (!isOnline) {
      const list = await getValueFromLocalStorage("SHOP_CATEGORIES");
      if (list) {
        return JSON.parse(list);
      }

      return [];
    }

    const message = this.buildMessage({ shopId });
    try {
      const result = await this.apiPost({
        apiName: this.DASHBOARD_API,
        path: "/categories",
        message,
      });
      await setValueToLocalStorage(
        "SHOP_CATEGORIES",
        JSON.stringify(result.categoryResult)
      );
      downloadServiceImages(result.categoryResult);
      return result.categoryResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchShopCategories(shopId, ++retryCount);
      }

      return { error };
    }
  }

  async updateServiceCategory(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentSCStorageData(
          "SHOP_CATEGORIES",
          param,
          param.scid,
          "scid"
        );
        return newParam;
      }

      const result: any = await this.API.graphql(
        graphqlOperation(updateServiceCategory, { input: param })
      );
      return result.data.updateServiceCategory;
    } catch (error) {
      console.warn("update service error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.updateServiceCategory(param, ++retryCount);
      }
      return { error };
    }
  }

  async searchSalonServices(shopId, categoryId, searchText) {
    const isOnline = await this.isCheckOnline();
    if (!isOnline) {
      const list = await getValueFromLocalStorage("SHOP_CATEGORIES");
      if (list) {
        const categoryResult = JSON.parse(list);
        const result = await searchCategoryServices(
          categoryResult,
          categoryId,
          searchText
        );
        return result[0];
      }

      return {};
    }

    const message = this.buildMessage({ shopId, categoryId, searchText });
    try {
      const result = await this.apiPost({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: "/services/category-id",
        message,
      });

      return result.categoryResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async uploadServiceImage(file) {
    try {
      const fileId = generateFileId(file);
      const result: any = await StorageRepository.uploadImage(fileId, file);
      return result.key;
    } catch (error) {
      console.warn("uploading error", error);
      return { error };
    }
  }

  async createServiceCategory(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentSCStorageData(
          "SHOP_CATEGORIES",
          param,
          param.scid,
          "scid"
        );
        return newParam;
      }
      const result: any = await this.API.graphql(
        graphqlOperation(createServiceCategory, { input: param })
      );
      return result.data.createServiceCategory;
    } catch (error) {
      console.warn("create service error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createServiceCategory(param, ++retryCount);
      }
      return { error };
    }
  }
}

export default new ServiceRepository();
