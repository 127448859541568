import { object, string, number, date, InferType } from "yup";

export const serviceChargeSchema = object({
  "serviceCharges.isEnabled": string().required(),
  "serviceCharges.isGuestCountEnabled": string().required(),
  "serviceCharges.guestCount": number()
    .integer("Only intergers are accepted.")
    .typeError("Only intergers are accepted.")
    .min(0, "Min value is 0.")
    .max(100, "Max value is 100.")
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue.trim() === "" ? null : value
    ),
  "serviceCharges.isOrderMinAmount": string().required(),
  "serviceCharges.orderMinAmount": number()
  .integer("Only intergers are accepted.")
  .typeError("Only intergers are accepted.")
  .min(0, "Min value is 0.")
  .max(100000, "Max value is 100,000.")
  .nullable()
  .transform((value: string, originalValue: string) =>
    originalValue.trim() === "" ? null : value
  ),
  "serviceCharges.isBeforeDiscount": string().required(),
});
