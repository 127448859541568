import React, {useEffect, useState} from 'react';
import {CustomerCardContainer} from "../Styles/Styled";

const SelectedCustomerCard = (props) => {
  const {selectedCustomer} = props;
  return (
    <CustomerCardContainer>
      <div className={'customerText'}>Customer</div>
      <div className={'bottomWrapper'}>
        <div className={'nameText'}>{selectedCustomer.firstName + " " + selectedCustomer.lastName}</div>
        <div className={'mobileText'}>{selectedCustomer.mobileNumber}</div>
        <div className={'mobileText'}>{selectedCustomer.email}</div>
      </div>
    </CustomerCardContainer>
  )
};

export default SelectedCustomerCard;
