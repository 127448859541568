import React, {FC} from 'react';
import { LoadingWrapper } from './styled';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { connect } from 'react-redux';

const LoadingOverlay = ({ loadingAction }) => {
  if (loadingAction?.loading && loadingAction.action?.meta?.blocking) {
    const type = loadingAction.action?.meta.type;

    return (
      <LoadingWrapper>
        <div>
          <h1>{type === 'loading' ? 'Loading' : 'Processing'}</h1>
          <CircularProgress />
        </div>
      </LoadingWrapper>
    );
  }
  return null;
}
export default connect(
  state => ({
    loadingAction: state.common.get('loadingAction'),
  }),
)(LoadingOverlay);

