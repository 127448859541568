import { createAction } from "../../internal/app/AppUtils";
import { mapPaymentDetails } from "../../internal/manager/PaymentManager";
import AppointmentRepository from "../../internal/repos/AppointmentRepository";

export const ModuleEvents = {
  CHECKOUT: "CHECKOUT",
  DELETE_ORDER: "DELETE_ORDER",
  ADD_SPLIT_PAYMENT: "ADD_SPLIT_PAYMENT",
  DELETE_SHOP_ORDER: "DELETE_SHOP_ORDER",
  UPDATE_SPLIT_PAYMENT: "UPDATE_SPLIT_PAYMENT",
  CLEAR_CURRENT_APPOINTMENT: "CLEAR_CURRENT_APPOINTMENT",
};

const Actions = {
  clearCurrentAppointment: createAction(
    ModuleEvents.CLEAR_CURRENT_APPOINTMENT,
    async (type = true, options) => {
      return { type, options };
    },
    { loading: true }
  ),
  checkout: createAction(
    ModuleEvents.CHECKOUT,
    async (
      data: any,
      nextView = null,
      stopNavigation = false,
      type = null,
      restore = false
    ) => {
      let appointmentResult = null;
      const { selectedAppointment } = data;
      if (restore) {
        appointmentResult = await AppointmentRepository.createOrder(data);
      } else {
        const paymentResult = await mapPaymentDetails(data);
        if (selectedAppointment?.orderId) {
          appointmentResult = await AppointmentRepository.updateOrder(
            paymentResult
          );
        } else {
          appointmentResult = await AppointmentRepository.createOrder(
            paymentResult
          );
        }
      }
      return {
        appointmentResult,
        status: data.status,
        nextView,
        stopNavigation,
        type,
      };
    },
    { loading: true, blocking: true, type: "processing" }
  ),

  deleteOrder: createAction(
    ModuleEvents.DELETE_ORDER,
    async (params) => {
      if (params.orderId && params.shopId) {
        return await AppointmentRepository.deleteOrder(params);
      }
      return {};
    },
    { loading: true }
  ),
  addSplitPayment: createAction(
    ModuleEvents.ADD_SPLIT_PAYMENT,
    async (params) => params
  ),
  updateSplitPayments: createAction(
    ModuleEvents.UPDATE_SPLIT_PAYMENT,
    async (params) => params
  ),
  deleteShopOrder: createAction(
    ModuleEvents.DELETE_SHOP_ORDER,
    async (params) => {
      if (params.orderId && params.shopId) {
        return await AppointmentRepository.deleteOrder(params);
      }
      return {};
    }
  ),
};
export default Actions;
