import React, { useEffect, useState } from "react";
import {
  ProductModifierForm,
  ProductModifierInput,
  AddModifierSetWrapper,
  ProductModifierWrapper,
  AddModifierButtonWrapper,
  ProductModifierFormWrapper,
  ProductModifierCreateInput,
  ProductModifierToggleInput,
  ProductModifierNameWrapper,
  ProductModifierItemWrapper,
  ProductModifierHeaderWrapper,
} from "./Styled";
import "antd/dist/antd.css";
import "../Styles.style.css";
import { nanoid } from "nanoid";
import Toggle from "react-toggle";
import { connect } from "react-redux/es/index";
import _, { compact, find, get, isEmpty } from "lodash";
import CreatableSelect from "react-select/creatable";
import { Actions } from "../../../internal/app/Actions";
import BackIcon from "../../../../assets/svg/backIcon.svg";
import IconPlus from "../../../../assets/svg/icon-plus.svg";
import CloseIcon from "../../../../assets/images/closeIcon.png";
import { navigate } from "../../../internal/service/Navigation.service";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";
import { Formik, FieldArray } from "formik";
import { ModifierSchema } from "../Validator";
import { getModifierInitials } from "../../../internal/manager/ProductManager";

const style = {
  container: (base: any) => ({
    ...base,
    width: "544px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
  }),
  singleValue: (base: any) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
  }),
  placeholder: (base: any) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
    color: "#979797",
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
  }),
  control: (base: any, state: any) => ({
    ...base,
    height: "45px",
    fontWeight: 400,
    fontSize: "14px",
    maxWidth: "544px",
    color: "#434343",
    paddingLeft: "23px",
    borderRadius: "6px",
    paddingRight: "23px",
    fontFamily: "Montserrat",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    "&:hover": {
      border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    },
  }),
};

const ProductModifierView = (props: any) => {
  const {
    shop,
    modifiers,
    currentUser,
    productModifiers,
    setProductModifiers,
  } = props;

  const initials = {
    title: "",
    setName: "",
    id: nanoid(8),
    isMandatory: false,
    shopId: shop.shopId,
    createdTime: Date.now(),
    updatedTime: Date.now(),
    createdUser: currentUser.userId,
    items: [
      {
        price: 0,
        itemName: "",
        itemId: nanoid(8),
      },
    ],
  };
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectOptions, setSelectOptions] = useState<Array<any>>([]);
  const [initialValues, setInitialValues] = useState<Array<any>>([initials]);

  useEffect(() => {
    setIsEdit(!isEmpty(productModifiers));
    if (!isEmpty(productModifiers)) {
      setInitialValues(productModifiers);
    }
  }, [productModifiers]);

  useEffect(() => {
    if (!isEmpty(modifiers)) {
      const options = modifiers.map((item: any) => {
        return {
          value: item.id,
          label: item.setName,
        };
      });
      setSelectOptions([...options]);
    }
  }, [modifiers]);

  const handleSubmit = (values: any, { resetForm }: any) => {
    setProductModifiers(values.productModifiers);
    navigate("/add-product");
    resetForm();
  };

  return (
    <div className="addProductModifierWrapper">
      <TopNavigationBar viewName="Products" path="/menu" />
      <ProductModifierWrapper>
        <ProductModifierHeaderWrapper>
          <div onClick={() => navigate("/add-product")}>
            <img src={BackIcon} />
            <div>{isEdit ? "Edit Modifier" : "Add Modifier"}</div>
          </div>
        </ProductModifierHeaderWrapper>

        <ProductModifierFormWrapper>
          <Formik
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={ModifierSchema}
            initialValues={{ productModifiers: initialValues }}
          >
            {({ errors, values, submitCount, handleSubmit, setFieldValue }) => {
              return (
                <form id="productModifierForm" onSubmit={handleSubmit}>
                  <FieldArray name="productModifiers">
                    {({ insert, remove, push, replace }) => {
                      return (
                        <div>
                          {values?.productModifiers.map(
                            (productModifier: any, index: number) => {
                              const error: any = get(
                                errors,
                                `productModifiers.[${index}]`,
                                null
                              );
                              return (
                                <ProductModifierForm>
                                  {values?.productModifiers.length > 1 && (
                                    <img
                                      src={CloseIcon}
                                      className="imageRemoveIcon"
                                      onClick={() => remove(index)}
                                    />
                                  )}

                                  <div>
                                    <div>Modifier Set Name</div>
                                    <ProductModifierCreateInput>
                                      <CreatableSelect
                                        isClearable
                                        styles={style}
                                        options={selectOptions}
                                        value={{
                                          label: productModifier.setName,
                                          value: productModifier.id,
                                        }}
                                        placeholder={"Modifier set name*"}
                                        onChange={(
                                          newValue: any,
                                          actionMeta: any
                                        ) => {
                                          setFieldValue(
                                            `productModifiers[${index}].setName`,
                                            actionMeta.action === "clear"
                                              ? ""
                                              : newValue.label
                                          );
                                          if (
                                            actionMeta.action ===
                                            "select-option"
                                          ) {
                                            const modifier = find(
                                              modifiers,
                                              (item: any) =>
                                                item.id === newValue.value
                                            );
                                            replace(index, modifier);
                                          } else if (
                                            actionMeta.action === "clear"
                                          ) {
                                            const clearData: any =
                                              getModifierInitials(
                                                shop,
                                                currentUser
                                              );
                                            replace(index, clearData);
                                          }
                                        }}
                                      />
                                      {!isEmpty(error?.setName) &&
                                        submitCount > 0 && (
                                          <span>{error.setName}</span>
                                        )}
                                    </ProductModifierCreateInput>
                                    <div className="emptyLine" />
                                  </div>

                                  <div>
                                    <div>Modifier Title</div>
                                    <ProductModifierInput>
                                      <input
                                        value={productModifier.title}
                                        placeholder="Modifier Title"
                                        className="addProductOptionInput"
                                        onChange={(event: any) => {
                                          let text = event.target.value;
                                          setFieldValue(
                                            `productModifiers[${index}].title`,
                                            text
                                          );
                                        }}
                                      />
                                      {!isEmpty(error?.setDisplayName) &&
                                        submitCount > 0 && (
                                          <span>{error.setDisplayName}</span>
                                        )}
                                    </ProductModifierInput>
                                    <div className="emptyLine" />
                                  </div>
                                  <div>
                                    <div>Mandatory</div>
                                    <ProductModifierToggleInput>
                                      <Toggle
                                        checked={productModifier.isMandatory}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `productModifiers[${index}].isMandatory`,
                                            e.target.checked
                                          )
                                        }
                                        value={productModifier.isMandatory}
                                      />
                                    </ProductModifierToggleInput>
                                    <div className="emptyLine" />
                                  </div>

                                  <FieldArray
                                    name={`productModifiers.${index}.items`}
                                  >
                                    {({
                                      push: childPush,
                                      remove: childRemove,
                                    }) => {
                                      const itemErrors: any = compact(
                                        get(
                                          errors,
                                          `productModifiers.${index}.items`,
                                          null
                                        )
                                      );
                                      return (
                                        <div>
                                          <div>Modifier Items</div>
                                          <ProductModifierNameWrapper>
                                            <div>
                                              <div>{"Modifier Items"}</div>
                                              <div>{"Price"}</div>
                                              <div></div>
                                            </div>

                                            {productModifier.items.map(
                                              (item: any, cIndex: number) => {
                                                const isLastItem =
                                                  productModifier.items.length -
                                                    1 ===
                                                  cIndex;
                                                return (
                                                  <ProductModifierItemWrapper
                                                    isLast={isLastItem}
                                                  >
                                                    <input
                                                      value={item.itemName}
                                                      placeholder="Item Name"
                                                      onChange={(event) => {
                                                        let text =
                                                          event.target.value;
                                                        setFieldValue(
                                                          `productModifiers[${index}].items[${cIndex}].itemName`,
                                                          text
                                                        );
                                                      }}
                                                    />
                                                    <input
                                                      value={item.price}
                                                      placeholder="Item Price"
                                                      onChange={(event) => {
                                                        let text =
                                                          event.target.value;
                                                        setFieldValue(
                                                          `productModifiers[${index}].items[${cIndex}].price`,
                                                          text
                                                        );
                                                      }}
                                                    />
                                                    {productModifier.items
                                                      .length > 1 ? (
                                                      <img
                                                        src={CloseIcon}
                                                        onClick={() => {
                                                          childRemove(cIndex);
                                                        }}
                                                        className="imageRemoveIcon"
                                                      />
                                                    ) : (
                                                      <div />
                                                    )}
                                                  </ProductModifierItemWrapper>
                                                );
                                              }
                                            )}
                                            {!isEmpty(itemErrors) && (
                                              <span>
                                                {itemErrors[0]?.itemName ||
                                                  itemErrors[0]?.price}
                                              </span>
                                            )}
                                          </ProductModifierNameWrapper>
                                          <button
                                            className="optionButtonWrapper"
                                            onClick={() => {
                                              const item: any = {
                                                price: 0,
                                                itemName: "",
                                                itemId: nanoid(8),
                                              };
                                              childPush(item);
                                            }}
                                          >
                                            {"Add Item"}
                                          </button>
                                        </div>
                                      );
                                    }}
                                  </FieldArray>
                                </ProductModifierForm>
                              );
                            }
                          )}
                          <AddModifierSetWrapper>
                            <div />
                            <div
                              onClick={() => {
                                const modifier: any = getModifierInitials(
                                  shop,
                                  currentUser
                                );
                                push(modifier);
                              }}
                            >
                              <div>{"Add Modifier Set"}</div>
                              <img src={IconPlus} />
                            </div>
                            <div />
                          </AddModifierSetWrapper>
                          <AddModifierButtonWrapper>
                            <div
                              onClick={() => {
                                values.productModifiers.forEach((_, index) => {
                                  if (index === 0) {
                                    const modifier: any = getModifierInitials(
                                      shop,
                                      currentUser
                                    );
                                    replace(0, modifier);
                                  } else {
                                    remove(index);
                                  }
                                  setProductModifiers({});
                                });
                              }}
                            >
                              {"Remove All"}
                            </div>
                            <button type="submit">{"Save Modifier"}</button>
                          </AddModifierButtonWrapper>
                        </div>
                      );
                    }}
                  </FieldArray>
                </form>
              );
            }}
          </Formik>
        </ProductModifierFormWrapper>
      </ProductModifierWrapper>
    </div>
  );
};

export default connect(
  (state: any) => ({
    shop: state.login.get("merchantShop"),
    modifiers: state.product.get("modifiers"),
    currentUser: state.login.get("currentUser"),
    productModifiers: state.product.get("productModifiers"),
  }),
  {
    setProductModifiers: Actions.product.setProductModifiers,
  }
)(ProductModifierView);
