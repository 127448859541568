import English from '../locale/en.json';
import Sinhala from '../locale/si.json';

export const loadLocaleData = (locale) => {
  switch (locale) {
    case 'en-US':
      return English;
    case 'si-LK':
      return Sinhala;
    default:
      return English;
  }
};
