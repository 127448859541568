import React, { useState } from "react";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Row, Col } from "react-grid-system";
import { BookingContainer } from "../../../components/Styles/Styled";
import SplitPaymentCard from "../../../components/bookings/SplitPaymentCard";
import SplitBillDetails from "../../../components/bookings/SplitBillDetails";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";
import SplitPaymentDetailsCard from "../../../components/bookings/SplitPaymentDetailsCard";

const SplitBillView = (props) => {
  const { formatMessage: f } = useIntl();
  const [selectedPayment, setSelectedPayment] = useState<string>("");

  const onClickItem = (id: string) => {
    setSelectedPayment(id);
  };

  return (
    <BookingContainer>
      <TopNavigationBar viewName={f({ id: "SPLIT_BILL.TITLE" })} path="/menu" />

      <div className={"bookingBottomWrapper"}>
        <Row style={{ height: "100%", margin: 0 }}>
          <Col md={6} style={{ height: "100%", padding: "10px" }}>
            {isEmpty(selectedPayment) ? (
              <SplitPaymentCard
                selectedPayment={selectedPayment}
                onClickItem={(id) => onClickItem(id)}
              />
            ) : (
              <SplitPaymentDetailsCard
                selectedPayment={selectedPayment}
                onClickBack={() => setSelectedPayment('')}
              />
            )}
          </Col>
          <Col md={6} style={{ height: "100%", padding: "10px" }}>
            <SplitBillDetails />
          </Col>
        </Row>
      </div>
    </BookingContainer>
  );
};
export default connect((state) => ({}), {})(SplitBillView);
