import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-grid-system';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import 'antd/dist/antd.css';
import {connect} from "react-redux/es/index";
import '../Styles.style.css';
import {Actions} from "../../../internal/app/Actions";
import EditProductItemTopHeader from '../../../components/product/views/EditProductItemTopHeader';
import _ from "lodash";
import ServiceWarningModal from '../../../components/model/views/ServiceWarningModal';
import {navigate} from "../../../internal/service/Navigation.service";
import {nanoid} from "nanoid";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const SupplierAddView = (props) => {
  const { createSupplier, deleteSupplier, salonData, currentUser,
    updateSupplier, selectedSupplier, loadingAction } = props;

  const [supplierName, setSupplierName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [comments, setComment] = useState('');
  const [company, setCompany] = useState('');
  const [isOpenDiscardModel, setIsOpenDiscardModel] = useState(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(selectedSupplier)) {
      setSupplierName(_.upperFirst(selectedSupplier.supName));
      setMobileNumber(selectedSupplier.mobileNumber);
      setTelephoneNumber(selectedSupplier.telephoneNumber);
      setEmail(selectedSupplier.supEmail);
      setCompany(selectedSupplier.company);
      setAddress(selectedSupplier.address);
      setComment(selectedSupplier.comments);
    }
  }, []);

  const {loading} = loadingAction;
  const isEdit = !_.isEmpty(selectedSupplier);

  const onCreateSupplier = () => {
    const param = {
      supName: supplierName,
      supEmail: email,
      mobileNumber,
      telephoneNumber,
      company,
      address,
      comments,
      createdUserId: currentUser.id,
      shopId: salonData.shopId,
      updatedTime: Date.now()
    };

    if (!isEdit) {
      param.supId = nanoid();
      param.createdTime = Date.now();
      createSupplier(param);
    }

    if (isEdit) {
      param.supId = selectedSupplier.supId;
      updateSupplier(param);
    }
  };

  const onDeleteSupplier = () => {
    deleteSupplier({supId: selectedSupplier.supId});
  };

  const isValid = !_.isEmpty(supplierName) && !_.isEmpty(mobileNumber) && isValidPhoneNumber(mobileNumber);

  return (
    <div className="addCategoryMainContainer">
      <TopNavigationBar viewName="Products" path="/menu"/>
      <div className="addServiceViewContainer">
        <div className="addServiceViewWrapper">
          <EditProductItemTopHeader
            onCreateService={() => {
              if (isValid) {
                onCreateSupplier();
              }
            }}
            isEdit={isEdit}
            onBack={() => {
              if (isEdit) {
                setIsOpenDiscardModel(true);
              } else {
                navigate('/products-customized');
              }
            }}
            isValid={isValid}
            backBtnText={isEdit ? "Edit Supplier" : "Add Supplier"}
            buttonText={'Save Supplier'}
          />
          <Row>
            <Col xs={12}>
              <div className="serviceAddFormContainer">
                <div className="addServiceDetailsText">
                  Details
                </div>
                <div className="addServiceInputContainer" >
                  <Row>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Supplier Name*
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input onChange={(text) => setSupplierName(text.target.value)} value={supplierName} className='serviceTextInput' placeholder="Supplier Name"/>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '20px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Mobile*
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <div className="createSupplierPhoneInput">
                        <PhoneInput
                          value={mobileNumber}
                          onChange={phone => setMobileNumber(phone)}
                          placeholder="Mobile"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '20px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Telephone
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <div className="createSupplierPhoneInput">
                        <PhoneInput
                          value={telephoneNumber}
                          onChange={phone => setTelephoneNumber(phone)}
                          placeholder="Telephone"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '20px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Email
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input onChange={(text) => setEmail(text.target.value)} value={email} className='serviceTextInput' placeholder="Email" />
                    </Col>
                  </Row>
                  <Row style={{marginTop: '20px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Address
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input onChange={(text) => setAddress(text.target.value)} value={address} className='serviceTextInput' placeholder="Address" />
                    </Col>
                  </Row>
                  <Row style={{marginTop: '20px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Company
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input onChange={(text) => setCompany(text.target.value)} value={company} className='serviceTextInput' placeholder="Company" />
                    </Col>
                  </Row>
                  <Row style={{marginTop: '20px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Comment
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <textarea onChange={(text) => setComment(text.target.value)} value={comments} className='serviceTextAreaInput' placeholder="Comment"/>
                    </Col>
                  </Row>
                  {
                    isEdit ?
                      <Row style={{marginTop: '20px'}}>
                        <Col xs={2} className="serviceTitleCol" />
                        <Col xs={10} className="serviceTitleColInput">
                          <div onClick={() => setIsOpenDeleteModel(true)} className="serviceDeleteBtnContainer">
                            <div className="serviceDeleteBtnText">
                              Delete Supplier
                            </div>
                          </div>
                        </Col>
                      </Row>
                      : null
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ServiceWarningModal
        isOpen={isOpenDiscardModel}
        headerText={'Are you sure?'}
        bodText={'All your changes will be discarded'}
        cancelBtnText={'Discard'}
        onCancel={() => {
          setIsOpenDiscardModel(false);
          navigate('/products-customized');
        }}
        closeModal={() => setIsOpenDiscardModel(false)}
      />
      <ServiceWarningModal
        isOpen={isOpenDeleteModel}
        headerText={'Warning!'}
        bodText={'Your supplier will be deleted and no longer\n available to order for the users'}
        cancelBtnText={'Delete'}
        onCancel={() => {
          onDeleteSupplier();
          setIsOpenDeleteModel(false);
        }}
        closeModal={() => setIsOpenDeleteModel(false)}
      />
    </div>
  );
};

export default connect(
  state => ({
    selectedSupplier: state.product.get('selectedSupplier'),
    salonData: state.login.get('salonData'),
    currentUser: state.login.get('currentUser'),
    loadingAction: state.common.get('loadingAction'),
  }),
  {
    createSupplier: Actions.product.createSupplier,
    updateSupplier: Actions.product.updateSupplier,
    deleteSupplier: Actions.product.deleteSupplier
  }
)(SupplierAddView);
