import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import {Row, Col} from 'react-grid-system';
import EditIcon from '../../../../assets/svg/categoryEditIcon.svg';
import {Actions} from "../../../internal/app/Actions";
import '../Styles.style.css';

const SupplierTableView = (props) => {
    const {supplierList, navigateEditSupplierView, fetchSalonSuppliers, salonData} = props;

  useEffect(() => {
    if (!_.isEmpty(salonData)) {
      fetchSalonSuppliers(salonData.shopId);
    }
  }, []);

    return (
      <div className="categoryTableContainer">
        <div className="tableHeaderContainer">
          <Row>
            <Col xs={2} className="categoryTableHeaderTextContainer">
              <div className="categoryTableHeaderText">
                Supplier Name
              </div>
            </Col>
            <Col xs={3} className="categoryTableHeaderTextContainer">
              <div className="categoryTableHeaderText">
                ID
              </div>
            </Col>
            <Col xs={2} className="categoryTableHeaderTextContainer">
              <div className="categoryTableHeaderText">
                Supplier Email
              </div>
            </Col>
            <Col xs={2} className="categoryTableHeaderTextContainer">
              <div className="categoryTableHeaderText">
                Phone Number
              </div>
            </Col>
            <Col xs={2} className="categoryTableHeaderTextContainer">
              <div className="categoryTableHeaderText">
                Company
              </div>
            </Col>
            <Col xs={1} />
          </Row>
        </div>
        <div className="categoryTableBodyContainer">
          {
            supplierList.supplierList.map((supplier) => {
              return (
                <div className="categoryTableBody">
                  <Row>
                    <Col xs={2} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableBodyText">
                        {supplier.supName}
                      </div>
                    </Col>
                    <Col xs={3} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableBodyText">
                        {supplier.supId}
                      </div>
                    </Col>
                    <Col xs={2} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableBodyText">
                        {supplier.supEmail}
                      </div>
                    </Col>
                    <Col xs={2} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableBodyText">
                        {supplier.mobileNumber}
                      </div>
                    </Col>
                    <Col xs={2} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableBodyText">
                        {supplier.company}
                      </div>
                    </Col>
                    <Col xs={1} className="categoryTableHeaderTextContainer">
                      <div className="categoryEditIcon" onClick={() => navigateEditSupplierView(supplier)}>
                        <img src={EditIcon}/>
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            })
          }
        </div>
      </div>
    );
  }
;
export default connect(
  state => ({
    supplierList: state.product.get('supplierList'),
    loadingAction: state.common.get('loadingAction'),
    salonData: state.login.get('salonData'),
  }),
  {
    navigateEditSupplierView: Actions.product.navigateEditSupplierView,
    fetchSalonSuppliers: Actions.product.fetchSalonSuppliers
  }
)(SupplierTableView);

