import { filter } from "lodash";
import { ModuleEvents } from "./Actions";
import { Actions } from "../../internal/app/Actions";
import { showAlert } from "../../internal/manager/AlertManager";

const Handlers = {
  [ModuleEvents.CREATE_OFFER]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      showAlert("Offer added successfully");
    } else {
      showAlert("Error occurred while adding offer, contact support", "error");
    }
  },
  [ModuleEvents.UPDATE_OFFER]: ({ dispatch, payload, appState }) => {
    if (payload && !payload.error) {
      showAlert("Offer updated successfully");
    } else {
      showAlert(
        "Error occurred while updating offer, contact support",
        "error"
      );
    }
  },

  [ModuleEvents.DELETE_OFFER]: ({ dispatch, payload, appState }) => {
    if (payload) {
      showAlert("Offer deleted successfully");
    } else {
      showAlert("Error occurred while deleted offer, contact support", "error");
    }
  },

  [ModuleEvents.DELET_ORDER_OFFER]: ({ dispatch, payload, appState }) => {
    if (payload && payload.offerType === "PRODUCT") {
      const purchaseProducts = appState.product.get("purchaseProducts");
      payload.offerItems.forEach((item: any) => {
        const products = filter(
          purchaseProducts,
          (product: any) => product.id === item.pid && product.offerProduct
        );
        products.forEach((data: any) => {
          dispatch(Actions.product.deleteOfferProduct(data));
        });
      });
    }
  },
};

export default Handlers;
