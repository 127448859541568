import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import {Row, Col} from 'react-grid-system';
import EditIcon from '../../../../assets/svg/categoryEditIcon.svg';
import {Actions} from "../../../internal/app/Actions";
import '../Styles.style.css';

const BrandsTableView = (props) => {
    const {brandsList, navigateEditBrandView, selectProductBrand} = props;

    const navigateEditBrandScreen = (brand)=> {
      navigateEditBrandView(brand);
      selectProductBrand(brand);
    };

    return (
      <div className="categoryTableContainer">
        <div className="tableHeaderContainer">
          <Row>
            <Col xs={3} className="categoryTableHeaderTextContainer">
              <div className="categoryTableHeaderText">
                Brand
              </div>
            </Col>
            <Col xs={3} className="categoryTableHeaderTextContainer">
              <div className="categoryTableHeaderText">
                ID
              </div>
            </Col>
            <Col xs={3} className="categoryTableHeaderTextContainer">
              <div className="categoryTableHeaderText">
                Min Price(LKR)
              </div>
            </Col>
            <Col xs={2} className="categoryTableHeaderTextContainer">
              <div className="categoryTableHeaderText">
                # Products
              </div>
            </Col>
            <Col xs={1} />
          </Row>
        </div>
        <div className="categoryTableBodyContainer">
          {
            brandsList.map((brand) => {
              return (
                <div className="categoryTableBody">
                  <Row>
                    <Col xs={3} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableBodyText">
                        {brand.brandName}
                      </div>
                    </Col>
                    <Col xs={3} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableBodyText">
                        {brand.id}
                      </div>
                    </Col>
                    <Col xs={3} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableBodyText">
                        {_.get(brand, 'minPrice', 0)}
                      </div>
                    </Col>
                    <Col xs={2} className="categoryTableHeaderTextContainer">
                      <div className="categoryTableBodyText">
                        {brand.totalProductCount}
                      </div>
                    </Col>
                    <Col xs={1} className="categoryTableHeaderTextContainer">
                      <div className="categoryEditIcon" onClick={() => navigateEditBrandScreen(brand)}>
                        <img src={EditIcon}/>
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            })
          }
        </div>
      </div>
    );
  }
;
export default connect(
  state => ({
    brandsList: state.product.get('brandsList'),
    loadingAction: state.common.get('loadingAction')
  }),
  {
    selectProductBrand: Actions.product.selectProductBrand,
    navigateEditBrandView: Actions.product.navigateEditBrandView,
  }
)(BrandsTableView);

