import React from "react";
import { filter } from "lodash";
import ReactDomServer from "react-dom/server";
import {
  PrintTypes,
  DeviceTypes,
} from "../../components/model/views/UpsertPrinter";
import GraphqlFunctions from "../service/Graphql.functions";
import PaymentReciept from "../../modules/orders/views/PrintReciept80";
import KitchenReciept from "../../modules/orders/views/PrintReciept51";
class PrinterRepository {
  async printReciept(details: any) {
    const {
      params,
      // image,
      // selectedVehicle,
      // selectedCustomer,
      // purchaseProducts,
      // purchaseServices,
      // givenPrice,
      shopData,
      // total,
      // subTotal,
      // appointmentDiscounts,
    } = details;

    const imageId = shopData.billImage;
    let base64Image: any = null;
    if (window?.api) {
      base64Image = await new Promise(async (res) => {
        if (imageId) {
          await window.api.getFilePath(imageId, async (result) => {
            const newImage = await new Buffer(result).toString("base64");
            res(newImage);
          });
        }
        res(null);
      });
    }
    const str = ReactDomServer.renderToStaticMarkup(
      <PaymentReciept
        details={details}
        base64Image={base64Image}
        merchantShop={shopData}
      />
    );
    this.dispatchPrint(str, PrintTypes.RECEIPT);
  }

  async printKitchen(details: any) {
    const {
      // params,
      // image,
      // selectedVehicle,
      // selectedCustomer,
      // purchaseProducts,
      // purchaseServices,
      // givenPrice,
      shopData,
      // total,
      // subTotal,
      // appointmentDiscounts,
    } = details;
    const str = ReactDomServer.renderToStaticMarkup(
      <KitchenReciept kitchen details={details} />
    );
    this.dispatchPrint(str, PrintTypes.KITCHEN);
  }

  async printBar(details: any) {
    const { shopData } = details;

    const str = ReactDomServer.renderToStaticMarkup(
      <KitchenReciept bar details={details} />
    );
    this.dispatchPrint(str, PrintTypes.BAR);
  }

  async dispatchPrint(content: string, type: PrintTypes) {
    // const printers: any = await GraphqlService.fetchPrinters();
    const printers: any = await GraphqlFunctions.commonGraphqlService(
      "FETCH_PRINTERS"
    );
    const prints: any = filter(printers, ({ printType }) => printType === type);
    await Promise.all(
      prints.map(async ({ deviceType, usb, ipAddress }) => {
        const params = {
          content,
          deviceType,
          receiptType: type,
          destination: deviceType === DeviceTypes.USB ? usb : ipAddress,
        };
        // await GraphqlService.printReceipt(params);
        await GraphqlFunctions.commonGraphqlService("PRINT_RECEIPT", params);
      })
    );
    // const printers = Storage.getItem("printers");
    // const prints = printers.filter(
    //   ({ printType }) => printType === type
    // );
    // console.log(prints, content)

    // prints.forEach(({ deviceType, usb, ipAddress }) => {
    //   // @ts-ignore
    //   window.api &&
    //   // @ts-ignore
    //     window.api.printPos({
    //       content,
    //       destination: deviceType === DeviceTypes.USB ? usb : ipAddress,
    //       deviceType,
    //       receiptType: type
    //     });
    // });
  }

  printPOS(content: string) {
    this.dispatchPrint(content, PrintTypes.RECEIPT);
  }
}

export default new PrinterRepository();
