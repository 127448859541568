import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import ReactModal from "react-modal";
import styles from "../../Styles/ModelStyles.css";
import { getCurrencyWithPrice } from "../../../internal/manager/PaymentManager";
import { getAppointmentDateTime } from "../../../internal/manager/AppointmentManager";

const customStyles = {
  content: {
    top: "20%",
    outline: "none",
    borderRadius: "5px",
    position: "absolute",
    left: "calc(50% - 165px)",
    border: "1px solid rgba(112, 112, 112, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const GenerateReportModal = (props: any) => {
  const { isOpen, closeModal, salon, appointment } = props;
  const nodeRef = useRef(null);
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const handleClickOutside = (event) => {
    return closeModal();
  };

  useEffect(() => {
    if (!_.isEmpty(appointment)) {
      const dateTime = getAppointmentDateTime(appointment);
      let duration = 0;
      appointment.serviceDetails.forEach((item) => {
        let serviceSplit = _.split(item.duration, ":");
        duration += parseInt(serviceSplit[0]) * 60 + parseInt(serviceSplit[1]);
      });
      const total = appointment.serviceDetails
        .map((item) => item.price)
        .reduce((prev, curr) => prev + curr, 0);
      const currencyPrice = getCurrencyWithPrice(total, salon.currency);
      const startTime = moment(dateTime.time, "hh:mm").format("hh:mm A");
      const endTime = moment(dateTime.time, "hh:mm")
        .add(duration, "minutes")
        .format("hh:mm A");
      setPrice(currencyPrice);
      setTime(`${startTime} - ${endTime}`);
      setDate(moment(dateTime.date, "DD/MM/YYYY").format("MMM DD, YYYY"));
    }
  }, [appointment]);

  return (
    <ReactModal
      isOpen={isOpen}
      nodeRef={nodeRef}
      style={customStyles}
      className={styles.generateReportModal}
    >
      <div className={styles.generateReportModalWrapper}>
        <div>
          <div className={styles.generateReportDetailsWrapper}>
            <div className={styles.generateReportDetailsSalonText}>
              {salon.salonName}
            </div>
            <div className={styles.generateReportDetailsAddressText}>
              {salon.address}
            </div>
            <div className={styles.generateReportDetailsBottomLine} />
          </div>
          <div className={styles.generateReportTimesWrapper}>
            <div className={styles.generateReportTimesWrapperInfo}>
              <div className={styles.generateReportDetailsTimeText}>
                {"Date"}
              </div>
              <div className={styles.generateReportDetailsTimeText}>{date}</div>
            </div>
            <div className={styles.generateReportTimesWrapperInfo}>
              <div className={styles.generateReportDetailsTimeText}>
                {"Time"}
              </div>
              <div className={styles.generateReportDetailsTimeText}>{time}</div>
            </div>
          </div>
        </div>

        <div>
          <div className={styles.generateReportTimesWrapper}>
            <div className={styles.generateReportServiceWrapper}>
              <div className={styles.generateReportDetailsSummaryText}>
                {"Sales summary"}
              </div>
              {!_.isEmpty(appointment) &&
                appointment.serviceDetails.map((item) => {
                  const currencyPrice = getCurrencyWithPrice(
                    item.price,
                    salon.currency
                  );
                  return (
                    <div className={styles.generateReportTimesWrapperInfo}>
                      <div className={styles.generateReportDetailsServiceText}>
                        {item.title || item.serviceTitle}
                      </div>
                      <div className={styles.generateReportDetailsServiceText}>
                        {currencyPrice}
                      </div>
                    </div>
                  );
                })}
              <div className={styles.generateReportTimesWrapperInfo}>
                <div className={styles.generateReportDetailsSaleText}>
                  {"Gross Sales"}
                </div>
                <div className={styles.generateReportDetailsSaleText}>
                  {price}
                </div>
              </div>
            </div>

            <div className={styles.generateReportServiceWrapper}>
              <div className={styles.generateReportTimesWrapperInfo}>
                <div className={styles.generateReportDetailsAddressText}>
                  {"Cash payments"}
                </div>
                <div className={styles.generateReportDetailsAddressText}>
                  {0}
                </div>
              </div>
              <div className={styles.generateReportTimesWrapperInfo}>
                <div className={styles.generateReportDetailsAddressText}>
                  {"Card payments"}
                </div>
                <div className={styles.generateReportDetailsAddressText}>
                  {0}
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={() => {}}
            className={styles.generateReportStylistWrapper}
          >
            <div className={styles.generateReportStylistText}>
              {"Send to Stylist"}
            </div>
          </div>
          <div onClick={() => {}} className={styles.generateReportPrintWrapper}>
            <div className={styles.generateReportPrintText}>
              {"Print Report"}
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
export default GenerateReportModal;
