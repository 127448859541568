import React, { Component } from "react";
import _, { get } from "lodash";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { Container } from "react-grid-system";
import styles from "../../Styles/ModelStyles.css";
import {
  mapServiceCalculator,
  getProductCalculatorText,
} from "../../../internal/manager/CalculatorManager";
import { Actions } from "../../../internal/app/Actions";
import CANCEL from "../../../../assets/icons/cancel.svg";

const customStyles = {
  content: {
    top: "15%",
    outline: "none",
    overflow: "auto",
    borderRadius: "4px",
    position: "absolute",
    left: "calc(50% - 285px)",
    WebkitOverflowScrolling: "touch",
    border: "1px solid rgba(112, 112, 112, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

class ProductCalculatorModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      symbol1: "",
      symbol2: "",
      servicePrice: "",
      enteredNumber1: "",
      enteredNumber2: "",
    };
  }

  componentDidMount() {
    const { selectedProduct, selectedItem }: any = this.props;

    if (!_.isEmpty(selectedProduct)) {
      const { symbol1, symbol2, tax, price, quantity, itemPrice } =
        selectedProduct;
      this.setState({
        symbol1,
        symbol2,
        total: price,
        itemPrice: itemPrice,
        enteredNumber1: quantity,
        enteredNumber2: tax > 0 ? tax : "",
      });
    } else {
      this.setState({ itemPrice: selectedItem.price });
    }
  }

  setEnteredNumber = (
    number: any,
    enteredNumber1: any,
    enteredNumber2: any
  ) => {
    const { symbol1, symbol2, itemPrice }: any = this.state;
    const mapData = mapServiceCalculator(
      symbol1,
      symbol2,
      number,
      enteredNumber1,
      enteredNumber2,
      itemPrice
    );
    if (!_.isEmpty(mapData)) {
      this.setState(mapData);
    }
  };

  getProductText = (
    symbol1: any,
    symbol2: any,
    enteredNumber1: any,
    enteredNumber2: any
  ) => {
    const { selectedItem, selectedProduct }: any = this.props;
    return getProductCalculatorText(
      symbol1,
      symbol2,
      enteredNumber1,
      enteredNumber2,
      selectedItem,
      selectedProduct
    );
  };

  closeModal = () => {
    const { openProductKeyPad, removeSelectedProduct, selectProductItem }: any =
      this.props;
    openProductKeyPad(false);
    removeSelectedProduct();
    selectProductItem({});
  };

  doneCalculateProduct = () => {
    const {
      selectedItem,
      selectedProduct,
      selectProductItem,
      setPurchaseProducts,
      removeSelectedProduct,
    }: any = this.props;
    const {
      total,
      symbol1,
      symbol2,
      itemPrice,
      enteredNumber1,
      enteredNumber2,
    }: any = this.state;
    const param = {
      symbol2,
      type: "PRODUCT",
      itemPrice: itemPrice,
      image: selectedItem.image,
      symbol1: symbol1 ? symbol1 : "X",
      price: total > 0 ? total : itemPrice,
      productName: selectedItem.productName,
      sendToBar: !!selectedProduct.sendToBar,
      id: selectedItem.id || selectedProduct.id,
      sendPrepTicket: selectedProduct.sendPrepTicket,
      sendToKitchen: !!selectedProduct.sendToKitchen,
      warranty: get(selectedProduct, "warranty", {}),
      modifiers: get(selectedProduct, "modifiers", []),
      tax:
        symbol1 === "+" ? enteredNumber1 : symbol2 === "+" ? enteredNumber2 : 0,
      quantity:
        symbol1 === "X" ? enteredNumber1 : symbol2 === "X" ? enteredNumber2 : 1,
    };
    setPurchaseProducts([param]);
    removeSelectedProduct();
    selectProductItem({});
  };

  render() {
    const { isOpen, closeModal }: any = this.props;
    const {
      total,
      symbol1,
      symbol2,
      itemPrice,
      enteredNumber1,
      enteredNumber2,
    }: any = this.state;

    return (
      <div>
        <ReactModal
          isOpen={isOpen}
          style={customStyles}
          className={styles.Calmodel}
        >
          <div>
            <div className={styles.modelStyle} onClick={() => closeModal()}>
              <div className={styles.modelTitle}>KeyPad</div>
              <img style={{ paddingRight: "14px" }} alt="icon" src={CANCEL} />
            </div>

            <div className={styles.calculatorInputFiledRow}>
              <div className={styles.modelSubTitle}>
                {this.getProductText(
                  symbol1,
                  symbol2,
                  enteredNumber1,
                  enteredNumber2
                )}
              </div>
              <div
                className={styles.modelSubTitle}
                style={{ paddingRight: "14px" }}
              >
                {total > 0 ? "$" + total : "$" + itemPrice}
              </div>
            </div>

            <Container fluid style={{ marginTop: 30 }}>
              <div className={styles.calculationModalContainer}>
                <div className={styles.calculationBoxContainer}>
                  <div
                    onClick={() => {
                      this.setEnteredNumber(1, enteredNumber1, enteredNumber2);
                    }}
                    className={styles.calculationBox}
                  >
                    1
                  </div>
                  <div
                    onClick={() => {
                      this.setEnteredNumber(4, enteredNumber1, enteredNumber2);
                    }}
                    className={styles.calculationBox}
                  >
                    4
                  </div>
                  <div
                    onClick={() => {
                      this.setEnteredNumber(7, enteredNumber1, enteredNumber2);
                    }}
                    className={styles.calculationBox}
                  >
                    7
                  </div>
                  <div
                    onClick={() => {
                      if (symbol1 && !symbol2 && symbol1 !== "X") {
                        this.setState({ symbol2: "X" });
                      } else {
                        this.setState({ symbol1: "X" });
                      }
                    }}
                    className={styles.calculationBox}
                  >
                    &times;
                  </div>
                </div>
                <div className={styles.middleCalculationBox}>
                  <div
                    onClick={() => {
                      this.setEnteredNumber(2, enteredNumber1, enteredNumber2);
                    }}
                    className={styles.calculationBox}
                  >
                    2
                  </div>
                  <div
                    onClick={() => {
                      this.setEnteredNumber(5, enteredNumber1, enteredNumber2);
                    }}
                    className={styles.calculationBox}
                  >
                    5
                  </div>
                  <div
                    onClick={() => {
                      this.setEnteredNumber(8, enteredNumber1, enteredNumber2);
                    }}
                    className={styles.calculationBox}
                  >
                    8
                  </div>
                  <div
                    onClick={() => {
                      this.setEnteredNumber(0, enteredNumber1, enteredNumber2);
                    }}
                    className={styles.calculationBox}
                  >
                    0
                  </div>
                </div>
                <div className={styles.middleCalculationBox}>
                  <div
                    onClick={() => {
                      this.setEnteredNumber(3, enteredNumber1, enteredNumber2);
                    }}
                    className={styles.calculationBox}
                  >
                    3
                  </div>
                  <div
                    onClick={() => {
                      this.setEnteredNumber(6, enteredNumber1, enteredNumber2);
                    }}
                    className={styles.calculationBox}
                  >
                    6
                  </div>
                  <div
                    onClick={() => {
                      this.setEnteredNumber(9, enteredNumber1, enteredNumber2);
                    }}
                    className={styles.calculationBox}
                  >
                    9
                  </div>
                  <div
                    onClick={() => {
                      if (symbol1 && symbol1 !== "+") {
                        this.setState({ symbol2: "+" });
                      } else {
                        this.setState({ symbol1: "+" });
                      }
                    }}
                    className={styles.calculationBox}
                  >
                    +
                  </div>
                </div>
                <div className={styles.middleCalculationBox}>
                  <div
                    onClick={() =>
                      this.setState({
                        enteredNumber1: "",
                        enteredNumber2: "",
                        symbol1: "",
                        symbol2: "",
                        total: "",
                      })
                    }
                    className={styles.calculationCText}
                  >
                    C
                  </div>
                  <div
                    onClick={() => {
                      this.doneCalculateProduct();
                      this.closeModal();
                      this.setState({
                        symbol1: "",
                        symbol2: "",
                        enteredNumber1: "",
                        enteredNumber2: "",
                        total: 0,
                        itemPrice: itemPrice,
                      });
                    }}
                    className={styles.calculationCText}
                  >
                    DONE
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    purchaseProducts: state.product.get("purchaseProducts"),
    selectedProduct: state.product.get("selectedProduct"),
    openProductKeyPad: Actions.product.openProductKeyPad,
    selectedItem: state.product.get("choossedProductItem"),
  }),
  {
    selectProduct: Actions.product.selectProduct,
    removeSelectedProduct: Actions.product.removeSelectedProduct,
    selectProductItem: Actions.product.selectProductItem,
    setPurchaseProducts: Actions.product.setPurchaseProducts,
  }
)(ProductCalculatorModel);
