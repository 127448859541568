import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Actions} from "../../../internal/app/Actions";
import _ from 'lodash';
import SalesReportCard from '../../../components/card/SalesReportCard';
import {
  SalesDetailsContainer,
  SalesDetailsReportCardContainer,
  SalesDetailsTableContainer,
} from '../../../components/Styles/Styled';
import ServicesGrowthWidget from "../../../components/chart/ServiceGrowthChart";
import IconCalendar from "../../../../assets/svg/IconCalendar.svg";
import styles from '../../../components/Styles/Styles.css';
import {DateRangePicker} from 'react-date-range';
import '../../../components/Styles/calendar-styles.module.css';
import '../../../components/Styles/calendar-default.module.css';
import moment from 'moment';
import ReportDetails from "../../../components/table/views/ReportDetails";
import {getDateRangeType, getReportTableColumn} from "../../../internal/manager/ReportsManager";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const today = moment(new Date(Date.now()).toDateString()).valueOf();

const SalesDetails = (props) => {
  const {shop, loadingAction, fetchReportForDate, fetchReportForDateRange, fetchAppointmentsForDate, fetchAppointmentsForDateRange, appointmentsForDate, appointmentsForDateRange} = props;
  const {action, loading} = loadingAction;

  const [openDateRange, setOpenDateRange] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });
  const [isSelectedDateRange, setIsSelectedDateRange] = useState(false);
  const [selectedType, setSelectedType] = useState('DAY');
  const [selectedDate, setSelectedDate] = useState(today);
  const [labels, setLabels] = useState([]);
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    if(!_.isEmpty(appointmentsForDateRange)){
      const label = getReportTableColumn(selectedDateRange, appointmentsForDateRange);
      setLabels(label);
      setAppointments(selectedType === 'DAY' ? [] : appointmentsForDateRange)
    }
  }, [appointmentsForDateRange]);

  useEffect(() => {
    if(!_.isEmpty(appointmentsForDate)){
      const label = getReportTableColumn(selectedDateRange, appointmentsForDate);
      setLabels(label);
      setAppointments(selectedType === 'DAY' ? appointmentsForDate : [])
    }
  }, [appointmentsForDate]);

  useEffect(() => {
    fetchAppointmentsForDate(shop.shopId, selectedDate);
    fetchReportForDate(shop.shopId, selectedDate)
  }, []);

  const handleSelect = (ranges) => {
    setIsSelectedDateRange(true);
    setSelectedDateRange(ranges.selection);
  };

  const toggleOpenDateRange = () => {
    setOpenDateRange(!openDateRange);
  };

  const onClickFilter = () => {
    const dateRangeValue = getDateRangeType(selectedDateRange);
    setSelectedType(dateRangeValue.type);
    if (dateRangeValue.type === 'DAY') {
      setSelectedDate(dateRangeValue.selectedDate);
      fetchAppointmentsForDate(shop.shopId, dateRangeValue.selectedDate);
      fetchReportForDate(shop.shopId, dateRangeValue.selectedDate)
    } else {
      const params = {
        shopId: shop.shopId,
        startMonth: dateRangeValue.startMonth,
        endMonth: dateRangeValue.endMonth,
        startDay: dateRangeValue.startDay,
        endDay: dateRangeValue.endDay
      };
      fetchAppointmentsForDateRange(shop.shopId, dateRangeValue.start, dateRangeValue.end);
      fetchReportForDateRange(params)
    }
    toggleOpenDateRange();
  };

  return (
    <SalesDetailsContainer>
      <SalesDetailsReportCardContainer>
        <SalesReportCard type={'SALE'} title={'Gross Sales'}/>
        <SalesReportCard type={'PRODUCT'} title={'Sold Products'}/>
        <SalesReportCard type={'APPOINTMENT'} title={'Total Appointments'}/>
      </SalesDetailsReportCardContainer>

      <div>
        <div onClick={() => toggleOpenDateRange()}>
          <div>{isSelectedDateRange ? moment(selectedDateRange.startDate).format('DD-MM-YYYY') : 'Start date'}</div>
          <div>{isSelectedDateRange ? moment(selectedDateRange.endDate).format('DD-MM-YYYY') : 'End date'}</div>
          <img src={IconCalendar}/>
        </div>
      </div>

      {
        openDateRange ?
          <div className={styles.dateRangePickerWrapper}>
            <DateRangePicker
              ranges={[selectedDateRange]}
              onChange={handleSelect}
              className={styles.dateRangePickerInput}
            />
            <div onClick={() => onClickFilter()} className={styles.dateRangePickerText}>{'Ok'}</div>
          </div>
          : null
      }

      {
        loading && (action.type === 'FETCH_APPOINTMENTS_FOR_DATE' || action.type === 'FETCH_APPOINTMENTS_FOR_DATE_RANGE') ?
          <div style={{position: 'absolute', top: '50%', left: '50%'}}>
            <CircularProgress color="primary" size={60} value={5}/>
          </div>
          : null
      }
      <SalesDetailsTableContainer>
        {
          !_.isEmpty(appointments) ?
            <ServicesGrowthWidget
              shop={shop}
              labels={labels}
              type={selectedType}
              appointments={appointments}
              dateRange={selectedDateRange}
            />
            :
            <div className={'salesDetailsTableEmptyAppointment'}>
              <div>{'No appointments found'}</div>
              <div>{'There are no appointments for this selected date!'}</div>
            </div>
        }
        {
          !_.isEmpty(appointments) ?
            <ReportDetails appointments={appointments}/>
            : null
        }
      </SalesDetailsTableContainer>
    </SalesDetailsContainer>
  );
};
export default connect(
  state => ({
    shop: state.login.get('merchantShop'),
    loadingAction: state.common.get('loadingAction'),
    appointmentsForDate: state.reports.get('appointmentsForDate'),
    appointmentsForDateRange: state.reports.get('appointmentsForDateRange'),
  }),
  {
    fetchReportForDate: Actions.reports.fetchReportForDate,
    fetchReportForDateRange: Actions.reports.fetchReportForDateRange,
    fetchAppointmentsForDate: Actions.reports.fetchAppointmentsForDate,
    fetchAppointmentsForDateRange: Actions.reports.fetchAppointmentsForDateRange,
  }
)(SalesDetails);

