import React, { useEffect, useState } from "react";
import {
  ProductPriceWrapper,
  ProductOptionWrapper,
  AddOptionButtonWrapper,
  ProductPriceFormWrapper,
  ProductPriceItemWrapper,
  ProductPriceHeaderWrapper,
  ProductOptionHeaderWrapper,
} from "./Styled";
import "antd/dist/antd.css";
import "../Styles.style.css";
import { nanoid } from "nanoid";
import { PriceVariant } from "../Types";
import _, { get, isEmpty } from "lodash";
import { connect } from "react-redux/es/index";
import { Actions } from "../../../internal/app/Actions";
import BackIcon from "../../../../assets/svg/backIcon.svg";
import CloseIcon from "../../../../assets/images/closeIcon.png";
import { navigate } from "../../../internal/service/Navigation.service";
import { getCurrencyWithPrice } from "../../../internal/manager/AppointmentManager";
import TopNavigationBar from "../../../components/TopNavigation/Views/TopNavigationBar";

import * as Yup from "yup";
import { Formik, FieldArray } from "formik";

const PriceSchema = Yup.object({
  priceVariants: Yup.array().of(
    Yup.object().shape({
      optionUnit: Yup.string()
        .min(1, "Must be 1 characters or more")
        .required("Please enter option unit"),
      price: Yup.number()
        .typeError("Please enter a valid price")
        .positive("Item price must be more than 0")
        .required("Please enter item price"),
    })
  ),
});

const initials = {
  price: 0,
  options: [],
  sku: nanoid(),
  optionUnit: "",
};

const ProductPriceView = (props: any) => {
  const { shop, selectedProduct, setPriceVariants, productPriceVariants } =
    props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [initialValues, setInitialValues] = useState<Array<PriceVariant>>([
    initials,
  ]);

  useEffect(() => {
    if (!isEmpty(productPriceVariants)) {
      setInitialValues([...productPriceVariants]);
    }
  }, [productPriceVariants]);

  const handleSubmit = (values: any, { resetForm }: any) => {
    setPriceVariants(values.priceVariants);
    resetForm();
  };

  return (
    <div className="addProductOptionWrapper">
      <TopNavigationBar viewName="Products" path="/menu" />
      <ProductOptionWrapper>
        <ProductOptionHeaderWrapper>
          <div onClick={() => navigate("/add-product-options")}>
            <img src={BackIcon} />
            <div>{"Price Variants"}</div>
          </div>
        </ProductOptionHeaderWrapper>

        <ProductPriceFormWrapper>
          <div>
            <div>Set product prices</div>
            <ProductPriceHeaderWrapper>
              <div>{"Variations"}</div>
              <div>{"SKU"}</div>
              <div>{"Price"}</div>
            </ProductPriceHeaderWrapper>
            <Formik
              onSubmit={handleSubmit}
              enableReinitialize={true}
              validationSchema={PriceSchema}
              initialValues={{ priceVariants: initialValues }}
            >
              {({
                errors,
                values,
                submitCount,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <form id="priceVariantForm" onSubmit={handleSubmit}>
                    <FieldArray name="priceVariants">
                      {({ insert, remove, push }) => {
                        return (
                          <>
                            <ProductPriceWrapper>
                              {values.priceVariants.map(
                                (priceVariant: any, index: number) => {
                                  const isLastItem =
                                    values.priceVariants.length - 1 === index;
                                  const error: any = get(
                                    errors,
                                    `priceVariants.[${index}]`,
                                    null
                                  );
                                  return (
                                    <ProductPriceItemWrapper
                                      isLast={isLastItem}
                                    >
                                      <div>
                                        <div>{priceVariant.optionUnit}</div>
                                        <div>{priceVariant.sku}</div>
                                        <div>
                                          {editIndex === index ? (
                                            <input
                                              placeholder="Price"
                                              value={`${priceVariant.price}`}
                                              onBlur={() => setEditIndex(null)}
                                              onChange={(event) => {
                                                let text = event.target.value;
                                                setFieldValue(
                                                  `priceVariants[${index}].price`,
                                                  text
                                                );
                                              }}
                                            />
                                          ) : (
                                            <div
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setEditIndex(index);
                                              }}
                                            >
                                              {getCurrencyWithPrice(
                                                priceVariant.price,
                                                shop.currency
                                              )}
                                            </div>
                                          )}

                                          {values?.priceVariants.length > 1 && (
                                            <img
                                              src={CloseIcon}
                                              className="imageRemoveIcon"
                                              onClick={() => remove(index)}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      {!isEmpty(error?.price) &&
                                        submitCount > 0 && (
                                          <span className="first-line:block text-xs font-normal text-rose-400	mr-16">
                                            {error?.price}
                                          </span>
                                        )}
                                    </ProductPriceItemWrapper>
                                  );
                                }
                              )}
                            </ProductPriceWrapper>

                            <AddOptionButtonWrapper>
                              <button onClick={() => {}}>{"Remove All"}</button>
                              <button type="submit">
                                {"Create  Variations"}
                              </button>
                            </AddOptionButtonWrapper>
                          </>
                        );
                      }}
                    </FieldArray>
                  </form>
                );
              }}
            </Formik>
          </div>
        </ProductPriceFormWrapper>
      </ProductOptionWrapper>
    </div>
  );
};

export default connect(
  (state) => ({
    shop: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
    selectedProduct: state.product.get("selectedProduct"),
    productCategoryList: state.product.get("productCategoryList"),
    productPriceVariants: state.product.get("productPriceVariants"),
  }),
  {
    createProduct: Actions.product.createProduct,
    updateProduct: Actions.product.updateProduct,
    deleteProduct: Actions.product.deleteProduct,
    setPriceVariants: Actions.product.setPriceVariants,
  }
)(ProductPriceView);
