import React from 'react';
import moment from "moment";
import styles from '../../Styles/Styles.css';
import IconFilter from "../../../../assets/svg/IconCloshcashFilter.svg";

const today = moment().format('Do MMMM YYYY');

const TransactionTableHeader = (props) => {
  const {filter = true, title, onClickFilter} = props;

  return (
    <>
      {
        filter ?
          <div className={styles.transactionTableHeaderWrapper}>
            <div className={styles.transactionTableHeaderDate}>{`Today, ${today}`}</div>
            <div className={styles.transactionTableHeaderIconWrapper}>
              <img className={styles.transactionTableHeaderIcon} onClick={() => onClickFilter()} src={IconFilter}/>
            </div>
          </div>
          :
          <div className={styles.transactionTableHeaderWrapper}>
            <div className={styles.transactionTableHeaderDate}>{title}</div>
          </div>
      }
    </>
  );
};
export default TransactionTableHeader;
