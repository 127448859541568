import GraphqlService from "./Graphql.service";

class GraphqlFunstions {
  async commonGraphqlService(type: string, data: any = {}) {
    let result: any;
    if (!window?.standlone) {
      switch (type) {
        case "FETCH_OFFLINE_ORDERS":
          result = await GraphqlService.fetchOfflineOrders("OFFLINE_ORDERS");
          return result;
        case "CREATE_ORDER":
          result = await GraphqlService.createOrder(data);
          return result;
        case "CREATE_ORDER_OFFLINE":
          await GraphqlService.createOfflineShopOrder(data);
          return;
        case "FETCH_ORDERS_COMPLETED":
          result = await GraphqlService.fetchOrders(data, "COMPLETED");
          return result;
        case "FETCH_ORDERS_PENDING":
          result = await GraphqlService.fetchOrders(data, "PENDING");
          return result;
        case "SET_ORDER":
          result = await GraphqlService.setShopOrders(data);
          return result;
        case "UPDATE_ORDER":
          result = await GraphqlService.updateOrder(data);
          return result;
        case "UPDATE_ORDER_OFFLINE":
          await GraphqlService.updateOfflineShopOrder(data);
          return;
        case "DELETE_ORDER":
          result = await GraphqlService.deleteOrder(data);
          return result;
        case "DELETE_ORDER_OFFLINE":
          await GraphqlService.deleteOfflineOrder(data);
          return;
        case "SERVICE_MESSAGE":
          GraphqlService.serviceMessage(data.message, data.value);
          return;
        case "FETCH_PRINTERS":
          result = await GraphqlService.fetchPrinters();
          return result;
        case "SET_PRINTERS":
          result = await GraphqlService.setPrinters(data);
          return result;
        case "PRINT_RECEIPT":
          await GraphqlService.printReceipt(data);
          return;
        case "CREATE_TABLE":
          result = await GraphqlService.createTable(data);
          return result;
        case "CREATE_TABLE_OFFLINE":
          await GraphqlService.createOfflineTable(data);
          return;
        case "UPDATE_TABLE":
          result = await GraphqlService.updateTable(data);
          return result;
        case "UPDATE_TABLE_OFFLINE":
          await GraphqlService.updateOfflineTable(data);
          return;
        case "SET_TABLES":
          result = await GraphqlService.setShopTables(data);
          return result;
        case "FETCH_TABLES":
          result = await GraphqlService.fetchTables(data);
          return result;
        case "FETCH_TABLE_OFFLINE":
          result = await GraphqlService.fetchOfflineTables("OFFLINE_TABLES");
          return result;
        case "DELETE_TABLE_OFFLINE":
          await GraphqlService.deleteOfflineTable();
          return;
        case "CREATE_ACTIVITY_LOG":
          result = await GraphqlService.createActivityLog(data);
          return result;
        case "DELETE_ACTIVITY_LOG":
          await GraphqlService.deleteActivityLog(data);
          return;
        case "SET_ACTIVITY_LOGS":
          await GraphqlService.setActivityLogs(data);
          return;
        case "GET_ACTIVITY_LOGS":
          result = await GraphqlService.getActivityLogs(data);
          return result;

        case "SET_PRODUCT_OPTION_SET":
          await GraphqlService.setProductOptionSet(data);
          return;
        case "GET_PRODUCT_OPTION_SET":
          result = await GraphqlService.getProductOptionSet(data);
          return result;
      }
    }
  }
}
export default new GraphqlFunstions();
