import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Actions} from "../../../internal/app/Actions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import _ from 'lodash';
import ProductSelectModal from "../../model/views/ProductSelectModal";
import ProductDeleteModal from "../../model/views/ProductDeleteModal";
import ServiceCard from '../../../components/card/ServiceCard';
import ServiceCategoryCard from '../../../components/card/ServiceCategoryCard';
import SearchComponent from '../../../components/search/SearchComponent';
import {
  ServiceContainer,
  ServiceCategoryContainer,
  ServiceCardContainer,
  ServiceKeyBoardContainer
} from '../../../components/Styles/Styled';
import ApprovalView from "../../../modules/orders/views/ApprovalView";
import {isCanAccess} from "../../../internal/manager/EmployeeManager";

const Services = (props) => {
    const {
      shopData, categories, setPurchaseService, loadingAction, openServiceKeyPad, isOpenKeypad, purchaseServices,
      deletePurchaseService, selectServiceItem, chossedServiceItem, selectedAppointment, fetchCategories,
      customers, onSelectCustomer, stylists, onSelectEmployee, currentUser, searchCategories, selectCategory,
      selectedCategory
    } = props;
    const {action, loading} = loadingAction;
    const [categoryList, setCategoryList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isOpenSelectModal, setIsOpenSelectModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [selectedRemoveService, setSelectedRemoveService] = useState({});
  const [isOpenApprovalModal, setIsOpenApprovalModal] = useState(false);
  const role = currentUser?.specializations;

    useEffect(() => {
      if (!_.isEmpty(selectedAppointment)) {
        // selectedAppointment.serviceDetails.forEach((service) => {
        //   const param = {
        //     tax: 0,
        //     quantity: _.get(service, 'quantity', 1),
        //     symbol1: '',
        //     symbol2: '',
        //     id: service.id,
        //     type: "SERVICE",
        //     enteredNumber1: '',
        //     enteredNumber2: '',
        //     price: _.get(service, 'totalPrice', service.price),
        //     duration: service.duration,
        //     servicePrice: service.price,
        //     serviceTitle: _.get(service, 'serviceTitle', service.title),
        //   };
        //   setPurchaseService(param);
        //   const customer = _.find(customers, (item) => item.id === selectedAppointment.clientId);
        //   onSelectCustomer(customer);
        //   const stylist = _.find(stylists, (item) => item.stid === selectedAppointment.stylistId);
        //   onSelectEmployee(stylist);
        //   selectedDateAndTime({date: selectedAppointment.date, time: selectedAppointment.time});
        // });
      }
    }, [selectedAppointment]);

    useEffect(() => {
      setCategoryList(_.get(categories, 'categories', []));
    }, [categories]);

    const debounceSearch = _.debounce((text) => {
      searchCategories(shopData.shopId, selectedCategory.id, text)
    }, 500);

    const searchServiceForName = (text) => {
      setSearchText(text);
      if (text && text.length > 0) {
        debounceSearch(text);
      } else {
        fetchCategories(shopData.shopId);
      }
    };

    const deleteSearchText = () => {
      setSearchText('');
      fetchCategories(shopData.shopId);
    };

    const onSelectService = (service) => {
      const param = {
        tax: 0,
        quantity: 1,
        symbol1: '',
        symbol2: '',
        id: service.id,
        type: "SERVICE",
        enteredNumber1: '',
        enteredNumber2: '',
        price: service.price,
        duration: service.duration,
        servicePrice: service.price,
        serviceTitle: service.serviceTitle
      };
      setPurchaseService([param]);
      selectServiceItem(service);
    };

    const toggleDeleteModal = () => {
      setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const removeSelectedService = () => {
      toggleDeleteModal();
      selectServiceItem({});
      setSelectedRemoveService({});
      deletePurchaseService(selectedRemoveService);
    };

    return (
      <ServiceContainer>
        <SearchComponent
          searchText={searchText}
          title="Search by service name"
          deleteSearchText={() => deleteSearchText()}
          onSearchText={(text) => searchServiceForName(text)}
        />
        <ServiceCategoryContainer>
          {
            categoryList.map((category) => {
              const isSelected = selectedCategory.id === category.id;
              return (
                <ServiceCategoryCard
                  category={category}
                  isSelected={isSelected}
                  onSelectCategory={(data) => selectCategory(data)}
                />
              )
            })
          }
        </ServiceCategoryContainer>
        {
          loading && (action.type === 'FETCH_CATEGORIES') &&
          <div className={'productLoading'}>
            <CircularProgress color="primary" size={60} value={5}/>
          </div>
        }
        <ServiceCardContainer>
          {
            _.get(selectedCategory, 'services', []).map((item) => {
              let isSelected = item.id === chossedServiceItem.id;
              let index = _.findIndex(purchaseServices, service => service.id === item.id);

              return (
                <ServiceCard
                  item={item}
                  index={index}
                  isSelected={isSelected}
                  onDelete={() => {
                    setSelectedRemoveService(item);
                    if (!isCanAccess(role, 'isCanDeleteItem')) {
                      setIsOpenApprovalModal(true);
                    } else {
                      toggleDeleteModal();
                    }
                  }}
                  onSelectServiceItem={(data) => onSelectService(data)}
                />
              )
            })
          }
        </ServiceCardContainer>

        {/* <ServiceKeyBoardContainer onClick={() => {
          if (!_.isEmpty(chossedServiceItem)) {
            openServiceKeyPad(true);
          } else {
            setIsOpenSelectModal(true)
          }
        }}>
          <div>Keypad</div>
        </ServiceKeyBoardContainer> */}


        <ProductSelectModal
          isOpen={isOpenSelectModal}
          closeModal={() => setIsOpenSelectModal(false)}
        />
        <ProductDeleteModal
          isOpen={isOpenDeleteModal}
          closeModal={() => toggleDeleteModal()}
          removeProduct={() => removeSelectedService()}
        />
        <ApprovalView
          isOpenApprovalModal={isOpenApprovalModal}
          onSetIsOpenApprovalModal={(status) => setIsOpenApprovalModal(status)}
          onOpenEditModal={() => {}}
          deleteItem={() => {
            toggleDeleteModal();
            setIsOpenApprovalModal(false);
          }}
        />
      </ServiceContainer>
    );
  }
;
export default connect(
  (state: any) => ({
    shopData: state.login.get('merchantShop'),
    stylists: state.employee.get('employees'),
    customers: state.customer.get('customers'),
    categories: state.service.get('categories'),
    currentUser: state.login.get("currentUser"),
    loadingAction: state.common.get('loadingAction'),
    isOpenKeypad: state.service.get('isOpenKeypad'),
    purchaseServices: state.service.get('purchaseServices'),
    selectedCategory: state.service.get('selectedCategory'),
    chossedServiceItem: state.service.get('chossedServiceItem'),
    selectedAppointment: state.orders.get('selectedAppointment'),

  }),
  {
    selectCategory: Actions.service.selectCategory,
    fetchCategories: Actions.service.fetchCategories,
    searchCategories: Actions.service.searchCategories,
    onSelectEmployee: Actions.employee.onSelectEmployee,
    onSelectCustomer: Actions.customer.onSelectCustomer,
    selectServiceItem: Actions.service.selectServiceItem,
    openServiceKeyPad: Actions.service.openServiceKeyPad,
    setPurchaseService: Actions.service.setPurchaseService,
    selectedDateAndTime: Actions.salon.selectedDateAndTime,
    deletePurchaseService: Actions.service.deletePurchaseService,

  }
)(Services);

