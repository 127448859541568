import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-grid-system';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import 'antd/dist/antd.css';
import {connect} from "react-redux/es/index";
import '../Styles.style.css';
import {Actions} from "../../../internal/app/Actions";
import _ from "lodash";
import ServiceWarningModal from '../../../components/model/views/ServiceWarningModal';
import {goBack, navigate} from "../../../internal/service/Navigation.service";
import {nanoid} from "nanoid";
import EditProductItemTopHeader from "../../../components/product/views/EditProductItemTopHeader";
import {mapStockMetrics} from "../../../internal/manager/ProductManager";
import Select from "react-select";

const style = {
  control: (base, state) => ({
    ...base,
    height: "45px",
    width: "544px",
    paddingRight: "20px",
    border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    "&:hover": {
      border: state.isFocused ? "1px solid #ebeaea" : "1px solid #ebeaea",
    },
    borderRadius: "6px",
    color: "#434343",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 10px 5px #c4d3f020",
    paddingLeft: "5px",
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "544px"
  }),
};

const StockAddView = (props) => {
  const { shop, selectedStockItem, currentUser, deleteStock, createStock, clearSelectedStock, selectedMainMenuView, history } = props;

  const [itemName, setItemName] = useState('');
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [isOpenDiscardModel, setIsOpenDiscardModel] = useState(false);
  const [isOpenDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!_.isEmpty(selectedStockItem)) {
      setItemName(selectedStockItem.itemName);
      setSelectedMetric({
        label: selectedStockItem.metric,
        value: selectedStockItem.metric,
        metricValue: selectedStockItem.metricValue
      });
    }
  }, [selectedStockItem]);

  const isEdit = !_.isEmpty(selectedStockItem);

  const onCreateStock = () => {
    const param:any = {
      itemName,
      metric: selectedMetric.value,
      shopId: shop.shopId,
      stockId: nanoid(8),
      updatedTime: Date.now(),
      createdUserId: currentUser.empId,
      metricValue: selectedMetric.metricValue
    };

    if (isEdit) {
      param.stockId = selectedStockItem.stockId;
      param.createdTime = selectedStockItem.createdTime;
      createStock(param);
    } else {
      param.createdTime = Date.now();
      createStock(param);
    }
  };

  const onInputChange = (option, {action}) => {
    if (action === "input-change") {
      const optionLength = option.length;
      const inputValueLength = inputValue.length;
      const newInputValue =
        optionLength < inputValueLength
          ? option
          : inputValue + option[option.length - 1];
      setInputValue(newInputValue);
    }
  };

  const onDeleteCategory = () => {
    deleteStock({stockId: selectedStockItem.stockId, shopId: shop.shopId});
  };

  const isValid = !_.isEmpty(selectedMetric) && itemName;
  const isCanDelete = selectedStockItem.isCanDelete;

  return (
    <div className="addCategoryMainContainer">
      <TopNavigationBar viewName="Products" path="/menu"/>
      <div className="addServiceViewContainer">
        <div className="addServiceViewWrapper">
          <EditProductItemTopHeader
            onCreateService={() => {
              if (isValid) {
                onCreateStock();
              }
            }}
            isEdit={isEdit}
            onBack={() => {
              if (isEdit) {
                setIsOpenDiscardModel(true);
              } else {
                goBack()
              }
            }}
            isValid={isValid}
            buttonText={"Save Stock"}
            backBtnText={isEdit ? "Edit Stock" : "Add Stock"}
          />
          <Row>
            <Col xs={12}>
              <div className="serviceAddFormContainer">
                <div className="addServiceDetailsText">
                  Details
                </div>
                <div className="addCategoryInputContainer">
                  <Row>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Stock Item Name
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput">
                      <input
                        onChange={(text) => setItemName(text.target.value)}
                        value={itemName}
                        className='serviceTextInput'
                        placeholder="Stock item Name"
                      />
                    </Col>
                  </Row>
                  <Row style={{marginTop: '30px'}}>
                    <Col xs={2} className="serviceTitleCol">
                      <div className="serviceTitleText">
                        Stock Metric
                      </div>
                    </Col>
                    <Col xs={10} className="serviceTitleColInput" style={{width: '544px'}}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        onChange={(data) => {
                          setSelectedMetric(data);
                          setInputValue('');
                        }}
                        styles={style}
                        value={selectedMetric}
                        placeholder={
                          <div className="categoryPlaceholderText">
                            Stock metric
                          </div>
                        }
                        name="color"
                        options={mapStockMetrics}
                        onInputChange={onInputChange}
                      />
                    </Col>
                  </Row>
                  {
                    isEdit ?
                      <Row style={{marginTop: '20px'}}>
                        <Col xs={2} className="serviceTitleCol" />
                        <Col xs={10} className="serviceTitleColInput">
                          <div onClick={() => {
                            if (isCanDelete) {
                              setIsOpenDeleteModel(true);
                            }
                          }} className="categoryDeleteBtnContainer" style={{backgroundColor: isCanDelete ? '#FFD6D6' : '#8A9CA5', border: isCanDelete ? '1px solid #FF5F5F' : '1px solid #8A9CA5'}}>
                            <div className="serviceDeleteBtnText" style={{color: isCanDelete ? '#FF5F5F' : '#ffffff'}}>
                              Delete Stock
                            </div>
                          </div>
                        </Col>
                      </Row>
                      : null
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ServiceWarningModal
        isOpen={isOpenDiscardModel}
        headerText={'Are you sure?'}
        bodText={'All your changes will be discarded'}
        cancelBtnText={'Discard'}
        onCancel={() => {
          setIsOpenDiscardModel(false);
          goBack();
          clearSelectedStock();
        }}
        closeModal={() => setIsOpenDiscardModel(false)}
      />
      <ServiceWarningModal
        isOpen={isOpenDeleteModel}
        headerText={'Warning!'}
        bodText={'Your stock will be deleted and no longer\n available to order for the users'}
        cancelBtnText={'Delete'}
        onCancel={() => {
          onDeleteCategory();
          setIsOpenDeleteModel(false);
        }}
        closeModal={() => setIsOpenDeleteModel(false)}
      />
    </div>
  );
};

export default connect(
  state => ({
    shop: state.login.get('merchantShop'),
    currentUser: state.login.get('currentUser'),
    selectedStockItem: state.product.get('selectedStockItem'),
    selectedMainMenuView: state.common.get('selectedMainMenuView')
  }),
  {
    createStock: Actions.product.createStock,
    deleteStock: Actions.product.deleteStock,
    clearSelectedStock: Actions.product.clearSelectedStock
  }
)(StockAddView);
