import {
  shiftDataFromStorage,
  setValueToLocalStorage,
  unshiftDataFromStorage,
} from "../manager/CommonManager";
import _, { delay, findIndex, get, isEmpty } from "lodash";
import Repository from "./Repository";
import StockRepository from "./StockRepository";
import Actions from "../../modules/product/Actions";
import ProductRepository from "./ProductRepository";
import ServiceRepository from "./ServiceRepository";
import CustomerRepository from "./CustomerRepository";
import StockActions from "../../modules/stock/Actions";
import OrderActions from "../../modules/orders/Actions";
import ClientActions from "../../modules/customer/Actions";
import ServiceActions from "../../modules/service/Actions";
import AppointmentRepository from "./AppointmentRepository";
import GraphqlFunctions from "../service/Graphql.functions";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import {
  CreateOption,
  UpdateOption,
  fetchOptionSets,
} from "./schema/Supplier.schema";
class CommonRepository extends Repository {
  init(store) {
    this.store = store;
  }

  async createOfflineProductType(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;
    await list.reduce(async (promise, item) => {
      await promise;
      const isCreate = _.cloneDeep(item.isCreate);
      const omitItem = _.omit(item, ["isOffline", "isCreate"]);
      if (isCreate) {
        await ProductRepository.createProductType(omitItem);
      } else {
        await ProductRepository.updateProductType(omitItem);
      }

      return Promise.resolve(list);
    }, Promise.resolve(list));

    if (shopId && list.length > 0) {
      dispatch(Actions.fetchProductTypes(shopId));
    }

    return list;
  }

  async deleteOfflineProductType(list) {
    try {
      const { dispatch }: any = this.store;
      const shopId = list[0] && list[0].shopId;
      await list.reduce(async (promise, item) => {
        await promise;
        const omitItem = _.omit(item, ["isOffline"]);
        await ProductRepository.deleteProductType(omitItem);

        return Promise.resolve(list);
      }, Promise.resolve(list));

      if (shopId && list.length > 0) {
        dispatch(Actions.fetchProductTypes(shopId));
        await setValueToLocalStorage(
          "deleted-PRODUCT_TYPES",
          JSON.stringify([])
        );
      }

      return list;
    } catch (error) {
      console.warn("Catched error when deleting product types", error);
      return;
    }
  }

  async createOfflineBrands(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;

    await list.reduce(async (promise, item) => {
      await promise;
      const isCreate = _.cloneDeep(item.isCreate);
      const omitItem = _.omit(item, ["isOffline", "isCreate"]);
      if (isCreate) {
        await ProductRepository.createBrand(omitItem);
      } else {
        await ProductRepository.updateBrand(omitItem);
      }

      return Promise.resolve(list);
    }, Promise.resolve(list));

    if (shopId && list.length > 0) {
      dispatch(Actions.fetchShopBrands(shopId));
    }

    return list;
  }

  async deleteOfflineBrands(list) {
    try {
      const { dispatch }: any = this.store;
      const shopId = list[0] && list[0].shopId;

      await list.reduce(async (promise, item) => {
        await promise;
        const omitItem = _.omit(item, ["isOffline"]);
        await ProductRepository.deleteBrand(omitItem);

        return Promise.resolve(list);
      }, Promise.resolve(list));

      if (shopId && list.length > 0) {
        dispatch(Actions.fetchShopBrands(shopId));
        await setValueToLocalStorage("deleted-BRANDS", JSON.stringify([]));
      }

      return list;
    } catch (error) {
      console.warn("Catched error when deleting offline brands", error);
      return;
    }
  }

  async createOfflineProductCategory(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;

    await list.reduce(async (promise, item) => {
      await promise;
      const isCreate = _.cloneDeep(item.isCreate);
      const omitItem = _.omit(item, ["isOffline", "isCreate"]);
      if (isCreate) {
        await ProductRepository.createProductCategory(omitItem);
      } else {
        await ProductRepository.updateProductCategory(omitItem);
      }

      return Promise.resolve(list);
    }, Promise.resolve(list));

    if (shopId && list.length > 0) {
      dispatch(Actions.fetchShopProductCategories(shopId));
    }

    return list;
  }

  async deleteOfflineProductCategory(list) {
    try {
      const { dispatch }: any = this.store;
      const shopId = list[0] && list[0].shopId;

      await list.reduce(async (promise, item) => {
        await promise;
        const omitItem = _.omit(item, ["isOffline"]);
        await ProductRepository.deleteProductCategory(omitItem);

        return Promise.resolve(list);
      }, Promise.resolve(list));

      if (shopId && list.length > 0) {
        dispatch(Actions.fetchShopProductCategories(shopId));
        await setValueToLocalStorage(
          "deleted-PRODUCT_CATEGORIES",
          JSON.stringify([])
        );
      }

      return list;
    } catch (error) {
      console.warn(
        "Catched error when deleting offline product categories",
        error
      );
      return;
    }
  }

  async createOfflineProduct(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;

    await list.reduce(async (promise, item) => {
      await promise;
      const isCreate = _.cloneDeep(item.isCreate);
      const omitItem = _.omit(item, ["isOffline", "isCreate"]);
      if (isCreate) {
        await ProductRepository.createProduct(omitItem);
      } else {
        await ProductRepository.updateProduct(omitItem);
      }

      return Promise.resolve(list);
    }, Promise.resolve(list));

    if (shopId && list.length > 0) {
      dispatch(Actions.fetchShopProducts(shopId));
    }

    return list;
  }

  async deleteOfflineProduct(list) {
    try {
      const { dispatch }: any = this.store;
      const shopId = list[0] && list[0].shopId;

      await list.reduce(async (promise, item) => {
        await promise;
        const omitItem = _.omit(item, ["isOffline"]);
        await ProductRepository.deleteProduct(omitItem);

        return Promise.resolve(list);
      }, Promise.resolve(list));

      if (shopId && list.length > 0) {
        dispatch(Actions.fetchShopProducts(shopId));
        await setValueToLocalStorage(
          "deleted-ALL_PRODUCTS",
          JSON.stringify([])
        );
      }

      return list;
    } catch (error) {
      console.warn("Catched error when deleting offline products", error);
      return;
    }
  }

  async createOfflineMenu(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;

    await list.reduce(async (promise, item) => {
      await promise;
      const isCreate = _.cloneDeep(item.isCreate);
      const omitItem = _.omit(item, ["isOffline", "isCreate"]);
      if (isCreate) {
        await ProductRepository.createMenu(omitItem);
      } else {
        await ProductRepository.updateMenu(omitItem);
      }

      return Promise.resolve(list);
    }, Promise.resolve(list));

    if (shopId && list.length > 0) {
      dispatch(Actions.fetchMenu(shopId));
    }

    return list;
  }

  async deleteOfflineMenu(list) {
    try {
      const { dispatch }: any = this.store;
      const shopId = list[0] && list[0].shopId;

      await list.reduce(async (promise, item) => {
        await promise;
        const omitItem = _.omit(item, ["isOffline"]);
        await ProductRepository.deleteMenu(omitItem);

        return Promise.resolve(list);
      }, Promise.resolve(list));

      if (shopId && list.length > 0) {
        dispatch(Actions.fetchMenu(shopId));
        await setValueToLocalStorage("deleted-MENU", JSON.stringify([]));
      }

      return list;
    } catch (error) {
      console.warn("Catched error when deleting offline menus", error);
      return;
    }
  }

  async createOfflineClients(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;

    await list.reduce(async (promise, item) => {
      await promise;
      const isCreate = _.cloneDeep(item.isCreate);
      const omitItem = _.omit(item, ["isOffline", "isCreate"]);
      if (isCreate) {
        if (omitItem.type === "STORE_CUSTOMER") {
          await CustomerRepository.createQuickCustomer(omitItem);
        } else {
          await CustomerRepository.createCustomer(omitItem);
        }
      } else {
        await CustomerRepository.updateCustomer(omitItem);
      }
      return Promise.resolve(list);
    }, Promise.resolve(list));

    if (shopId && list.length > 0) {
      dispatch(ClientActions.fetchCustomers(shopId));
    }

    return list;
  }

  async deleteOfflineClients(list) {
    try {
      const { dispatch }: any = this.store;
      const shopId = list[0] && list[0].shopId;

      await list.reduce(async (promise, item) => {
        await promise;
        await CustomerRepository.deleteCustomer(item.id, item.shopId);
        return Promise.resolve(list);
      }, Promise.resolve(list));

      if (shopId && list.length > 0) {
        dispatch(ClientActions.fetchCustomers(shopId));
        await setValueToLocalStorage(
          "deleted-MERCHANT_CLIENT",
          JSON.stringify([])
        );
      }

      return list;
    } catch (error) {
      console.warn("Catched error when deleting offline clients", error);
      return;
    }
  }

  async updateShopCategory(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;

    await list.reduce(async (promise, item) => {
      await promise;
      await ServiceRepository.updateServiceCategory({
        scid: item.scid,
        categories: item.categories,
      });
      return Promise.resolve(list);
    }, Promise.resolve(list));

    if (shopId && list.length > 0) {
      dispatch(ServiceActions.fetchCategories(shopId));
    }

    return list;
  }

  async updateCompletedOrders(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;

    const errorList = await list.reduce(async (promise, item) => {
      const errorList = await promise;
      try {
        const isCreate = item.isCreate;
        const omitItem = _.omit(item, [
          "isOffline",
          "isCreate",
          "employee",
          "client",
        ]);
        let result = null;
        if (isCreate) {
          result = await AppointmentRepository.createOrder(omitItem);
        } else {
          result = await AppointmentRepository.updateOrder(omitItem);
        }
        if (result?.error) {
          errorList.push({ item, result });
        }
        return Promise.resolve(errorList);
      } catch (e) {
        return Promise.resolve(errorList);
      }
    }, Promise.resolve([]));

    if (shopId && list.length > 0) {
      dispatch(OrderActions.fetchCompletedAppointments(shopId));
    }

    return errorList;
  }

  async processOrderActions() {
    const { dispatch }: any = this.store;
    // const shopId = list[0] && l/ist[0].shopId;
    let item = await shiftDataFromStorage("ORDER_OPERATION");
    const failArray = [];
    let trycount = 1;
    let error = false;
    while (item && !error) {
      const { action, data } = item;
      console.log("Processing: ", action, data);
      try {
        if (trycount > 3) {
          failArray.push(item);
          await unshiftDataFromStorage("ORDER_OPERATION", item);
          error = true;
          return failArray;
          // item = undefined;
        }
        switch (action) {
          case "CREATE": {
            const omitItem = _.omit(data, [
              "isOffline",
              "isCreate",
              "employee",
              "client",
            ]);
            const result: any = await AppointmentRepository.createOrder(
              omitItem
            );
            if (result?.error) {
              throw new Error("Order Error");
            }
            break;
          }
          case "DELETE": {
            const omitItem = _.omit(data, [
              "isOffline",
              "isCreate",
              "employee",
              "client",
            ]);
            const result = await AppointmentRepository.deleteOrder(omitItem);
            if (result.error) {
              throw new Error("Order Error");
            }
            break;
          }
          case "UPDATE": {
            const omitItem = _.omit(data, [
              "isOffline",
              "isCreate",
              "employee",
              "client",
            ]);
            const result: any = await AppointmentRepository.updateOrder(
              omitItem
            );
            if (result.error) {
              throw new Error("Order Error");
            }
            break;
          }
          case "REFUND": {
            const omitItem = _.omit(data, [
              "isOffline",
              "isCreate",
              "employee",
              "client",
            ]);
            const result: any = await AppointmentRepository.updateOrder(
              omitItem
            );
            if (result?.error) {
              throw new Error("Order Error");
            }
            break;
          }
          default: {
            console.log("UNHANDLED OFFLINE EVENT", data, action);
          }
        }
        item = await shiftDataFromStorage("ORDER_OPERATION");
        trycount = 1;
      } catch (e) {
        trycount += 1;
        // Short delay for retry
        await new Promise((res) => {
          delay(() => {
            res(true);
          }, 800);
        });
      }
    }
    return failArray;
  }

  async updateUnCompletedOrders(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;

    const errorList = await list.reduce(
      async (promise: Promise<[any]>, item: any) => {
        const errors = await promise;
        const isCreate = _.cloneDeep(item.isCreate);
        const omitItem = _.omit(item, [
          "isOffline",
          "isCreate",
          "employee",
          "client",
        ]);
        let result = null;
        if (isCreate) {
          result = await AppointmentRepository.createOrder(omitItem);
        } else {
          result = await AppointmentRepository.updateOrder(omitItem);
        }
        if (result?.error) {
          errors.push({ item, result });
        }
        return Promise.resolve(errors);
      },
      Promise.resolve([])
    );

    if (shopId && list.length > 0) {
      dispatch(OrderActions.fetchUnCompletedAppointments(shopId));
    }

    return errorList;
  }

  async createRefundOrder(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;

    await list.reduce(async (promise, item) => {
      await promise;
      const omitItem = _.omit(item, ["isOffline", "isCreate", "employee"]);
      await AppointmentRepository.createRefund(omitItem);
      return Promise.resolve(list);
    }, Promise.resolve(list));

    if (shopId && list.length > 0) {
      dispatch(OrderActions.fetchCompletedAppointments(shopId));
      await setValueToLocalStorage("REFUND_ORDERS", JSON.stringify([]));
    }

    return list;
  }

  async createOfflineStock(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;
    await list.reduce(async (promise, item) => {
      await promise;
      const isCreate = _.cloneDeep(item.isCreate);
      const omitItem = _.omit(item, ["isOffline", "isCreate"]);
      if (isCreate) {
        await ProductRepository.createStock(omitItem);
      } else {
        await ProductRepository.createStock(omitItem);
      }

      return Promise.resolve(list);
    }, Promise.resolve(list));

    if (shopId && list.length > 0) {
      dispatch(Actions.fetchStock(shopId));
    }

    return list;
  }

  async deleteOfflineStock(list) {
    try {
      const { dispatch }: any = this.store;
      const shopId = list[0] && list[0].shopId;

      await list.reduce(async (promise, item) => {
        await promise;
        await ProductRepository.deleteStock({
          shopId: item.shopId,
          stockId: item.stockId,
        });
        return Promise.resolve(list);
      }, Promise.resolve(list));

      if (shopId && list.length > 0) {
        dispatch(Actions.fetchStock(shopId));
        await setValueToLocalStorage("deleted-STOCK", JSON.stringify([]));
      }

      return list;
    } catch (error) {
      console.warn("Catched error when deleting offline stocks", error);
      return;
    }
  }

  async createOfflineStockRecord(list) {
    const { dispatch }: any = this.store;
    const shopId = list[0] && list[0].shopId;
    await list.reduce(async (promise, item) => {
      await promise;
      const isCreate = _.cloneDeep(item.isCreate);
      const omitItem = _.omit(item, ["isOffline", "isCreate"]);
      if (isCreate) {
        await StockRepository.createStockRecord(omitItem);
      } else {
        await StockRepository.createStockRecord(omitItem);
      }

      return Promise.resolve(list);
    }, Promise.resolve(list));

    if (shopId && list.length > 0) {
      dispatch(StockActions.fetchStockRecord(shopId));
    }

    return list;
  }

  async deleteOfflineStockRecord(list) {
    try {
      const { dispatch }: any = this.store;
      const shopId = list[0] && list[0].shopId;

      await list.reduce(async (promise, item) => {
        await promise;
        await StockRepository.deleteStockRecord({
          shopId: item.shopId,
          id: item.id,
        });
        return Promise.resolve(list);
      }, Promise.resolve(list));

      if (shopId && list.length > 0) {
        dispatch(StockActions.fetchStockRecord(shopId));
        await setValueToLocalStorage(
          "deleted-STOCK_RECORD",
          JSON.stringify([])
        );
      }

      return list;
    } catch (error) {
      console.warn("Catched error when deleting offline stock records", error);
      return;
    }
  }

  async deleteOfflineOrders(list) {
    try {
      const { dispatch }: any = this.store;
      const shopId = list[0] && list[0].shopId;

      await list.reduce(async (promise, item) => {
        await promise;
        await AppointmentRepository.deleteOrder({
          shopId: item.shopId,
          orderId: item.orderId,
        });
        return Promise.resolve(list);
      }, Promise.resolve(list));

      if (shopId && list.length > 0) {
        dispatch(OrderActions.fetchUnCompletedAppointments(shopId));
        await setValueToLocalStorage(
          "deleted-UN_COMPLETED_APPOINTMENT_LIST",
          JSON.stringify([])
        );
      }

      return list;
    } catch (error) {
      console.warn("Catched error when deleting offline orders", error);
      return;
    }
  }

  async createOfflineProductOption(shopId: string) {
    try {
      const result = await this.API.graphql(
        graphqlOperation(fetchOptionSets, { shopId })
      );
      const optionSets = get(result, "data.fetchProductOptionSets.items", []);
      const data = await GraphqlFunctions.commonGraphqlService(
        "GET_PRODUCT_OPTION_SET",
        { shopId, type: "PRODUCT_OPTION_SET" }
      );
      if (!isEmpty(data && !data.error)) {
        await Promise.all(
          data?.map(async (productOption: any) => {
            let result: any;
            const isCreated =
              findIndex(optionSets, (set: any) => set.id === productOption.id) >
              -1;
            try {
              if (isCreated) {
                result = await this.API.graphql(
                  graphqlOperation(UpdateOption, { input: productOption })
                );
              } else {
                result = await this.API.graphql(
                  graphqlOperation(CreateOption, { input: productOption })
                );
              }
            } catch (error) {
              console.warn(
                "Catched error when creating offline option set",
                error
              );
            }
          })
        );
      }
    } catch (error) {
      console.warn("Error when creating offline option set", error);
      return;
    }
  }
}

export default new CommonRepository();
