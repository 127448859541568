import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { connect } from "react-redux";
import Plus from "../../../assets/svg/icon-plus-1.svg";
import { Field, FieldArray, Formik } from "formik";
import Minus from "../../../assets/svg/icon-minus.svg";
import { capitalize, find, get, isEmpty } from "lodash";
import Close from "../../../assets/svg/close-circle-icon.svg";
import { BsCurrencyDollar, BsPercent } from "react-icons/bs";
import { mapProductOffers } from "../../internal/manager/ProductManager";
import { getCurrencyWithPrice } from "../../internal/manager/PaymentManager";
import { Actions } from "../../internal/app/Actions";
import FormInput from "../input/Views/TextInput";

const selectStyles = {
  control: (base: any) => ({
    ...base,
    width: "367px",
    height: "100%",
    boxShadow: "none",
    color: "#F0F1FA",
    borderColor: "#F0F1FA",
    "&:hover": {
      borderColor: "#F0F1FA",
    },
  }),
  container: (base: any) => ({
    ...base,
    width: "367px",
  }),
};

const initials = {
  pid: "",
  name: "",
  type: "",
  quantity: 1,
  discount: {},
  products: [],
};

const OfferItemForm = (props: any) => {
  const { products, closeModal, isEdit, addOfferItem } = props;

  const [productMax, setProductMax] = useState<any>(0);

  const Schema = Yup.object().shape({
    pid: Yup.string().required("Please select valid product*"),
    type: Yup.string().required("Please select offer type*"),
    discount: Yup.object().shape({
      amountType: Yup.string().required("Please select amount type*"),
      amount: Yup.string()
        .required("Please enter discount amount*")
        .when("amountType", {
          is: "PERCENTAGE",
          then: Yup.string().test(
            "percentage",
            "Please enter percentage less than 100",
            (val) => {
              if (val == undefined) {
                return true;
              }
              return val.length == 0 || parseFloat(val) <= 100;
            }
          ),
          otherwise: Yup.string().test(
            "fixed amount",
            "Please enter a amount lower than the product max value",
            (val) => {
              if (val == undefined) {
                return true;
              }
              return parseFloat(val) <= productMax;
            }
          ),
        }),
    }),
    products: Yup.array().of(
      Yup.object().shape({
        pid: Yup.string().required("Please select valid product*"),
      })
    ),
  });

  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [initialValues, setInitialValues] = useState<any>(initials);
  const [validationSchema, setValidationSchema] = useState<any>(Schema);
  const [productOptions, setProductOptions] = useState<Array<any>>([]);

  useEffect(() => {
    if (!isEmpty(selectedProduct)) {
      let maxValue = 0;
      if (selectedProduct.priceVariants) {
        maxValue = selectedProduct.priceVariants.reduce(
          (acc: any, value: any) => {
            return (acc = acc > value.price ? acc : value.price);
          },
          0
        );
      } else {
        maxValue = selectedProduct.itemPrice;
      }
      setProductMax(maxValue);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (!isEmpty(products?.products)) {
      const options = mapProductOffers(products.products);
      setProductOptions([...options]);
    }
  }, [products]);

  const onSubmitOfferItem = (values: any) => {
    addOfferItem(values);
  };

  const submitButton = isEdit ? "Edit offer item" : "Add offer item";
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmitOfferItem(values)}
      >
        {({
          values,
          errors,
          touched,
          submitCount,
          handleSubmit,
          setFieldValue,
          setFieldError,
        }: any) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="overflow-auto bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 md:col-span-3">
                    <label
                      htmlFor={"offeringProduct"}
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      {"Offer applied product"}
                    </label>
                    <Select
                      styles={selectStyles}
                      options={productOptions}
                      placeholder={"Select applied product"}
                      className={
                        "block w-full h-10 shadow rounded-lg outline-none text-sm text-ship-cove border-gray-300"
                      }
                      onChange={(option) => {
                        const findProduct = find(
                          products?.products,
                          (product: any) => product.pid === option.value
                        );
                        setSelectedProduct(findProduct);
                        setFieldValue(`pid`, option.value);
                        setFieldValue(`name`, capitalize(option.label));
                      }}
                    />
                    {!isEmpty(errors?.pid) && submitCount > 0 && (
                      <span className="mb-1 text-xs font-normal text-rose-400 mr-10">
                        {errors?.pid}
                      </span>
                    )}
                  </div>
                  <div className="col-span-6 md:col-span-3" />
                  <div className="col-span-6 md:col-span-3">
                    <label
                      htmlFor={"OfferType"}
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Offer Type
                    </label>
                    <div
                      role="group"
                      onChange={(type) => {}}
                      aria-labelledby="my-radio-group"
                      className="w-full flex flex-col gap-3"
                    >
                      <label className="flex items-center gap-3 text-sm font-medium text-genteel-lavender">
                        <Field
                          name="type"
                          type="radio"
                          value="PRODUCT"
                          onChange={(event: any) => {
                            if (
                              event.target.checked &&
                              isEmpty(initialValues?.products)
                            ) {
                              const product = {
                                pid: "",
                                name: "",
                                quantity: 1,
                              };
                              setFieldValue(`discount`, {});
                              setFieldValue(`products`, [product]);
                              const updatedSchema = Schema.omit(["discount"]);
                              setValidationSchema(updatedSchema);
                            }
                            setFieldValue(`type`, "PRODUCT");
                          }}
                          className="w-4 h-4 text-medium-slate-blue bg-medium-slate-blue border-medium-slate-blue"
                        />
                        Offer with a item
                      </label>
                      <label className="flex items-center gap-3 text-sm font-medium text-genteel-lavender">
                        <Field
                          name="type"
                          type="radio"
                          value="DISCOUNT"
                          onChange={(event: any) => {
                            if (
                              event.target.checked &&
                              isEmpty(initialValues?.discount)
                            ) {
                              setFieldValue(`discount.amount`, "");
                              setFieldValue(
                                `discount.amountType`,
                                "PERCENTAGE"
                              );
                              setFieldValue(`products`, []);
                              const updatedSchema = Schema.omit(["products"]);
                              setValidationSchema(updatedSchema);
                            }
                            setFieldValue(`type`, "DISCOUNT");
                          }}
                          className="w-4 h-4 text-medium-slate-blue bg-medium-slate-blue border-medium-slate-blue rounded"
                        />
                        Offer with a discount
                      </label>
                    </div>
                    {!isEmpty(errors.type) && touched.type && (
                      <span className="mt-1 first-line:block text-xs font-normal text-rose-400">
                        {errors.type}
                      </span>
                    )}
                  </div>

                  <div className="col-span-6 md:col-span-3" />
                  {values.type === "PRODUCT" ? (
                    <FieldArray name="products">
                      {({ insert, remove, push }) => {
                        return (
                          <div className="col-span-6">
                            <label
                              htmlFor={"offeringProduct"}
                              className="block mb-2 text-sm font-medium text-gray-700"
                            >
                              {"Offering Product"}
                            </label>
                            {values.products.map(
                              (product: any, index: number) => {
                                const error: any = !isEmpty(errors)
                                  ? get(errors, `products.[${index}]`, null)
                                  : null;

                                return (
                                  <div className="flex flex-col gap-1 mb-3">
                                    <div className="flex flex-row gap-4">
                                      <Select
                                        styles={selectStyles}
                                        options={productOptions}
                                        placeholder={"Select offering product"}
                                        onChange={(option) => {
                                          const lastIndex =
                                            values.products.length - 1;
                                          setFieldValue(
                                            `products[${lastIndex}].pid`,
                                            option.value
                                          );
                                          setFieldValue(
                                            `products[${lastIndex}].name`,
                                            capitalize(option.label)
                                          );
                                        }}
                                        className={
                                          "block w-full h-10 shadow rounded-lg outline-none text-sm text-ship-cove border-gray-300"
                                        }
                                      />
                                      {/* <div className="flex flex-row justify-between items-center w-16 h-10">
                                      <img
                                        className="w-6 h-6"
                                        onClick={(e) => {}}
                                        src={Plus}
                                      />
                                      <img
                                        className="w-6 h-6"
                                        onClick={(e) => {}}
                                        src={Minus}
                                      />
                                    </div> */}
                                      <div className="flex items-center sm:w-16 w-20 h-10 ml-6">
                                        <img
                                          src={Close}
                                          alt="actions"
                                          onClick={() => remove(index)}
                                          className="w-4 h-4  cursor-pointer"
                                        />
                                      </div>
                                    </div>
                                    {!isEmpty(error) && submitCount > 0 && (
                                      <span className="text-xs font-normal text-rose-400">
                                        {error?.pid}
                                      </span>
                                    )}
                                  </div>
                                );
                              }
                            )}
                            <button
                              className="flex w-40 flex-row text-sm font-medium text-gray-700 mb-4"
                              onClick={() => {
                                const product = {
                                  pid: "",
                                  name: "",
                                  quantity: 1,
                                };
                                push(product);
                              }}
                            >
                              {"Add Offer Product"}
                              <img className="ml-2" src={Plus} />
                            </button>
                          </div>
                        );
                      }}
                    </FieldArray>
                  ) : (
                    <>
                      {values.type === "DISCOUNT" && (
                        <div className="flex flex-row gap-4 col-span-6 items-center">
                          <div className="flex flex-row gap-4">
                            <div className="w-[276px]">
                              <FormInput
                                id="amount"
                                label="Discount Amount"
                                value={values?.discount?.amount}
                                error={errors?.discount?.amount}
                                touched={touched?.discount?.amount}
                                onChange={(text: any) => {
                                  if (
                                    values?.discount?.amountType ===
                                    "PERCENTAGE"
                                  ) {
                                    if (parseFloat(text) > 100) {
                                      setFieldError(
                                        "discount.amount",
                                        "Please enter valid amount*"
                                      );
                                    }
                                  } else {
                                    if (
                                      parseFloat(text) > parseFloat(productMax)
                                    ) {
                                      setFieldError(
                                        "discount.amount",
                                        "Please enter valid amount*"
                                      );
                                    }
                                  }
                                  setFieldValue(`discount.amount`, text);
                                }}
                              />
                            </div>
                            <div className="flex flex-row justify-between items-center sm:w-16 w-20 h-8 bg-lavender rounded-md mt-8">
                              <div
                                className={`flex w-10 h-8 rounded-l-md items-center justify-center ${
                                  values?.discount?.amountType === "PERCENTAGE"
                                    ? "bg-medium-slate-blue"
                                    : "bg-lavender"
                                }`}
                              >
                                <BsPercent
                                  size={20}
                                  color={
                                    values?.discount?.amountType ===
                                    "PERCENTAGE"
                                      ? "white"
                                      : "black"
                                  }
                                  onClick={() =>
                                    setFieldValue(
                                      `discount.amountType`,
                                      "PERCENTAGE"
                                    )
                                  }
                                />
                              </div>
                              <div
                                className={`flex w-10 h-8 rounded-r-md items-center justify-center ${
                                  values?.discount?.amountType ===
                                  "FIXED_AMOUNT"
                                    ? "bg-medium-slate-blue"
                                    : "bg-lavender"
                                }`}
                              >
                                <BsCurrencyDollar
                                  size={20}
                                  color={
                                    values?.discount?.amountType ===
                                    "FIXED_AMOUNT"
                                      ? "white"
                                      : "black"
                                  }
                                  onClick={() =>
                                    setFieldValue(
                                      `discount.amountType`,
                                      "FIXED_AMOUNT"
                                    )
                                  }
                                />
                              </div>
                            </div>
                            {!isEmpty(selectedProduct) && (
                              <div className="flex h-8 mt-8 items-center justify-center text-start text-speech-blue text-md font-medium">
                                {`Max: ${getCurrencyWithPrice(productMax)}`}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-row justify-between items-center bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-lg">
                {isEdit ? (
                  <button
                    type="button"
                    onClick={() => {}}
                    className="inline-flex min-w-[154px] outline-none justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-red-700"
                  >
                    {"Delete Offer"}
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => closeModal()}
                    className="inline-flex min-w-[154px] outline-none justify-center rounded-md border border-lavender bg-dismiss-button py-2 px-4 text-sm font-medium text-valhalla shadow "
                  >
                    {"Close"}
                  </button>
                )}
                <button
                  type="submit"
                  className="inline-flex min-w-[154px] justify-center rounded-md border border-transparent bg-medium-slate-blue py-2 px-4 text-sm font-medium text-white shadow hover:bg-slate-blue"
                >
                  {submitButton}
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
export default connect(
  (state: any) => ({
    products: state.product.get("products"),
    currentUser: state.setting.get("currentUser"),
    loadingAction: state.common.get("loadingAction"),
    selectedShop: state.dashboard.get("selectedShop"),
  }),
  {
    createReward: Actions.product.createReward,
    updateReward: Actions.product.updateReward,
    deleteReward: Actions.product.deleteReward,
  }
)(OfferItemForm);
