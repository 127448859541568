import Repository from "./Repository";
import {
  GetMenus,
  CreateMenu,
  UpdateMenu,
  DeleteMenu,
} from "./schema/Menu.schema";
import {
  CreateBrand,
  UpdateBrand,
  DeleteBrand,
  FetchProductTypes,
  CreateProductType,
  UpdateProductType,
  DeleteProductType,
} from "./schema/Product.schema";
import {
  CreateOption,
  CreateSupplier,
  UpdateSupplier,
  DeleteSupplier,
  fetchOptionSets,
  UpdateOption,
} from "./schema/Supplier.schema";
import {
  downloadImages,
  setValueToLocalStorage,
  updateCurrentStorageData,
  deleteCurrentStorageData,
  getValueFromLocalStorage,
} from "../manager/CommonManager";
import _, { get, isArray, isEmpty, uniqBy } from "lodash";
import StorageRepository from "./StorageRepository";
import { generateFileId } from "../manager/ImageManager";
import { getProductOptionSets, searchProduct } from "../manager/ProductManager";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import GraphqlFunctions from "../service/Graphql.functions";
import { isNetworkError } from "../manager/AppointmentManager";

class ProductRepository extends Repository {
  async createBrand(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "BRANDS",
          param,
          param.id,
          "id"
        );
        return newParam;
      }
      const result: any = await this.API.graphql(
        graphqlOperation(CreateBrand, { input: param })
      );
      return result.data.createBrand;
    } catch (error) {
      console.warn("create brand error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createBrand(param, ++retryCount);
      }
      return { error };
    }
  }

  async fetchBrandsForShopId(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("BRANDS");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }
      const message = this.buildMessage(param);

      const result = await this.apiPost({
        apiName: this.DASHBOARD_API,
        path: "/brands",
        message,
      });
      await setValueToLocalStorage(
        "BRANDS",
        JSON.stringify(result.brandResult)
      );
      return result.brandResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchBrandsForShopId(param, ++retryCount);
      }
      return { error };
    }
  }

  async createProductCategory(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "PRODUCT_CATEGORIES",
          param,
          param.categoryId,
          "categoryId"
        );
        return newParam;
      }

      const message = this.buildMessage(param);

      const result = await this.apiPost({
        apiName: this.DASHBOARD_API,
        path: "/product/category",
        message,
      });
      return result.categoryResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createProductCategory(param, ++retryCount);
      }
      return { error };
    }
  }

  async searchProduct(param) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline || param.categoryId === "ALL") {
        const list = await getValueFromLocalStorage("ALL_PRODUCTS");
        if (list) {
          const newList = JSON.parse(list);
          const filterList =
            param.categoryId === "ALL"
              ? newList.Items
              : _.filter(
                  newList.Items,
                  (item) =>
                    item.shopId === param.shopId &&
                    item.categoryId === param.categoryId
                );
          const searchList = searchProduct(filterList, param.searchText);
          return searchList;
        }

        return [];
      }

      const message = this.buildMessage(param);

      const result = await this.apiPost({
        apiName: this.DASHBOARD_API,
        path: "/products/search-text",
        message,
      });
      return result.productResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchProductCategories(shopId, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("PRODUCT_CATEGORIES");
        if (list) {
          const newList = JSON.parse(list);
          return newList.Items;
        }

        return;
      }

      const message = this.buildMessage({ shopId });

      const result = await this.apiPost({
        apiName: this.DASHBOARD_API,
        path: "/products/category",
        message,
      });

      await setValueToLocalStorage(
        "PRODUCT_CATEGORIES",
        JSON.stringify({ Items: result.categoryResult })
      );
      return result.categoryResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchProductCategories(shopId, ++retryCount);
      }
      return { error };
    }
  }

  async fetchProductForCategoryId(sid, categoryId) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("ALL_PRODUCTS");
        if (list) {
          const newList = JSON.parse(list);
          const filterList = _.filter(
            newList.Items,
            (item) => item.shopId === sid && item.categoryId === categoryId
          );
          return { Items: filterList };
        }

        return { Items: [] };
      }

      const message = this.buildMessage({ sid, categoryId });

      const result = await this.apiPost({
        apiName: this.DASHBOARD_API,
        path: "/products",
        message,
      });
      return result.productResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchAllProducts(sid, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("ALL_PRODUCTS");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }
      const message = this.buildMessage({ sid, limit: -1 });
      const result = await this.apiPost({
        apiName: this.DASHBOARD_API,
        path: "/products",
        message,
      });
      await setValueToLocalStorage(
        "ALL_PRODUCTS",
        JSON.stringify(result.productResult)
      );
      downloadImages(result.productResult);
      return result.productResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchAllProducts(sid, ++retryCount);
      }
      return { error };
    }
  }

  async updateBrand(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "BRANDS",
          param,
          param.id,
          "id",
          true
        );
        return newParam;
      }
      const result: any = await this.API.graphql(
        graphqlOperation(UpdateBrand, { input: param })
      );
      return result.data.updateBrand;
    } catch (error) {
      console.warn("update brand error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.updateBrand(param, ++retryCount);
      }
      return { error };
    }
  }

  async deleteBrand(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await deleteCurrentStorageData(
          "BRANDS",
          param,
          param.id,
          "id"
        );
        return newParam;
      }

      const result: any = await this.API.graphql(
        graphqlOperation(DeleteBrand, { input: param })
      );
      return result.data.deleteBrand;
    } catch (error) {
      console.warn("delete brand error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteBrand(param, ++retryCount);
      }
      return { error };
    }
  }

  async createSupplier(param) {
    try {
      const result: any = await this.API.graphql(
        graphqlOperation(CreateSupplier, { input: param })
      );
      return result.data.createSupplier;
    } catch (error) {
      console.warn("create supplier error", error);
      return { error };
    }
  }

  async updateSupplier(param) {
    try {
      const result: any = await this.API.graphql(
        graphqlOperation(UpdateSupplier, { input: param })
      );
      return result.data.updateSupplier;
    } catch (error) {
      console.warn("update supplier error", error);
      return { error };
    }
  }

  async deleteSupplier(param) {
    try {
      const result: any = await this.API.graphql(
        graphqlOperation(DeleteSupplier, { input: param })
      );
      return result.data.deleteSupplier;
    } catch (error) {
      console.warn("delete supplier error", error);
      return { error };
    }
  }

  updateProductOptions = async (data: any) => {
    try {
      data['updatedTime'] = Date.now();
      await this.API.graphql(
        graphqlOperation(UpdateOption, { input: data })
      );
    } catch (error) {
      console.warn("update product options error", error);
    }
  };

  createProductOptions = async (data: any) => {
    const productOptions: any = await getProductOptionSets(data);
    await Promise.all(
      productOptions.map(async (productOption: any) => {
        try {
          await this.API.graphql(
            graphqlOperation(CreateOption, { input: productOption })
          );
        } catch (error) {
          console.warn(error);
          if (isArray(error?.errors)) {
            const conditional = error.errors.find(
              ({ errorType }) =>
                errorType === "DynamoDB:ConditionalCheckFailedException"
            );
            if (conditional) {
              this.updateProductOptions(productOption);
            } else {
              throw error;
            }
          }
        }
      })
    );
  };

  setOfflineProductOptions = async (data: any) => {
    const productOptions: any = await getProductOptionSets(data);
    await GraphqlFunctions.commonGraphqlService("SET_PRODUCT_OPTION_SET", {
      productOptions: productOptions,
      type: "PRODUCT_OPTION_SET",
    });
  };

  async createProduct(param: any, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "ALL_PRODUCTS",
          param,
          param.pid,
          "pid"
        );
        if (!isEmpty(get(param, "productOptions", null))) {
          this.setOfflineProductOptions(param.productOptions);
        }
        return newParam;
      }
      const message = this.buildMessage(param);
      const result = await this.apiPost({
        apiName: this.DASHBOARD_API,
        path: "/product",
        message,
      });
      if (!isEmpty(get(param, "productOptions", null))) {
        await this.createProductOptions(param.productOptions);
      }
      return result.productResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createProduct(param, ++retryCount);
      }
      return { error };
    }
  }

  async updateProduct(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "ALL_PRODUCTS",
          param,
          param.pid,
          "pid",
          true
        );
        if (!isEmpty(get(param, "productOptions", null))) {
          this.setOfflineProductOptions(param.productOptions);
        }
        return newParam;
      }
      const message = this.buildMessage(param);
      const result = await this.apiPut({
        apiName: this.DASHBOARD_API,
        path: "/product",
        message,
      });
      if (!isEmpty(get(param, "productOptions", null))) {
        await this.createProductOptions(param.productOptions);
      }
      return result.productResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.updateProduct(param, ++retryCount);
      }
      return { error };
    }
  }

  async deleteProduct(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await deleteCurrentStorageData(
          "ALL_PRODUCTS",
          param,
          param.productId,
          "pid"
        );
        return newParam;
      }

      const message = this.buildMessage(param);

      const result = await this.apiDelete({
        apiName: this.DASHBOARD_API,
        path: "/product",
        message,
      });

      return result.productResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteProduct(param, ++retryCount);
      }
      return { error };
    }
  }

  async updateProductCategory(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "PRODUCT_CATEGORIES",
          param,
          param.categoryId,
          "categoryId",
          true
        );
        return newParam;
      }

      const message = this.buildMessage(param);

      const result = await this.apiPut({
        apiName: this.DASHBOARD_API,
        path: "/productCategory",
        message,
      });

      return result.productCategoryResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.updateProductCategory(param, ++retryCount);
      }
      return { error };
    }
  }

  async deleteProductCategory(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await deleteCurrentStorageData(
          "PRODUCT_CATEGORIES",
          param,
          param.categoryId,
          "categoryId"
        );
        return newParam;
      }

      const message = this.buildMessage(param);

      const result = await this.apiDelete({
        apiName: this.DASHBOARD_API,
        path: "/productCategory",
        message,
      });

      return result.productCategoryResult;
    } catch (error) {
      console.warn("error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteProductCategory(param, ++retryCount);
      }
      return { error };
    }
  }

  async uploadProductImage(file: any) {
    try {
      const fileId = generateFileId(file);
      const result: any = await StorageRepository.uploadImage(fileId, file);
      return result.key;
    } catch (error) {
      console.warn("uploading error", error);
      return { error };
    }
  }

  async fetchFavoriteProducts(shopId) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("ALL_PRODUCTS");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }
      const message = this.buildMessage({ shopId });
      const result = await this.apiGet({
        apiName: this.CLOUD_POS_API,
        path: `/favourite-products?shopId=${shopId}`,
        message,
      });
      await setValueToLocalStorage(
        "ALL_PRODUCTS",
        JSON.stringify(result.favouriteProducts)
      );
      return result.favouriteProducts;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchSalonSuppliers(shopId, endPointName) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("SALON_SUPPLIERS");
        if (list) {
          return JSON.parse(list);
        }
        return;
      }
      const message = this.buildMessage({ shopId, endPointName });
      const result = await this.apiPost({
        apiName: this.DASHBOARD_API,
        path: `/suppliers`,
        message,
      });
      await setValueToLocalStorage(
        "SALON_SUPPLIERS",
        JSON.stringify(result.supplierResult)
      );
      return result.supplierResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchProductForBrandId(shopId, brandId) {
    const message = this.buildMessage(null);
    try {
      const result = await this.apiGet({
        apiName: this.CLOUD_POS_LAMBDA_API,
        path: `/product/brand-id?shopId=${shopId}&brandId=${brandId}`,
        message,
      });
      return result.data.productResult;
    } catch (error) {
      console.warn("error", error);
      return { error };
    }
  }

  async fetchMenu(shopId, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("MENU");
        if (list) {
          const newList = JSON.parse(list);
          return newList.Items;
        }

        return [];
      }

      const result: any = await this.API.graphql(
        graphqlOperation(GetMenus, { shopId })
      );
      await setValueToLocalStorage(
        "MENU",
        JSON.stringify({ Items: result.data.getMenus })
      );
      return result.data.getMenus;
    } catch (error) {
      console.warn("create product menu error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchMenu(shopId, ++retryCount);
      }
      return { error };
    }
  }

  async createMenu(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "MENU",
          param,
          param.menuId,
          "menuId"
        );
        return newParam;
      }

      const result: any = await this.API.graphql(
        graphqlOperation(CreateMenu, { input: param })
      );
      return result.data.createMenu;
    } catch (error) {
      console.warn("create product menu error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createMenu(param, ++retryCount);
      }
      return { error };
    }
  }

  async updateMenu(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "MENU",
          param,
          param.menuId,
          "menuId",
          true
        );
        return newParam;
      }
      const result: any = await this.API.graphql(
        graphqlOperation(UpdateMenu, { input: param })
      );
      return result.data.updateMenu;
    } catch (error) {
      console.warn("update product menu error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.updateMenu(param, ++retryCount);
      }
      return { error };
    }
  }

  async deleteMenu(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await deleteCurrentStorageData(
          "MENU",
          param,
          param.menuId,
          "menuId"
        );
        return newParam;
      }
      const result: any = await this.API.graphql(
        graphqlOperation(DeleteMenu, { input: param })
      );
      return result.data.deleteMenu;
    } catch (error) {
      console.warn("delete product menu error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteMenu(param, ++retryCount);
      }
      return { error };
    }
  }

  async fetchProductTypes(shopId, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("PRODUCT_TYPES");
        if (list) {
          const newList = JSON.parse(list);
          return newList.Items;
        }

        return [];
      }

      const result: any = await this.API.graphql(
        graphqlOperation(FetchProductTypes, { shopId })
      );
      await setValueToLocalStorage(
        "PRODUCT_TYPES",
        JSON.stringify({ Items: result.data.fetchProductTypes })
      );
      return result.data.fetchProductTypes;
    } catch (error) {
      console.warn("fetch product types error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchProductTypes(shopId, ++retryCount);
      }
      return { error };
    }
  }

  async createProductType(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "PRODUCT_TYPES",
          param,
          param.id,
          "id"
        );
        return newParam;
      }

      const result: any = await this.API.graphql(
        graphqlOperation(CreateProductType, { input: param })
      );
      return result.data.createProductType;
    } catch (error) {
      console.warn("create product type error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createProductType(param, ++retryCount);
      }
      return { error };
    }
  }

  async updateProductType(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "PRODUCT_TYPES",
          param,
          param.id,
          "id",
          true
        );
        return newParam;
      }

      const result: any = await this.API.graphql(
        graphqlOperation(UpdateProductType, { input: param })
      );
      return result.data.updateProductType;
    } catch (error) {
      console.warn("update product type error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.updateProductType(param, ++retryCount);
      }
      return { error };
    }
  }

  async deleteProductType(param, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await deleteCurrentStorageData(
          "PRODUCT_TYPES",
          param,
          param.id,
          "id"
        );
        return newParam;
      }

      const result: any = await this.API.graphql(
        graphqlOperation(DeleteProductType, { input: param })
      );
      return result.data.deleteProductType;
    } catch (error) {
      console.warn("delete product type error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteProductType(param, ++retryCount);
      }
      return { error };
    }
  }

  async createStock(param, retryCount = 1) {
    try {
      const message = this.buildMessage(param);

      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "STOCK",
          param,
          param.stockId,
          "stockId"
        );
        return newParam;
      }

      const result = await this.apiPost({
        apiName: this.API_NETLISE_APP,
        path: `/stock`,
        message,
      });
      return result.stockResult;
    } catch (error) {
      console.warn("create stock error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createStock(param, ++retryCount);
      }
      return { error };
    }
  }

  async fetchStock(shopId, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("STOCK");
        if (list) {
          const newList = JSON.parse(list);
          return newList;
        }

        return [];
      }

      const result = await this.apiGet({
        apiName: this.API_NETLISE_APP,
        path: `/stocks?shopId=${shopId}`,
      });
      await setValueToLocalStorage("STOCK", JSON.stringify(result.stockResult));
      return result.stockResult;
    } catch (error) {
      console.warn("fetch stock error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchStock(shopId, ++retryCount);
      }
      return { error };
    }
  }

  async deleteStock(param, retryCount = 1) {
    try {
      const message = this.buildMessage(param);
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await deleteCurrentStorageData(
          "STOCK",
          param,
          param.stockId,
          "stockId"
        );
        return newParam;
      }

      const result = await this.apiDelete({
        apiName: this.API_NETLISE_APP,
        path: `/stock`,
        message,
      });
      return result.stockResult;
    } catch (error) {
      console.warn("delete product menu error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteStock(param, ++retryCount);
      }
      return { error };
    }
  }

  fetchShopOptionSet = async (
    shopId: string,
    limit: number,
    nextToken: any,
    retryCount: number = 1
  ) => {
    const isOnline = await this.isCheckOnline();
    try {
      let data: any = [];
      let token: any = null;
      if (isOnline) {
        try {
          const params = {
            shopId,
            limit,
            nextToken,
          };
          const result = await this.API.graphql(
            graphqlOperation(fetchOptionSets, params)
          );
          data = get(result, "data.fetchProductOptionSets.items", []);
          token = get(result, "data.fetchProductOptionSets.nextToken", null);
          await GraphqlFunctions.commonGraphqlService(
            "SET_PRODUCT_OPTION_SET",
            {
              productOptions: data,
              type: "PRODUCT_OPTION_SET",
            }
          );
        } catch (error) {
          throw error;
        }
      } else {
        data = await GraphqlFunctions.commonGraphqlService(
          "GET_PRODUCT_OPTION_SET",
          { shopId, type: "PRODUCT_OPTION_SET" }
        );
      }
      return { data, nextToken: token };
    } catch (error) {
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchShopOptionSet(
          shopId,
          limit,
          nextToken,
          ++retryCount
        );
      }
      console.warn("fetch option sets error", error);
      return { error };
    }
  };
}
export default new ProductRepository();
