import ServiceRepository from '../../internal/repos/ServiceRepository';
import ProductRepository from "../../internal/repos/ProductRepository";

export const ModuleEvents = {
  OPEN_SERVICE_KEYPAD: 'OPEN_SERVICE_KEYPAD',
  SET_PURCHASE_SERVICE: 'SET_PURCHASE_SERVICE',
  SELECT_SERVICE: 'SELECT_SERVICE',
  DELETE_PURCHASE_SERVICE: 'DELETE_PURCHASE_SERVICE',
  REMOVE_SELECTED_SERVICE: 'REMOVE_SELECTED_SERVICE',
  SELECT_SERVICE_ITEM: 'SELECT_SERVICE_ITEM',
  CHANGE_SERVICE_QUANTITY: 'CHANGE_SERVICE_QUANTITY',
  FETCH_SALON_SERVICE: 'FETCH_SALON_SERVICE',
  ON_SERVICE_CREATE_SUBSCRIPTION: 'ON_SERVICE_CREATE_SUBSCRIPTION',
  ON_SERVICE_UPDATE_SUBSCRIPTION: 'ON_SERVICE_UPDATE_SUBSCRIPTION',
  ON_SERVICE_DELETE_SUBSCRIPTION: 'ON_SERVICE_DELETE_SUBSCRIPTION',
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  SEARCH_CATEGORIES: 'SEARCH_CATEGORIES',
  UPDATE_SERVICE_CATEGORY: 'UPDATE_SERVICE_CATEGORY',
  NAVIGATE_EDIT_SERVICE_VIEW: 'NAVIGATE_EDIT_SERVICE_VIEW',
  CLEAR_SELECTED_SERVICE_CATEGORY: 'CLEAR_SELECTED_SERVICE_CATEGORY',
  SELECT_SERVICE_SIDE_BAR_VIEW: 'SELECT_SERVICE_SIDE_BAR_VIEW',
  NAVIGATE_EDIT_CATEGORY_VIEW: 'NAVIGATE_EDIT_CATEGORY_VIEW',
  SELECT_CATEGORY: 'SELECT_CATEGORY',
  UPLOAD_SERVICE_IMAGE: 'UPLOAD_SERVICE_IMAGE',
  REMOVE_SERVICE_IMAGE: 'REMOVE_SERVICE_IMAGE',
  CREATE_SERVICE_CATEGORY: 'CREATE_SERVICE_CATEGORY'
};

const createAction:any = (type, action:any = () => {}, meta) => {
  return (...args) => ({ type, payload: action(...args), meta });
};

export default {
  openServiceKeyPad: createAction(ModuleEvents.OPEN_SERVICE_KEYPAD, async (status) => {
    return status;
  }),
  selectService: createAction(ModuleEvents.SELECT_SERVICE, async (data) => {
    return data;
  }),

  removeSelectedService: createAction(ModuleEvents.REMOVE_SELECTED_SERVICE, async () => {
    return true;
  }),
  selectServiceItem: createAction(ModuleEvents.SELECT_SERVICE_ITEM, async (data) => {
    return data;
  }),
  changeServiceQuantity: createAction(ModuleEvents.CHANGE_SERVICE_QUANTITY, async (data, status) => {
    return {data, status};
  }),

  setPurchaseService: createAction(ModuleEvents.SET_PURCHASE_SERVICE, async (data) => {
    return data;
  }),
  deletePurchaseService: createAction(ModuleEvents.DELETE_PURCHASE_SERVICE, async (data) => {
    return data;
  }),
  onServiceCreateSubscription: createAction(ModuleEvents.ON_SERVICE_CREATE_SUBSCRIPTION, async (data) => {
    return data;
  }),
  onServiceUpdateSubscription: createAction(ModuleEvents.ON_SERVICE_UPDATE_SUBSCRIPTION, async (data) => {
    return data;
  }),
  onServiceDeleteSubscription: createAction(ModuleEvents.ON_SERVICE_DELETE_SUBSCRIPTION, async (data) => {
    return data;
  }),
  fetchCategories: createAction(ModuleEvents.FETCH_CATEGORIES, async (shopId) => {
    const result = await ServiceRepository.fetchShopCategories(shopId);
    if (Array.isArray(result)) {
      return result[0];
    }
  }, {loading: true}),
  searchCategories: createAction(ModuleEvents.SEARCH_CATEGORIES, async (shopId, categoryId, searchText) => {
    const result = await ServiceRepository.searchSalonServices(shopId, categoryId, searchText);
    return result;
  }, {loading: true}),
  createServiceCategory: createAction(ModuleEvents.CREATE_SERVICE_CATEGORY, async (param, type='') => {
    const result = await ServiceRepository.createServiceCategory(param);
    return {result, type};
  }, {loading: true, blocking: true, type: 'loading'}),

  updateServiceCategory: createAction(ModuleEvents.UPDATE_SERVICE_CATEGORY, async (param, type='', serviceData) => {
    const result = await ServiceRepository.updateServiceCategory(param);
    return {result, type, serviceData};
  }, {loading: true, blocking: true, type: 'loading'}),

  navigateEditServiceView: createAction(ModuleEvents.NAVIGATE_EDIT_SERVICE_VIEW, async (data) => {
    return data;
  }),
  navigateEditCategoryView: createAction(ModuleEvents.NAVIGATE_EDIT_CATEGORY_VIEW, async (data) => {
    return data;
  }),
  clearSelectedServiceCategory: createAction(ModuleEvents.CLEAR_SELECTED_SERVICE_CATEGORY, async (path) => {
    return path;
  }),
  selectServiceSideBarView: createAction(ModuleEvents.SELECT_SERVICE_SIDE_BAR_VIEW, async (view) => {
    return view;
  }),
  selectCategory: createAction(ModuleEvents.SELECT_CATEGORY, async (data) => {
    return data;
  }),
  uploadServiceImage: createAction(ModuleEvents.UPLOAD_SERVICE_IMAGE, async (file) => {
    const result = await ServiceRepository.uploadServiceImage(file);
    return result;
  }, {loading: true}),
  removeServiceImage: createAction(ModuleEvents.REMOVE_SERVICE_IMAGE, async () => {
    return true;
  })
}
