export const AppModules = [
  'login',
  'salon',
  'table',
  'rooms',
  'stock',
  'offer',
  'common',
  'orders',
  'product',
  'service',
  'payment',
  'invoice',
  'reports',
  'profile',
  'employee',
  'customer',
  'warranty',
  'closeCash',
  'activityLog',
];

export default AppModules;
