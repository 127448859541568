import styled from "styled-components";

export const OrderQueueContainer = styled.div<{ height: string }>`
      width: 100%;
      height: 100vh;
`;

export const OrderQueueBottomContainer = styled.div<{ height: string }>`
      // width: 100%;
      padding: 10px;
      background-color: #eff3f6;
      height: calc(100vh - 61px);
`;

export const RefundButtonContainer = styled.div<{valid: boolean}>`
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 10px;

      &> div {
            height: '100%';
            background-color: ${props => props.valid ? '#49a4d4' : '#f5f5f5'};
            border: ${props => props.valid ? '1px solid #ffffff' : '1px solid #cccccc'};
            color: ${props => props.valid ? '#ffffff' : '#cccccc'};
            padding: 16px 14px;
            max-width: 289px;
            height: 67px;
            text-align: center;
            font-size: 16px;
            border-radius: 3px;
            transition-duration: 0.4s;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-family: 'Montserrat';
font-weight: 600;
            
            :focus {
                outline: none;
            }
      }
      
        .closeCashReceiptButton {
            height: '100%';
            background-color: #F5F5F5;
            border: 1px solid #4A4A4A;
            color: #4A4A4A;
            padding: 16px 14px;
            max-width: 289px;
            height: 67px;
            text-align: center;
            font-size: 16px;
            border-radius: 4px;
            transition-duration: 0.4s;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-family: 'Montserrat';
font-weight: 600;
            
            :focus {
                outline: none;
            }
      }
`;
