import React, {useEffect, useState} from 'react';
import TopNavigationBar from '../../../components/TopNavigation/Views/TopNavigationBar';
import {connect} from "react-redux";
import moment from 'moment';
import Button from '@material-ui/core/Button';
import IconArrowLeft from '../../../../assets/svg/IconArrowLeft.svg';
import IconArrowRight from '../../../../assets/svg/IconArrowRight.svg';
import IconPlus from '../../../../assets/svg/IconPlus.svg';
import {classifyAppointments, getTimeColumn} from "../../../internal/manager/AppointmentManager";
import AppointmentCalendarDetails from "../../../components/AppointmentCalendarDetails";
import {Actions} from "../../../internal/app/Actions";
import {CalendarContainer, CalendarBottomContainer} from './Styled';
import _ from 'lodash';
import {navigate} from "../../../internal/service/Navigation.service";

const dayMillis = 86400000;
const today = moment(new Date(Date.now()).toDateString()).valueOf();

const CalendarView = (props) => {
  const {salon, appointmentsForDate, fetchAppointmentForDate, stylists} = props;
  const [selectedDate, setSelectedDate] = useState(today);
  const [data, setData] = useState(new Map());
  const [timeColumn, setTimeColumn] = useState(getTimeColumn(selectedDate, salon));
  const [isClosed, setIsClosed] = useState(false);
  const [isOpenClosedTimeNotAvailable, setIsOpenClosedTimeNotAvailable] = useState(false);

  useEffect(() => {
    const dateTime = getTimeColumn(selectedDate, salon);
    setTimeColumn(dateTime);
    fetchAppointmentForDate(salon.shopId, selectedDate);
    const filterDay = _.find(salon.schedule, (day) => day.day === moment(selectedDate).format('dddd'));
    const isOpenClosedTimeNotAvailable = _.isEmpty(filterDay.start) && _.isEmpty(filterDay.end);
    setIsOpenClosedTimeNotAvailable(isOpenClosedTimeNotAvailable);
    setIsClosed(filterDay.isClosed);
  }, [selectedDate]);

  useEffect(() => {
    const appointments = _.filter(appointmentsForDate, item => !_.isEmpty(_.get(item, 'stylistId', null)));
    const value = classifyAppointments(timeColumn, appointments);
    setData(value);
  }, [appointmentsForDate]);

  const onPressArrow = (type) => {
    if (type === 'RIGHT') {
      setSelectedDate(selectedDate + dayMillis);
    } else {
      setSelectedDate(selectedDate - dayMillis);
    }
  };

  const renderDate = (selectedDate) => {
    return moment(selectedDate).format('dddd MMM DD, YYYY')
  };

  return (
    <CalendarContainer>
      <TopNavigationBar viewName={"Calendar view"} path="/menu"/>
      <CalendarBottomContainer>
        <div>
          <div>
            <div/>
            <div>
              <Button onClick={() => onPressArrow('LEFT')}><img src={IconArrowLeft}/></Button>
              <div> {renderDate(selectedDate)}</div>
              <Button onClick={() => onPressArrow('RIGHT')}><img src={IconArrowRight}/></Button>
            </div>
            <div onClick={() => navigate('product')}>
              <div>Add appointment</div>
              <img src={IconPlus}/>
            </div>
          </div>
          <div>
            {
              !_.isEmpty(appointmentsForDate) ?
                <AppointmentCalendarDetails
                  data={data}
                  timeColumn={timeColumn}
                  stylists={stylists}
                />
                : isOpenClosedTimeNotAvailable ?
                <div className={'calendarEmptyAppointment'}>
                  <div>{'Salon open-closed time not available'}</div>
                </div>
                :
                <div className={'calendarEmptyAppointment'}>
                  <div>{'No appointments found'}</div>
                  <div>{'There are no appointments for this date!'}</div>
                </div>
            }

          </div>
        </div>
      </CalendarBottomContainer>
    </CalendarContainer>
  )
};

export default connect(
  state => ({
    salon: state.login.get('salonData'),
    stylists: state.employee.get('employees'),
    appointmentsForDate: state.orders.get('appointmentsForDate'),
  }),
  ({
    fetchAppointmentForDate: Actions.orders.fetchAppointmentForDate,

  })
)(CalendarView);



