import React, {useEffect, Component, useState} from 'react';
import styles from '../../Styles/Styles.css';
import {Container, Row, Col} from 'react-grid-system';
import SortIcon from '../../../../assets/icons/sortIcon.svg'
import defaultStylistImage from '../../../../assets/icons/user4.svg';
import {connect} from "react-redux";
import {Actions} from "../../../internal/app/Actions";
import {generateImageUrl} from '../../../internal/manager/ImageManager';
import moment from 'moment';
import {getAppointmentDateTime} from "../../../internal/manager/AppointmentManager";
import _ from 'lodash';
import {getCurrencyWithPrice} from "../../../internal/manager/PaymentManager";


const Invoice = (props) => {
  const {appointments, salon, fetchSalonAppointment, shopId, fetchAllAppointments, onPressAppointment} = props;
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    fetchSalonAppointment(shopId);
    fetchAllAppointments();
  }, []);

  const onClickAppointment = (data, index) => {
    setSelectedIndex(index);
    onPressAppointment(data);
  };

  return (
    <div className={styles.invoiceTableWrapper}>
      <div className={styles.invoiceTableRow}>
        <p className={styles.invoiceTableHeaderText}>All appointments</p>
      </div>
      <div style={{overflow: 'scroll', height: window.innerHeight - 180}}>
        <div className={styles.invoiceTableTopHeader}>
          <Row>
            <Col md={4} className={styles.invoiceTableRowSortIconContainer}>
              <div className={styles.invoiceTableTopHeaderText}>
                Employee
              </div>
              <img className={styles.invoiceTableRowSortIcon} src={SortIcon}/>
            </Col>
            <Col md={3} className={styles.invoiceTableRowSortIconContainer}>
              <div className={styles.invoiceTableTopHeaderText}>
                Order date
              </div>
              <img className={styles.invoiceTableRowSortIcon} src={SortIcon}/>
            </Col>
            <Col md={3} className={styles.invoiceTableRowSortIconContainer}>
              <div className={styles.invoiceTableTopHeaderText}>
                Customer name
              </div>
              <img className={styles.invoiceTableRowSortIcon} src={SortIcon}/>
            </Col>
            <Col md={2} className={styles.invoiceTableRowSortIconContainer}>
              <div className={styles.invoiceTableTopHeaderText}>
                Total
              </div>
              <img className={styles.invoiceTableRowSortIcon} src={SortIcon}/>
            </Col>
          </Row>
        </div>
        <div>
          {
            appointments && appointments.map((data, index) => {
              const dateTime = getAppointmentDateTime(data);
              let duration = 0;
              data.serviceDetails.forEach(item => {
                  let serviceSplit = _.split(item.duration, ':');
                  duration += parseInt(serviceSplit[0]) * 60 + parseInt(serviceSplit[1]);
                }
              );
              const total = data.serviceDetails.map(item => item.price).reduce((prev, curr) => prev + curr, 0);
              const currencyPrice = getCurrencyWithPrice(total, salon.currency);
              const startTime = dateTime.time;
              const endTime = moment(startTime, 'hh:mm').add(duration, 'minutes').format('hh:mmA');
              const specialization = _.get(data, 'stylist.specializations', []).slice(0, 2).map(item => item.title).join(', ');
              return (
                <div onClick={()=> onClickAppointment(data, index)} style={{backgroundColor: index === selectedIndex ? '#F8F9FB' : '#FFFFFF'}} className={styles.invoiceTableBody}>
                  <Row style={{paddingLeft: '10px'}}>
                    <Col md={4} className={styles.invoiceTableBodyRowContainer}>
                      <img className={styles.invoiceTableStylistImage} src={data.stylistImage ? generateImageUrl(data.stylistImage) : defaultStylistImage}/>
                      <div style={{marginLeft: '10px'}}>
                        <div className={styles.invoiceTableBodyText}>{data.stylistName}</div>
                        <div className={styles.invoiceTableStylistSubHeaderText}>{specialization}</div>
                      </div>
                    </Col>
                    <Col md={3} className={styles.invoiceTableBodyRowContainerInfo}>
                      <div className={styles.invoiceTableBodyText}>
                        {moment(dateTime.date, 'DD/MM/YYYY').format('MMM DD, YYYY')}
                      </div>
                      <div className={styles.invoiceTableBodyText}>
                        {`${startTime} - ${endTime}`}
                      </div>
                    </Col>
                    <Col md={3} className={styles.invoiceTableBodyRowContainerInfo}>
                      <div className={styles.invoiceTableBodyText}>
                        {_.upperFirst(data.name)}
                      </div>
                    </Col>
                    <Col md={2} className={styles.invoiceTableBodyRowContainerInfo}>
                    <div className={styles.invoiceTableBodyText}>
                        {currencyPrice}
                      </div>
                    </Col>
                  </Row>
                  {
                    (appointments.length - index) !== 1 ?
                      <div className={styles.invoiceTableBottomLine}/>
                      : null
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    salon: state.salon.get('salon'),
    shopId: state.common.get('shopId'),
  }),
  {
    fetchSalonAppointment: Actions.orders.fetchSalonAppointment,
    fetchAllAppointments: Actions.orders.fetchAllAppointments
  }
)(Invoice);
