import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {
  BookingDetailsHeader,
  ServiceCardNameWrapper,
  BookingDetailsContainer,
  ServiceCardImageWrapper,
  BookingDetailsBottomButton,
  PurchaseDetailsGroupWrapper,
  BookingDetailsBottomService,
  BookingDetailsBottomContainer,
  BookingDetailsAppointmentClear,
  BookingDetailsAppointmentHeader,
  BookingDetailsBottomServiceItem,
  BookingDetailsBottomServiceName,
  BookingDetailsBottomServiceCount,
  BookingDetailsBottomServiceItemLine,
} from "../../components/Styles/Styled";
import {
  getTotalPrice,
  getOffersPrice,
  getCurrencyWithPrice,
  getAppointmentTotalPrice,
} from "../../internal/manager/PaymentManager";
import { Actions } from "../../internal/app/Actions";
import AddIcon from "../../../assets/icons/addIcon.svg";
import backIcon from "../../../assets/icons/backIcon.svg";
import MinusIcon from "../../../assets/icons/minusIcon.svg";
import _, { startCase, get, groupBy, isEmpty } from "lodash";
import { navigate } from "../../internal/service/Navigation.service";
import bookingRemoveIcon from "../../../assets/icons/bookingRemoveIcon.svg";

const BookingDetailsComponent = (props) => {
  const {
    shopData,
    currentUser,
    orderOffers,
    purchaseProducts,
    purchaseServices,
    appointmentDiscounts,
    changeServiceQuantity,
    changeProductQuantity,
    clearCurrentAppointment,
  } = props;
  const { formatMessage: f } = useIntl();
  let purchaseList = purchaseServices.concat(purchaseProducts);
  let subTotalPrice = getTotalPrice(purchaseList);
  let { total: totalPrice, serviceCharge } = getAppointmentTotalPrice(
    purchaseList,
    appointmentDiscounts,
    shopData,
    orderOffers
  );
  let discountPrice = appointmentDiscounts?.reduce(
    (prev, item) => prev + item.amount,
    0
  );
  let offersPrice = getOffersPrice(orderOffers, purchaseProducts);
  const gridMode = get(currentUser, "viewMode", "GRID") === "GRID";

  const renderProductItem = (purchaseProducts: any) => {
    const groupProducts = groupBy(purchaseProducts, "id");
    return (
      <>
        {Object.keys(groupProducts).map((key) => {
          const isOptions = !isEmpty(
            get(groupProducts[key], "[0].priceVariants", null)
          );
          return (
            <PurchaseDetailsGroupWrapper>
              {groupProducts[key] &&
                groupProducts[key].map((item: any, index: number) => {
                  let title: string;
                  let character: any;
                  let optionName: string = "";
                  const price = getCurrencyWithPrice(
                    item.productSalePrice,
                    shopData.currency,
                    false
                  );
                  const productOptionName = get(
                    item,
                    "priceVariants[0].optionUnit",
                    ""
                  );
                  title = startCase(get(item, "productName", ""));
                  if (isOptions) {
                    character = productOptionName.match(/\b(\w)/g);
                    optionName = get(item, "priceVariants[0].optionUnit", "");
                  } else {
                    character = title.match(/\b(\w)/g);
                  }
                  let nameIcon =
                    _.toUpper(character[0]) + _.toUpper(character[1]);

                  return (
                    <>
                      <BookingDetailsBottomServiceItem>
                        <BookingDetailsBottomServiceName>
                          <ServiceCardImageWrapper>
                            <div>{nameIcon}</div>
                          </ServiceCardImageWrapper>
                          <ServiceCardNameWrapper>
                            <div>
                              <div className={"nameText"}>{title}</div>
                              {isOptions && (
                                <div
                                  className={"optionText"}
                                >{`- ${optionName}`}</div>
                              )}
                            </div>
                            <div className={"priceText"}>{price}</div>
                          </ServiceCardNameWrapper>
                        </BookingDetailsBottomServiceName>

                        <BookingDetailsBottomServiceCount>
                          <img
                            src={MinusIcon}
                            style={{ paddingRight: "13px" }}
                            onClick={() =>
                              changeProductQuantity(item, "DECREMENT")
                            }
                          />
                          <div>{item.quantity}</div>
                          <img
                            src={AddIcon}
                            style={{ paddingLeft: "13px" }}
                            onClick={() =>
                              changeProductQuantity(item, "INCREMENT")
                            }
                          />
                        </BookingDetailsBottomServiceCount>
                      </BookingDetailsBottomServiceItem>
                      {!isOptions && <BookingDetailsBottomServiceItemLine />}
                      {isOptions && groupProducts[key].length - 1 === index && (
                        <BookingDetailsBottomServiceItemLine />
                      )}
                    </>
                  );
                })}
            </PurchaseDetailsGroupWrapper>
          );
        })}
      </>
    );
  };

  const renderServiceItem = (purchaseServices: any) => {
    purchaseServices.map((item: any) => {
      let itemPrice = get(item, "servicePrice");
      let character = get(item, "serviceTitle").match(/\b(\w)/g);
      let nameIcon = _.toUpper(character[0]) + _.toUpper(character[1]);
      let price = getCurrencyWithPrice(itemPrice, shopData.currency);

      return (
        <>
          <BookingDetailsBottomServiceItem>
            <BookingDetailsBottomServiceName>
              <ServiceCardImageWrapper>
                <div>{nameIcon}</div>
              </ServiceCardImageWrapper>
              <ServiceCardNameWrapper>
                <div className={"nameText"}>
                  {startCase(item.productName || item.serviceTitle)}
                </div>
                <div className={"priceText"}>{price}</div>
              </ServiceCardNameWrapper>
            </BookingDetailsBottomServiceName>
            <BookingDetailsBottomServiceCount>
              <img
                src={MinusIcon}
                style={{ paddingRight: "13px" }}
                onClick={() => changeServiceQuantity(item, "DECREMENT")}
              />
              <div>{item.quantity}</div>
              <img
                src={AddIcon}
                style={{ paddingLeft: "13px" }}
                onClick={() => changeServiceQuantity(item, "INCREMENT")}
              />
            </BookingDetailsBottomServiceCount>
          </BookingDetailsBottomServiceItem>
          <BookingDetailsBottomServiceItemLine />
        </>
      );
    });
  };

  return (
    <BookingDetailsContainer>
      <BookingDetailsHeader>
        <div>
          <BookingDetailsAppointmentHeader
            onClick={() => navigate(gridMode ? "/product" : "/order-list")}
          >
            <img src={backIcon} />
            <div>{f({ id: "CHECKOUT.BACK_ORDER" })}</div>
          </BookingDetailsAppointmentHeader>
          <BookingDetailsAppointmentClear
            onClick={() => clearCurrentAppointment()}
          >
            <img src={bookingRemoveIcon} />
            <div>{f({ id: "CHECKOUT.CLEAR" })}</div>
          </BookingDetailsAppointmentClear>
        </div>
      </BookingDetailsHeader>

      <BookingDetailsBottomContainer>
        <BookingDetailsBottomService id="checkout">
          {renderProductItem(purchaseProducts)}
          {renderServiceItem(purchaseServices)}
        </BookingDetailsBottomService>

        <BookingDetailsBottomButton>
          <div className={"subTotal"}>
            <div>
              <div>{f({ id: "CHECKOUT.SUB_TOTAL" })}</div>
              <div>{`${getCurrencyWithPrice(
                subTotalPrice,
                shopData.currency
              )}`}</div>
            </div>
            <div>
              <div>{f({ id: "CHECKOUT.DISCOUNT" })}</div>
              <div>{`${discountPrice > 0 ? "-" : ""} ${getCurrencyWithPrice(
                discountPrice,
                shopData.currency
              )}`}</div>
            </div>
            <div>
              <div>{f({ id: "CHECKOUT.OFFER" })}</div>
              <div>{`${offersPrice > 0 ? "-" : ""} ${getCurrencyWithPrice(
                offersPrice,
                shopData.currency
              )}`}</div>
            </div>
            <div>
              <div>{f({ id: "CHECKOUT.SERVICE_CHARGE" })}</div>
              <div>
                {getCurrencyWithPrice(serviceCharge, shopData.currency)}
              </div>
            </div>
          </div>
          <div className={"totalBox"}>
            <div className={"totalPriceText"}>
              {f({ id: "CHECKOUT.TOTAL" })}
            </div>
            <div className={"totalPriceValueText"}>{`${getCurrencyWithPrice(
              totalPrice,
              shopData.currency
            )}`}</div>
          </div>
        </BookingDetailsBottomButton>
      </BookingDetailsBottomContainer>
    </BookingDetailsContainer>
  );
};
export default connect(
  (state: any) => ({
    shopData: state.login.get("merchantShop"),
    currentUser: state.login.get("currentUser"),
    orderOffers: state.offer.get("orderOffers"),
    purchaseProducts: state.product.get("purchaseProducts"),
    purchaseServices: state.service.get("purchaseServices"),
    appointmentDiscounts: state.product.get("appointmentDiscounts"),
  }),
  {
    clearCurrentAppointment: Actions.payment.clearCurrentAppointment,
    changeServiceQuantity: Actions.service.changeServiceQuantity,
    changeProductQuantity: Actions.product.changeProductQuantity,
  }
)(BookingDetailsComponent);
