import _ from "lodash";
import {
  setValueToLocalStorage,
  updateCurrentStorageData,
  deleteCurrentStorageData,
  getValueFromLocalStorage,
} from "../manager/CommonManager";
import Repository from "./Repository";
import { isNetworkError } from "../manager/AppointmentManager";
class StockRepository extends Repository {
  async createStockRecord(param, retryCount = 1) {
    try {
      const message = this.buildMessage(param);

      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "STOCK_RECORD",
          param,
          param.id,
          "id"
        );
        return newParam;
      }

      const result = await this.apiPost({
        apiName: this.API_NETLISE_APP,
        path: `/stock-record`,
        message,
      });
      return result.stockResult;
    } catch (error) {
      console.warn("create stock record error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.createStockRecord(param, ++retryCount);
      }
      return { error };
    }
  }

  async updateStockRecord(param, retryCount = 1) {
    try {
      const message = this.buildMessage(param);
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await updateCurrentStorageData(
          "STOCK_RECORD",
          param,
          param.id,
          "id"
        );
        return newParam;
      }

      const result = await this.apiPut({
        apiName: this.API_NETLISE_APP,
        path: `/stock-record`,
        message,
      });
      return result.stockResult;
    } catch (error) {
      console.warn("update stock record error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.updateStockRecord(param, ++retryCount);
      }
      return { error };
    }
  }

  async fetchStockRecords(shopId, retryCount = 1) {
    try {
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const list = await getValueFromLocalStorage("STOCK_RECORD");
        if (list) {
          const newList = JSON.parse(list);
          return newList.Items;
        }
        return [];
      }

      const result = await this.apiGet({
        apiName: this.API_NETLISE_APP,
        path: `/stock-records?shopId=${shopId}`,
      });
      await setValueToLocalStorage(
        "STOCK_RECORD",
        JSON.stringify(result.stockResult)
      );
      return result.stockResult;
    } catch (error) {
      console.warn("fetch stock records error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.fetchStockRecords(shopId, ++retryCount);
      }
      return { error };
    }
  }

  async deleteStockRecord(param, retryCount = 1) {
    try {
      const message = this.buildMessage(param);
      const isOnline = await this.isCheckOnline();
      if (!isOnline) {
        const newParam = await deleteCurrentStorageData(
          "STOCK_RECORD",
          param,
          param.id,
          "id"
        );
        return newParam;
      }

      const result = await this.apiDelete({
        apiName: this.API_NETLISE_APP,
        path: `/stock-record`,
        message,
      });
      return result.stockResult;
    } catch (error) {
      console.warn("delete stock record error", error);
      if (isNetworkError(error) && retryCount <= 3) {
        return await this.deleteStockRecord(param, ++retryCount);
      }
      return { error };
    }
  }
}
export default new StockRepository();
