import React, { useEffect, useState } from "react";
import _, { get, isEmpty } from "lodash";
import moment from "moment";
import styles from "../../../components/Styles/Styles.css";
import IconCash from "../../../../assets/svg/IconCash.svg";
import IconCreditCard from "../../../../assets/svg/IconCard.svg";
import IconPaymentSplit from "../../../../assets/svg/PaymentSplit.svg";
import { getCurrencyWithPrice } from "../../../internal/manager/AppointmentManager";

const TransactionDataRows = (props) => {
  const { transaction, selectedTransaction, shopData, onClickTransaction } =
    props;
  const [type, setType] = useState("CASH");
  const [price, setPrice] = useState("");
  const [name, setName] = useState("");
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setType(_.get(transaction, "paymentMethod", "CASH"));
    setName(`${transaction.firstName || ""} ${transaction.lastName || ""}`);
    setPrice(getCurrencyWithPrice(transaction.totalPrice, shopData.currency));
  }, [transaction]);

  useEffect(() => {
    if (!_.isEmpty(selectedTransaction)) {
      setIsSelected(selectedTransaction.orderId === transaction.orderId);
    }
  }, [selectedTransaction]);

  const getIcon = (paymentMethod) => {
    switch (paymentMethod) {
      case "CASH": {
        return IconCash;
      }
      case "CARD": {
        return IconCreditCard;
      }
      case "SPLIT": {
        return IconPaymentSplit;
      }
      default: {
        return IconCash;
      }
    }
  };
  const didRefund = !!transaction.voidType;
  return (
    <div>
      <div
        onClick={() => onClickTransaction(transaction)}
        className={styles.transactionDataRowWrapper}
        style={{ backgroundColor: isSelected ? "#F8F9FB" : "#FFFFFF" }}
      >
        <div className={styles.transactionDataPriceWrapper}>
          <div className={styles.transactionDataIcon}>
            <img alt="" src={getIcon(type)} />
          </div>
          <div className={styles.transactionDataPriceWrapperInfo}>
            <div
              className={
                didRefund
                  ? styles.transactionDataRowValueRefund
                  : styles.transactionDataRowValue
              }
            >
              {price} {didRefund && "REFUND"}
            </div>
            <div className={styles.transactionDataRow}>
              <div className={styles.transactionDataRowName}>
                Reciept: {transaction.recieptId || ""}{" "}
                {transaction.clientId ? `- ${name}` : ""}
              </div>
              {!isEmpty(get(transaction, "tableOrder.tbId", null)) ? (
                <div className={styles.transactionDataRowName}>
                  {`Table: ${get(transaction, "tableOrder.tbNumber", "")}`}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <div className={styles.transactionDataRowTime}>
            {moment(transaction.updatedTime).format("hh:mm A")}
          </div>
          <div className={styles.transactionDataRowTime}>
            {moment(transaction.updatedTime).format("DD/MM/YYYY ddd")}
          </div>
        </div>
      </div>
      <div className={styles.transactionDataRowLine} />
    </div>
  );
};
export default TransactionDataRows;
