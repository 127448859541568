import { createAction } from "../../internal/app/AppUtils";
import OfferRepository from "../../internal/repos/OfferRepository";

export const ModuleEvents = {
  FETCH_OFFERS: "FETCH_OFFERS",
  CREATE_OFFER: "CREATE_OFFER",
  UPDATE_OFFER: "UPDATE_OFFER",
  DELETE_OFFER: "DELETE_OFFER",
  SET_ORDER_OFFER: "SET_ORDER_OFFER",
  DELET_ORDER_OFFER: "DELET_ORDER_OFFER",
};

const Actions = {
  fetchOffers: createAction(
    ModuleEvents.FETCH_OFFERS,
    async (shopId: string, limit: number = 30, nextToken = null) => {
      const result = await OfferRepository.fetchOffers(
        shopId,
        limit,
        nextToken
      );
      return result;
    }
  ),
  createOffer: createAction(ModuleEvents.CREATE_OFFER, async (param: any) => {
    const result = await OfferRepository.createOffer(param);
    return result;
  }),
  updateOffer: createAction(ModuleEvents.UPDATE_OFFER, async (param: any) => {
    const result = await OfferRepository.updateOffer(param);
    return result;
  }),
  deleteOffer: createAction(
    ModuleEvents.DELETE_OFFER,
    async (shopId: string, id: string) => {
      const result = await OfferRepository.deleteOffer(shopId, id);
      return result;
    }
  ),
  setOrderOffer: createAction(
    ModuleEvents.SET_ORDER_OFFER,
    async (data: any) => {
      return data;
    }
  ),
  deleteOrderOffer: createAction(
    ModuleEvents.DELET_ORDER_OFFER,
    async (data: any) => {
      return data;
    }
  ),
};

export default Actions;
