import React, {useEffect, useState} from 'react';
import styles from '../../../components/Styles/Styles.css';
import {getImage} from "../../../internal/manager/ImageManager";
import IconAdd from '../../../../assets/svg/IconAdd.svg';

const ServiceItem = (props) => {
  const {onClickAdd} = props;

  return (
    <div style={{maxHeight: window.innerHeight - 200}} className={styles.serviceItemWrapper}>
      {
        [1, 2, 3, 4, 5].map((item) => {
          return (
            <div className={styles.serviceItemBoxWrapper} onClick={() => {
            }}>
              <img className={styles.serviceItemImage} src={getImage['dandexShampoo']}/>
              <div className={styles.serviceItemText}>{'Service Item'}</div>
              <div className={styles.serviceItemTextLabel}>{'(5 ml)'}</div>
            </div>
          )
        })
      }
      <div className={styles.serviceItemAddBoxWrapper} onClick={() => {}}>
        <div className={styles.serviceItemAddBoxWrapperInfo}>
          <img onClick={()=> onClickAdd()} src={IconAdd}/>
        </div>
      </div>
    </div>
  );
};
export default ServiceItem;
