import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { CenterContainer } from "../../../components";
import { Actions } from "../../../internal/app/Actions";
import { Container, Row, Col } from "react-grid-system";
import styles from "../../../components/Common/Style/Styles.css";
import FormInput from "../../../components/input/Views/formInput";
import { validateName } from "../../../internal/validator/Validate";
import { navigate } from "../../../internal/service/Navigation.service";
import SignInHeader from "../../../components/headers/Views/signInHeader";
import SignInButton from "../../../components/Buttons/Views/SignInButton";
import BackToWelcomeIcon from "../../../../assets/icons/backToWelcomeIcon.svg";
import AuthenticationAlertModel from "../../../components/model/views/AuthenticationAlertModel";

const LoginView = (props) => {
  const {
    userSignIn,
    loadingAction,
    openAuthStatus,
    openAuthenticationModel,
    selectedLoginEmployee,
  } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(true);

  useEffect(() => {
    if (!_.isEmpty(selectedLoginEmployee)) {
      setEmail(selectedLoginEmployee.empId);
      // setIsValidEmail(true);
    }
  }, []);

  const setValue = async (type, text) => {
    if (text.length > 6) {
      return;
    }
    if (type === "EMAIL") {
      const isValid = await validateName(text);
      setEmail(text);
    } else if (type === "PASSWORD") {
      const isValid = await validateName(text);
      setPassword(text);
      setIsValidPassword(isValid.isValid);
    }
  };

  const checkValid = async () => {
    const isEmailValid = await validateName(email);
    const isPasswordValid = await validateName(password);
    setIsValidPassword(isPasswordValid.isValid);
    return isEmailValid.isValid && isPasswordValid.isValid;
  };

  const { isOpen, message } = openAuthStatus;
  const isLoading =
    loadingAction.loading &&
    (loadingAction.action.type === "USER_SIGNIN" ||
      loadingAction.action.type === "SET_LOGIN_IDS" ||
      loadingAction.action.type === "GET_CURRENT_USER" ||
      loadingAction.action.type === "SET_ACCESS_TOKEN");

  const userLogin = async () => {
    const isValid = await checkValid();
    if (!isValid || isLoading) {
      return;
    }
    userSignIn(email, password);
  };

  return (
    <div className={styles.background}>
      <div
        onClick={() => navigate("/welcome")}
        className={styles.loginViewBackBtnContainer}
      >
        <img src={BackToWelcomeIcon} />
        <div className={styles.backToWelcomeText}>Back to Welcome</div>
      </div>
      <CenterContainer width="592px">
        <Container>
          <Row>
            <Col xs={12} className={styles.headerAligh}>
              <SignInHeader />
            </Col>
          </Row>

          {/*<Row>*/}
          {/*  <Col xs={12} className={styles.forUserName}>*/}
          {/*    <FormInput*/}
          {/*      onChangeText={(text) => setValue("EMAIL", text)}*/}
          {/*      isValid={isValidEmail}*/}
          {/*      validationText={!isValidEmail ? !email ? "Username must be required" : "Enter valid username" : null}*/}
          {/*      value={email}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}

          <Row>
            <Col xs={12} className={styles.forPasscode}>
              <FormInput
                type={"password"}
                text={"Pin *"}
                placeholder={"Pin *"}
                value={password}
                onChangeText={(text) => setValue("PASSWORD", text)}
                isValid={isValidPassword}
                validationText={
                  !isValidPassword
                    ? !password
                      ? "Pin must be required"
                      : "Enter valid pin"
                    : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              className={styles.forPasscode}
              onClick={() => userLogin()}
            >
              <SignInButton loading={isLoading} text="Sign In" />
            </Col>
          </Row>
        </Container>
      </CenterContainer>
      <AuthenticationAlertModel
        isOpen={isOpen}
        closeModel={() => openAuthenticationModel(false)}
        message={message}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    loadingAction: state.common.get("loadingAction"),
    selectedLoginEmployee: state.login.get("selectedLoginEmployee"),
    openAuthStatus: state.login.get("openAuthenticationModelStatus"),
  }),
  {
    userSignIn: Actions.login.userSignIn,
    openAuthenticationModel: Actions.login.openAuthenticationModel,
  }
)(LoginView);
