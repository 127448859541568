import { createAction } from "../../internal/app/AppUtils";
import ActivityRepository from "../../internal/repos/ActivityRepository";

export const ModuleEvents = {
  FETCH_ACTIVITIES: "FETCH_ACTIVITIES",
  CREATE_ACTIVITY_LOG: "CREATE_ACTIVITY_LOG",
  RESTORE_ACTIVITY_LOG: "RESTORE_ACTIVITY_LOG",
};

export default {
  fetchActivities: createAction(
    ModuleEvents.FETCH_ACTIVITIES,
    async (shopId: string, limit: number = 100, lastKey: any = null) => {
      const result = await ActivityRepository.fetchActivityLogs(
        shopId,
        limit,
        lastKey
      );
      return result;
    },
    { loading: true }
  ),
  createActivityLog: createAction(
    ModuleEvents.CREATE_ACTIVITY_LOG,
    async (params: any) => {
      const result = await ActivityRepository.uploadActivity(params);
      return result
    },
    { loading: true }
  ),
  restoreActivityLog: createAction(
    ModuleEvents.RESTORE_ACTIVITY_LOG,
    async (params: any) => {
      const result = await ActivityRepository.restoreActivityLog(params);
      return result
    },
    { loading: true }
  ),
};
