import moment from "moment";
import _, { isArray, isEmpty, isFunction, unionBy } from "lodash";

export const getTimeColumn = (selectedDate, chosenSalon) => {
  const { start, end } = getDailyStartEndTime(chosenSalon, selectedDate);
  let timeArray = [];
  let newStart = moment(start, "HH:mm").valueOf();
  let newEnd = moment(end, "HH:mm").valueOf();
  while (newStart <= newEnd) {
    const time = moment(newStart).format("hh:mm A");
    timeArray.push(time);
    newStart = moment(newStart).add(60, "minutes").valueOf();
  }
  return timeArray;
};

export const getDailyStartEndTime = (salon, selectedDay) => {
  const day = moment(selectedDay).format("dddd");
  const data = _.filter(
    _.get(salon, "schedule", []),
    (item) => item.day === day
  );
  if (
    _.isEmpty(_.get(data, "[0].start", null)) ||
    _.isEmpty(_.get(data, "[0].end", null))
  ) {
    return { open: "00:00", close: "00:00" };
  }
  const { start, end } = roundDownTime(data[0].start, data[0].end);
  return { start, end };
};

const roundDownTime = (begin, end) => {
  const startHour = moment(begin, ["h:mm A"]).startOf("hour");
  const endHour = moment(end, ["h:mm A"]).startOf("hour");

  const startDiff = moment
    .duration(moment(begin, ["h:mm A"]).diff(startHour))
    .asMinutes();
  const endDiff = moment
    .duration(moment(end, ["h:mm A"]).diff(endHour))
    .asMinutes();

  let newStart;
  let newEnd;
  if (startDiff === 0) {
    newStart = begin;
  } else {
    if (startDiff >= 30) {
      newStart = moment(begin, ["h:mm A"]).subtract(startDiff - 30, "minutes");
    } else {
      newStart = moment(begin, ["h:mm A"]).subtract(startDiff, "minutes");
    }
  }
  if (endDiff === 0) {
    newEnd = end;
  } else {
    if (endDiff > 30) {
      newEnd = moment(end, ["h:mm A"]).add(60 - endDiff, "minutes");
    } else {
      newEnd = moment(end, ["h:mm A"]).add(30 - endDiff, "minutes");
    }
  }

  const start = moment(newStart, ["h:mm A"]).format("HH:mm");
  const close = moment(newEnd, ["h:mm A"]).format("HH:mm");
  return { start: start, end: close };
};

export const getAppointmentDateTime = (appointment) => {
  let date;
  let time;
  const isTimeProposed = !_.isEmpty(
    _.get(appointment, "appointmentReason[1].proposeTime", null)
  );
  const isAccepted =
    _.get(appointment, "appointmentReason[1].reasonStatus", "") === "ACCEPTED";

  if (isTimeProposed) {
    time = moment(
      _.get(appointment, "appointmentReason[1].proposeTime", ""),
      "hh.mm"
    ).format("hh:mm");
    date = moment(
      _.get(appointment, "appointmentReason[1].proposeDate", appointment.date)
    ).format("DD/MM/YYYY");
  } else if (isAccepted) {
    time = moment(
      _.get(appointment, "appointmentReason[2].proposeTime", ""),
      "hh.mm"
    ).format("hh:mm");
    date = moment(
      _.get(appointment, "appointmentReason[2].proposeDate", appointment.date)
    ).format("DD/MM/YYYY");
  } else {
    time = moment(appointment.time, "hh.mm").format("hh:mm");
    date = moment(appointment.date).format("DD/MM/YYYY");
  }
  return { date, time };
};

export const getWeeklyStartEndTime = (salon) => {
  const schedule = _.get(salon, "schedule", []);
  let minTime = null;
  let maxTime = null;

  schedule.forEach((item) => {
    let hourlyStart;
    let hourlyEnd;
    if (!_.isEmpty(item.start) && !_.isEmpty(item.end)) {
      hourlyStart = moment(item.start, "hh:mm A").format("HH:mm:ss");
      hourlyEnd = moment(item.end, "hh:mm A").format("HH:mm:ss");
      if (_.isNil(minTime)) {
        minTime = hourlyStart;
        maxTime = hourlyEnd;
      } else {
        minTime = minTime < hourlyStart ? minTime : hourlyStart;
        maxTime = maxTime > hourlyEnd ? maxTime : hourlyEnd;
      }
    }
  });
  const { start, end } = roundDownTime(minTime, maxTime);
  return { start, end };
};

export const getWeekDays = (selectedDate) => {
  let date = moment(selectedDate).format("ddd-MMM-D");
  let weekDays = [moment(selectedDate).format("ddd, MMM D")];
  for (let i = 0; i < 6; i++) {
    date = moment(date, "ddd-MMM-D").add(1, "d").format("ddd, MMM D");
    weekDays.push(date);
  }
  return weekDays;
};

export const getMonthDays = (selectedDate) => {
  let today = moment(selectedDate).format("ddd-MMM-D");
  const splits = _.split(today, "-");
  let monthDays = [`${splits[0]},${splits[2]}`];
  for (let i = 0; i < 30; i++) {
    today = moment(today, "ddd-MMM-D").add(1, "d").format("ddd-MMM-D");
    const splits = _.split(today, "-");
    let day = `${splits[0]},${splits[2]}`;
    monthDays.push(day);
  }
  return monthDays;
};

export const getTotalDuration = (service) => {
  let totalDuration = 0;
  service.forEach((item) => {
    const splits = _.split(item.duration, ":");
    const time = parseInt(splits[0]) * 60 + parseInt(splits[1]);
    totalDuration += time;
  });
  return totalDuration;
};

const daysInMonth = (month, year) =>
  new Date(parseInt(year), parseInt(month), 0).getDate();

export const setWeeklyDateRange = (day, monthName, year) => {
  let weeklyText;
  const month = parseInt(moment().month(monthName).format("M"));
  const maxDays = daysInMonth(month, year);
  const startDay = parseInt(day);
  const endDay = startDay + 6;
  if (month === 12) {
    if (maxDays < endDay) {
      const additionalDays = endDay - maxDays;
      const newYear = parseInt(year) + 1;
      const newMonthName = moment(1, "M").format("MMM");
      weeklyText = `${monthName} ${startDay} - ${newMonthName} ${additionalDays}`;
    } else {
      weeklyText = `${monthName} ${startDay} - ${monthName} ${endDay}, ${year}`;
    }
  } else {
    if (maxDays < endDay) {
      const additionalDays = endDay - maxDays;
      const newMonthName = moment(month + 1, "M").format("MMM");
      weeklyText = `${monthName} ${startDay} - ${newMonthName} ${additionalDays}`;
    } else {
      weeklyText = `${monthName} ${startDay} - ${monthName} ${endDay}`;
    }
  }
  return weeklyText;
};

export const setDailySuperScript = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const setDailyDate = (day, month, year) => {
  const superScript = setDailySuperScript(day);
  return `${day}${superScript} of ${month} ${year}`;
};

export const classifyDailyAppointments = (timeColumn, appointments) => {
  let classifiedAppointments = new Map();
  timeColumn.forEach((item) => {
    const newItem = moment(item, "hh:mm A").format("HH.mm");
    const endItem = moment(item, "hh:mm A").add(30, "minutes").format("HH.mm");
    const filteredAppointments = _.filter(appointments, (unit) => {
      const newUnitTime = moment(unit.time, "HH.mm A").format("HH.mm");
      return newUnitTime >= newItem && newUnitTime < endItem;
    });

    const newFiltered = filteredAppointments.map((miniItem) => {
      const totalDuration = getTotalDuration(miniItem.serviceDetails);
      const { start, end } = getStartEndAppointmentTime(
        miniItem,
        totalDuration
      );
      const difference = moment
        .duration(moment(start, "hh:mm A").diff(moment(newItem, "HH.mm")))
        .asMinutes();

      const marginTopAddition = difference * 2;
      miniItem.endTime = end;
      miniItem.duration = totalDuration;
      miniItem.timeDiff = marginTopAddition;
      return miniItem;
    });
    classifiedAppointments.set(item, newFiltered);
  });
  return classifiedAppointments;
};

const getAppointmentKey = (date) => {
  const newKey = moment(parseInt(date)).format("ddd-D-MMM");
  const splits = _.split(newKey, "-");
  return `${splits[0]}, ${splits[2]} ${splits[1]}`;
};

const getStartEndAppointmentTime = (item, duration) => {
  const startTime = _.get(item, "time", []);
  const end = moment(`${startTime}`, "HH:mm")
    .add(duration, "m")
    .format("hh:mm A");
  const start = moment(`${startTime}`, "HH:mm").format("hh:mm A");
  return { start, end };
};

const getYearAppointmentKey = (date) => {
  const newKey = moment(parseInt(date)).format("ddd-D-MMM");
  const splits = _.split(newKey, "-");
  return `${splits[0]},${splits[1]}`;
};

export const getAppointmentsPerStylistArray = (appointments) =>
  _.groupBy(appointments, "stylistId");

export const classifyAppointments = (timeColumn, appointments) => {
  let classifiedAppointments = new Map();
  const appointmentsArray = getAppointmentsPerStylistArray(appointments);

  Object.keys(appointmentsArray).forEach((key) => {
    classifiedAppointments.set(
      key,
      classifyDailyAppointments(timeColumn, appointmentsArray[key])
    );
  });
  return classifiedAppointments;
};

export const setAppointmentBackColor = (status) => {
  switch (status) {
    case "CONFIRMED":
      return "#E0F3E8";
    case "ACCEPTED":
      return "#39B97D";
    case "PENDING":
      return "#FFFAED";
    case "COMPLETED":
      return "#E6ECFF";
    case "CANCELED":
      return "#FFEAEA";
    case "RESCHEDULED":
      return "#B939AF";
    default:
      return "#FFFAED";
  }
};

export const setBackgroundColor = (status) => {
  switch (status) {
    case "CONFIRMED":
      return "#39B97D";
    case "ACCEPTED":
      return "#39B97D";
    case "PENDING":
      return "#FFB100";
    case "COMPLETED":
      return "#3952B9";
    case "CANCELED":
      return "#FF6464";
    case "RESCHEDULED":
      return "#B939AF";
    default:
      return "#FFB100";
  }
};

export const getCurrencyWithPrice = (price, currency) => {
  return new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: currency,
  }).format(price);
};

export const getServiceCount = (appointments) => {
  return appointments
    .map((item) => item.serviceDetails.length)
    .reduce((prev, curr) => prev + curr, 0);
};

export const getGrossSales = (appointments) => {
  return appointments
    .map((item) => item.totalPrice)
    .reduce((prev, curr) => prev + curr, 0)
    .toFixed(2);
};

export const isValidOrder = ({
  purchaseProducts,
  purchaseServices,
  // selectedCustomer,
  // quickCustomer,
  selectedEmployee,
  totalPrice,
  appointmentDate,
}) => {
  return (
    (purchaseServices.length > 0 || purchaseProducts.length > 0) &&
    // (!_.isEmpty(selectedCustomer) || !_.isEmpty(quickCustomer)) &&
    !_.isEmpty(selectedEmployee) &&
    totalPrice > 0 &&
    appointmentDate
  );
};

export const isValidKitchenOrder = ({ purchaseProducts }) => {
  let isValidItem: boolean = false;
  if (!isEmpty(purchaseProducts)) {
    isValidItem = purchaseProducts.reduce((accumulator: any, product: any) => {
      if (product?.sendToKitchen || product?.sendToBar) {
        let sentQty = 0;
        let quantityDifference = 0;
        if (!isEmpty(product?.sendPrepTicket)) {
          sentQty = product?.sendPrepTicket.reduce((acc, { quantity }) => {
            return acc + quantity;
          }, 0);
          quantityDifference = product.quantity - sentQty;
        } else {
          quantityDifference = product.quantity;
        }
        return quantityDifference > 0;
      }
      return accumulator;
    }, false);
  }
  return isValidItem;
};

export const mapTables = (tables) => {
  return tables.map(({ tbId, tbNumber, tbType, tbShape, x, y, w, h }) => ({
    tbId,
    tbNumber,
    tbType,
    tbShape,
    x,
    y,
    w,
    h,
  }));
};

export const mapRooms = (rooms) => {
  return rooms.map(({ rmId, rmNumber, rmType, rmShape, x, y, w, h }) => ({
    rmId,
    rmNumber,
    rmType,
    rmShape,
    x,
    y,
    w,
    h,
  }));
};

export const keyValueToObject = (keyValues) => {
  if (!isArray(keyValues)) {
    return {};
  }
  return keyValues.reduce((acc, { key, value }) => {
    acc[key] = value;
    return acc;
  }, {});
};

export const mapSystemOptions = (options: any, values: Object) => {
  const optionValues = Object.entries(values).map(([key, value]) => ({
    key,
    value,
  }));
  if (options) {
    return unionBy(optionValues, options, "key");
  }
  return optionValues;
};

export const getServiceChargeSettings = (settings) => {
  const settingObj = keyValueToObject(settings);
  const isEnabled = settingObj["serviceCharges.isEnabled"] === "true";
  const serviceChargePercentage = settingObj["serviceCharges.chargeAmount"];

  return { isEnabled, serviceChargePercentage };
};

export const getDiscountTitle = (discount, currency) => {
  return discount.amountType === "PERCENTAGE"
    ? `${discount.amount}%`
    : getCurrencyWithPrice(discount.amount, currency);
};

export const mapRefundAmount = async (services, products) => {
  let serviceCount = 0,
    productCount = 0,
    servicePrice = 0,
    productPrice = 0;
  await services.map((service) => {
    serviceCount = serviceCount + service.quantity;
    servicePrice = servicePrice + service.amount * service.quantity;
  });

  await products.map((product) => {
    productCount = productCount + product.quantity;
    productPrice = productPrice + product.amount * product.quantity;
  });

  return { serviceCount, productCount, servicePrice, productPrice };
};

export const checkIsSaveCurrentOrder = (
  purchaseProducts,
  purchaseServices,
  selectedVehicle,
  selectedCustomer,
  selectedTable
) => {
  const isValid =
    purchaseProducts.length > 0 ||
    purchaseServices.length > 0 ||
    !_.isEmpty(selectedVehicle) ||
    !_.isEmpty(selectedCustomer) ||
    selectedTable?.tbId;
  return isValid;
};

export const isNetworkError = (error) => {
  return (
    _.get(error, "errors.0.message") === "Network Error" ||
    _.get(error, "message") === "Network Error" ||
    error === "Error: Network Error" ||
    (isFunction(error.toJSON) && error.toJSON().message === "Network Error")
  );
};

export const getWarrantyEndDate = (warranty: any, dateTime: any) => {
  const type = warranty.durationType;
  const duration = parseInt(warranty.duration);
  if (type) {
    switch (type) {
      case "DAYS": {
        const endDate = moment(dateTime.date)
          .add(duration, "d")
          .format("DD/MM/yyyy");
        return endDate;
      }
      case "WEEKS": {
        const endDate = moment(dateTime.date)
          .add(duration, "w")
          .format("DD/MM/yyyy");
        return endDate;
      }
      case "MONTHS": {
        const endDate = moment(dateTime.date)
          .add(duration, "M")
          .format("DD/MM/yyyy");
        return endDate;
      }
      case "YEARS": {
        const endDate = moment(dateTime.date)
          .add(duration, "y")
          .format("DD/MM/yyyy");
        return endDate;
      }
    }
  } else {
    return warranty.endDate;
  }
};
